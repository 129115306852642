import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import cls from "classnames";
import { Header } from "./Header";
import { PasswordInput } from "./PasswordInput";
import { cognizantPasswordContraints } from "../utils/passwordConstraint";
import { useParams } from "react-router-dom";

import panelStyles from "./Panel.module.scss";
import styles from "./PasswordNew.module.scss";
import './PasswordNewOverrides.scss'
import { PasswordRequirements } from "./PasswordRequirements";
import {
  setNewPassword,
  loggedIn,
  newPasswordRequired,
} from "../../features/auth/authSlice";
import { DialogPopUp } from "../common/DialogPopUp";

import {
  Col,
  Row,
  Card,
  Button,
  Form,
} from 'antd';

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

export function PasswordNew() {
  const params: any = useParams();
  const { code, username } = params;
  const newPassword = useSelector(newPasswordRequired);
  const [password, setPassword] = useState<string>("");
  const [passwordConfirmation, setPaswrdConfirmation] = useState<string>("");
  const [passwordDontMatchError, setPasswordDontMatchError] = useState<string>(
    ""
  );
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [allIsFulfiled, setAllIsFulfiled] = useState<boolean>(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const isLoggedIn = useSelector(loggedIn);

  const handleSubmit = () => {
    if (password !== passwordConfirmation) {
      setPasswordDontMatchError("Contraseñas no son iguales");
      return;
    }
    const { valid, errors } = cognizantPasswordContraints(password);
    if (!valid) {
      errors.forEach((error) => console.log(error));
      return;
    }
    dispatch(setNewPassword(code, username, password));
  };

  useEffect(() => {
    if (isLoggedIn) {
      history.push("/welcome");
    }
  });

  return (
    <React.Fragment>
      <DialogPopUp
        name="¡Contraseña"
        action="actualizada!"
        visible={isModalVisible}
        onCancel={() => {
          setIsModalVisible(false);
        }}
      />
      <div className={cls(panelStyles.Bkgnd)}></div>
      <div>
        <Row>
          <Col xs={{ span: 18, offset: 3 }} md={{ span: 8, offset: 8 }}>
            <Row>
              <Col span={22} offset={1} className={cls(styles.Container)}>
                <Card>
                  <Header
                    className={cls(styles.Dark, styles.size)}
                    title={"Crear nueva contraseña"}
                  />
                  <Form
                    className={cls(styles.form)}
                    {...layout}
                    name="basic"
                    onFinish={(values: { newPassword: string, newPasswordConfirmation: string }) => {
                      handleSubmit();
                    }}
                    onFinishFailed={() => { console.log('Error al intentar cambiar el password') }}
                  >
                    <Form.Item
                      className={styles.gray}
                      label="Nueva contraseña"
                      name="newPassword"
                      rules={[{ required: true, message: 'Por favor ingrese la contraseña!' }]}
                      labelCol={{ span: 24 }}
                      wrapperCol={{ span: 24 }}
                    >
                      <PasswordInput onChange={setPassword} />
                    </Form.Item>
                    <Form.Item
                      label="Repetir contraseña"
                      name="newPasswordConfirmation"
                      rules={[{ required: true, message: 'La confirmación es requerida' }]}
                      labelCol={{ span: 24 }}
                      wrapperCol={{ span: 24 }}
                    >
                      <PasswordInput onChange={setPaswrdConfirmation} showMeter={false} />
                    </Form.Item>
                    <div className={cls("error")}>{passwordDontMatchError}</div>
                    <div className={cls(styles.requirementsSize)}>
                      <PasswordRequirements
                        password={password}
                        onAllFulfiledChange={(allIsFulfiled: boolean) => {
                          setAllIsFulfiled(allIsFulfiled);
                        }}
                      />
                    </div>
                    <Button
                      disabled={!allIsFulfiled || passwordConfirmation.length === 0}
                      className={cls(styles.setPasswordBtn)}
                      type="primary"
                      htmlType="submit"
                    >
                      {newPassword
                        ? "Establecer contraseña"
                        : "Reestablecer contraseña"}
                    </Button>
                  </Form>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
}
