import { Col, Row } from "antd";
import moment from "moment";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchCategories,
  selectCategoriesAreFetching,
  selectCategoriesDict,
} from "../../../features/category/categorySlice";
import {
  fetchCourses,
  selectAreCoursesFetching,
  selectCourses,
  selectCoursesDict,
} from "../../../features/courses/coursesSlice";
import {
  fetchLessons,
  IMedia,
  selectAreLessonsFetching,
  selectLessons,
} from "../../../features/lesson/lessonSlice";
import {
  fetchTeachers,
  selectAreTeachersFetching,
  selectTeachersDict,
} from "../../../features/teacher/teacherSlice";
import {
  fetchTopics,
  selectAreTopicsFetching,
  selectTopics,
  selectTopicsIds,
} from "../../../features/topic/topicSlice";
import { RoundedContainer } from "../../common/RoundedContainer";
import { StatusEnum } from "../../common/Status";
import { AnalyticsViewReport } from "../common/AnalyticsViewReport";
import { ReportTypes, sectionTitles } from "../MenuAndRoutes";
import { EducationalResourcesAvailability } from "./EducationalResourcesAvailability";
import { EducationalResourcesStats } from "./EducationalResourcesStats";
import { EducationalResourcesTable } from "./EducationalResourcesTable";

export enum FileType {
  IMAGE = "Imagen",
  VIDEO = "Video",
  PDF = "PDF",
}

const getMediaType = (media: IMedia[]) => {
  if (media.length === 0) {
    return "N/A";
  }
  return FileType[media[0].type];
};

export interface EducationalResource {
  id: string;
  type: "Lección" | "Tema" | "Curso";
  category: string;
  name: string;
  fileType: string;
  studyHours: number;
  status: StatusEnum;
  teacher: string;
  creationDate: string;
}

export const EducationalResourcesReport: React.FunctionComponent = () => {
  const lessons = useSelector(selectLessons);
  const courses = useSelector(selectCourses);
  const topics = useSelector(selectTopicsIds);
  const topicsDict = useSelector(selectTopics);
  const coursesDict = useSelector(selectCoursesDict);
  const categoriesDict = useSelector(selectCategoriesDict);
  const teachersDict = useSelector(selectTeachersDict);
  const areCategoriesFetching = useSelector(selectCategoriesAreFetching);
  const areTeachersFetching = useSelector(selectAreTeachersFetching);
  const areLessonsFetching = useSelector(selectAreLessonsFetching);
  const areCoursesFetching = useSelector(selectAreCoursesFetching);
  const areTopicsFetching = useSelector(selectAreTopicsFetching);
  const dispatch = useDispatch();

  const loading =
    areCategoriesFetching ||
    areTeachersFetching ||
    areLessonsFetching ||
    areCoursesFetching ||
    areTopicsFetching;
  const topicList = topics.map((topic) => topicsDict[topic]);
  const coursesList = courses.map((course) => coursesDict[course]);

  const resources: EducationalResource[] = [];
  lessons.forEach((lesson) => {
    const teacher = teachersDict[lesson.teacher as string];
    resources.push({
      id: lesson._id ?? "",
      type: "Lección",
      category: categoriesDict[lesson.category as string]?.name ?? "N/A",
      name: lesson.title,
      fileType: getMediaType(lesson.media),
      studyHours: 0,
      status: lesson.status ?? StatusEnum.Disable,
      teacher: teacher ? teacher.firstName + " " + teacher.lastName : "N/A",
      creationDate: lesson.createdAt
        ? moment(lesson.createdAt).format("DD/MM/YYYY")
        : "",
    });
  });

  topicList.forEach((topic) => {
    resources.push({
      id: topic._id ?? "",
      type: "Tema",
      category: categoriesDict[topic.category as string]?.name ?? "N/A",
      name: topic.title,
      fileType: "N/A",
      studyHours: 0,
      status: topic.status ?? StatusEnum.Disable,
      teacher: "N/A",
      creationDate: topic.createdAt
        ? moment(topic.createdAt).format("DD/MM/YYYY")
        : "",
    });
  });

  coursesList.forEach((course) => {
    resources.push({
      id: course._id ?? "",
      type: "Curso",
      category: categoriesDict[course.category as string]?.name ?? "N/A",
      name: course.title,
      fileType: "N/A",
      studyHours: course.durationHour,
      status: course.status ?? StatusEnum.Disable,
      teacher: "N/A",
      creationDate: course.createdAt
        ? moment(course.createdAt).format("DD/MM/YYYY")
        : "",
    });
  });

  useEffect(() => {
    dispatch(fetchCategories());
    dispatch(fetchTeachers());
    dispatch(fetchLessons());
    dispatch(fetchCourses());
    dispatch(fetchTopics());
  }, [dispatch]);

  return (
    <>
      <AnalyticsViewReport reportName={sectionTitles[ReportTypes.EducationalContent]} />
      <Row gutter={[24, 24]} style={{ marginBottom: 46 }}>
        <Col span={12}>
          <RoundedContainer>
            <EducationalResourcesAvailability
              lessons={lessons}
              topics={topicList}
              courses={coursesList}
            />
          </RoundedContainer>
        </Col>
        <Col span={12}>
          <RoundedContainer>
            <EducationalResourcesStats
              courses={coursesList}
              totalResources={resources.length.toString()}
              totalEnabledResources={resources
                .filter((resource) => resource.status === StatusEnum.Enable)
                .length.toString()}
            />
          </RoundedContainer>
        </Col>
        <Col span={24}>
          <EducationalResourcesTable resources={resources} loading={loading} />
        </Col>
      </Row>
    </>
  );
};
