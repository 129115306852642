import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Dispatch } from "react";
import { Microservices } from "../../app/AllowedMicroservices";
import { RootState } from "../../app/store";
import apiActionCreator, { HttpMethods } from "../../services/apiActionCreator";
import { FetchStatus } from "../common/enums";

export interface ITeacher {
  _id?: string;
  firstName: string;
  middleName: string;
  lastName: string;
  secondLastName: string;
  picture?: string;
  description: string;
}

interface ITeacherDict {
  [_id: string]: ITeacher;
}

interface TeacherState {
  list: ITeacher[];
  dict: ITeacherDict;
  status: FetchStatus;
}

const initialState: TeacherState = {
  list: [],
  dict: {},
  status: FetchStatus.Idle,
};

interface TeacherFetch {
  docs: ITeacher[];
  limit: number;
  page: number;
  pages: number;
  total: number;
}

export const teachersSlice = createSlice({
  name: "teachers",
  initialState,
  reducers: {
    creating: (state) => {
      state.status = FetchStatus.Creating;
    },
    fetching: (state) => {
      state.status = FetchStatus.Fetching;
    },
    fetched: (state, action: PayloadAction<TeacherFetch>) => {
      state.list = action.payload.docs;
      state.dict = {};
      action.payload.docs.forEach((teacher) => {
        state.dict[teacher?._id ?? ""] = teacher;
      });
      state.status = FetchStatus.Fetched;
    },
    error: (state, _action: PayloadAction<TeacherFetch>) => {
      state.list = [];
      state.status = FetchStatus.Error;
    },
  },
});

const { fetching, fetched, error } = teachersSlice.actions;
export const fetchTeachers = () => (dispatch: Dispatch<object>) => {
  return dispatch(
    apiActionCreator({
      endpoint: "/teachers?limit=1000000",
      types: {
        requestType: fetching,
        successTypes: [
          {
            actionOrCreator: fetched,
          },
        ],
        errorTypes: [
          {
            actionOrCreator: error,
          },
        ],
      },
      method: HttpMethods.GET,
      microservice: Microservices.LEARNING_SERVICE,
      authenticated: true,
    })
  );
};

export const selectTeachers = (state: RootState) => state.teachers.list;
export const selectTeachersDict = (state: RootState) => state.teachers.dict;
export const selectAreTeachersFetching = (state: RootState) =>
  state.teachers.status === FetchStatus.Fetching;
export const selectTeacher = (id: string) => (state: RootState) =>
  state.teachers.dict[id];

export default teachersSlice.reducer;
