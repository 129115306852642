import React, { ReactElement } from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import { Upload, message } from 'antd';
import { RcFile } from 'antd/es/upload/interface';
import ctx from 'classnames';

import {CogIcon} from '../CogIcon';
import { cognitoUser } from '../../../features/auth/authSlice';
import { Microservices } from '../../../app/AllowedMicroservices';
import { BASE_URL } from '../../../app/apiMiddleware';

import styles from './index.module.scss';

interface UploadButtonProps {
    text?: string,
    loading?: boolean
    image: ReactElement | boolean
}

interface UploadImageProps {
    text?: string,
    loading?: boolean,
    onHandleChange: any,
    imageUrl?: string,
}


function beforeUpload(file: RcFile) {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
        message.error('You can only upload JPG/PNG file!');
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
        message.error('Image must smaller than 2MB!');
    }
    return isJpgOrPng && isLt2M;
}

export const UploadButton: React.FunctionComponent<UploadButtonProps> = (props) => {
    const { text = "Cargar imagen", loading = false, image } = props;
    return <div className={styles.uploadButton}>
        <div className={ctx(styles.image, { [styles.loadedImage]: !!image })}>
            {loading ? <LoadingOutlined /> : (image ? image : <CogIcon color="white" icon="upload-image" />)}
        </div>
        <div className={styles.text}>{text}</div>
    </div>;
};

export const UploadImage: React.FunctionComponent<UploadImageProps> = (props) => {
    const { imageUrl, loading, onHandleChange: onChandleChange, text } = props;
    return <Upload
        headers={{
            Authorization: cognitoUser?.getSignInUserSession()?.getAccessToken().getJwtToken() || '',
        }}
        method="POST"
        name="file"
        listType="picture-card"
        className={styles.uploadImage}
        showUploadList={false}
        action={`${BASE_URL[Microservices.FILES_SERVICE]}/files/uploadToS3`}
        beforeUpload={beforeUpload}
        onChange={onChandleChange}
    >
        <UploadButton text={text} image={imageUrl ? <img src={imageUrl} alt="avatar" style={{ width: '100%' }} /> : false} loading={loading} />
    </Upload>;
};