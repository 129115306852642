import { Button, Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import moment from "moment";
import { RangeValue } from "rc-picker/es/interface";
import React, {
  FunctionComponent,
  ReactText,
  useEffect,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchCompanies,
  selectCompanies,
} from "../../features/company/companySlice";
import {
  fetchContentCategories,
  selectAreContentCategoriesFetching,
  selectContentCategoriesDict,
} from "../../features/contentCategory/contentCategorySlice";
import {
  fetchContents,
  IContent,
  pusblishContentStatus,
  selectContents,
  selectContentsDict,
  selectIsContentFetching,
  unpublishContentStatus,
} from "../../features/knowledgeBase/contentSlice";
import { PAGE_SIZE } from "../../utils/constants";
import { getDateFilterAndSortProps } from "../common/DateFilter";
import tableStyles from "../common/table.module.scss";
import { TableTools } from "../common/TableTools";
import { CompanyFilter } from "../Filters/Company";
import { useDebounce } from "../utils/useDebounce";
import { Status } from "./Status";

interface ContentListProps {
  editContent: Function;
}
export const ContentList: FunctionComponent<ContentListProps> = ({
  editContent,
}) => {
  const [selectedRowKeys, selectRowKeys] = useState<ReactText[]>([]);
  const [filterValue, setFilterValue] = useState<ReactText[]>([]);
  const [dateFilterValues, setDateFilterValues] = useState<
    RangeValue<moment.Moment>
  >();
  const isFetching = useSelector(selectIsContentFetching);
  const isFetchingCategories = useSelector(selectAreContentCategoriesFetching);
  const categories = useSelector(selectContentCategoriesDict);
  const companies = useSelector(selectCompanies);
  const contentsIdsList = useSelector(selectContents);
  const contentDict = useSelector(selectContentsDict);
  const [companyFilter, setCompanyFilter] = useState<ReactText[]>([]);
  const [showActivationBtns, setShowActivationBtns] = useState(false);
  const dispatch = useDispatch();
  const debounceFilterValue = useDebounce(filterValue, 500);
  const contentsList = contentsIdsList.map((contentId) => ({
    ...contentDict[contentId],
    categoryName: categories[contentDict[contentId].category as string]?.name,
    companyName: companies[contentDict[contentId].companyId as string]?.name,
  }));
  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedRowKeys: ReactText[]) => {
      selectRowKeys(selectedRowKeys);
    },
  };

  useEffect(() => {
    if (selectedRowKeys.length > 0) {
      setShowActivationBtns(true);
    } else {
      setShowActivationBtns(false);
    }
  }, [selectedRowKeys]);

  useEffect(() => {
    dispatch(fetchContents());
    dispatch(fetchCompanies());
    dispatch(fetchContentCategories());
  }, [dispatch]);

  const enableStatus = () => {
    const data = {
      ids: selectedRowKeys.map((key) => key.toString()),
    };
    dispatch(pusblishContentStatus(data));
  };

  const disableStatus = () => {
    const data = {
      ids: selectedRowKeys.map((key) => key.toString()),
    };
    dispatch(unpublishContentStatus(data));
  };

  const columns: ColumnsType<IContent> = [
    {
      title: "Titulo",
      dataIndex: "title",
      sortDirections: ["descend", "ascend"],
      defaultSortOrder: "ascend",
      ellipsis: true,
      sorter: (a: IContent, b: IContent) => a.title.localeCompare(b.title),
      filteredValue: debounceFilterValue,
      onFilter: (value, record) => {
        return (
          record.title.toLowerCase().includes(value.toString().toLowerCase()) ||
          record.categoryName
            ?.toLowerCase()
            .includes(value.toString().toLowerCase()) ||
          record._id?.toLowerCase().includes(value.toString().toLowerCase())
        );
      },
    },
    {
      title: "Categoria",
      dataIndex: "categoryName",
      sortDirections: ["descend", "ascend"],
      ellipsis: true,
      sorter: (a: IContent, b: IContent) =>
        (a.categoryName || "").localeCompare(b.categoryName || ""),
    },
    {
      title: "Estado",
      dataIndex: "published",
      render: (published) => {
        return <Status status={published} />;
      },
      sortDirections: ["descend", "ascend"],
      sorter: (a: IContent, b: IContent) =>
        a.published === b.published ? 0 : a.published > b.published ? 1 : -1,
    },
    {
      title: "Fecha",
      dataIndex: "createdAt",
      ...getDateFilterAndSortProps<IContent>(
        dateFilterValues,
        setDateFilterValues
      ),
      render: (createdAd) => {
        return moment(createdAd).format("DD-MM-YYYY");
      },
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Empresa",
      dataIndex: "companyName",
      sortDirections: ["descend", "ascend"],
      filteredValue: companyFilter,
      onFilter: (value, record: IContent) => {
        return record.companyId === value;
      },
      ellipsis: true,
      sorter: (a: IContent, b: IContent) =>
        (a.companyName || "").localeCompare(b.companyName || ""),
      render: (companyName, content) => {
        return (
          <React.Fragment>
            {companyName}
            <div className={tableStyles.actions}>
              <Button
                type="default"
                onClick={() => {
                  editContent(content._id);
                }}
              >
                Editar
              </Button>
            </div>
          </React.Fragment>
        );
      },
    },
  ];
  return (
    <React.Fragment>
      <TableTools
        placeholder="Buscar en Teurona"
        onSearch={(value) => setFilterValue([value])}
        onChange={(event) => setFilterValue([event.target.value])}
        onEnable={() => enableStatus()}
        onDisable={() => disableStatus()}
        activationBtnsVisible={showActivationBtns}
        filter={<CompanyFilter onApply={setCompanyFilter} />}
        enableLabel="Publicar"
        disableLabel="Despublicar"
      />
      <Table<IContent>
        loading={isFetchingCategories || isFetching}
        className={tableStyles.table}
        rowClassName={() => tableStyles.row}
        rowKey="_id"
        rowSelection={rowSelection}
        columns={columns}
        dataSource={contentsList}
        pagination={{ position: ["bottomRight"], pageSize: PAGE_SIZE }}
      />
    </React.Fragment>
  );
};
