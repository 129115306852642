import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  selectCompany,
  fetchCompanies,
} from "../../features/company/companySlice";
import { getCognitoUserData } from '../../features/auth/authSlice';
import { selectUser } from '../../features/users/usersSlice';
import { analytics } from '../../services/analytics';

export const AnalyticsCompanyDimSetter: React.FunctionComponent<{}> = () => {
  const cognitoUserData = useSelector(getCognitoUserData);
  const cognitoUsername = cognitoUserData ? cognitoUserData['cognito:username'] : null;
  const dispatch = useDispatch();

  const userFromStore = useSelector(selectUser(cognitoUsername));
  const companyId = userFromStore?.company ?? "";
  const company = useSelector(
    selectCompany(companyId)
  );

  useEffect(() => {
    if (company) {
      analytics.setCompanyCustomDimension({
        company: company,
      });
    }
  }, [company]);

  useEffect(() => {
    dispatch(fetchCompanies());
  }, [dispatch]);
  return null;
}