import React, { FunctionComponent, useEffect } from 'react';
import { Row, Col } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import { selectLessonsStatus } from '../../../../features/lesson/lessonSlice';
import { LearningType } from '../../../../features/reports/learning/common/interfaces';
import { fetchLessonsCompletion, selectLessonsFulfillments, selectLessonsFulfillmentsFetching } from '../../../../features/reports/learning/lessons/fulfillment';
import { fetchLessonsCompletionPerCompany, selectLessonsFulfillmentsPerCompany, selectLessonsFulfillmentsPerCompanyFetching } from '../../../../features/reports/learning/lessons/fulfillmentPerCompany';
import { CommonFetcher } from '../common/CommonFetcher';
import { LessonsIndicators } from './LessonsIndicators';
import { FulfillmentChart } from '../common/FulfillmentChart';
import { FulfillmentTop5Chart } from '../common/FulfillmentTop5';
import { FulfillmentInTop10 } from '../common/FulfillmentInTop10';
import { ContentTable } from '../common/ContentTable';
import { fetchQuizCounters, selectQuizResultsCounters, selectQuizResultsCountersIsFetching } from '../../../../features/reports/learning/lessons/quizResultsCounters';
import { selectAreUsersFetching, selectStudents, UserRole } from '../../../../features/users/usersSlice';
import { selectUserRole } from '../../../../features/auth/authSlice';
import { fetchLessonsInTop10, selectLessonsInTop10, selectLessonsInTop10Fetching } from '../../../../features/reports/learning/lessons/onTop10';
import { AnalyticsViewReport } from '../../common/AnalyticsViewReport';
import { ReportTypes, sectionTitles } from '../../MenuAndRoutes';

interface LessonsReportProps {
  companyId: string;
}

export const LessonsReport: FunctionComponent<LessonsReportProps> = ({companyId}) => {
  const students = useSelector(selectStudents);
  const isStudentFetching = useSelector(selectAreUsersFetching);
  const userRole = useSelector(selectUserRole);
  const dispatch = useDispatch();
  useEffect(()=>{
    if(!isStudentFetching){
      const usersIds = Object.keys(students).filter(userId => students[userId].enabled);
      dispatch(fetchQuizCounters({
        usersIds
      }));
    }
  }, [dispatch, isStudentFetching, students]);
  let FulfillmentTop10;
  if(userRole === UserRole.SuperAdmin){
    FulfillmentTop10 = <FulfillmentTop5Chart
      fetchLearningContentCompletionPerCompany={fetchLessonsCompletionPerCompany}
      selectFulfillmentsPerCompany={selectLessonsFulfillmentsPerCompany}
      selectFulfillmentsPerCompanyFetching={selectLessonsFulfillmentsPerCompanyFetching}
      learningType={LearningType.lessons}
    />
  }else{
    FulfillmentTop10 = <FulfillmentInTop10
      fetchLearningContentCompletionPerCompany={fetchLessonsCompletionPerCompany}
      fetchIsInTop10={fetchLessonsInTop10}
      selectFetchingInTop10={selectLessonsInTop10Fetching}
      selectIsInTop10={selectLessonsInTop10}
      learningType={LearningType.lessons}
    />
  }
  return <>
    <AnalyticsViewReport companyId={companyId} reportName={sectionTitles[ReportTypes.Lessons]} />
    <Row gutter={24}>
      <CommonFetcher />
      <Col span={12}>
        <FulfillmentChart
            companyId={companyId}
            fetchLearningContentStatus={selectLessonsStatus}
            fetchLearningContentCompletion={fetchLessonsCompletion}
            selectFulfillments={selectLessonsFulfillments}
            selectFulfillmentsFetching={selectLessonsFulfillmentsFetching}
            learningType={LearningType.lessons}
        />
      </Col>
      <Col span={12}>
        <Row style={{marginBottom: 24}}>
          <Col span={24}>
          <LessonsIndicators
            companyId={companyId}
            selectFulfillmentsPerCompany={selectLessonsFulfillmentsPerCompany}
            selectFulfillmentsPerCompanyFetching={selectLessonsFulfillmentsPerCompanyFetching}
          />
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            {FulfillmentTop10}
          </Col>
        </Row>
      </Col>
    </Row>
    <Row gutter={24}>
      <Col span={24}>
        <ContentTable 
          companyId={companyId}
          selectEvaluationsResultsCounters={selectQuizResultsCounters}
          selectEvaluationsResultsCountersIsFetching={selectQuizResultsCountersIsFetching}
          learningType={LearningType.lessons} 
          selectFulfillments={selectLessonsFulfillments}
          selectFulfillmentsFetching={selectLessonsFulfillmentsFetching}
        />
      </Col>
    </Row>
  </>
};