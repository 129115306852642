import { Card, Col, Layout, Row, Space } from "antd";
import moment from "moment";
import React, { FunctionComponent, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { setMessageError } from "../../../features/message/messageSlice";
import {
  fetchStudentCourse,
  selectStudentCourse,
  selectStudentCourseIsFetching,
  selectStudentCourseRules,
} from "../../../features/students/courseSlice";
import { StudentMainLayout } from "../../common/Layouts/StudentMainLayout";
import LoadingOverlay from "../../common/LoadingOverlay";
import { SIZES } from "../../common/screen";
import { useDeviceSize } from "../../utils/useDeviceSize";
import { TwoColumnLayout } from "../common/TwoColumnLayout";
import { StudentCourseAboutInfo } from "./CourseAboutInfo";
import { StudentCourseImage } from "./CourseImage";
import { StudentCourseInfo } from "./CourseInfo";
import { StudentCourseTopics } from "./CourseTopics";
import { StudentLessonInfo } from "./LessonInfo";
// For analytics
import { IProgram, } from '../../../features/program/programSlice';
import { analytics } from "../../../services/analytics";
import { selectCompany, fetchCompany, selectCompaniesAreFetching } from '../../../features/company/companySlice';
import { selectUser } from '../../../features/users/usersSlice';
import {
  getCognitoUserData,
} from "../../../features/auth/authSlice";
import { fetchGroups, selectAreGroupsFetching, IGroup, selectGroup } from "../../../features/groups/groupSlice";
// For analytics

export const StudentCourse: FunctionComponent = () => {
  const size = useDeviceSize();
  const isFetching = useSelector(selectStudentCourseIsFetching);
  const { id }: { id: string } = useParams();
  const courseRules = useSelector(selectStudentCourseRules);
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchStudentCourse(id));
  }, [dispatch, id]);

  if (isFetching) {
    return <LoadingOverlay spinning={true} />;
  }

  if (!isFetching && courseRules) {
    if (moment().isBefore(courseRules.startDate)) {
      dispatch(setMessageError({ message: "Esta curso no esta disponible" }));
      history.push("/myCourses");
    }
  }

  const image = <StudentCourseImage />;
  const lessonInfo = <StudentLessonInfo />;
  const aboutInfo = <StudentCourseAboutInfo />;
  const info = <StudentCourseInfo />;
  const topics = <StudentCourseTopics />;

  if (size === SIZES.DESKTOP) {
    return (
      <StudentMainLayout>
        <TwoColumnLayout
          firstColumnSpan={18}
          firstColumnContent={
            <React.Fragment>
              {image}
              {aboutInfo}
              {topics}
            </React.Fragment>
          }
          secondColumnContent={
            <React.Fragment>
              {lessonInfo}
              {info}
            </React.Fragment>
          }
        />
        <AnalyticsViewCourse />
      </StudentMainLayout>
    );
  }
  if (size === SIZES.TABLET) {
    return (
      <StudentMainLayout>
        <Layout>
          {image}
          <Row>
            <Col span={18} style={{ padding: "0 1em", position: "absolute" }}>
              <div style={{ position: "relative", top: "-20px" }}>
                <Row>
                  <Col span={17}>{lessonInfo}</Col>
                </Row>
              </div>
              {aboutInfo}
              {info}
              {topics}
            </Col>
          </Row>
        </Layout>
        <AnalyticsViewCourse />
      </StudentMainLayout>
    );
  }

  return (
    <React.Fragment>
      <Space direction="vertical" size="middle">
        {image}
        <StudentMainLayout>
          <Layout>
            <Space direction="vertical" size="middle">
              {lessonInfo}
              <Card>{aboutInfo}</Card>
              {info}
              {topics}
            </Space>
          </Layout>
        </StudentMainLayout>
      </Space>
      <AnalyticsViewCourse />
    </React.Fragment>
  );
};


interface AnalyticsViewCourseProps {
  // no props needs as long as this component gets called in this file
}

const AnalyticsViewCourse: React.FunctionComponent<AnalyticsViewCourseProps> = () => {
  const dispatch = useDispatch();
  const studentCourse = useSelector(selectStudentCourse);
  const isFetchingCourse = useSelector(selectStudentCourseIsFetching);
  const cognitoUserData = useSelector(getCognitoUserData);
  const cognitoUsername = cognitoUserData
    ? cognitoUserData["cognito:username"]
    : null;
  const userFromStore = useSelector(selectUser(cognitoUsername));
  const companyId = userFromStore?.company ?? "";
  const company = useSelector(
    selectCompany(companyId)
  );
  const isCompanyFetching = useSelector(selectCompaniesAreFetching);
  const group = useSelector(selectGroup(userFromStore?.studentGroupId || ''));
  const areGroupsFetching = useSelector(selectAreGroupsFetching);

  useEffect(() => {
    dispatch(fetchCompany(companyId));
    dispatch(fetchGroups());
  }, [dispatch, companyId]);

  useEffect(() => {
    if (
      studentCourse && !isFetchingCourse &&
      company && !isCompanyFetching &&
      group && !areGroupsFetching) {
      // TODO program is missing
      analytics.selectCourse({ course: studentCourse, program: {} as IProgram, company: company, group: group as IGroup })
    }
  }, [studentCourse, isFetchingCourse, company, isCompanyFetching, group, areGroupsFetching]);

  return null;
}
