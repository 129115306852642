import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import cls from 'classnames';
import { Header } from './Header';
import { Footer } from './Footer';
import { Button, Input } from 'antd';
import {
  Form,
  Col,
  Row
} from 'antd';

import './LoginOverrides.scss';
import styles from './Login.module.scss';

import {
  errorMessage,
  loginToCognito,
  newPasswordRequired,
  loggedIn,
  forgotPassword
} from '../../features/auth/authSlice';
import { setMessageError } from '../../features/message/messageSlice';
import { ContainerWithCarousel } from './ContainerWithCarousel';
import LoadingOverlay from '../common/LoadingOverlay';

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

export function Login() {
  const [email, setEmail] = useState<string>('');
  const dispatch = useDispatch();
  const history = useHistory();
  const newPassword = useSelector(newPasswordRequired);
  const isLoggedIn = useSelector(loggedIn);
  const loginErrorMessage = useSelector(errorMessage);

  useEffect(
    () => {
      if (newPassword) {
        history.push('/finishRegistration');
      }
    }, [history, isLoggedIn, newPassword]
  );
  if (isLoggedIn) {
    history.push('/welcome');
    return <LoadingOverlay spinning />;
  }

  return (
    <React.Fragment>
      <ContainerWithCarousel>
        <Form
          {...layout}
          name="basic"
          initialValues={{ remember: true }}
          onFinish={(values: { email: string, password: string }) => {
            const { email, password } = values;
            dispatch(loginToCognito(email, password, history as any));
          }}
          onFinishFailed={() => { dispatch(setMessageError({ message: 'Hubo un error' })) }}
        >
          <Col span={24} className={cls(styles.formInnerContainer, styles.overload)}>
            <Row>
              <Col span={18} offset={3}>
                <Header title={'¡Bienvenido!'}>
                  <div>Nos emociona trabajar contigo.</div>
                  <div>Introduce tus credenciales para comenzar.</div>
                </Header>
                <Form.Item
                  label="Email"
                  name="email"
                  rules={[{
                    required: true,
                    type: "email",
                    message: "Por favor ingrese un email válido!"
                  }
                  ]}
                  labelCol={{ span: 24 }}
                  wrapperCol={{ span: 24 }}
                >
                  <Input placeholder="YourName@cognizant.com" onChange={(event) => {
                    setEmail(event.target.value)
                  }} />
                </Form.Item>
                <Form.Item
                  label="Contraseña"
                  name="password"
                  rules={[{ required: true, message: 'Por favor ingrese la contraseña!' }]}
                  labelCol={{ span: 24 }}
                  wrapperCol={{ span: 24 }}
                >
                  <Input.Password />
                </Form.Item>
                <div className={cls('error')}>{loginErrorMessage}</div>
                <Button
                  type='primary'
                  htmlType='submit'
                  disabled={false}
                  className={cls(styles.loginBtn)}
                >
                  Ingresar
                </Button>
                <Button
                  loading={false}
                  disabled={false}
                  type="link"
                  key="changePassword"
                  className={styles.loginBtn}
                  onClick={() => {
                    if (email === '') {
                      dispatch(setMessageError({ message: 'Debes colocar un correo' }));
                    }
                    dispatch(forgotPassword(email));
                  }}
                >
                  ¿Olvidaste tu contraseña?
                </Button>
              </Col>
            </Row>
            <Footer title={'¿Aún no tienes cuenta?'} linkLabel={'Registrate'} linkRef={'/signup'} />
          </Col>
        </Form>
      </ContainerWithCarousel>
    </React.Fragment>
  );
}
