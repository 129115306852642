import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Dispatch } from "react";
import { Microservices } from "../../../app/AllowedMicroservices";
import { RootState } from "../../../app/store";
import apiActionCreator, {
  HttpMethods,
} from "../../../services/apiActionCreator";
import { FetchStatus } from "../../common/enums";
import { IUser } from "../../users/usersSlice";

const EMPTY_USER_STATS = {
  users: [],
  topFive: [],
  companiesWithUserUsage: [],
};

interface GAUser {
  id: string;
  session: number;
}

interface UserSessionData {
  gausers: GAUser[];
}

export interface Stats {
  companyId: string;
  avgActiveUserPercentage: number;
}

interface UserStats {
  users: IUser[];
  topFive: Stats[];
  companiesWithUserUsage: Stats[],
}

interface InitialStateData {
  data: UserStats;
  status: FetchStatus;
}

const initialState: InitialStateData = {
  data: EMPTY_USER_STATS,
  status: FetchStatus.Idle,
};

export const dataSlice = createSlice({
  name: "userStats",
  initialState,
  reducers: {
    fetching: (state) => {
      state.status = FetchStatus.Fetching;
    },
    fetched: (state, action: PayloadAction<UserStats>) => {
      state.data = action.payload;
      state.status = FetchStatus.Fetched;
    },
    error: (state) => {
      state.data = EMPTY_USER_STATS;
      state.status = FetchStatus.Error;
    },
  },
});

export const { fetching, fetched, error } = dataSlice.actions;

export const fetchUserStats =
  (users: UserSessionData) => (dispatch: Dispatch<object>) => {
    return dispatch(
      apiActionCreator({
        endpoint: "/companies/userStats",
        types: {
          requestType: fetching,
          successTypes: [
            {
              actionOrCreator: fetched,
            },
          ],
          errorTypes: [
            {
              actionOrCreator: error,
            },
          ],
        },
        method: HttpMethods.POST,
        microservice: Microservices.COMPANY_SERVICE,
        authenticated: true,
        data: users,
      })
    );
  };

export const selectUserStats = (state: RootState) =>
  state.reports.company.userStats.data;
export const selectUserStatsAreFetching = (state: RootState) =>
  state.reports.company.userStats.status === FetchStatus.Fetching;

export default dataSlice.reducer;
