import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Dispatch } from "react";
import { Microservices } from "../../app/AllowedMicroservices";
import { RootState } from "../../app/store";
import apiActionCreator, { HttpMethods } from "../../services/apiActionCreator";
import { ICategoriesDict, ICategory } from "../category/categorySlice";
import { FetchStatus } from "../common/enums";

interface ContentCategoryState {
  list: string[];
  dict: ICategoriesDict;
  status: FetchStatus;
}

const initialState: ContentCategoryState = {
  list: [],
  dict: {},
  status: FetchStatus.Idle,
};

interface ContentCategoryFetch {
  docs: ICategory[];
  limit: number;
  page: number;
  pages: number;
  total: number;
}

export const contentCategorySlice = createSlice({
  name: "contentCategories",
  initialState,
  reducers: {
    fetching: (state) => {
      state.status = FetchStatus.Fetching;
    },
    fetched: (state, action: PayloadAction<ContentCategoryFetch>) => {
      state.list = action.payload.docs.map((category) => category._id);
      state.dict = {};
      action.payload.docs.forEach((category) => {
        state.dict[category._id] = category;
      });
      state.status = FetchStatus.Fetched;
    },
    error: (state, _action: PayloadAction<ContentCategoryFetch>) => {
      state.list = [];
      state.status = FetchStatus.Error;
    },
  },
});

export const { fetching, fetched, error } = contentCategorySlice.actions;

export const fetchContentCategories = () => (dispatch: Dispatch<object>) => {
  return dispatch(
    apiActionCreator({
      endpoint: "/categories?limit=1000000",
      types: {
        requestType: fetching,
        successTypes: [
          {
            actionOrCreator: fetched,
          },
        ],
        errorTypes: [
          {
            actionOrCreator: error,
          },
        ],
      },
      method: HttpMethods.GET,
      microservice: Microservices.KNOWLEDGE_BASE_SERVICE,
      authenticated: true,
    })
  );
};

export const selectContentCategories = (state: RootState) =>
  state.contentCategories.list;
export const selectContentCategoriesDict = (state: RootState) =>
  state.contentCategories.dict;
export const selectAreContentCategoriesFetching = (state: RootState) =>
  state.contentCategories.status === FetchStatus.Fetching;
export const selectContentCategory = (id: string) => (state: RootState) =>
  state.contentCategories.dict[id];

export default contentCategorySlice.reducer;
