import React, { useState, FunctionComponent, useEffect } from 'react';
import { Form, Col, Row, Button, Input, DatePicker, Select } from 'antd';
import cls from 'classnames';
import moment from 'moment';

import { Header } from '../Header';

import styles from './index.module.scss';
import commonStyles from '../Login.module.scss';
import { ISignUpStepProps, layout } from './interfaces';
import { GenderEnum } from '../../../features/users/usersSlice';
import { genders, identificationTypes } from '../../Users/Form';
import { dateFormatList } from '../../../utils/constants';
import { extractDate } from '../../utils/convertions';
import { isCIValid } from '../../../utils/validation';
import { IdTypes } from '../../Users/common/identification';

interface Step2Props extends ISignUpStepProps {

};

export const Step3: FunctionComponent<Step2Props> = ({ next, userData }) => {
  const [gender, setGender] = useState<GenderEnum|undefined>();
  const [birthdate, setBirthdate] = useState<moment.Moment|null>();
  const [nationalIdType, setNationalIdType] = useState<string>('');
  const [nationalId, setNationalId] = useState<string>('');
  useEffect(() => {
  }, []);
  const handleSubmit = () => {
    form.validateFields().then(()=>{
      next({
        birthdate: extractDate(birthdate as moment.Moment),
        gender,
        nationalIdType,
        nationalId
      });
  });
  };

  useEffect(() => {
    setGender(userData.gender);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const [form] = Form.useForm();
  return (
    <Form
      {...layout}
      form={form}
      name="basic"
      onFinish={() => {
        handleSubmit();
      }}
      onFinishFailed={() => {
        console.log('signup failed');
      }}
      
    >
      <Col
        span={24}
        className={cls(commonStyles.formInnerContainer, commonStyles.overload)}
      >
        <Row>
          <Col span={18} offset={3}>
            <Header title={'Queremos conocerte'}>
              <div className={styles.subTitles}>
              No te preocupes, esta información la usaremos solo como datos de registro.
              </div>
            </Header>
            <Form.Item
              label="Genero"
              name="gender"
              rules={[{ required: true, message: 'email@compania.com' }]}
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              initialValue={gender}
            >
              <Select
                placeholder="Selecciona un genero"
                onChange={(value) => {
                  setGender(value as GenderEnum);
                }}
              >
                {genders.map((item, index) => (
                  <Select.Option key={index} value={item.value}>
                    {item.label}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              label="Fecha de nacimiento"
              name="birthday"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              initialValue={birthdate}
              rules={[
                () => ({
                  validator: async (rules, value) => {
                    const today = moment(new Date());
                    const years = today.diff(value, "years");
                    if (years < 18 || value === null || isNaN(years)) {
                      throw new Error("La edad mínima será 18 años");
                    }
                  },
                }),
              ]}
            >
              <DatePicker
                placeholder="DD/MM/AA"
                onChange={(value) => {
                  setBirthdate(value);
                }}
                format={dateFormatList}
              />
            </Form.Item>
            <Form.Item
              label="Tipo de identificación"
              name="nationalIdType"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              initialValue={nationalIdType}
              rules={[
                { type: "string", required: true, message: "Seleccione un tipo" },
              ]}
            >
              <Select
                placeholder="Selecciona un tipo de identificación"
                onChange={(value) => {
                  setNationalIdType(value as string);
                }}
              >
                {identificationTypes.map((item, index) => (
                  <Select.Option key={index} value={item.value}>
                    {item.label}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              label="Número de identificación."
              name="nationalId"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              initialValue={nationalId}
              rules={[
                { type: "string", required: true, message: "Requerido" },
                ({ getFieldValue }) => ({
                  validator: async (rules, value: string) => {
                    const nationalIdType = getFieldValue("nationalIdType");
                    if (nationalIdType === undefined) {
                      return;
                    }
                    if (nationalIdType === IdTypes.id && !isCIValid(value)) {
                      throw new Error("La cédula debe ser válida");
                    }
                  },
                }),
              ]}
            >
              <Input
                disabled={!nationalIdType}
                onChange={(event) => {
                  setNationalId(event.target.value);
                }}
              />
            </Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              disabled={false}
              className={cls(commonStyles.signupBtn, styles.signupBtn2)}
            >
              Continuar
            </Button>
          </Col>
        </Row>
      </Col>
    </Form>
  );
};
