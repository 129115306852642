import React, { FunctionComponent } from 'react';
import { useSelector } from 'react-redux';
import ctx from 'classnames';

import {
    selectStudentCourseImage
} from '../../../features/students/courseSlice';

import styles from './courseImage.module.scss';
import { useDeviceSize } from '../../utils/useDeviceSize';
import { SIZES } from '../../common/screen';

interface StudentCourseProps { }

export const StudentCourseImage: FunctionComponent<StudentCourseProps> = () => {
    const size = useDeviceSize()
    const imageurl = useSelector(selectStudentCourseImage);
    if (!imageurl) {
        return null;
    }
    return <div className={ctx(styles.container)}>
        <img
            className={ctx(styles.image, {
                [styles.mobile]: size === SIZES.PHONE,
            })}
            src={imageurl}
            alt="Course"
        />
    </div>
}
