import { DatePicker, Form, Input, Select, Switch } from "antd";
import { FormInstance } from "antd/lib/form";
import moment from "moment";
import phoneValidator from "phone";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getCognitoUserData } from "../../features/auth/authSlice";
import { FetchStatus } from "../../features/common/enums";
import {
  GenderDict,
  IUser,
  RolesDict,
  selectUser,
  selectUsersFetchStatus,
  UserRole,
  UsersGroups,
} from "../../features/users/usersSlice";
import { dateFormatList } from "../../utils/constants";
import { isCIValid } from "../../utils/validation";
import { CompanyField } from "../common/CompanyField";
import { GroupField } from "../common/GroupField";
import { StudentGroupField } from "../common/SudentGroupField";
import { UploadImage } from "../common/UploadImage";
import { IdTypes } from "./common/identification";
import styles from "./students.module.scss";
import countriesJson from '../../assets/countries/countries.json'; 
import { getUniqueNamesCities } from "../common/cities.utils";

export interface ISelectValue {
  value: string;
  label: string;
}
const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};
const roles = [
  {
    value: UserRole.SuperAdmin,
    label: RolesDict[UserRole.SuperAdmin],
  },
  {
    value: UserRole.CompanyAdmin,
    label: RolesDict[UserRole.CompanyAdmin],
  },
  {
    value: UserRole.Supervisor,
    label: RolesDict[UserRole.Supervisor],
  },
];

enum Gender {
  male = "MALE",
  female = "FEMALE",
}
export const genders = [
  {
    value: Gender.female,
    label: GenderDict[Gender.female],
  },
  {
    value: Gender.male,
    label: GenderDict[Gender.male],
  },
];

export const identificationTypes = [
  {
    value: IdTypes.id,
    label: "Cédula",
  },
  {
    value: IdTypes.passport,
    label: "Pasaporte",
  },
];

export const Countries: ISelectValue[] = countriesJson.map(country => {
  return {
    value: country.iso,
    label: country.name,
  }
});

export const MIN_3_CHAR = "Mínimo 3 caracteres";

interface UserFormProps {
  user?: IUser;
  setUser: Dispatch<SetStateAction<IUser | undefined>>;
  formId: string;
  adminOptions: boolean;
  isCreating: boolean;
  form: FormInstance;
  isStudent?: boolean;
}
export const UserForm: React.FunctionComponent<UserFormProps> = (props) => {
  const {
    setUser,
    user: newUser,
    formId,
    adminOptions = false,
    isCreating = false,
    form,
    isStudent = false,
  } = props;
  const fetchStatus = useSelector(selectUsersFetchStatus);
  const fetchedUser = useSelector(selectUser(newUser?.username || ""));
  const [loadingImage, setLoadingImage] = useState<boolean>(false);
  const [isAdminToo, setIsAdminToo] = useState<boolean>(false);
  const [isStudentToo, setIsStudentToo] = useState<boolean>(false);
  const cognitoUserData = useSelector(getCognitoUserData);
  const userRole = cognitoUserData ? cognitoUserData["custom:role"] : null;
  const [cities, setCities] = useState<ISelectValue[]>([]);

  useEffect(() => {
    (async() => {
      if (newUser?.country) {
        const countryCities:any = await import(`../../assets/countries/cities/${newUser?.country}.json`);
        const cities:ISelectValue[] = getUniqueNamesCities(countryCities);
        setCities(cities);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])
  useEffect(() => {
    if (newUser?.status && fetchStatus === FetchStatus.Fetched) {
      let isAdminToo = false;
      let isStudentToo = false;
      if (fetchedUser.groups.includes(UsersGroups.Administrators)) {
        form.setFieldsValue({
          groups: fetchedUser.groups,
          isAdminToo: true,
        });
        isAdminToo = true;
        setIsAdminToo(true);
      }
      if (fetchedUser.groups.includes(UsersGroups.Students)) {
        form.setFieldsValue({
          groups: fetchedUser.groups,
          isStudentToo: true,
        });
        isStudentToo = true;
        setIsStudentToo(true);
      }
      setUser(
        Object.assign({}, fetchedUser, {
          groups: fetchedUser.groups,
          isAdminToo,
          isStudentToo,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchStatus, fetchedUser]);

  const handleChange = (info: any) => {
    if (info.file.status === "uploading") {
      setLoadingImage(true);
      return;
    }
    if (info.file.status === "done") {
      setLoadingImage(false);
      setUser(Object.assign({}, newUser, { picture: info.file.response.url }));
    }
  };
  const birthdate = moment.utc(newUser?.birthdate);

  return (
    <Form
      {...formItemLayout}
      form={form}
      name="register"
      layout="vertical"
      scrollToFirstError
      id={formId}
    >
      {!isStudent ? (
        <React.Fragment>
          <Form.Item initialValue={newUser?.picture} wrapperCol={{ sm: 24 }}>
            <div className={styles.adminUserSwitch}>
              <div>Usuario estudiante</div>
              <div>
                <Switch
                  checked={isStudentToo}
                  defaultChecked
                  onChange={(value) => {
                    setUser(
                      Object.assign({}, newUser, { isStudentToo: value })
                    );
                    setIsStudentToo(value);
                  }}
                />
              </div>
            </div>
          </Form.Item>
        </React.Fragment>
      ) : null}
      {isStudent &&
      [UserRole.SuperAdmin, UserRole.CompanyAdmin].includes(userRole) ? (
        <React.Fragment>
          <Form.Item initialValue={newUser?.picture} wrapperCol={{ sm: 24 }}>
            <div className={styles.adminUserSwitch}>
              <div>Usuario administrativo</div>
              <div>
                <Switch
                  checked={isAdminToo}
                  defaultChecked
                  onChange={(value) => {
                    setUser(Object.assign({}, newUser, { isAdminToo: value }));
                    setIsAdminToo(value);
                  }}
                />
              </div>
            </div>
          </Form.Item>
        </React.Fragment>
      ) : null}
      <Form.Item
        name="picture"
        initialValue={newUser?.picture}
        wrapperCol={{ sm: 24 }}
      >
        <UploadImage
          text="Cargar foto"
          loading={loadingImage}
          imageUrl={newUser?.picture}
          onHandleChange={handleChange}
        />
      </Form.Item>
      {adminOptions ? (
        <React.Fragment>
          <Form.Item
            name="role"
            label="Rol del Usuario"
            initialValue={
              newUser?.role && RolesDict[newUser?.role]
                ? RolesDict[newUser?.role]
                : null
            }
            wrapperCol={{ sm: 24 }}
            rules={[
              { type: "string", required: true, message: "Seleccione un rol" },
            ]}
          >
            <Select
              placeholder="Selecciona el rol del usuario"
              onChange={(value) => {
                setUser(Object.assign({}, newUser, { role: value }));
              }}
            >
              {roles
                .filter(
                  (role) =>
                    userRole === UserRole.SuperAdmin ||
                    role.value === UserRole.Supervisor
                )
                .map((item, index) => (
                  <Select.Option key={index} value={item.value}>
                    {item.label}
                  </Select.Option>
                ))}
            </Select>
          </Form.Item>
        </React.Fragment>
      ) : null}
      {newUser?.role !== UserRole.SuperAdmin && (
        <CompanyField
          newObject={newUser}
          value={newUser?.company}
          setNewObject={setUser}
        />
      )}
      {newUser?.role === UserRole.Supervisor ? (
        <GroupField
          newObject={newUser}
          value={newUser?.groupId}
          setNewObject={setUser}
          companyId={newUser?.company}
        />
      ) : null}
      {isStudent ? (
        <StudentGroupField
          newObject={newUser}
          value={newUser?.studentGroupId}
          setNewObject={setUser}
          companyId={newUser?.company}
          form={form}
        />
      ) : null}
      <Form.Item
        initialValue={newUser?.given_name}
        label="Primer nombre"
        name="given_name"
        required
        wrapperCol={{ sm: 24 }}
        rules={[
          { type: "string", required: true, message: "Requerido" },
          { type: "string", max: 15, message: "Máximo 15 caracteres" },
          { type: "string", min: 3, message: MIN_3_CHAR },
          {
            validator: (rule, value: string = "", callback) => {
              if (value.match(/\d/)) {
                callback("No se permiten números");
                return;
              }
              callback();
            },
          },
        ]}
      >
        <Input
          placeholder="Primer nombre"
          onChange={(event) => {
            setUser(
              Object.assign({}, newUser, { given_name: event.target.value })
            );
          }}
        />
      </Form.Item>
      <Form.Item
        initialValue={newUser?.middle_name}
        label="Segundo nombre"
        name="middle_name"
        wrapperCol={{ sm: 24 }}
        rules={[
          { type: "string", max: 15, message: "Máximo 15 caracteres" },
          { type: "string", min: 3, message: MIN_3_CHAR },
          {
            validator: (rule, value: string = "", callback) => {
              if (value.match(/\d/)) {
                callback("No se permiten números");
                return;
              }
              callback();
            },
          },
        ]}
      >
        <Input
          placeholder="Segundo nombre"
          onChange={(event) => {
            setUser(
              Object.assign({}, newUser, { middle_name: event.target.value })
            );
          }}
        />
      </Form.Item>
      <Form.Item
        initialValue={newUser?.family_name}
        label="Primer apellido"
        name="family_name"
        required
        wrapperCol={{ sm: 24 }}
        rules={[
          { type: "string", required: true, message: "Requerido" },
          { type: "string", max: 15, message: "Máximo 15 caracteres" },
          { type: "string", min: 3, message: MIN_3_CHAR },
          {
            validator: (rule, value: string = "", callback) => {
              if (value.match(/\d/)) {
                callback("No se permiten números");
                return;
              }
              callback();
            },
          },
        ]}
      >
        <Input
          placeholder="Primer apellido"
          onChange={(event) => {
            setUser(
              Object.assign({}, newUser, { family_name: event.target.value })
            );
          }}
        />
      </Form.Item>
      <Form.Item
        initialValue={newUser?.secondLastname}
        label="Segundo apellido"
        name="secondLastname"
        wrapperCol={{ sm: 24 }}
        rules={[
          { type: "string", max: 15, message: "Máximo 15 caracteres" },
          { type: "string", min: 3, message: MIN_3_CHAR },
          {
            validator: (rule, value: string = "", callback) => {
              if (value.match(/\d/)) {
                callback("No se permiten números");
                return;
              }
              callback();
            },
          },
        ]}
      >
        <Input
          placeholder="Segundo apellido"
          onChange={(event) => {
            setUser(
              Object.assign({}, newUser, { secondLastname: event.target.value })
            );
          }}
        />
      </Form.Item>
      <Form.Item
        initialValue={newUser?.email}
        label="Correo electrónico"
        name="email"
        required
        wrapperCol={{ sm: 24 }}
        rules={[
          { type: "string", required: true, message: "Requerido" },
          { type: "email", message: "Debe ser un correo electrónico" },
        ]}
      >
        <Input
          disabled={newUser?.status === "CONFIRMED"}
          placeholder="Correo electrónico"
          onChange={(event) => {
            setUser(Object.assign({}, newUser, { email: event.target.value }));
            if (isCreating) {
              setUser(
                Object.assign({}, newUser, { username: event.target.value })
              );
            }
          }}
        />
      </Form.Item>
      <Form.Item
        initialValue={newUser?.gender}
        name="gender"
        label="Género"
        wrapperCol={{ sm: 24 }}
        rules={[
          { type: "string", required: true, message: "Seleccione un género" },
        ]}
      >
        <Select
          placeholder="Selecciona un genero"
          onChange={(value) => {
            setUser(Object.assign({}, newUser, { gender: value }));
          }}
        >
          {genders.map((item, index) => (
            <Select.Option key={index} value={item.value}>
              {item.label}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item
        initialValue={
          isCreating
            ? null
            : birthdate.isValid()
            ? birthdate
            : null
        }
        label="Fecha de nacimiento"
        name="birthdate"
        required
        wrapperCol={{ sm: 24 }}
        rules={[
          () => ({
            validator: async (rules, value) => {
              const today = moment(new Date());
              const years = today.diff(value, "years");
              if (years < 18 || value === null || isNaN(years)) {
                throw new Error("La edad mínima será 18 años");
              }
            },
          }),
        ]}
      >
        <DatePicker
          placeholder="DD/MM/AA"
          onChange={(value) => {
            setUser(Object.assign({}, newUser, { birthdate: value }));
          }}
          format={dateFormatList}
        />
      </Form.Item>
      <Form.Item
        initialValue={newUser?.phone_number}
        label="Número de celular"
        name="phone_number"
        required
        wrapperCol={{ sm: 24 }}
        rules={[
          (obj) => ({
            validator: async (rules, value) => {
              const phone = phoneValidator(value);
              if (phone.length === 0) {
                throw new Error(
                  "El telefono debe ser válido (debe tener el codigo de país (ej: +593)"
                );
              }
            },
          }),
        ]}
      >
        <Input
          placeholder="Número de celular"
          onChange={(event) => {
            setUser(
              Object.assign({}, newUser, { phone_number: event.target.value })
            );
          }}
        />
      </Form.Item>
      <Form.Item
        initialValue={newUser?.nationalIdType}
        name="nationalIdType"
        label="Tipo de identificación"
        wrapperCol={{ sm: 24 }}
        rules={[
          { type: "string", required: true, message: "Seleccione un tipo" },
        ]}
      >
        <Select
          placeholder="Selecciona un tipo de identificación"
          onChange={(value) => {
            setUser(Object.assign({}, newUser, { nationalIdType: value }));
          }}
        >
          {identificationTypes.map((item, index) => (
            <Select.Option key={index} value={item.value}>
              {item.label}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        initialValue={newUser?.nationalId}
        label="Número de identificación"
        name="nationalId"
        required
        wrapperCol={{ sm: 24 }}
        rules={[
          { type: "string", required: true, message: "Requerido" },
          ({ getFieldValue }) => ({
            validator: async (rules, value: string) => {
              const nationalIdType = getFieldValue("nationalIdType");
              if (nationalIdType === undefined) {
                return;
              }
              if (nationalIdType === IdTypes.id && !isCIValid(value)) {
                throw new Error("La cédula debe ser válida");
              }
            },
          }),
        ]}
      >
        <Input
          disabled={!newUser?.nationalIdType}
          placeholder="00 000 0000"
          onChange={(event) => {
            setUser(
              Object.assign({}, newUser, { nationalId: event.target.value })
            );
          }}
        />
      </Form.Item>
      <Form.Item
        initialValue={newUser?.position}
        label="Cargo o posición"
        name="position"
        required
        wrapperCol={{ sm: 24 }}
        rules={[
          { type: "string", required: true, message: "Requerido" },
          { type: "string", min: 3, message: MIN_3_CHAR },
          { type: "string", max: 50, message: "Máximo 50 caracteres" },
        ]}
      >
        <Input
          placeholder="Director/a de marketing"
          onChange={(event) => {
            setUser(
              Object.assign({}, newUser, { position: event.target.value })
            );
          }}
        />
      </Form.Item>
      <Form.Item
        initialValue={newUser?.country}
        name="country"
        label="País"
        wrapperCol={{ sm: 24 }}
        rules={[
          { type: "string", required: true, message: "Selecciona un país" },
        ]}
      >
        <Select
          showSearch
          filterOption={(input, option) => {
            return (option?.children?.toString().toLowerCase() ?? '').includes(`${input}`.toLowerCase());
          }}
          filterSort={(optionA, optionB) =>
            {
              return (optionA?.children?.toString() ?? '').toLowerCase().localeCompare((optionB?.children?.toString() ?? '').toLowerCase());
            }
          }
          placeholder="Selecciona un país"
          onChange={async (value) => {
            setUser(Object.assign({}, newUser, { country: value, city: null }));
            form.setFieldsValue({
              city: null,
            });
            const countryCities:any = await import(`../../assets/countries/cities/${value}.json`);
            const cities:ISelectValue[] = getUniqueNamesCities(countryCities);
            setCities(cities);
          }}
        >
          {Countries.map((item) => (
            <Select.Option key={item.value} value={item.value}>
              {item.label}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        initialValue={newUser?.city}
        name="city"
        label="Ciudad"
        wrapperCol={{ sm: 24 }}
        rules={[
          { type: "string", required: true, message: "Selecciona una ciudad" },
        ]}
      >
        <Select
          placeholder="Selecciona una ciudad"
          onChange={(value) => {
            setUser(Object.assign({}, newUser, { city: value }));
          }}
        >
          {cities.map((item) => (
            <Select.Option key={item.value} value={item.value}>
              {item.label}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        initialValue={newUser?.address}
        name="address"
        label="Dirección"
        wrapperCol={{ sm: 24 }}
        rules={[
          { type: "string", required: true, message: "Requerido" },
          { type: "string", max: 200, message: "Máximo 200 caracteres" },
          { type: "string", min: 3, message: MIN_3_CHAR },
        ]}
      >
        <Input.TextArea
          onChange={(event) => {
            setUser(
              Object.assign({}, newUser, { address: event.target.value })
            );
          }}
        />
      </Form.Item>
    </Form>
  );
};
