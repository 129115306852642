import React, { FunctionComponent } from 'react';
import cls from 'classnames';
import styles from './namePlusThumbnail.module.scss';
import { IUser } from '../../../features/users/usersSlice';
import defaultImg from '../../../assets/profile/profile-placeholder.png';

interface NamePlusThumbnailProps {
  value: string,
  user: IUser,
}

export const NamePlusThumbnail: FunctionComponent<NamePlusThumbnailProps> = ({value, user}) => {
  const { picture = defaultImg } = user;
  return(
    <div className={cls(styles.fullName)}>
      <img src={picture} className={cls(styles.image)} alt="thumbnail"></img>
      <div className={cls(styles.label)}>{value}</div>
    </div>
  );
}
