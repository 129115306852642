import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Dispatch } from "react";
import { Microservices } from "../../../app/AllowedMicroservices";
import { RootState } from "../../../app/store";
import apiActionCreator, {
  HttpMethods,
} from "../../../services/apiActionCreator";
import { FetchStatus } from "../../common/enums";

interface Company {
  companyId: string;
  session: number;
}

interface InitialStateData {
  data: Array<Company>;
  status: FetchStatus;
}

const initialState: InitialStateData = {
  data: [],
  status: FetchStatus.Idle,
};

export const dataSlice = createSlice({
  name: "companySessionDuration",
  initialState,
  reducers: {
    fetching: (state) => {
      state.status = FetchStatus.Fetching;
    },
    fetched: (state, action: PayloadAction<Array<Company>>) => {
      state.data = action.payload;
      state.status = FetchStatus.Fetched;
    },
    error: (state) => {
      state.data = [];
      state.status = FetchStatus.Error;
    },
  },
});

export const { fetching, fetched, error } = dataSlice.actions;

export const fetchCompanySessionDuration =
  (companyId?: string) => (dispatch: Dispatch<object>) => {
    return dispatch(
      apiActionCreator({
        endpoint: `/company/sessions${companyId ? "/" + companyId : ""}`,
        types: {
          requestType: fetching,
          successTypes: [
            {
              actionOrCreator: fetched,
            },
          ],
          errorTypes: [
            {
              actionOrCreator: error,
            },
          ],
        },
        method: HttpMethods.GET,
        microservice: Microservices.ANALYTICS_SERVICE,
        authenticated: true,
      })
    );
  };

export const selectCompanySessionDuration = (state: RootState) =>
  state.reports.company.sessionDuration.data;
export const selectCompanySessionDurationIsFetching = (state: RootState) =>
  state.reports.company.sessionDuration.status === FetchStatus.Fetching;

export default dataSlice.reducer;
