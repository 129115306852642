import React from "react";
import { UserReportData } from "..";
import { IconNames } from "../../common/SimpleStat";
import { StatsRow } from "../../common/StatsRow";

interface Props {
  registerUsersAmount: number;
  enabledUsersAmount: number;
  activeUsers: UserReportData[];
}

export const UserStatsChart: React.FunctionComponent<Props> = ({
  activeUsers,
  enabledUsersAmount,
  registerUsersAmount,
}) => {
  const avgSession =
    activeUsers.length > 0
      ? activeUsers.reduce(
          (total: number, user) => total + user.sessionDuration,
          0
        ) / activeUsers.length
      : 0;

  const stats = [
    {
      description: "Usuarios registrados",
      iconName: IconNames.User,
      mainText: registerUsersAmount.toString(),
      tooltipText: "Información desde el primer día",
    },
    {
      description: "Usuarios habilitados",
      iconName: IconNames.UserEnabled,
      mainText: enabledUsersAmount.toString(),
      tooltipText: "Información desde el primer día",
    },
    {
      description: "Usuarios activos",
      iconName: IconNames.UserActive,
      mainText: activeUsers.length.toString(),
      tooltipText: "Usuarios que ingresan a la plataforma",
    },
    {
      description: "Tiempo promedio de conexión",
      iconName: IconNames.Frequency,
      mainText: (avgSession / 3600).toFixed(2) + "h",
      tooltipText: "Tiempo promedio que los usuarios permanecen logueados en la plataforma",
    },
  ];
  return <StatsRow statsData={stats} />;
};
