import { Button, Form, Row, Col, Card } from "antd";
import cls from "classnames";
import React, { Dispatch, FunctionComponent, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { setBreadcrumbs } from "../../features/breadcrumbs/breadcrumbsSlice";
import {
  fetchCompanies,
  fetchCompany,
  ICompany,
  selectCompany,
} from "../../features/company/companySlice";
import {
  fetchGroups,
  GroupDict,
  selectGroupsDict,
} from "../../features/groups/groupSlice";
import {
  disableUsersStatus,
  enableUsersStatus,
  fetchUser,
  hideModal,
  IUser,
  selectDisplayModal,
  selectUser,
  updateUser,
} from "../../features/users/usersSlice";
import { DialogPopUp } from "../common/DialogPopUp";
import { CogDrawer } from "../common/Drawer";
import { ProfileLayout } from "../common/Layouts/ProfileLyt";
import { Output } from "../common/Output";
import { Status, StatusEnum } from "../common/Status";
import { UserForm } from "../Users/Form";
import { extractDate } from "../utils/convertions";
import styles from "./shared.module.scss";
import { Headlines, ProfileDetailsColumn } from "./UserDetailsColumn";

interface ProfileProps { }

interface RenderOptionsProps {
  toggleVisible: any;
  username: string;
  dispatch: Dispatch<any>;
  user: IUser;
  goToPrint(): void;
}

const renderOptions = ({
  toggleVisible,
  username,
  dispatch,
  user,
  goToPrint,
}: RenderOptionsProps) => {
  return (
    <Row style={{ justifyContent: "flex-end" }}>
      <Button
        type="link"
        onClick={() => {
          if (user.enabled) {
            dispatch(disableUsersStatus(false, { usernames: [user.username] }));
          } else {
            dispatch(enableUsersStatus(false, { usernames: [user.username] }));
          }
        }}
      >
        {user.enabled ? "Deshabilitar" : "Habilitar"}
      </Button>
      <Button
        onClick={() => {
          dispatch(fetchCompanies());
          dispatch(fetchUser(username));
          toggleVisible();
        }}
      >
        Editar
      </Button>
      <Button
        onClick={() => goToPrint()}
        className={cls(styles.downloadBtn)}
        type="primary"
      >
        Descargar ficha del perfil
      </Button>
    </Row>
  );
};

const renderLeftColumn = (user: IUser, company: ICompany) => {
  return (
    <ProfileDetailsColumn
      headlinesType={Headlines.Student}
      user={user}
      company={company}
    />
  );
};

const renderRightColumn = (user: IUser, groupsDict: GroupDict) => {
  return (
    <React.Fragment>
      <Row gutter={[16, 40]}>
        <Col span={24}>
          <Card className={cls(styles.card, styles.overload)}>
            <h2 className={cls(styles.title)}>Estudios</h2>
            <Output
              label={"Grupo asignado"}
              text={
                user?.studentGroupId
                  ? groupsDict[user.studentGroupId]?.name
                  : ""
              }
            />
            <Output label={"Estatus"} text={""}>
              <div className={cls(styles.status)}>
                <Status
                  status={
                    user.enabled ? StatusEnum.Enable : StatusEnum.Disable
                  }
                ></Status>
              </div>
            </Output>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
};

const studentFormId = "studentForm";

interface RouteParams {
  id: string;
}

export const StudentProfile: FunctionComponent<ProfileProps> = () => {
  const [visible, setVisible] = useState<boolean>(false);
  const toggleVisible = () => setVisible(!visible);
  const [user, setUser] = useState<IUser>();
  const [currentUser, setCurrentUser] = useState<IUser>();
  const [company, setCompany] = useState<ICompany>();
  const dispatch = useDispatch();
  const { id } = useParams<RouteParams>();
  const isModalVisible = useSelector(selectDisplayModal);
  const groupsDict = useSelector(selectGroupsDict);
  const history = useHistory();

  const userFromStore = useSelector(selectUser(id));
  useEffect(() => {
    dispatch(
      setBreadcrumbs([
        {
          label: "Estudiantes",
          url: "/students",
        },
        {
          label: "Detalle",
        },
      ])
    );
  }, [dispatch]);
  useEffect(() => {
    if (userFromStore) {
      setUser(userFromStore);
      setCurrentUser(userFromStore);
      dispatch(fetchCompany(userFromStore?.company ?? ""));
    }
  }, [userFromStore, dispatch]);

  const companyFromStore = useSelector(
    selectCompany(userFromStore?.company ?? "")
  );
  useEffect(() => {
    if (companyFromStore) {
      setCompany(companyFromStore);
    }
  }, [companyFromStore]);

  useEffect(() => {
    dispatch(fetchGroups());
    dispatch(fetchUser(id));
  }, [dispatch, id]);

  const [form] = Form.useForm();

  const goToPrint = () => {
    history.push(`/students/${id}/print`);
  };

  return (
    <ProfileLayout
      image={currentUser?.picture}
      rightTopOptions={renderOptions({
        toggleVisible,
        username: currentUser?.username || "",
        dispatch,
        user: Object.assign({}, currentUser),
        goToPrint,
      })}
      leftBottomOptions={renderLeftColumn(
        Object.assign({}, currentUser),
        Object.assign({}, company)
      )}
      rightBottomOptions={renderRightColumn(
        Object.assign({}, currentUser),
        Object.assign({}, groupsDict)
      )}
    >
      <DialogPopUp
        name="Estudiante"
        action="actualizado"
        visible={isModalVisible}
        onCancel={() => {
          dispatch(hideModal());
        }}
      />
      <CogDrawer
        visible={visible}
        toggleVisible={toggleVisible}
        title="Editar"
        footer={
          <div>
            <Button onClick={toggleVisible}>Cancelar</Button>&nbsp;&nbsp;
            <Button
              type="primary"
              htmlType="submit"
              form={studentFormId}
              onClick={() => {
                form
                  .validateFields()
                  .then((_value) => {
                    const userToSend = { ...user } as IUser;
                    if (!userToSend) {
                      return;
                    }
                    delete userToSend.companyName;
                    delete userToSend.status;
                    userToSend.birthdate = extractDate(userToSend.birthdate);
                    dispatch(updateUser(userToSend, toggleVisible));
                    dispatch(fetchUser(id));
                  })
                  .catch((error) => {
                    console.log("StudentProfile -> error", error);
                  });
              }}
            >
              Actualizar
            </Button>
          </div>
        }
      >
        <div>
          {!visible ? null : (
            <UserForm
              form={form}
              formId={studentFormId}
              user={user}
              setUser={setUser}
              adminOptions={false}
              isCreating={false}
              isStudent
            />
          )}
        </div>
      </CogDrawer>
    </ProfileLayout>
  );
};
