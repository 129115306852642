import {
  Button,
  Card,
  DatePicker,
  Divider,
  Dropdown,
  Form,
  Input,
  Menu,
  Select,
} from "antd";
import { FormInstance } from "antd/lib/form";
import moment from "moment";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Microservices } from "../../app/AllowedMicroservices";
import { BASE_URL } from "../../app/apiMiddleware";
import {
  VisibilityRole,
  VisibilityRolesDict,
} from "../../features/campaign/campaignSlice";
import {
  selectCompanies,
  selectCompaniesIds,
} from "../../features/company/companySlice";
import { EvaluationTypeEnum } from "../../features/evaluation/common/enums";
import { IEvaluation } from "../../features/evaluation/common/interfaces";
import {
  selectEvaluationsDict,
  selectSurveys,
} from "../../features/evaluation/evaluationsSlice";
import { CogIcon } from "../common/CogIcon";
import EvaluationField from "../common/EvaluationField";
import { MIN_3_CHAR } from "../Users/Form";
import { UploadFile } from "../Users/UploadFile";
import styles from "./form.module.scss";

const dateFormat = "DD/MM/YYYY";

export interface InitialValues {
  description: string;
  title: string;
  resultsVisibilityRole?: VisibilityRole;
  companyId?: string;
  survey?: string;
  rangeDate?: [moment.Moment, moment.Moment];
  image?: string;
}

interface Props {
  initialValues: InitialValues;
  form: FormInstance;
  campaignId?: string;
  onUpdateValues: React.Dispatch<
    React.SetStateAction<InitialValues | undefined>
  >;
}

export const CampaignForm: React.FunctionComponent<Props> = ({
  form,
  campaignId,
  initialValues,
  onUpdateValues,
}) => {
  const [loading, setLoading] = useState(false);
  const [companyId, setCompanyId] = useState<string>('');
  const [error, setError] = useState("");
  const dict = useSelector(selectEvaluationsDict);
  const idsList = useSelector(selectCompaniesIds);
  const list = useSelector(selectCompanies);
  const { RangePicker } = DatePicker;

  const isDateDisabled =
    campaignId !== undefined &&
    initialValues.rangeDate &&
    initialValues.rangeDate[0].diff(moment(new Date())) <= 0;

  const handleChangeFile = (info: any) => {
    const { status } = info.file;
    if (status === "uploading") {
      setLoading(true);
      setError("");
      return;
    }
    if (status === "done") {
      const currentCampaign = form.getFieldsValue() as InitialValues;
      setLoading(false);
      form.setFieldsValue({
        ...currentCampaign,
        image: info.file.response.url,
      });
      onUpdateValues({ ...initialValues, image: info.file.response.url });
    } else if (status === "error") {
      setError("Error al subir imagen");
    }
  };

  const handleSurveyUpdate = (evaluation: IEvaluation) => {
    const currentCampaign = form.getFieldsValue() as InitialValues;
    form.setFieldsValue({
      ...currentCampaign,
      survey: evaluation._id,
    });
    onUpdateValues({ ...initialValues, survey: evaluation._id });
  };
  const handleSurveyDelete = () =>
  {
    onUpdateValues({
      ...initialValues,
      survey: undefined,
      resultsVisibilityRole: undefined,
    });
    const values = form.getFieldsValue();
    values.survey = undefined;
    values.resultsVisibilityRole = undefined;
    form.setFieldsValue(values);
  }
  const handleRoleDelete = () =>
    onUpdateValues({ ...initialValues, resultsVisibilityRole: undefined });

  const menu = (
    <Menu>
      {Object.entries(VisibilityRole).map((role) => {
        return (
          <Menu.Item
            key={role[0]}
            onClick={() => {
              onUpdateValues({
                ...initialValues,
                resultsVisibilityRole: role[1],
              });
            }}
          >
            <p>{VisibilityRolesDict[role[1]]}</p>
          </Menu.Item>
        );
      })}
    </Menu>
  );
  return (
    <Form
      form={form}
      initialValues={initialValues}
      className={styles.container}
      layout="vertical"
    >
      <div className={styles.formWrapper}>
        <UploadFile
          onHandleChange={handleChangeFile}
          uploadUrl={`${
            BASE_URL[Microservices.FILES_SERVICE]
          }/files/uploadToS3`}
          loading={loading}
          fileTypesAllowed={["image/jpeg", "image/png", "image/gif"]}
          fileNotAllowedMessage="¡Solo se puede subir imagenes jpg, png o gif!"
          imageUrlToShow={initialValues.image}
        />
        <p>{error}</p>
        <Form.Item
          name="companyId"
          wrapperCol={{ sm: 24 }}
          label="Empresa"
          rules={[{ required: true, message: "Requerido" }]}
        >
          <Select 
            placeholder="Asigna una empresa" 
            style={{ width: "100%" }}
            onChange={(data)=>{
              setCompanyId(data?.toString() || '');
            }}
          >
            {idsList.map((id) => {
              return (
                <Select.Option key={id} value={id}>
                  {list[id].name}
                </Select.Option>
              );
            })}
          </Select>
        </Form.Item>
        <Form.Item
          label="Título"
          name={"title"}
          rules={[
            { required: true, message: "Requerido" },
            { type: "string", min: 3, message: MIN_3_CHAR },
            { type: "string", max: 50, message: "Máximo 50 caracteres" },
          ]}
        >
          <Input type="text" placeholder="Título" />
        </Form.Item>
        <Form.Item
          label="Descripción"
          name="description"
          rules={[
            { type: "string", required: true, message: "Requerido" },
            {
              type: "string",
              max: 500,
              message: "No puede tener más de 500 caracteres",
            },
          ]}
        >
          <Input.TextArea placeholder="Descripción" />
        </Form.Item>
        <Form.Item
          label="Duración de la campaña"
          name="rangeDate"
          rules={[
            { required: true, message: "Requerido" },
            () => ({
              validator: async (_rules, value) => {
                if (!value) {
                  return;
                }
                if (isDateDisabled) {
                  return;
                }
                const startDate = value[0];
                const endDate = value[1];
                const today = moment(new Date());
                if (
                  !(
                    today.isBefore(startDate) || today.isSame(startDate, "days")
                  )
                ) {
                  throw new Error(
                    "La fecha de inicio no puede ser en el pasado"
                  );
                } else if (startDate.isSame(endDate)) {
                  throw new Error("Debe existir al menos un día de diferencia");
                }
              },
            }),
          ]}
        >
          <RangePicker
            name="rangeDate"
            placeholder={["DD/MM/AAAA", "DD/MM/AAAA"]}
            suffixIcon={<CogIcon icon="Calendar" color={"#c1c1c1"} />}
            separator="-"
            format={dateFormat}
            disabled={isDateDisabled}
            className={styles.rangePicker}
          />
        </Form.Item>
        <Divider />
        <Form.Item
          name="survey"
        >
          <EvaluationField
            evaluationId={initialValues.survey}
            hideSelectorAfterSelection
            type={EvaluationTypeEnum.Survey}
            onUpdate={handleSurveyUpdate}
            evaluationSelector={selectSurveys}
            companyId={companyId}
            parentLabel="la campaña"
          >
            {initialValues.survey && (
              <Card className={styles.card}>
                <h2>{dict[initialValues.survey]?.title}</h2>
                <span>
                  Total de preguntas:{" "}
                  {dict[initialValues.survey]?.questions.length}
                </span>
                <Divider />
                {!initialValues.resultsVisibilityRole && (
                  <>
                    <p>Roles que pueden ver los resultados</p>
                    <Form.Item
                      name="resultsVisibilityRole"
                      rules={[{ required: true, message: "Requerido" }]}
                    >
                      <Dropdown overlay={menu} trigger={["click"]}>
                        <p
                          className="ant-dropdown-link"
                          onClick={(e) => {
                            e.preventDefault();
                          }}
                        >
                          <Button className={styles.addRoleButton}>
                            Roles que pueden ver los resultados
                            <CogIcon icon="Chevron-down" />
                          </Button>
                        </p>
                      </Dropdown>
                    </Form.Item>
                  </>
                )}
                {initialValues.resultsVisibilityRole && (
                  <div className={styles.actions}>
                    <Button
                      type="default"
                      className={styles.role}
                      onClick={handleSurveyDelete}
                    >
                      <CogIcon className={styles.square} icon="Delete" />
                    </Button>
                    <Button
                      type="default"
                      className={styles.role}
                      onClick={handleRoleDelete}
                    >
                      <CogIcon className={styles.square} icon="Eye" />
                      <span>
                        {
                          VisibilityRolesDict[
                            initialValues.resultsVisibilityRole
                          ]
                        }
                      </span>
                    </Button>
                  </div>
                )}
              </Card>
            )}
          </EvaluationField>
        </Form.Item>
      </div>
    </Form>
  );
};
