import React, { FunctionComponent } from 'react';
import { Row, Col } from 'antd';

interface TwoColumnLayoutProp {
    firstColumnContent: React.ReactNode,
    secondColumnContent?: React.ReactNode,
    firstColumnClassName?: string,
    secondColumClassName?: string,
    firstColumnSpan: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | 13 | 14 | 15 | 16 | 17 | 18 | 19 | 20 | 21 | 22 | 23 | 24,
}

export const TwoColumnLayout: FunctionComponent<TwoColumnLayoutProp> = ({ firstColumnContent, secondColumnContent, firstColumnSpan = 12 }) => {
    const col1Span = secondColumnContent ? firstColumnSpan : 24;
    const col2Span = 24 - col1Span;
    return (
        <Row gutter={16}>
            <Col className="gutter-row" span={col1Span}>
                {firstColumnContent}
            </Col>
            {secondColumnContent ?
                (
                    <Col className="gutter-row" span={col2Span}>
                        {secondColumnContent}
                    </Col>
                ) : null
            }
        </Row>
    );
};
