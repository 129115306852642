import React from "react";
import { useSelector } from "react-redux";
import Vimeo from "@u-wave/react-vimeo";
import { Player } from "@vimeo/player";
import { Alert, Spin } from "antd";
import ctx from 'classnames';

import {
  selectVideoStatus,
  VideoStatus
} from "../../features/lesson/videoStatus";
import { IQuizCuePoint } from "../../features/lesson/lessonSlice";

import styles from './videoPreviewForLink.module.scss';

interface VideoPreviewForLinkProps {
  videoUrl: string;
  quizCuePoints: IQuizCuePoint [],
  duration: number,
  onChangeStatus(isPaused: boolean): void
  setProgress(progress: number): void
  progress: number
}

export const VideoPreviewForLink: React.FunctionComponent<VideoPreviewForLinkProps> = ({
  videoUrl,
  quizCuePoints=[],
  duration=1,
  onChangeStatus,
  setProgress,
  progress,
}) => {
  let playerInstance: Player;
  const video: string = videoUrl.split("/").pop() || "";
  const videoStatus = useSelector(selectVideoStatus);
  if (
    [VideoStatus.transcodeStarting, VideoStatus.transcoding].includes(
      videoStatus
    )
  ) {
    return (
      <Alert
        message={
          <span>
            El video está siendo procesado,
            <br />
            Debe esperar unos minutos a que se termine de procesar para poder
            utilizarlo para vincular el quiz. Puede guardar el curso y regresar
            mas tarde.
          </span>
        }
        type="info"
      />
    );
  }
  if (videoStatus !== VideoStatus.available) {
    return <Spin />;
  }
  return (
    <>
      <Vimeo
        onPlay={()=>{
          onChangeStatus(false);
        }}
        onPause={()=>{
          onChangeStatus(true);
        }}
        onEnd={()=>{
          onChangeStatus(true);
        }}
        video={video}
        width={520}
        onReady={player => {
          player.addCuePoint(3, {
            text: "YEIII"
          });
          playerInstance = player;
        }}
        onCuePoint={a => {
          playerInstance.pause();
        }}

        onTimeUpdate={(event)=>{
          setProgress(event.percent);
        }}
      />
      <div className={styles.timeBarContainer}>
        <div className={styles.backgroundBar}/>
        <div className={styles.foregroundBar} style={{ width: `${Math.floor(progress * 100) }%`}}/>
        {quizCuePoints.map(quizCuePoint=>{
          const pos = quizCuePoint.time / duration;
          const left = Math.floor(pos * 520);
          return <div key={quizCuePoint.questionId} style={{
            left,
          }} className={ctx(styles.timeStop, {
            [styles.current]: Math.abs(progress - pos) <= 0.01
          })} />
        })}
      </div>
    </>
  );
};
