import { Divider } from "antd";
import moment from "moment";
import React, { FunctionComponent } from "react";
import { IEvalWithCategory } from "./Profile"
import styles from "./groupOfCompletedEvals.module.scss";

interface EvalGroupProps {
  items: IEvalWithCategory[];
}

export const GroupOfCompletedEvals: FunctionComponent<EvalGroupProps> = ({
  items = [],
}) => {
  return (
    <React.Fragment>
      {items.map((item, index) => {
        let title = item.course ?? "";
        if (item.lesson) {
          title = item.lesson;
        } else if (item.topic) {
          title = item.topic;
        }
        return (
          <React.Fragment key={`group${index}`}>
            <div className={styles.wrapper}>
              <div
                className={styles.evaluationItem}
                key={`${item.course}-${item.finishedAt}`}
              >
                <div className={styles.title}>{title}</div>
                <div className={styles.bottomInfoWrapper}>
                  <div className={styles.category}>{item.categoryName}</div>
                  <div className={styles.finishedAt}>
                    Fecha: {moment.utc(item.finishedAt).format("DD-MM-YYYY")}
                  </div>
                </div>
                <Divider />
              </div>
            </div>
          </React.Fragment>
        );
      })}
    </React.Fragment>
  );
};
