import { Grid } from 'antd';
import { SIZES } from '../common/screen';
import { useState, useEffect } from 'react';
const { useBreakpoint } = Grid;

export const useDeviceSize = () => {
  const breakpoint = useBreakpoint();
  const [size, setSize] = useState<SIZES>(SIZES.PHONE);

  useEffect(() => {
    const bp = breakpoint;
    if (bp.xs) {
      setSize(SIZES.PHONE);
      return;
    }
    if (!bp.md) {
      setSize(SIZES.TABLET);
      return;
    }
    setSize(SIZES.DESKTOP);
  }, [breakpoint]);

  return size;
}
