import { Button } from "antd";
import React, { FunctionComponent, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchCompanies,
  selectCompanies,
} from "../../features/company/companySlice";
import { MainLayout } from "../common/Layouts/Main";
import { CompanySideFilter } from "./CompanySideFilter";
import styles from "./index.module.scss";
import { Manager } from "./Manager";

export const Notifications: FunctionComponent = () => {
  const [companyId, setCompanyId] = useState<string | null>(null);
  const [openScheduledNotifications, setOpenScheduledNotifications] = useState<
    boolean
  >(false);
  const companies = useSelector(selectCompanies);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchCompanies());
  }, [dispatch]);

  return (
    <MainLayout
      title="Notificaciones"
      titleContainerClass={styles.header}
      rightTopOptions={
        <Button
          disabled={!companyId}
          onClick={() => {
            setOpenScheduledNotifications(true);
          }}
          type="default"
        >
          Ver notificaciones programadas
        </Button>
      }
    >
      <div className={styles.container}>
        <div className={styles.companyFilterContainer}>
          <CompanySideFilter
            list={companies}
            companyId={companyId}
            setCompanyId={(companyId: string) => {
              setCompanyId(companyId);
            }}
          />
        </div>
        <div className={styles.innerContainer}>
          <Manager
            closeScheduledNotifications={() => {
              setOpenScheduledNotifications(false);
            }}
            openScheduledNotifications={openScheduledNotifications}
            companyId={companyId}
          />
        </div>
      </div>
    </MainLayout>
  );
};
