import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Dispatch } from "react";
import { Microservices } from "../../../../app/AllowedMicroservices";
import { RootState } from "../../../../app/store";
import apiActionCreator, {
  HttpMethods,
} from "../../../../services/apiActionCreator";
import { FetchStatus } from "../../../common/enums";

interface IInTop10Response {
  onTop10: boolean,
}
interface IInTop10State {
  inTop10: boolean,
  status: FetchStatus,
}
const initialState: IInTop10State = {
  inTop10: false,
  status: FetchStatus.Idle,
};

export const dataSlice = createSlice({
  name: "reportLearningCoursesInTop10",
  initialState,
  reducers: {
    fetching: (state) => {
      state.status = FetchStatus.Fetching;
    },
    fetched: (state, action: PayloadAction<IInTop10Response>) => {
      state.inTop10 = action.payload.onTop10;
      state.status = FetchStatus.Fetched;
    },
    error: (state) => {
      state.status = FetchStatus.Error;
    },
  },
});

export const { fetching, fetched, error } = dataSlice.actions;

export const fetchCoursesInTop10 = () => (dispatch: Dispatch<object>) => {
  let url = '/courses/onTop10';
  return dispatch(
    apiActionCreator({
      endpoint: url,
      types: {
        requestType: fetching,
        successTypes: [
          {
            actionOrCreator: fetched,
          },
        ],
        errorTypes: [
          {
            actionOrCreator: error,
          },
        ],
      },
      method: HttpMethods.GET,
      microservice: Microservices.LEARNING_SERVICE,
      authenticated: true,
    })
  );
};

export const selectCoursesInTop10 = (state: RootState) => state.reports.learning.courses.coursesInTop10.inTop10;
export const selectCoursesInTop10Fetching = (state: RootState) => state.reports.learning.courses.coursesInTop10.status === FetchStatus.Fetching;

export default dataSlice.reducer;
