import React, { useEffect } from 'react';
import { Select, Form } from 'antd';
import { useSelector } from 'react-redux';

import { selectGroups } from '../../../features/groups/groupSlice';
import { IUser } from '../../../features/users/usersSlice';
import {
    ICompany,
} from '../../../features/company/companySlice';
import { FormInstance } from 'antd/lib/form';

interface CompanyFieldProps {
    newObject?: IUser,
    value?: string | ICompany,
    setNewObject: Function,
    form: FormInstance;
    companyId?: string,
}

export const StudentGroupField: React.FunctionComponent<CompanyFieldProps> = (props) => {
    const { value, setNewObject, newObject, companyId, form } = props;
    const list = useSelector(selectGroups);
    useEffect(() => {
        if (!newObject?.studentGroupId) {
            return;
        }
        const currentGroup = list.filter(group => {
            return group.company === companyId
        }).find(group => group._id === newObject?.studentGroupId);
        if (!currentGroup) {
            setNewObject({ ...newObject, studentGroupId: undefined });
            form.setFieldsValue({
                studentGroupId: undefined,
            });
        }
    }, [companyId, newObject, list, form, setNewObject]);

    return <Form.Item
        initialValue={newObject?.studentGroupId}
        name="studentGroupId"
        wrapperCol={{ sm: 24 }}
        label="Grupo del estudiante"
        rules={[{ required: true, message: 'Requerido' }]}
    >
        <Select
            placeholder="Asigna un grupo al estudiante"
            value={value?.toString()}
            style={{ width: '100%' }}
            onChange={(value) => {
                setNewObject({ ...newObject, studentGroupId: value })
            }}>
            {list.filter(group => {
                return group.company === companyId
            }).map(group => {
                return <Select.Option key={group._id} value={group._id}>{group.name}</Select.Option>
            })}
        </Select>
    </Form.Item>
};