import { Col, Input, Pagination, Row } from "antd";
import cls from "classnames";
import moment from "moment";
import qs from "query-string";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import notFoundImage from "../../../assets/modalIllustrations/notfound.png";
import {
  fetchContentCategories,
  selectContentCategoriesDict,
} from "../../../features/contentCategory/contentCategorySlice";
import {
  FetchKBPostData,
  fetchKnowledgeBases,
  selectIsKnowledgeBaseFetching,
  selectKnowledgeBaseDict,
  selectKnowledgeBasesIds,
} from "../../../features/students/knowledgeBaseSlice";
import { StudentMainLayout } from "../../common/Layouts/StudentMainLayout";
import LoadingOverlay from "../../common/LoadingOverlay";
import { SIZES } from "../../common/screen";
import { useDeviceSize } from "../../utils/useDeviceSize";
import styles from "./knowledgeBaseSearch.module.scss";
import { KnowledgeBaseSummary } from "./KnowledgeBaseSummary";
import "./searchOverrides.scss";

export const SEARCH_INPUT_PARAM_NAME = "textToSearch";
export const CATEGORY_PARAM_NAME = "category";

export const KnowledgeBaseSearch: React.FC = () => {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [resultsCount, setResultsCount] = useState<number>(0);
  const [categoryId, setCategoryId] = useState<string>("");
  const [categoryName, setCategoryName] = useState<string>("");
  const size = useDeviceSize();

  const queryParamsObj: any = qs.parse(location.search);
  const searchInputParam: string =
    queryParamsObj[SEARCH_INPUT_PARAM_NAME] || "";
  const categoryInputParam: string = queryParamsObj[CATEGORY_PARAM_NAME] || "";

  useEffect(() => {
    dispatch(fetchContentCategories());
  }, [dispatch]);

  useEffect(() => {
    if (categoryInputParam.length > 0) {
      setCategoryId(categoryInputParam);
    }
  }, [categoryInputParam, setCategoryId]);

  useEffect(() => {
    const postData: FetchKBPostData = {} as FetchKBPostData;
    if (categoryInputParam && categoryInputParam.length > 0) {
      postData.categoryId = categoryInputParam;
    } else {
      setCategoryName("Todas las categorias");
    }
    if (searchInputParam && searchInputParam.length > 0) {
      postData.textToSearch = searchInputParam;
    }
    dispatch(fetchKnowledgeBases(postData));
  }, [categoryInputParam, searchInputParam, dispatch]);

  const loading = useSelector(selectIsKnowledgeBaseFetching);
  const contentCategoryDict = useSelector(selectContentCategoriesDict);
  const knowledgeBaseIds = useSelector(selectKnowledgeBasesIds);
  const knowledgeBaseDict = useSelector(selectKnowledgeBaseDict);
  const knowledgeBaseItems = knowledgeBaseIds.map((id) => {
    const knowledgeBase = knowledgeBaseDict[id];
    const category = contentCategoryDict[knowledgeBase.category];
    return {
      ...knowledgeBase,
      categoryName: category ? category.name : "",
    };
  });

  useEffect(() => {
    setResultsCount(knowledgeBaseIds.length);
  }, [knowledgeBaseIds]);

  useEffect(() => {
    if (categoryInputParam && categoryInputParam.length > 0) {
      const category = contentCategoryDict[categoryInputParam];
      setCategoryName(category ? category.name : "");
    }
  }, [categoryInputParam, contentCategoryDict]);

  const handleSearch = (value: any, event: any) => {
    const postData: FetchKBPostData = {} as FetchKBPostData;
    const queryData: any = {};

    if (categoryId && categoryId.length > 0) {
      postData.categoryId = categoryId;
      queryData[CATEGORY_PARAM_NAME] = categoryId;
    }
    if (value && value.length > 0) {
      postData.textToSearch = value;
      queryData[SEARCH_INPUT_PARAM_NAME] = value;
    }
    const queryParams = qs.stringify(queryData);
    const pathPlusParams = `${location.pathname}?${queryParams}`;
    history.push(pathPlusParams);
    dispatch(fetchKnowledgeBases(postData));
  };

  const handleOnSummaryClick = ({ contentId }: { contentId: string }) => {
    const pathPlusParams = `/knowledgeBases/${contentId}`;
    history.push(pathPlusParams);
  };

  return (
    <StudentMainLayout>
      <Row style={{ paddingLeft: "1em", paddingRight: "1em" }}>
        <Col
          span={cls({
            16: size === SIZES.DESKTOP,
            24: size === SIZES.TABLET || size === SIZES.PHONE,
          })}
        >
          <Row>
            <Col span={24}>
              <div
                className={cls({
                  [styles.resultsHeader]: true,
                  [styles.column]: size === SIZES.PHONE,
                })}
              >
                <div className={styles.left}>
                  <span className={styles.title}>
                    {searchInputParam && <span>Resultados para</span>}
                    {!searchInputParam && <span>{categoryName}</span>}
                    <br />
                    {searchInputParam && <span>"{searchInputParam}"</span>}
                  </span>
                  <span className={styles.counter}>
                    {resultsCount} artículos
                  </span>
                </div>
                <div className={styles.right}>
                  <Input.Search
                    placeholder={`Buscar en ${categoryName}`}
                    className={cls("custom-search")}
                    defaultValue={`${searchInputParam}`}
                    onSearch={handleSearch}
                    allowClear
                  />
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col
              span={cls({
                24: size === SIZES.DESKTOP,
                21: size === SIZES.TABLET,
              })}
            >
              <div className={styles.resultsContent}>
                {resultsCount > 0 ? (
                  loading ? (
                    <LoadingOverlay spinning fullscreen />
                  ) : (
                    <Row gutter={[0, 22]}>
                      {knowledgeBaseItems
                        .sort(
                          (a, b) =>
                            new Date(b.updatedAt).getTime() -
                            new Date(a.updatedAt).getTime()
                        )
                        .map((kbItem) => (
                          <Col
                            className="gutter-row"
                            span={24}
                            key={kbItem._id}
                          >
                            <span
                              onClick={() =>
                                handleOnSummaryClick({ contentId: kbItem._id })
                              }
                            >
                              <KnowledgeBaseSummary
                                key={kbItem._id}
                                title={kbItem.title}
                                description={kbItem.description}
                                category={kbItem.categoryName}
                                lastUpdate={moment(kbItem.updatedAt).format(
                                  "DD/MM/YYYY"
                                )}
                              />
                            </span>
                          </Col>
                        ))}
                    </Row>
                  )
                ) : loading ? (
                  <LoadingOverlay spinning fullscreen />
                ) : (
                  <Row>
                    <Col span={12} offset={6}>
                      <img
                        style={{ width: "100%", paddingTop: "50px" }}
                        src={notFoundImage}
                        alt="success"
                      />
                      <p className={styles.notFoundText}>
                        No hemos encontrado coincidencias con tu búsqueda
                      </p>
                    </Col>
                  </Row>
                )}
              </div>
            </Col>
          </Row>
          <Row>
            {resultsCount > 0 ? (
              <Pagination
                responsive={true}
                current={currentPage}
                pageSize={20}
                onChange={(page) => {
                  setCurrentPage(page);
                }}
                total={resultsCount}
                showSizeChanger={false}
              />
            ) : null}
          </Row>
        </Col>
      </Row>
    </StudentMainLayout>
  );
};
