import React, { FunctionComponent, useState } from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import { Button } from "antd";
import { useHistory } from "react-router-dom";
import {
  selectStudentHomeCourse,
  selectIsStudentHomeCourseBlocked,
} from "../../../features/students/homeCoursesSlice";
import { selectCategory } from "../../../features/category/categorySlice";
import { CogIcon } from "../../common/CogIcon";
import {
  COURSES_PRIORITY_COLORS,
  COURSES_PRIORITY_LABELS,
  COURSES_PRIORITY_ICON,
} from "../../../utils/constants";

import styles from "./courseBlock.module.scss";
import { NotAvailable } from "../common/NotAvaiblable";
import ConfirmationModal from "../../common/ConfirmationModal";
import { selectStudentMyCourses } from "../../../features/students/myCoursesSlice";

interface StudentRouterProps {
  courseKey: number;
}

export const CourseBlock: FunctionComponent<StudentRouterProps> = ({
  courseKey,
}) => {
  const [isModalShowing, setIsModalShowing] = useState<boolean>(false);
  const { course, extra, isCompleted } = useSelector(
    selectStudentHomeCourse(courseKey)
  );
  const isBlockedBecausePredecesor = useSelector(
    selectIsStudentHomeCourseBlocked(course?._id)
  );

  const isCourseDateAvailable = extra?.endDate ?
    moment().isBetween(extra?.startDate, extra?.endDate) :
    moment().isAfter(extra?.startDate);
  const { priority = 1 } = extra || {};
  const history = useHistory();
  const homeCourses = useSelector(selectStudentMyCourses);
  const predecesorsIds = extra?.hasPredecessor
    ? extra.predecessor.map((id) => id)
    : [];

  const predecesorCourses = homeCourses
    .filter((obj) => {
      return predecesorsIds.includes(obj.course._id);
    })
    .map((obj) => obj.course);

  const predecesorCourse = predecesorCourses[0];
  const category = useSelector(selectCategory(course.category.toString()));
  const goToPredecesor = () => {
    const courseId = predecesorCourse?._id || "";
    history.push(`/course/${courseId}`);
  };
  return (
    <React.Fragment>
      {isModalShowing ? (
        <ConfirmationModal
          closable
          headerIcon="Attention"
          onCancel={() => setIsModalShowing(false)}
          title="Hay un curso predecesor pendiente"
          message={
            <div className={styles.containerModal}>
              <div className={styles.mainContent}>
                <div className={styles.description}>
                  Este curso no se puede mostrar hasta culminar el curso "
                  {predecesorCourse?.title}".
                </div>
              </div>
            </div>
          }
          footer={
            <Button
              type="link"
              onClick={() => goToPredecesor()}
              className={styles.text}
            >
              Ir al curso predecesor
            </Button>
          }
          onConfirmation={goToPredecesor}
        />
      ) : null}
      <div className={styles.container}>
        {category && course && (
          <>
            <div className={styles.imageWrapper}>
              <span className={styles.category}>{category.name}</span>
              <img
                className={styles.image}
                src={course.imageurl}
                alt={`${course.title}`}
              />
            </div>
            <div className={styles.innerContainer}>
              <div className={styles.durationPriorityContainer}>
                <div className={styles.duration}>
                  <CogIcon
                    className={styles.icon}
                    color="#81858B"
                    icon="clock"
                  />
                  {course.durationHour} Hora
                  {course.durationHour === 1 ? "" : "s"}
                </div>
                <div className={styles.priority}>
                  <CogIcon
                    className={styles.icon}
                    color={COURSES_PRIORITY_COLORS[priority]}
                    icon={COURSES_PRIORITY_ICON[priority]}
                  />
                  Prioridad {COURSES_PRIORITY_LABELS[priority]}
                </div>
              </div>
              <div className={styles.title}>{course.title}</div>
              <div className={styles.description}>{course.description}</div>
              <div className={styles.scheduleContainer}>
                <div className={styles.start}>
                  Comienza: {moment(extra?.startDate).format("DD-MM-YYYY")}
                </div>
                <div className={styles.finish}>
                  Termina: {extra?.endDate ? moment(extra?.endDate).format("DD-MM-YYYY") : 'no tiene fecha'}
                </div>
              </div>
              <div className={styles.buttonContainer}>
                {(isCompleted || isCourseDateAvailable) &&
                  !isBlockedBecausePredecesor ? (
                  <Button
                    onClick={() => history.push(`/course/${course._id}`)}
                    className={styles.button}
                    type="default"
                  >
                    Ver curso
                  </Button>
                ) : predecesorCourse ? (
                  <div
                    onClick={() => {
                      if (isBlockedBecausePredecesor) {
                        setIsModalShowing(true);
                      }
                    }}
                  >
                    <NotAvailable />
                  </div>
                ) : (
                  <NotAvailable />
                )}
              </div>
            </div>
          </>
        )}
      </div>
    </React.Fragment>
  );
};
