import { Button, Progress, Spin } from "antd";
import ctx from "classnames";
import React, { FunctionComponent } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import placeholderImage from "../../../assets/business-12.png";
import { cognitoGivenName } from "../../../features/auth/authSlice";
import {
  selectAreStudentHomeCoursesFetching,
  selectStudentHomeCurrentCourse,
} from "../../../features/students/homeCoursesSlice";
import styles from "./currentCourseBlock.module.scss";

interface StudentRouterProps {}

interface ProgressLocalProps {
  percentage: number;
}

const ProgressLocal: FunctionComponent<ProgressLocalProps> = ({
  percentage,
}) => {
  return (
    <div className={styles.progress}>
      <div className={styles.percentage}>{(percentage * 100).toFixed(0)}%</div>
      <Progress percent={percentage * 100} showInfo={false} />
    </div>
  );
};
export const CurrentCourseBlock: FunctionComponent<StudentRouterProps> = () => {
  const { course, advancePercentage = 0 } =
    useSelector(selectStudentHomeCurrentCourse) || {};
  const loading = useSelector(selectAreStudentHomeCoursesFetching);
  const givenName = useSelector(cognitoGivenName);
  const history = useHistory();
  let welcomeContent;
  let courseContainer;
  if (course) {
    welcomeContent = (
      <div className={styles.welcomeContainer}>
        <div>Hola, {givenName}</div>
        <div className={styles.strongTitle}>¡Continua con tus lecciones!</div>
      </div>
    );
    courseContainer = (
      <div className={styles.courseContainer}>
        <div className={styles.imageWrapper}>
          <img
            className={styles.image}
            src={course.imageurl}
            alt={`${course.title}`}
          />
        </div>
        <div className={styles.bottomContainer}>
          <div className={styles.infoContainer}>
            <div className={styles.title}>{course.title}</div>
            <div className={styles.progressbar}>
              <ProgressLocal percentage={advancePercentage} />
            </div>
          </div>
          <div className={styles.buttonContainer}>
            <Button
              type="primary"
              onClick={() => {
                history.push(`/course/${course._id}`);
              }}
            >
              Continuar curso
            </Button>
          </div>
        </div>
      </div>
    );
  } else {
    welcomeContent = (
      <div className={ctx(styles.welcomeContainer, styles.noCourse)}>
        <div>Hola, {givenName}</div>
        <div className={styles.strongTitle}>¡Comienza a capacitarte!</div>
        <div className={ctx(styles.extra, styles.firstLine)}>
          En esta plataforma podrás tomar cursos, reportar casos, y agilizar
          ciertas gestiones dentro de tu empresa o negocio.
        </div>
        <div className={ctx(styles.extra)}>
          Explora y descubre como puedes potenciar tu empresa
        </div>
      </div>
    );

    courseContainer = (
      <div className={ctx(styles.courseContainer, styles.noCourse)}>
        <div className={styles.imageWrapper}>
          <img
            className={styles.image}
            src={placeholderImage}
            alt={`no course`}
          />
        </div>
      </div>
    );
  }
  return (
    <Spin spinning={loading}>
      <div className={styles.container}>
        <div className={styles.innerContainer}>
          {welcomeContent}
          {courseContainer}
        </div>
      </div>
    </Spin>
  );
};
