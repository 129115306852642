import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import moment from "moment";
import { Dispatch } from "react";
import { Microservices } from "../../../app/AllowedMicroservices";
import { RootState } from "../../../app/store";
import apiActionCreator, {
  HttpMethods,
} from "../../../services/apiActionCreator";
import { FetchStatus } from "../../common/enums";
import { IReportValue } from "../common";

interface IGlobalStatus {
  code: string;
  count: number;
}

interface SubCategories {
  globalStatusArray: IGlobalStatus[];
}

interface ITicketsByStatus {
  id: string;
  name: string;
  code: string;
  subcategories: SubCategories[];
}

interface TicketsByStatusState {
  data: ITicketsByStatus[];
  status: FetchStatus;
}

const initialState: TicketsByStatusState = {
  data: [],
  status: FetchStatus.Idle,
};

export const dataSlice = createSlice({
  name: "caseByStatus",
  initialState,
  reducers: {
    fetching: (state) => {
      state.status = FetchStatus.Fetching;
    },
    fetched: (state, action: PayloadAction<ITicketsByStatus[]>) => {
      state.data = action.payload;
      state.status = FetchStatus.Fetched;
    },
    error: (state) => {
      state.data = [];
      state.status = FetchStatus.Error;
    },
  },
});

export const { fetching, fetched, error } = dataSlice.actions;

interface FetchCasesBody {
  from: moment.Moment;
  to: moment.Moment;
  customToken: string;
  companyId?: string;
}
export const fetchAllCases =
  ({ from, to, customToken, companyId }: FetchCasesBody) =>
  (dispatch: Dispatch<object>) => {
    const fromStrDate = moment(from).format("YYYY-MM-DD");
    const toStrDate = moment(to).format("YYYY-MM-DD");
    return dispatch(
      apiActionCreator({
        endpoint: "cognizant/ticketsByStatus",
        types: {
          requestType: fetching,
          successTypes: [
            {
              actionOrCreator: fetched,
            },
          ],
          errorTypes: [
            {
              actionOrCreator: error,
            },
          ],
        },
        method: HttpMethods.POST,
        microservice: Microservices.X_ONE,
        authenticated: true,
        customToken,
        data: {
          from: fromStrDate,
          to: toStrDate,
          company: companyId,
        },
      })
    );
  };

export const selectCaseIsFetching = (state: RootState) =>
  state.reports.case.ticketsByStatus.status === FetchStatus.Fetching;

const getReportValuesGivenStatus = (
  ticketsByStatus: ITicketsByStatus[],
  status: string
) => {
  return ticketsByStatus.map((ticketsByStatus) => {
    const createdCount = ticketsByStatus.subcategories.reduce(
      (accum, subCategory) => {
        return (accum +=
          subCategory.globalStatusArray.find(
            (globalStatus) => globalStatus.code === status
          )?.count || 0);
      },
      0
    );
    const reportValue: IReportValue = {
      key: ticketsByStatus.code,
      label: ticketsByStatus.name,
      value: createdCount,
    };
    return reportValue;
  });
};

export const selectCaseCreatedData = (state: RootState) => {
  const ticketsByStatusArray = state.reports.case.ticketsByStatus.data;
  return getReportValuesGivenStatus(ticketsByStatusArray, "OPEN");
};

export const selectCasePendingData = (state: RootState) => {
  const ticketsByStatusArray = state.reports.case.ticketsByStatus.data;
  return getReportValuesGivenStatus(ticketsByStatusArray, "IN_PROCESS");
};

export const selectCaseResolvedData = (state: RootState) => {
  const ticketsByStatusArray = state.reports.case.ticketsByStatus.data;
  return getReportValuesGivenStatus(ticketsByStatusArray, "RESOLVED");
};

export const selectCaseAssignedData = (state: RootState) => {
  const ticketsByStatusArray = state.reports.case.ticketsByStatus.data;
  return ticketsByStatusArray.map((ticketsByStatus) => {
    const createdCount = ticketsByStatus.subcategories.reduce(
      (accum, subCategory) => {
        return (accum += subCategory.globalStatusArray.reduce(
          (total, ticket) => {
            return (total += ticket.code === "UNASSIGNED" ? 0 : ticket.count);
          },
          0
        ));
      },
      0
    );
    const reportValue: IReportValue = {
      key: ticketsByStatus.code,
      label: ticketsByStatus.name,
      value: createdCount,
    };
    return reportValue;
  });
};

export default dataSlice.reducer;
