import React, { FunctionComponent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { RootState } from '../../../../../app/store';
import { selectCompanies, selectCompaniesAreFetching } from '../../../../../features/company/companySlice';
import { IReportValue } from '../../../../../features/reports/common';
import { selectCompaniesContents, selectCompaniesContentsAreFetching } from '../../../../../features/reports/learning/common/companiesContent';
import { IFulfillmentPerCompany, LearningLabels, LearningType } from '../../../../../features/reports/learning/common/interfaces';
import { selectStudentsPerCompany } from '../../../../../features/users/usersSlice';
import LoadingOverlay from '../../../../common/LoadingOverlay';
import { RoundedContainer } from '../../../../common/RoundedContainer';
import { BarChartSimple } from '../../../common/BarChartSimple';

import styles from './index.module.scss';

interface FulfillmentTop5ChartProps {
  fetchLearningContentCompletionPerCompany: (groupId?: string | undefined) => void,
  selectFulfillmentsPerCompany: (state: RootState) => IFulfillmentPerCompany;
  selectFulfillmentsPerCompanyFetching: (state: RootState) => boolean;
  learningType: LearningType;
}

export const FulfillmentTop5Chart: FunctionComponent<FulfillmentTop5ChartProps> = ({
  fetchLearningContentCompletionPerCompany,
  selectFulfillmentsPerCompany,
  selectFulfillmentsPerCompanyFetching,
  learningType,
}) => {
  const companiesContent = useSelector(selectCompaniesContents);
  const [data, setData] = useState<IReportValue[]>([])
  // Companies
  const isCompaniesFetching = useSelector(selectCompaniesAreFetching);
  const companies = useSelector(selectCompanies);
  // Users
  const studentsPerCompany = useSelector(selectStudentsPerCompany);
  // Fulfillments
  const isFulfillmentsFetching = useSelector(selectFulfillmentsPerCompanyFetching);
  const fulfillments = useSelector(selectFulfillmentsPerCompany);

  const contentsAreFetching = useSelector(selectCompaniesContentsAreFetching);

  const fetching = isCompaniesFetching || 
    isFulfillmentsFetching || 
    contentsAreFetching;

  const dispatch = useDispatch();
  useEffect(()=>{
    dispatch(fetchLearningContentCompletionPerCompany());
  }, [dispatch, fetchLearningContentCompletionPerCompany]);

  useEffect(()=>{
    if(fetching || Object.keys(companiesContent).length === 0){
      return;
    }
    const data:IReportValue[] = [];
    Object.keys(fulfillments).forEach(companyId => {
      if(!companiesContent[companyId]){
        return;
      }
      const learningContent = companiesContent[companyId][learningType];
      const fulfillment = fulfillments[companyId];
      const company = companies[companyId];
      if(!company){
        return;
      }
      const studentsCount = studentsPerCompany[companyId]?.length;
      if(!studentsCount){
        return;
      }
      let total = studentsCount * Object.keys(learningContent).length ;
      total = total ? total : 1;
      data.push({
        label:  company.name,
        key: companyId,
        value: Math.round(fulfillment.completed/total * 1000) / 10,
      });
    });
    data.sort((a, b) => {
      return +b.value - +a.value;
    });
    setData(data.filter(company=> company.value > 0));
  },[companies, fetching, fulfillments, companiesContent, learningType, studentsPerCompany]);
  const height = learningType === LearningType.lessons ? 280 : 400;
  return <RoundedContainer>
    <div className={styles.title}>Top 5 de cumplimento de {LearningLabels[learningType]} por empresa</div>
    {fetching ? (
      <LoadingOverlay inline height={height} spinning />
    ) : (
      <BarChartSimple
        height={height}
        xMaxValue={100}
        fetching={fetching} 
        labelColumnTitle="" 
        data={data.slice(0, 5)} 
        xTickCallBack={(value: number) => (value + '%')}
        labelCallback={(tooltipItem: any, data: any) => {
          const dataset = data.datasets[tooltipItem.datasetIndex];
          return `${(dataset.data[tooltipItem.index].x).toFixed(2)}%`;
        }}
      />
    )}
  </RoundedContainer>
}