import ctx from "classnames";
import React from "react";
import styles from "./main.module.scss";

interface MainLayoutProps {
  children: React.ReactNode;
  classname?: string;
  title: string;
  rightTopOptions?: React.ReactNode;
  hideTitle?: boolean;
  hideRightTopOptions?: boolean;
  titleContainerClass?: string;
  backgroundColor?: string
}

const renderRightTopOptions = (
  rightTopOptions?: React.ReactNode,
  hideRightTopOptions?: boolean
) => {
  if (!rightTopOptions || hideRightTopOptions) {
    return null;
  }
  return <div className={styles.rightTopOptions}>{rightTopOptions}</div>;
};

export const MainLayout: React.FunctionComponent<MainLayoutProps> = ({
  title,
  rightTopOptions,
  hideRightTopOptions,
  hideTitle,
  children,
  titleContainerClass = "",
  backgroundColor,
}) => {
  return (
    <div className={styles.wrapper} style={{
      backgroundColor: backgroundColor ? backgroundColor : 'white'
    }}>
      <div className={styles.main}>
        <div className={ctx(styles.titleContainer, titleContainerClass)}>
          {!hideTitle ? <div className={styles.title}>{title}</div> : null}
          {renderRightTopOptions(rightTopOptions, hideRightTopOptions)}
        </div>
        <div className={styles.content}>{children}</div>
      </div>
    </div>
  );
};
