import { Divider, Space } from "antd";
import React, { FunctionComponent, useEffect } from "react";
import { useDispatch } from "react-redux";
import { fetchCategories } from "../../../features/category/categorySlice";
import {
  fetchStudentMyCourses,
  selectStudentMyCompletedCourse,
  selectStudentMyCompletedCoursesKeys,
  selectStudentMyPendingCourse,
  selectStudentMyPendingCoursesKeys,
  selectStudentMyStartedCourse,
  selectStudentMyStartedCoursesKeys,
} from "../../../features/students/myCoursesSlice";
import { StudentMainLayout } from "../../common/Layouts/StudentMainLayout";
import { OneColumnLayout } from "../common/OneColumnLayout";
import styles from "./index.module.scss";
import { MyCoursesBlock } from "./MyCoursesBlock";
import { MyCourseSearch } from "./MyCourseSearch";

interface StudentMyCoursesProps {}

export const StudentMyCourses: FunctionComponent<StudentMyCoursesProps> = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchStudentMyCourses());
    dispatch(fetchCategories());
  }, [dispatch]);
  return (
    <StudentMainLayout>
      <OneColumnLayout>
        <div className={styles.container}>
          <MyCourseSearch /> 
          <Space style={{ width: "100%" }} size={80} direction="vertical">
            <div>
              <Divider />
              <MyCoursesBlock
                coursesToShow={3}
                individualSelector={selectStudentMyStartedCourse}
                title="En proceso"
                selector={selectStudentMyStartedCoursesKeys}
                showViewMore={true}
                viewMorePath="/myCourses/in-progress"
                emptyMessage="No tienes ningún curso en proceso"
              />
            </div>
            <div>
              <Divider />
              <MyCoursesBlock
                coursesToShow={3}
                individualSelector={selectStudentMyPendingCourse}
                title="Pendiente"
                selector={selectStudentMyPendingCoursesKeys}
                showViewMore={true}
                viewMorePath="/myCourses/pending"
                emptyMessage="Felicitaciones, no tienes cursos pendientes"
              />
            </div>
            <div>
              <Divider />
              <MyCoursesBlock
                coursesToShow={3}
                individualSelector={selectStudentMyCompletedCourse}
                title="Completados"
                selector={selectStudentMyCompletedCoursesKeys}
                showViewMore={true}
                viewMorePath="/myCourses/completed"
                emptyMessage="No tienes cursos completados"
              />
            </div>
          </Space>
        </div>
      </OneColumnLayout>
    </StudentMainLayout>
  );
};
