import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Dispatch } from "react";
import { Microservices } from "../../app/AllowedMicroservices";
import { RootState } from "../../app/store";
import apiActionCreator, { HttpMethods } from "../../services/apiActionCreator";
import { FetchStatus } from "../common/enums";
import { ParentTypeEnum } from "../evaluation/common/enums";
import { IAnswer, IHistory } from "../evaluation/common/interfaces";

interface IEvaluationResult {
  userId: string;
  evaluationId: string;
  updatedAt: Date;
  result: number;
  total: number;
  parentType: ParentTypeEnum;
  history: IHistory[];
  answers: IAnswer[];
  correctAnswersToPass?: number;
  passed?: boolean;
}

interface ExamenResultState {
  result: IEvaluationResult | null;
  status: FetchStatus;
}

const initialState: ExamenResultState = {
  result: null,
  status: FetchStatus.Idle,
};

export const examResultSlice = createSlice({
  name: "examResult",
  initialState,
  reducers: {
    fetching: (state) => {
      state.result = null;
      state.status = FetchStatus.Fetching;
    },
    fetchedCourse: (state, { payload }: PayloadAction<IEvaluationResult>) => {
      state.result = payload;
      state.status = FetchStatus.Fetched;
    },
    error: (state, _action: PayloadAction) => {
      state.result = null;
      state.status = FetchStatus.Error;
    },
  },
});

export const {
  fetching,
  fetchedCourse: fetchedCourses,
  fetchedCourse,
  error,
} = examResultSlice.actions;

export const fetchExamResult = (evaluationId: string, courseId: string) => (
  dispatch: Dispatch<object>
) => {
  return dispatch(
    apiActionCreator({
      endpoint: `/evaluation/results/${evaluationId}/${courseId}`,
      types: {
        requestType: fetching,
        successTypes: [
          {
            actionOrCreator: fetchedCourse,
          },
        ],
        errorTypes: [
          {
            actionOrCreator: error,
          },
        ],
      },
      method: HttpMethods.GET,
      microservice: Microservices.EVALUATION_SERVICE,
      authenticated: true,
    })
  );
};

export const selectIsStudentExamResultFetching = (state: RootState) =>
  state.examResult.status === FetchStatus.Fetching;
export const selectStudentExamResult = (state: RootState) =>
  state.examResult.result;

export default examResultSlice.reducer;
