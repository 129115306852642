import React, {
  FunctionComponent,
} from 'react';
import cls from 'classnames';
import {
  logoutFromCognito,
} from '../../features/auth/authSlice';
import {
  useHistory
} from 'react-router-dom';
import {
  Menu,
  Dropdown,
} from 'antd';
import {
  useSelector,
  useDispatch,
} from 'react-redux';
import {
  getCognitoUserData
} from '../../features/auth/authSlice';
import styles from './profilePill.module.scss';
import defaultImg from '../../assets/profile/profile-placeholder.png';
import { selectUserMode, UserModes } from '../../features/userMode/UserMode';
import { analytics } from '../../services/analytics';

interface ProfilePillProps { }

export const ProfilePill: FunctionComponent<ProfilePillProps> = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const cognitoUserData = useSelector(getCognitoUserData);
  const userMode = useSelector(selectUserMode);

  const onMenuClick = (info: any) => {
    const { key } = info;
    history.push(`/${key}`);
  }
  const logOutAnalyticEvent = () => {
    analytics.logOut();
  }
  const menu = (
    <Menu className={styles.menuWrapper} onClick={onMenuClick} >
      <Menu.Item key="my-profile">Mi perfil</Menu.Item>
      {
        userMode === UserModes.STUDENT &&
        <Menu.Item key="support"> Soporte técnico</Menu.Item>
      }
      <Menu.Item key=" ">
        <div
          onClick={() => {
            dispatch(logoutFromCognito());
            logOutAnalyticEvent();
          }}
          className={styles.logOutButton}
        >
          Cerrar sesión
        </div>
      </Menu.Item>
    </Menu>
  )

  return (
    <Dropdown overlay={menu} trigger={['click']} placement="bottomRight">
      <div className={styles.mainWrapper}>
        <div className={styles.text}>Perfil</div>
        <div className={styles.imageWrapper}>
          <img src={cognitoUserData?.picture || defaultImg} className={cls(styles.image)} alt="thumbnail"></img>
        </div>
      </div>
    </Dropdown>
  );
}
