import { BackTop } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getCognitoUserData } from '../../features/auth/authSlice';
import { fetchCompany, ICompany, selectCompany } from '../../features/company/companySlice';
import { CogIcon } from '../common/CogIcon';
import styles from './index.module.scss';
const jwt = require('jsonwebtoken');

const ReportCaseButton: React.FunctionComponent = () => {
    const [currentUser, setCurrentUser] = useState<any>();
    const [company, setCompany] = useState<ICompany>();
    const dispatch = useDispatch();
    const cognitoUserData = useSelector(getCognitoUserData);

    const companyFromStore = useSelector(selectCompany(cognitoUserData ? cognitoUserData['custom:company'] : ''));

    useEffect(() => {
        if (cognitoUserData) {
            setCurrentUser(cognitoUserData);
            dispatch(fetchCompany(cognitoUserData ? cognitoUserData['custom:company'] : ''));
        }
    }, [cognitoUserData, dispatch]);

    useEffect(() => {
        if (companyFromStore) {
            setCompany(companyFromStore);
        }
    }, [companyFromStore]);
    if(companyFromStore?.xOneDisabled){
        return null;
    }
    const payload = {
        personalData: {
            pdFirstName: currentUser?.given_name,
            pdSecondName: currentUser?.middle_name,
            pdLastName: currentUser?.family_name,
            pdSecondLastName: currentUser ? currentUser['custom:secondLastname'] : '',
            pdDocumentType: (currentUser ? currentUser['custom:idType'] : '') === "ID" ? "CI" : "PASSPORT",
            pdDocument: currentUser ? currentUser['custom:id'] : '',
            pdPhone: currentUser?.phone_number,
            pdEmail: currentUser?.email,
        },
        company: {
            name: company?.name,
            logoUrl: company?.logo,
            id: company?._id,
        },
        userType: "internal",
    };

    const handleRedirect = () => {
        window.open(`https://eager-raman-011fd0.netlify.app/form/${jwt.sign(payload, 'jwt')}`, '_blank');
    }

    return (
        <BackTop
            className={styles.reportButton}
            onClick={handleRedirect}
            visibilityHeight={100}
        >
            <div className={styles.content}>
                <div className={styles.square} >
                    <CogIcon icon="Flag" color="#4376F9" size="18px" />
                </div>
                <span>Reportar Caso</span>
            </div>
        </BackTop>
    );
}

export default ReportCaseButton;