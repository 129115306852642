import { Select } from 'antd';
import React, { FunctionComponent } from 'react';
import cls from 'classnames';
import styles from './statusSelector.module.scss';

const { Option } = Select;

interface StatusSelectorProps {
  onChange: (coso: any) => void,
}

export enum ExtendedStatus {
  Enabled = 'enabled',
  Disabled = 'disabled',
  All = 'all',
}

export const StatusSelect: FunctionComponent<StatusSelectorProps> = ({
  onChange,
}) => {
  return (
    <Select
      defaultValue={ExtendedStatus.All}
      onChange={onChange}
      className={cls(styles.selector, styles.targeted)}
      dropdownClassName='madres'
    >
      <Option value={ExtendedStatus.All}>Todas</Option>
      <Option value={ExtendedStatus.Enabled}>Habilitado</Option>
      <Option value={ExtendedStatus.Disabled}>Deshabilitado</Option>
    </Select>
  );
}
