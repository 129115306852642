export enum ModalStatus {
  Show = "Show",
  Hide = "Hide",
}

export enum FetchStatus {
  Fetching = "Fetching",
  Fetched = "Fetched",
  Error = "Error",
  Idle = "Idle",
  Creating = "Creating",
}

export enum ConditionEnum {
  Published = "PUBLISHED",
  Unpublished = "UNPUBLISHED",
}