import { GenderEnum } from './../../../features/users/usersSlice';
export interface ISignUpStepProps {
  next: (userData: ISignUpUser) =>void,
  userData: ISignUpUser,
}

export interface ISignUpUser {
  email?: string,
  given_name?: string;
  middle_name?: string;
  family_name?: string;
  secondLastname?: string,
  gender?: GenderEnum;
  birthdate?: string;
  nationalId?: string;
  nationalIdType?: string;
  picture?: string;
  country?: string;
  city?: string;
  address?: string;
  phone_number?: string;
  username?: string;
  name?: string;
};

export const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};