import { Button, Form } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectUserRole } from '../../features/auth/authSlice';
import { clearBreadcrumbs } from "../../features/breadcrumbs/breadcrumbsSlice";
import {
  fetchProgram,
  hideModal,
  selectAreProgramsFetching,
  selectDisplayModal,
} from "../../features/program/programSlice";
import { UserRole } from '../../features/users/usersSlice';
import { DialogPopUp } from "../common/DialogPopUp";
import { MainLayout } from "../common/Layouts/Main";
import { ProgramForm } from "./Form";
import { ProgramList } from "./List";

function renderOptions(
  toggleVisible: any,
  form: any,
  setEditedProgram: any,
  setCurrentAction: Function,
  userRole: UserRole | null
) {
  if(userRole !== UserRole.SuperAdmin){
    return null;
  }
  return (
    <Button
      onClick={() => {
        setTimeout(() => {
          form.resetFields();
          form.setFieldsValue({});
        }, 100);
        toggleVisible();
        setEditedProgram(null);
        setCurrentAction("creado");
      }}
      type="primary"
    >
      Nuevo programa
    </Button>
  );
}

export const Program: React.FC = () => {
  const [isFormVisible, setFormVisible] = useState<boolean>(false);
  const [editedProgram, setEditedProgram] = useState<string | null>(null);
  const [currentAction, setCurrentAction] = useState<string>("");
  const isModalVisible = useSelector(selectDisplayModal);
  const areProgramsFetching = useSelector(selectAreProgramsFetching);
  const userRole = useSelector(selectUserRole);
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!isFormVisible) {
      dispatch(clearBreadcrumbs());
    }
  }, [dispatch, isFormVisible]);

  useEffect(() => {
    if (!areProgramsFetching) {
      form.setFieldsValue({});
      form.resetFields();
    }
  }, [form, areProgramsFetching]);

  let content;
  if (isFormVisible) {
    content = (
      <ProgramForm
        programBeingEdited={editedProgram}
        form={form}
        onDone={() => {
          setFormVisible(false);
          setEditedProgram(null);
          setTimeout(() => {
            form.resetFields();
            form.setFieldsValue({});
          }, 100);
        }}
      />
    );
  } else {
    const onEdit = (programId: string) => {
      dispatch(fetchProgram(programId));
      setEditedProgram(programId);
      setCurrentAction("actualizado");
      setFormVisible(true);
    };
    content = <ProgramList editProgramById={onEdit} />;
  }
  const toggleVisible = () => setFormVisible(!isFormVisible);
  return (
    <MainLayout
      hideTitle={isFormVisible}
      hideRightTopOptions={isFormVisible}
      title="Programas"
      rightTopOptions={renderOptions(
        toggleVisible,
        form,
        setEditedProgram,
        setCurrentAction,
        userRole
      )}
    >
      <DialogPopUp
        name="Programa"
        action={currentAction}
        visible={isModalVisible}
        onCancel={() => {
          dispatch(hideModal());
        }}
      />
      {content}
    </MainLayout>
  );
};
