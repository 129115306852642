import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";

export interface ICrumb {
  url?: string;
  label: string;
}

const initialState: { list: ICrumb[] } = {
  list: [],
};

export const breadcrumbsSlice = createSlice({
  name: "breadcrumbs",
  initialState,
  reducers: {
    setBreadcrumbs: (state, action: PayloadAction<ICrumb[]>) => {
      state.list = action.payload;
    },
    clearBreadcrumbs: (state) => {
      state.list = [];
    },
  },
});

export const { setBreadcrumbs, clearBreadcrumbs } = breadcrumbsSlice.actions;
export const selectBreadcrumbs = (state: RootState) => state.breadcrumbs.list;
export default breadcrumbsSlice.reducer;
