export interface IFulfillment {
  completed: number,
  inProgress: number,
  notStarted: number,
  id: string,
  username: string,
}
export interface IFulfillmentPerCompany {
  [id:string]: {
    completed: number,
    inProgress: number,
    notStarted: number,
    id: string,
  }
}

export enum LearningType {
  lessons = 'lessons',
  topics = 'topics',
  courses = 'courses',
}

export enum LearningLabelsSingle {
  lessons = 'Lección',
  topics = 'Tema',
  courses = 'Curso',
}

export enum LearningLabels {
  lessons = 'lecciones',
  topics = 'temas',
  courses = 'cursos',
}

export enum EvaluationLabels {
  lessons = 'quiz',
  topics = 'test',
  courses = 'examen',
}

export interface IEvaluationsCounters{
  passed: number;
  responsesCount: number;
  results: number;
  total: number;
  _id: {
    parentId: string;
    correctAnswersToPass: number;
    username: string;
  }
}