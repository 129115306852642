import React from 'react';
import ctx from 'classnames';

import { Input as AntInput } from 'antd';
import { Row, Col, Button } from 'antd';
import { SearchProps } from 'antd/lib/input';

import styles from './index.module.scss';
import './tableTools.scss';

const { Search } = AntInput;

export interface TableToolsProps extends SearchProps {
  onEnable?: React.MouseEventHandler<HTMLElement>;
  onDisable?: React.MouseEventHandler<HTMLElement>;
  loading?: boolean;
  filter?: React.ReactNode
  activationBtnsVisible: boolean,
  enableLabel?: string,
  disableLabel?: string,
}

export const TableTools: React.FunctionComponent<TableToolsProps> = (props) => {
  const {
    activationBtnsVisible,
    filter,
    className,
    onEnable,
    onDisable,
    enableLabel='Habilitar',
    disableLabel='Deshabilitar',
    ...rest
  } = props;
  return (
    <Row align='middle' gutter={[0, 16]}>
      <Col span={5}>
        <Search className={ctx(className, styles.search, styles.wrapper, 'custom-search')} {...rest} />
      </Col>
      <Col span={3}>
        {filter}
      </Col>
      {
        activationBtnsVisible && onEnable &&
        <Col span={2}>
          <Button type='link' onClick={onEnable}>{enableLabel}</Button>
        </Col>
      }
      {
        activationBtnsVisible && onDisable &&
        <Col span={2}>
          <Button type='link' onClick={onDisable}>{disableLabel}</Button>
        </Col>
      }
    </Row>
  );
}