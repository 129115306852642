import React, { FunctionComponent } from 'react';
import { Layout } from 'antd';
import {
    Router,
    Switch,
    Route,
} from "react-router-dom";
import * as H from 'history';

import { Header } from '../../components/Header';
import { FinishRegistration } from '../../components/Authentication/FinishRegistration';
import { PasswordChange } from '../../components/Authentication/PasswordChange';
import { Login } from '../../components/Authentication/Login';
import { Message } from '../../components/common/Message';
import { Welcome } from '../../components/Authentication/Welcome';
import { LoggedUserProfile } from '../../components/LoggedUser/LoggedUserProfile';
import { Reports } from '../../components/Reports';
import ScrollToTop from '../../components/common/ScrollToTop';
import { AllNotifications } from '../../components/Students/Notifications';
import { Notifications } from '../../components/Notifications';
import { AnalyticsCompanyDimSetter } from '../../components/common/AnalyticsCompanyDimSetter';


const { Content } = Layout;

interface AdminRouterProps {
    history: H.History<any>;
}

const SupervisorRouter: FunctionComponent<AdminRouterProps> = ({ history }) => {
    return <Router history={history}>
        <AnalyticsCompanyDimSetter />
        <ScrollToTop />
        <Layout>
            <Header isLoggedIn />
            <Content className="site-layout" style={{ position: "relative" }}>
                <Switch>
                    <Route path="/my-profile">
                        <LoggedUserProfile />
                    </Route>
                    <Route path="/new-password">
                        <FinishRegistration />
                    </Route>
                    <Route path="/change-password">
                        <PasswordChange />
                    </Route>
                    <Route path="/welcome">
                        <Welcome />
                    </Route>
                    <Route path="/reports">
                        <Reports />
                    </Route>
                    <Route path="/notifications">
                        <Notifications />
                    </Route>
                    <Route path="/all/notifications">
                        <AllNotifications />
                    </Route>
                    <Route path="/">
                        <Login />
                    </Route>
                </Switch>
            </Content>
            <Message />
        </Layout>
    </Router>
};

export default SupervisorRouter;