import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Dispatch } from "react";
import { RootState } from "../../app/store";
import apiActionCreator from "../../services/apiActionCreator";
import { Microservices } from "./../../app/AllowedMicroservices";
import { NotificationsTypes } from "./../../components/Notifications/Manager";
import { HttpMethods } from "./../../services/apiActionCreator";

export enum NotificationsScheduleStatusEnum {
  IDLE = "IDLE",
  fetching = "FETCHING",
  fetched = "FETCHED",
  error = "ERROR",
}
export interface INotification {
  ids: string[];
  scheduledDateTime: string;
  body: string;
  scheduleUid: string;
  title?: string;
  type: NotificationsTypes;
}
interface MesageState {
  status: NotificationsScheduleStatusEnum;
  notifications: INotification[];
}

const initialState: MesageState = {
  status: NotificationsScheduleStatusEnum.IDLE,
  notifications: [],
};

export const notificationsScheduleSlice = createSlice({
  name: "notificationsSchedule",
  initialState,
  reducers: {
    fetching: (state) => {
      state.status = NotificationsScheduleStatusEnum.fetching;
    },
    fetched: (state, action: PayloadAction<INotification[]>) => {
      state.notifications = action.payload;
      state.status = NotificationsScheduleStatusEnum.fetched;
    },
    error: (state) => {
      state.status = NotificationsScheduleStatusEnum.error;
    },
  },
});

export const { fetching, fetched, error } = notificationsScheduleSlice.actions;

export interface IToData {
  to: string; //phone or email
  username: string;
  companyId: string;
}

interface IFetchCompanyScheduledNotifications {
  companyId: string;
}

export const fetchCompanyScheduledNotifications = ({
  companyId,
}: IFetchCompanyScheduledNotifications) => (dispatch: Dispatch<object>) => {
  const endpoint = `/notifications/schedule/list/${companyId}`;
  return dispatch(
    apiActionCreator({
      endpoint,
      types: {
        requestType: fetching,
        successTypes: [
          {
            actionOrCreator: fetched,
          },
        ],
        errorTypes: [
          {
            actionOrCreator: error,
          },
        ],
      },
      method: HttpMethods.GET,
      microservice: Microservices.COMPANY_SERVICE,
      authenticated: true,
    })
  );
};
export const selectScheduledNotificationsIsFetching = (state: RootState) =>
  state.notificationsSchedule.status ===
  NotificationsScheduleStatusEnum.fetching;
export const selectScheduledNotifications = (state: RootState) =>
  state.notificationsSchedule.notifications;
export default notificationsScheduleSlice.reducer;
