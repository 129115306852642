import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Dispatch } from "react";
import { Microservices } from "../../../app/AllowedMicroservices";
import { RootState } from "../../../app/store";
import apiActionCreator, {
  HttpMethods,
} from "../../../services/apiActionCreator";
import { FetchStatus } from "../../common/enums";

export interface User {
  companyId: string;
  session: number;
  userId: string;
}

export interface IUserSessionDuration extends User { };

interface InitialStateData {
  data: Array<User>;
  status: FetchStatus;
}

const initialState: InitialStateData = {
  data: [],
  status: FetchStatus.Idle,
};

export const dataSlice = createSlice({
  name: "userSessionDuration",
  initialState,
  reducers: {
    fetching: (state) => {
      state.status = FetchStatus.Fetching;
    },
    fetched: (state, action: PayloadAction<Array<User>>) => {
      state.data = action.payload;
      state.status = FetchStatus.Fetched;
    },
    error: (state) => {
      state.data = [];
      state.status = FetchStatus.Error;
    },
  },
});

export const { fetching, fetched, error } = dataSlice.actions;

export const fetchUsersSessionDuration =
  (companyId?: string) => (dispatch: Dispatch<object>) => {
    return dispatch(
      apiActionCreator({
        endpoint: `/user/sessions${companyId ? "/" + companyId : ""}`,
        types: {
          requestType: fetching,
          successTypes: [
            {
              actionOrCreator: fetched,
            },
          ],
          errorTypes: [
            {
              actionOrCreator: error,
            },
          ],
        },
        method: HttpMethods.GET,
        microservice: Microservices.ANALYTICS_SERVICE,
        authenticated: true,
      })
    );
  };

export const selectUsersSessionDuration = (state: RootState) =>
  state.reports.user.sessionDuration.data;
export const selectUsersSessionDurationIsFetching = (state: RootState) =>
  state.reports.user.sessionDuration.status === FetchStatus.Fetching;

export default dataSlice.reducer;
