import ctx from "classnames";
import moment from "moment";
import React, { FunctionComponent, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SendType } from "../../../features/notifications/notificationsSlice";
import {
  fetchStudentNotifications,
  INotification,
  selectStudentNotifications,
} from "../../../features/students/notifications";
import { CogIcon } from "../../common/CogIcon";
import { StudentMainLayout } from "../../common/Layouts/StudentMainLayout";
import { getNotificationIcon } from "../../LoggedUser/NotificationMenu";
import styles from "./index.module.scss";
import { NotificationPreview } from "./NotificationPreview";

import noNotificationImage from "../../../assets/notification.png";
import { analytics } from "../../../services/analytics";

interface AllNotificationsProps {}

export const AllNotifications: FunctionComponent<AllNotificationsProps> =
  () => {
    const [selected, setSelected] = useState<string | null>(null);
    const dispatch = useDispatch();
    const notifications = useSelector(selectStudentNotifications);

    useEffect(() => {
      dispatch(fetchStudentNotifications());
    }, [dispatch]);

    const sortByDate = (a: INotification, b: INotification) => {
      const date1 = new Date(
        a.sendingType === SendType.SCHEDULE ? a.scheduledDateTime : a.createdAt
      ).getTime();
      const date2 = new Date(
        b.sendingType === SendType.SCHEDULE ? b.scheduledDateTime : b.createdAt
      ).getTime();
      return date2 - date1;
    };
    if(notifications.length === 0) {
      return <StudentMainLayout><div className={styles.noNotifications}>
      <div className={styles.imageContainer}>
        <img src={noNotificationImage} alt="no notifications" />
      </div>
      <div className={styles.noNotificationsText}>
      En este momento no tienes notificaciones nuevas
      </div>
    </div>
      </StudentMainLayout>
    }
    return (
      <StudentMainLayout>
        <div className={styles.container}>
          <div className={ctx(styles.notificationsContainer)}>
            <div className={styles.mainTitle}>Notificaciones</div>
            <div className={styles.notifications}>
              {notifications
                .slice()
                .sort(sortByDate)
                .map((notification) => {
                  return (
                    <div
                      onClick={() => {
                        setSelected(notification._id);
                        analytics.notificationOpen(notification.title);
                      }}
                      key={notification._id}
                      className={ctx(styles.notification, {
                        [styles.selected]: selected === notification._id,
                      })}
                    >
                      <div className={styles.iconContainer}>
                        <div className={styles.background}>
                          <CogIcon
                            className={styles.icon}
                            icon={getNotificationIcon(notification)}
                          />
                        </div>
                      </div>
                      <div className={styles.content}>
                        <div
                          className={styles.title}
                          title={notification.title ?? notification.body}
                        >
                          {notification.body ?? notification.title}
                        </div>
                        <div className={styles.date}>
                          {moment(
                            notification.scheduledDateTime ??
                              notification.createdAt
                          ).format("DD/MM/Y")}
                        </div>
                      </div>
                      <div className={styles.unread}>
                        {notification.read ? null : (
                          <div className={styles.indicator}></div>
                        )}
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
          <div className={styles.preview}>
            <NotificationPreview notificationId={selected} />
          </div>
        </div>
      </StudentMainLayout>
    );
  };
