import cls from "classnames";
import { jsPDF } from "jspdf";
import moment from "moment";
import React from "react";
import logo from "../../../assets/logo-teurona.svg";
import {
  FONT_INTER_BOLD,
  FONT_INTER_NORMAL,
  FONT_INTER_REGULAR,
} from "../fontFiles";
import styles from "./index.module.scss";
const QRCode = require("qrcode.react");

export const RATIO_FACTOR = 632 / 842;

export interface TemplateProps {
  createdAt: string;
  showName: boolean;
  showDescription: boolean;
  showTotalHours: boolean;
  showTraineeFullName: boolean;
  showTraineeNationalId: boolean;
  showCompanyName: boolean;
  showNameAndLogo: boolean;
  showUniqueCode: boolean;
  showQrCode: boolean;
  courseName: string;
  name: string;
  description: string;
  totalHours: number;
  traineeNationalId: string;
  companyName: string;
  uniqueCode: string;
  companyLogo: string;
  baseFontSizePx?: number;
}

export const centerText = (doc: jsPDF, text: string, y: number) => {
  const txtWidth =
    (doc.getStringUnitWidth(text) * doc.getFontSize()) /
    doc.internal.scaleFactor;
  doc.text(text, (doc.internal.pageSize.width - txtWidth) / 2, y);
};
export const splitText = (
  doc: jsPDF,
  text: string,
  size: number,
  lineHeight: number,
  x: number,
  y: number,
  centered: boolean = false
) => {
  let slitedText: string[] = doc.splitTextToSize(text, size);
  let start = y;
  slitedText.forEach((item) => {
    centered ? centerText(doc, item, start) : doc.text(item, x, start);
    start += lineHeight;
  });
};

export const drawImage = (
  id: string,
  doc: jsPDF,
  x: number,
  y: number,
  centered: boolean = false
) => {
  let imgCanvas = document.createElement("CANVAS") as HTMLCanvasElement;
  let businessLogo: HTMLImageElement = document.getElementById(
    id
  ) as HTMLImageElement;
  const ratio = businessLogo.height / businessLogo.width;
  // In order to get a better resolution from the svg
  // the width is dupicated.
  const width = businessLogo.width * 2;
  const heigth = width * ratio;
  imgCanvas.width = width;
  imgCanvas.height = heigth;
  let ctx = imgCanvas.getContext("2d");
  ctx?.drawImage(businessLogo, 0, 0, width, heigth);
  // to get the actual width that svg will use on pdf
  // we need to use the 842 that is the max width used on the HTML
  // to calculate the width
  const imgWidth = (businessLogo.width * RATIO_FACTOR) / 1.2;
  const imgHeigth = imgWidth * ratio;
  var img = imgCanvas.toDataURL("image/png", 1);
  doc.addImage(
    img,
    "PNG",
    centered ? (doc.internal.pageSize.width - imgWidth) / 2 : x,
    y,
    imgWidth,
    imgHeigth
  );
};

export const createPDF = (name: string, props: TemplateProps) => {
  const doc = new jsPDF({
    orientation: "l",
    unit: "px",
    format: "a4",
  });
  let canvas: HTMLCanvasElement | undefined = props.showQrCode
    ? (document.getElementById("certicate-qr") as HTMLCanvasElement)
    : undefined;

  doc.addFileToVFS("Inter.ttf", FONT_INTER_NORMAL);
  doc.addFileToVFS("InterRegular.ttf", FONT_INTER_REGULAR);
  doc.addFileToVFS("InterBold.ttf", FONT_INTER_BOLD);

  // draw body
  doc.setFillColor("#4376F9");
  doc.rect(0, 0, doc.internal.pageSize.width, 84 * RATIO_FACTOR, "F");
  doc.setFillColor("#F7F8FA");
  doc.rect(
    0,
    84 * RATIO_FACTOR,
    doc.internal.pageSize.width,
    509 * RATIO_FACTOR,
    "F"
  );
  doc.setFillColor("#FFFFFF");
  doc.rect(
    44 * RATIO_FACTOR,
    43 * RATIO_FACTOR,
    752 * RATIO_FACTOR,
    511 * RATIO_FACTOR,
    "F"
  );

  // added fonts
  doc.addFont("Inter.ttf", "Inter", "normal");
  doc.addFont("InterRegular.ttf", "Inter", "regular");
  doc.addFont("InterBold.ttf", "Inter", "bold");

  // draw header
  doc.setFont("Inter", "normal");
  doc.setTextColor("#81858B");
  doc.setFontSize(12);
  if (props.showCompanyName) {
    doc.text(props.companyName, 69 * RATIO_FACTOR, 72 * RATIO_FACTOR);
  }
  doc.text(
    moment(props.createdAt).format("DD/MM/YYYY"),
    710 * RATIO_FACTOR,
    72 * RATIO_FACTOR
  );
  if (props.showNameAndLogo) {
    drawImage("businessLogo", doc, 0, 74 * RATIO_FACTOR, true);
  }

  // draw content
  if (props.showName) {
    doc.setFontSize(14);
    centerText(doc, "Certificado del curso", 168 * RATIO_FACTOR);
    doc.setFont("Inter", "bold");
    doc.setFontSize(28);
    doc.setTextColor("#4376F9");
    splitText(
      doc,
      props.courseName,
      700 * RATIO_FACTOR,
      26,
      0,
      202 * RATIO_FACTOR,
      true
    );
  }
  if (props.showTotalHours) {
    doc.setFont("Inter", "regular");
    doc.setFontSize(16);
    doc.setTextColor("#686F84");
    centerText(doc, `${props.totalHours} horas de estudio`, 268 * RATIO_FACTOR);
  }
  doc.setFont("Inter", "normal");
  doc.setFontSize(13);
  doc.setTextColor("#81858B");
  if (props.showDescription) {
    splitText(
      doc,
      props.description,
      640 * RATIO_FACTOR,
      12,
      0,
      294 * RATIO_FACTOR,
      true
    );
  }
  if (props.showTraineeFullName || props.showTraineeNationalId) {
    centerText(doc, "Otorgado a ", 378 * RATIO_FACTOR);
  }
  doc.setTextColor("#686F84");
  if (props.showTraineeFullName) {
    doc.setFont("Inter", "bold");
    doc.setFontSize(22);
    centerText(doc, props.name, 408 * RATIO_FACTOR);
  }
  if (props.showTraineeNationalId) {
    doc.setFont("Inter", "regular");
    doc.setFontSize(16);
    centerText(doc, "ID. " + props.traineeNationalId, 437 * RATIO_FACTOR);
  }

  // draw footer
  doc.setFont("Inter", "normal");
  doc.setFontSize(12);
  doc.setTextColor("#81858B");
  if (props.showUniqueCode) {
    doc.text(props.uniqueCode, 69 * RATIO_FACTOR, 527 * RATIO_FACTOR);
  }
  if (props.showQrCode && canvas) {
    doc.addImage(
      canvas,
      "PNG",
      712 * RATIO_FACTOR,
      465 * RATIO_FACTOR,
      62 * RATIO_FACTOR,
      62 * RATIO_FACTOR
    );
  }

  // Save document
  doc.save(`${name}.pdf`);
};

const Template1: React.FunctionComponent<TemplateProps> = ({
  showName,
  showDescription,
  showTotalHours,
  showTraineeFullName,
  showTraineeNationalId,
  showCompanyName,
  showNameAndLogo,
  showUniqueCode,
  showQrCode,
  name,
  courseName,
  description,
  totalHours,
  traineeNationalId,
  companyName,
  uniqueCode,
  createdAt,
  baseFontSizePx,
}) => (
  <article
    className={cls(styles.mainWrapper)}
    style={{ fontSize: `${baseFontSizePx}px` }}
  >
    <div className={styles.wrapper}>
      <header className={styles.frameHeader} />
      <section className={styles.container}>
        <header>
          <div className={styles.companyName}>
            {showCompanyName && <span>{companyName}</span>}
          </div>
          <div className={styles.logo}>
            {showNameAndLogo && (
              <img
                src={logo}
                alt="businessLogo"
                id="businessLogo"
                crossOrigin="anonymous"
              />
            )}
          </div>
          <span className={styles.date}>
            {moment(createdAt).format("DD/MM/YYYY")}
          </span>
        </header>
        <div className={styles.content}>
          <div className={styles.courseTitle}>
            {showName && <h5>Certificado del curso</h5>}
          </div>
          <div className={styles.courseName}>
            {showName && <h1>{courseName}</h1>}
          </div>
          <div className={styles.courseHours}>
            {showTotalHours && <h4>{`${totalHours} horas de estudio`}</h4>}
          </div>
          <div className={styles.description}>
            {showDescription && <p>{description}</p>}
          </div>
          <div className={styles.traineeTitle}>
            {(showTraineeFullName || showTraineeNationalId) && (
              <span>Otorgado a</span>
            )}
          </div>
          <div className={styles.traineeName}>
            {showTraineeFullName && <h2>{name}</h2>}
          </div>
          <div className={styles.traineeId}>
            {showTraineeNationalId && <h3>ID. {traineeNationalId}</h3>}
          </div>
        </div>
        <footer>
          {showUniqueCode && <span>{uniqueCode}</span>}
          {showQrCode && (
            <QRCode
              value={window.location.href}
              id="certicate-qr"
              renderAs="canvas"
            />
          )}
        </footer>
      </section>
    </div>
  </article>
);

export default Template1;
