import { DatePicker, Space } from "antd";
import moment from "moment";
import { RangeValue } from "rc-picker/lib/interface";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Line } from 'react-chartjs-2'
import { ICampaign } from "../../../../features/campaign/campaignSlice";
import {
  fetchCampaignHistory,
  selectCampaignHistory,
} from "../../../../features/reports/Campaigns/byDateRange";
import {
  PIECHART_COLORS,
} from "../../../../features/reports/common";
import LoadingOverlay from "../../../common/LoadingOverlay";
import styles from "./index.module.scss";

const { RangePicker } = DatePicker;

interface Props {
  companyId: string | undefined;
  campaigns: ICampaign[];
  enabledCampaigns: ICampaign[];
  loading: boolean;
}

interface LineData {
  label: string,
  data: number[],
  color: string,
}

interface IGetChartConfig {
  line1: LineData,
  line2: LineData,
  labels: string[],
  tooltipLabels: string[]
}

const getChartFullConfig = ({ line1, line2, labels, tooltipLabels }: IGetChartConfig) => {
  const data = {
    labels: labels,
    datasets: [
      {
        label: line1.label,
        data: line1.data,
        fill: false,
        borderColor: line1.color,
        tension: 0.1,
        borderWidth: 1,
        pointBackgroundColor: line1.color,
        spanGaps: 2,
      },
      {
        label: line2.label,
        data: line2.data,
        fill: false,
        borderColor: line2.color,
        tension: 0.1,
        borderWidth: 1,
        pointBackgroundColor: line2.color,
        spanGaps: 2,
      }
    ]
  };
  return {
    type: 'line',
    data: data,
    options: {
      scales: {
        xAxes: [{
          ticks: {
            autoSkip: true,
            maxRotation: 60,
            minRotation: 60,
          },
          gridLines: {
            display: true
          }
        }],
        yAxes: [{
          ticks: {
            precision: 0,
            stepSize: 1,
          }
        }],
      },
      legend: {
        position: 'bottom',
        align: 'center',
        reverse: true,
      },
      tooltips: {
        callbacks: {
          title: function () { return null },
          label: function (tooltipItem: any, data: any) {
            const { index } = tooltipItem;
            return tooltipLabels[index]
          },
          afterLabel: function (tooltipItem: any, data: any) {
            const { datasetIndex, yLabel } = tooltipItem;
            const { label } = data.datasets[datasetIndex];
            return `${label}: ${yLabel}`;
          },
        }
      }
    }
  };
}

export const CampaignsBarChart: React.FunctionComponent<Props> = ({
  companyId,
  loading,
}) => {
  const toDay = moment().startOf('day');
  const [endDate, setEndDate] = useState<moment.Moment | null>(toDay);
  const [initialDate, setInitialDate] = useState<moment.Moment | null>(toDay.clone().subtract(30, 'days'));
  const history = useSelector(selectCampaignHistory);
  const dispatch = useDispatch();
  useEffect(() => {
    if (initialDate && endDate) {
      dispatch(fetchCampaignHistory(initialDate, endDate, companyId));
    }
  }, [dispatch, initialDate, endDate, companyId]);
  const chart1Config = getChartFullConfig({
    line2: {
      label: 'Campañas deshabilitadas',
      data: history.disabled.map(item => item.y),
      color: PIECHART_COLORS[0]
    },
    line1: {
      label: 'Campañas habilitadas',
      data: history.enabled.map(item => item.y),
      color: PIECHART_COLORS[1],
    },
    labels: history.enabled.map(item => item.x),
    tooltipLabels: history.enabled.map(item => item.x),
  });

  const onChange = (dates: RangeValue<moment.Moment>) => {
    if (dates) {
      setInitialDate(dates[0]);
      setEndDate(dates[1]);
      return;
    }
    setInitialDate(null);
    setEndDate(null);
  };

  return (
    <>
      <div className={styles.title}>Campañas habilitadas y deshabilitadas</div>
      {loading ? (
        <LoadingOverlay inline height={420} spinning />
      ) : (
        <>
          <Space direction="vertical" size={12}>
            <RangePicker
              value={[initialDate, endDate]}
              defaultValue={[initialDate, endDate]}
              // disabled={[true, false]}
              onChange={(values) => onChange(values)}
            />
            <div></div>
          </Space>
          <Line {...chart1Config} height={220} />
        </>
      )}
    </>
  );
};
