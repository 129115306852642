import React, { FunctionComponent } from 'react';
import { useSelector } from 'react-redux';
import { Card, Button, Switch } from 'antd';

import { selectTopics } from '../../features/topic/topicSlice';
import { ICourse, TopicsLessonsSelectedDisabled } from '../../features/courses/coursesSlice';
import { CogIcon } from '../common/CogIcon';

import styles from './topicsSelected.module.scss';
import { selectLessons, selectLesson } from '../../features/lesson/lessonSlice';

interface TopicsSelectedProps {
    course?: ICourse,
    selectedTopics: string[],
    setCourse: Function,
}

interface TopicSelectedProps {
    topicId: string,
    topicsLessonsDisabled: TopicsLessonsSelectedDisabled,
    onRemove: Function,
    onLessonChnageDisable: Function,
}

interface TopicsLessonsProps {
    selectedLessons: string[],
    topicsLessonsDisabled: TopicsLessonsSelectedDisabled,
    onLessonChnageDisable: Function
}
interface TopicsLessonProps {
    lessonId: string,
    disabled: boolean,
    onLessonChnageDisable: Function,
}

const TopicsLesson: FunctionComponent<TopicsLessonProps> = ({ lessonId, disabled, onLessonChnageDisable }) => {
    const lesson = useSelector(selectLesson(lessonId));
    if (!lesson) {
        return null;
    }
    return <div className={styles.lesson}>
        <div className={styles.handler}><CogIcon icon="drag" /></div>
        <div className={styles.innerContainer}>
            <div className={styles.title}>
                {lesson.title}
            </div>
            <div className={styles.disableSwitch}>
                Habilitado <Switch onChange={(value) => { onLessonChnageDisable(lessonId, value) }} checked={!disabled} size="small" />
            </div>
        </div>
    </div>;
}
const TopicsLessons: FunctionComponent<TopicsLessonsProps> = ({ selectedLessons, topicsLessonsDisabled, onLessonChnageDisable }) => {
    const lessons = useSelector(selectLessons);
    if (!lessons) {
        return <div>No hay lecciones seleccionadas</div>;
    }
    const result = selectedLessons.map(lessonId => {
        return <TopicsLesson onLessonChnageDisable={onLessonChnageDisable} disabled={!!topicsLessonsDisabled[lessonId]} key={lessonId} lessonId={lessonId} />
    });
    return <div className={styles.lessons}>
        {result}
    </div>
};
const TopicSelected: FunctionComponent<TopicSelectedProps> = ({ topicId, onRemove, topicsLessonsDisabled, onLessonChnageDisable }) => {
    const topics = useSelector(selectTopics);
    const topic = topics[topicId];
    if (!topic) {
        return null;
    }
    return <Card className={styles.topic}>
        <div className={styles.title}>
            {topic.title}
        </div>
        <div className={styles.description}>
            {topic.description}
        </div>
        <div className={styles.separator} />
        <TopicsLessons onLessonChnageDisable={onLessonChnageDisable} selectedLessons={topic.lessons} topicsLessonsDisabled={topicsLessonsDisabled} />
        <div className={styles.separator} />
        <Button className={styles.removeButton} type="default" onClick={() => onRemove()}><CogIcon color="#81858B" icon="Delete" /></Button>
    </Card>
};
export const TopicsSelected: FunctionComponent<TopicsSelectedProps> = ({ selectedTopics, setCourse, course }) => {
    const topicsLessonsDisabled = course && course.topicsLessonsDisabled ? course.topicsLessonsDisabled : {};
    return <div className={styles.topics}>
        {selectedTopics.map((selectedTopic, index) => {
            return <TopicSelected
                topicsLessonsDisabled={topicsLessonsDisabled}
                key={index} topicId={selectedTopic}
                onRemove={() => {
                    const currentTopics = [...selectedTopics];
                    currentTopics.splice(index, 1);
                    setCourse({ ...course, topics: currentTopics })
                }}
                onLessonChnageDisable={(lessonId: string, value: boolean) => {
                    setCourse({ ...course, topicsLessonsDisabled: { ...topicsLessonsDisabled, [lessonId]: !value } })
                }}
            />
        })}
    </div>
};