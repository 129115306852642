import React from 'react';
import cls from 'classnames';
import {
  Row,
  Col,
} from 'antd';
import styles from './profileLytBase.module.scss';

interface ProfileLayoutProps {
  children: React.ReactNode;
  classname?: string,
  panelHeight?: string,
  topPanelColor?: string,
}

export const ProfileBase: React.FunctionComponent<ProfileLayoutProps> = ({ children, panelHeight, topPanelColor = 'e8ebf3' }) => {
  return (
    <React.Fragment>
      <div className={cls(styles.bkgndPanel, panelHeight)} style={{ backgroundColor: topPanelColor }}></div>
      <div className={styles.content}>
        <Row>
          <Col span={24}>
            {children ? children : null}
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
}
