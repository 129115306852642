import React, { FunctionComponent, useState } from 'react';
import { useSelector } from 'react-redux';
import { Dropdown, Radio, Button, Select } from 'antd';
import ctx from 'classnames';
import { RadioChangeEvent } from 'antd/lib/radio';
import { SizeType } from "antd/lib/config-provider/SizeContext";

import {
    selectCategoriesDict,
} from '../../features/category/categorySlice';
import {
    selectStudentMyCourses,
} from '../../features/students/myCoursesSlice';
import { HandleFilterParams } from '../Students/Courses/MyCourseSearch';

import styles from './shared.module.scss';

const { Option } = Select;

interface CoursesFilterMenuProps {
    onApply: (params: HandleFilterParams) => void,
    closeMenu: Function
}
const FilterMenu: FunctionComponent<CoursesFilterMenuProps> = ({ onApply, closeMenu }) => {
    const params = new URLSearchParams(window.location.search);
    const [selectedCategory, setSelectedCategory] = useState<string>(params.get('category') || '');
    const [selectedTags, setSelectedTags] = useState<string[]>([]);
    const categoriesDict = useSelector(selectCategoriesDict);
    const plainOptions = Object.keys(categoriesDict)
        .map(categoryId => ({ label: categoriesDict[categoryId].name, value: categoryId }));
    const allCourses = useSelector(selectStudentMyCourses);
    const tags = allCourses.map(obj => obj.course).sort().reduce((acc: string[], item) => {
        return acc.concat(item.tags);
    }, []).sort();

    const onChange = (e: RadioChangeEvent) => {
        if (e.target.value) {
            setSelectedCategory(e.target.value || '');
        };
    };
    const onApplyFilter = () => {
        onApply({
            tags: [...selectedTags],
            categoryId: selectedCategory,
        });
        closeMenu();
    };
    const onClearFilter = () => {
        onApply({
            tags: [],
            categoryId: '',
        });
        closeMenu();
        setSelectedCategory('');
        setSelectedTags([]);
    };
    const children = tags.map(tag => <Option key={tag} value={tag}>{tag}</Option>)
    const handleTagsChange = (value: string[]) => {
        setSelectedTags(value);
    };
    return <div className={ctx(styles.filterMenu, styles.noHeightLimit)}>
        <div className={styles.innerContainer}>
            <div className={styles.title}>
                Categorias
            </div>
            <div style={{ paddingTop: '15px', paddingBottom: '15px' }}>
                <Radio.Group
                    className={ctx(styles.radioGroup, styles.vertical)}
                    options={plainOptions}
                    onChange={onChange}
                    value={selectedCategory}
                />
            </div>
            <div className={styles.title}>
                Palabras claves
            </div>
            <div style={{ paddingTop: '15px', paddingBottom: '15px' }}>
                <Select
                    mode="tags"
                    value={[...selectedTags]}
                    size={"default" as SizeType}
                    onChange={handleTagsChange}
                    style={{ width: '100%' }}
                    tokenSeparators={[" ", ","]}
                >
                    {children}
                </Select>
            </div>
        </div>
        <div className={styles.actionButton}>
            <Button disabled={!selectedCategory && !(selectedTags.length > 0)} type='link' onClick={onClearFilter}>Limpiar filtros</Button>
            <Button type='link' onClick={onApplyFilter}>Aplicar filtros</Button>
        </div>
    </div>;
}
interface CoursesFilteProps {
    onApply: (params: HandleFilterParams) => void,
}

export const CoursesFilter: FunctionComponent<CoursesFilteProps> = ({ onApply }) => {
    const [visible, setVisible] = useState<boolean>(false);

    return <Dropdown
        overlay={<FilterMenu closeMenu={() => setVisible(false)} onApply={onApply} />}
        trigger={['click']}
        onVisibleChange={(visible => setVisible(visible))}
        visible={visible}
    >
        <div className={styles.filterButton}>
            Filtros
            <div className={styles.filterIcon}>
                <div className={styles.filterIconTopLine} />
                <div className={styles.filterIconBottomLine} />
            </div>
        </div>
    </Dropdown>
};