import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Dispatch } from "react";
import { Microservices } from "../../../app/AllowedMicroservices";
import { RootState } from "../../../app/store";
import apiActionCreator, {
  HttpMethods,
} from "../../../services/apiActionCreator";
import { FetchStatus } from "../../common/enums";
import { DataState, sortReportValue } from "../common";

interface IResponseItem {
  parentId: string;
  title: string;
  score: number | string;
}

const initialState: DataState = {
  data: [],
  status: FetchStatus.Idle,
};

export const dataSlice = createSlice({
  name: "scoreCompanyByTopics",
  initialState,
  reducers: {
    fetching: (state) => {
      state.status = FetchStatus.Fetching;
    },
    fetched: (state, action: PayloadAction<IResponseItem[]>) => {
      state.data = action.payload.map((item) => {
        return {
          key: item.parentId,
          label: item.title,
          value: item.score,
        };
      });
      state.data.sort(sortReportValue);
      state.status = FetchStatus.Fetched;
    },
    error: (state) => {
      state.data = [];
      state.status = FetchStatus.Error;
    },
  },
});

export const { fetching, fetched, error } = dataSlice.actions;

export const fetchScoreCompanyByTopic = (companyId: string) => (
  dispatch: Dispatch<object>
) => {
  let endpoint = `/evaluation/results/companyResultsByTopics/${companyId}`;
  return dispatch(
    apiActionCreator({
      endpoint,
      types: {
        requestType: fetching,
        successTypes: [
          {
            actionOrCreator: fetched,
          },
        ],
        errorTypes: [
          {
            actionOrCreator: error,
          },
        ],
      },
      method: HttpMethods.GET,
      microservice: Microservices.EVALUATION_SERVICE,
      authenticated: true,
    })
  );
};

export const selectScoreCompanyByTopicData = (state: RootState) =>
  state.reports.learning.scoreCompanyByTopics.data;
export const selectScoreCompanyByTopicIsFetching = (state: RootState) =>
  state.reports.learning.scoreCompanyByTopics.status === FetchStatus.Fetching;

export default dataSlice.reducer;
