import React, { Fragment } from "react";
import cls from "classnames";
import { Row, Col } from "antd";

import styles from "./printProfileLytBase.module.scss";
import { ProfileBase } from "./ProfileBaseLyt";
import defaultImg from "../../../assets/profile/profile-placeholder.png";

interface ProfileLayoutProps {
  children?: React.ReactNode;
  classname?: string;
  rightTopOptions?: React.ReactNode;
  image?: any;
  leftBottomOptions?: React.ReactNode;
  rightBottomOptions?: React.ReactNode;
  topPanelColor?: string;
}

const renderImage = (imageOption?: string) => {
  if (!imageOption) {
    return null;
  }
  return (
    <Fragment>
      <img className={cls(styles.image)} src={imageOption} alt="profile" />
    </Fragment>
  );
};

const renderLeftBottomOptions = (leftBottomOptions?: React.ReactNode) => {
  if (!leftBottomOptions) {
    return null;
  }
  return <Fragment>{leftBottomOptions}</Fragment>;
};

const renderRightBottomOptions = (rightBottomOptions?: React.ReactNode) => {
  if (!rightBottomOptions) {
    return null;
  }
  return <Fragment>{rightBottomOptions}</Fragment>;
};

export const PrintProfileLayout: React.FunctionComponent<ProfileLayoutProps> = ({
  children,
  image = defaultImg,
  leftBottomOptions,
  rightBottomOptions,
  topPanelColor = "#e8ebf3",
}) => {
  return (
    <ProfileBase panelHeight={styles.height90} topPanelColor={topPanelColor}>
      <div className={styles.content}>
        <Row>
          <Col span={12}>{renderImage(image)}</Col>
        </Row>
        <Row gutter={[32, 0]} style={{ marginTop: "40px" }}>
          <Col span={12}>{renderLeftBottomOptions(leftBottomOptions)}</Col>
          <Col span={12}>{renderRightBottomOptions(rightBottomOptions)}</Col>
        </Row>
        {children ? children : null}
      </div>
    </ProfileBase>
  );
};
