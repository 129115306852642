import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Dispatch } from "react";
import { Microservices } from "../../../../app/AllowedMicroservices";
import { RootState } from "../../../../app/store";
import apiActionCreator, {
  HttpMethods,
} from "../../../../services/apiActionCreator";
import { FetchStatus } from "../../../common/enums";
import { EvaluationTypeEnum } from '../../../evaluation/common/enums';
import { IQuestion } from '../../../evaluation/common/interfaces';

interface IEvaluation {
  _id: string;
  type: EvaluationTypeEnum;
  title: string;
  questions: IQuestion[];
}
interface IResults {
  answers: {
    questionId: string;
    answerId: string;
  }[];
  correctAnswersToPass: number;
  passed: boolean;
  result: number;
  total: number;
  updatedAt: string;
  userId: string;
}
interface IResultsWithResponses {
  evaluation?: IEvaluation
  results: IResults[],
}

interface IEvaluationsResultsWithResponsesState {
  data: IResultsWithResponses,
  status: FetchStatus,
}
const initialState: IEvaluationsResultsWithResponsesState = {
  data: {
    results: [],
  },
  status: FetchStatus.Idle,
};

export const dataSlice = createSlice({
  name: "reportLearningEvaluationsResultsWithResponses",
  initialState,
  reducers: {
    fetching: (state) => {
      state.status = FetchStatus.Fetching;
    },
    fetched: (state, action: PayloadAction<IResultsWithResponses>) => {
      state.data = action.payload;
      state.status = FetchStatus.Fetched;
    },
    error: (state) => {
      state.status = FetchStatus.Error;
    },
  },
});

export const { fetching, fetched, error } = dataSlice.actions;

interface IFetchEvaluationsResultsWithResponses{
  companyId?: string,
  usersIds: string[],
  parentId: string,
  evaluationId: string,
  evaluationType: EvaluationTypeEnum,
}
export const fetchEvaluationsResultsWithResponses = ({ companyId, usersIds, parentId, evaluationId, evaluationType }:IFetchEvaluationsResultsWithResponses) => (dispatch: Dispatch<object>) => {
  let url = '/evaluation/results/evaluationsResultsWithResponses';
  const data: {
    evaluationType: EvaluationTypeEnum;
    companyId?: string,
    usersIds: string[],
    parentId: string,
    evaluationId: string,
  } = {
    companyId,
    usersIds,
    parentId,
    evaluationType,
    evaluationId
  };
  return dispatch(
    apiActionCreator({
      endpoint: url,
      types: {
        requestType: fetching,
        successTypes: [
          {
            actionOrCreator: fetched,
          },
        ],
        errorTypes: [
          {
            actionOrCreator: error,
          },
        ],
      },
      method: HttpMethods.POST,
      microservice: Microservices.EVALUATION_SERVICE,
      authenticated: true,
      data,
    })
  );
};

export const selectEvaluationsResultsWithResponses = (state: RootState) => state.reports.learning.evaluations.evaluationsResultsWithResponses.data;
export const selectEvaluationsResultsWithResponsesIsFetching = (state: RootState) => state.reports.learning.evaluations.evaluationsResultsWithResponses.status === FetchStatus.Fetching;

export default dataSlice.reducer;
