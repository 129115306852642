import React, { FunctionComponent } from 'react';
import ctx from 'classnames';

import styles from './oneColumnLayout.module.scss';

interface OneColumnLayoutProp {
    children: React.ReactNode,
    className?:string,
}

export const OneColumnLayout: FunctionComponent<OneColumnLayoutProp> = ({ children, className }) => {
    return <div className={ctx(styles.container, className)}>
    {children}
</div>;
};