import { Spin } from "antd";
import ctx from "classnames";
import React, { FunctionComponent } from "react";
import { HorizontalBar } from "react-chartjs-2";
import { CHART_COLORS, IReportValue } from "../../../features/reports/common";
import styles from "./barChartSimple.module.scss";
import { buildTooltipsCallbacks } from "./utils";

interface BarChartSimpleProps {
  data: IReportValue[];
  labelColumnTitle: string;
  fetching: boolean;
  stacked?: boolean;
  backgroundColor?: string;
  xMaxValue?: number;
  xMinValue?: number;
  yMaxValue?: number;
  yMinValue?: number;
  height?: number;
  xTickCallBack?(value: any, index: number, values: any): string,
  titleCallback?(tooltipItem: any, data: any): string | string[] | null,
  labelCallback?(tooltipItem: any, data: any): string | string[] | null,
  xStepSize?: number;
}

export const BarChartSimple: FunctionComponent<BarChartSimpleProps> = ({
  data,
  labelColumnTitle,
  fetching,
  stacked,
  backgroundColor = CHART_COLORS[0],
  xMaxValue,
  xMinValue,
  yMaxValue,
  yMinValue,
  height,
  xStepSize,
  xTickCallBack,
  titleCallback,
  labelCallback,
}) => {
  const chartData = {
    labels: [...data.map((row) => row.label)],
    datasets: [
      {
        label: labelColumnTitle,
        borderWidth: 1,
        backgroundColor,
        data: [
          ...data.map((row, index) => ({
            y: row.index ?? index,
            x: row.value,
            date: row.index ?? index,
          })),
        ],
      },
    ],
  };

  let xCallbackObj = {};
  if (xTickCallBack) {
    xCallbackObj = {
      callback: xTickCallBack,
    }
  }
  const tooltips = buildTooltipsCallbacks({ titleCallback, labelCallback });

  return (
    <div className={ctx(styles.container)}>
      <Spin spinning={fetching}>
        <HorizontalBar
          legend={null}
          data={chartData}
          height={height || 400}
          options={{
            maintainAspectRatio: false,
            scales: {
              xAxes: [
                {
                  ticks: {
                    min: xMinValue ?? 0,
                    max: xMaxValue,
                    stepSize: xStepSize,
                    ...xCallbackObj,
                  },
                }
              ],
              yAxes: [
                {
                  ticks: {
                    stepSize: 1,
                    min: yMinValue ?? 0,
                    max: yMaxValue,
                  },
                  stacked,
                },
              ],
            },
            ...tooltips,
          }}
        />
      </Spin>
    </div>
  );
};
