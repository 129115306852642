import React, { FunctionComponent } from 'react';
import { useSelector } from 'react-redux';
import ctx from 'classnames';

import {
    selectStudentCourseTopicIds
} from '../../../features/students/courseSlice';

import styles from './courseTopics.module.scss';
import { StudentCourseTopic } from './CourseTopic';

interface StudentCourseTopicsProps {}

export const StudentCourseTopics: FunctionComponent<StudentCourseTopicsProps> = () => {
    const topicIds = useSelector(selectStudentCourseTopicIds);
    if(!topicIds || topicIds.length === 0){
        return null;
    }
    let lastId: string;
    return <div className={ctx(styles.container)}>
        <div className={styles.title}>Temas</div>
        {topicIds.map((topicId, index)=>{
            const topic = <StudentCourseTopic lastTopicId={lastId} key={topicId} id={topicId} isFirst={index === 0} />;
            lastId = topicId;
            return topic;
        })}
    </div>
}
