import React from 'react';
import {
  Link
} from "react-router-dom";
import { Menu, Layout, Button } from 'antd';
import { useHistory } from 'react-router-dom';
import ctx from 'classnames';
import { ButtonType } from 'antd/lib/button'
import styles from './index.module.scss';
import { CogIcon } from '../common/CogIcon';

import logo from '../../../src/assets/logo-teurona.svg';
import { useDispatch, useSelector } from 'react-redux';
import { getCognitoUserData, selectCompanyId, selectUserRole } from '../../features/auth/authSlice';
import { UserRole, UsersGroups } from '../../features/users/usersSlice';
import { ProfilePill } from '../LoggedUser/ProfilePill';
import {
  selectUserAdmin,
  selectUserStudent, changeToAdmin, changeToStudent
} from '../../features/userMode/UserMode';
import { Breadcrumbs } from '../common/Breadcrumbs';
import { BASE_REPORT_URL, ReportTypes, ReportRoles, menuTitles } from '../Reports/MenuAndRoutes';
import { NotificationMenu } from '../LoggedUser/NotificationMenu';
import { analytics } from '../../services/analytics';
import { selectCompany } from '../../features/company/companySlice';

interface IMainMenus {
  key: string,
  title: string
}

const SupervisorMenu: IMainMenus[] = [
  {
    key: 'notifications',
    title: 'Notificaciones',
  },
];

const CompanyAdminMenu: IMainMenus[] = [
  {
    key: 'admin-users',
    title: 'Usuarios administrativos',
  },
  {
    key: 'groups',
    title: 'Grupos',
  },
  {
    key: 'students',
    title: 'Estudiantes',
  },
  {
    key: 'notifications',
    title: 'Notificaciones',
  },
];

const SuperAdminMenu: IMainMenus[] = [
  {
    key: 'companies',
    title: 'Empresas',
  },
  {
    key: 'admin-users',
    title: 'Usuarios administrativos',
  },
  {
    key: 'groups',
    title: 'Grupos',
  },
  {
    key: 'students',
    title: 'Estudiantes',
  },
  {
    key: 'notifications',
    title: 'Notificaciones',
  },
];

const CompanyAdminTrainingMenu: IMainMenus[] = [
  {
    key: 'programs',
    title: 'Programas',
  },
  {
    key: 'courses',
    title: 'Cursos',
  },
  {
    key: 'topics',
    title: 'Temas',
  },
  {
    key: 'lessons',
    title: 'Lecciones',
  },
  {
    key: 'evaluations',
    title: 'Evaluaciones',
  },
  {
    key: 'knowledgeBase',
    title: 'Bases de conocimiento',
  },
  {
    key: 'campaigns',
    title: 'Campañas',
  },
];

const SuperAdminTrainingMenu: IMainMenus[] = [
  {
    key: 'programs',
    title: 'Programas',
  },
  {
    key: 'courses',
    title: 'Cursos',
  },
  {
    key: 'topics',
    title: 'Temas',
  },
  {
    key: 'lessons',
    title: 'Lecciones',
  },
  {
    key: 'evaluations',
    title: 'Evaluaciones',
  },
  {
    key: 'certificates',
    title: 'Certificados',
  },
  {
    key: 'knowledgeBase',
    title: 'Bases de conocimiento',
  },
  {
    key: 'campaigns',
    title: 'Campañas',
  },
];

const TRAINING_MENU: Record<UserRole, IMainMenus[]> = {
  "SuperAdmin": SuperAdminTrainingMenu,
  "CompanyAdmin": CompanyAdminTrainingMenu,
  "Supervisor": [],
};

const ADMIN_MENU: Record<UserRole, IMainMenus[]> = {
  "SuperAdmin": SuperAdminMenu,
  "CompanyAdmin": CompanyAdminMenu,
  "Supervisor": SupervisorMenu,
};

const { SubMenu } = Menu;

interface CognitoAttr {
  [key: string]: any;
}

interface BottomHeaderProps {
  role: UserRole;
}

interface HeadermenuProps {
  isLoggedIn: boolean;
  cognitoData: CognitoAttr | undefined;
}

interface HeaderProps {
  isLoggedIn?: boolean
}

const StudentBottomHeader = () => {
  const history = useHistory();
  const onMenuClick = (info: any) => {
    const { key } = info;
    history.push(`/${key}`);
  }
  return <div className={styles.bottomHeader}>
    <div className={styles.innerBottomHeader}>
      <Menu onClick={onMenuClick} className={styles.menu} theme="light" mode="horizontal" triggerSubMenuAction="click">
        <Menu.Item key="home" className={styles.subMenu}>Home</Menu.Item>
        <Menu.Item key="myCourses" className={styles.subMenu}>Mis cursos</Menu.Item>
      </Menu>
    </div>
  </div>
}

const AdminBottomHeader: React.FunctionComponent<BottomHeaderProps> = ({
  role,
}) => {
  const userRole = useSelector(selectUserRole);
  const companyId = useSelector(selectCompanyId);
  const company = useSelector(selectCompany(companyId || ''));
  
  const history = useHistory();
  const onMenuClick = (info: any) => {
    const { key } = info;
    history.push(`/${key}`);
  }
  let AdminMenu = ADMIN_MENU[role];
  const hasNotification = company?.notificationTypesEnabled?.email ||
    company?.notificationTypesEnabled?.sms ||
    company?.notificationTypesEnabled?.web;
  if(!hasNotification){
    AdminMenu = AdminMenu.filter(menu => menu.key !== 'notifications');
  }
  const TrainingMenu = TRAINING_MENU[role];
  return <div className={styles.bottomHeader}>
    <div className={styles.innerBottomHeader}>
      <Menu onClick={onMenuClick} className={styles.menu} theme="light" mode="horizontal" triggerSubMenuAction="click">
        <SubMenu className={styles.subMenu} popupClassName={styles.subMenuContainer} key="admin" title="Administración">
          {AdminMenu?.map(menu => (<Menu.Item key={menu.key}>{menu.title}</Menu.Item>))}
        </SubMenu>
        {
          TrainingMenu.length > 0 &&
          <SubMenu className={styles.subMenu} popupClassName={styles.subMenuContainer} key="training" title="Capacitación">
            {TrainingMenu?.map(menu => (<Menu.Item key={menu.key}>{menu.title}</Menu.Item>))}
          </SubMenu>
        }
        <SubMenu className={styles.subMenu} popupClassName={styles.subMenuContainer} title="Reportes y Dashboards">
          {Object.values(ReportTypes).filter(reportType => 
            (userRole && ReportRoles[reportType].includes(userRole))
          ).map(reportType => {
            return (
              <Menu.Item key={`${BASE_REPORT_URL}${reportType}`}>{menuTitles[reportType]}</Menu.Item>
            );
          })}
        </SubMenu>
      </Menu>
    </div>
  </div>
}

const BottomHeader: React.FunctionComponent<HeadermenuProps> = ({
  isLoggedIn,
  cognitoData,
}) => {
  const isUserAnAdmin = useSelector(selectUserAdmin);
  if (!isLoggedIn || !cognitoData) {
    return null;
  }
  if (isUserAnAdmin && cognitoData['cognito:groups'].includes(UsersGroups.Administrators)) {
    const role = cognitoData['custom:role'];
    return <AdminBottomHeader role={role} />;
  }
  return <StudentBottomHeader />;
};

const UserMode: React.FunctionComponent<HeadermenuProps> = ({
  isLoggedIn,
  cognitoData,
}) => {
  const isUserAStudent = useSelector(selectUserStudent);
  const history = useHistory();
  const dispatch = useDispatch();
  if (!isLoggedIn || !cognitoData) {
    return null;
  }
  if (!cognitoData['cognito:groups'].includes(UsersGroups.Administrators)) {
    return null;
  }
  let adminButtonType: ButtonType = 'primary';
  let studentButtonType: ButtonType = 'link';
  if (isUserAStudent) {
    adminButtonType = 'link';
    studentButtonType = 'primary';
  }
  return <div className={styles.userMode}>
    <div className={styles.text}>Modo:</div>
    <Button className={styles.button} type={adminButtonType} block onClick={() => {
      dispatch(changeToAdmin());
      history.push('/welcome');
    }}>
      Administrador
    </Button>
    <Button className={styles.button} type={studentButtonType} block onClick={() => {
      dispatch(changeToStudent());
      history.push('/home');
    }}>
      Estudiante
    </Button>
  </div>;
};

const KNOWLEDGE_BASES_KEY = 'knowledgeBases';

export const Header: React.FunctionComponent<HeaderProps> = (props) => {
  const { isLoggedIn = false } = props;
  const isUserAStudent = useSelector(selectUserStudent);
  const cognitoData = useSelector(getCognitoUserData);
  const history = useHistory();
  const onMenuClick = (info: any) => {
    const { key } = info;
    if (key === KNOWLEDGE_BASES_KEY) {
      analytics.selectKnowledgeBases();
    }
    history.push(`/${key}`);
  }
  return <Layout.Header className={ctx(styles.header, {
    [styles.longHeader]: isLoggedIn
  })}>
    <div className={styles.topHeader}>
      <div className={styles.innerTopHeader}>
        <div className={styles.logo}>
          <Link to="/"><img src={logo} alt="logo" /></Link>
        </div>
        {
          cognitoData &&
          cognitoData['cognito:groups'].includes(UsersGroups.Administrators) &&
          cognitoData['cognito:groups'].includes(UsersGroups.Students) &&
          <UserMode isLoggedIn={isLoggedIn} cognitoData={cognitoData} />
        }
      </div>
      {
        isLoggedIn ?
          <div className={styles.right}>
            {isUserAStudent ? <Menu onClick={onMenuClick} className={styles.kbMenu} theme="light" mode="horizontal" triggerSubMenuAction="click">
              <Menu.Item key={KNOWLEDGE_BASES_KEY} className={styles.subMenu}>
                <CogIcon size="1.2em" icon='book' color='#81858b' className={styles.icon} />
                <span className={styles.text}>
                  Bases de conocimiento
              </span>
              </Menu.Item>
            </Menu> : null}
            <NotificationMenu />
            <ProfilePill />
          </div> : null
      }
    </div>
    <BottomHeader isLoggedIn={isLoggedIn} cognitoData={cognitoData} />
    <Breadcrumbs />
  </Layout.Header>;
}
