import React, { FunctionComponent } from 'react';
import cls from 'classnames';

import styles from './contanier.module.scss';

interface ContainerProps {
  className?: string 
}

export const RoundedContainer: FunctionComponent<ContainerProps> = ({children, className}) => {
  return <div className={cls(styles.container, className)}>
    {children}
  </div>
};