import React from 'react';
import cls from 'classnames';
import { Card, Button } from 'antd';
import styles from './card.module.scss';

interface CardProps {
  logo: string,
  title: string,
  description: string,
  link: string,
}

export interface CardData extends CardProps {};

export const CardItem: React.FunctionComponent<CardProps> = ({ logo, title, description, link }) => {
  const handleLink = () => {
    window.open(`${link}`, '_blank');
  }
  return (
    <Card className={cls(styles.card, styles.force)} bodyStyle={{padding: 0}}>
      <div className={styles.container}>
        <div className={styles.top}>
          <div className={styles.logoContainer}>
            <img className={styles.logo} src={logo} alt='logo' />
          </div>
          <div className={styles.title}>{title}</div>
        </div>
        <div className={styles.bottom}>
          <div className={styles.description}>{description}</div>
          <Button
            className={cls(styles.link)}
            onClick={handleLink}
          >
            <span>Descargar</span>
          </Button>
        </div>
      </div>
    </Card>
  );
}