import React, { FunctionComponent } from "react";
import styles from './dot.module.scss';

interface DotProps {
  color: string,
  value: number
  suffix?: string,
}

export const Dot: FunctionComponent<DotProps> = ({ color,  value, suffix }) => {
  const roundedValue = Math.round(value);

  return (
    <div className={styles.dotMainWrapper}>
      <div className={styles.dot} style={{ backgroundColor: `${color}` }}></div>
      <div className={styles.label}>{roundedValue}{suffix}</div>
    </div>
  )

}