import { Dispatch } from 'react';

import { Microservices } from './../../app/AllowedMicroservices';
import { HttpMethods } from './../../services/apiActionCreator';
import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import apiActionCreator from '../../services/apiActionCreator';

export enum NotificationsStatusEnum {
  IDLE = 'IDLE',
  REGISTERING = 'REGISTERING',
  REGISTERED = 'REGISTERED',
  ERROR = 'ERROR',
}
interface MesageState {
  status: NotificationsStatusEnum;
}

const initialState: MesageState = {
  status: NotificationsStatusEnum.IDLE,
};

export interface ISetMessage {
  message: string;
}

export const registerClientSlice = createSlice({
  name: 'registerClient',
  initialState,
  reducers: {
    registering: (state) => {
      state.status =NotificationsStatusEnum.REGISTERING;
    },
    registered: (state) => {
      state.status = NotificationsStatusEnum.IDLE;
    },
    error: (state) => {
      state.status = NotificationsStatusEnum.IDLE;
    },
  },
});

export const { registering, registered, error } = registerClientSlice.actions;


interface IRegisterClient {
  firebaseClientId: string, 
}

export const registerClient = ({
  firebaseClientId
}:IRegisterClient) => (dispatch: Dispatch<object>) => {
  const data = {
    firebaseClientId
  }
  const endpoint = '/notifications/register/client'; 
  
  return dispatch(
      apiActionCreator({
          endpoint,
          types: {
              requestType: registering,
              successTypes: [
                  {
                      actionOrCreator: registered,
                      
                  },
              ],
              errorTypes: [
                  {
                      actionOrCreator: error,
                  }
              ],
          },
          method: HttpMethods.POST,
          microservice: Microservices.COMPANY_SERVICE,
          authenticated: true,
          data,
      })
  );
};
export const selectRegisterClientStatus = (state: RootState) => state.registerClient.status;

export default registerClientSlice.reducer;
