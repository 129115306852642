import { ICourse } from "../courses/coursesSlice";
import { ICourseRules } from "../program/programSlice";
import { ITopic } from "../topic/topicSlice";

interface courseProgress {
  startedAt: string;
}

interface Progress {
  startedAt: Date;
  lastProgress: Date;
  finishedAt?: Date;
  progress: number; // 0 -> 1
}

export interface ILessonProgress extends Progress {
  _id: string;
  lessonId: string;
}

export interface topicProgress extends Progress {
  topicId: string;
}

export interface IProgress {
  username: string;
  course: string;
  courseProgress?: courseProgress;
  lessons: ILessonProgress[];
  topics: topicProgress[];
}

export interface IStudentCourse {
  course: ICourse;
  extra?: ICourseRules;
  progress?: IProgress;
  topics: ITopic[];
  isCompleted: boolean;
  isStarted: boolean;
  advancePercentage: number;
}
export interface IBoockedCourses {
  [_id: string]: boolean;
}

export const isCourseBlocked = (
  courses: IStudentCourse[],
  currentCourse: IStudentCourse
): boolean => {
  if (!currentCourse.extra || !currentCourse.extra.hasPredecessor) {
    return false;
  }
  for (const predecessor of currentCourse.extra.predecessor) {
    const predecessorCourse = courses.find(
      ({ course }) => course._id === predecessor
    );
    if (!predecessorCourse && predecessor) {
      return true;
    }
    if (predecessorCourse && predecessorCourse.advancePercentage < 1) {
      return true;
    }
  }
  return false;
};
