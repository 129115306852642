import React, { FunctionComponent, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectCompanies, selectCompaniesAreFetching } from '../../../../features/company/companySlice';
import {
  IUserSessionDuration,
  selectUsersSessionDuration,
  selectUsersSessionDurationIsFetching
} from '../../../../features/reports/users/sessionDuration';
import LoadingOverlay from '../../../common/LoadingOverlay';
import { RoundedContainer } from '../../../common/RoundedContainer';
import { StatusEnum } from '../../../common/Status';
import { IconNames } from '../../common/SimpleStat';
import { StatData, StatsRow } from '../../common/StatsRow';

interface StatsStructure {
  allCompaniesCount: number,
  enabledCompaniesCount: number,
  companiesWithAtLeastOneActiveUserCount: number,
}

interface LeftCompaniesIndicatorsProps { }

export const LeftCompaniesIndicators: FunctionComponent<LeftCompaniesIndicatorsProps> = () => {
  const isCompaniesFetching = useSelector(selectCompaniesAreFetching);
  const isUserSessionDurationFetching = useSelector(selectUsersSessionDurationIsFetching);
  const dataIsFetching = isCompaniesFetching || isUserSessionDurationFetching;
  const [counters, setCounters] = useState<StatsStructure>({
    allCompaniesCount: 0,
    enabledCompaniesCount: 0,
    companiesWithAtLeastOneActiveUserCount: 0,
  });

  const allCompanies = useSelector(selectCompanies);
  const userSessionDurationData: IUserSessionDuration[] = useSelector(selectUsersSessionDuration);

  useEffect(() => {
    if (dataIsFetching) {
      return
    };

    const activeCompaniesIds: string[] =
      userSessionDurationData
        .map(userData => userData.companyId)
        .filter((value, index, self) => self.indexOf(value) === index).filter(id => id.length > 10);
    const allCompaniesArr = Object.values(allCompanies);
    const companiesEnabledCount = allCompaniesArr.filter(company => company.status === StatusEnum.Enable).length;

    setCounters({
      allCompaniesCount: allCompaniesArr.length,
      enabledCompaniesCount: companiesEnabledCount,
      companiesWithAtLeastOneActiveUserCount: activeCompaniesIds.length,
    });

  }, [allCompanies, dataIsFetching, userSessionDurationData]);

  const statsData = buildStatsData(counters);

  return (
    <RoundedContainer>
      {dataIsFetching ?
        <LoadingOverlay inline height={185} spinning /> :
        <StatsRow statsData={statsData} />
      }
    </RoundedContainer>
  );
}

const buildStatsData = (data: StatsStructure) => {
  const statsData: StatData = [];

  statsData.push({
    iconName: IconNames.Company,
    mainText: `${data.allCompaniesCount}`,
    description: 'Empresas registradas',
    tooltipText: 'Información desde el primer día',
  });

  statsData.push({
    iconName: IconNames.CompanyEnabled,
    mainText: `${data.enabledCompaniesCount}`,
    description: 'Empresas habilitadas',
    tooltipText: 'Información desde el primer día',
  });

  statsData.push({
    iconName: IconNames.CompanyActive,
    mainText: `${data.companiesWithAtLeastOneActiveUserCount}`,
    description: 'Empresas activas',
    tooltipText: 'Empresas en las que al menos un usuario haya ingresado activamente a la plataforma',
  });

  return statsData;
}
