import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';

export enum MessageTypeEnum {
  INFO = 'info',
  ERROR = 'error',
  WARNING = 'warning',
}
interface MesageState {
  message: string | boolean,
  type: MessageTypeEnum,
}

const initialState: MesageState = {
  message: false,
  type: MessageTypeEnum.INFO,
};

export interface ISetMessage {
  message: string
};

export const messageSlice = createSlice({
  name: 'message',
  initialState,
  reducers: {
    setMessageInfo: (state, action: PayloadAction<ISetMessage>) => {
      state.message = action.payload.message;
      state.type = MessageTypeEnum.INFO
    },
    setMessageError: (state, action: PayloadAction<ISetMessage>) => {
      state.message = action.payload.message;
      state.type = MessageTypeEnum.ERROR
    },
    setMessageWarning: (state, action: PayloadAction<ISetMessage>) => {
      state.message = action.payload.message;
      state.type = MessageTypeEnum.WARNING
    },
    clearMessage: (state) => {
      state.message = false;
      state.type = MessageTypeEnum.INFO
    },
  },
});

export const { setMessageInfo, setMessageError, setMessageWarning, clearMessage } = messageSlice.actions;

export const selectMessage = (state: RootState) => state.message;

export default messageSlice.reducer;
