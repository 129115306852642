import React from 'react';
import cls from 'classnames';
import { history } from '../../App';
import panelStyles from './Panel.module.scss';
import mainStyles from './PasswordChange.module.scss';

import {
  Col,
  Row,
  Card,
  Button,
} from 'antd';

export function PasswordChange() {
  return (
    <React.Fragment>
      <div className={cls(panelStyles.Bkgnd)}></div>
      <div>
        <Row>
          <Col xs={{ span: 18, offset: 3 }} md={{ span: 8, offset: 8 }} className={cls(mainStyles.card)}>
            <Row>
              <Col span={22} offset={1}>
                <Card>
                  <h2 className={cls(mainStyles.title)}>Hemos enviado un correo electrónico para recuperación de contraseña</h2>
                  <p className={cls(mainStyles.description)}>Accede al link del correo para cambiar tu contraseña</p>
                  <Button className={cls(mainStyles.button)}
                    onClick={() => { history.replace('/login') }}
                    type="primary">
                    Volver
                  </Button>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
}
