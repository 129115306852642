import { Button, Form, Table } from "antd";
import { ColumnsType } from "antd/es/table";
import { FormInstance } from "antd/lib/form";
import moment from "moment";
import React, { ReactText, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCognitoUserData } from "../../features/auth/authSlice";
import {
  fetchCompanies,
  selectCompanies,
  selectCompaniesAreFetching,
} from "../../features/company/companySlice";
import { fetchCourses } from "../../features/courses/coursesSlice";
import {
  createGroup,
  disableGroupsStatus,
  enableGroupsStatus,
  fetchGroups,
  hideModal,
  IGroup,
  selectAreGroupsFetching,
  selectDisplayModal,
  selectGroups,
  updateGroup,
  selectCreatedGroup,
} from "../../features/groups/groupSlice";
import { fetchPrograms } from "../../features/program/programSlice";
import { UserRole } from "../../features/users/usersSlice";
import { PAGE_SIZE } from "../../utils/constants";
import { DialogPopUp } from "../common/DialogPopUp";
import { CogDrawer } from "../common/Drawer";
import { MainLayout } from "../common/Layouts/Main";
import { Status, StatusEnum } from "../common/Status";
import tableStyles from "../common/table.module.scss";
import { TableTools } from "../common/TableTools";
import { CompanyFilter } from "../Filters/Company";
import { useDebounce } from "../utils/useDebounce";
import { GroupForm } from "./Form";
import { analytics } from '../../services/analytics';

function renderOptions(
  toggleVisible: any,
  setNewGroup: any,
  form: FormInstance
) {
  return (
    <Button
      onClick={() => {
        toggleVisible();
        setNewGroup({});
        setTimeout(() => {
          form.resetFields();
          form.setFieldsValue({});
        }, 100);
      }}
      type="primary"
    >
      Nuevo grupo
    </Button>
  );
}
function isValidate(group?: IGroup) {
  if (!group) {
    return false;
  }
  if (!group.company) {
    return false;
  }
  return (group.name || "").trim() === "";
}

export const Group: React.FunctionComponent = () => {
  const companiesList = useSelector(selectCompanies);
  const isCompaniesFetching = useSelector(selectCompaniesAreFetching);
  const areGroupFetching = useSelector(selectAreGroupsFetching);
  const list = [...useSelector(selectGroups)].map((group) => ({
    ...group,
    companyName: companiesList[group.company]?.name,
  }));
  const [visible, setVisible] = useState<boolean>(false);
  const [newGroup, setNewGroup] = useState<IGroup>();
  const [selectedRowKeys, selectRowKeys] = useState<ReactText[]>([]);
  const toggleVisible = () => setVisible(!visible);
  const dispatch = useDispatch();
  const [filterValue, setFilterValue] = useState<ReactText[]>([]);
  const [filteredInfo, setFilteredInfo] = useState<ReactText[]>([]);
  const debounceFilterValue = useDebounce(filterValue, 500);
  const [showActivationBtns, setShowActivationBtns] = useState<boolean>(false);
  const [currentAction, setCurrentAction] = useState<string>("");
  const cognitoData = useSelector(getCognitoUserData);
  const isModalVisible = useSelector(selectDisplayModal);
  const role = cognitoData ? cognitoData["custom:role"] : undefined;
  const cognitoUsername = cognitoData ? cognitoData['cognito:username'] : null;
  const createdGroup = useSelector(selectCreatedGroup);

  useEffect(() => {
    if (createdGroup) {
      analytics.createGroup({
        userName: cognitoUsername,
        companyName: companiesList[createdGroup.company]?.name,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createdGroup]);

  useEffect(() => {
    if (selectedRowKeys.length > 0) {
      setShowActivationBtns(true);
    } else {
      setShowActivationBtns(false);
    }
  }, [selectedRowKeys]);

  const columns: ColumnsType<IGroup> = [
    {
      title: "Nombre",
      dataIndex: "name",
      sortDirections: ["descend", "ascend"],
      defaultSortOrder: "ascend",
      sorter: (a: IGroup, b: IGroup) => a.name.localeCompare(b.name),
      filteredValue: debounceFilterValue,
      onFilter: (value, record) =>
        record.name.toLowerCase().includes(value.toString().toLowerCase()),
      ellipsis: true,
    },
    {
      title: "Fecha de creación",
      dataIndex: "createdAt",
      render: (createdAt) => moment.utc(createdAt).format("DD-MM-YYYY"),
      sortDirections: ["descend", "ascend"],
      sorter: (a: IGroup, b: IGroup) =>
        new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime(),
    },
    {
      title: "Cursos asignados",
      dataIndex: "courses",
      render: (courses: string[]) => courses.length,
    },
    {
      title: "Estado",
      dataIndex: "status",
      render: (status) => <Status status={status} />,
      sortDirections: ["descend", "ascend"],
      sorter: (a: IGroup, b: IGroup) =>
        a.status === b.status ? 0 : a.status === StatusEnum.Enable ? 1 : -1,
    },
    {
      title: "Empresa",
      dataIndex: "companyName",
      sortDirections: ["descend", "ascend"],
      filteredValue: filteredInfo,
      onFilter: (value, record: IGroup) => record.company === (value as string),
      sorter: (a: IGroup, b: IGroup) =>
        (a.companyName || "").localeCompare(b.companyName || ""),
      render: (value, group) => {
        return (
          <React.Fragment>
            {value}
            <div className={tableStyles.actions}>
              <Button
                type="default"
                onClick={() => {
                  setNewGroup(group);
                  form.resetFields();
                  toggleVisible();
                }}
              >
                Editar
              </Button>
            </div>
          </React.Fragment>
        );
      },
    },
  ];

  useEffect(() => {
    dispatch(fetchGroups());
    dispatch(fetchCompanies());
    dispatch(fetchPrograms());
    dispatch(fetchCourses());
  }, [dispatch]);

  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedRowKeys: ReactText[]) => {
      selectRowKeys(selectedRowKeys);
    },
  };
  const disabledSaveButton = isValidate(newGroup);

  const enableStatus = () => {
    const data = {
      ids: selectedRowKeys.map((key) => key.toString()),
    };
    dispatch(enableGroupsStatus(data));
  };

  const disableStatus = () => {
    const data = {
      ids: selectedRowKeys.map((key) => key.toString()),
    };
    dispatch(disableGroupsStatus(data));
  };
  const [form] = Form.useForm();

  return (
    <MainLayout
      title="Grupos"
      rightTopOptions={renderOptions(toggleVisible, setNewGroup, form)}
    >
      <DialogPopUp
        name="Grupo"
        action={currentAction}
        visible={isModalVisible}
        onCancel={() => {
          dispatch(hideModal());
        }}
      />
      <TableTools
        placeholder="Buscar en Teurona"
        onSearch={(value) => setFilterValue([value])}
        onChange={(event) => setFilterValue([event.target.value])}
        onEnable={() => enableStatus()}
        onDisable={() => disableStatus()}
        filter={
          role === UserRole.SuperAdmin ? (
            <CompanyFilter onApply={setFilteredInfo} />
          ) : (
            undefined
          )
        }
        activationBtnsVisible={showActivationBtns}
      />
      <Table<IGroup>
        loading={isCompaniesFetching || areGroupFetching}
        className={tableStyles.table}
        rowClassName={() => tableStyles.row}
        rowKey="_id"
        rowSelection={rowSelection}
        columns={columns}
        dataSource={list}
        pagination={{ position: ["bottomRight"], pageSize: PAGE_SIZE }}
      />
      <CogDrawer
        visible={visible}
        toggleVisible={toggleVisible}
        title={newGroup?._id ? "Editar grupo" : "Nuevo grupo"}
        footer={
          <div>
            <Button onClick={toggleVisible} type="default">
              Cancelar
            </Button>
            &nbsp;&nbsp;
            <Button
              disabled={disabledSaveButton}
              onClick={() => {
                form
                  .validateFields()
                  .then((value) => {
                    if (!newGroup) {
                      return;
                    }
                    if (!newGroup._id) {
                      newGroup.status = StatusEnum.Enable;
                      setCurrentAction("creado");
                      dispatch(createGroup(newGroup));
                    } else {
                      setCurrentAction("actualizado");
                      dispatch(updateGroup(newGroup));
                    }
                    toggleVisible();
                  })
                  .catch((error) => {
                    console.log(
                      "Company:React.FunctionComponent -> error",
                      error
                    );
                  });
              }}
              type="primary"
            >
              {newGroup?._id ? "Actualizar grupo" : "Crear grupo"}
            </Button>
          </div>
        }
      >
        <div>
          {visible ? (
            <GroupForm
              form={form}
              newGroup={newGroup}
              setNewGroup={setNewGroup}
            />
          ) : null}
        </div>
      </CogDrawer>
    </MainLayout>
  );
};
