import { Col, Row } from "antd";
import moment from "moment";
import React, { FunctionComponent, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCognitoUserData } from "../../../features/auth/authSlice";
import {
  fetchCompanies,
  selectCompanies,
  selectCompaniesAreFetching,
} from "../../../features/company/companySlice";
import {
  fetchGroups,
  selectAreGroupsFetching,
  selectGroupsDict,
} from "../../../features/groups/groupSlice";
import {
  fetchUserStats,
  selectUserStats,
  selectUserStatsAreFetching,
} from "../../../features/reports/company/userStats";
import {
  fetchUsersSessionDuration,
  selectUsersSessionDuration,
  selectUsersSessionDurationIsFetching,
} from "../../../features/reports/users/sessionDuration";
import { GenderDict, UserRole } from "../../../features/users/usersSlice";
import { RoundedContainer } from "../../common/RoundedContainer";
import { AnalyticsViewReport } from "../common/AnalyticsViewReport";
import { ReportTypes, sectionTitles } from "../MenuAndRoutes";
import { CompanyTop5 } from "./CompanyTop5";
import { UserDoughnut } from "./UserDoughnut";
import { UsersStateChart } from "./UsersStateChart";
import { UsersTable } from "./UsersTable";
import { UserStatsChart } from "./UserStatsChart";

export enum UserReportType {
  Student = "Student",
  CompanyAdmin = "CompanyAdmin",
  Supervisor = "Supervisor",
}

export enum UserReportRoleDict {
  Student = "Estudiante",
  CompanyAdmin = "Admin. de empresas",
  Supervisor = "Supervisor",
}

const UserReportTypeDict = {
  [UserRole.CompanyAdmin]: UserReportType.CompanyAdmin,
  [UserRole.Supervisor]: UserReportType.Supervisor,
};

export interface UserReportData {
  id: string;
  enabled: boolean;
  active: boolean;
  sessionDuration: number;
  groupName: string;
  groupId: string;
  companyId: string;
  companyName: string;
  gender: string;
  genderName: string;
  age: number;
  userType: UserReportType;
  userTypeName: string;
  userName: string;
  email: string;
  phone: string;
}

interface UserReportsProps {
  companyId: string;
}

export const UsersReports: FunctionComponent<UserReportsProps> = ({
  companyId,
}) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const gaUserSessions = useSelector(selectUsersSessionDuration);
  const companyDict = useSelector(selectCompanies);
  const groupsDict = useSelector(selectGroupsDict);
  const userStats = useSelector(selectUserStats);
  const isFetchingGroups = useSelector(selectAreGroupsFetching);
  const isFetchingCompanies = useSelector(selectCompaniesAreFetching);
  const isFetchinSessions = useSelector(selectUsersSessionDurationIsFetching);
  const isFetchingUserStats = useSelector(selectUserStatsAreFetching);
  const cognitoData = useSelector(getCognitoUserData);
  const role = cognitoData ? cognitoData["custom:role"] : undefined;
  const userCompanyId = cognitoData ? cognitoData["custom:company"] : undefined;

  const currentCompanyId =
    role === UserRole.SuperAdmin ? companyId : userCompanyId;

  const isLoading =
    isFetchingCompanies ||
    isFetchinSessions ||
    isFetchingGroups ||
    isFetchingCompanies;
  const dispatch = useDispatch();

  useEffect(() => {
    const loadStatsData = async () => {
      dispatch(fetchGroups());
      dispatch(fetchCompanies());
      await dispatch(fetchUsersSessionDuration());
      setIsLoaded(true);
    };
    loadStatsData();
  }, [dispatch]);

  useEffect(() => {
    if (isLoaded) {
      dispatch(
        fetchUserStats({
          gausers: gaUserSessions.map((user) => ({
            id: user.userId,
            session: user.session,
          })),
        })
      );
    }
  }, [dispatch, gaUserSessions, isLoaded]);

  const userReportList: UserReportData[] = userStats.users
    .filter((user) => user.role !== UserRole.SuperAdmin)
    .map((user) => {
      const activeUser = gaUserSessions.find(
        (gaUser) => user.username === gaUser.userId
      );
      const currentUser = user;
      const groupId = currentUser.studentGroupId ?? "";
      const userType: UserReportType[] =
        currentUser.role && currentUser.role !== UserRole.SuperAdmin
          ? [UserReportTypeDict[currentUser.role]]
          : [];

      let userTypeName: string[] = userType.map(userType => UserReportRoleDict[userType]);
      if (
        currentUser.studentGroupId
      ) {
        userTypeName.push(UserReportRoleDict[UserReportType.Student]);
      }

      return {
        id: user.username,
        userName: `${currentUser.given_name} ${currentUser.family_name}`,
        companyName: companyDict[currentUser.company ?? ""]?.name,
        enabled: currentUser.enabled,
        active: activeUser !== undefined,
        sessionDuration: activeUser?.session ?? 0,
        groupName: groupsDict[groupId]?.name ?? "",
        groupId,
        companyId: currentUser.company ?? "",
        gender: currentUser.gender ?? "",
        genderName: currentUser.gender ? GenderDict[currentUser.gender] : "",
        age: moment().diff(
          moment(currentUser.birthdate, "YYYY-MM-DD"),
          "years"
        ),
        userType: userType[0],
        userTypeName: userTypeName.join(', '),
        email: currentUser.email,
        phone: currentUser.phone_number,
      };
    });

  const filteredUserReportList = currentCompanyId
    ? userReportList.filter((user) => user.companyId === currentCompanyId)
    : userReportList;
  const activeUsers = filteredUserReportList.filter((user) => user.active);
  const enabledUsers = filteredUserReportList.filter((user) => user.enabled);

  return (
    <Row gutter={[24, 24]} style={{ marginBottom: 46 }}>
      <AnalyticsViewReport
        companyId={currentCompanyId}
        reportName={sectionTitles[ReportTypes.Users]}
      />
      <Col span={16}>
        <RoundedContainer>
          <UserStatsChart
            registerUsersAmount={filteredUserReportList.length}
            enabledUsersAmount={enabledUsers.length}
            activeUsers={activeUsers}
          />
        </RoundedContainer>
      </Col>
      <Col span={8}>
        <RoundedContainer>
          <UserDoughnut enabledUsers={enabledUsers} activeUsers={activeUsers} />
        </RoundedContainer>
      </Col>
      <Col span={12}>
        <RoundedContainer>
          <UsersStateChart
            enabledUsers={enabledUsers}
            companyId={currentCompanyId}
            isLoading={isLoading || isFetchingUserStats}
          />
        </RoundedContainer>
      </Col>
      <Col span={12}>
        <RoundedContainer>
          <CompanyTop5
            topFive={userStats.topFive}
            isLoading={isLoading || isFetchingUserStats}
          />
        </RoundedContainer>
      </Col>
      <Col span={24}>
        <UsersTable
          companyId={currentCompanyId}
          isLoading={isLoading}
          filteredUserReportList={filteredUserReportList}
        />
      </Col>
    </Row>
  );
};
