import { Button, Form } from "antd";
import React, { FunctionComponent, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { clearBreadcrumbs } from "../../features/breadcrumbs/breadcrumbsSlice";
import {
  fetchContent,
  hideModal,
  selectDisplayModal,
  selectIsContentFetching,
  selectJustCreatedContent,
} from "../../features/knowledgeBase/contentSlice";
import { DialogPopUp } from "../common/DialogPopUp";
import { MainLayout } from "../common/Layouts/Main";
import { ContentForm } from "./Form";
import { ContentList } from "./List";
import { analytics } from '../../services/analytics';
import { getCognitoUserData } from '../../features/auth/authSlice';
import {
    selectCompanies,
    fetchCompanies,
} from '../../features/company/companySlice';

function renderOptions(toggleVisible: any) {
  return (
    <Button
      onClick={() => {
        toggleVisible();
      }}
      type="primary"
    >
      Nuevo contenido
    </Button>
  );
}

export const KnowledgeBase: FunctionComponent = () => {
  const [isFormVisible, setFormVisible] = useState<boolean>(false);
  const [editingContent, setEditingContent] = useState<string | null>(null);
  const isModalVisible = useSelector(selectDisplayModal);
  const isFetchingContents = useSelector(selectIsContentFetching);
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!isFormVisible) {
      dispatch(clearBreadcrumbs());
    }
  }, [dispatch, isFormVisible]);

  useEffect(() => {
    if (!isFetchingContents) {
      form.setFieldsValue({});
      form.resetFields();
    }
  }, [form, isFetchingContents]);

  let content;
  if (isFormVisible) {
    content = (
      <ContentForm
        editing={editingContent}
        form={form}
        onDone={() => {
          setFormVisible(false);
          setEditingContent(null);
        }}
      />
    );
  } else {
    const onEdit = (contentId: string) => {
      dispatch(fetchContent(contentId));
      setEditingContent(contentId);
      setFormVisible(true);
    };
    content = <ContentList editContent={onEdit} />;
  }
  const toggleVisible = () => setFormVisible(!isFormVisible);
  return (
    <MainLayout
      hideTitle={isFormVisible}
      hideRightTopOptions={isFormVisible}
      title="Base de conocimiento"
      rightTopOptions={renderOptions(toggleVisible)}
    >
      <DialogPopUp
        name="Excelente"
        action="Creaste una base"
        visible={isModalVisible}
        onCancel={() => {
          dispatch(hideModal());
        }}
      />
      <AnalyticsKnowledgeBaseCreated />
      {content}
    </MainLayout>
  );
};

interface AnalyticsKBCreatedProps { }
const AnalyticsKnowledgeBaseCreated: React.FunctionComponent<AnalyticsKBCreatedProps> = () => {
    const createdKB = useSelector(selectJustCreatedContent);
    const cognitoData = useSelector(getCognitoUserData);
    const cognitoUsername = cognitoData ? cognitoData['cognito:username'] : null;
    const companiesList = useSelector(selectCompanies);
    const dispatch = useDispatch();
    useEffect(() => {
        if (createdKB) {
            analytics.createKnowledgebase({
                userName: cognitoUsername,
                companyName: companiesList[createdKB.companyId]?.name,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [createdKB]);
    useEffect(() => {
        dispatch(fetchCompanies());
    }, [dispatch]);
    return null;
}
