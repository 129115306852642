import { CALL_API } from '../app/apiMiddleware';
import { Microservices } from '../app/AllowedMicroservices';
import { PayloadAction, ActionCreatorWithPayload, ActionCreatorWithoutPayload } from '@reduxjs/toolkit';
import { Dispatch } from 'react';

interface SelectorFun<T,TResult>
{
    (item: T): TResult;
}

interface GeneralAction {
  type: string,
  payload: any
}
type ApiActionCreatorType = (dispatch: Dispatch<object>) => void;

interface ActionWithSelector {
  actionOrCreator: ApiActionCreatorType | ActionCreatorWithPayload<any> | ActionCreatorWithoutPayload | GeneralAction,
  selector?: SelectorFun<object, any>
}

interface FuncPayload
{
    (K: any): PayloadAction;
}

interface Types{
  requestType: PayloadAction | FuncPayload | GeneralAction,
  successTypes: ActionWithSelector[],
  errorTypes: ActionWithSelector[],
}

export enum HttpMethods {
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
  DELETE = 'DELETE',
}

const ApiActionCreator = ({
  endpoint,
  types,
  method,
  microservice,
  authenticated = false,
  data,
  contentType,
  customToken,
}: {
  endpoint: string,
  types: Types,
  method: HttpMethods,
  microservice: Microservices,
  authenticated?: boolean,
  data?: any,
  contentType?: string,
  customToken?: string,
}) => {
  return {
    [CALL_API]: {
      endpoint,
      types,
      method,
      microservice,
      authenticated,
      data,
      contentType,
      customToken,
    },
  };
};

export default ApiActionCreator;