import React, { FunctionComponent } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import cls from 'classnames';
import {
    Button,
    Divider,
} from 'antd';

import { CourseBlock } from './CourseBlock';
import { SIZES } from '../../common/screen'
import { useDeviceSize } from '../../utils/useDeviceSize';
import styles from './myCoursesBlock.module.scss';

interface MyCoursesBlockProps {
    selector: any,
    individualSelector: any,
    title: string,
    showViewMore?: boolean,
    viewMorePath?: string,
    coursesToShow?: number | 'all',
    emptyMessage: string,
}

export const MyCoursesBlock: FunctionComponent<MyCoursesBlockProps> = ({
    selector,
    title,
    individualSelector,
    showViewMore = false,
    viewMorePath = '',
    coursesToShow = 'all',
    emptyMessage,
}) => {
    const history = useHistory();
    const size = useDeviceSize();
    const coursesKeys: number[] = useSelector(selector);
    const coursesKeysSubGroup = coursesToShow === 'all' ? coursesKeys : coursesKeys.slice(0, coursesToShow);

    if (!coursesKeys) {
        return null;
    }
    if(coursesKeys.length === 0) {
        return <div className={styles.container}>
            <div className={styles.titleContainer}>
                <div className={styles.title}>{title} ({coursesKeys.length})</div>
            </div>
            <div className={styles.emptyMessage}>
                {emptyMessage}
            </div>
        </div>
    }
    return <div className={styles.container}>
        <div className={styles.titleContainer}>
            <div className={styles.title}>{title} ({coursesKeys.length})</div>
        </div>
        <div className={styles.innerContainer}>
            {coursesKeysSubGroup.map(courseKey => {
                const returnValue = (
                    <React.Fragment key={courseKey}>
                        <CourseBlock selector={individualSelector} key={courseKey} courseKey={courseKey} />
                        {
                            size === SIZES.PHONE ? (
                                <Divider />
                            ) : null
                        }
                    </React.Fragment>
                )
                return returnValue;
            })}
        </div>
        {
            showViewMore &&
            (
                <div className={styles.moreWrapper}>
                    <Button type='default' className={cls({
                        [styles.width100]: size !== SIZES.DESKTOP,
                    })} onClick={() => { history.push(`${viewMorePath}`); }} disabled={false} >Ver más ({coursesKeys.length})</Button>
                </div>
            )
        }
    </div>
};