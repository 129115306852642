import React, { FunctionComponent, useEffect, useState } from "react";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import { Alert, Button, Input } from "antd";
import Modal from "antd/lib/modal/Modal";

import { PasswordInput } from "./PasswordInput";
import { PasswordRequirements } from "./PasswordRequirements";
import {
  selectChangePasswordSuccess,
  changePassword,
  clearChangePasswordStatus,
  selectChangePasswordError,
  selectChangePasswordFailed,
  selectChangingPassword
} from "../../features/auth/authSlice";

import styles from "./PasswordChangeModal.module.scss";
import { useDispatch, useSelector } from "react-redux";

import successImage from '../../assets/modalIllustrations/success.png';

interface PasswordChangeModalProps {
  visible: boolean;
  onChanged(): void;
  onClose(): void;
}
enum Status {
  Form = 'Form',
  Confirmation = 'Confirmation',
  Feedback = 'Feedback',
}

export const PasswordChangeModal: FunctionComponent<PasswordChangeModalProps> = ({onClose, onChanged, visible}) => {
  const changingPassword = useSelector(selectChangingPassword);
  const changePasswordFailed = useSelector(selectChangePasswordFailed);
  const changePasswordSuccess = useSelector(selectChangePasswordSuccess);
  const error = useSelector(selectChangePasswordError);
  const [status, setStatus] = useState<Status>(Status.Form);
  const [newPassword, setNewPassword] = useState<string>("");
  const [oldPassword, setOldPassword] = useState<string>("");
  const [repeatedPassword, setRepeatedPassword] = useState<string>("");
  const [enableChangeButton, setEnableChangeButton] = useState<boolean>(false);
  const dispatch = useDispatch();
  const handeChangePassword = () => {
    dispatch(changePassword(oldPassword, newPassword));
  };
  const onAllFulfiledChange = (allIsFulfiled: boolean) => {
    setEnableChangeButton(allIsFulfiled);
  };
  useEffect(() => {
    dispatch(clearChangePasswordStatus());
  }, [dispatch]);
  useEffect(() => {
    if(changePasswordSuccess){
      setStatus(Status.Feedback);
      setTimeout(()=>{
        onChanged();
        dispatch(clearChangePasswordStatus());
      }, 3000);
    }
  }, [changePasswordSuccess, dispatch, onChanged]);
  useEffect(()=>{
    if(changePasswordFailed){
      setNewPassword('');
      setOldPassword('');
      setRepeatedPassword('');
      setStatus(Status.Form);
    }
  },[changePasswordFailed]);
  const close = () =>{
    setNewPassword('');
    setOldPassword('');
    setRepeatedPassword('');
    dispatch(clearChangePasswordStatus());
    setStatus(Status.Form);
    onClose();
  };
  const form = <React.Fragment>
    <div className={styles.label}>Contraseña actual</div>
      <Input.Password
        autoComplete="off"
        value={oldPassword}
        onChange={({ target: { value } }) => setOldPassword(value)}
        iconRender={visible =>
          visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
        }
      />
      <div className={styles.label}>Ingresa tu nueva contraseña</div>
      <PasswordInput onChange={setNewPassword} />
      <div className={styles.label}>Ingresar la contraseña nuevamente</div>
      <Input.Password
        className={styles.lastField}
        autoComplete="off"
        value={repeatedPassword}
        onChange={({ target: { value } }) => setRepeatedPassword(value)}
        iconRender={visible =>
          visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
        }
      />
      <PasswordRequirements
        onAllFulfiledChange={onAllFulfiledChange}
        password={newPassword}
      />
      {(changePasswordFailed || changingPassword) && error ? (
        <Alert message={error} type="error" showIcon />
      ) : null}
  </React.Fragment>;
  const confirmation = <React.Fragment>
    <div className={styles.title}>¿Seguro quires actualizar tu contraseña?</div>
    <div className={styles.subtitle}>Al cambiarla tu sesión se cerrará</div>
  </React.Fragment>;
  const feedback = <React.Fragment>
    <img className={styles.successImage} src={successImage} alt="success"/>
    <div className={styles.successText}>¡Contraseña actualizada!</div>
  </React.Fragment>
  const StatusViews = {
    [Status.Form]: form,
    [Status.Confirmation]: confirmation,
    [Status.Feedback]: feedback,
  }
  const StatusTitles = {
    [Status.Form]: "Cambiar contraseña",
    [Status.Confirmation]: undefined,
    [Status.Feedback]: undefined,
  }
  const StatusActions = {
    [Status.Form]: [
      <Button
        loading={changingPassword}
        disabled={
          !enableChangeButton ||
          repeatedPassword !== newPassword ||
          oldPassword.length === 0 ||
          changingPassword
        }
        type="link"
        key="changePassword"
        onClick={()=>{
          setStatus(Status.Confirmation)
        }}
      >
        Cambiar contraseña
      </Button>
    ],
    [Status.Confirmation]: [
      <Button
        disabled={
          !enableChangeButton ||
          repeatedPassword !== newPassword ||
          oldPassword.length === 0 ||
          changingPassword
        }
        type="link"
        key="cancel"
        onClick={close}
      >
        Cancelar
      </Button>,
      <Button
        loading={changingPassword}
        disabled={
          !enableChangeButton ||
          repeatedPassword !== newPassword ||
          oldPassword.length === 0 ||
          changingPassword
        }
        type="link"
        key="confirm"
        onClick={handeChangePassword}
      >
        Confirmar
      </Button>
    ],
    [Status.Feedback]: null,
  }
  const content = StatusViews[status];

  return (
    <Modal
      closable={status === Status.Form}
      visible={visible}
      title={StatusTitles[status]}
      className={styles.container}
      width={372}
      onCancel={close}
      footer={StatusActions[status]}
    >
      {content}
    </Modal>
  );
};
