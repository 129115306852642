import { Divider, Form, Input, Select } from "antd";
import { SizeType } from "antd/lib/config-provider/SizeContext";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { selectUserRole } from "../../features/auth/authSlice";
import { selectCompanies } from "../../features/company/companySlice";
import { UserRole } from "../../features/users/usersSlice";
import { CategoryField } from "../common/CategoryField";
import { MIN_3_CHAR } from "../Users/Form";
import { StepProps } from "./commonInterfaces";
import { TeacherFields } from "./TeacherFields";

const MAX_TAGS = 10;
const MAX_TAG_CHAR_COUNT = 20;

export const StepZero: React.FunctionComponent<StepProps> = ({
  newLesson,
  form,
  categories,
  categoriesIds,
  setNewLesson,
}) => {
  const userRole = useSelector(selectUserRole);
  const companies = useSelector(selectCompanies);
  const [displayDropdown, setDisplayDropdown] = useState(!!newLesson.teacher);
  const handleTagsChange = (value: string[]) => {
    if (value.length <= MAX_TAGS) {
      setNewLesson({ ...newLesson, tags: [...value] });
    }
  };
  const handleCompanyChange = (value: string) => {
    setNewLesson({ ...newLesson, companyId: value });
  };
  return (
    <>
      <Form.Item
        name="title"
        label="Título de la lección"
        rules={[
          { required: true, message: "Requerido" },
          { type: "string", min: 3, message: MIN_3_CHAR },
          { type: "string", max: 200, message: "Máximo 200 caracteres" },
        ]}
      >
        <Input
          onChange={(event) => {
            setNewLesson({ ...newLesson, title: event.target.value });
          }}
          type="text"
          placeholder="Título de la lección"
        />
      </Form.Item>

      <CategoryField
        markRequired
        categories={categories}
        categoriesIds={categoriesIds}
        label="Categoría"
        object={newLesson}
        setObject={setNewLesson}
        initialValue={false}
      />

      <Form.Item
        name="description"
        label="Descripción"
        rules={[
          { required: true, message: "Requerido" },
          { type: "string", max: 500, message: "Máximo 500 caracteres" },
          { type: "string", min: 3, message: MIN_3_CHAR },
        ]}
      >
        <Input.TextArea
          onChange={(event) => {
            setNewLesson({ ...newLesson, description: event.target.value });
          }}
          placeholder="Descripción..."
        />
      </Form.Item>

      <Form.Item
        name="tags"
        label="Tags"
        rules={[
          { required: true, message: "Requerido" },
          {
            validator: (_rule, value: any) => {
              if (value) {
                const exceedsMaxCharCount = value.find(
                  (element: string) => element.length > MAX_TAG_CHAR_COUNT
                );
                return new Promise((resolve, reject) => {
                  if (value.length > MAX_TAGS) {
                    value.pop();
                    reject(`Máximo ${MAX_TAGS} tags`);
                    return;
                  } else if (exceedsMaxCharCount) {
                    value.pop();
                    reject(`Máximo ${MAX_TAG_CHAR_COUNT} caracteres por tag`);
                    return;
                  }
                  resolve();
                });
              }
            },
          },
        ]}
      >
        <Select
          mode="tags"
          size={"default" as SizeType}
          placeholder="Please select"
          onChange={handleTagsChange}
          style={{ width: "100%" }}
          maxTagCount={MAX_TAGS}
          maxTagTextLength={20}
          tokenSeparators={[" ", ","]}
        />
      </Form.Item>

      {userRole === UserRole.SuperAdmin ? (
        <Form.Item
          label="Compañia"
          name="companyId"
          initialValue={newLesson?.companyId || ""}
        >
          <Select
            placeholder=""
            onChange={handleCompanyChange}
            style={{ width: "100%" }}
          >
            <Select.Option key={"all_companies"} value={""}>
              Todas
            </Select.Option>
            {Object.values(companies).map((company) => {
              return (
                <Select.Option key={company._id} value={company._id}>
                  {company.name}
                </Select.Option>
              );
            })}
          </Select>
        </Form.Item>
      ) : null}

      {newLesson.companyId || userRole !== UserRole.SuperAdmin ? null : (
        <>
          <Divider />
          <TeacherFields
            isEditing={typeof newLesson._id === "string"}
            form={form}
            teacher={newLesson.teacher}
            displayDropdown={displayDropdown}
            setDisplayDropdown={setDisplayDropdown}
            onUpdateTeacher={(teacher) =>
              setNewLesson({ ...newLesson, teacher: teacher })
            }
          />
        </>
      )}
    </>
  );
};
