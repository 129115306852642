import { Col, Row } from "antd";
import cls from "classnames";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import supportImage from "../../assets/support/support.png";
import { getCognitoUserData } from "../../features/auth/authSlice";
import { selectCompany } from "../../features/company/companySlice";
import { fetchUser, selectUser } from "../../features/users/usersSlice";
import { ProfileBase } from "../common/Layouts/ProfileBaseLyt";
import { CardData } from "./CardItem";
import { Section } from "./Section";
import styles from "./support.module.scss";

const browsers: CardData[] = [
  {
    logo: "https://www.google.com/chrome/static/images/chrome-logo.svg",
    title: "Google Chrome",
    description: "Este navegador es compatible con la plataforma Teurona.",
    link: "https://www.google.com/chrome/",
  },
  {
    logo:
      "https://www.mozilla.org/media/protocol/img/logos/firefox/browser/logo-sm.f2523d97cbe0.png",
    title: "Mozilla Firefox",
    description: "Este navegador es compatible con la plataforma Teurona.",
    link: "https://www.mozilla.org/es-ES/firefox/new/",
  },
  {
    logo:
      "https://cdn-production-opera-website.operacdn.com/staticfiles/assets/images/main/home/opera-app-icon.4a5a1ea5cd51.png",
    title: "Opera",
    description: "Este navegador es compatible con la plataforma Teurona.",
    link: "https://www.opera.com/es",
  },
  {
    logo:
      "https://km.support.apple.com/kb/image.jsp?productid=PL165&size=240x240",
    title: "Safari",
    description: "Este navegador es compatible con la plataforma Teurona.",
    link: "https://support.apple.com/es-lamr/HT204416",
  },
];

const compressors: CardData[] = [
  {
    logo: "https://www.win-rar.com/uploads/pics/rar-archive-8_42.png",
    title: "Winrar",
    description: "Este compresor es compatible con la plataforma Teurona.",
    link: "https://www.winrar.es/descargas",
  },
  {
    logo: "https://www.winzip.com/static/images/layout/g-ico-wz.png",
    title: "Winzip",
    description: "Este compresor es compatible con la plataforma Teurona.",
    link: "https://www.winzip.com/win/es/prod_down.html",
  },
];

const other: CardData[] = [
  {
    logo:
      "https://acrobat.adobe.com/content/dam/dx-dc/images/acrobat/product-icon-reader.svg",
    title: "Adobe reader",
    description: "Este software es compatible con la plataforma Teurona.",
    link: "https://get.adobe.com/reader/",
  },
];

interface SupportProps {}

export const Support: React.FunctionComponent<SupportProps> = () => {
  const cognitoUserData = useSelector(getCognitoUserData);
  const cognitoUsername = cognitoUserData
    ? cognitoUserData["cognito:username"]
    : null;
  const dispatch = useDispatch();
  const userFromStore = useSelector(selectUser(cognitoUsername));
  const companyFromStore = useSelector(
    selectCompany(userFromStore?.company ?? "")
  );
  useEffect(() => {
    dispatch(fetchUser(cognitoUsername));
  }, [dispatch, cognitoUsername]);

  const contactEmail =
    companyFromStore?.supportEmail ?? companyFromStore?.contactInfo.email;

  return (
    <ProfileBase panelHeight={styles.panel}>
      <div className={styles.mastheadContainer}>
        <Row className={styles.masthead} gutter={16}>
          <Col span={14} className={styles.left}>
            <div className={styles.titleContainer}>
              <h1 className={styles.title}>Soporte técnico</h1>
              <div className={styles.info}>
                En caso que necesites ayuda o tengas algún inconveniente
                accediendo a todos los servicios de la plataforma, ponte en
                contacto con <span className={styles.mail}>{contactEmail}</span>
              </div>
            </div>
          </Col>
          <Col span={10} className={styles.right}>
            <div className={styles.imageContainer}>
              <img
                className={cls(styles.image)}
                src={supportImage}
                alt="support"
              />
            </div>
          </Col>
        </Row>
      </div>
      <Section cards={browsers} title="Navegadores compatibles" />
      <Section cards={compressors} title="Compresores" />
      <Section cards={other} title="Otro software" />
    </ProfileBase>
  );
};
