import React, { FunctionComponent, useEffect, useState } from 'react';
import moment from 'moment';

import styles from './header.module.scss';

interface HeaderProps {
  weekStartOnSunday: boolean,
}

interface Label {
  shortLabel: string,
  label: string;
}
export const Header: FunctionComponent<HeaderProps> = ({weekStartOnSunday}) => {
  const [labels, setLabels] = useState<Label[]>([]);
  useEffect(()=>{
    let firstDay = moment().startOf('week');
    if(weekStartOnSunday){
      firstDay = firstDay.subtract(1, 'd');
    }
    const labels:Label[] = [];
    for(let i = 0; i < 7; ++i){
      const shortLabel = firstDay.format('ddd');
      labels.push({
        label: firstDay.format('dddd'),
        shortLabel: shortLabel.replace(new RegExp("[.]+$"), ""),
      });
      firstDay = firstDay.add(1, 'd');
    }
    setLabels(labels);
  },[weekStartOnSunday]);
  return <div className={styles.header}>
    {labels.map(item =>{
      return <div className={styles.day} title={item.label}>
        {item.shortLabel.substr(0, )}
      </div>;
    })}
  </div>;
}