import { Button, Form, Table } from "antd";
import { ColumnsType } from "antd/es/table";
import { CheckboxProps } from "antd/lib/checkbox";
import { FormInstance } from "antd/lib/form";
import React, {
  FunctionComponent,
  ReactText,
  useEffect,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  getCognitoUserData,
  resendConfirmationEmail,
} from "../../features/auth/authSlice";
import {
  fetchCompanies,
  selectCompanies,
  selectCompaniesAreFetching,
} from "../../features/company/companySlice";
import { fetchGroups } from "../../features/groups/groupSlice";
import {
  createUser,
  disableUsersStatus,
  enableUsersStatus,
  fetchUser,
  fetchUsers,
  hideModal,
  IUser,
  RolesDict,
  selectAreUsersFetching,
  selectDisplayModal,
  selectUsers,
  selectUsersIds,
  updateUser,
  UserRole,
  UsersGroups,
} from "../../features/users/usersSlice";
import { PAGE_SIZE } from "../../utils/constants";
import { DialogPopUp } from "../common/DialogPopUp";
import { CogDrawer } from "../common/Drawer";
import { MainLayout } from "../common/Layouts/Main";
import { Status, StatusEnum } from "../common/Status";
import tableStyles from "../common/table.module.scss";
import { TableTools } from "../common/TableTools";
import { CompanyFilter } from "../Filters/Company";
import { extractDate } from "../utils/convertions";
import { useDebounce } from "../utils/useDebounce";
import { NamePlusThumbnail } from "./common/NamePlusThumbnail";
import { UserForm } from "./Form";
import { getGroupsForUser } from "./utils";

function renderOptions(
  toggleVisible: any,
  setNewUser: any,
  setIsCreating: any,
  form: FormInstance
) {
  return (
    <Button
      onClick={() => {
        toggleVisible();
        setNewUser({});
        setIsCreating(true);
        setTimeout(() => {
          form.resetFields();
          form.setFieldsValue({});
        }, 100);
      }}
      type="primary"
    >
      Nuevo usuario
    </Button>
  );
}

export const AdminUsers: FunctionComponent = () => {
  const cognitoUserData = useSelector(getCognitoUserData);
  const companiesDict = useSelector(selectCompanies);
  const usersIdsList = useSelector(selectUsersIds);
  const usersDict = useSelector(selectUsers);
  const usersList = usersIdsList.map((userId) => ({
    ...usersDict[userId],
    companyName: companiesDict[usersDict[userId].company ?? ""]?.name,
  }));
  const areCompaniesFetching = useSelector(selectCompaniesAreFetching);
  const areUsersFetching = useSelector(selectAreUsersFetching);
  const userFormId = "userAdminForm";
  const [filterValue, setFilterValue] = useState<ReactText[]>([]);
  const debounceFilterValue = useDebounce(filterValue, 500);
  const history = useHistory();
  const [visible, setVisible] = useState<boolean>(false);
  const [newUser, setNewUser] = useState<IUser>();
  const [isCreating, setIsCreating] = useState<boolean>(false);
  const [selectedRowKeys, selectRowKeys] = useState<ReactText[]>([]);
  const [filteredInfo, setFilteredInfo] = useState<ReactText[]>([]);
  const [currentAction, setCurrentAction] = useState<string>("");
  const [showActivationBtns, setShowActivationBtns] = useState<boolean>(false);
  const isModalVisible = useSelector(selectDisplayModal);
  const dispatch = useDispatch();
  const toggleVisible = () => setVisible(!visible);

  useEffect(() => {
    if (selectedRowKeys.length > 0) {
      setShowActivationBtns(true);
    } else {
      setShowActivationBtns(false);
    }
  }, [selectedRowKeys]);

  const columns: ColumnsType<IUser> = [
    {
      title: "Nombre completo",
      dataIndex: "name",
      sortDirections: ["descend", "ascend"],
      defaultSortOrder: "ascend",
      filteredValue: debounceFilterValue,
      ellipsis: true,
      sorter: (a: IUser, b: IUser) => a.name.localeCompare(b.name),
      onFilter: (value, record) => {
        return (
          record.name.toLowerCase().includes(value.toString().toLowerCase()) ||
          record.email.toLowerCase().includes(value.toString().toLowerCase())
        );
      },
      render: (value, user) => {
        return <NamePlusThumbnail value={value} user={user} />;
      },
    },
    {
      title: "Rol",
      dataIndex: "role",
      render: (value) => {
        return RolesDict[value as UserRole];
      },
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Estado",
      dataIndex: "enabled",
      render: (status) => {
        return (
          <Status status={status ? StatusEnum.Enable : StatusEnum.Disable} />
        );
      },
      sortDirections: ["descend", "ascend"],
      sorter: (a: IUser, b: IUser) =>
        a.enabled === b.enabled ? 0 : a.enabled > b.enabled ? 1 : -1,
    },
    {
      title: "Empresa",
      dataIndex: "companyName",
      sortDirections: ["descend", "ascend"],
      filteredValue: filteredInfo,
      onFilter: (value, record: IUser) => record.company === (value as string),
      sorter: (a: IUser, b: IUser) =>
        (a.companyName || "").localeCompare(b.companyName || ""),
      render: (value, user) => {
        return (
          <React.Fragment>
            {value}
            <div className={tableStyles.actions}>
              {user.status === "FORCE_CHANGE_PASSWORD" ? (
                <Button
                  type="default"
                  onClick={() => {
                    dispatch(resendConfirmationEmail(user.username));
                  }}
                >
                  Reenviar invitación
                </Button>
              ) : null}
              <Button
                type="default"
                onClick={() => {
                  history.push(`/admin-users/${user.username}`);
                }}
              >
                Ver
              </Button>
              <Button
                type="default"
                onClick={() => {
                  setIsCreating(false);
                  dispatch(fetchUser(user.username));
                  toggleVisible();
                  form.resetFields();
                  setNewUser(user);
                }}
              >
                Editar
              </Button>
            </div>
          </React.Fragment>
        );
      },
    },
  ];

  useEffect(() => {
    dispatch(fetchUsers());
    dispatch(fetchCompanies());
    dispatch(fetchGroups());
  }, [dispatch]);
  const cognitoUsername = cognitoUserData
    ? cognitoUserData["cognito:username"]
    : null;
  const rowSelection = {
    getCheckboxProps: (record: IUser): CheckboxProps => {
      if (record.username === cognitoUsername) {
        return {
          disabled: true,
        };
      }
      return {};
    },
    selectedRowKeys,
    onChange: (selectedRowKeys: ReactText[]) => {
      selectRowKeys(selectedRowKeys);
    },
  };

  const enableStatus = () => {
    const data = {
      usernames: selectedRowKeys.map((key) => key.toString()),
    };
    dispatch(enableUsersStatus(true, data));
  };

  const disableStatus = () => {
    const data = {
      usernames: selectedRowKeys.map((key) => key.toString()),
    };
    dispatch(disableUsersStatus(true, data));
  };
  const [form] = Form.useForm();

  return (
    <MainLayout
      title="Usuarios administrativos"
      rightTopOptions={renderOptions(
        toggleVisible,
        setNewUser,
        setIsCreating,
        form
      )}
    >
      <DialogPopUp
        name="Usuario administrativo"
        action={currentAction}
        visible={isModalVisible}
        onCancel={() => {
          dispatch(hideModal());
        }}
      />
      <TableTools
        placeholder="Buscar en Teurona"
        onSearch={(value) => setFilterValue([value])}
        onChange={(event) => setFilterValue([event.target.value])}
        onEnable={() => enableStatus()}
        onDisable={() => disableStatus()}
        filter={<CompanyFilter onApply={setFilteredInfo} />}
        activationBtnsVisible={showActivationBtns}
      />
      <Table<IUser>
        loading={areCompaniesFetching || areUsersFetching}
        className={tableStyles.table}
        rowClassName={() => tableStyles.row}
        rowKey="username"
        rowSelection={rowSelection}
        columns={columns}
        dataSource={usersList}
        pagination={{ position: ["bottomRight"], pageSize: PAGE_SIZE }}
      />
      <CogDrawer
        loading={areUsersFetching}
        visible={visible}
        toggleVisible={toggleVisible}
        title={newUser?.status ? "Editar usuario" : "Nuevo usuario"}
        footer={
          <div>
            <Button onClick={toggleVisible}>Cancelar</Button>&nbsp;&nbsp;
            <Button
              type="primary"
              htmlType="submit"
              form={userFormId}
              onClick={() => {
                form
                  .validateFields()
                  .then((value) => {
                    if (!newUser) {
                      return;
                    }
                    const userToSend = { ...newUser };
                    delete userToSend.companyName;
                    userToSend.birthdate = extractDate(userToSend.birthdate);
                    const isStudentToo = userToSend.isStudentToo;
                    delete userToSend.isAdminToo;
                    delete userToSend.isStudentToo;
                    userToSend.groups = getGroupsForUser(
                      userToSend.groups,
                      UsersGroups.Administrators
                    );
                    if (isStudentToo) {
                      userToSend.groups = getGroupsForUser(
                        userToSend.groups,
                        UsersGroups.Students
                      );
                    } else {
                      userToSend.groups = [UsersGroups.Administrators];
                    }
                    if (
                      userToSend.role === UserRole.SuperAdmin &&
                      userToSend.company === ""
                    ) {
                      delete userToSend.company;
                    }
                    const status = userToSend.status;
                    delete userToSend.status;
                    if (!status) {
                      setCurrentAction("creado");
                      dispatch(createUser(userToSend, toggleVisible));
                    } else {
                      setCurrentAction("actualizado");
                      dispatch(updateUser(userToSend, toggleVisible));
                    }
                  })
                  .catch((error) => {
                    console.log(
                      "Company:React.FunctionComponent -> error",
                      error
                    );
                  });
              }}
            >
              {!(newUser?.status === undefined)
                ? "Actualizar"
                : "Crear usuario"}
            </Button>
          </div>
        }
      >
        <div>
          {!visible ? null : (
            <UserForm
              form={form}
              formId={userFormId}
              user={newUser}
              setUser={setNewUser}
              adminOptions={true}
              isCreating={isCreating}
            />
          )}
        </div>
      </CogDrawer>
    </MainLayout>
  );
};
