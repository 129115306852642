import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Dispatch } from "react";
import { Microservices } from "../../../../app/AllowedMicroservices";
import { RootState } from "../../../../app/store";
import apiActionCreator, {
  HttpMethods,
} from "../../../../services/apiActionCreator";
import { FetchStatus } from "../../../common/enums";
import { EvaluationTypeEnum } from '../../../evaluation/common/enums';
import { IEvaluationsCounters } from '../common/interfaces';


interface IExamResultsCountersState {
  data: IEvaluationsCounters[],
  status: FetchStatus,
}
const initialState: IExamResultsCountersState = {
  data: [],
  status: FetchStatus.Idle,
};

export const dataSlice = createSlice({
  name: "reportLearningLessonsQuizResultsCounters",
  initialState,
  reducers: {
    fetching: (state) => {
      state.status = FetchStatus.Fetching;
    },
    fetched: (state, action: PayloadAction<IEvaluationsCounters[]>) => {
      state.data = action.payload;
      state.status = FetchStatus.Fetched;
    },
    error: (state) => {
      state.status = FetchStatus.Error;
    },
  },
});

export const { fetching, fetched, error } = dataSlice.actions;

interface IFetchExamCounters{
  companyId?: string,
  usersIds?: string[],
  parentId?: string
}
export const fetchQuizCounters = ({ companyId, usersIds, parentId }:IFetchExamCounters) => (dispatch: Dispatch<object>) => {
  let url = '/evaluation/results/evaluationsCounters';
  const data:{
    evaluationType: EvaluationTypeEnum;
    companyId?: string,
    usersIds?: string[],
    parentId?: string,
  } = {
    companyId,
    usersIds,
    parentId,
    evaluationType: EvaluationTypeEnum.Quiz
  };
  return dispatch(
    apiActionCreator({
      endpoint: url,
      types: {
        requestType: fetching,
        successTypes: [
          {
            actionOrCreator: fetched,
          },
        ],
        errorTypes: [
          {
            actionOrCreator: error,
          },
        ],
      },
      method: HttpMethods.POST,
      microservice: Microservices.EVALUATION_SERVICE,
      authenticated: true,
      data,
    })
  );
};

export const selectQuizResultsCounters = (state: RootState) => state.reports.learning.lessons.quizResultsCounters.data;
export const selectQuizResultsCountersIsFetching = (state: RootState) => state.reports.learning.lessons.quizResultsCounters.status === FetchStatus.Fetching;

export default dataSlice.reducer;
