import React from "react";
import { ICourse } from "../../../../features/courses/coursesSlice";
import { StatusEnum } from "../../../common/Status";
import { IconNames } from "../../common/SimpleStat";
import { StatsRow } from "../../common/StatsRow";

interface Props {
  courses: ICourse[];
  totalResources: string;
  totalEnabledResources: string;
}

export const EducationalResourcesStats: React.FunctionComponent<Props> = ({
  courses,
  totalResources,
  totalEnabledResources,
}) => {
  const stats = [
    {
      description: "Cursos disponibles",
      iconName: IconNames.Frequency,
      mainText:
        courses
          .filter((course) => course.status === StatusEnum.Enable)
          .reduce((total: number, course) => total + course.durationHour, 0) +
        " horas",
    },
    {
      description: "Material registrado",
      iconName: IconNames.RegisteredResources,
      mainText: totalResources,
    },
    {
      description: "Material habilitado",
      iconName: IconNames.EnabledResources,
      mainText: totalEnabledResources,
    },
  ];
  return <StatsRow statsData={stats} />;
};
