import { buildShortIds, IWithShortId } from './../common/shortId';
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Dispatch } from "react";
import { Microservices } from "../../app/AllowedMicroservices";
import { AppThunk, RootState } from "../../app/store";
import { StatusEnum } from "../../components/common/Status";
import apiActionCreator, { HttpMethods } from "../../services/apiActionCreator";
import { MODAL_DELAY_MS } from "../../utils/constants";
import { fetchCategories, ICategory } from "../category/categorySlice";
import { FetchStatus, ModalStatus } from "../common/enums";
import { setMessageError, setMessageInfo } from "../message/messageSlice";

export interface ITopicEmpty {
  _id: string,
  title: string,
}

export interface ITopic extends IWithShortId {
  _id: string;
  title: string;
  category: string | ICategory;
  categoryName?: string;
  description: string;
  tags: string[];
  lessons: string[];
  status: StatusEnum;
  deleted: boolean;
  deletedAt: Date;
  createdAt: Date;
  evaluation?: string;
  correctAnswersToPass?: number;
  companyId?: string;
  companyName?: string;
}

export interface ITopicDict {
  [_id: string]: ITopic;
}

interface TopicState {
  list: string[];
  dict: ITopicDict;
  status: FetchStatus;
  modalStatus: ModalStatus;
}

const initialState: TopicState = {
  list: [],
  dict: {},
  status: FetchStatus.Idle,
  modalStatus: ModalStatus.Hide,
};

interface TopicsFetch {
  docs: ITopic[];
  limit: number;
  page: number;
  pages: number;
  total: number;
}

const BASE_TOPIC_ENDPOINT = "/topics";

export const topicSlice = createSlice({
  name: "topics",
  initialState,
  reducers: {
    creating: (state) => {
      state.status = FetchStatus.Creating;
    },
    fetching: (state) => {
      state.status = FetchStatus.Fetching;
    },
    fetchedTopics: (state, action: PayloadAction<TopicsFetch>) => {
      action.payload.docs = buildShortIds(action.payload.docs) as ITopic[];
      state.list = action.payload.docs.map((topic) => topic._id);
      state.dict = {};
      action.payload.docs.forEach((topic) => {
        state.dict[topic._id] = topic;
      });
      state.status = FetchStatus.Fetched;
    },
    fetchedTopic: (state, action: PayloadAction<ITopic>) => {
      if (!state.list.includes(action.payload._id)) {
        state.list.push(action.payload._id);
      }
      state.dict[action.payload._id] = action.payload;
      state.status = FetchStatus.Fetched;
    },
    error: (state) => {
      state.status = FetchStatus.Error;
    },
    showModal: (state) => {
      state.modalStatus = ModalStatus.Show;
    },
    hideModal: (state) => {
      state.modalStatus = ModalStatus.Hide;
    },
  },
});

const { showModal } = topicSlice.actions;

export const fetchTopics = () => (dispatch: Dispatch<object>) => {
  return dispatch(
    apiActionCreator({
      endpoint: `${BASE_TOPIC_ENDPOINT}?limit=1000000`,
      types: {
        requestType: fetching,
        successTypes: [
          {
            actionOrCreator: fetchedTopics,
          },
        ],
        errorTypes: [
          {
            actionOrCreator: error,
          },
        ],
      },
      method: HttpMethods.GET,
      microservice: Microservices.TOPIC_SERVICE,
      authenticated: true,
    })
  );
};

export const createTopic = (newTopic: ITopic) => (
  dispatch: Dispatch<object>
) => {
  return dispatch(
    apiActionCreator({
      endpoint: BASE_TOPIC_ENDPOINT,
      types: {
        requestType: creating,
        successTypes: [
          {
            actionOrCreator: fetchedTopic,
          },
          {
            actionOrCreator: showModal,
            selector: () => {
              dispatch(hideModalWithDelay());
            },
          },
          {
            actionOrCreator: fetchCategories,
          },
        ],
        errorTypes: [
          {
            actionOrCreator: error,
            selector: (response: any) => {
              const message = `Error desconocido`;
              dispatch(setMessageError({ message }));
            },
          },
        ],
      },
      method: HttpMethods.POST,
      microservice: Microservices.TOPIC_SERVICE,
      authenticated: true,
      data: newTopic,
    })
  );
};

export const updateTopic = (topic: ITopic) => (dispatch: Dispatch<object>) => {
  return dispatch(
    apiActionCreator({
      endpoint: `${BASE_TOPIC_ENDPOINT}/${topic._id}`,
      types: {
        requestType: creating,
        successTypes: [
          {
            actionOrCreator: fetchTopics,
          },
          {
            actionOrCreator: showModal,
            selector: () => {
              dispatch(hideModalWithDelay());
            },
          },
        ],
        errorTypes: [
          {
            actionOrCreator: error,
            selector: (response: any) => {
              const message = `Error desconocido`;
              dispatch(setMessageError({ message }));
            },
          },
        ],
      },
      method: HttpMethods.PUT,
      microservice: Microservices.TOPIC_SERVICE,
      authenticated: true,
      data: topic,
    })
  );
};

const hideModalWithDelay = (): AppThunk => async (dispatch) => {
  return new Promise((resolve) => {
    setTimeout(() => {
      dispatch(hideModal());
      resolve();
    }, MODAL_DELAY_MS);
  });
};

interface TopicUpdateStatusData {
  ids: string[];
}

export const enableTopicStatus = (topics: TopicUpdateStatusData) => (
  dispatch: Dispatch<object>
) => {
  return dispatch(
    apiActionCreator({
      endpoint: `${BASE_TOPIC_ENDPOINT}/enable`,
      types: {
        requestType: creating,
        successTypes: [
          {
            actionOrCreator: fetchTopics,
          },
          {
            actionOrCreator: setMessageInfo({
              message: "Temas(s) activado(s)",
            }),
          },
        ],
        errorTypes: [
          {
            actionOrCreator: error,
            selector: (response: any) => {
              if (
                response.data &&
                response.data.statusCode === 409 &&
                response.data.data === "CanBeEnabled"
              ) {
                const message = "Algunos temas no están completos";
                dispatch(setMessageError({ message }));
                return;
              }
              dispatch(setMessageError({ message: "Falló la activación" }));
            },
          },
          {
            actionOrCreator: fetchTopics,
          },
        ],
      },
      method: HttpMethods.PUT,
      microservice: Microservices.TOPIC_SERVICE,
      authenticated: true,
      data: topics,
    })
  );
};

export const disableTopicStatus = (topics: TopicUpdateStatusData) => (
  dispatch: Dispatch<object>
) => {
  return dispatch(
    apiActionCreator({
      endpoint: `${BASE_TOPIC_ENDPOINT}/disable`,
      types: {
        requestType: creating,
        successTypes: [
          {
            actionOrCreator: fetchTopics,
          },
          {
            actionOrCreator: setMessageInfo({
              message: "Tema(s) desactivado(s)",
            }),
          },
        ],
        errorTypes: [
          {
            actionOrCreator: error,
          },
          {
            actionOrCreator: fetchTopics,
          },
          {
            actionOrCreator: setMessageError({
              message: "Falló la desactivación",
            }),
          },
        ],
      },
      method: HttpMethods.PUT,
      microservice: Microservices.TOPIC_SERVICE,
      authenticated: true,
      data: topics,
    })
  );
};

export const {
  creating,
  fetching,
  fetchedTopic,
  fetchedTopics,
  error,
  hideModal,
} = topicSlice.actions;

export const selectTopicsIds = (state: RootState) => state.topics.list;
export const selectTopics = (state: RootState) => state.topics.dict;
export const selectTopicsFetchStatus = (state: RootState) =>
  state.topics.status;
  export const selectTopicsStatus = (state: RootState) => {
    const status: {
      [id:string]: StatusEnum
    } = {};
    Object.values(state.topics.dict).forEach((topic) => {
      status[topic._id || ''] = topic.status || StatusEnum.Disable;
    });
    return status;
  };
export const selectAreTopicsFetching = (state: RootState) =>
  state.lessons.status === FetchStatus.Fetching;
export const selectTopic = (state: RootState, id: string) =>
  state.topics.dict[id];
export const selectDisplayModal = (state: RootState) =>
  state.topics.modalStatus === ModalStatus.Show;

export default topicSlice.reducer;
