import React, { FunctionComponent } from 'react';
import moment from 'moment';
import { useHistory } from 'react-router-dom';

import { CogIcon } from '../../../common/CogIcon';
import { EventsDict } from './interfaces';

import styles from './dayView.module.scss';
import { COURSES_PRIORITY_COLORS, COURSES_PRIORITY_ICON, COURSES_PRIORITY_LABELS } from '../../../../utils/constants';

interface DayViewProps {
  selectedDate?: moment.Moment,
  onChangeSelectedDate: (date:moment.Moment) => void,
  eventsDict: EventsDict,
  events: (string|number)[],
}

interface EventListProps {
  eventsDict: EventsDict,
  events: (string|number)[],
}

export const EventList: FunctionComponent<EventListProps> = ({eventsDict, events = []}) => {
  const history = useHistory();
  if(events.length === 0){
    return <div className={styles.noEvents}>No hay eventos este día</div>;
  }
  return <>
    {events.map(event=>{
      const {
        priority,
        tooltip,
        endDate,
        id
      } = eventsDict[event];

      return <div className={styles.course} onClick={()=>{
        history.push(`/course/${id}`)
      }}>
        <div className={styles.title}>
          {tooltip}
        </div>
        <div className={styles.extra}>
          <div className={styles.priority}>
              <CogIcon className={styles.icon} color={COURSES_PRIORITY_COLORS[priority]} icon={COURSES_PRIORITY_ICON[priority]} />
              Prioridad {COURSES_PRIORITY_LABELS[priority]}
          </div>
          <div>
            Fecha de finalización: {endDate.format('DD/MM/YYYY')}
          </div>
        </div>
      </div>;
    })}
  </>;
}
export const DayView: FunctionComponent<DayViewProps> = ({selectedDate, onChangeSelectedDate, eventsDict, events}) => {
  if(!selectedDate){
    return null;
  }
  const onPrev = () => {
    let date = selectedDate.clone().subtract(1, 'd');
    onChangeSelectedDate(date);
  };
  const onNext = () => {
    let date = selectedDate.clone().add(1, 'd');
    onChangeSelectedDate(date);
  };
  const name = selectedDate.format('dddd');
  return <div className={styles.container}>
     <div className={styles.innerContainer}>
    <div className={styles.header}>
      <div className={styles.monthAndYear}><span className={styles.month}>{name}</span> {selectedDate.format('DD')}</div>
      <div className={styles.controls}>
        <span className={styles.button} onClick={onPrev}><CogIcon className={styles.icon} icon="Arrow-left-big"/></span>
        <span className={styles.button} onClick={onNext}><CogIcon className={styles.icon} icon="Arrow-right-big"/></span>
      </div>
    </div>
    <div className={styles.eventsCointainer}>
      <EventList eventsDict={eventsDict} events={events}/>
    </div>
    </div>
  </div>;
}