import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Dispatch } from "react";
import { Microservices } from "../../app/AllowedMicroservices";
import { RootState } from "../../app/store";
import apiActionCreator, { HttpMethods } from "../../services/apiActionCreator";
import { FetchStatus, ModalStatus } from "../common/enums";

export interface IStat {
  certificates: number;
  finishedCourses: number;
  pendingCourses: number;
}

interface LoggerUserState {
  stats: IStat;
  status: FetchStatus;
  modalStatus: ModalStatus;
}

const initialState: LoggerUserState = {
  stats: {
    certificates: 0,
    finishedCourses: 0,
    pendingCourses: 0,
  },
  status: FetchStatus.Idle,
  modalStatus: ModalStatus.Hide,
};

export const loggedUserSlice = createSlice({
  name: "logged-user",
  initialState,
  reducers: {
    fetching: (state) => {
      state.status = FetchStatus.Fetching;
    },
    fetchedStats: (state, action: PayloadAction<IStat>) => {
      state.stats = action.payload;
      state.status = FetchStatus.Fetched;
    },
    error: (state) => {
      state.stats = {
        certificates: 0,
        finishedCourses: 0,
        pendingCourses: 0,
      };
      state.status = FetchStatus.Error;
    },
  },
});

export const { fetching, fetchedStats, error } = loggedUserSlice.actions;

export const fetchStats = () => (dispatch: Dispatch<object>) => {
  return dispatch(
    apiActionCreator({
      endpoint: `/student/profile`,
      types: {
        requestType: fetching,
        successTypes: [
          {
            actionOrCreator: fetchedStats,
          },
        ],
        errorTypes: [
          {
            actionOrCreator: error,
          },
        ],
      },
      method: HttpMethods.GET,
      microservice: Microservices.LEARNING_SERVICE,
      authenticated: true,
    })
  );
};

export const selectStats = (state: RootState) => state.loggedUser.stats;
export const selectProgramsFetchStatus = (state: RootState) =>
  state.loggedUser.status;
export const selectModalStatus = (state: RootState) =>
  state.loggedUser.modalStatus;

export default loggedUserSlice.reducer;
