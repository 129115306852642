export enum Microservices {
  COMPANY_SERVICE = 'COMPANY_SERVICE',
  LEARNING_SERVICE = 'LEARNING_SERVICE',
  FILES_SERVICE = 'FILES_SERVICE',
  USER_SERVICE = 'USER_SERVICE',
  TOPIC_SERVICE = 'TOPIC_SERVICE',
  EVALUATION_SERVICE = 'EVALUATION_SERVICE',
  KNOWLEDGE_BASE_SERVICE = 'KNOWLEDGE_BASE_SERVICE',
  CAMPAIGN_SERVICE = 'CAMPAIGN_SERVICE',
  MEDIA_SERVICE = 'MEDIA_SERVICE',
  X_ONE = 'X_ONE',
  ANALYTICS_SERVICE = 'ANALYTICS_SERVICE',
}
