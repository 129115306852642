import { ISignUpUser } from './../../components/Authentication/SignUp/interfaces';
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Dispatch } from "react";
import { Microservices } from "../../app/AllowedMicroservices";
import { RootState } from "../../app/store";
import apiActionCreator, { HttpMethods } from "../../services/apiActionCreator";
import { FetchStatus } from "../common/enums";
import { setMessageError } from "../message/messageSlice";



interface UserState {
  status: FetchStatus,
}

const initialState: UserState = {
  status: FetchStatus.Idle,
};


export const signUpSliceSlice = createSlice({
  name: "signUpSlice",
  initialState,
  reducers: {
    signUpping: (state) => {
      state.status = FetchStatus.Creating;
    },
    signUpped: (state, action: PayloadAction) => {
      state.status = FetchStatus.Fetched;
    },
    error: (state) => {
      state.status = FetchStatus.Error;
    },
  },
});

export const {
  signUpping,
  signUpped,
  error,
} = signUpSliceSlice.actions;

export const signUpUser = (newUser: ISignUpUser, toggleVisible: Function, onError: Function) => (
  dispatch: Dispatch<object>
) => {
  return dispatch(
    apiActionCreator({
      endpoint: '/user/signup',
      types: {
        requestType: signUpping,
        successTypes: [
          {
            actionOrCreator: signUpped,
            selector: () => {
              toggleVisible();
            },
          },
        ],
        errorTypes: [
          {
            actionOrCreator: error,
            selector: (response: any) => {
              onError();
              if (
                response.data &&
                response.data.statusCode === 409 &&
                response.data.data === "UsernameExists"
              ) {
                const message = `El correo "${newUser.username}" ya existe.`;
                dispatch(setMessageError({ message }));
                return;
              }
              const message = `Error desconocido`;
              dispatch(setMessageError({ message }));
            },
          },
        ],
      },
      method: HttpMethods.POST,
      microservice: Microservices.USER_SERVICE,
      data: newUser,
    })
  );
};

export const selectSignUpping = (state: RootState) =>
  state.users.status === FetchStatus.Fetching;

export default signUpSliceSlice.reducer;
