import React, { FunctionComponent } from 'react';
import { Layout } from 'antd';
import { useSelector } from "react-redux";
import {
    Router,
    Switch,
    Route,
  } from "react-router-dom";
import * as H from 'history';

import { Header } from '../../components/Header';
import { FinishRegistration } from '../../components/Authentication/FinishRegistration';
import { PasswordChange } from '../../components/Authentication/PasswordChange';
import { PasswordNew } from '../../components/Authentication/PasswordNew';
import { Login } from '../../components/Authentication/Login';
import { Message } from '../../components/common/Message';
import ScrollToTop from '../../components/common/ScrollToTop';
import {
  loggedIn,
} from "../../features/auth/authSlice";
import { SignUp } from '../../components/Authentication/SignUp';

const { Content } = Layout;

interface AnonymousRouterProps {
    history: H.History<any>;
}

export const AnonymousRouter: FunctionComponent<AnonymousRouterProps> = ({history}) => {
  const isLoggedIn = useSelector(loggedIn);
    return <Router history={history}>
    <ScrollToTop />
    <Layout>
      <Header />
      <Content className="site-layout" style={{ marginTop: isLoggedIn ? 105: 0 }}>
        <Switch>
          <Route path="/finishRegistration">
            <FinishRegistration />
          </Route>
          <Route path="/change-password">
            <PasswordChange />
          </Route>
          <Route path="/recoverPassword/:code/:username">
            <PasswordNew />
          </Route>
          <Route path="/signup">
            <SignUp />
          </Route>
          <Route path="/">
            <Login />
          </Route>
        </Switch>
      </Content>
      <Message />
    </Layout>
  </Router>
};



