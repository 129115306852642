import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Dispatch } from "react";
import { Microservices } from "../../app/AllowedMicroservices";
import { RootState } from "../../app/store";
import apiActionCreator, { HttpMethods } from "../../services/apiActionCreator";
import { FetchStatus } from "../common/enums";
import { IBoockedCourses, isCourseBlocked, IStudentCourse } from "./common";

interface MyCoursesState {
  pending: IStudentCourse[];
  started: IStudentCourse[];
  completed: IStudentCourse[];
  all: IStudentCourse[];
  blockedCourses: IBoockedCourses;
  status: FetchStatus;
}

const initialState: MyCoursesState = {
  pending: [],
  started: [],
  completed: [],
  all: [],
  blockedCourses: {},
  status: FetchStatus.Idle,
};
export const myCoursesSlice = createSlice({
  name: "myCourses",
  initialState,
  reducers: {
    fetching: (state) => {
      state.status = FetchStatus.Fetching;
      state.pending = [];
      state.started = [];
      state.completed = [];
      state.all = [];
    },
    fetched: (state, action: PayloadAction<IStudentCourse[]>) => {
      state.blockedCourses = {};
      state.pending = [];
      state.started = [];
      state.completed = [];
      state.all = [];
      action.payload.forEach((item) => {
        state.all.push(item);
        if (item.isCompleted) {
          state.completed.push(item);
          return;
        }
        if (item.isStarted) {
          state.started.push(item);
          return;
        }
        state.pending.push(item);
      });
      action.payload.forEach((item) => {
        state.blockedCourses[item.course._id] = isCourseBlocked(
          state.all,
          item
        );
      });
      state.status = FetchStatus.Fetched;
    },
    error: (state) => {
      state.pending = [];
      state.started = [];
      state.completed = [];
      state.blockedCourses = {};
      state.status = FetchStatus.Error;
    },
  },
});

export const { fetching, fetched, error } = myCoursesSlice.actions;

export const fetchStudentMyCourses = () => (dispatch: Dispatch<object>) => {
  return dispatch(
    apiActionCreator({
      endpoint: "/student/studentMyCourses",
      types: {
        requestType: fetching,
        successTypes: [
          {
            actionOrCreator: fetched,
          },
        ],
        errorTypes: [
          {
            actionOrCreator: error,
          },
        ],
      },
      method: HttpMethods.GET,
      microservice: Microservices.LEARNING_SERVICE,
      authenticated: true,
    })
  );
};

export const selectStudentMyPendingCourse = (key: number) => (
  state: RootState
) => state.studentMyCourses.pending[key];
export const selectStudentMyPendingCoursesKeys = (state: RootState) =>
  Array.from(state.studentMyCourses.pending.keys());

export const selectStudentMyStartedCourse = (key: number) => (
  state: RootState
) => state.studentMyCourses.started[key];
export const selectStudentMyStartedCoursesKeys = (state: RootState) =>
  Array.from(state.studentMyCourses.started.keys());

export const selectStudentMyCompletedCourse = (key: number) => (
  state: RootState
) => state.studentMyCourses.completed[key];
export const selectStudentMyCompletedCoursesKeys = (state: RootState) =>
  Array.from(state.studentMyCourses.completed.keys());
export const selectIsStudentMyCourseBlocked = (id: string) => (
  state: RootState
) => state.studentMyCourses.blockedCourses[id];

export const selectStudentMyCourse = (key: number) => (state: RootState) =>
  state.studentMyCourses.all[key];
export const selectStudentMyCourses = (state: RootState) =>
  state.studentMyCourses.all;
export const selectAreStudentMyCoursesFetching = (state: RootState) =>
  state.studentMyCourses.status === FetchStatus.Fetching;
export const selectStudentMyCoursesCount = (state: RootState) =>
  state.studentMyCourses.all.length;
export const selectStudentMyCoursesKeys = (state: RootState) =>
  Array.from(state.studentMyCourses.all.keys());

interface Filter {
  tags: string[];
  categoryId: string;
}
interface SearchText {
  text: string;
}
export const selectStudentMyCoursesFilteredKeysCount = (
  filter: Filter,
  searchText: SearchText
) => (state: RootState) => {
  return selectStudentMyCoursesFilteredKeys(filter, searchText)(state).length;
};
export const selectStudentMyCoursesFilteredKeys = (
  filter: Filter,
  searchText: SearchText
) => {
  if (searchText.text.length > 0) {
    return (state: RootState) => {
      const textToSearch = searchText.text;
      const regexSearch = new RegExp(textToSearch, "i");
      const keys: number[] = [];
      state.studentMyCourses.all.forEach((item, index) => {
        if (
          regexSearch.test(item.course.title) ||
          regexSearch.test(item.course.description)
        ) {
          keys.push(index);
        }
      });
      return keys;
    };
  } else if (filter.categoryId.length > 0 || filter.tags.length > 0) {
    return (state: RootState) => {
      const keys: number[] = [];
      const coursesByCategoryKeyObj: { [key: number]: IStudentCourse } = {};
      state.studentMyCourses.all.forEach((item, index) => {
        if (filter.categoryId.length > 0) {
          if (item.course.category === filter.categoryId) {
            coursesByCategoryKeyObj[index] = item;
          }
        } else {
          coursesByCategoryKeyObj[index] = item;
        }
      });

      for (const objKey in coursesByCategoryKeyObj) {
        const courseObj = coursesByCategoryKeyObj[objKey];

        if (filter.tags.length > 0) {
          const found = filter.tags.some((tag) =>
            courseObj.course.tags.includes(tag)
          );
          if (found) {
            keys.push(parseInt(objKey));
          }
        } else {
          keys.push(parseInt(objKey));
        }
      }

      return keys;
    };
  } else {
    return (state: RootState) => Array.from(state.studentMyCourses.all.keys());
  }
};

export default myCoursesSlice.reducer;
