import { Alert, Button, DatePicker, Modal, Radio } from "antd";
import moment from "moment";
import React, { FunctionComponent, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCognitoUserData } from "../../features/auth/authSlice";
import { selectCompany } from "../../features/company/companySlice";
import { fetchGroupsByCompany } from "../../features/groups/groupSlice";
import {
  hideModal,
  IToData,
  NotificationsStatusEnum,
  selectDisplayModal,
  selectNotificationsStatus,
  sendNotifications,
  sendNotificationsSchedule,
} from "../../features/notifications/notificationsSlice";
import {
  fetchStudentsByCompany,
  selectStudents,
  UserRole,
} from "../../features/users/usersSlice";
import { DialogPopUp } from "../common/DialogPopUp";
import { TargetType, ViewProps } from "./common";
import { Email } from "./Email";
import styles from "./manager.module.scss";
import { Push } from "./Push";
import { ScheduledNotifications } from "./ScheduledNotifications";
import { SMS } from "./SMS";
import { Targets } from "./Targets";

interface ManagerProp {
  companyId: string | null;
  openScheduledNotifications: boolean;
  closeScheduledNotifications: () => void;
}

export enum NotificationsTypes {
  SMS = "SMS",
  EMAIL = "EMAIL",
  PUSH = "PUSH",
}
export const NotificationsTypesLabels = {
  [NotificationsTypes.SMS]: "Mensaje de texto",
  [NotificationsTypes.EMAIL]: "Email",
  [NotificationsTypes.PUSH]: "Push notification",
};
const VIEWS_TITLE_LIMITS = {
  [NotificationsTypes.SMS]: 50,
  [NotificationsTypes.EMAIL]: 50,
  [NotificationsTypes.PUSH]: 50,
};

const VIEWS_MESSAGE_LIMITS = {
  [NotificationsTypes.SMS]: 150,
  [NotificationsTypes.EMAIL]: 500,
  [NotificationsTypes.PUSH]: 150,
};

const optionsWithDisabled: {
  [_id: string]: any;
} = {
  [NotificationsTypes.SMS]: {
    label: "SMS",
    value: NotificationsTypes.SMS,
    disabled: false,
  },
  [NotificationsTypes.EMAIL]: {
    label: "E-mail",
    value: NotificationsTypes.EMAIL,
    disabled: false,
  },
  [NotificationsTypes.PUSH]: {
    label: "Push",
    value: NotificationsTypes.PUSH,
    disabled: false,
  },
};

const views: { [_id: string]: React.FunctionComponent<ViewProps> } = {
  [NotificationsTypes.SMS.toString()]: SMS,
  [NotificationsTypes.PUSH.toString()]: Push,
  [NotificationsTypes.EMAIL.toString()]: Email,
};

export const Manager: FunctionComponent<ManagerProp> = ({
  companyId,
  closeScheduledNotifications,
  openScheduledNotifications,
}) => {
  const [dateTime, setDateTime] = useState<moment.Moment | null>(null);
  const [openScheduleModal, setOpenScheduleModal] = useState<boolean>(false);
  const [targetType, setTargetType] = useState<TargetType>(TargetType.groups);
  const [blocked, setBlocked] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");
  const [title, setTitle] = useState<string>("");
  const [view, setView] = useState<NotificationsTypes>(NotificationsTypes.SMS);
  const [targets, setTargets] = useState<string[]>([]);
  const [action, setAction] = useState<"programada" | "enviada">("enviada");
  const company = useSelector(selectCompany(companyId));
  const notificationsStatus = useSelector(selectNotificationsStatus);
  const students = useSelector(selectStudents);
  const isModalVisible = useSelector(selectDisplayModal);
  const cognitoData = useSelector(getCognitoUserData);
  const groupId = cognitoData ? cognitoData["custom:group"] : undefined;
  const role = cognitoData ? cognitoData["custom:role"] : undefined;
  const View = views[view];
  const dispatch = useDispatch();

  useEffect(() => {
    if (!company) {
      return;
    }
    Object.keys(optionsWithDisabled).forEach((view) => {
      optionsWithDisabled[view].disabled = true;
    });
    const availableViews: NotificationsTypes[] = [];
    if (company.notificationTypesEnabled?.sms) {
      optionsWithDisabled[NotificationsTypes.SMS].disabled = false;
      availableViews.push(NotificationsTypes.SMS);
    }
    if (company.notificationTypesEnabled?.email) {
      optionsWithDisabled[NotificationsTypes.EMAIL].disabled = false;
      availableViews.push(NotificationsTypes.EMAIL);
    }
    if (company.notificationTypesEnabled?.web) {
      optionsWithDisabled[NotificationsTypes.PUSH].disabled = false;
      availableViews.push(NotificationsTypes.PUSH);
    }
    if (availableViews.length === 0) {
      setBlocked(true);
      return;
    }
    setBlocked(false);
    if (availableViews.includes(view)) {
      return;
    }
    setView(availableViews[0]);
  }, [company, view]);

  useEffect(() => {
    if (companyId) {
      dispatch(fetchStudentsByCompany(companyId));
      dispatch(fetchGroupsByCompany(companyId));
    }
  }, [companyId, dispatch]);

  useEffect(() => {
    setTitle((currentTitle) =>
      currentTitle.substring(0, VIEWS_TITLE_LIMITS[view])
    );
    setMessage((currentMessage) =>
      currentMessage.substring(0, VIEWS_MESSAGE_LIMITS[view])
    );
  }, [view]);

  if (blocked) {
    return (
      <div className={styles.noNotificationMethods}>
        <Alert
          message="Esta compañia no tiene ningún metodo de notificación habilitado."
          type="info"
          showIcon
        />
      </div>
    );
  }
  const getToData = () => {
    const to: IToData[] = [];
    if (targetType === TargetType.groups) {
      Object.values(role === UserRole.Supervisor
        ? Object.values(students).filter(
            (student) => student.studentGroupId === groupId
          )
        : students).forEach((student) => {
        if (targets.includes(student.studentGroupId || "")) {
          switch (view) {
            case NotificationsTypes.EMAIL:
              to.push({
                companyId: companyId || "",
                username: student.username,
                to: student.email,
              });
              break;
            case NotificationsTypes.SMS:
              to.push({
                companyId: companyId || "",
                username: student.username,
                to: student.phone_number,
              });
              break;
            case NotificationsTypes.PUSH:
              to.push({
                companyId: companyId || "",
                username: student.username,
              });
              break;
          }
        }
      });
    } else {
      Object.values(role === UserRole.Supervisor
        ? Object.values(students).filter(
            (student) => student.studentGroupId === groupId
          )
        : students)
        .filter((student) => targets.includes(student.username))
        .forEach((student) => {
          switch (view) {
            case NotificationsTypes.EMAIL:
              to.push({
                companyId: companyId || "",
                username: student.username,
                to: student.email,
              });
              break;
            case NotificationsTypes.SMS:
              to.push({
                companyId: companyId || "",
                username: student.username,
                to: student.phone_number,
              });
              break;
            case NotificationsTypes.PUSH:
              to.push({
                companyId: companyId || "",
                username: student.username,
              });
              break;
          }
        });
    }
    return to;
  };
  const schedule = () => {
    if (!dateTime) {
      return;
    }
    setAction("programada");
    const utcDateTime = moment.utc(dateTime).format();
    const to: IToData[] = getToData();
    dispatch(
      sendNotificationsSchedule({
        message,
        title,
        to,
        type: view,
        date: utcDateTime,
      })
    );
    setOpenScheduleModal(false);
    resetForm();
  };
  const sendNow = () => {
    setAction("enviada");
    const to: IToData[] = getToData();
    dispatch(
      sendNotifications({
        message,
        title,
        to,
        type: view,
      })
    );
    resetForm();
  };
  const disabled =
    targets.length === 0 ||
    message.length === 0 ||
    (title.length === 0 && view !== NotificationsTypes.SMS);

  const resetForm = () => {
    setTargets([]);
    setMessage("");
    setTitle("");
  };

  return (
    <div className={styles.container}>
      <DialogPopUp
        name="Notificación"
        action={action}
        visible={isModalVisible}
        onCancel={() => {
          dispatch(hideModal());
        }}
      />
      <div>
        <Radio.Group
          className={styles.viewSelector}
          options={Object.values(optionsWithDisabled)}
          onChange={(event) => {
            setView(event.target.value);
          }}
          value={view}
          optionType="button"
          buttonStyle="solid"
        />
      </div>
      <Targets
        setTargets={(targets) => {
          setTargets(targets);
        }}
        targets={targets}
        targetType={targetType}
        setTargetType={setTargetType}
      />
      <View
        maxMessageCount={VIEWS_MESSAGE_LIMITS[view]}
        message={message}
        setMessage={(message) => {
          setMessage(message.substring(0, VIEWS_MESSAGE_LIMITS[view]));
        }}
        title={title}
        setTitle={(title) => {
          setTitle(title.substring(0, VIEWS_TITLE_LIMITS[view]));
        }}
        targets={targets}
      />
      <div className={styles.actions}>
        <Button
          loading={
            notificationsStatus === NotificationsStatusEnum.SENDING_SCHEDULE
          }
          onClick={() => {
            setOpenScheduleModal(true);
          }}
          disabled={
            disabled ||
            notificationsStatus === NotificationsStatusEnum.SENDING_NOW
          }
          className={styles.button}
          type="default"
        >
          Programar envío
        </Button>
        <Button
          loading={notificationsStatus === NotificationsStatusEnum.SENDING_NOW}
          onClick={sendNow}
          disabled={
            disabled ||
            notificationsStatus === NotificationsStatusEnum.SENDING_SCHEDULE
          }
          className={styles.button}
          type="primary"
        >
          Enviar ahora
        </Button>
      </div>
      <Modal
        className={styles.scheduleModal}
        visible={openScheduleModal}
        onOk={schedule}
        onCancel={() => {
          setOpenScheduleModal(false);
        }}
        okButtonProps={{
          disabled: !dateTime || (dateTime && dateTime < moment()),
        }}
      >
        <div className={styles.title}>Elija la fecha y la hora</div>
        <div className={styles.dateTimeContainer}>
          <div className={styles.date}>
            <DatePicker
              value={dateTime}
              placeholder="Fecha y hora"
              format="YYYY-MM-DD HH:mm:ss"
              showTime={{ defaultValue: moment("00:00:00", "HH:mm:ss") }}
              disabledDate={(current) => {
                // Can not select days before today
                return current && current < moment().startOf("day");
              }}
              onChange={(value) => {
                setDateTime(value);
              }}
            />
          </div>
        </div>
      </Modal>
      {companyId && openScheduledNotifications && (
        <ScheduledNotifications
          close={closeScheduledNotifications}
          companyId={companyId}
        />
      )}
    </div>
  );
};
