import { Card, Carousel, Col, Row } from "antd";
import { CarouselRef } from "antd/lib/carousel";
import moment from "moment";
import React, { FunctionComponent, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { FetchStatus } from "../../../features/common/enums";
import {
  fetchContentCategories,
  selectContentCategoriesDict,
} from "../../../features/contentCategory/contentCategorySlice";
import {
  fetchKnowledgeBasesHome,
  selectKnowledgeBaseDict,
  selectKnowledgeBaseFetchStatus,
  selectKnowledgeBasesIds,
} from "../../../features/students/knowledgeBaseSlice";
import { CogIcon } from "../../common/CogIcon";
import { SIZES } from "../../common/screen";
import { useDeviceSize } from "../../utils/useDeviceSize";
import { CarouselControls } from "../common/CarouselControls";
import styles from "./relatedArticles.module.scss";

interface ArticleCardProps {
  title: string;
  date: Date;
  categoryName: string;
}

const ArticleCard: FunctionComponent<ArticleCardProps> = ({
  title,
  date,
  categoryName,
}) => {
  return (
    <Card className={styles.articleCard} bodyStyle={{ padding: 0 }}>
      <div className={styles.content}>
        <div className={styles.top}>
          <CogIcon size="1em" icon="article" />
        </div>
        <div className={styles.middle}>
          <div className={styles.text}>{title}</div>
        </div>
        <div className={styles.bottom}>
          <div className={styles.dateContainer}>
            <CogIcon size="1em" icon="clock" color="#81858b" />
            <div className={styles.date}>{moment(date).format("DD/MM/YY")}</div>
          </div>
          <div className={styles.category}>
            <span className={styles.text}>{categoryName}</span>
          </div>
        </div>
      </div>
    </Card>
  );
};

export const RelatedArticlesBlock: FunctionComponent<{}> = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const size = useDeviceSize();
  const refCarousel = useRef<CarouselRef>(null);
  const kbFetchStatus = useSelector(selectKnowledgeBaseFetchStatus);
  const isFetchingKBdata = kbFetchStatus === FetchStatus.Fetching;

  useEffect(() => {
    dispatch(fetchKnowledgeBasesHome());
    dispatch(fetchContentCategories());
  }, [dispatch]);

  const contentCategoryDict = useSelector(selectContentCategoriesDict);
  const knowledgeBaseIds = useSelector(selectKnowledgeBasesIds);
  const knowledgeBaseDict = useSelector(selectKnowledgeBaseDict);
  const knowledgeBaseItems = knowledgeBaseIds.map((id) => {
    const knowledgeBase = knowledgeBaseDict[id];
    const category = contentCategoryDict[knowledgeBase.category];
    return {
      ...knowledgeBase,
      categoryName: category ? category.name : "",
    };
  });

  const handleOnSummaryClick = ({ contentId }: { contentId: string }) => {
    const pathPlusParams = `/knowledgeBases/${contentId}`;
    history.push(pathPlusParams);
  };

  const handleNext = () => {
    if (refCarousel && refCarousel.current) {
      refCarousel.current.next();
    }
  };

  const handlePrev = () => {
    if (refCarousel && refCarousel.current) {
      refCarousel.current.prev();
    }
  };
  if (!knowledgeBaseItems || knowledgeBaseItems.length === 0) {
    return null;
  }

  const articlesContent = knowledgeBaseItems.map((item) => {
    if (size === SIZES.PHONE) {
      return (
        <ArticleCard
          key={item._id}
          title={item.title}
          date={item.updatedAt}
          categoryName={item.categoryName}
        />
      );
    }
    return (
      <Col className="gutter-row" key={item._id} xs={24} sm={12} md={12} lg={6}>
        <span onClick={() => handleOnSummaryClick({ contentId: item._id })}>
          <ArticleCard
            key={item._id}
            title={item.title}
            date={item.updatedAt}
            categoryName={item.categoryName}
          />
        </span>
      </Col>
    );
  });

  return (
    <div className={styles.container}>
      <div className={styles.sectionRelatedArticles}>
        <div className={styles.sectionTitle}>
          Artículos que te prodrían interesar
        </div>
        {size === SIZES.PHONE ? (
          <div className={styles.carouselContainer}>
            <Carousel
              ref={refCarousel}
              accessibility={true}
              effect="fade"
              dots={false}
            >
              {articlesContent}
            </Carousel>
            {!isFetchingKBdata ? (
              <CarouselControls
                handleNext={handleNext}
                handlePrev={handlePrev}
              />
            ) : null}
          </div>
        ) : null}
        {size === SIZES.DESKTOP || size === SIZES.TABLET ? (
          <Row gutter={[22, 22]}>{articlesContent}</Row>
        ) : null}
      </div>
    </div>
  );
};
