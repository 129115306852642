import { Col, Row } from "antd";
import React, { FunctionComponent, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { fetchCompanies } from "../../../features/company/companySlice";
import { fetchCompanyTypes } from "../../../features/companyType/companyTypeSlice";
import { fetchUsersSessionDuration, selectUsersSessionDuration } from "../../../features/reports/users/sessionDuration";
import { ChurnedAndLicenses } from "./Churned";
import { LeftCompaniesIndicators } from "./CompaniesIndicators";
import { CompaniesPerType } from "./PerType";
import { InsAndOutsPerDay } from "./InsAndOuts/PerDay";
import { TopCountriesWithActiveCompanies } from "./TopFive";
import { CompaniesTable } from "./Table";
import { fetchUserStats } from "../../../features/reports/company/userStats";
import { fetchStudents } from "../../../features/users/usersSlice";
import { AnalyticsViewReport } from "../common/AnalyticsViewReport";
import { ReportTypes, sectionTitles } from "../MenuAndRoutes";
import { InsAndOutsPerDayAggregated } from "./InsAndOuts/PerDayAggregated";

interface CompanyReportsProps { }

export const CompanyReports: FunctionComponent<CompanyReportsProps> = () => {
  const [isLoaded, setIsLoaded] = useState(false);
  const gaUserSessions = useSelector(selectUsersSessionDuration);
  const dispatch = useDispatch();
  useEffect(() => {
    const loadData = async () => {
      dispatch(fetchStudents())
      dispatch(fetchCompanies());
      dispatch(fetchCompanyTypes());
      await dispatch(fetchUsersSessionDuration());
      setIsLoaded(true);
    };
    loadData();
  }, [dispatch]);

  useEffect(() => {
    if (isLoaded) {
      dispatch(
        fetchUserStats({
          gausers: gaUserSessions.map((user) => ({
            id: user.userId,
            session: user.session,
          })),
        })
      );
    }
  }, [dispatch, gaUserSessions, isLoaded])

  return (
    <>
      <AnalyticsViewReport reportName={sectionTitles[ReportTypes.Companies]} />
      <Row gutter={[24, 24]}>
        <Col span={12}>
          <LeftCompaniesIndicators />
        </Col>
        <Col span={12}>
          <ChurnedAndLicenses />
        </Col>
        <Col span={12}>
          <CompaniesPerType />
        </Col>
        <Col span={12}>
          <TopCountriesWithActiveCompanies topWhat={5} />
        </Col>
        <Col span={12}>
          <InsAndOutsPerDay />
        </Col>
        <Col span={12}>
          <InsAndOutsPerDayAggregated />
        </Col>
        <Col span={24}>
          <CompaniesTable />
        </Col>
      </Row>
    </>
  );
};
