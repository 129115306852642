import React from 'react';
import moment from 'moment';

export const convertToP = (content: string | undefined, containerClassname: string, pClassname:string) => {
    if(!content){
        return null;
    }
    const rows = content.split("\n");
    return <div className={containerClassname}>
        {rows.map((row, key) => <p className={pClassname} key={key}>{row}</p> )}
    </div>
}

export const extractDate = (date: string | moment.Moment):string  => {
    let dateToExtract;
    if(typeof date === 'object'){
        dateToExtract = date.toDate().toISOString();
    }else{
        dateToExtract = date;
    }
    return moment(dateToExtract.split('T')[0]).format('YYYY-MM-DD')
};