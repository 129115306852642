import ctx from "classnames";
import React, { FunctionComponent, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { ParentTypeEnum } from "../../../features/evaluation/common/enums";
import {
  CurrentItemType,
  selectStudentCourse,
  selectStudentCourseCurrentItem,
} from "../../../features/students/courseSlice";
import {
  fetchEvaluationResults,
  fetchingOneEvaluationResult,
  selectStudentEvaluationResult,
  selectStudentEvaluationResultIsFetching,
} from "../../../features/students/evaluationsResultsSlice";
import { selectEvaluationTitle } from "../../../features/students/evaluationsTitlesSlice";
import { CompletenessIndicator } from "./CompletenessIndicator";
import styles from "./courseExam.module.scss";
import { AnalyticsSelectExam, AnalyticsSelectExamRef } from './index';

interface StudentLessonCourseExamProp {
  className?: string;
  id: string;
  selected?: boolean;
}

export const StudentLessonCourseExam: FunctionComponent<StudentLessonCourseExamProp> = ({
  className,
  id,
  selected = false,
}) => {
  const currentItem = useSelector(selectStudentCourseCurrentItem);
  const result = useSelector(selectStudentEvaluationResult(id));
  const fetching = useSelector(selectStudentEvaluationResultIsFetching(id));
  const courseId = useSelector(selectStudentCourse)?._id;
  const title = useSelector(selectEvaluationTitle(id));
  const history = useHistory();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchingOneEvaluationResult(id));
    dispatch(
      fetchEvaluationResults(id, courseId ?? "", courseId ?? "", ParentTypeEnum.CourseExam)
    );
  }, [dispatch, id, courseId]);
  const selectExamRef = useRef<AnalyticsSelectExamRef>(null);

  const canGoThere =
    (currentItem.id === id &&
      currentItem.type === CurrentItemType.Evaluation) ||
    selected ||
    result?.passed;

  return (
    <div
      className={ctx(
        styles.container,
        {
          [styles.cannotGoThere]: !canGoThere,
        },
        className
      )}
    >
      <CompletenessIndicator
        progress={result?.passed ? 1 : 0}
        loading={fetching}
      />
      <div
        className={ctx(styles.title, {
          [styles.selected]: selected,
        })}
        onClick={() => {
          if (canGoThere) {
            selectExamRef?.current?.trigger();
            history.push(`/course/${courseId}/exam`);
          }
        }}
      >
        {title}
      </div>
      <AnalyticsSelectExam ref={selectExamRef}/>
    </div>
  );
};
