import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Dispatch } from "react";
import { Microservices } from "../../app/AllowedMicroservices";
import { RootState } from "../../app/store";
import apiActionCreator, { HttpMethods } from "../../services/apiActionCreator";
import { FetchStatus } from "../common/enums";

export interface ICategory {
  _id: string;
  name: string;
}

export interface ICategoriesDict {
  [_id: string]: ICategory;
}

interface CategoryState {
  list: string[];
  dict: ICategoriesDict;
  status: FetchStatus;
}

const initialState: CategoryState = {
  list: [],
  dict: {},
  status: FetchStatus.Idle,
};

interface CategoryFetch {
  docs: ICategory[];
  limit: number;
  page: number;
  pages: number;
  total: number;
}

export const categorySlice = createSlice({
  name: "categories",
  initialState,
  reducers: {
    fetching: (state) => {
      state.status = FetchStatus.Fetching;
    },
    fetched: (state, action: PayloadAction<CategoryFetch>) => {
      state.list = action.payload.docs.map((company) => company._id);
      state.dict = {};
      action.payload.docs.forEach((company) => {
        state.dict[company._id] = company;
      });
      state.status = FetchStatus.Fetched;
    },
    error: (state, _action: PayloadAction<CategoryFetch>) => {
      state.list = [];
      state.status = FetchStatus.Error;
    },
  },
});

export const { fetching, fetched, error } = categorySlice.actions;

export const fetchCategories = () => (dispatch: Dispatch<object>) => {
  return dispatch(
    apiActionCreator({
      endpoint: "/categories?limit=1000000",
      types: {
        requestType: fetching,
        successTypes: [
          {
            actionOrCreator: fetched,
          },
        ],
        errorTypes: [
          {
            actionOrCreator: error,
          },
        ],
      },
      method: HttpMethods.GET,
      microservice: Microservices.LEARNING_SERVICE,
      authenticated: true,
    })
  );
};

export const selectCategories = (state: RootState) => state.categories.list;
export const selectCategoriesAreFetching = (state: RootState) =>
  state.categories.status === FetchStatus.Fetching;
export const selectCategoriesDict = (state: RootState) => state.categories.dict;
export const selectCategory = (id: string) => (state: RootState) =>
  state.categories.dict[id];

export default categorySlice.reducer;
