import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Dispatch } from "react";
import { Microservices } from "../../app/AllowedMicroservices";
import { RootState } from "../../app/store";
import apiActionCreator, { HttpMethods } from "../../services/apiActionCreator";
import { FetchStatus } from "../common/enums";

export interface IEval {
  categoryId: string,
  course?: string;
  topic?: string;
  lesson?: string;
  finishedAt: Date | string;
}

interface UserEvaluationState {
  coursesCompleted: IEval[];
  lessonsCompleted: IEval[];
  topicsCompleted: IEval[];
  status: FetchStatus;
}

const initialState: UserEvaluationState = {
  coursesCompleted: [],
  lessonsCompleted: [],
  topicsCompleted: [],
  status: FetchStatus.Idle,
};

interface CourseEvalFetch {
  categoryId: string,
  course: string;
  finishedAt: Date | string;
}

interface LessonEvalFetch {
  categoryId: string,
  lesson: string;
  finishedAt: Date | string;
}

interface TopicsEvalFetch {
  categoryId: string,
  topic: string;
  finishedAt: Date | string;
}

export const userEvaluationsSlice = createSlice({
  name: "userEvaluation",
  initialState,
  reducers: {
    fetching: (state) => {
      state.status = FetchStatus.Fetching;
    },
    coursesCompletedFetched: (
      state,
      action: PayloadAction<CourseEvalFetch[]>
    ) => {
      state.coursesCompleted = action.payload.map((course) => course);
      state.status = FetchStatus.Fetched;
    },
    lessonsCompletedFetched: (
      state,
      action: PayloadAction<LessonEvalFetch[]>
    ) => {
      state.lessonsCompleted = action.payload.map((lesson) => lesson);
      state.status = FetchStatus.Fetched;
    },
    topicsCompletedFetched: (
      state,
      action: PayloadAction<TopicsEvalFetch[]>
    ) => {
      state.topicsCompleted = action.payload.map((topic) => topic);
      state.status = FetchStatus.Fetched;
    },
    error: (state, _action: PayloadAction<CourseEvalFetch>) => {
      state.coursesCompleted = [];
      state.lessonsCompleted = [];
      state.topicsCompleted = [];
      state.status = FetchStatus.Error;
    },
  },
});

export const {
  fetching,
  coursesCompletedFetched,
  lessonsCompletedFetched,
  topicsCompletedFetched,
  error,
} = userEvaluationsSlice.actions;

export const fetchCompletedCourses = (username: string) => (
  dispatch: Dispatch<object>
) => {
  return dispatch(
    apiActionCreator({
      endpoint: `/courses/completed/${username}`,
      types: {
        requestType: fetching,
        successTypes: [
          {
            actionOrCreator: coursesCompletedFetched,
          },
        ],
        errorTypes: [
          {
            actionOrCreator: error,
          },
        ],
      },
      method: HttpMethods.GET,
      microservice: Microservices.LEARNING_SERVICE,
      authenticated: true,
    })
  );
};

export const fetchCompletedLessons = (username: string) => (
  dispatch: Dispatch<object>
) => {
  return dispatch(
    apiActionCreator({
      endpoint: `/lessons/completed/${username}`,
      types: {
        requestType: fetching,
        successTypes: [
          {
            actionOrCreator: lessonsCompletedFetched,
          },
        ],
        errorTypes: [
          {
            actionOrCreator: error,
          },
        ],
      },
      method: HttpMethods.GET,
      microservice: Microservices.LEARNING_SERVICE,
      authenticated: true,
    })
  );
};

export const fetchCompletedTopics = (username: string) => (
  dispatch: Dispatch<object>
) => {
  return dispatch(
    apiActionCreator({
      endpoint: `/topics/completed/${username}`,
      types: {
        requestType: fetching,
        successTypes: [
          {
            actionOrCreator: topicsCompletedFetched,
          },
        ],
        errorTypes: [
          {
            actionOrCreator: error,
          },
        ],
      },
      method: HttpMethods.GET,
      microservice: Microservices.LEARNING_SERVICE,
      authenticated: true,
    })
  );
};

export const selectIsCompletedEvaluationFetching = (state: RootState) =>
  state.userEvaluationStats.status === FetchStatus.Fetching;
export const selectCompletedCourses = (state: RootState) =>
  state.userEvaluationStats.coursesCompleted;
export const selectCompletedLessons = (state: RootState) =>
  state.userEvaluationStats.lessonsCompleted;
export const selectCompletedTopics = (state: RootState) =>
  state.userEvaluationStats.topicsCompleted;

export default userEvaluationsSlice.reducer;
