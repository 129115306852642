import { Countries } from './Form';
export const getCountryName = (countryCode: string) => {
    const country = Countries.find(country => country.value === countryCode);
    if(country){
        return country.label;
    }
    return null;
}

export const getGroupsForUser = (oldGoups: string[], newGroup:string) => {
    const groups = new Set(oldGoups);
    groups.add(newGroup);
    return Array.from(groups);
};