import React, { useState, FunctionComponent, useEffect } from 'react';
import { Form, Col, Row, Button, Input } from 'antd';
import cls from 'classnames';

import { Header } from '../Header';

import styles from './index.module.scss';
import commonStyles from '../Login.module.scss';
import { ISignUpStepProps, layout } from './interfaces';
import { MIN_3_CHAR } from '../../Users/Form';

interface Step2Props extends ISignUpStepProps {

};

export const Step2: FunctionComponent<Step2Props> = ({ next, userData }) => {
  const [given_name, setFirstName] = useState<string>('');
  const [middle_name, setMiddleName] = useState<string>('');
  const [family_name, setLastName] = useState<string>('');
  const [secondLastname, setSecondLastName] = useState<string>('');
  useEffect(() => {
  }, []);
  const handleSubmit = () => {
      form.validateFields().then(()=>{
        next({
          given_name,
          middle_name,
          family_name,
          secondLastname,
        });
      });
    
  };

  useEffect(() => {
    setFirstName(userData.given_name || '');
    setMiddleName(userData.middle_name || '');
    setLastName(userData.family_name || '');
    setSecondLastName(userData.secondLastname || '');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [form] = Form.useForm();
  return (
    <Form
      {...layout}
      form={form}
      name="basic"
      onFinish={() => {
        handleSubmit();
      }}
      onFinishFailed={() => {
        console.log('signup failed');
      }}
      
    >
      <Col
        span={24}
        className={cls(commonStyles.formInnerContainer, commonStyles.overload)}
      >
        <Row>
          <Col span={18} offset={3}>
            <Header title={'Presentate'}>
              <div className={styles.subTitles}>
              No te preocupes, esta información la usaremos solo como datos de registro.
              </div>
            </Header>
            <Form.Item
              label="Primer nombre"
              name="firstName"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              initialValue={given_name}
              rules={[
                { type: "string", required: true, message: "Requerido" },
                { type: "string", max: 15, message: "Máximo 15 caracteres" },
                { type: "string", min: 3, message: MIN_3_CHAR },
                {
                  validator: (rule, value: string = "", callback) => {
                    if (value.match(/\d/)) {
                      callback("No se permiten números");
                      return;
                    }
                    callback();
                  },
                },
              ]}
            >
              <Input
                onChange={(event) => {
                  setFirstName(event.target.value);
                }}
              />
            </Form.Item>
            <Form.Item
              label="Segundo nombre"
              name="middleName"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              initialValue={middle_name}
              rules={[
                { type: "string", required: false, message: "Requerido" },
                { type: "string", max: 15, message: "Máximo 15 caracteres" },
                { type: "string", min: 3, message: MIN_3_CHAR },
                {
                  validator: (rule, value: string = "", callback) => {
                    if (value.match(/\d/)) {
                      callback("No se permiten números");
                      return;
                    }
                    callback();
                  },
                },
              ]}
            >
              <Input
                onChange={(event) => {
                  setMiddleName(event.target.value);
                }}
              />
            </Form.Item>
            <Form.Item
              label="Primer apellido"
              name="lastName"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              initialValue={family_name}
              rules={[
                { type: "string", required: true, message: "Requerido" },
                { type: "string", max: 15, message: "Máximo 15 caracteres" },
                { type: "string", min: 3, message: MIN_3_CHAR },
                {
                  validator: (rule, value: string = "", callback) => {
                    if (value.match(/\d/)) {
                      callback("No se permiten números");
                      return;
                    }
                    callback();
                  },
                },
              ]}
            >
              <Input
                onChange={(event) => {
                  setLastName(event.target.value);
                }}
              />
            </Form.Item>
            <Form.Item
              label="Segundo apellido"
              name="secondLastName"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              initialValue={secondLastname}
              rules={[
                { type: "string", required: false, message: "Requerido" },
                { type: "string", max: 15, message: "Máximo 15 caracteres" },
                { type: "string", min: 3, message: MIN_3_CHAR },
                {
                  validator: (rule, value: string = "", callback) => {
                    if (value.match(/\d/)) {
                      callback("No se permiten números");
                      return;
                    }
                    callback();
                  },
                },
              ]}
            >
              <Input
                onChange={(event) => {
                  setSecondLastName(event.target.value);
                }}
              />
            </Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              disabled={false}
              className={cls(commonStyles.signupBtn, styles.signupBtn2)}
            >
              Continuar
            </Button>
          </Col>
        </Row>
      </Col>
    </Form>
  );
};
