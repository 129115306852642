import React, { FunctionComponent, useEffect } from "react";
import { Layout } from "antd";
import { Router, Switch, Route } from "react-router-dom";
import * as H from "history";

import { Header } from "../../components/Header";
import { FinishRegistration } from "../../components/Authentication/FinishRegistration";
import { PasswordChange } from "../../components/Authentication/PasswordChange";
import { Login } from "../../components/Authentication/Login";
import { Message } from "../../components/common/Message";
import { Welcome } from "../../components/Authentication/Welcome";
import { Group } from "../../components/Group";
import { CompanyProfile } from "../../components/Company/CompanyProfile";
import { Company } from "../../components/Company";
import { AdminUsers } from "../../components/Users/AdminUsers";
import { StudentProfile } from "../../components/Profile/StudentProfile";
import { Students } from "../../components/Users/Students";
import { Profile } from "../../components/Profile";
import { Courses } from "../../components/Courses";
import { Topic } from "../../components/Topic";
import { Program } from "../../components/Program";
import { KnowledgeBase } from "../../components/KnowledgeBase";
import { Campaigns } from "../../components/Campaign";
import { Reports } from "../../components/Reports";
import { LoggedUserProfile } from "../../components/LoggedUser/LoggedUserProfile";
import ScrollToTop from "../../components/common/ScrollToTop";
import { useDispatch } from "react-redux";
import { clearBreadcrumbs } from "../../features/breadcrumbs/breadcrumbsSlice";
import { Blueprints } from "../../components/Blueprint";
import { Notifications } from "../../components/Notifications";
import { PrintableProfile } from "../../components/Printable/Profile";
import { CompanyPrintableProfile } from "../../components/Printable/CompanyProfile";
import { AllNotifications } from "../../components/Students/Notifications";
import { Lessons } from "../../components/Lesson";
import { Evaluations } from "../../components/Evaluation";
import { AnalyticsCompanyDimSetter } from '../../components/common/AnalyticsCompanyDimSetter';

const { Content } = Layout;

interface AdminRouterProps {
  history: H.History<any>;
}

export const AdminRouter: FunctionComponent<AdminRouterProps> = ({
  history,
}) => {
  const dispatch = useDispatch();
  useEffect(() => {
    return history.listen((location) => {
      dispatch(clearBreadcrumbs());
    });
  }, [dispatch, history]);
  return (
    <Router history={history}>
      <AnalyticsCompanyDimSetter />
      <ScrollToTop />
      <Layout>
        <Header isLoggedIn />
        <Content className="site-layout" style={{ position: "relative" }}>
          <Switch>
            <Route path="/my-profile">
              <LoggedUserProfile />
            </Route>
            <Route path="/new-password">
              <FinishRegistration />
            </Route>
            <Route path="/change-password">
              <PasswordChange />
            </Route>
            <Route path="/welcome">
              <Welcome />
            </Route>
            <Route path="/groups">
              <Group />
            </Route>
            <Route path="/companies/:id/print">
              <CompanyPrintableProfile />
            </Route>
            <Route path="/companies/:id">
              <CompanyProfile />
            </Route>
            <Route path="/companies">
              <Company />
            </Route>
            <Route path="/admin-users/:id/print">
              <PrintableProfile />
            </Route>
            <Route path="/admin-users/:id">
              <Profile />
            </Route>
            <Route path="/admin-users">
              <AdminUsers />
            </Route>
            <Route path="/students/:id/print">
              <PrintableProfile />
            </Route>
            <Route path="/students/:id">
              <StudentProfile />
            </Route>
            <Route path="/students">
              <Students />
            </Route>
            <Route path="/lessons">
              <Lessons />
            </Route>
            <Route path="/evaluations">
              <Evaluations />
            </Route>
            <Route path="/campaigns">
              <Campaigns />
            </Route>
            <Route path="/courses">
              <Courses />
            </Route>
            <Route path="/topics">
              <Topic />
            </Route>
            <Route path="/programs">
              <Program />
            </Route>
            <Route path="/knowledgeBase">
              <KnowledgeBase />
            </Route>
            <Route path="/reports">
              <Reports />
            </Route>
            <Route path="/certificates">
              <Blueprints />
            </Route>
            <Route path="/notifications">
              <Notifications />
            </Route>
            <Route path="/all/notifications">
              <AllNotifications />
            </Route>
            <Route path="/">
              <Login />
            </Route>
          </Switch>
        </Content>
        <Message />
      </Layout>
    </Router>
  );
};
