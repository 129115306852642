export function isCIValid(ci: string) {
	const cad = ci.trim();
	let total = 0;
	const longitud = cad.length;
	const longcheck = longitud - 1;
	if(isNaN(parseInt(ci))){
		return false;
	}
	if (cad === "" && longitud !== 10) {
		return false;
	}
	const digito_region = parseInt(ci.substring(0, 2));
	if (digito_region < 1 || digito_region > 30) {
		return false;
	}
	for (let i: number = 0; i < longcheck; i++) {
		if (i % 2 === 0) {
			let aux = parseInt(cad.charAt(i), 10) * 2;
			if (aux > 9) {
				aux -= 9;
			}
			total += aux;
		} else {
			total += parseInt(cad.charAt(i)); // parseInt o concatenará en lugar de sumar
		}
	}

	total = total % 10 ? 10 - total % 10 : 0;

	if (cad.charAt(longitud - 1) === `${total}`) {
		return true;
	}
	return false;
}