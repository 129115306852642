import { Button, Form, Table, Tooltip } from "antd";
import { ColumnsType } from "antd/es/table";
import { FormInstance } from "antd/lib/form";
import moment from "moment";
import { RangeValue } from "rc-picker/es/interface";
import React, { ReactText, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCognitoUserData } from "../../features/auth/authSlice";
import {
  createCampaign,
  fetchCampaigns,
  hideModal,
  ICampaign,
  publishCampaignStatus,
  selectCampaigns,
  selectCampaignsAreFetching,
  selectCampaignStatus,
  selectDisplayModal,
  unpublishCampaignStatus,
  updateCampaign,
  VisibilityRole,
  selectCreatedCampaign,
} from "../../features/campaign/campaignSlice";
import { ConditionEnum } from "../../features/common/enums";
import {
  fetchCompanies,
  selectCompanies,
} from "../../features/company/companySlice";
import { UserRole } from "../../features/users/usersSlice";
import { getDateFilterAndSortProps } from "../common/DateFilter";
import { DialogPopUp } from "../common/DialogPopUp";
import { CogDrawer } from "../common/Drawer";
import { MainLayout } from "../common/Layouts/Main";
import tableStyles from "../common/table.module.scss";
import { TableTools } from "../common/TableTools";
import { CompanyFilter } from "../Filters/Company";
import { useDebounce } from "../utils/useDebounce";
import { CampaignTile } from "./CampaignTile";
import { CampaignForm, InitialValues } from "./Form";
import styles from "./index.module.scss";
import { analytics } from "../../services/analytics";

const PAGE_SIZE = 10;
const EMPTY_VALUE = {
  title: "",
  description: "",
  fromDate: "",
  toDate: "",
  companyId: undefined,
  rangeDate: undefined,
};

function renderOptions(
  setInitialValues: React.Dispatch<
    React.SetStateAction<InitialValues | undefined>
  >,
  setAction: React.Dispatch<React.SetStateAction<string>>,
  form: FormInstance
) {
  return (
    <Button
      onClick={() => {
        setAction("create");
        setInitialValues(EMPTY_VALUE);
        form.setFieldsValue(EMPTY_VALUE);
      }}
      type="primary"
    >
      Nueva campaña
    </Button>
  );
}

export const Campaigns: React.FunctionComponent = () => {
  const [dateFilterValues, setDateFilterValues] = useState<
    RangeValue<moment.Moment>
  >();
  const [action, setAction] = useState<string>("");
  const [filterValue, setFilterValue] = useState<ReactText[]>([]);
  const [selectedRowKeys, selectRowKeys] = useState<ReactText[]>([]);
  const [showActivationBtns, setShowActivationBtns] = useState(false);
  const [initialValues, setInitialValues] = useState<InitialValues | undefined>(
    undefined
  );
  const [currentCampaign, setCurrentCampaign] = useState<ICampaign | undefined>(
    undefined
  );
  const [currentAction, setCurrentAction] = useState("");
  const [form] = Form.useForm();
  const list = useSelector(selectCampaigns);
  const companiesDict = useSelector(selectCompanies);
  const isModalVisible = useSelector(selectDisplayModal);
  const debounceFilterValue = useDebounce(filterValue, 500);
  const areCampaignsFetching = useSelector(selectCampaignsAreFetching);
  const isLoading = useSelector(selectCampaignStatus);
  const cognitoData = useSelector(getCognitoUserData);
  const role = cognitoData ? cognitoData["custom:role"] : undefined;
  const cognitoUsername = cognitoData ? cognitoData['cognito:username'] : null;
  const createdCampaign = useSelector(selectCreatedCampaign);
  const CustomRow = (props:any) => {
    const { children = [] } = props;
    const row = <tr {...props} />;
    if(!Array.isArray(children)){
      return row
    }
    const [{props: { record = {} }}] = children;
    if(record.survey){
      return row;
    }
    return (
      <Tooltip title="Necesita configurar encuesta">
        {row}
      </Tooltip>
    );
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedRowKeys: ReactText[]) => {
      selectRowKeys(selectedRowKeys);
    },
    getCheckboxProps: (record: ICampaign) => {
      return {
        disabled: !record.survey,
        title: '',
      };
    },
  };
  const dispatch = useDispatch();

  const handleEditCampaign = (campaign: ICampaign) => {
    setAction("edit");
    setCurrentCampaign(campaign);
    form.setFieldsValue({
      companyId: campaign.companyId,
      description: campaign.description,
      title: campaign.title,
      survey: campaign.survey,
      rangeDate: [moment(campaign.fromDate), moment(campaign.toDate)],
      image: campaign.image,
      resultsVisibilityRole:
        campaign.resultsVisibilityRole ?? VisibilityRole.AllRoles,
    });
  };

  const columns: ColumnsType<ICampaign> = [
    {
      title: "",
      colSpan: 0,
      render: () => ({
        props: {
          colSpan: 0,
        },
      }),
      filteredValue: debounceFilterValue,
      onFilter: (value, record) => {
        const from = dateFilterValues?.[0];
        const to = dateFilterValues?.[1];
        const textFilterResult =
          record.title.toLowerCase().includes(value.toString().toLowerCase()) ||
          record.companyId === (value as string);
        if (!to || !from || !record.createdAt) {
          return textFilterResult;
        }
        return (
          textFilterResult &&
          moment(record.createdAt).diff(from.startOf("day")) >= 0 &&
          moment(record.createdAt).diff(to.endOf("day")) <= 0
        );
      },
    },
    {
      colSpan: 2,
      title: "",
      dataIndex: "title",
      sortDirections: ["descend", "ascend"],
      defaultSortOrder: "ascend",
      ellipsis: true,
      ...getDateFilterAndSortProps<ICampaign>(
        dateFilterValues,
        setDateFilterValues
      ),
      render: (_value, campaign: ICampaign) => {
        return {
          props: {
            colSpan: 2,
          },
          children: (
            <CampaignTile
              campaign={campaign}
              companyName={companiesDict[campaign.companyId]?.name}
              onEdit={handleEditCampaign}
            />
          ),
        };
      },
      sorter: (a: ICampaign, b: ICampaign) => a.title.localeCompare(b.title),
    },
  ];

  useEffect(() => {
    if (createdCampaign) {
      analytics.createPromoBanner({
        userName: cognitoUsername,
        companyName: companiesDict[createdCampaign.companyId]?.name,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createdCampaign]);

  useEffect(() => {
    if (currentCampaign) {
      setInitialValues({
        companyId: currentCampaign.companyId,
        description: currentCampaign.description,
        title: currentCampaign.title,
        survey: currentCampaign.survey,
        rangeDate: [
          moment(currentCampaign.fromDate),
          moment(currentCampaign.toDate),
        ],
        image: currentCampaign.image,
        resultsVisibilityRole:
          currentCampaign.resultsVisibilityRole ?? VisibilityRole.AllRoles,
      });
    }
  }, [currentCampaign]);

  useEffect(() => {
    if (selectedRowKeys.length > 0) {
      setShowActivationBtns(true);
    } else {
      setShowActivationBtns(false);
    }
  }, [selectedRowKeys]);

  useEffect(() => {
    dispatch(fetchCampaigns());
    dispatch(fetchCompanies());
  }, [dispatch]);

  useEffect(() => {
    if (action === "" && initialValues && !isLoading) {
      setInitialValues(undefined);
      setCurrentCampaign(undefined);
    }
  }, [action, isLoading, initialValues]);

  const handleClose = () => {
    setCurrentCampaign(undefined);
    setInitialValues(undefined);
    form.setFieldsValue(EMPTY_VALUE);
  };

  const enableStatus = () => {
    const data = {
      ids: selectedRowKeys.map((key) => key.toString()),
    };
    dispatch(publishCampaignStatus(data));
  };

  const disableStatus = () => {
    const data = {
      ids: selectedRowKeys.map((key) => key.toString()),
    };
    dispatch(unpublishCampaignStatus(data));
  };

  const handleFinish = (campaign: ICampaign) => {
    if (currentCampaign?._id) {
      dispatch(
        updateCampaign({
          ...campaign,
          _id: currentCampaign?._id,
        })
      );
      setCurrentAction("actualizada");
      setAction("");
      form.setFieldsValue(EMPTY_VALUE);
      return;
    }
    dispatch(createCampaign(campaign));
    setCurrentAction("creada");
    setAction("");
    form.setFieldsValue(EMPTY_VALUE);
  };

  const handleSave = (publish?: ConditionEnum) => () => {
    if (initialValues) {
      form
        .validateFields()
        .then((values) => {
          const fromDate = values.rangeDate
            ? values.rangeDate[0].format('YYYY-MM-DD')
            : "";
          const toDate = values.rangeDate ? values.rangeDate[1].format('YYYY-MM-DD') : "";
          const newCampaign: ICampaign = {
            _id: "",
            image: initialValues.image,
            companyId: values.companyId,
            survey: initialValues.survey,
            title: values.title,
            description: values.description,
            fromDate,
            toDate,
            published: publish ? publish : undefined,
          };
          if (initialValues.survey && initialValues.resultsVisibilityRole) {
            newCampaign.resultsVisibilityRole =
              initialValues.resultsVisibilityRole;
          }
          handleFinish(newCampaign);
        })
        .catch((_error) => {});
    }
  };

  return (
    <MainLayout
      title="Campañas"
      rightTopOptions={renderOptions(setInitialValues, setAction, form)}
    >
      <DialogPopUp
        name="Campaña"
        action={currentAction}
        visible={isModalVisible}
        onCancel={() => {
          dispatch(hideModal());
        }}
      />
      <TableTools
        placeholder="Buscar en Teurona"
        onEnable={() => enableStatus()}
        onDisable={() => disableStatus()}
        onSearch={(value) => setFilterValue([value])}
        onChange={(event) => setFilterValue([event.target.value])}
        filter={
          role === UserRole.SuperAdmin ? (
            <CompanyFilter onApply={setFilterValue} />
          ) : (
            undefined
          )
        }
        activationBtnsVisible={showActivationBtns}
      />
      <Table<ICampaign>
        loading={areCampaignsFetching}
        className={tableStyles.table}
        rowClassName={() => tableStyles.row}
        rowKey="_id"
        rowSelection={rowSelection}
        columns={columns}
        dataSource={list}
        pagination={{ position: ["bottomRight"], pageSize: PAGE_SIZE }}
        components={{
          body: {
            row: CustomRow
          }
        }}
      />
      {initialValues && (
        <CogDrawer
          title={`${currentCampaign ? "Editar" : "Nueva"} campaña`}
          visible={true}
          toggleVisible={handleClose}
          loading={isLoading}
          footer={
            <div className={styles.content}>
              <Button type="default" onClick={handleClose} disabled={isLoading}>
                Cancelar
              </Button>
              <Button
                type="default"
                onClick={handleSave()}
                disabled={isLoading}
              >
                {currentCampaign?._id ? "Editar" : "Guardar"}
              </Button>
              <Button
                type="primary"
                onClick={handleSave(ConditionEnum.Published)}
                disabled={isLoading || !initialValues?.survey}
              >
                {currentCampaign?._id
                  ? "Editar y publicar"
                  : "Guardar y publicar"}
              </Button>
            </div>
          }
        >
          <CampaignForm
            form={form}
            campaignId={currentCampaign?._id}
            initialValues={initialValues}
            onUpdateValues={setInitialValues}
          />
        </CogDrawer>
      )}
    </MainLayout>
  );
};
