import { Alert, Button, Dropdown, Menu } from "antd";
import React, { ReactNode, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../app/store";
import { selectCompanyId, selectUserRole } from '../../../features/auth/authSlice';
import { EvaluationTypeEnum, EvaluationTypeLabelEnum, EvaluationTypeLabelEnum2 } from "../../../features/evaluation/common/enums";
import { IEvaluation } from "../../../features/evaluation/common/interfaces";
import {
  fetchEvaluations,
  selectEvaluationsDict
} from "../../../features/evaluation/evaluationsSlice";
import { UserRole } from '../../../features/users/usersSlice';
import { StatusEnum } from "../../common/Status";
import { CogIcon } from "../CogIcon";
import styles from "./index.module.scss";

interface Props {
  type: EvaluationTypeEnum;
  hideSelectorAfterSelection?: boolean;
  evaluationId?: string;
  children?: ReactNode;
  evaluationSelector(state: RootState): string[];
  onUpdate(selectedEvaluation: IEvaluation): void;
  disabled?: boolean;
  companyId: string;
  parentLabel: string;
}

const EvaluationField: React.FunctionComponent<Props> = ({
  evaluationId,
  hideSelectorAfterSelection = false,
  type,
  children,
  evaluationSelector,
  onUpdate,
  disabled = false,
  companyId,
  parentLabel = ''
}) => {
  const dispatch = useDispatch();
  const list = useSelector(evaluationSelector);
  const dict = useSelector(selectEvaluationsDict);
  const userRole = useSelector(selectUserRole);
  const userCompanyId = useSelector(selectCompanyId);

  useEffect(() => {
    dispatch(fetchEvaluations());
  }, [dispatch]);
  const title = EvaluationTypeLabelEnum[type];
  const action = evaluationId ? "Cambiar" : "Añadir";

  const evaluationByTitleComparator = (a: IEvaluation, b: IEvaluation) => {
    const aName = a.title.toLowerCase();
    const bName = b.title.toLowerCase();

    if (aName < bName) {
      return -1;
    }
    if (aName > bName) {
      return 1;
    }
    return 0;
  };
  const menuItems = list
    .filter((item) => 
      {
        const isEvaluationEnable = dict[item].status === StatusEnum.Enable;
        const isSuperAdmin = userRole === UserRole.SuperAdmin;
        const topicCompanyId = isSuperAdmin ? (!companyId ? undefined : companyId) : userCompanyId;
        const itemFromCompany = dict[item].companyId === topicCompanyId || (isSuperAdmin && dict[item].companyId === undefined);
        return isEvaluationEnable && itemFromCompany
      }
    )
    .map((element) => dict[element])
    .sort(evaluationByTitleComparator)
    .map((evaluation) => {
      return (
        <Menu.Item
          key={evaluation._id}
          onClick={() => {
            if (evaluation._id === evaluationId) {
              return;
            }
            onUpdate(evaluation);
          }}
        >
          <p>{evaluation.title}</p>
        </Menu.Item>
      );
    });
  const hasEvaluationAvailable = menuItems.length > 0;
  const menu = (
    <Menu>
      {menuItems}
    </Menu>
  );

  return (
    <React.Fragment>
      <div className={styles.sectionTitle}>{title}</div>
      {children}
      {hideSelectorAfterSelection && evaluationId ? null : (
        hasEvaluationAvailable ? <Dropdown overlay={menu} trigger={["click"]}>
          <p
            className="ant-dropdown-link"
            onClick={(e) => {
              e.preventDefault();
            }}
          >
            <Button disabled={disabled} className={styles.addEvaluationButton} type="default">
              <CogIcon className={styles.square} color="white" icon="Plus" />
              {`${action} ${title.toLowerCase()}`}
            </Button>
          </p>
        </Dropdown> : <Alert message={`Termina de configurar ${parentLabel} y luego crea ${EvaluationTypeLabelEnum2[type]} en la sección correspondiente`} type="info"/>
      )}
    </React.Fragment>
  );
};

export default EvaluationField;
