import React, { FunctionComponent, useEffect, useState } from 'react';
import moment from 'moment';

import { Day } from './Day';
import { EventsDict, Week } from './interfaces';
import { Header } from './Header';
import { Navigator } from './Navigator';
import { DayView } from './DayView';


import styles from './index.module.scss';
import { IStudentCourse } from '../../../../features/students/common';

interface CalendarProps {
  date: Date,
  onSelectDate: (date: Date) => void,
  courses: IStudentCourse[],
  weekStartOnSunday?: boolean,
}

const getWeeks = (firstDate: moment.Moment, lastDate: moment.Moment, dayOfWeek:number):Week[] => {
  let currentDate: moment.Moment = firstDate.clone().startOf('week');
  let week:Week = []; 
  const weeks:Week[] = [];
  for(let i = 0; i < dayOfWeek; ++i){
    week.push({
      date: currentDate.clone(),
      events: [],
      outOfTheMonth: true,
    });
    currentDate = currentDate.add(1, 'd');
  }
  do {
    week.push({
      date: currentDate.clone(),
      events: [],
      outOfTheMonth: false,
    });
    if(week.length >= 7){
      weeks.push(week);
      week = [];
    }
    currentDate = currentDate.add(1, 'd');
  }while(currentDate.isBefore(lastDate));

  if(week.length > 0){
    for(let i = week.length; i < 7; ++i){
      week.push({
        date: currentDate.clone(),
        events: [],
        outOfTheMonth: true,
      });
      currentDate = currentDate.add(1, 'd');
    }
    weeks.push(week);
    week = [];
  }
  return weeks;
};

export const Calendar: FunctionComponent<CalendarProps> = ({date, courses = [], weekStartOnSunday = true}) => {
  const [eventDict, setEventDict] = useState<EventsDict>({});
  const [currentDate, setCurrentDate] = useState<moment.Moment>();
  const [weeks, setWeeks] = useState<Week[]>([]);
  const [selectedDate , setSelectedDate ] = useState<moment.Moment>(moment('2021-06-10'));
  const calculateMonth = (date:Date|moment.Moment, weekStartOnSunday:boolean, courses:IStudentCourse[]) => {
    const momentDate = moment(date);
    const first = momentDate.clone().startOf('month');
    const last = momentDate.clone().endOf('month');
    const cloneFirst = first.clone();
    const dayOfWeek = weekStartOnSunday ? cloneFirst.day() : cloneFirst.isoWeekday() - 1;
    const weeks = getWeeks(first, last, dayOfWeek);
    setCurrentDate(momentDate);
    const enventDict:EventsDict = {};
    weeks.forEach((week) => {
      week.forEach(day=>{
        courses.forEach(course => {
          if(!course.extra || !day){
            return;
          }
          if(day.date.isAfter(course.extra.startDate) && day.date.isBefore(course.extra.endDate)){
            day.events.push(course.course._id);
            enventDict[course.course._id] = {
              id: course.course._id,
              priority: course.extra.priority,
              tooltip: course.course.title,
              endDate: moment(course.extra.endDate)
            };
          }
        });
      });
    });
    setEventDict(enventDict);
    setWeeks(weeks);
  }
  useEffect(() => {
    calculateMonth(date, weekStartOnSunday, courses);
  }, [date, weekStartOnSunday, courses]);

  if(!currentDate){
    return <div>loading</div>;
  }
  let selectedDay:any = null;
  weeks.forEach(week=>{
    week.forEach(day=>{
      const isSelected = day?.date.isSame(selectedDate) || false;
      if(isSelected){
        selectedDay = day;
      }
    })
  });

  return <div className={styles.container}>
    <div className={styles.calendar}>
      <Navigator currentDate={currentDate} onChangeCurrentDate={(date)=>{
        calculateMonth(date, weekStartOnSunday, courses);
      }} />
      <Header weekStartOnSunday={weekStartOnSunday} />
      {weeks.map(week=>{
        return <div className={styles.week}>{week.map(day=>{
          const isSelected = day?.date.isSame(selectedDate) || false;
          return <Day
            eventsDict={eventDict}
            selected={isSelected}
            date={day} 
            onSelectDate={()=>{
              setSelectedDate(day?.date);
              if(day.outOfTheMonth){
                calculateMonth(day.date, weekStartOnSunday, courses);
              }
          }} />
        })}</div>;
      })}
    </div>
    <DayView eventsDict={eventDict} events={selectedDay?.events} selectedDate={selectedDate} onChangeSelectedDate={(date)=>{
      setSelectedDate(date);
      if(currentDate.format('M') !== date.format('M')){
        calculateMonth(date, weekStartOnSunday, courses);
      }
    }}/>
  </div>
}