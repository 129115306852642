import { Select } from "antd";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { UserReportData, UserReportRoleDict, UserReportType } from "..";
import { selectGroups } from "../../../../features/groups/groupSlice";
import {
  CHART_COLORS,
  IReportDataset,
} from "../../../../features/reports/common";
import { GenderDict, GenderEnum } from "../../../../features/users/usersSlice";
import LoadingOverlay from "../../../common/LoadingOverlay";
import { BarChartGrouped } from "../../common/BarChartGrouped";
import styles from "./index.module.scss";

const { Option } = Select;

interface AgeInterval {
  min: number;
  max: number;
}

enum AgeGroupsEnum {
  young = "young",
  youngAdult = "youngAdult",
  adult = "adult",
  mature = "mature",
}

const AgeGroupDict: Record<AgeGroupsEnum, string> = {
  young: "18-30",
  youngAdult: "31-50",
  adult: "51-70",
  mature: "71 o mayor",
};

const AgeGroups: Record<AgeGroupsEnum, AgeInterval> = {
  young: { min: 18, max: 30 },
  youngAdult: { min: 31, max: 50 },
  adult: { min: 51, max: 70 },
  mature: { min: 71, max: 120 },
};

const getData = (list: UserReportData[], type: UserReportType) => {
  const userList = list.filter((user) => user.userType === type);
  const activeUsers = userList.filter((user) => user.active);
  return [activeUsers.length, userList.length - activeUsers.length];
};

const checkAgeRange = (age: number, ageGroup?: AgeGroupsEnum) => {
  if (!ageGroup) {
    return false;
  }
  return age >= AgeGroups[ageGroup].min && age <= AgeGroups[ageGroup].max;
};

const filterCondition = (
  user: UserReportData,
  group?: string,
  gender?: GenderEnum,
  ageGroup?: AgeGroupsEnum
) => {
  return (
    (!group || user.groupId === group) &&
    (!gender || user.gender === gender) &&
    (!ageGroup || checkAgeRange(user.age, ageGroup))
  );
};

interface Props {
  enabledUsers: UserReportData[];
  isLoading: boolean;
  companyId?: string;
}

export const UsersStateChart: React.FunctionComponent<Props> = ({
  enabledUsers,
  isLoading,
  companyId,
}) => {
  const [selectedGroup, setSelectedGroup] = useState<string>();
  const [selectedGender, setSelectedGender] = useState<GenderEnum>();
  const [selectedAgeGroup, setSelectedAgeGroup] = useState<AgeGroupsEnum>();

  const gruopsList = useSelector(selectGroups);

  const onGroupChange = (value: string) => setSelectedGroup(value);
  const onGenderChange = (value: GenderEnum) => setSelectedGender(value);
  const onAgeGroupChange = (value: AgeGroupsEnum) => setSelectedAgeGroup(value);

  useEffect(() => {
    setSelectedGroup(undefined);
    setSelectedGender(undefined);
  }, [companyId]);

  const filteredEnabledUsers = enabledUsers.filter((user) =>
    filterCondition(user, selectedGroup, selectedGender, selectedAgeGroup)
  );

  const filteredGroupList = companyId
    ? gruopsList.filter((group) => group.company === companyId)
    : gruopsList;

  const userTypeData: IReportDataset[] = [
    {
      label: UserReportRoleDict.Supervisor,
      data: getData(filteredEnabledUsers, UserReportType.Supervisor),
      color: CHART_COLORS[1],
    },
    {
      label: UserReportRoleDict.CompanyAdmin,
      data: getData(filteredEnabledUsers, UserReportType.CompanyAdmin),
      color: CHART_COLORS[0],
    },
    {
      label: UserReportRoleDict.Student,
      data: getData(filteredEnabledUsers, UserReportType.Student),
      color: CHART_COLORS[2],
    },
  ];

  return (
    <>
      <div className={styles.title}>
        Cantidad de usuarios activos e inactivos
      </div>
      {isLoading ? (
        <LoadingOverlay inline height={420} spinning />
      ) : (
        <>
          <Select
            allowClear
            value={selectedGroup}
            onChange={onGroupChange}
            className={selectedGroup ? styles.activeSelector : styles.selector}
            style={{ width: 100, margin: "7px 8px" }}
            placeholder="Grupo"
            dropdownMatchSelectWidth={200}
          >
            {filteredGroupList.map((group) => (
              <Option value={group._id} key={group._id}>
                {group.name}
              </Option>
            ))}
          </Select>

          <Select
            allowClear
            value={selectedGender}
            onChange={onGenderChange}
            className={selectedGender ? styles.activeSelector : styles.selector}
            style={{ width: 100, margin: "7px 8px" }}
            placeholder="Género"
            dropdownMatchSelectWidth={200}
          >
            {Object.values(GenderEnum).map((gender) => (
              <Option value={gender} key={gender}>
                {GenderDict[gender]}
              </Option>
            ))}
          </Select>

          <Select
            allowClear
            value={selectedAgeGroup}
            onChange={onAgeGroupChange}
            className={
              selectedAgeGroup ? styles.activeSelector : styles.selector
            }
            style={{ width: 100, margin: "7px 8px" }}
            placeholder="Edad"
            dropdownMatchSelectWidth={200}
          >
            {Object.values(AgeGroupsEnum).map((ageGroup) => (
              <Option value={ageGroup} key={ageGroup}>
                {AgeGroupDict[ageGroup]}
              </Option>
            ))}
          </Select>

          <BarChartGrouped
            datasets={userTypeData}
            labels={["Activos", "Inactivos"]}
            labelColumnTitle="my label"
            fetching={isLoading}
            axisMaxValue={
              enabledUsers.length > 0 ? enabledUsers.length : undefined
            }
          />
        </>
      )}
    </>
  );
};
