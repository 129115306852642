import React, { FunctionComponent, useState, useRef, useEffect } from "react";
// @ts-ignore
import ReactPasswordStrength from "react-password-strength";
import cls from "classnames";
import { Progress } from "antd";
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';

import styles from "./PasswordInput.module.scss";

interface FooterProps {
  showMeter?: boolean;
  onChange?: (password: string) => void;
}

export const PasswordInput: FunctionComponent<FooterProps> = ({
  showMeter = true,
  onChange = passwrd => {}
}) => {
  const [passStrength, setPassStrength] = useState<0 | 1 | 2 | 3 | 4>(0);
  const [password, setPassword] = useState<string>("");
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const prevShowRef = useRef<boolean>(showPassword);

  useEffect(() => {
    prevShowRef.current = showPassword;
  });

  return (
    <div>
      <div>
        <div className={styles.inputContainer}>
          <ReactPasswordStrength
            className={cls(
              styles.noBorder,
              styles.removeFocus,
              styles.passwordInput
            )}
            tooShortWord={""}
            minLength={0}
            minScore={0}
            scoreWords={["weak", "weak", "good", "good", "strong"]}
            changeCallback={(inputState: {
              score: 0 | 1 | 2 | 3 | 4;
              isValid: boolean;
              password: string;
            }) => {
              setPassword(inputState.password);
              onChange(inputState.password);
              setPassStrength(inputState.score);
            }}
            inputProps={{
              autoComplete: "off",
              type: showPassword ? "text" : "password"
            }}
          />
          <span
            onClick={e => {
              e.preventDefault();
              setShowPassword(!prevShowRef.current);
            }}
            className={cls(styles.eyeButton)}
          >
            {showPassword? <EyeTwoTone color="#81858B" /> : <EyeInvisibleOutlined color="#81858B" />}
          </span>
        </div>
      </div>
      <div className={cls({ [styles.hide]: !showMeter }, styles.strength)}>
        {password.length === 0 ? null : (
          <Progress
            strokeWidth={2}
            className={styles.progress}
            size="small"
            strokeColor="#EB5757"
            showInfo={false}
            percent={100}
          />
        )}
        {passStrength < 2 ? null : (
          <Progress
            strokeWidth={2}
            className={styles.progress}
            size="small"
            strokeColor="#FF9F00"
            showInfo={false}
            percent={100}
          />
        )}
        {passStrength < 4 ? null : (
          <Progress
            strokeWidth={2}
            className={styles.progress}
            size="small"
            strokeColor="#67DD2D"
            showInfo={false}
            percent={100}
          />
        )}
      </div>
    </div>
  );
};
