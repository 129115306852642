import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Dispatch } from "react";
import { Microservices } from "../../app/AllowedMicroservices";
import { AppThunk, RootState } from "../../app/store";
import { StatusEnum } from "../../components/common/Status";
import apiActionCreator, { HttpMethods } from "../../services/apiActionCreator";
import { MODAL_DELAY_MS } from "../../utils/constants";
import { FetchStatus, ModalStatus } from "../common/enums";
import { setMessageError, setMessageInfo } from "../message/messageSlice";

export const PreCreatedCompanies = ['1f0e04dd69db25021f593ad2', '1f0e04dd69db25021f593ad1'];
interface ICompanyType {
  name: string;
}

export interface IContactInfo {
  name: string;
  firstname: string;
  middlename: string;
  lastname: string;
  secondLastname: string;
  position: string;
  cellphone: string;
  email: string;
  address: string;
  city: string;
  country: string;
}

export interface INotificationTypesEnabled {
  web?: boolean;
  sms?: boolean;
  email?: boolean;
}
export interface ICompany {
  _id: string;
  name: string;
  ruc: string; // S3 url
  logo: string;
  maxActiveStudents: number;
  contractDate: Date;
  address: string;
  city: string;
  country: string;
  status: StatusEnum;
  notificationTypesEnabled: INotificationTypesEnabled;
  contactInfo: IContactInfo;
  supportEmail: string;
  studyProgram: string;
  deleted: boolean;
  deletedAt: Date;
  createdAt: Date;
  activeUsersCount: number;
  inactiveUsersCount?: number;
  companyType: ICompanyType | string | undefined;
  companyTypeName?: string;
  xOneDisabled?: boolean;
}

export interface ICompanyDict {
  [_id: string]: ICompany;
}

interface CompanyState {
  list: string[];
  dict: ICompanyDict;
  status: FetchStatus;
  modalStatus: ModalStatus;
}

const initialState: CompanyState = {
  list: [],
  dict: {},
  status: FetchStatus.Idle,
  modalStatus: ModalStatus.Hide,
};

interface CompanyFetch {
  docs: ICompany[];
  limit: number;
  page: number;
  pages: number;
  total: number;
}

interface CompanyUpdateStatusData {
  ids: string[];
}

export const companySlice = createSlice({
  name: "companies",
  initialState,
  reducers: {
    creating: (state) => {
      state.status = FetchStatus.Creating;
    },
    fetching: (state) => {
      state.status = FetchStatus.Fetching;
    },
    fetchedCompanies: (state, action: PayloadAction<CompanyFetch>) => {
      action.payload.docs.sort((companyA, companyB) => {
        const nameA = companyA.name;
        const nameB = companyB.name;
        if(nameA > nameB){
          return 1;
        }
        if(nameB > nameA){
          return -1;
        }
        return 0;
      });
      state.list = action.payload.docs.map((company) => company._id);
      state.dict = {};
      action.payload.docs.forEach((company) => {
        state.dict[company._id] = company;
      });
      state.status = FetchStatus.Fetched;
    },
    fetchedCompany: (state, action: PayloadAction<ICompany>) => {
      if (!state.list.includes(action.payload._id)) {
        state.list.push(action.payload._id);
      }
      state.dict[action.payload._id] = action.payload;
      state.status = FetchStatus.Fetched;
    },
    error: (state, _action: PayloadAction<CompanyFetch>) => {
      state.list = [];
      state.status = FetchStatus.Error;
    },
    showModal: (state) => {
      state.modalStatus = ModalStatus.Show;
    },
    hideModal: (state) => {
      state.modalStatus = ModalStatus.Hide;
    },
  },
});

const { showModal } = companySlice.actions;
export const {
  fetching,
  fetchedCompanies,
  fetchedCompany,
  error,
  creating,
  hideModal,
} = companySlice.actions;

export const createCompany = (newCompany: ICompany) => (
  dispatch: Dispatch<object>
) => {
  return dispatch(
    apiActionCreator({
      endpoint: "/companies",
      types: {
        requestType: creating,
        successTypes: [
          {
            actionOrCreator: fetchCompanies,
          },
          {
            actionOrCreator: showModal,
            selector: () => {
              dispatch(hideModalWithDelay());
            },
          },
        ],
        errorTypes: [
          {
            actionOrCreator: error,
          },
        ],
      },
      method: HttpMethods.POST,
      microservice: Microservices.COMPANY_SERVICE,
      authenticated: true,
      data: newCompany,
    })
  );
};

export const updateCompany = (company: ICompany) => (
  dispatch: Dispatch<object>
) => {
  return dispatch(
    apiActionCreator({
      endpoint: `/companies/${company._id}`,
      types: {
        requestType: creating,
        successTypes: [
          {
            actionOrCreator: fetchCompanies,
          },
          {
            actionOrCreator: showModal,
            selector: () => {
              dispatch(hideModalWithDelay());
            },
          },
        ],
        errorTypes: [
          {
            actionOrCreator: error,
          },
        ],
      },
      method: HttpMethods.PUT,
      microservice: Microservices.COMPANY_SERVICE,
      authenticated: true,
      data: company,
    })
  );
};

export const fetchCompanies = () => (dispatch: Dispatch<object>) => {
  return dispatch(
    apiActionCreator({
      endpoint: `/companies?limit=1000000`,
      types: {
        requestType: fetching,
        successTypes: [
          {
            actionOrCreator: fetchedCompanies,
          },
        ],
        errorTypes: [
          {
            actionOrCreator: error,
          },
        ],
      },
      method: HttpMethods.GET,
      microservice: Microservices.COMPANY_SERVICE,
      authenticated: true,
    })
  );
};

export const fetchCompany = (id: string) => (dispatch: Dispatch<object>) => {
  return dispatch(
    apiActionCreator({
      endpoint: `/companies/${id}`,
      types: {
        requestType: fetching,
        successTypes: [
          {
            actionOrCreator: fetchedCompany,
          },
        ],
        errorTypes: [
          {
            actionOrCreator: error,
          },
        ],
      },
      method: HttpMethods.GET,
      microservice: Microservices.COMPANY_SERVICE,
      authenticated: true,
    })
  );
};

export const enableCompaniesStatus = (companies: CompanyUpdateStatusData) => (
  dispatch: Dispatch<object>
) => {
  return dispatch(
    apiActionCreator({
      endpoint: "/companies/enable",
      types: {
        requestType: creating,
        successTypes: [
          {
            actionOrCreator: fetchCompanies,
          },
          {
            actionOrCreator: setMessageInfo({
              message: "Empresa(s) activada(s).",
            }),
          },
        ],
        errorTypes: [
          {
            actionOrCreator: error,
          },
          {
            actionOrCreator: fetchCompanies,
          },
          {
            actionOrCreator: setMessageError({
              message: "Falló la activación",
            }),
          },
        ],
      },
      method: HttpMethods.PUT,
      microservice: Microservices.COMPANY_SERVICE,
      authenticated: true,
      data: companies,
    })
  );
};

export const disableCompaniesStatus = (companies: CompanyUpdateStatusData) => (
  dispatch: Dispatch<object>
) => {
  return dispatch(
    apiActionCreator({
      endpoint: "/companies/disable",
      types: {
        requestType: creating,
        successTypes: [
          {
            actionOrCreator: fetchCompanies,
          },
          {
            actionOrCreator: setMessageInfo({
              message: "Empresa(s) desactivada(s)",
            }),
          },
        ],
        errorTypes: [
          {
            actionOrCreator: error,
          },
          {
            actionOrCreator: fetchCompanies,
          },
          {
            actionOrCreator: setMessageError({
              message: "Falló la desactivación",
            }),
          },
        ],
      },
      method: HttpMethods.PUT,
      microservice: Microservices.COMPANY_SERVICE,
      authenticated: true,
      data: companies,
    })
  );
};

const hideModalWithDelay = (): AppThunk => async (dispatch) => {
  return new Promise((resolve) => {
    setTimeout(() => {
      dispatch(hideModal());
      resolve();
    }, MODAL_DELAY_MS);
  });
};

export const selectCompaniesIds = (state: RootState) => state.companies.list;
export const selectCompanies = (state: RootState) => state.companies.dict;
export const selectCompaniesAreFetching = (state: RootState) =>
  state.companies.status === FetchStatus.Fetching;
export const selectCompany = (id: string | null) => (state: RootState) =>
  id ? state.companies.dict[id] : null;
export const selectDisplayModal = (state: RootState) =>
  state.companies.modalStatus === ModalStatus.Show;

export default companySlice.reducer;
