import React from 'react';
import { Select, Form } from 'antd';
import { useSelector } from 'react-redux';

import { IGroup } from '../../../features/groups/groupSlice';
import { IUser } from '../../../features/users/usersSlice';
import {
    selectCompanies,
    ICompany,
    selectCompaniesIds
} from '../../../features/company/companySlice';

interface CompanyFieldProps {
    newObject?: IGroup | IUser,
    value?: string | ICompany,
    setNewObject: Function
}

export const CompanyField: React.FunctionComponent<CompanyFieldProps> = (props) => {
    const { value, setNewObject, newObject } = props;
    const idsList = useSelector(selectCompaniesIds);
    const list = useSelector(selectCompanies);
    return <Form.Item
        initialValue={newObject?.company}
        name="company"
        wrapperCol={{ sm: 24 }}
        label="Empresa"
        rules={[{ required: true, message: 'Requerido' }]}
    >
        <Select
            placeholder="Asigna una empresa"
            value={value?.toString()}
            style={{ width: '100%' }}
            onChange={(value) => {
                setNewObject({ ...newObject, company: value })
            }}>
            {idsList.map(id => {
                return <Select.Option key={id} value={id}>{list[id].name}</Select.Option>
            })}
        </Select>
    </Form.Item>
};