import { Tooltip } from "antd";
import React from "react";
import { useSelector } from "react-redux";
import { getCognitoUserData } from "../../../../features/auth/authSlice";
import { selectCompanies } from "../../../../features/company/companySlice";
import { Stats } from "../../../../features/reports/company/userStats";
import { UserRole } from "../../../../features/users/usersSlice";
import { CogIcon } from "../../../common/CogIcon";
import LoadingOverlay from "../../../common/LoadingOverlay";
import { BarChartSimple } from "../../common/BarChartSimple";
import { TopFiveAward } from "../../common/TopFiveAward";
import { fillWithEmptyDataIfNeeded } from "../../common/utils";
import styles from "./index.module.scss";

const TOOLTIP_TEXT =
  "El top 5 es calculado en función de los usuarios activos vs los habilitados";

interface Props {
  topFive: Stats[];
  isLoading: boolean;
}

export const CompanyTop5: React.FunctionComponent<Props> = ({
  topFive,
  isLoading,
}) => {
  const companyDict = useSelector(selectCompanies);
  const cognitoData = useSelector(getCognitoUserData);
  const role = cognitoData ? cognitoData["custom:role"] : undefined;
  const userCompanyId = cognitoData ? cognitoData["custom:company"] : undefined;
  const isInTop5 =
    companyDict &&
    topFive.find((item) => item.companyId === userCompanyId) !== undefined;

  const data = topFive.map((item) => ({
    key: item.companyId,
    label: companyDict[item.companyId]?.name ?? "",
    value: item.avgActiveUserPercentage.toFixed(2),
  }));

  const companyData = fillWithEmptyDataIfNeeded(data, 5);

  return (
    <>
      {role === UserRole.SuperAdmin && (
        <>
          <div className={styles.title}>
            Top 5 de empresas con más usuarios activos{" "}
            <Tooltip
              placement="topLeft"
              title={TOOLTIP_TEXT}
              className={styles.tooltip}
            >
              <div>
                <CogIcon size="1.4em" color="#81858b" icon={"Attention"} />
              </div>
            </Tooltip>
          </div>
          {isLoading ? (
            <LoadingOverlay inline height={400} spinning />
          ) : (
            <div className={styles.chartContainer}>
              <BarChartSimple
                data={companyData}
                labelColumnTitle=" "
                fetching={isLoading}
                xMaxValue={100}
                xTickCallBack={(value: number) => (value + '%')}
                labelCallback={(tooltipItem: any, data: any) => {
                  const dataset = data.datasets[tooltipItem.datasetIndex];
                  return `${dataset.data[tooltipItem.index].x}%`;
                }}
              />
            </div>
          )}
        </>
      )}
      {role !== UserRole.SuperAdmin && (
        <>
          {isLoading ? (
            <LoadingOverlay inline height={400} spinning />
          ) : (
            <TopFiveAward
              isInTop5={isInTop5}
              tooltipText="El top 10 se calcula en base a los usuarios que ingresan a la plataforma"
            />
          )}
        </>
      )}
    </>
  );
};
