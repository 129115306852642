import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { analytics } from '../../services/analytics';

export const useAnalyticsPageTracking = (pathParam?: string ) => {
  const { pathname } = useLocation();
  useEffect(() => {
    if (pathParam) {
      analytics.pageview(pathParam);
    } else {
      analytics.pageview(pathname);
    }
  }, [pathname, pathParam]);
}
