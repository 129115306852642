import React, { FunctionComponent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import cx from 'classnames';

import { RootState } from '../../../../../app/store';
import {
  ICourseDict,
  selectAreCoursesFetching,
  selectCoursesDict,
} from '../../../../../features/courses/coursesSlice';
import { selectCompaniesGroups, selectGroupsDict } from '../../../../../features/groups/groupSlice';
import {
  ILessonDict,
  selectAreLessonsFetching,
  selectLessonsDict,
} from '../../../../../features/lesson/lessonSlice';
import {
  IReportDataset,
  CHART_COLORS,
} from '../../../../../features/reports/common';
import { selectCompaniesContentsAreFetching, selectGroupsContents } from '../../../../../features/reports/learning/common/companiesContent';
import { IFulfillment, LearningLabels, LearningType } from '../../../../../features/reports/learning/common/interfaces';
import {
  ITopicDict,
  selectAreTopicsFetching,
  selectTopics,
} from '../../../../../features/topic/topicSlice';
import {
  selectAreUsersFetching,
  selectGroupStudent,
  selectStudentIdPerGroup,
  selectStudents,
} from '../../../../../features/users/usersSlice';
import LoadingOverlay from '../../../../common/LoadingOverlay';

import { RoundedContainer } from '../../../../common/RoundedContainer';
import { StatusEnum } from '../../../../common/Status';
import { BarChartGrouped } from '../../../common/BarChartGrouped';
import { GroupSelector } from '../GroupSelector';
import { IGroupsChildren } from '../interfaces';

import styles from './index.module.scss';
import { CogIcon } from '../../../../common/CogIcon';
import { Tooltip } from 'antd';
interface FulfillmentChartProps {
  companyId?: string;
  fetchLearningContentCompletion: (groupId?: string | undefined) => void;
  fetchLearningContentStatus: (state: RootState) => { [id: string]: StatusEnum;};
  selectFulfillments: (state: RootState) => IFulfillment[];
  selectFulfillmentsFetching: (state: RootState) => boolean;
  learningType: LearningType;
}

export const FulfillmentChart: FunctionComponent<FulfillmentChartProps> = ({
  companyId,
  fetchLearningContentCompletion,
  fetchLearningContentStatus,
  selectFulfillments,
  selectFulfillmentsFetching,
  learningType,
}) => {
  const contentsAreFetching = useSelector(selectCompaniesContentsAreFetching);
  const [groupsChildren, setGroupsChildren] = useState<IGroupsChildren>({});
  const groups = useSelector(selectGroupsDict);
  const [groupId, setGroupId] = useState<string>();
  const [data, setData] = useState<IReportDataset[]>([]);
  // Users
  const students = useSelector(selectStudents);
  const studentIdPerGroup = useSelector(selectStudentIdPerGroup);
  const studentsFetching = useSelector(selectAreUsersFetching);
  const groupStudent = useSelector(selectGroupStudent);
  // Fulfillments/Content
  const isFulfillmentsFetching = useSelector(selectFulfillmentsFetching);
  const fulfillments = useSelector(selectFulfillments);
  const contentStatus = useSelector(fetchLearningContentStatus);
  // Lessons
  const lessonsStatusFetching = useSelector(selectAreLessonsFetching);
  // Courses
  const coursesStatusFetching = useSelector(selectAreCoursesFetching);
  // Topics
  const topicsStatusFetching = useSelector(selectAreTopicsFetching);
  const learningContentsStatusFetching = lessonsStatusFetching || coursesStatusFetching || topicsStatusFetching || contentsAreFetching;
  //Groups
  const companiesGroups = useSelector(selectCompaniesGroups);
  const groupsContents = useSelector(selectGroupsContents);
  //LearningContent
  const learningContents: {
    lessons: ILessonDict;
    topics: ITopicDict;
    courses: ICourseDict;
  } = {
    lessons: useSelector(selectLessonsDict),
    topics: useSelector(selectTopics),
    courses: useSelector(selectCoursesDict),
  };

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchLearningContentCompletion());
  }, [dispatch, fetchLearningContentCompletion]);
  const courses = learningContents.courses;
  
  const topics = learningContents.topics;
  useEffect(() => {
    if(learningContentsStatusFetching){
      return;
    }
    const groupsChildren: IGroupsChildren = {};
    const coursesForChart:string[] = [];
    if(companyId){
      if(companiesGroups[companyId]){
        Array.from(new Set(companiesGroups[companyId])).forEach(groupId => {
          const group = groups[groupId];
          if(!group){
            return;
          }
          coursesForChart.push(...group.courses);
        });
      }
    }else{
      coursesForChart.push(...Object.keys(courses));
    }
    Array.from(new Set(coursesForChart)).forEach((courseId) => {
      const groupsIds: string[] = Object.values(groups)
        .filter((group) => group.courses.includes(courseId))
        .map((group) => group._id);
      const course = courses[courseId];
      const topicsIds: string[] = course.topics;
      const lessonsIds: string[] = [];
      course.topics.forEach((topicId) => {
        const topic = topics[topicId];
        if(topic){
          lessonsIds.push(...topic.lessons);
        }
      });
      groupsIds.forEach((groupId) => {
        if (!groupsChildren[groupId]) {
          groupsChildren[groupId] = {
            courses: [],
            topics: [],
            lessons: [],
            students: 0,
          };
        }
        groupsChildren[groupId].lessons.push(...lessonsIds);
        groupsChildren[groupId].courses.push(courseId);
        groupsChildren[groupId].topics.push(...topicsIds);
      });
    });
    setGroupsChildren(groupsChildren);
  }, [
    courses,
    groups,
    topics,
    learningContentsStatusFetching,
    companyId,
    companiesGroups,
  ]);
  useEffect(() => {
    if (!studentsFetching) {
      Object.keys(groups).forEach((groupId) => {
        if (!groupsChildren[groupId]) {
          groupsChildren[groupId] = {
            courses: [],
            topics: [],
            lessons: [],
            students: 0,
          };
        }
        Object.keys(students).forEach((studentId) => {
          const student = students[studentId];
          if (student.studentGroupId === groupId) {
            ++groupsChildren[groupId].students;
          }
        });
      });
    }
  }, [studentsFetching, students, groups, groupsChildren]);
  const fetching = studentsFetching ||
    learningContentsStatusFetching ||
    isFulfillmentsFetching;
  useEffect(() => {
    const data: IReportDataset[] = [
      { label: 'Activo', data: [0, 0], color: CHART_COLORS[0] },
      { label: 'Inactivo', data: [0, 0], color: CHART_COLORS[1] },
    ];
    let completedEnabled = 0;
    let completedDisabled = 0;
    let pendingEnabled = 0;
    let pendingDisabled = 0;
    if (
      fetching
    ) {
      setData(data);
    }
    const foundContentCountPerGroup: {[id:string]: {
      [id: string]: { completed: number, pending: number },
    }} = {};
    fulfillments.forEach((fulfillment) => {
      const studentGroupId = groupStudent[fulfillment.username];
      if(!groupsChildren[studentGroupId]) {
        return;
      }
      if(!foundContentCountPerGroup[studentGroupId]) {
        foundContentCountPerGroup[studentGroupId] = {};
      }
      if(foundContentCountPerGroup[studentGroupId][fulfillment.id] === undefined) {
        foundContentCountPerGroup[studentGroupId][fulfillment.id] = { completed: 0, pending: 0 };
      }
      if (groupId) {
        const groupChildren = groupsChildren[groupId];
        if (!groupChildren || !groupChildren[learningType].includes(fulfillment.id)) {
          return;
        }
      }
      if(companyId) {
        const companyGroups = companiesGroups[companyId] ? companiesGroups[companyId] : [];
        if(!companyGroups.includes(studentGroupId)){
          return;
        }
      }
      if (contentStatus[fulfillment.id] === StatusEnum.Enable) {
        completedEnabled += fulfillment.completed;
        foundContentCountPerGroup[studentGroupId][fulfillment.id].completed += fulfillment.completed;
        foundContentCountPerGroup[studentGroupId][fulfillment.id].pending += fulfillment.inProgress + fulfillment.notStarted;
        return;
      }
      foundContentCountPerGroup[studentGroupId][fulfillment.id].completed += fulfillment.completed;
      completedDisabled += fulfillment.completed;
      foundContentCountPerGroup[studentGroupId][fulfillment.id].pending += fulfillment.inProgress + fulfillment.notStarted;
    });
    const processCompanyGroups = (companyGroups:string[]) => {
      Array.from(new Set(companyGroups)).forEach(companyGroupId=>{
        if (groupId && groupId !== companyGroupId) {
          return;
        }
        const foundContentCount = foundContentCountPerGroup[companyGroupId] ?? {};
        const contentIds = groupsContents[companyGroupId] ? groupsContents[companyGroupId][learningType] : null;
        if(!contentIds){
          return;
        }
        
        const studentsCountToAdd = groupsContents[companyGroupId].students;
        contentIds.forEach(contentId => {
          const count = foundContentCount[contentId] ?? {completed: 0, pending: 0};
          if (contentStatus[contentId] === StatusEnum.Enable) {
            pendingEnabled += studentsCountToAdd - count.completed;
            return;
          }
          pendingDisabled += studentsCountToAdd - count.completed;
          });
        })
    }
    if(companyId){
      processCompanyGroups(companiesGroups[companyId]);
    }else{
      Object.keys(companiesGroups).forEach(companyId => {
        const companyGroups = companiesGroups[companyId];
        processCompanyGroups(companyGroups);
      });
    }
    data[0].data = [completedEnabled, pendingEnabled];
    data[1].data = [completedDisabled, pendingDisabled];
    setData(data);
  }, [contentStatus, fetching, fulfillments, groupId, groupsChildren, learningType, groupStudent, studentIdPerGroup, companyId, companiesGroups, groupsContents]);
  const height = learningType === LearningType.lessons ? 400 : 324;
  const legend = <div className={styles.legend}>
    <div className={styles.item}>
      <div className={cx(styles.square, styles.blue)}/> Activo
    </div>
    <div className={styles.item}>
      <div className={cx(styles.square, styles.pink)}/> Inactivo&nbsp;&nbsp;<Tooltip
      title="Material educativo que no está vigente"
      className={styles.tooltip}
    >
      <span>
        <CogIcon size="1.4em" color="#81858b" icon={"Attention"} />
      </span>
    </Tooltip>
    </div>
  </div>;
  return (
    <RoundedContainer>
      <div className={styles.title}>Cumplimiento de {LearningLabels[learningType]} por grupo</div>
      <div className={styles.filterContainer}>
        <GroupSelector
          companyId={companyId}
          groupId={groupId}
          onChange={setGroupId}
        />
      </div>
      {fetching ? (
        <LoadingOverlay inline height={height} spinning />
      ) : (
        <>
          <BarChartGrouped
            height={height}
            datasets={data}
            labels={['Completa', 'Pendiente']}
            labelColumnTitle="my label"
            fetching={false}
            hideLegend
          />
          {legend}
        </>
      )}
    </RoundedContainer>
  );
};
