import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Dispatch } from "react";
import { Microservices } from "../../app/AllowedMicroservices";
import { RootState } from "../../app/store";
import apiActionCreator, { HttpMethods } from "../../services/apiActionCreator";
import { FetchStatus } from "../common/enums";

export interface IEvaluationsTitles {
  _id: string;
  title: string;
}
interface IEvaluationsTitlesDict {
  [_id: string]: string;
}

interface EvaluationsTitltesState {
  list: string[];
  dict: IEvaluationsTitlesDict;
  status: FetchStatus;
}

const initialState: EvaluationsTitltesState = {
  list: [],
  dict: {},
  status: FetchStatus.Idle,
};

export const categorySlice = createSlice({
  name: "evaluationsTitles",
  initialState,
  reducers: {
    fetching: (state) => {
      state.status = FetchStatus.Fetching;
    },
    fetched: (state, action: PayloadAction<IEvaluationsTitles[]>) => {
      state.list = action.payload.map((evaluationTitle) => evaluationTitle._id);
      state.dict = {};
      action.payload.forEach((evaluationTitle) => {
        state.dict[evaluationTitle._id] = evaluationTitle.title;
      });
      state.status = FetchStatus.Fetched;
    },
    error: (state) => {
      state.list = [];
      state.dict = {};
      state.status = FetchStatus.Error;
    },
  },
});

export const { fetching, fetched, error } = categorySlice.actions;

export const fetchEvaluationsTitles = (ids: string[]) => (
  dispatch: Dispatch<object>
) => {
  return dispatch(
    apiActionCreator({
      endpoint: "/evaluations/titles",
      types: {
        requestType: fetching,
        successTypes: [
          {
            actionOrCreator: fetched,
          },
        ],
        errorTypes: [
          {
            actionOrCreator: error,
          },
        ],
      },
      data: { ids },
      method: HttpMethods.POST,
      microservice: Microservices.EVALUATION_SERVICE,
      authenticated: true,
    })
  );
};

export const selectEvaluationTitle = (id: string) => (state: RootState) =>
  state.evaluationsTitles.dict[id];
export const selectIsEvaluationTitlesFetching = (state: RootState) =>
  state.evaluationsTitles.status === FetchStatus.Fetching;

export default categorySlice.reducer;
