import { Form } from "antd";
import { FormInstance } from "antd/lib/form";
import React, { useEffect, useState } from "react";
import { ICategoriesDict } from "../../features/category/categorySlice";
import { ILesson, MediaTypes } from "../../features/lesson/lessonSlice";
import formStyles from "../common/form.module.scss";
import { StepOne } from "./StepOne";
import { StepZero } from "./StepZero";

const STEPS = [StepZero, StepOne];

interface Props {
  form: FormInstance;
  step: number;
  fileType: MediaTypes;
  newLesson: ILesson;
  categories: ICategoriesDict;
  categoriesIds: string[];
  onBack(): void;
  setNewLesson: React.Dispatch<React.SetStateAction<ILesson>>;
  setFileType: React.Dispatch<React.SetStateAction<MediaTypes>>;
}

export const LessonForm: React.FunctionComponent<Props> = ({
  form,
  newLesson,
  step,
  fileType,
  categories,
  categoriesIds,
  setNewLesson,
  setFileType,
  onBack,
}) => {
  const [acceptedTypes, setAcceptedTypes] = useState<string>("");
  const Step = STEPS[step];

  useEffect(() => {
    if (fileType === MediaTypes.IMAGE) {
      setAcceptedTypes("image/jpeg,image/jpg,image/gif,image/png");
    }
    if (fileType === MediaTypes.PDF) {
      setAcceptedTypes("application/pdf");
    }
    if (fileType === MediaTypes.VIDEO) {
      setAcceptedTypes(
        "video/mp4,video/x-flv,video/quicktime,video/x-msvideo,video/x-ms-wmv"
      );
    }
  }, [fileType]);

  return (
    <Form form={form} className={formStyles.form} layout="vertical">
      <Step
        categories={categories}
        categoriesIds={categoriesIds}
        form={form}
        fileType={fileType}
        newLesson={newLesson}
        acceptedTypes={acceptedTypes}
        onBack={onBack}
        setNewLesson={setNewLesson}
        setFileType={setFileType}
      />
    </Form>
  );
};
