import React from "react";
import { Button } from 'antd';
import ctx from 'classnames';

import { IMedia, IVideoInfo, MediaTypes } from '../../features/lesson/lessonSlice';
import { CogIcon } from '../common/CogIcon';

import styles from "./form.module.scss";
import { VideoPreview } from './VideoPreview';

interface MediaPreviewProps {
    media: IMedia;
    onDelete(url: string): void;
    onUnlockVideo(videoInfo: IVideoInfo): void;
    isVideoLocked?: boolean;
  }
  
export const MediaPreview: React.FunctionComponent<MediaPreviewProps> = ({
    media,
    onDelete,
    onUnlockVideo
  }) => {
    const handleDelete = () => onDelete(media.url);
    if (media.type === MediaTypes.IMAGE) {
      return (
        <div
          style={{
            background: `url("${media.url}")`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat"
          }}
          className={styles.imageContainer}
        >
          <Button className={styles.deleteButton} onClick={handleDelete}>
            <CogIcon size="14px" icon="Delete" color="#FFF" />
          </Button>
        </div>
      );
    }
    if (media.type === MediaTypes.PDF) {
      return (
        <div className={styles.fileContainer}>
          <p>{media.filename}</p>
          <span>{media.size} kB</span>
          <Button
            className={styles.deleteButton}
            onClick={() => onDelete(media.url)}
          >
            <CogIcon size="14px" icon="Delete" color="#FFF" />
          </Button>
        </div>
      );
    }
    if (media.type === MediaTypes.VIDEO) {
      return (
        <div className={ctx(styles.fileContainer, styles.video)}>
          <p>{media.filename}</p>
          <span>{media.size} kB</span>
          <VideoPreview isReady={onUnlockVideo} videoUrl={media.url} />
          <Button
            className={styles.deleteButton}
            onClick={() => onDelete(media.url)}
          >
            <CogIcon size="14px" icon="Delete" color="#FFF" />
          </Button>
        </div>
      );
    }
    return <div>
      unsupported {media.type} media type
    </div>;
  };