import React from "react";
import { Button, ConfigProvider, DatePicker } from "antd";
import locale from "antd/lib/locale/es_ES";
import moment from "moment";
import { RangeValue } from "rc-picker/es/interface";

const { RangePicker } = DatePicker;

interface IWidthCreatedAt {
  createdAt?: Date | string;
}
export function getDateFilterAndSortProps<T extends IWidthCreatedAt>(
  dateFilterValues: RangeValue<moment.Moment> | null | undefined,
  setDateFilterValues: any
) {
  return {
    sorter: (a: T, b: T) => moment(a.createdAt).diff(moment(b.createdAt)),
    filterDropdown: () => {
      return (
        <div>
          <ConfigProvider locale={locale}>
            <RangePicker
              renderExtraFooter={() => (
                <div>
                  <Button>Este mes</Button>
                  <Button>Semana pasada</Button>
                  <Button>Hoy</Button>
                </div>
              )}
              onChange={val => {
                setDateFilterValues(val);
              }}
            />
          </ConfigProvider>
        </div>
      );
    },
    filteredValue:
      dateFilterValues && dateFilterValues.length > 0 ? ["a"] : undefined,
    onFilter: (value: any, record: T) => {
      const from = dateFilterValues?.[0];
      const to = dateFilterValues?.[1];
      if (!to || !from || !record.createdAt) {
        return true;
      }
      return (
        moment(record.createdAt).diff(from.startOf("day")) >= 0 &&
        moment(record.createdAt).diff(to.endOf("day")) <= 0
      );
    }
  };
}
