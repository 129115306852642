import cls from "classnames";
import React, { FunctionComponent } from "react";
import styles from "./sectionHeader.module.scss";

interface SectionHeaderProps {
  title: string;
  hideExceptOnPrint?: boolean;
}

export const SectionHeader: FunctionComponent<SectionHeaderProps> = ({
  title,
  hideExceptOnPrint,
}) => {
  return (
    <div
      className={cls(styles.wrapper, {
        [styles.hide]: hideExceptOnPrint,
      })}
    >
      <div className={styles.sectionTitle}>
        <div className={styles.title}>{title}</div>
      </div>
    </div>
  );
};
