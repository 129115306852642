import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Dispatch } from "react";
import { RootState } from "../../app/store";
import apiActionCreator from "../../services/apiActionCreator";
import { Microservices } from "./../../app/AllowedMicroservices";
import { NotificationsTypes } from "./../../components/Notifications/Manager";
import { HttpMethods } from "./../../services/apiActionCreator";
import { SendType } from "./../notifications/notificationsSlice";

export enum NotificationsScheduleStatusEnum {
  IDLE = "IDLE",
  fetcing = "FETCHING",
  fetched = "FETCHED",
  error = "ERROR",
}
export interface INotification {
  body: string;
  company: string;
  createdAt: string;
  read: boolean;
  sendingType: SendType;
  sent: boolean;
  title: string;
  to: string;
  type: NotificationsTypes;
  scheduledDateTime: string;
  updatedAt: string;
  username: string;
  _id: string;
}
interface MesageState {
  status: NotificationsScheduleStatusEnum;
  notifications: INotification[];
  ureadNotifications: INotification[];
}

const initialState: MesageState = {
  status: NotificationsScheduleStatusEnum.IDLE,
  notifications: [],
  ureadNotifications: [],
};

export const notificationsScheduleSlice = createSlice({
  name: "studentNotifications",
  initialState,
  reducers: {
    fetching: (state) => {
      state.status = NotificationsScheduleStatusEnum.fetcing;
    },
    fetched: (state, action: PayloadAction<INotification[]>) => {
      state.notifications = action.payload;
      state.ureadNotifications = action.payload.filter(
        (notification) => !notification.read
      );
      state.status = NotificationsScheduleStatusEnum.fetched;
    },
    error: (state) => {
      state.status = NotificationsScheduleStatusEnum.error;
    },
  },
});

export const { fetching, fetched, error } = notificationsScheduleSlice.actions;

export interface IToData {
  to: string; //phone or email
  username: string;
  companyId: string;
}

export const fetchStudentNotifications = () => (dispatch: Dispatch<object>) => {
  const endpoint = `/notifications/list`;
  return dispatch(
    apiActionCreator({
      endpoint,
      types: {
        requestType: fetching,
        successTypes: [
          {
            actionOrCreator: fetched,
          },
        ],
        errorTypes: [
          {
            actionOrCreator: error,
          },
        ],
      },
      method: HttpMethods.GET,
      microservice: Microservices.COMPANY_SERVICE,
      authenticated: true,
    })
  );
};

export const markAsReadStudentNotifications = (id: string) => (
  dispatch: Dispatch<object>
) => {
  const endpoint = `/notifications/read/${id}`;
  return dispatch(
    apiActionCreator({
      endpoint,
      types: {
        requestType: fetching,
        successTypes: [
          {
            actionOrCreator: fetched,
            selector: () => {
              dispatch(fetchStudentNotifications());
            },
          },
        ],
        errorTypes: [
          {
            actionOrCreator: error,
          },
        ],
      },
      method: HttpMethods.PUT,
      microservice: Microservices.COMPANY_SERVICE,
      authenticated: true,
    })
  );
};
export const selectStudentNotificationsIsFetching = (state: RootState) =>
  state.studentNotifications.status === NotificationsScheduleStatusEnum.fetcing;
export const selectStudentNotifications = (state: RootState) =>
  state.studentNotifications.notifications;
export const selectStudentNotification = (notificationId: string) => (
  state: RootState
) =>
  state.studentNotifications.notifications.find(
    (notification) => notification._id === notificationId
  );
export const selectStudentUreadNotifications = (state: RootState) =>
  state.studentNotifications.ureadNotifications;
export default notificationsScheduleSlice.reducer;
