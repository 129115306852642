import React, { FunctionComponent, useEffect } from 'react';
import ctx from 'classnames';
import { useDispatch, useSelector } from 'react-redux';

import {
    fetchTeachers,
} from '../../../features/teacher/teacherSlice';

import styles from './topics.module.scss';
import { selectEvaluationsIds, selectStudentCourse, selectStudentCourseTopicIds } from '../../../features/students/courseSlice';
import { StudentLessonTopic } from './Topic';
import { StudentLessonCourseExam } from './CourseExam';
import { fetchEvaluationsTitles } from '../../../features/students/evaluationsTitlesSlice';

interface LessonTopicsProp {
    className?: string,
    currentTopicId?: string,
    currentLessonId?: string,
    isExam: boolean,
    isTest: boolean,
}

export const StudentLessonTopics: FunctionComponent<LessonTopicsProp> = ({ className, currentTopicId, currentLessonId, isExam = false, isTest = false }) => {
    const topicIds = useSelector(selectStudentCourseTopicIds);
    const course = useSelector(selectStudentCourse);
    const evaluationsIds = useSelector(selectEvaluationsIds);
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(fetchTeachers())
    }, [dispatch]);
    useEffect(() => {
        dispatch(fetchEvaluationsTitles(evaluationsIds));
    }, [dispatch, evaluationsIds]);
    return <div className={ctx(styles.container, className)}>
        {topicIds.map(topicId=>{
            return <StudentLessonTopic isTest={isTest} currentTopicId={currentTopicId} currentLessonId={currentLessonId} topicId={topicId} key={topicId}/> 
        })}
        {course?.evaluation ?
            <StudentLessonCourseExam id={course?.evaluation} selected={isExam} />
            : null
        }
    </div>;
};