import React, { FunctionComponent } from 'react';
import cls from 'classnames';
import {
  Row,
  Col,
  Carousel
} from 'antd';

import './LoginOverrides.scss';
import './containerWithCarousel.scss';
import loginStyles from './Login.module.scss';
import image1 from '../../assets/login/shutterstock_1_desktop.jpg';
import image1Mobile from '../../assets/login/shutterstock_1_mobile.jpg';
import image2 from '../../assets/login/shutterstock_2_desktop.jpg';
import image2Mobile from '../../assets/login/shutterstock_2_mobile.jpg';
import image3 from '../../assets/login/shutterstock_3_desktop.jpg';
import image3Mobile from '../../assets/login/shutterstock_3_mobile.jpg';
import image4 from '../../assets/login/shutterstock_4_desktop.jpg';
import image4Mobile from '../../assets/login/shutterstock_4_mobile.jpg';
import { useDeviceSize } from '../utils/useDeviceSize';
import { SIZES } from '../common/screen';

interface CarouselImage {
  thumbnail: string;
  mobile: string;
  alt: string;
}

interface CarouselItem {
  image: CarouselImage;
  title: string;
  description: string;
}

const CAROUSEL_ITEMS: CarouselItem[] = [
  {
    image: {
      thumbnail: image1,
      mobile: image1Mobile,
      alt: "First Slide",
    },
    title: "Teurona",
    description: "Ayudamos a crear valores y a generar conciencia en tu ambiente laboral.",
  },
  {
    image: {
      thumbnail: image2,
      mobile: image2Mobile,
      alt: "Second Slide",
    },
    title: "Teurona",
    description: "Fomentamos el trabajo en equipos grandes y pequeños.",
  },
  {
    image: {
      thumbnail: image3,
      mobile: image3Mobile,
      alt: "Third Slide",
    },
    title: "Teurona",
    description: "Creamos conexiones sólidas entre la empresa y sus empleados.",
  },
  {
    image: {
      thumbnail: image4,
      mobile: image4Mobile,
      alt: "Third Slide",
    },
    title: "Teurona",
    description: "Potenciamos y optimizamos tus labores desde su núcleo.",
  },
];
interface FooterProps {
}

export const ContainerWithCarousel: FunctionComponent<FooterProps> = ({ children }) => {
  const size = useDeviceSize();
  return (
    <React.Fragment>
      <Row className={loginStyles.row1} justify="center">
        <Col className={loginStyles.col1}>
          <Row className={cls(loginStyles.roundedBorder, loginStyles.row2, { [loginStyles.noMobile]: size !== SIZES.PHONE })}>
            <Col xs={24}  sm={24} lg={12}>
              <Carousel autoplaySpeed={3000} speed={700}  autoplay={true} >
                {
                  CAROUSEL_ITEMS.map((item, index) => (
                    <div key={index} className={cls(loginStyles.carouselItem)}>
                      <div className={cls(loginStyles.container)}
                        style={{ backgroundImage: (size === SIZES.PHONE || size === SIZES.TABLET) ? `url('${item.image.mobile}')` : `url('${item.image.thumbnail}')` }}
                      >
                        <div className={loginStyles.caption}>
                          <h3 className={loginStyles.title}>{item.title}</h3>
                          <p>{item.description}</p>
                        </div>
                      </div>
                    </div>
                  ))
                }
              </Carousel>
            </Col>
            <Col xs={24} sm={24} lg={12}>
              {children}
            </Col>
          </Row>
        </Col>
      </Row>
    </React.Fragment>
  );
};

