import { Button, Card, Collapse, Space } from "antd";
import cls from "classnames";
import React, { FunctionComponent, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  getCognitoUserData,
  logoutFromCognito,
} from "../../features/auth/authSlice";
import {
  fetchCompany,
  ICompany,
  selectCompany,
} from "../../features/company/companySlice";
import {
  fetchStats,
  selectStats,
} from "../../features/loggedUser/loggedUserSlice";
import {
  fetchCertificatesList,
  selectCertificateList,
  selectIsCertificateListFetching,
} from "../../features/students/certificateListSlice";
import { changeToStudent } from "../../features/userMode/UserMode";
import {
  fetchUser,
  IUser,
  selectUser,
  UsersGroups,
} from "../../features/users/usersSlice";
import { PasswordChangeModal } from "../Authentication/PasswordChangeModal";
import { CogIcon } from "../common/CogIcon";
import { ProfileLayout } from "../common/Layouts/ProfileLyt";
import LoadingOverlay from "../common/LoadingOverlay";
import { SIZES } from "../common/screen";
import { Headlines, ProfileDetailsColumn } from "../Profile/UserDetailsColumn";
import { goToCertificate } from "../Students/Course/Congratulations";
import { useDeviceSize } from "../utils/useDeviceSize";
import styles from "./loggedUserProfile.module.scss";

const renderLeftColumn = (user: IUser, company: ICompany) => {
  return (
    <ProfileDetailsColumn
      headlinesType={Headlines.Self}
      hideCreationDate
      user={user}
      company={company}
    />
  );
};

interface StatProps {
  iconName: string;
  description: string;
  count: number;
}
const Stat: React.FC<StatProps> = ({ iconName, description, count }) => {
  const numberWithLeadingZeros = count.toString().padStart(2, "0");
  return (
    <React.Fragment>
      <div className={styles.stats}>
        <div className={styles.topWrapper}>
          <div className={styles.count}>{numberWithLeadingZeros}</div>
          <div className={styles.iconWrapper}>
            <CogIcon size="0.8em" color="#4f4f4f" icon={iconName} />
          </div>
        </div>
        <div className={styles.botomWrapper}>{description}</div>
      </div>
    </React.Fragment>
  );
};

interface ProgressCardProps {
  user: IUser;
}
const ProgressCard: React.FC<ProgressCardProps> = ({ user }) => {
  const { groups = [] } = user;
  const dispatch = useDispatch();
  const history = useHistory();
  useEffect(() => {
    dispatch(fetchStats());
  }, [dispatch]);

  const stats = useSelector(selectStats);
  if (!groups.includes(UsersGroups.Students)) {
    return null;
  }
  return (
    <React.Fragment>
      <Card className={cls(styles.progressCard, styles.Card)}>
        <div className={styles.achievements}>
          <div className={styles.title}>Logros</div>
          <div className={styles.content}>
            <Stat
              count={stats.finishedCourses}
              description="Cursos realizados"
              iconName="Check"
            />
            <Stat
              count={stats.pendingCourses}
              description="Cursos pendientes"
              iconName="clock"
            />
            <Stat
              count={stats.certificates}
              description="Certificados obtenidos"
              iconName="Copy"
            />
          </div>
        </div>
        {stats.pendingCourses > 0 && (
          <div className={styles.coursesInfo}>
            <div className={styles.headline}>
              {`${user.given_name}, aún tienes cursos pendientes`}
            </div>
            <div className={styles.description}>
              Haz clic en el botón para ir a tus cursos pendientes y
              completarlos
            </div>
            <div className={styles.ctaWrapper}>
              <Button
                type="primary"
                onClick={() => {
                  dispatch(changeToStudent());
                  history.push("/my-courses");
                }}
              >
                Ir a mis cursos
              </Button>
            </div>
          </div>
        )}
      </Card>
    </React.Fragment>
  );
};

interface CertificationProps {
  user: IUser;
}
const Certifications: React.FC<CertificationProps> = ({ user }) => {
  const { groups = [] } = user;
  const dispatch = useDispatch();
  const fetching = useSelector(selectIsCertificateListFetching);
  const list = useSelector(selectCertificateList);
  useEffect(() => {
    dispatch(fetchCertificatesList());
  }, [dispatch]);
  if (!groups.includes(UsersGroups.Students)) {
    return null;
  }
  if (fetching) {
    return <LoadingOverlay inline spinning height={100} />;
  }
  return (
    <Collapse
      defaultActiveKey={["1"]}
      onChange={() => {}}
      accordion
      className={cls(styles.certifications, styles.element)}
    >
      <Collapse.Panel
        className={styles.certificateList}
        header="Certificados Obtenidos"
        key="1"
      >
        {list.map((item) => {
          return (
            <div className={styles.certificate} key={item._id}>
              <div className={styles.courseName}>{item.courseName}</div>
              <div className={styles.gotoLink}>
                <Button
                  className={styles.viewButton}
                  type="link"
                  onClick={goToCertificate(item.hash)}
                >
                  Ver
                </Button>
              </div>
            </div>
          );
        })}
        {list.length === 0 && (
          <div className={styles.description}>
            En este momento no tienes certificados disponibles.
          </div>
        )}
      </Collapse.Panel>
    </Collapse>
  );
};

interface SecurityProps {
  company: ICompany;
}
const Security: React.FC<SecurityProps> = ({ company }) => {
  const [isPasswordChangeOpen, setIsPasswordChangeOpen] =
    useState<boolean>(false);
  const onChangePassword = () => {
    setIsPasswordChangeOpen(true);
  };
  const onCloseChangeModal = () => {
    setIsPasswordChangeOpen(false);
  };
  const dispatch = useDispatch();
  return (
    <React.Fragment>
      <PasswordChangeModal
        visible={isPasswordChangeOpen}
        onClose={() => {
          onCloseChangeModal();
        }}
        onChanged={() => {
          onCloseChangeModal();
          dispatch(logoutFromCognito());
        }}
      />
      <Space direction="vertical" size="middle">
        <Card className={cls(styles.securityCard, styles.Card)}>
          <div className={styles.mainWrapper}>
            <div className={styles.title}>Seguridad</div>
            <div className={styles.description}>
              Al momento de cambiar tu contraseña deberas iniciar sesión
              nuevamente
            </div>
            <div className={styles.ctaWrapper}>
              <Button
                type="default"
                style={{ paddingRight: "0", paddingLeft: "0" }}
                onClick={() => {
                  onChangePassword();
                }}
              >
                Cambiar contraseña
              </Button>
            </div>
          </div>
        </Card>
        {company?.contactInfo && (
          <Card className={cls(styles.infoCard, styles.Card)}>
            <span className={styles.mainWrapper}>
              <div className={styles.ctaWrapper}>
                <CogIcon size="1.8em" color="#4376f9" icon="Attention" />
              </div>
              <div className={styles.description}>
                Para cambiar tus datos de perfil debes contactarte con el
                administrador a través de
                <span className={styles.email}>
                  &nbsp;{company.contactInfo.email}
                </span>
              </div>
            </span>
          </Card>
        )}
      </Space>
    </React.Fragment>
  );
};

const renderRightColumn = (user: IUser, company: ICompany) => {
  return (
    <React.Fragment>
      <Space direction="vertical" size="large">
        <ProgressCard user={user} />
        <Certifications user={user} />
        <Security company={company} />
      </Space>
    </React.Fragment>
  );
};

export const LoggedUserProfile: FunctionComponent = () => {
  const [currentUser, setCurrentUser] = useState<IUser>();
  const [company, setCompany] = useState<ICompany>();
  const dispatch = useDispatch();
  const cognitoUserData = useSelector(getCognitoUserData);
  const size = useDeviceSize();

  const cognitoUsername = cognitoUserData
    ? cognitoUserData["cognito:username"]
    : null;
  const userFromStore = useSelector(selectUser(cognitoUsername));

  useEffect(() => {
    if (userFromStore) {
      setCurrentUser(userFromStore);
      dispatch(fetchCompany(userFromStore?.company ?? ""));
    }
  }, [userFromStore, dispatch]);

  const companyFromStore = useSelector(
    selectCompany(userFromStore?.company ?? "")
  );
  useEffect(() => {
    if (companyFromStore) {
      setCompany(companyFromStore);
    }
  }, [companyFromStore]);

  useEffect(() => {
    dispatch(fetchUser(cognitoUsername));
  }, [dispatch, cognitoUsername]);

  const isMobile = size === SIZES.PHONE || size === SIZES.TABLET;

  const leftColumn = renderLeftColumn(
    Object.assign({}, currentUser),
    Object.assign({}, company)
  );

  const rightColumn = renderRightColumn(
    Object.assign({}, currentUser),
    Object.assign({}, company)
  );

  return (
    <ProfileLayout
      rightTopOptions={null}
      image={currentUser?.picture}
      leftBottomOptions={isMobile ? rightColumn : leftColumn}
      rightBottomOptions={isMobile ? leftColumn : rightColumn}
    ></ProfileLayout>
  );
};
