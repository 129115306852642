import ReactGA from 'react-ga4';
import { ICourse } from '../../features/courses/coursesSlice';
import { GA_MEASUREMENT_ID } from '../../app/config';
import { ILesson } from '../../features/lesson/lessonSlice';
import { ITopic, ITopicEmpty } from '../../features/topic/topicSlice';
import { IProgram } from '../../features/program/programSlice';
import { ICompany } from '../../features/company/companySlice';
import { IGroup } from '../../features/groups/groupSlice';
import { IEvaluation, IAnswer } from "../../features/evaluation/common/interfaces";
import { ICampaign } from '../../features/campaign/campaignSlice';

import { ICategory } from '../../features/category/categorySlice';
import { IContent } from '../../features/students/knowledgeBaseSlice';

const CUSTOM_DIMENSION = {
  LESSON_NAME: 'dimension1',
  TOPIC_NAME: 'dimension2',
  COURSE_NAME: 'dimension3',
  PROGRAM_NAME: 'dimension4',
  COMPANY_NAME: 'dimension5',
  GROUP_NAME: 'dimension6',
  USER_ID: 'dimension7',
  ANSWERS: 'dimension8',
  EXAM_NAME: 'dimension9',
  QUIZ_NAME: 'dimension10',
  TEST_NAME: 'dimension11',
  KNOWLEDGE_BASE_CATEGORY_NAME: 'dimension12',
  KNOWLEDGE_BASE_NAME: 'dimension13',
  REPORT_INDICATOR: 'dimension14',
  REPORT_FILTER: 'dimension15',
}
const ACTIONS = {
  SELECT_COURSE: 'select_course',
  SELECT_TOPIC: 'select_theme', // theme term given by airtable events doc
  SELECT_LESSON: 'select_lesson',
  SELECT_TEST: 'select_test',
  SELECT_EXAM: 'select_exam',
  SELECT_SURVEY: 'select_poll', // select_poll name given by airtable doc
  COMPLETE_LESSON: 'complete_lesson',
  COMPLETE_TOPIC: 'complete_theme', // complete_theme name given by airtable doc
  COMPLETE_COURSE: 'complete_course',
  COMPLETE_EXAM: 'complete_exam',
  COMPLETE_SURVEY: 'complete_poll', //poll term given by airtable events doc
  COMPLETE_QUIZ: 'complete_quiz',
  COMPLETE_TEST: 'complete_test',
  DOWNLOAD_CERTIFICATE: 'download_certificate',
  LOG_IN: 'log_in',
  LOG_OUT: 'log_out',
  SIGN_UP: 'sign_up',
  COMPLETE_PROMOBANNER: 'complete_promobanner',
  VIEW_PROMOBANNER: 'impression_promobanner',
  SELECT_PROMOBANNER: 'select_promobanner',
  CREATE_STUDENT: 'create_student',
  CREATE_GROUP: 'create_group',
  CREATE_KNOWLEDGEBASE: 'create_knowledgebase',
  CREATE_PROMOBANNER: 'create_promobanner',
  NOTIFICATION_DISMISS: 'notification_dismiss',
  NOTIFICATION_RECEIVE: 'notification_receive',
  NOTIFICATION_OPEN: 'notification_open',
  SELECT_KNOWLEDGE_BASE: 'select_knowledgebase',
  SELECT_KNOWLEDGE_BASE_CATEGORY: 'select_knowledgebasecategory',
  SELECT_KNOWLEDGE_BASE_REGISTRY: 'select_knowledgebaseregistry',
  VIEW_REPORT: 'impression_report', // impression_report name given by airtable doc
}

const CATEGORY = {
  COURSE: 'course',
  LOGGING: 'loggin',
  OTHER: 'other',
  ADMIN: 'admin',
}
const gaKey = (GA_MEASUREMENT_ID || '').split(',')[0] ?? '';

const NA_TEXT = 'n/a';
const DEFAULT_TRACKER_NAME = 'default';

const setDimensionInDefaultTracker = (key: string, value: unknown) => {
  ReactGA.gtag('config', gaKey, { [`${key}`]: value });
}
let userId = 'NOT_DEFINED';

let gtagLoaded = false;

function gtag(){
  // @ts-ignore
  dataLayer.push(arguments);
}

const loadGtag = () => {
  if (gtagLoaded) {
    return;
  }
  (function(w:any,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
  new Date().getTime(),event:'gtm.js'});const f=d.getElementsByTagName(s)[0],
  j:any=d.createElement(s),dl=l!=='dataLayer'?'&l='+l:'';j.async=true;j.src=
  'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode?.insertBefore(j,f);
  })(window,document,'script','dataLayer', gaKey);
  gtagLoaded = true;
};
loadGtag();

const initialize = (userIdToInitialize: string, companyId: string) => {
  userId = userIdToInitialize;
  console.log("🚀 ~ file: index.ts:89 ~ initialize ~ userId:", userId)
  console.log('keys', gaKey);

  loadGtag();
  // @ts-ignore
  window.dataLayer.push({ user_id: userId, [CUSTOM_DIMENSION.USER_ID]: userId, [CUSTOM_DIMENSION.COMPANY_NAME]: companyId });
  // @ts-ignore
  gtag('config', gaKey, {
    user_id: userId,
    [CUSTOM_DIMENSION.USER_ID]: userId,
    [CUSTOM_DIMENSION.COMPANY_NAME]: companyId,
  });
}

const pageview = (currentPath: string) => {
  console.log("🚀 ~ file: index.ts:120 ~ pageview ~ currentPath:", currentPath)
  ReactGA.gtag('event', "page_view", {
    'page_location': currentPath,
  });
}

interface CompanyDimSetterProps {
  company: ICompany | null,
}

const setCompanyCustomDimension =
  ({
    company,
  }: CompanyDimSetterProps
  ) => {
    const { name: companyName } = company ?? { name: NA_TEXT };
    setDimensionInDefaultTracker(CUSTOM_DIMENSION.COMPANY_NAME, companyName);
  }

interface CompleteLessonSetterProps {
  lessonTitle: string,
  topicTitle: string,
  courseTitle: string,
  programTitle: string,
  companyName: string,
  groupName: string,
}

const setCompleteLessonCustomDimemsions =
  ({
    lessonTitle,
    topicTitle,
    courseTitle,
    programTitle,
    companyName,
    groupName,
  }: CompleteLessonSetterProps
  ) => {
    setDimensionInDefaultTracker(CUSTOM_DIMENSION.LESSON_NAME, lessonTitle);
    setDimensionInDefaultTracker(CUSTOM_DIMENSION.TOPIC_NAME, topicTitle);
    setDimensionInDefaultTracker(CUSTOM_DIMENSION.COURSE_NAME, courseTitle);
    setDimensionInDefaultTracker(CUSTOM_DIMENSION.PROGRAM_NAME, programTitle);
    setDimensionInDefaultTracker(CUSTOM_DIMENSION.COMPANY_NAME, companyName);
    setDimensionInDefaultTracker(CUSTOM_DIMENSION.GROUP_NAME, groupName);
  }
interface CompleteLessonGAProps {
  lesson: ILesson,
  topic: ITopic | null,
  course: ICourse | null,
  program: IProgram | null,
  company: ICompany | null,
  group: IGroup | null,
}

const completeLesson = ({ lesson, topic, course, program, company, group }: CompleteLessonGAProps) => {
  const { title: lessonTitle } = lesson;
  const { title: topicTitle } = topic ?? { title: NA_TEXT };
  const { title: courseTitle } = course ?? { title: NA_TEXT };
  const { title: programTitle } = program ?? { title: NA_TEXT };
  const { name: companyName } = company ?? { name: NA_TEXT };
  const { name: groupName } = group ?? { name: NA_TEXT };

  setCompleteLessonCustomDimemsions({
    lessonTitle: lessonTitle,
    topicTitle: topicTitle,
    courseTitle: courseTitle,
    programTitle: programTitle,
    companyName: companyName,
    groupName: groupName,
  });

  ReactGA.event({
    category: CATEGORY.COURSE,
    action: ACTIONS.COMPLETE_LESSON,
    label: `${lessonTitle}`,
  }, [DEFAULT_TRACKER_NAME]);
}

interface CompleteTopicSetterProps {
  topicTitle: string,
  courseTitle: string,
  programTitle: string,
  companyName: string,
  groupName: string,
}

const setCompleteTopicCustomDimensions =
  ({
    topicTitle,
    courseTitle,
    programTitle,
    companyName,
    groupName,
  }: CompleteTopicSetterProps
  ) => {
    setDimensionInDefaultTracker(CUSTOM_DIMENSION.TOPIC_NAME, topicTitle);
    setDimensionInDefaultTracker(CUSTOM_DIMENSION.COURSE_NAME, courseTitle);
    setDimensionInDefaultTracker(CUSTOM_DIMENSION.PROGRAM_NAME, programTitle);
    setDimensionInDefaultTracker(CUSTOM_DIMENSION.COMPANY_NAME, companyName);
    setDimensionInDefaultTracker(CUSTOM_DIMENSION.GROUP_NAME, groupName);
  }

interface CompleteTopicGAProps {
  topic: ITopic | null,
  course: ICourse | null,
  program: IProgram | null,
  company: ICompany | null,
  group: IGroup | null,
}

const completeTopic = ({ topic, course, program, company, group }: CompleteTopicGAProps) => {
  const { title: topicTitle } = topic ?? { title: NA_TEXT };
  const { title: courseTitle } = course ?? { title: NA_TEXT };
  const { title: programTitle } = program ?? { title: NA_TEXT };
  const { name: companyName } = company ?? { name: NA_TEXT };
  const { name: groupName } = group ?? { name: NA_TEXT };

  setCompleteTopicCustomDimensions({
    topicTitle: topicTitle,
    courseTitle: courseTitle,
    programTitle: programTitle,
    companyName: companyName,
    groupName: groupName,
  });

  ReactGA.event({
    category: CATEGORY.COURSE,
    action: ACTIONS.COMPLETE_TOPIC,
    label: `${topicTitle}`,
  }, [DEFAULT_TRACKER_NAME]);
}

interface CompleteCourseSetterProps {
  courseTitle: string,
  programTitle: string,
  companyName: string,
  groupName: string,
}

const setCompleteCourseCustomDimensions =
  ({
    courseTitle,
    programTitle,
    companyName,
    groupName,
  }: CompleteCourseSetterProps
  ) => {
    setDimensionInDefaultTracker(CUSTOM_DIMENSION.COURSE_NAME, courseTitle);
    setDimensionInDefaultTracker(CUSTOM_DIMENSION.PROGRAM_NAME, programTitle);
    setDimensionInDefaultTracker(CUSTOM_DIMENSION.COMPANY_NAME, companyName);
    setDimensionInDefaultTracker(CUSTOM_DIMENSION.GROUP_NAME, groupName);
  }

interface CompleteCourseGAProps {
  course: ICourse | null,
  program: IProgram | null,
  company: ICompany | null,
  group: IGroup | null,
}
const completeCourse = ({ course, program, company, group }: CompleteCourseGAProps) => {
  const { title: courseTitle } = course ?? { title: NA_TEXT };
  const { title: programTitle } = program ?? { title: NA_TEXT };
  const { name: companyName } = company ?? { name: NA_TEXT };
  const { name: groupName } = group ?? { name: NA_TEXT };

  setCompleteCourseCustomDimensions({
    courseTitle: courseTitle,
    programTitle: programTitle,
    companyName: companyName,
    groupName: groupName,
  });

  ReactGA.event({
    category: CATEGORY.COURSE,
    action: ACTIONS.COMPLETE_COURSE,
    label: `${courseTitle}`,
  }, [DEFAULT_TRACKER_NAME]);
}

interface CompleteExamSetterProps {
  examTitle: string,
  courseTitle: string,
  programTitle: string,
  companyName: string,
  groupName: string,
  answers: string
}

const setCompleteExamCustomDimensions =
  ({
    examTitle,
    courseTitle,
    programTitle,
    companyName,
    groupName,
    answers,
  }: CompleteExamSetterProps
  ) => {
    setDimensionInDefaultTracker(CUSTOM_DIMENSION.EXAM_NAME, examTitle);
    setDimensionInDefaultTracker(CUSTOM_DIMENSION.COURSE_NAME, courseTitle);
    setDimensionInDefaultTracker(CUSTOM_DIMENSION.PROGRAM_NAME, programTitle);
    setDimensionInDefaultTracker(CUSTOM_DIMENSION.COMPANY_NAME, companyName);
    setDimensionInDefaultTracker(CUSTOM_DIMENSION.GROUP_NAME, groupName);
    setDimensionInDefaultTracker(CUSTOM_DIMENSION.ANSWERS, answers);
  }

interface CompleteExamGAProps {
  exam: IEvaluation | null,
  course: ICourse | null,
  program: IProgram | null,
  company: ICompany | null,
  group: IGroup | null,
  answers: IAnswer[],
}
const completeExam = ({ exam, course, program, company, group, answers }: CompleteExamGAProps) => {
  const { title: courseTitle } = course ?? { title: NA_TEXT };
  const { title: programTitle } = program ?? { title: NA_TEXT };
  const { name: companyName } = company ?? { name: NA_TEXT };
  const { name: groupName } = group ?? { name: NA_TEXT };
  const answersStrArray = answers.map(answer => '' + answer.answerId + answer.questionId);
  const answersStr = answersStrArray.join(';');
  const examTitle = exam?.title ?? NA_TEXT;

  setCompleteExamCustomDimensions({
    examTitle: examTitle,
    courseTitle: courseTitle,
    programTitle: programTitle,
    companyName: companyName,
    groupName: groupName,
    answers: answersStr,
  });

  ReactGA.event({
    category: CATEGORY.COURSE,
    action: ACTIONS.COMPLETE_EXAM,
    label: `${examTitle}`,
  }, [DEFAULT_TRACKER_NAME]);
}

interface CompleteSurveySetterProps {
  courseTitle: string,
  programTitle: string,
  companyName: string,
  groupName: string,
  answers: string,
}

const setCompleteSurveyCustomDimensions =
  ({
    courseTitle,
    programTitle,
    companyName,
    groupName,
    answers,
  }: CompleteSurveySetterProps
  ) => {
    setDimensionInDefaultTracker(CUSTOM_DIMENSION.COURSE_NAME, courseTitle);
    setDimensionInDefaultTracker(CUSTOM_DIMENSION.PROGRAM_NAME, programTitle);
    setDimensionInDefaultTracker(CUSTOM_DIMENSION.COMPANY_NAME, companyName);
    setDimensionInDefaultTracker(CUSTOM_DIMENSION.GROUP_NAME, groupName);
    setDimensionInDefaultTracker(CUSTOM_DIMENSION.ANSWERS, answers);
  }

interface CompleteSurveyGAProps {
  survey: IEvaluation | null,
  course: ICourse | null,
  program: IProgram | null,
  company: ICompany | null,
  group: IGroup | null,
  answers: IAnswer[],
}

const completeSurvey = ({ survey, course, program, company, group, answers }: CompleteSurveyGAProps) => {
  const { title: courseTitle } = course ?? { title: NA_TEXT };
  const { title: programTitle } = program ?? { title: NA_TEXT };
  const { name: companyName } = company ?? { name: NA_TEXT };
  const { name: groupName } = group ?? { name: NA_TEXT };
  const answersStrArray = answers.map(answer => '' + answer.answerId + answer.questionId);
  const answersStr = answersStrArray.join(';');
  const { title: surveyTitle } = survey ?? { title: NA_TEXT };

  setCompleteSurveyCustomDimensions({
    courseTitle: courseTitle,
    programTitle: programTitle,
    companyName: companyName,
    groupName: groupName,
    answers: answersStr,
  });

  ReactGA.event({
    category: CATEGORY.COURSE,
    action: ACTIONS.COMPLETE_SURVEY,
    label: `${surveyTitle}`,
  }, [DEFAULT_TRACKER_NAME]);
}

interface CompleteQuizSetterProps {
  courseTitle: string,
  quizName: string,
  lessonName: string,
  topicName: string,
  programTitle: string,
  companyName: string,
  groupName: string,
  answers: string,
}

const setCompleteQuizCustomDimensions =
  ({
    quizName,
    courseTitle,
    lessonName,
    topicName,
    programTitle,
    companyName,
    groupName,
    answers,
  }: CompleteQuizSetterProps
  ) => {
    setDimensionInDefaultTracker(CUSTOM_DIMENSION.QUIZ_NAME, quizName);
    setDimensionInDefaultTracker(CUSTOM_DIMENSION.LESSON_NAME, lessonName);
    setDimensionInDefaultTracker(CUSTOM_DIMENSION.TOPIC_NAME, topicName);
    setDimensionInDefaultTracker(CUSTOM_DIMENSION.COURSE_NAME, courseTitle);
    setDimensionInDefaultTracker(CUSTOM_DIMENSION.PROGRAM_NAME, programTitle);
    setDimensionInDefaultTracker(CUSTOM_DIMENSION.COMPANY_NAME, companyName);
    setDimensionInDefaultTracker(CUSTOM_DIMENSION.GROUP_NAME, groupName);
    setDimensionInDefaultTracker(CUSTOM_DIMENSION.ANSWERS, answers);
  }

interface CompleteQuizGAProps {
  quiz: IEvaluation | null,
  lesson: ILesson | null,
  topic: ITopic | ITopicEmpty | null,
  course: ICourse | null,
  program: IProgram | null,
  company: ICompany | null,
  group: IGroup | null,
  answers: IAnswer[],
}
const completeQuiz = ({ quiz, lesson, topic, course, program, company, group, answers }: CompleteQuizGAProps) => {
  const { title: quizTitle } = quiz ?? { title: NA_TEXT };
  const { title: lessonTitle } = lesson ?? { title: NA_TEXT };
  const { title: topicTitle } = topic ?? { title: NA_TEXT };
  const { title: courseTitle } = course ?? { title: NA_TEXT };
  const { title: programTitle } = program ?? { title: NA_TEXT };
  const { name: companyName } = company ?? { name: NA_TEXT };
  const { name: groupName } = group ?? { name: NA_TEXT };
  const answersStrArray = answers.map(answer => '' + answer.answerId + answer.questionId);
  const answersStr = answersStrArray.join(';');

  setCompleteQuizCustomDimensions({
    quizName: quizTitle,
    courseTitle: courseTitle,
    lessonName: lessonTitle,
    topicName: topicTitle,
    programTitle: programTitle,
    companyName: companyName,
    groupName: groupName,
    answers: answersStr,
  });

  ReactGA.event({
    category: CATEGORY.COURSE,
    action: ACTIONS.COMPLETE_QUIZ,
    label: `${quizTitle}`,
  }, [DEFAULT_TRACKER_NAME]);
}

interface CompleteTestSetterProps {
  testName: string,
  topicName: string,
  courseTitle: string,
  programTitle: string,
  companyName: string,
  groupName: string,
  answers: string,
}

const setCompleteTestCustomDimensions =
  ({
    testName,
    topicName,
    courseTitle,
    programTitle,
    companyName,
    groupName,
    answers,
  }: CompleteTestSetterProps
  ) => {
    setDimensionInDefaultTracker(CUSTOM_DIMENSION.TEST_NAME, testName);
    setDimensionInDefaultTracker(CUSTOM_DIMENSION.TOPIC_NAME, topicName);
    setDimensionInDefaultTracker(CUSTOM_DIMENSION.COURSE_NAME, courseTitle);
    setDimensionInDefaultTracker(CUSTOM_DIMENSION.PROGRAM_NAME, programTitle);
    setDimensionInDefaultTracker(CUSTOM_DIMENSION.COMPANY_NAME, companyName);
    setDimensionInDefaultTracker(CUSTOM_DIMENSION.GROUP_NAME, groupName);
    setDimensionInDefaultTracker(CUSTOM_DIMENSION.ANSWERS, answers);
  }

interface CompleteTestGAProps {
  test: IEvaluation | null,
  topic: ITopic | null,
  course: ICourse | null,
  program: IProgram | null,
  company: ICompany | null,
  group: IGroup | null,
  answers: IAnswer[],
}
const completeTest = ({ test, topic, course, program, company, group, answers }: CompleteTestGAProps) => {
  const { title: topicTitle } = topic ?? { title: NA_TEXT };
  const { title: courseTitle } = course ?? { title: NA_TEXT };
  const { title: programTitle } = program ?? { title: NA_TEXT };
  const { name: companyName } = company ?? { name: NA_TEXT };
  const { name: groupName } = group ?? { name: NA_TEXT };
  const answersStrArray = answers.map(answer => '' + answer.answerId + answer.questionId);
  const answersStr = answersStrArray.join(';');

  setCompleteTestCustomDimensions({
    testName: test?.title ?? NA_TEXT,
    topicName: topicTitle,
    courseTitle: courseTitle,
    programTitle: programTitle,
    companyName: companyName,
    groupName: groupName,
    answers: answersStr,
  });

  ReactGA.event({
    category: CATEGORY.COURSE,
    action: ACTIONS.COMPLETE_TEST,
    label: `${test?.title}`,
  }, [DEFAULT_TRACKER_NAME]);
}
interface DownloadCertificateSetterProps {
  userName: string,
  courseTitle: string,
  programTitle: string,
  companyName: string,
  groupName: string,
}

const setDownloadCertificateCustomDimensions =
  ({
    userName,
    courseTitle,
    programTitle,
    companyName,
    groupName,
  }: DownloadCertificateSetterProps
  ) => {
    setDimensionInDefaultTracker(CUSTOM_DIMENSION.USER_ID, userName);
    setDimensionInDefaultTracker(CUSTOM_DIMENSION.COURSE_NAME, courseTitle);
    setDimensionInDefaultTracker(CUSTOM_DIMENSION.PROGRAM_NAME, programTitle);
    setDimensionInDefaultTracker(CUSTOM_DIMENSION.COMPANY_NAME, companyName);
    setDimensionInDefaultTracker(CUSTOM_DIMENSION.GROUP_NAME, groupName);
  }

interface DownloadCertificateGAProps {
  userName: string | null,
  courseTitle: string | null,
  programTitle: string | null,
  companyName: string | null,
  groupName: string | null,
}
const downloadCertificate = ({ userName, courseTitle, programTitle, companyName, groupName }: DownloadCertificateGAProps) => {
  setDownloadCertificateCustomDimensions({
    userName: userName ?? NA_TEXT,
    courseTitle: courseTitle ?? NA_TEXT,
    programTitle: programTitle ?? NA_TEXT,
    companyName: companyName ?? NA_TEXT,
    groupName: groupName ?? NA_TEXT,
  });

  ReactGA.event({
    category: CATEGORY.COURSE,
    action: ACTIONS.DOWNLOAD_CERTIFICATE,
    label: `${courseTitle}`,
  }, [DEFAULT_TRACKER_NAME]);
}

const logIn = (userIdToInitialize:string, companyId:string) => {
  userId = userIdToInitialize;
  loadGtag();
  // @ts-ignore
  window.dataLayer.push({ user_id: userId, [CUSTOM_DIMENSION.USER_ID]: userId, [CUSTOM_DIMENSION.COMPANY_NAME]: companyId });
  // @ts-ignore
  gtag('config', gaKey, {
    user_id: userId,
    [CUSTOM_DIMENSION.USER_ID]: userId,
    [CUSTOM_DIMENSION.COMPANY_NAME]: companyId,
  });
  ReactGA.event({
    category: CATEGORY.LOGGING,
    action: ACTIONS.LOG_IN,
    label: '',
  }, [DEFAULT_TRACKER_NAME]);
}

const logOut = () => {
  ReactGA.event({
    category: CATEGORY.LOGGING,
    action: ACTIONS.LOG_OUT,
    label: '',
  }, [DEFAULT_TRACKER_NAME]);
}

const signUp = () => {
  ReactGA.event({
    category: CATEGORY.LOGGING,
    action: ACTIONS.SIGN_UP,
    label: '',
  }, [DEFAULT_TRACKER_NAME]);
}


interface CompletePromoBannerSetterProps {
  answers: string,
}

const setCompleteBannerCustomDimensions = ({ answers }: CompletePromoBannerSetterProps) => {
  setDimensionInDefaultTracker(CUSTOM_DIMENSION.ANSWERS, answers);
}

interface CompletePromoBannerGAProps {
  totalTimeSecs: number,
  answers: IAnswer[],
}

const completePromoBanner = ({ totalTimeSecs, answers }: CompletePromoBannerGAProps) => {
  const answersStrArray = answers.map(answer => '' + answer.answerId + answer.questionId);
  const answersStr = answersStrArray.join(';');
  setCompleteBannerCustomDimensions({
    answers: answersStr,
  });

  ReactGA.event({
    category: CATEGORY.OTHER,
    action: ACTIONS.COMPLETE_PROMOBANNER,
    label: '',
    value: totalTimeSecs,
  }, [DEFAULT_TRACKER_NAME]);
}

const viewPromoBanner = ({ banner }: { banner: ICampaign }) => {
  ReactGA.event({
    category: CATEGORY.OTHER,
    action: ACTIONS.VIEW_PROMOBANNER,
    label: banner.title,
  }, [DEFAULT_TRACKER_NAME]);
}

const selectPromoBanner = ({ banner }: { banner: ICampaign }) => {
  ReactGA.event({
    category: CATEGORY.OTHER,
    action: ACTIONS.SELECT_PROMOBANNER,
    label: banner.title,
  }, [DEFAULT_TRACKER_NAME]);
}

interface CreateStudentSetterProps {
  userName: string;
  companyName: string;
  studentsCount?: number;
}

const createStudent = ({ userName, companyName, studentsCount = 1 }: CreateStudentSetterProps) => {
  ReactGA.event({
    category: CATEGORY.ADMIN,
    action: ACTIONS.CREATE_STUDENT,
    label: '',
    value: studentsCount,
  }, [DEFAULT_TRACKER_NAME]);
}

interface CreateGroupSetterProps {
  userName: string;
  companyName: string;
}

const createGroup = ({ userName, companyName }: CreateGroupSetterProps) => {
  ReactGA.event({
    category: CATEGORY.ADMIN,
    action: ACTIONS.CREATE_GROUP,
    label: '',
  }, [DEFAULT_TRACKER_NAME]);
}

interface CreateknowledgebaseSetterProps {
  userName: string;
  companyName: string;
}

const createKnowledgebase = ({ userName, companyName }: CreateknowledgebaseSetterProps) => {
  ReactGA.event({
    category: CATEGORY.ADMIN,
    action: ACTIONS.CREATE_KNOWLEDGEBASE,
    label: '',
  }, [DEFAULT_TRACKER_NAME]);
}

interface CreatePromoBannerSetterProps {
  userName: string;
  companyName: string;
}


const createPromoBanner = ({ userName, companyName }: CreatePromoBannerSetterProps) => {
  ReactGA.event({
    category: CATEGORY.ADMIN,
    action: ACTIONS.CREATE_PROMOBANNER,
    label: '',
  }, [DEFAULT_TRACKER_NAME]);
}

const notificationDismiss = (notificationTitle: string) => {
  ReactGA.event({
    category: CATEGORY.OTHER,
    action: ACTIONS.NOTIFICATION_DISMISS,
    label: notificationTitle,
  }, [DEFAULT_TRACKER_NAME]);
}

const notificationReceive = (notificationTitle: string) => {
  ReactGA.event({
    category: CATEGORY.OTHER,
    action: ACTIONS.NOTIFICATION_RECEIVE,
    label: notificationTitle,
  }, [DEFAULT_TRACKER_NAME]);
}

const notificationOpen = (notificationTitle: string) => {
  ReactGA.event({
    category: CATEGORY.OTHER,
    action: ACTIONS.NOTIFICATION_OPEN,
    label: notificationTitle,
  }, [DEFAULT_TRACKER_NAME]);
}

const selectKnowledgeBases = () => {
  ReactGA.event({
    category: CATEGORY.OTHER,
    action: ACTIONS.SELECT_KNOWLEDGE_BASE,
    label: 'Todas las bases de conocimiento',
  }, [DEFAULT_TRACKER_NAME]);
}


export interface SelectKBCategoryGAProps {
  category: { name: string, categoryId: string } | null,
}

const selectKnowledgeBaseCategory = ({ category }: SelectKBCategoryGAProps) => {

  ReactGA.event({
    category: CATEGORY.OTHER,
    action: ACTIONS.SELECT_KNOWLEDGE_BASE_CATEGORY,
    label: category?.name,
  }, [DEFAULT_TRACKER_NAME]);
}

interface SelectCourseSetterProps {
  courseTitle: string,
  programTitle: string,
  companyName: string,
  groupName: string,
}

const setSelectCourseCustomDimensions =
  ({
    courseTitle,
    programTitle,
    companyName,
    groupName,
  }: SelectCourseSetterProps
  ) => {
    setDimensionInDefaultTracker(CUSTOM_DIMENSION.COURSE_NAME, courseTitle);
    setDimensionInDefaultTracker(CUSTOM_DIMENSION.PROGRAM_NAME, programTitle);
    setDimensionInDefaultTracker(CUSTOM_DIMENSION.COMPANY_NAME, companyName);
    setDimensionInDefaultTracker(CUSTOM_DIMENSION.GROUP_NAME, groupName);
  }

interface SelectCourseGAProps {
  course: ICourse | null,
  program: IProgram | null,
  company: ICompany | null,
  group: IGroup | null,
}

const selectCourse = ({ course, program, company, group }: SelectCourseGAProps) => {
  const { title: courseTitle } = course ?? { title: NA_TEXT };
  const { title: programTitle } = program ?? { title: NA_TEXT };
  const { name: companyName } = company ?? { name: NA_TEXT };
  const { name: groupName } = group ?? { name: NA_TEXT };

  setSelectCourseCustomDimensions({ courseTitle, programTitle, companyName, groupName });

  ReactGA.event({
    category: CATEGORY.COURSE,
    action: ACTIONS.SELECT_COURSE,
    label: `${courseTitle}`,
  }, [DEFAULT_TRACKER_NAME]);
}

interface SelectTopicSetterProps {
  topicName: string,
  courseTitle: string,
  programTitle: string,
  companyName: string,
  groupName: string,
}

const setSelectTopicCustomDimensions =
  ({
    topicName,
    courseTitle,
    programTitle,
    companyName,
    groupName,
  }: SelectTopicSetterProps
  ) => {
    setDimensionInDefaultTracker(CUSTOM_DIMENSION.TOPIC_NAME, topicName);
    setDimensionInDefaultTracker(CUSTOM_DIMENSION.COURSE_NAME, courseTitle);
    setDimensionInDefaultTracker(CUSTOM_DIMENSION.PROGRAM_NAME, programTitle);
    setDimensionInDefaultTracker(CUSTOM_DIMENSION.COMPANY_NAME, companyName); // check if company is needed or can be set a single time for each user
    setDimensionInDefaultTracker(CUSTOM_DIMENSION.GROUP_NAME, groupName);
  }

interface SelectTopicGAProps {
  topic: ITopic | null,
  course: ICourse | null,
  program: IProgram | null,
  company: ICompany | null,
  group: IGroup | null,
}

const selectTopic = ({ topic, course, program, company, group }: SelectTopicGAProps) => {
  const { title: topicTitle } = topic ?? { title: NA_TEXT };
  const { title: courseTitle } = course ?? { title: NA_TEXT };
  const { title: programTitle } = program ?? { title: NA_TEXT };
  const { name: companyName } = company ?? { name: NA_TEXT };
  const { name: groupName } = group ?? { name: NA_TEXT };

  setSelectTopicCustomDimensions({
    topicName: topicTitle,
    courseTitle: courseTitle,
    programTitle: programTitle,
    companyName: companyName,
    groupName: groupName
  });

  ReactGA.event({
    category: CATEGORY.COURSE,
    action: ACTIONS.SELECT_TOPIC,
    label: `${topicTitle}`,
  }, [DEFAULT_TRACKER_NAME]);
}

interface SelectLessonSetterProps {
  lessonName: string,
  topicName: string,
  courseTitle: string,
  programTitle: string,
  companyName: string,
  groupName: string,
}

const setSelectLessonCustomDimensions =
  ({
    lessonName,
    topicName,
    courseTitle,
    programTitle,
    companyName,
    groupName,
  }: SelectLessonSetterProps
  ) => {
    setDimensionInDefaultTracker(CUSTOM_DIMENSION.LESSON_NAME, lessonName);
    setDimensionInDefaultTracker(CUSTOM_DIMENSION.TOPIC_NAME, topicName);
    setDimensionInDefaultTracker(CUSTOM_DIMENSION.COURSE_NAME, courseTitle);
    setDimensionInDefaultTracker(CUSTOM_DIMENSION.PROGRAM_NAME, programTitle);
    setDimensionInDefaultTracker(CUSTOM_DIMENSION.COMPANY_NAME, companyName);
    setDimensionInDefaultTracker(CUSTOM_DIMENSION.GROUP_NAME, groupName);
  }

interface SelectLessonGAProps {
  lesson: ILesson | null,
  topic: ITopic | null,
  course: ICourse | null,
  program: IProgram | null,
  company: ICompany | null,
  group: IGroup | null,
}

const selectLesson = ({ lesson, topic, course, program, company, group }: SelectLessonGAProps) => {
  const { title: lessonTitle } = lesson ?? { title: NA_TEXT };
  const { title: topicTitle } = topic ?? { title: NA_TEXT };
  const { title: courseTitle } = course ?? { title: NA_TEXT };
  const { title: programTitle } = program ?? { title: NA_TEXT };
  const { name: companyName } = company ?? { name: NA_TEXT };
  const { name: groupName } = group ?? { name: NA_TEXT };

  setSelectLessonCustomDimensions({
    lessonName: lessonTitle,
    topicName: topicTitle,
    courseTitle: courseTitle,
    programTitle: programTitle,
    companyName: companyName,
    groupName: groupName
  });

  ReactGA.event({
    category: CATEGORY.COURSE,
    action: ACTIONS.SELECT_LESSON,
    label: `${lessonTitle}`,
  }, [DEFAULT_TRACKER_NAME]);
}

interface SelectTestSetterProps {
  testName: string,
  topicName: string,
  courseTitle: string,
  programTitle: string,
  companyName: string,
  groupName: string,
}

const setSelectTestCustomDimensions =
  ({
    testName,
    topicName,
    courseTitle,
    programTitle,
    companyName,
    groupName,
  }: SelectTestSetterProps
  ) => {
    setDimensionInDefaultTracker(CUSTOM_DIMENSION.LESSON_NAME, testName);
    setDimensionInDefaultTracker(CUSTOM_DIMENSION.TOPIC_NAME, topicName);
    setDimensionInDefaultTracker(CUSTOM_DIMENSION.COURSE_NAME, courseTitle);
    setDimensionInDefaultTracker(CUSTOM_DIMENSION.PROGRAM_NAME, programTitle);
    setDimensionInDefaultTracker(CUSTOM_DIMENSION.COMPANY_NAME, companyName);
    setDimensionInDefaultTracker(CUSTOM_DIMENSION.GROUP_NAME, groupName);
  }

interface SelecTestGAProps {
  test: IEvaluation | null,
  topic: ITopic | null,
  course: ICourse | null,
  program: IProgram | null,
  company: ICompany | null,
  group: IGroup | null,
}

const selectTest = ({ test, topic, course, program, company, group }: SelecTestGAProps) => {
  const { title: testTitle } = test ?? { title: NA_TEXT };
  const { title: topicTitle } = topic ?? { title: NA_TEXT };
  const { title: courseTitle } = course ?? { title: NA_TEXT };
  const { title: programTitle } = program ?? { title: NA_TEXT };
  const { name: companyName } = company ?? { name: NA_TEXT };
  const { name: groupName } = group ?? { name: NA_TEXT };

  setSelectTestCustomDimensions({
    testName: testTitle,
    topicName: topicTitle,
    courseTitle: courseTitle,
    programTitle: programTitle,
    companyName: companyName,
    groupName: groupName
  });

  ReactGA.event({
    category: CATEGORY.COURSE,
    action: ACTIONS.SELECT_TEST,
    label: `${testTitle}`,
  }, [DEFAULT_TRACKER_NAME]);
}

interface SelectExamSetterProps {
  examTitle: string,
  courseTitle: string,
  programTitle: string,
  companyName: string,
  groupName: string,
}

const setSelectExamCustomDimensions =
  ({
    examTitle,
    courseTitle,
    programTitle,
    companyName,
    groupName,
  }: SelectExamSetterProps
  ) => {
    setDimensionInDefaultTracker(CUSTOM_DIMENSION.EXAM_NAME, examTitle);
    setDimensionInDefaultTracker(CUSTOM_DIMENSION.COURSE_NAME, courseTitle);
    setDimensionInDefaultTracker(CUSTOM_DIMENSION.PROGRAM_NAME, programTitle);
    setDimensionInDefaultTracker(CUSTOM_DIMENSION.COMPANY_NAME, companyName);
    setDimensionInDefaultTracker(CUSTOM_DIMENSION.GROUP_NAME, groupName);
  }

interface SelecExamGAProps {
  exam: IEvaluation | null,
  course: ICourse | null,
  program: IProgram | null,
  company: ICompany | null,
  group: IGroup | null,
}

const selectExam = ({ exam, course, program, company, group }: SelecExamGAProps) => {
  const { title: examTitle } = exam ?? { title: NA_TEXT };
  const { title: courseTitle } = course ?? { title: NA_TEXT };
  const { title: programTitle } = program ?? { title: NA_TEXT };
  const { name: companyName } = company ?? { name: NA_TEXT };
  const { name: groupName } = group ?? { name: NA_TEXT };

  setSelectExamCustomDimensions({
    examTitle: examTitle,
    courseTitle: courseTitle,
    programTitle: programTitle,
    companyName: companyName,
    groupName: groupName
  });

  ReactGA.event({
    category: CATEGORY.COURSE,
    action: ACTIONS.SELECT_EXAM,
    label: `${examTitle}`,
  }, [DEFAULT_TRACKER_NAME]);
}

interface SelectSurveySetterProps {
  courseTitle: string,
  programTitle: string,
  companyName: string,
  groupName: string,
}

const setSelectSurveyCustomDimensions =
  ({
    courseTitle,
    programTitle,
    companyName,
    groupName,
  }: SelectSurveySetterProps
  ) => {
    setDimensionInDefaultTracker(CUSTOM_DIMENSION.COURSE_NAME, courseTitle);
    setDimensionInDefaultTracker(CUSTOM_DIMENSION.PROGRAM_NAME, programTitle);
    setDimensionInDefaultTracker(CUSTOM_DIMENSION.COMPANY_NAME, companyName);
    setDimensionInDefaultTracker(CUSTOM_DIMENSION.GROUP_NAME, groupName);
  }

interface SelecSurveyGAProps {
  survey: IEvaluation | null,
  course: ICourse | null,
  program: IProgram | null,
  company: ICompany | null,
  group: IGroup | null,
}

const selectSurvey = ({ survey, course, program, company, group }: SelecSurveyGAProps) => {
  const { title: courseTitle } = course ?? { title: NA_TEXT };
  const { title: programTitle } = program ?? { title: NA_TEXT };
  const { name: companyName } = company ?? { name: NA_TEXT };
  const { name: groupName } = group ?? { name: NA_TEXT };

  setSelectSurveyCustomDimensions({
    courseTitle: courseTitle,
    programTitle: programTitle,
    companyName: companyName,
    groupName: groupName
  });

  ReactGA.event({
    category: CATEGORY.COURSE,
    action: ACTIONS.SELECT_SURVEY,
    label: `${survey?.title}`,
  }, [DEFAULT_TRACKER_NAME]);
}

interface ViewKnowledgeBaseSetterProps {
  categoryName: string,
  knowledgeBaseName: string,
}

const setViewKnowledgeBaseCustomDimensions =
  ({
    categoryName,
    knowledgeBaseName,
  }: ViewKnowledgeBaseSetterProps
  ) => {
    setDimensionInDefaultTracker(CUSTOM_DIMENSION.KNOWLEDGE_BASE_CATEGORY_NAME, categoryName);
    setDimensionInDefaultTracker(CUSTOM_DIMENSION.KNOWLEDGE_BASE_NAME, knowledgeBaseName);
  }

interface ViewKnowledgeBaseGAProps {
  category: ICategory | null,
  knowledgeBase: IContent | null,
}

const viewKnowledgeBase = ({ category, knowledgeBase }: ViewKnowledgeBaseGAProps) => {
  const { name: categoryName } = category ?? { name: NA_TEXT };
  const { title } = knowledgeBase ?? { title: NA_TEXT };
  setViewKnowledgeBaseCustomDimensions({ categoryName, knowledgeBaseName: title });
  ReactGA.event({
    category: CATEGORY.OTHER,
    action: ACTIONS.SELECT_KNOWLEDGE_BASE_REGISTRY,
    label: title ?? NA_TEXT,
  }, [DEFAULT_TRACKER_NAME]);
}

interface ViewReportSetterProps {
  indicator: string,
  filter: string,
}

const setViewReportCustomDimensions =
  ({
    indicator,
    filter,
  }: ViewReportSetterProps
  ) => {
    setDimensionInDefaultTracker(CUSTOM_DIMENSION.REPORT_INDICATOR, indicator);
    setDimensionInDefaultTracker(CUSTOM_DIMENSION.REPORT_FILTER, filter);
  }

interface ViewReportGAProps {
  reportName: string,
  filter: ICompany | null,
}

const viewReport = ({ reportName , filter }: ViewReportGAProps) => {
  const { name: companyName } = filter ?? { name: ''};
  const filterJson = (() => {
    const resultFilter: any = {};
    if(companyName) {
      resultFilter.compania = companyName;
    }
    return resultFilter;
  })();
  setViewReportCustomDimensions({ indicator: reportName, filter: JSON.stringify(filterJson) });
  ReactGA.event({
    category: CATEGORY.ADMIN,
    action: ACTIONS.VIEW_REPORT,
    label: reportName ?? NA_TEXT,
  }, [DEFAULT_TRACKER_NAME]);
}

export const analytics = {
  initialize,
  pageview,
  setCompanyCustomDimension,
  completeLesson,
  completeCourse,
  completeTopic,
  completeExam,
  completeSurvey,
  completeQuiz,
  completeTest,
  downloadCertificate,
  logIn,
  logOut,
  signUp,
  completePromoBanner,
  viewPromoBanner,
  selectPromoBanner,
  createStudent,
  createGroup,
  createKnowledgebase,
  createPromoBanner,
  notificationDismiss,
  notificationReceive,
  notificationOpen,
  selectKnowledgeBases,
  selectKnowledgeBaseCategory,
  viewKnowledgeBase,
  selectCourse,
  selectTopic,
  selectLesson,
  selectTest,
  selectExam,
  selectSurvey,
  viewReport,
}
