import { Button, Card } from "antd";
import React, { FunctionComponent, ReactNode } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { QuestionsAnswers } from "../../../../features/evaluation/common/interfaces";
import { selectEvaluation } from "../../../../features/evaluation/currentEvaluationSlice";
import {
  COURSE_EXAM,
  FINISH_COURSE,
  ISelectStudentCourseNextLessonIdReturn,
  makeProgressStudentCourse,
  selectStudentCourseNextLessonIdAfterTest,
  selectStudentCourseTopic,
} from "../../../../features/students/courseSlice";
import { CogIcon } from "../../../common/CogIcon";
import styles from "./lastPage.module.scss";

interface LastPageProps {
  topicId: string;
  courseId: string;
  answersSelected: QuestionsAnswers;
  retakeTest: Function;
}

interface GetActionsParams {
  showRetake: boolean;
  testWasPassed: boolean;
  retakeTest: Function;
  nextLessonId: ISelectStudentCourseNextLessonIdReturn;
  courseId: string;
}

export const LastPage: FunctionComponent<LastPageProps> = ({
  topicId,
  courseId,
  answersSelected = {},
  retakeTest,
}) => {
  const correctAnswersCount = Object.values(answersSelected).reduce(
    (total: number, answer) => total + (answer?.correct ? 1 : 0),
    0
  );
  const topic = useSelector(selectStudentCourseTopic(topicId));
  const evaluation = useSelector(selectEvaluation(topic.evaluation || ''));
  const questionsCount = evaluation?.questions?.length || 1;
  const successRate = Math.round((correctAnswersCount / questionsCount) * 100);
  const correctAnswersToPass = topic?.correctAnswersToPass || 0;
  const testWasPassed = correctAnswersCount >= correctAnswersToPass;
  const title = testWasPassed ? "¡Excelente, aprobaste!" : "Fallaste esta vez";
  const icon = testWasPassed ? "test-1" : "Cross-circle";
  const nextLessonId = useSelector(
    selectStudentCourseNextLessonIdAfterTest(topicId)
  );
  const history = useHistory();
  const dispatch = useDispatch();

  const getActions = ({
    showRetake,
    testWasPassed,
    retakeTest,
    nextLessonId,
    courseId,
  }: GetActionsParams): ReactNode[] => {
    if (testWasPassed) {
      let buttonLabel = "Siguiente lección";
      if (nextLessonId.where === FINISH_COURSE) {
        buttonLabel = "Completar curso";
      }
      if (nextLessonId.where === COURSE_EXAM) {
        buttonLabel = "Tomar examen";
      }

      const buttons = [];
      if (showRetake) {
        buttons.push(
          <div className={styles.buttonContainer}>
            <Button
              onClick={() => {
                retakeTest();
              }}
              type="link"
            >
              Mejorar calificación
            </Button>
          </div>
        );
      }
      buttons.push(
        <div className={styles.buttonContainer}>
          <Button
            onClick={() => {
              const doNext = () => {
                dispatch(makeProgressStudentCourse({
                  courseId: courseId,
                  topicId, 
                  progress: 1,
                }));
              }
              if (![FINISH_COURSE, COURSE_EXAM].includes(nextLessonId.where)) {
                doNext();
                history.push(`/course/${courseId}/${nextLessonId.id}`);
                return;
              }
              if (nextLessonId.where === COURSE_EXAM) {
                doNext();
                history.push(`/course/${courseId}/exam`);
                return;
              }
              dispatch(makeProgressStudentCourse({
                courseId: courseId,
                topicId, 
                progress: 1,
              }, () => dispatch(
                makeProgressStudentCourse(
                  {
                    courseId: courseId,
                    progress: 1,
                  },
                  () => history.push(`/course/${courseId}/congratulations`)
                )
              )));
            }}
            type="primary"
          >
            {buttonLabel}
          </Button>
        </div>
      );
      return buttons;
    }
    return [
      <div className={styles.buttonContainer}>
        <Button
          onClick={() => {
            retakeTest();
          }}
          type="primary"
        >
          Volver a intentarlo
        </Button>
      </div>,
    ];
  };
  return (
    <Card
      className={styles.container}
      actions={getActions({
        showRetake: correctAnswersCount !== questionsCount,
        testWasPassed,
        retakeTest,
        nextLessonId,
        courseId,
      })}
    >
      <CogIcon className={styles.icon} icon={icon} />
      <div className={styles.info}>{title}</div>
      <div className={styles.results}>
        <div className={styles.result}>
          Tu calificación fue de: {correctAnswersCount} / {questionsCount}
        </div>
        <div className={styles.result}>
          Tuviste un acierto del: {successRate}%
        </div>
        <div className={styles.result}>
          Respuestas correctas: {correctAnswersCount}
        </div>
        <div className={styles.result}>
          Respuestas incorrectas: {questionsCount - correctAnswersCount}
        </div>
      </div>
    </Card>
  );
};
