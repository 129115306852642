import { DownOutlined } from "@ant-design/icons";
import { Alert, Button, Dropdown, Form, Menu, Card, Divider, Row, Col } from "antd";
import cls from "classnames";
import FileDownload from "js-file-download";
import moment from "moment";
import React, { Dispatch, FunctionComponent, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { Microservices } from "../../app/AllowedMicroservices";
import { BASE_URL } from "../../app/apiMiddleware";
import { cognitoUser } from "../../features/auth/authSlice";
import { setBreadcrumbs } from "../../features/breadcrumbs/breadcrumbsSlice";
import {
  disableCompaniesStatus,
  enableCompaniesStatus,
  fetchCompany,
  hideModal,
  ICompany,
  selectCompany,
  selectDisplayModal,
  updateCompany
} from "../../features/company/companySlice";
import {
  fetchPrograms,
  ICourseDict,
  selectProgramsDict
} from "../../features/program/programSlice";
import { CogIcon } from "../common/CogIcon";
import { DialogPopUp } from "../common/DialogPopUp";
import { CogDrawer } from "../common/Drawer";
import { ProfileLayout } from "../common/Layouts/ProfileLyt";
import { Output } from "../common/Output";
import { Status, StatusEnum } from "../common/Status";
import { getCountryName } from "../Users/utils";
import styles from "./companyProfile.module.scss";
import { CompanyForm } from "./Form";

export const MAX_EXTRA_STUDENTS_PERCENT = 20;

interface ProfileProps { }

const renderOptions = (
  toggleVisible: any,
  company: ICompany,
  dispatch: Dispatch<any>,
  goToPrint: Function
) => {
  const menu = (
    <Menu
      onClick={async (info) => {
        let url;
        if (info.key === "1") {
          goToPrint();
          return;
        } else {
          url = `${BASE_URL[Microservices.COMPANY_SERVICE]}/companies/${company._id
            }/users/report`;
        }
        const response = await fetch(url, {
          method: "GET",
          headers: new Headers({
            Authorization:
              cognitoUser
                ?.getSignInUserSession()
                ?.getAccessToken()
                .getJwtToken() || "",
          }),
        });
        const data = await response.blob();
        const fileName = `${company.name}-Report.xlsx`;
        FileDownload(data, fileName);
      }}
    >
      <Menu.Item key="1">Ficha</Menu.Item>
      <Menu.Item key="2">Reporte de usuarios</Menu.Item>
    </Menu>
  );

  return (
    <Row style={{ justifyContent: "flex-end" }} >
      <Button
        type="link"
        onClick={() => {
          if (company.status === StatusEnum.Enable) {
            dispatch(disableCompaniesStatus({ ids: [company._id] }));
          } else {
            dispatch(enableCompaniesStatus({ ids: [company._id] }));
          }
        }}
      >
        {company.status === StatusEnum.Enable ? "Deshabilitar" : "Habilitar"}
      </Button>
      <Button
        onClick={() => {
          toggleVisible();
        }}
      >
        Editar
      </Button>
      &nbsp;&nbsp;&nbsp;
      <Dropdown overlay={menu} trigger={["click"]}>
        <Button className={styles.downloadButton} type="primary">
          Descargar <DownOutlined />
        </Button>
      </Dropdown>
    </Row>
  );
};

const renderLeftColumn = (company: ICompany) => {
  const contact = company.contactInfo || {};
  return (
    <React.Fragment>
      <Row gutter={[16, 40]}>
        <Col span={24}>
          <Card className={cls(styles.card, styles.overload)}>
            <h2 className={cls(styles.title)}>Datos de la Empresa</h2>
            <Output label={"Nombre de la empresa"} text={company.name} />
            <Output label={"RUC"} text={company.ruc} />
            <Output
              label={"Número máximo de estudiantes activos"}
              text={company.maxActiveStudents?.toString()}
            />
            <Output
              label={"Fecha de creación"}
              text={moment.utc(company.createdAt).format("DD-MM-YYYY")}
            />
            <Divider />
            <Output label={"Dirección"} text={company.address} />
            <Output
              label={"País"}
              text={getCountryName(company.country) || "---"}
            />
            <Output label={"Ciudad"} text={company.city} />
          </Card>
        </Col>
        <Col span={24}>
          <Card className={cls(styles.card, styles.overload)}>
            <h2 className={cls(styles.title)}>Datos de contacto</h2>
            <Output
              label={"Nombres"}
              text={`${contact.firstname} ${contact.middlename ?? ""}`}
            />
            <Output
              label={"Apellidos"}
              text={`${contact.lastname} ${contact.secondLastname ?? ""}`}
            />
            <Output label={"Cargo o posición"} text={contact.position} />
            <Divider />
            <Output label={"Número celular"} text={contact.cellphone} />
            <Output label={"Correo electrónico"} text={contact.email} />
            <Output label={"Dirección"} text={contact.address} />
            <Output
              label={"País"}
              text={getCountryName(contact.country) || "---"}
            />
            <Output label={"Ciudad"} text={contact.city} />
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
};

const renderRightColumn = (company: ICompany, programs: ICourseDict) => {
  const { activeUsersCount = 0, inactiveUsersCount = 0 } = company;
  const totalStudentsCount = activeUsersCount + inactiveUsersCount;
  const extraStudents = totalStudentsCount - (company.maxActiveStudents ?? 0);
  const showAlert =
    extraStudents > 0 &&
    (extraStudents / company.maxActiveStudents) * 100 >
    MAX_EXTRA_STUDENTS_PERCENT;
  const notificationBlock = company.notificationTypesEnabled?.email ||
    company.notificationTypesEnabled?.sms ||
    company.notificationTypesEnabled?.web;
  return (
    <>
      <Row gutter={[16, 40]}>
        <Col span={24}>
          <Card className={cls(styles.card, styles.overload)}>
            <h2 className={cls(styles.title)}>Contrato</h2>
            <Output
              label={"Programa de estudios"}
              text={programs[company.studyProgram]?.title ?? "No definido"}
            />
            <Divider />
            <Output label={"Notificaciones activas"} text={""}>
              {notificationBlock ? 
              <>
                {company.notificationTypesEnabled?.email ? (
                  <div>
                    <CogIcon icon="Earth" /> <span>E-mail</span>
                  </div>
                ) : null}
                {company.notificationTypesEnabled?.web ? (
                  <div>
                    <CogIcon icon="Mail" /> <span>Browser push</span>
                  </div>
                ) : null}
                {company.notificationTypesEnabled?.sms ? (
                  <div>
                    <CogIcon icon="sms" /> <span>SMS</span>
                  </div>
                ) : null}
                
                <Divider />
              </>:'No tiene notificaciones activas'}
            </Output>
            <Output
              label={"Fecha del contrato"}
              text={
                company.contractDate
                  ? moment(company.contractDate).format("DD/MM/YYYY")
                  : "---"
              }
            />
            <Divider />
            <Output label={"Estatus"} text={""}>
              <div style={{ paddingTop: '1rem' }}>
                <Status
                  status={
                    company.status ? StatusEnum.Enable : StatusEnum.Disable
                  }
                ></Status>
              </div>
            </Output>
          </Card>
          <div style={{ marginTop: 10 }}>
            {showAlert ? (
              <Alert
                className={styles.alertError}
                message={
                  <span className={styles.message}>
                    <CogIcon className={styles.icon} color="red" icon="Attention" />
                    Se ha sobrepasado el 20% del total de estudiantes configurados
                  </span>
                }
                type="error"
              />
            ) : null}
          </div>
        </Col>
      </Row>
    </>
  );
};

interface RouteParams {
  id: string;
}

const companyFormId = "companyForm";

export const CompanyProfile: FunctionComponent<ProfileProps> = () => {
  const [visible, setVisible] = useState<boolean>(false);
  const toggleVisible = () => setVisible(!visible);
  const [company, setCompany] = useState<ICompany>();
  const [currentCompany, setCurrentCompany] = useState<ICompany>();
  const dispatch = useDispatch();
  const { id } = useParams<RouteParams>();
  const programs = useSelector(selectProgramsDict);
  const companyFromStore = useSelector(selectCompany(id));
  const isModalVisible = useSelector(selectDisplayModal);
  const history = useHistory();

  useEffect(() => {
    dispatch(
      setBreadcrumbs([
        {
          label: "Empresas",
          url: "/companies",
        },
        {
          label: "Detalle de la empresa",
        },
      ])
    );
  }, [dispatch]);
  useEffect(() => {
    if (companyFromStore) {
      setCompany(companyFromStore);
      setCurrentCompany(companyFromStore);
    }
  }, [companyFromStore, dispatch]);

  useEffect(() => {
    dispatch(fetchCompany(id));
    dispatch(fetchPrograms());
  }, [dispatch, id]);

  const [form] = Form.useForm();

  const goToProfilePrint = () => {
    history.push(`/companies/${id}/print`);
  };

  return (
    <ProfileLayout
      image={currentCompany?.logo}
      rightTopOptions={renderOptions(
        toggleVisible,
        Object.assign({}, currentCompany),
        dispatch,
        goToProfilePrint
      )}
      leftBottomOptions={renderLeftColumn(Object.assign({}, currentCompany))}
      rightBottomOptions={renderRightColumn(
        Object.assign({}, currentCompany),
        programs
      )}
    >
      <DialogPopUp
        name="Empresa"
        action="actualizada"
        visible={isModalVisible}
        onCancel={() => {
          dispatch(hideModal());
        }}
      />
      <CogDrawer
        visible={visible}
        toggleVisible={toggleVisible}
        title="Editar"
        footer={
          <div>
            <Button onClick={toggleVisible}>Cancelar</Button>&nbsp;&nbsp;
            <Button
              type="primary"
              htmlType="submit"
              form={companyFormId}
              onClick={() => {
                form
                  .validateFields()
                  .then((_value) => {
                    if (!company) {
                      return;
                    }
                    dispatch(updateCompany(company));
                    toggleVisible();
                    dispatch(fetchCompany(id));
                  })
                  .catch((error) => {
                    console.error(
                      "CompanyProfile: FunctionComponent<ProfileProps> -> error",
                      error
                    );
                  });
              }}
            >
              Actualizar
            </Button>
          </div>
        }
      >
        <div>
          {!visible ? null : (
            <CompanyForm
              form={form}
              newCompany={company}
              setNewCompany={setCompany}
            />
          )}
        </div>
      </CogDrawer>
    </ProfileLayout>
  );
};
