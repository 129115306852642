import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Dispatch } from "react";
import { Microservices } from "../../app/AllowedMicroservices";
import { AppThunk, RootState } from "../../app/store";
import apiActionCreator, { HttpMethods } from "../../services/apiActionCreator";
import { MODAL_DELAY_MS } from "../../utils/constants";
import { FetchStatus, ModalStatus } from "../common/enums";
import { buildShortIds } from '../common/shortId';
import { setMessageError, setMessageInfo } from "../message/messageSlice";
import { EvaluationTypeEnum } from "./common/enums";
import { IEvaluation } from "./common/interfaces";

interface IEvaluationDict {
  [_id: string]: IEvaluation;
}

interface EvaluationState {
  list: IEvaluation[];
  quizzes: string[];
  tests: string[];
  examns: string[];
  surveys: string[];
  dict: IEvaluationDict;
  status: FetchStatus;
  modalStatus: ModalStatus;
}

const initialState: EvaluationState = {
  list: [],
  quizzes: [],
  tests: [],
  examns: [],
  surveys: [],
  dict: {},
  status: FetchStatus.Idle,
  modalStatus: ModalStatus.Hide,
};

interface EvaluationFetch {
  docs: IEvaluation[];
  limit: number;
  page: number;
  pages: number;
  total: number;
}

interface EvaluationUpdateStatusData {
  ids: string[];
}

const evaluationSlice = createSlice({
  name: "evaluations",
  initialState,
  reducers: {
    fetching: (state) => {
      state.status = FetchStatus.Fetching;
    },
    fetchedEvaluations: (state, action: PayloadAction<EvaluationFetch>) => {
      action.payload.docs = buildShortIds(action.payload.docs) as IEvaluation[];
      state.list = action.payload.docs;
      state.quizzes = action.payload.docs
        .filter((evaluation) => evaluation.type === EvaluationTypeEnum.Quiz)
        .map((item) => item._id);
      state.tests = action.payload.docs
        .filter((evaluation) => evaluation.type === EvaluationTypeEnum.Test)
        .map((item) => item._id);
      state.examns = action.payload.docs
        .filter((evaluation) => evaluation.type === EvaluationTypeEnum.Exam)
        .map((item) => item._id);
      state.surveys = action.payload.docs
        .filter((evaluation) => evaluation.type === EvaluationTypeEnum.Survey)
        .map((item) => item._id);
      state.dict = {};
      action.payload.docs.forEach((evaluation) => {
        state.dict[evaluation._id] = evaluation;
      });
      state.status = FetchStatus.Fetched;
    },
    creating: (state) => {
      state.status = FetchStatus.Creating;
    },
    showModal: (state) => {
      state.modalStatus = ModalStatus.Show;
    },
    hideModal: (state) => {
      state.modalStatus = ModalStatus.Hide;
    },
    error: (state, _action: PayloadAction<EvaluationFetch>) => {
      state.list = [];
      state.quizzes = [];
      state.tests = [];
      state.examns = [];
      state.surveys = [];
      state.status = FetchStatus.Error;
    },
  },
});

const BASE_ENDPOINT = "/evaluations";
export const { hideModal } = evaluationSlice.actions;
const {
  fetching,
  fetchedEvaluations,
  error,
  creating,
  showModal,
} = evaluationSlice.actions;

export const fetchEvaluations = () => (dispatch: Dispatch<object>) => {
  return dispatch(
    apiActionCreator({
      endpoint: `${BASE_ENDPOINT}?limit=1000000`,
      types: {
        requestType: fetching,
        successTypes: [
          {
            actionOrCreator: fetchedEvaluations,
          },
        ],
        errorTypes: [
          {
            actionOrCreator: error,
          },
        ],
      },
      method: HttpMethods.GET,
      microservice: Microservices.EVALUATION_SERVICE,
      authenticated: true,
    })
  );
};

export const createEvaluation = (newEvaluation: IEvaluation) => (
  dispatch: Dispatch<object>
) => {
  return dispatch(
    apiActionCreator({
      endpoint: BASE_ENDPOINT,
      types: {
        requestType: creating,
        successTypes: [
          {
            actionOrCreator: fetchEvaluations,
          },
          {
            actionOrCreator: showModal,
            selector: () => {
              dispatch(hideModalWithDelay());
            },
          },
        ],
        errorTypes: [
          {
            actionOrCreator: error,
            selector: (_response: any) => {
              const message = `Error desconocido`;
              dispatch(setMessageError({ message }));
            },
          },
        ],
      },
      method: HttpMethods.POST,
      microservice: Microservices.EVALUATION_SERVICE,
      authenticated: true,
      data: newEvaluation,
    })
  );
};

export const updateEvaluation = (evaluation: IEvaluation) => (
  dispatch: Dispatch<object>
) => {
  return dispatch(
    apiActionCreator({
      endpoint: `${BASE_ENDPOINT}/${evaluation._id}`,
      types: {
        requestType: creating,
        successTypes: [
          {
            actionOrCreator: fetchEvaluations,
          },
          {
            actionOrCreator: showModal,
            selector: () => {
              dispatch(hideModalWithDelay());
            },
          },
        ],
        errorTypes: [
          {
            actionOrCreator: error,
            selector: (_response: any) => {
              const message = `Error desconocido`;
              dispatch(setMessageError({ message }));
            },
          },
        ],
      },
      method: HttpMethods.PUT,
      microservice: Microservices.EVALUATION_SERVICE,
      authenticated: true,
      data: evaluation,
    })
  );
};

export const enableEvaluationStatus = (
  evaluations: EvaluationUpdateStatusData
) => (dispatch: Dispatch<object>) => {
  return dispatch(
    apiActionCreator({
      endpoint: `${BASE_ENDPOINT}/enable`,
      types: {
        requestType: creating,
        successTypes: [
          {
            actionOrCreator: fetchEvaluations,
          },
          {
            actionOrCreator: setMessageInfo({
              message: "Evaluación(es) activado(s)",
            }),
          },
        ],
        errorTypes: [
          {
            actionOrCreator: error,
          },
          {
            actionOrCreator: fetchEvaluations,
          },
          {
            actionOrCreator: setMessageError({
              message: "Falló la activación",
            }),
          },
        ],
      },
      method: HttpMethods.PUT,
      microservice: Microservices.EVALUATION_SERVICE,
      authenticated: true,
      data: evaluations,
    })
  );
};

export const disableEvaluationsStatus = (
  evaluations: EvaluationUpdateStatusData
) => (dispatch: Dispatch<object>) => {
  return dispatch(
    apiActionCreator({
      endpoint: `${BASE_ENDPOINT}/disable`,
      types: {
        requestType: creating,
        successTypes: [
          {
            actionOrCreator: fetchEvaluations,
          },
          {
            actionOrCreator: setMessageInfo({
              message: "Evaluación(es) desactivado(s)",
            }),
          },
        ],
        errorTypes: [
          {
            actionOrCreator: error,
          },
          {
            actionOrCreator: fetchEvaluations,
          },
          {
            actionOrCreator: setMessageError({
              message: "Falló la desactivación",
            }),
          },
        ],
      },
      method: HttpMethods.PUT,
      microservice: Microservices.EVALUATION_SERVICE,
      authenticated: true,
      data: evaluations,
    })
  );
};

const hideModalWithDelay = (): AppThunk => async (dispatch) => {
  return new Promise((resolve) => {
    setTimeout(() => {
      dispatch(hideModal());
      resolve();
    }, MODAL_DELAY_MS);
  });
};

export const selectEvaluationsDict = (state: RootState) =>
  state.evaluations.dict;
export const selectEvaluations = (state: RootState) => state.evaluations.list;
export const selectQuizzes = (state: RootState) => state.evaluations.quizzes;
export const selectTests = (state: RootState) => state.evaluations.tests;
export const selectExamns = (state: RootState) => state.evaluations.examns;
export const selectSurveys = (state: RootState) => state.evaluations.surveys;
export const selectAreEvaluationFetching = (state: RootState) =>
  state.evaluations.status === FetchStatus.Fetching;
export const selectDisplayModal = (state: RootState) =>
  state.evaluations.modalStatus === ModalStatus.Show;
export const selectQuiz = (id: string) => (state: RootState) =>
  state.evaluations.dict[id];
export const selectEvaluationById = (id:string) => (state: RootState) => {
  return state.evaluations.dict[id] || null;
}

export default evaluationSlice.reducer;
