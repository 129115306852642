import React from "react";
import IcomoonReact from "icomoon-react";
import iconSet from "../../assets/cognizant-font/selection.json";

export const CogIcon: React.FC<{
  color?: string,
  size?: string | number,
  icon: string,
  className?: string,
}> = props => {
  const { color, size = "1em", icon, className = "" } = props;
  return (
    <IcomoonReact
      className={className}
      iconSet={iconSet}
      color={color}
      size={size}
      icon={icon}
    />
  );
};