import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Dispatch } from "react";
import { Microservices } from "../../../app/AllowedMicroservices";
import { RootState } from "../../../app/store";
import apiActionCreator, {
  HttpMethods,
} from "../../../services/apiActionCreator";
import { FetchStatus } from "../../common/enums";

interface CampaignUser {
  id: string;
  surveyTaken: boolean;
}

export interface ReportCampaign {
  id: string;
  companyId: string;
  users: CampaignUser[];
}

interface InitialStateData {
  data: ReportCampaign[];
  status: FetchStatus;
}

const initialState: InitialStateData = {
  data: [],
  status: FetchStatus.Idle,
};

export const dataSlice = createSlice({
  name: "stats",
  initialState,
  reducers: {
    fetching: (state) => {
      state.status = FetchStatus.Fetching;
    },
    fetched: (state, action: PayloadAction<ReportCampaign[]>) => {
      state.data = action.payload;
      state.status = FetchStatus.Fetched;
    },
    error: (state) => {
      state.data = [];
      state.status = FetchStatus.Error;
    },
  },
});

export const { fetching, fetched, error } = dataSlice.actions;

export const fetchCampaignStats = () => (dispatch: Dispatch<object>) => {
  return dispatch(
    apiActionCreator({
      endpoint: "/campaign/stats",
      types: {
        requestType: fetching,
        successTypes: [
          {
            actionOrCreator: fetched,
          },
        ],
        errorTypes: [
          {
            actionOrCreator: error,
          },
        ],
      },
      method: HttpMethods.GET,
      microservice: Microservices.CAMPAIGN_SERVICE,
      authenticated: true,
    })
  );
};

export const selectCampaignStats = (state: RootState) =>
  state.reports.campaign.stats.data;
export const selectCampaignStatsAreFetching = (state: RootState) =>
  state.reports.campaign.stats.status === FetchStatus.Fetching;

export default dataSlice.reducer;
