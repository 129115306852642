import React, { FunctionComponent, useEffect, useState } from "react";
import Table, { ColumnsType } from "antd/lib/table";
import { Status, StatusEnum } from "../../../common/Status";
import moment from "moment";
import { useSelector } from "react-redux";
import { ICompany, selectCompanies, selectCompaniesAreFetching } from "../../../../features/company/companySlice";
import { TileTile } from "../common/TileTitle";
import { selectCompanyTypesAreFetching, selectCompanyTypesDict } from "../../../../features/companyType/companyTypeSlice";
import { Button, Tooltip } from "antd";

import styles from './index.module.scss';
import { getCoutriesDictionary } from "../common/utils";
import { CogIcon } from "../../../common/CogIcon";
import { DataType, exportToExcel, HeadersType } from "../../common/excelExport";
import { Dot } from "../../common/Dot";

const EXCEL_HEADERS: HeadersType = [
  "Empresa",
  "Estado de empresa",
  "Tipo de industria",
  "País",
  "Ciudad",
  "Fecha de inicio de contrato",
  "Notificación SMS",
  "Notificación browser",
  "Notificación E-mail",
  "Usuarios contratados",
  "Usuarios habilitados",
  "Usuarios deshabilitados",
];

interface ICompanyReport extends ICompany {
  countryName: string,
}

interface Props { }
export const CompaniesTable: FunctionComponent<Props> = () => {
  const isFetchingCompanies = useSelector(selectCompaniesAreFetching);
  const isFetchingCompanyTypes = useSelector(selectCompanyTypesAreFetching);
  const allCompanies = useSelector(selectCompanies);
  const companyTypes = useSelector(selectCompanyTypesDict);
  const [companiesData, setCompaniesData] = useState<ICompanyReport[]>([]);
  const dataIsFetching = isFetchingCompanies || isFetchingCompanyTypes;

  useEffect(() => {
    if (dataIsFetching || Object.keys(allCompanies).length === 0) {
      return;
    }

    const countryDataByCode = getCoutriesDictionary();
    const allCompaniesEnhanced: ICompanyReport[] = [];
    for (const companyId in allCompanies) {
      const companyBase = allCompanies[companyId];
      const companyTypeId = companyBase.companyType || '';
      const companyTypeName = (companyTypes[companyTypeId as string] || { name: '' }).name
      allCompaniesEnhanced.push({
        ...companyBase,
        countryName: countryDataByCode[companyBase.country].countryName || '',
        companyTypeName: companyTypeName,
      });
    }
    setCompaniesData(allCompaniesEnhanced);
  }, [dataIsFetching, allCompanies, companyTypes])

  const columns: ColumnsType<ICompanyReport> = [
    {
      width: 300,
      title: 'Empresa',
      dataIndex: 'name',
      sortDirections: ["descend", "ascend"],
      defaultSortOrder: "ascend",
      ellipsis: true,
      sorter: (a: ICompanyReport, b: ICompanyReport) => a.name.localeCompare(b.name),
    },
    {
      width: 100,
      title: 'Estado de empresa',
      dataIndex: 'status',
      render: (status, entry) => {
        return <Status status={status} />
      },
      sortDirections: ["descend", "ascend"],
      sorter: (a: ICompanyReport, b: ICompanyReport) => a.status.localeCompare(b.status)
    },
    {
      width: 150,
      title: 'Tipo de industria',
      dataIndex: 'companyTypeName',
      sortDirections: ["descend", "ascend"],
      sorter: (a: ICompanyReport, b: ICompanyReport) => (a.companyTypeName || '').localeCompare(b.companyTypeName || ''),
    },
    {
      width: 150,
      title: 'País',
      dataIndex: 'countryName',
      sortDirections: ["descend", "ascend"],
      sorter: (a: ICompanyReport, b: ICompanyReport) => a.countryName?.localeCompare(b.countryName),
    },
    {
      width: 150,
      title: 'Ciudad',
      dataIndex: 'city',
      sortDirections: ["descend", "ascend"],
      sorter: (a: ICompanyReport, b: ICompanyReport) => a.city?.localeCompare(b.city),
    },
    {
      width: 150,
      title: 'Fecha de incio de contrato',
      dataIndex: 'contractDate',
      sortDirections: ["descend", "ascend"],
      render: (contractDate) => {
        return moment(contractDate).format("DD-MM-YYYY");
      },
    },
    {
      width: 120,
      title: 'Notificación SMS',
      dataIndex: ['notificationTypesEnabled', 'sms'],
      sortDirections: ["descend", "ascend"],
      sorter: (a: ICompanyReport, b: ICompanyReport) => {
        if (a.notificationTypesEnabled.sms === b.notificationTypesEnabled.sms) {
          return 0
        }
        if (!!a.notificationTypesEnabled.sms > !!b.notificationTypesEnabled.sms) {
          return 1
        }
        return -1;
      },
      render: (enabled) => {
        return enabled ? 'Si' : 'No';
      },
    },
    {
      width: 120,
      title: 'Notificación Browser',
      dataIndex: ['notificationTypesEnabled', 'web'],
      sortDirections: ["descend", "ascend"],
      sorter: (a: ICompanyReport, b: ICompanyReport) => {
        if (a.notificationTypesEnabled.web === b.notificationTypesEnabled.web) {
          return 0
        }
        if (!!a.notificationTypesEnabled.web > !!b.notificationTypesEnabled.web) {
          return 1
        }
        return -1;
      },
      render: (enabled) => {
        return enabled ? 'Si' : 'No';
      },
    },
    {
      width: 120,
      title: 'Notificación E-mail',
      dataIndex: ['notificationTypesEnabled', 'email'],
      sortDirections: ["descend", "ascend"],
      sorter: (a: ICompanyReport, b: ICompanyReport) => {
        if (a.notificationTypesEnabled.email === b.notificationTypesEnabled.email) {
          return 0
        }
        if (!!a.notificationTypesEnabled.email > !!b.notificationTypesEnabled.email) {
          return 1
        }
        return -1;
      },
      render: (enabled) => {
        return enabled ? 'Si' : 'No';
      },
    },
    {
      width: 170,
      title: fulfillmentColumnTitle,
      dataIndex: 'maxActiveStudents',
      sortDirections: ["descend", "ascend"],
      sorter: (a: ICompanyReport, b: ICompanyReport) => b.activeUsersCount - a.activeUsersCount,
      render: (count, entry) => {
        const percentage = (entry.activeUsersCount * 100) / count;
        const color = ((value) => {
          if (value > 90) {
            return '#ec5757'; //red
          }
          if (value > 70 && value <= 90) {
            return '#fbbf21'; //yellow
          }
          return '#44d4a8'; //green
        })(percentage);

        return <Dot value={count} color={color} />
      },
    },
    {
      width: 150,
      title: 'Usuarios habilitados',
      dataIndex: 'activeUsersCount',
      sortDirections: ["descend", "ascend"],
      sorter: (a: ICompanyReport, b: ICompanyReport) => b.activeUsersCount - a.activeUsersCount,
    },
    {
      width: 150,
      title: 'Usuarios deshabilitados',
      dataIndex: 'inactiveUsersCount',
      sortDirections: ["descend", "ascend"],
      sorter: (a: ICompanyReport, b: ICompanyReport) => ((b.inactiveUsersCount || 0) - (a.inactiveUsersCount || 0)),
    }
  ];

  const handleTableChange = () => { }

  const handleDownload = () => {
    const headers = [...EXCEL_HEADERS];
    const data: DataType = companiesData.map(company => {
      const { inactiveUsersCount = 0, notificationTypesEnabled: { sms = false, web = false, email = false } = {} } = company;

      return [
        company.name,
        company.status === StatusEnum.Enable ? 'Habilitado' : 'Deshabilitado',
        company.companyTypeName ?? '',
        company.countryName,
        company.city,
        moment(company.contractDate).format("DD-MM-YYYY"),
        sms ? 'Si' : 'No',
        web ? 'Si' : 'No',
        email ? 'Si' : 'No',
        company.maxActiveStudents,
        company.activeUsersCount,
        inactiveUsersCount
      ]
    });

    const now = moment().format('HH[h]:mm[m]:ss[s]_DD')
    exportToExcel(
      headers,
      data,
      'Compañias',
      `reporte_empresas_${now}`
    )
  }

  return (
    <div className={styles.mainWrapper}>
      <div className={styles.header}>
        <TileTile text='Detalle de empresas' />
        <Button className={styles.downloadButton} onClick={handleDownload} >
          Descargar <CogIcon size="1.4em" color="#81858b" icon={"Download"} />
        </Button>
      </div>
      <Table<ICompanyReport>
        onChange={handleTableChange}
        locale={undefined}
        loading={dataIsFetching}
        rowKey='_id'
        columns={columns}
        dataSource={companiesData}
        pagination={{ position: ["bottomRight"], pageSize: 10 }}
        scroll={{ y: 372, x: 'max-content' }}
      />
    </div>
  )
}

const fulfillmentColumnTitle = () => {
  return <span style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
    Usuarios contratados&nbsp;<Tooltip
      title={<>
        Verde ≤ 70%<br />
        Amarillo Entre 70% y 90%<br />
        Rojo {'>'} 90%
      </>}
      className={styles.tooltip}
    >
      <span>
        <CogIcon size="1.4em" color="#81858b" icon={"Attention"} />
      </span>
    </Tooltip>
  </span>;
}
