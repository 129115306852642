import { Layout } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../../assets/logo-teurona.svg';
import styles from './index.module.scss';

const StudentFooter: React.FunctionComponent = () => (
    <Layout.Footer style={{ padding: 0, background: "#F7F8FA" }} >
        <div className={styles.footerContainer}>
            <img src={logo} alt="teurona" />
            <div className={styles.footerMenu} >
                <Link to="/support">Soporte</Link>
            </div>
        </div>
    </Layout.Footer>
);

export default StudentFooter;