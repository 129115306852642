import { useEffect } from "react";
import { useSelector } from "react-redux";
import { selectCompany } from "../../../features/company/companySlice";
import { analytics } from "../../../services/analytics";

interface AnalyticsViewReportProps {
  companyId?: string,
  reportName: string
}

export const AnalyticsViewReport: React.FunctionComponent<AnalyticsViewReportProps> = ({ companyId = '', reportName }) => {
  const company = useSelector(selectCompany(companyId));
  useEffect(() => {
    analytics.viewReport({ reportName, filter: company });
  }, [company, reportName]);
  return null;
}
