import React, { FunctionComponent } from 'react';
import cls from 'classnames';

import {DayEvents, EventsDict} from './interfaces';

import styles from './day.module.scss';
import { COURSES_PRIORITY } from '../../../../utils/constants';

interface DayProps {
  date: DayEvents|null;
  onSelectDate: () => void,
  selected: boolean
  eventsDict: EventsDict,
}
interface CourseIndicatorProps {
  events: (string|number)[],
  eventsDict: EventsDict,
}

const priorityClasses = {
    [COURSES_PRIORITY.HIGH]: cls(styles.event, styles.high),
    [COURSES_PRIORITY.MEDIUM]: cls(styles.event, styles.medium),
    [COURSES_PRIORITY.LOW]: cls(styles.event, styles.low),
}

const CourseIndicator:FunctionComponent<CourseIndicatorProps> = ({ events, eventsDict }) => {
  if(events.length === 0){
    return null;
  }
  events.sort((a, b) => {
    return eventsDict[a].priority - eventsDict[b].priority;
  });
  return <div className={styles.indicators}>
    {events.map((event, index) => {
      if(!eventsDict[event] || index > 3){
        return  null;
      }
      const color = eventsDict[event].priority;
      return <div className={priorityClasses[color]}>
      </div>;
    })}
  </div>
}
export const Day: FunctionComponent<DayProps> = ({date, onSelectDate, selected, eventsDict}) => {
  return <div className={styles.day} onClick={(e)=>{
    onSelectDate();
    e.stopPropagation();
  }}>
    <div className={cls(styles.innerContainer, {
      [styles.selected]: selected,
      [styles.outOfTheMonth]: date?.outOfTheMonth,
    })}>
      {date?.date.format('D')}
      <CourseIndicator events={date?.events || []} eventsDict={eventsDict} />
    </div>
  </div>;
}