
import React from 'react';
import styles from './studentMain.module.scss';

interface MainLayoutProps {
	children: React.ReactNode;
}

export const StudentMainLayout: React.FunctionComponent<MainLayoutProps> = ({
	children,
}) => {
	return (
		<div className={styles.wrapper}>
			<div className={styles.content}>
				{children}
			</div>
		</div>
	);
}