import { configureStore, ThunkAction, Action, getDefaultMiddleware } from '@reduxjs/toolkit';
import { 
  persistStore, 
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER, 
} from 'redux-persist'
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web

import innerRootReducer from './rootReducer';
import apiMiddleware from './apiMiddleware';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['userMode'] 
}
const persistedReducer = persistReducer(persistConfig, innerRootReducer)

export const store = configureStore({
  reducer: persistedReducer,
  middleware: [apiMiddleware, ...getDefaultMiddleware({
    serializableCheck: {
      // Ignore these action types
      ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
    },
  })]
});

export const persistor = persistStore(store);

if (process.env.NODE_ENV === 'development' && module.hot) {
  module.hot.accept('./rootReducer', () => {
    const newRootReducer = require('./rootReducer').default
    store.replaceReducer(newRootReducer)
  })
}

export type RootState = ReturnType<typeof innerRootReducer>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
