import { Button, Form, Table } from "antd";
import { ColumnsType } from "antd/es/table";
import { FormInstance } from "antd/lib/form";
import moment from "moment";
import { RangeValue } from "rc-picker/es/interface";
import React, { ReactText, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCognitoUserData, selectCompanyId, selectUserRole } from "../../features/auth/authSlice";
import {
  fetchCategories,
  ICategory,
  selectCategories,
  selectCategoriesDict,
} from "../../features/category/categorySlice";
import { FetchStatus } from "../../features/common/enums";
import { selectCompanies } from "../../features/company/companySlice";
import { fetchLessons } from "../../features/lesson/lessonSlice";
import {
  createTopic,
  disableTopicStatus,
  enableTopicStatus,
  fetchTopics,
  hideModal,
  ITopic,
  selectDisplayModal,
  selectTopics,
  selectTopicsFetchStatus,
  selectTopicsIds,
  updateTopic,
} from "../../features/topic/topicSlice";
import { UserRole } from "../../features/users/usersSlice";
import { PAGE_SIZE } from "../../utils/constants";
import { OTHER_CATEGORY_OPTION } from "../common/CategoryField";
import { getDateFilterAndSortProps } from "../common/DateFilter";
import { DialogPopUp } from "../common/DialogPopUp";
import { CogDrawer } from "../common/Drawer";
import { MainLayout } from "../common/Layouts/Main";
import { Status, StatusEnum } from "../common/Status";
import tableStyles from "../common/table.module.scss";
import { TableTools } from "../common/TableTools";
import { CompanyFilter } from "../Filters/Company";
import { useDebounce } from "../utils/useDebounce";
import { TopicForm } from "./Form";

function renderOptions(
  toggleVisible: any,
  setNewTopic: any,
  form: FormInstance,
) {
  return (
    <Button
      onClick={() => {
        toggleVisible();
        setNewTopic({});
        setTimeout(() => {
          form.resetFields();
          form.setFieldsValue({});
        }, 100);
      }}
      type="primary"
    >
      Nuevo tema
    </Button>
  );
}

export const Topic: React.FunctionComponent = () => {
  const categoriesIds = useSelector(selectCategories);
  const categoriesDict = useSelector(selectCategoriesDict);
  const userRole = useSelector(selectUserRole);
  const companyId = useSelector(selectCompanyId);
  const companiesList = useSelector(selectCompanies);
  const [dateFilterValues, setDateFilterValues] = useState<
    RangeValue<moment.Moment>
  >();
  const isTopicsFetching =
    useSelector(selectTopicsFetchStatus) === FetchStatus.Fetching;
  const isTopicCreating =
    useSelector(selectTopicsFetchStatus) === FetchStatus.Creating;
  const topicIdsList = useSelector(selectTopicsIds);
  const topicDict = useSelector(selectTopics);
  const list = topicIdsList.filter(topicId => {
    if(userRole === UserRole.SuperAdmin){
      return true;
    }
    return companyId === topicDict[topicId].companyId;
  }).map((topicId) => ({
    ...topicDict[topicId],
    categoryName: categoriesDict[topicDict[topicId].category as string]?.name,
    companyName: topicDict[topicId].companyId ? companiesList[topicDict[topicId].companyId || '']?.name : 'Todas',
  }));
  const [visible, setVisible] = useState<boolean>(false);
  const [newTopic, setNewTopic] = useState<ITopic>();
  const [selectedRowKeys, selectRowKeys] = useState<ReactText[]>([]);
  const toggleVisible = () => {
    setVisible(!visible);
    setStep(0);
  };
  const dispatch = useDispatch();
  const [filterValue, setFilterValue] = useState<ReactText[]>([]);
  const [filteredInfo, setFilteredInfo] = useState<ReactText[]>([]);
  const debounceFilterValue = useDebounce(filterValue, 500);
  const [showActivationBtns, setShowActivationBtns] = useState<boolean>(false);
  const [step, setStep] = useState<number>(0);
  const [currentAction, setCurrentAction] = useState<string>("");
  const cognitoData = useSelector(getCognitoUserData);
  const isModalVisible = useSelector(selectDisplayModal);
  const role = cognitoData ? cognitoData["custom:role"] : undefined;

  useEffect(() => {
    if (selectedRowKeys.length > 0) {
      setShowActivationBtns(true);
    } else {
      setShowActivationBtns(false);
    }
  }, [selectedRowKeys]);

  const columns: ColumnsType<ITopic> = [
    {
      title: "Nombre",
      dataIndex: "title",
      sortDirections: ["descend", "ascend"],
      defaultSortOrder: "ascend",
      sorter: (a: ITopic, b: ITopic) => a.title.localeCompare(b.title),
      filteredValue: debounceFilterValue,
      onFilter: (value, record) => {
        const categoryName = (record.categoryName ?? "")
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
          .toLowerCase();
        const normalizedValue = value
          .toString()
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
          .toLowerCase();
        return (
          record.title.toLowerCase().includes(value.toString().toLowerCase()) ||
          record._id.toLowerCase().includes(value.toString().toLowerCase()) ||
          categoryName.includes(normalizedValue)
        );
      },
      ellipsis: true,
    },
    {
      title: "ID",
      dataIndex: "shortId",
      sortDirections: ["descend", "ascend"],
      sorter: (a: ITopic, b: ITopic) => a._id.localeCompare(b._id),
    },
    {
      title: "Categoria",
      dataIndex: "categoryName",
      sortDirections: ["descend", "ascend"],
      filteredValue: filteredInfo,
      sorter: (a: ITopic, b: ITopic) => {
        const nameA = a.categoryName ?? "";
        const nameB = b.categoryName ?? "";
        return nameA.localeCompare(nameB);
      },
    },
    {
      title: "Estado",
      dataIndex: "status",
      render: (enabled) => {
        return <Status status={enabled} />;
      },
      sortDirections: ["descend", "ascend"],
      sorter: (a: ITopic, b: ITopic) =>
        a.status === b.status ? 0 : a.status > b.status ? 1 : -1,
    },
    {
      title: "Empresa",
      dataIndex: "companyName",
      sortDirections: ["descend", "ascend"],
      filteredValue: filteredInfo,
      ellipsis: true,
      onFilter: (value, record: ITopic) => record.companyId === (value as string),
      sorter: (a: ITopic, b: ITopic) =>
        (a.companyName || "").localeCompare(b.companyName || ""),
    },
    {
      title: "Fecha",
      dataIndex: "createdAt",
      sortDirections: ["descend", "ascend"],
      ...getDateFilterAndSortProps<ITopic>(
        dateFilterValues,
        setDateFilterValues
      ),
      render: (date, topic) => {
        return (
          <React.Fragment>
            {moment(date).format("DD/MM/YYYY")}
            <div className={tableStyles.actions}>
              <Button
                type="default"
                onClick={() => {
                  setNewTopic(topic);
                  form.resetFields();
                  toggleVisible();
                }}
              >
                Editar
              </Button>
            </div>
          </React.Fragment>
        );
      },
    },
  ];

  useEffect(() => {
    dispatch(fetchTopics());
    dispatch(fetchCategories());
    dispatch(fetchLessons()); // make form ready to work
  }, [dispatch]);

  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedRowKeys: ReactText[]) => {
      selectRowKeys(selectedRowKeys);
    },
  };

  const disabledSaveButton = false;
  const disabledContinueButton = false;

  const enableStatus = () => {
    const data = {
      ids: selectedRowKeys.map((key) => key.toString()),
    };
    dispatch(enableTopicStatus(data));
  };

  const disableStatus = () => {
    const data = {
      ids: selectedRowKeys.map((key) => key.toString()),
    };
    dispatch(disableTopicStatus(data));
  };
  const [form] = Form.useForm();

  const handleCreateOrUpdate = () => {
    form
      .validateFields()
      .then((value) => {
        if (!newTopic) {
          return;
        }
        if (newTopic?.category === OTHER_CATEGORY_OPTION) {
          newTopic.category = {
            name: newTopic.categoryName || "",
          } as ICategory;
        }
        if(userRole !== UserRole.SuperAdmin){
          if(newTopic._id && newTopic.companyId !== companyId){ // don't allow editing topic that don't belong to the company
            return;
          }
          newTopic.companyId = companyId; // force to company in case of creating
        }
        if(!newTopic.companyId){
          newTopic.companyId = undefined;
        }
        if (!newTopic._id) {
          newTopic.status = StatusEnum.Enable;
          setCurrentAction("creado");
          dispatch(createTopic(newTopic));
        } else {
          setCurrentAction("actualizado");
          dispatch(updateTopic(newTopic));
        }
        toggleVisible();
      })
      .catch((error) => {
        console.log("Company:React.FunctionComponent -> error", error);
      });
  };

  const handleContinue = () => {
    form
      .validateFields()
      .then(() => {
        setStep(step + 1);
      })
      .catch((error) => {
        console.log("Topic:React.FunctionComponent -> error", error);
      });
  };

  return (
    <MainLayout
      title="Temas"
      rightTopOptions={renderOptions(toggleVisible, setNewTopic, form)}
    >
      <DialogPopUp
        name="Tema"
        action={currentAction}
        visible={isModalVisible}
        onCancel={() => {
          dispatch(hideModal());
        }}
      />
      <TableTools
        placeholder="Buscar en Teurona"
        onSearch={(value) => setFilterValue([value])}
        onChange={(event) => setFilterValue([event.target.value])}
        onEnable={() => enableStatus()}
        onDisable={() => disableStatus()}
        filter={
          role === UserRole.SuperAdmin ? (
            <CompanyFilter onApply={setFilteredInfo} />
          ) : (
            undefined
          )
        }
        activationBtnsVisible={showActivationBtns}
      />
      <Table<ITopic>
        loading={isTopicsFetching || isTopicCreating}
        className={tableStyles.table}
        rowClassName={() => tableStyles.row}
        rowKey="_id"
        rowSelection={rowSelection}
        columns={columns}
        dataSource={list}
        pagination={{ position: ["bottomRight"], pageSize: PAGE_SIZE }}
      />
      <CogDrawer
        visible={visible}
        toggleVisible={toggleVisible}
        title={newTopic?._id ? "Editar tema" : "Nuevo tema"}
        footer={
          <div>
            <Button onClick={toggleVisible} type="default">
              Cancelar
            </Button>
            &nbsp;&nbsp;
            {step === 0 && (
              <Button
                disabled={disabledContinueButton}
                onClick={handleContinue}
                type="primary"
              >
                Continuar
              </Button>
            )}
            {step === 1 && (
              <Button
                disabled={disabledSaveButton}
                onClick={handleCreateOrUpdate}
                type="primary"
              >
                {newTopic?._id ? "Actualizar tema" : "Crear tema"}
              </Button>
            )}
          </div>
        }
      >
        <div>
          {visible ? (
            <TopicForm
              step={step}
              form={form}
              newTopic={newTopic}
              categories={categoriesDict}
              categoriesIds={categoriesIds}
              setNewTopic={setNewTopic}
              onBack={() => setStep(step - 1)}
            />
          ) : null}
        </div>
      </CogDrawer>
    </MainLayout>
  );
};
