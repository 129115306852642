import { Button } from "antd";
import ctx from "classnames";
import React, { FunctionComponent, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import certificateImg from "../../../assets/certificate.png";
import {
  fetchHashByTemplateId,
  selectCertificateHash,
  selectIsCertificateHashFetching,
} from "../../../features/students/certificateHashSlice";
import {
  fetchStudentCourse,
  selectStudentCourse,
  selectStudentCourseIsFetching,
} from "../../../features/students/courseSlice";
import LoadingOverlay from "../../common/LoadingOverlay";
import styles from "./congratulations.module.scss";

interface StudentCourseAboutInfoProps {}
export const goToCertificate = (hash: string) => {
  return () => {
    window.open(`/public/certificate/${hash}`, "_blank");
  };
};

export const Congratulation: FunctionComponent<StudentCourseAboutInfoProps> =
  () => {
    const isFetching = useSelector(selectStudentCourseIsFetching);
    const { id }: { id: string } = useParams();
    const course = useSelector(selectStudentCourse);
    const isFetchingHash = useSelector(selectIsCertificateHashFetching);
    const hash = useSelector(selectCertificateHash(course?.certificate || ""));
    const history = useHistory();

    const dispatch = useDispatch();

    useEffect(() => {
      if (!course) {
        dispatch(fetchStudentCourse(id));
      }
    }, [dispatch, id, course]);

    useEffect(() => {
      if (course?.certificate) {
        dispatch(fetchHashByTemplateId(course.certificate));
      }
    }, [dispatch, course]);

    if (isFetching || isFetchingHash) {
      return <LoadingOverlay spinning={true} fullscreen />;
    }

    return (
      <div className={ctx(styles.container)}>
        <div className={styles.image}>
          <img src={certificateImg} alt="certificate" />
        </div>
        <div className={styles.header}>¡Felicidades!</div>
        <div className={ctx(styles.header, styles.second)}>
          Ha culminado el curso de:
        </div>
        <div className={styles.courseName}>{course?.title}</div>
        <div className={styles.extraInfo}>
          <p>
            Continua aprendiendo cosas nuevas o repasa los cursos anteriores
            para reforzar tus conocimientos
          </p>
          <p>
            Puedes visualizar tu certificado aqui o en la sección de
            certificados en tu perfil
          </p>
        </div>
        {hash ? (
          <div className={styles.buttonContainer}>
            <Button
              onClick={goToCertificate(hash)}
              className={styles.button}
              type="primary"
            >
              Ver certificado
            </Button>
          </div>
        ) : null}
        <div className={ctx(styles.buttonContainer, styles.second)}>
          <Button
            onClick={() => {
              history.push(`/myCourses`);
            }}
            className={styles.button}
            type="default"
          >
            Volver a mis cursos
          </Button>
        </div>
      </div>
    );
  };
