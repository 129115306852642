import { Spin } from 'antd';
import React from 'react';

interface Props {
    spinning: boolean;
    fullscreen?: boolean;
    inline?: boolean;
    height?:number | string;
}

const LoadingOverlay: React.FunctionComponent<Props> = ({ 
    spinning, 
    fullscreen = false,
    inline = false,
    height: heigth
}) => {
    const styles: any = {
        position: "absolute", top: 0
    }
    const innerStyles: any = {
        height: "100vh", width: "100vw", backgroundColor: "#00000038"
    }
    if(fullscreen){
        styles.position = 'fixed';
        styles.left = 0;
        styles.zIndex = 10000;
    }
    if(inline){
        styles.position = undefined;
        innerStyles.width = '100%';
        if(heigth !== undefined){
            styles.height = heigth;
            innerStyles.height = heigth;
        }
    }
    return <div style={styles} >
    <Spin size="large" spinning={spinning}>
        <div
            style={innerStyles}
        />
    </Spin>
</div>;
}

export default LoadingOverlay;