import React, { FunctionComponent } from 'react';
import { useSelector } from 'react-redux';
import ctx from 'classnames';

import {
    selectStudentCourseDescription
} from '../../../features/students/courseSlice';

import styles from './courseAboutInfo.module.scss';
import { convertToP } from '../../utils/convertions';

interface StudentCourseAboutInfoProps {}

export const StudentCourseAboutInfo: FunctionComponent<StudentCourseAboutInfoProps> = () => {
    const description = useSelector(selectStudentCourseDescription);
    return <div className={ctx(styles.container)}>
        <div className={styles.title}>Sobre el curso</div>
        {convertToP(description, styles.content, styles.p)}
    </div>
}