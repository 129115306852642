import React, {
    FunctionComponent,
  } from "react";
  import { Button, Card } from "antd";
  
  import styles from "./firstPage.module.scss";
  import { useSelector } from "react-redux";
  import {
    selectEvaluation,
  } from "../../../../features/evaluation/currentEvaluationSlice";
  import { CogIcon } from "../../../common/CogIcon";
  import {
    selectStudentCourseTopic
  } from "../../../../features/students/courseSlice";
  
  interface FirstPageProps {
    className?: string;
    topicId: string;
    onNext: Function;
    evaluationId: string;
  }
  
  export const FirstPage: FunctionComponent<FirstPageProps> = ({
    evaluationId,
    topicId,
    onNext,
  }) => {
    const topic = useSelector(selectStudentCourseTopic(topicId));
    const evaluation = useSelector(selectEvaluation(evaluationId));
    const questionsCount = evaluation?.questions?.length || 1;
    const correctAnswersToPass = topic?.correctAnswersToPass || 0;
    const percentageToPass = Math.round(correctAnswersToPass / questionsCount * 100);
    const buttonLabel = 'Comenzar test';
    return <Card className={styles.container}
    actions={[
      <div className={styles.buttonContainer}>
        <Button
          onClick={() => onNext()}
          type="primary"
        >
          {buttonLabel}
        </Button>
      </div>
    ]}>
        <CogIcon className={styles.icon} icon="test-1"/>
        <div className={styles.info}>Para tomar este test toma en cuenta...</div>
        <ul className={styles.infoList}>
            <li>Todas las respuestas son obligatorias</li>
            <li>El porcentaje minimo para aprobar es del {percentageToPass > 100 || percentageToPass === 0 ? '--' : percentageToPass}%</li>
            <li>Podrás mejorar tu calificación las veces que quieras</li>
        </ul>
    </Card>;
  };
  