import React from 'react';
import { Col, Row } from 'antd';

import styles from './section.module.scss';
import { CardItem, CardData } from './CardItem';

interface SectionProps {
  cards: CardData[],
  title: string,
}

export const Section: React.FunctionComponent<SectionProps> = ({ cards, title }) => {
  return (
    <React.Fragment>
      <div className={styles.mainContainer}>
        <div className={styles.title}>
          {title}
        </div>
        <div className={styles.items}>
          <Row gutter={[16, 16]}>
            {cards.map(cardData => {
              return (
                <Col span={8}>
                  <CardItem
                    title={cardData.title}
                    description={cardData.description}
                    logo={cardData.logo}
                    link={cardData.link}
                  />
                </Col>
              );
            })}
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
}