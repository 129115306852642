import cls from "classnames";
import React, { FunctionComponent } from "react";
import { ConditionEnum } from "../../features/common/enums";
import styles from "./status.module.scss";

interface StatusProps {
  status: ConditionEnum;
}

export const Status: FunctionComponent<StatusProps> = ({ status }) => {
  return (
    <div className={styles.status}>
      <div
        className={cls(styles.color, {
          [styles.green]: status === ConditionEnum.Published,
          [styles.red]: status === ConditionEnum.Unpublished,
        })}
      />
      <div className={styles.label}>
        {status === ConditionEnum.Published ? "Publicado" : "No publicado"}
      </div>
    </div>
  );
};
