import ctx from "classnames";
import React, { FunctionComponent, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { ParentTypeEnum } from "../../../features/evaluation/common/enums";
import {
  CurrentItemType,
  selectStudentCourse,
  selectStudentCourseCurrentItem,
} from "../../../features/students/courseSlice";
import {
  fetchEvaluationResults,
  fetchingOneEvaluationResult,
  selectStudentEvaluationResult,
  selectStudentEvaluationResultIsFetching,
} from "../../../features/students/evaluationsResultsSlice";
import { selectEvaluationTitle } from "../../../features/students/evaluationsTitlesSlice";
import { CompletenessIndicator } from "./CompletenessIndicator";
import styles from "./topicTest.module.scss";
import { AnalyticsSelectTest, AnalyticsSelectTestRef } from './index';


interface StudentLessonTopicItemProp {
  className?: string;
  topicId: string;
  evaluationId: string;
  selected: boolean;
}

export const StudentLessonTopicTest: FunctionComponent<StudentLessonTopicItemProp> = ({
  className,
  topicId,
  evaluationId,
  selected,
}) => {
  const currentItem = useSelector(selectStudentCourseCurrentItem);
  const result = useSelector(selectStudentEvaluationResult(evaluationId));
  const fetching = useSelector(
    selectStudentEvaluationResultIsFetching(evaluationId)
  );
  const courseId = useSelector(selectStudentCourse)?._id;
  const title = useSelector(selectEvaluationTitle(evaluationId));
  const history = useHistory();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchingOneEvaluationResult(evaluationId));
    dispatch(
      fetchEvaluationResults(evaluationId, topicId, courseId || '', ParentTypeEnum.Topic)
    );
  }, [dispatch, evaluationId, topicId, courseId]);
  const canGoThere =
    (currentItem.id === evaluationId &&
      currentItem.type === CurrentItemType.Evaluation) ||
    selected ||
    result?.passed;
    const analyticsRef = useRef<AnalyticsSelectTestRef>(null);

  return (
    <React.Fragment>
      <div className={styles.divider} />
      <div
        className={ctx(
          styles.container,
          {
            [styles.cannotGoThere]: !canGoThere,
          },
          className
        )}
      >
        <CompletenessIndicator
          progress={result?.passed ? 1 : 0}
          loading={fetching}
        />
        <div
          className={ctx(styles.title, { [styles.selected]: selected })}
          onClick={() => {
            if (canGoThere) {
              analyticsRef?.current?.trigger();
              history.push(`/course/${courseId}/test/${topicId}`);
            }
          }}
        >
          {title}
        </div>
      </div>
      <AnalyticsSelectTest courseId={courseId || ''} ref={analyticsRef} topicId={topicId || ''} />
    </React.Fragment>
  );
};
