import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Dispatch } from "react";
import { FetchStatus } from "../../common/enums";
import apiActionCreator, {
  HttpMethods,
} from "../../../services/apiActionCreator";
import { Microservices } from "../../../app/AllowedMicroservices";
import { RootState } from "../../../app/store";
import { IRecord, IRecordsAtTimeWindow, EnableDisableProps } from "./insAndOutsCommon";
import { StatusEnum } from "../../../components/common/Status";


interface EntriesAndExitsState {
  enabledCompaniesAtStart: number,
  fetchStatus: FetchStatus,
  enabledCompaniesAtEnd: number,
}

const initialState: EntriesAndExitsState = {
  fetchStatus: FetchStatus.Idle,
  enabledCompaniesAtStart: 0,
  enabledCompaniesAtEnd: 0,
};

interface EnabledAnDisabledFetch {
  atStartDate: IRecord[];
  betweenStartAndEndDates: IRecordsAtTimeWindow;
}

const insAndOutsForChurned = createSlice({
  name: "insAndOutsForChurned",
  initialState,
  reducers: {
    fetching: (state) => {
      state.fetchStatus = FetchStatus.Fetching;
    },
    fetched: (state, action: PayloadAction<EnabledAnDisabledFetch>) => {
      const enabledCompanies:{[id:string]: boolean} = {};
      action.payload.atStartDate.filter(value => value.status === StatusEnum.Enable).forEach(value=>{
        enabledCompanies[value.companyId] = true;
      });
      state.enabledCompaniesAtStart = Object.values(enabledCompanies).length;
      Object.values(action.payload.betweenStartAndEndDates).forEach(current=>{
        const records = current.records.sort(
          (a, b) => +new Date(b.createdAt) - +new Date(a.createdAt)
        );
        if(records.length === 0 || records[0].status !== StatusEnum.Disable){
          return;
        }
        delete enabledCompanies[current.companyId];
      });
      state.enabledCompaniesAtEnd = Object.values(enabledCompanies).length;
      state.fetchStatus = FetchStatus.Fetched;
    },
    fetchFailed: (state) => {
      state.fetchStatus = FetchStatus.Error;
    },
  },
});

export const { fetching, fetched, fetchFailed } = insAndOutsForChurned.actions;

export const fetchDataForChurn =
  ({ yyyyMmDdEndDate, utcOffset }: EnableDisableProps) =>
  (dispatch: Dispatch<object>) => {
    return dispatch(
      apiActionCreator({
        endpoint: `/companies/insAndOuts?endDate=${yyyyMmDdEndDate}&utcOffset=${utcOffset}`,
        types: {
          requestType: fetching,
          successTypes: [
            {
              actionOrCreator: fetched,
            },
          ],
          errorTypes: [
            {
              actionOrCreator: fetchFailed,
            },
          ],
        },
        method: HttpMethods.GET,
        microservice: Microservices.COMPANY_SERVICE,
        authenticated: true,
      })
    );
  };

export const selectChurnedDataIsFetching = (state: RootState) =>
  state.reports.company.insAndOutsNew.forChurned.fetchStatus === FetchStatus.Fetching;
export const selectCompaniesCountAtStart = (state: RootState) =>
  state.reports.company.insAndOutsNew.forChurned.enabledCompaniesAtStart;
export const selectCompaniesCountAtEnd = (state: RootState) =>
  state.reports.company.insAndOutsNew.forChurned.enabledCompaniesAtEnd;

export default insAndOutsForChurned.reducer;
