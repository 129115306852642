import Modal from 'antd/lib/modal/Modal';
import React, { ReactNode } from 'react';
import { CogIcon } from '../CogIcon';
import styles from './index.module.scss';

interface Props {
    title: string;
    message: React.ReactNode;
    footer: ReactNode;
    onCancel(): void;
    onConfirmation(): void;
    headerIcon?: string;
    closable?:boolean
}

const ConfirmationModal: React.FunctionComponent<Props> = ({
    title,
    message,
    footer,
    onCancel,
    onConfirmation,
    headerIcon,
    closable=false,
}) => (
        <Modal
            className={styles.confirmModal}
            visible={true}
            onOk={onConfirmation}
            onCancel={onCancel}
            closable={closable}
            centered={true}
            width={375}
            footer={footer}
        >
            {
                headerIcon ? (
                    <CogIcon size='20px' icon={headerIcon} color="#686F84" />

                ) : null
            }
            {
                title ? <h2>{title}</h2> : null
            }
            {message}
        </Modal>
    );

export default ConfirmationModal;