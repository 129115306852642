import { Button, Dropdown, Menu } from "antd";
import Table, { ColumnsType } from "antd/es/table";
import moment from "moment";
import { RangeValue } from "rc-picker/es/interface";
import React, { ReactText, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectCompanyId, selectUserRole } from '../../features/auth/authSlice';
import { selectCompanies } from "../../features/company/companySlice";
import {
  EvaluationTypeEnum,
  EvaluationTypeLabelEnum,
} from "../../features/evaluation/common/enums";
import { IEvaluation } from "../../features/evaluation/common/interfaces";
import {
  createEvaluation,
  disableEvaluationsStatus,
  enableEvaluationStatus,
  fetchEvaluations,
  hideModal,
  selectAreEvaluationFetching,
  selectDisplayModal,
  selectEvaluations,
  updateEvaluation,
} from "../../features/evaluation/evaluationsSlice";
import { UserRole } from '../../features/users/usersSlice';
import { CogIcon } from "../common/CogIcon";
import ConfirmationModal from "../common/ConfirmationModal";
import { getDateFilterAndSortProps } from "../common/DateFilter";
import { DialogPopUp } from "../common/DialogPopUp";
import { CogDrawer } from "../common/Drawer";
import { MainLayout } from "../common/Layouts/Main";
import { Status, StatusEnum } from "../common/Status";
import { getStatusFilterAndSortProps } from "../common/statusFilter";
import tableStyles from "../common/table.module.scss";
import { TableTools } from "../common/TableTools";
import { useDebounce } from "../utils/useDebounce";
import EvaluationForm, { Values } from "./Form";
import styles from "./form.module.scss";

const PAGE_SIZE = 10;

function renderOptions(menu: React.ReactElement) {
  return (
    <Dropdown overlay={menu} overlayClassName={styles.evaluationMenu}>
      <Button type="primary" className={styles.evaluationButton}>
        Nueva evaluación
        <CogIcon
          className={styles.square}
          size="20px"
          color="#ffffff"
          icon="Arrow-down-big"
        />
      </Button>
    </Dropdown>
  );
}

const emptyState = {
  evaluationTitle: "",
  questions: [
    {
      title: "",
      answers: [
        { title: "", feedback: "" },
        { title: "", feedback: "" },
      ],
      selected: 0,
    },
  ],
};

export const Evaluations: React.FunctionComponent = () => {
  const [action, setAction] = useState("");
  const [evaluationId, setEvaluationId] = useState("");
  const [statusFilter, setStatusFilter] = useState<StatusEnum>();
  const [dateFilterValues, setDateFilterValues] = useState<
    RangeValue<moment.Moment>
  >();
  const [initialValues, setInitialValues] = useState<Values>(emptyState);
  const [selectedEvaluationType, setSelectedEvaluationType] = useState<
    EvaluationTypeEnum | undefined
  >(undefined);
  const [selectedRowKeys, selectRowKeys] = useState<ReactText[]>([]);
  const [filterValue, setFilterValue] = useState<ReactText[]>([]);
  const [currentAction, setCurrentAction] = useState("");
  const [showActivationBtns, setShowActivationBtns] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const userRole = useSelector(selectUserRole);
  const companyId = useSelector(selectCompanyId);
  const companiesList = useSelector(selectCompanies);
  const evaluationList = [...useSelector(selectEvaluations)].filter(evaluation => {
    if(userRole === UserRole.SuperAdmin){
      return true;
    }
    return companyId === evaluation.companyId;
  }).map(
    (evaluation) => ({
      ...evaluation,
      typeName: EvaluationTypeLabelEnum[evaluation.type],
      companyName: evaluation.companyId ? companiesList[evaluation.companyId || '']?.name : 'Todas',
    })
  );
  const areEvaluationsFetching = useSelector(selectAreEvaluationFetching);
  const debounceFilterValue = useDebounce(filterValue, 500);
  const isModalVisible = useSelector(selectDisplayModal);
  const isLoading = useSelector(selectAreEvaluationFetching);
  const dispatch = useDispatch();

  const columns: ColumnsType<IEvaluation> = [
    {
      title: "Titulo",
      dataIndex: "title",
      sortDirections: ["descend", "ascend"],
      defaultSortOrder: "ascend",
      filteredValue: debounceFilterValue,
      ellipsis: true,
      onFilter: (value, record) => {
        return (
          record.title.toLowerCase().includes(value.toString().toLowerCase()) ||
          record.typeName
            .toLowerCase()
            .includes(value.toString().toLowerCase()) ||
          record._id?.toLowerCase().includes(value.toString().toLowerCase())
        );
      },
      sorter: (a: IEvaluation, b: IEvaluation) =>
        a.title.localeCompare(b.title),
    },
    {
      title: "Tipo",
      dataIndex: "typeName",
      sortDirections: ["descend", "ascend"],
      defaultSortOrder: "ascend",
      sorter: (a: IEvaluation, b: IEvaluation) =>
        a.typeName?.localeCompare(b?.typeName ?? "") ?? 0,
    },
    {
      title: "Estado",
      dataIndex: "status",
      sortDirections: ["descend", "ascend"],
      render: (status) => <Status status={status} />,
      ...getStatusFilterAndSortProps<IEvaluation>(statusFilter),
    },
    {
      title: "ID",
      dataIndex: "shortId",
      sortDirections: ["descend", "ascend"],
      defaultSortOrder: "ascend",
      ellipsis: true,
      sorter: (a: IEvaluation, b: IEvaluation) => a._id.localeCompare(b._id),
    },
    {
      title: "Empresa",
      dataIndex: "companyName",
      sortDirections: ["descend", "ascend"],
      ellipsis: true,
      onFilter: (value, record: IEvaluation) => record.companyId === (value as string),
      sorter: (a: IEvaluation, b: IEvaluation) =>
        (a.companyName || "").localeCompare(b.companyName || ""),
    },
    {
      title: "Fecha",
      dataIndex: "createdAt",
      sortDirections: ["descend", "ascend"],
      ...getDateFilterAndSortProps<IEvaluation>(
        dateFilterValues,
        setDateFilterValues
      ),
      render: (createdAt, evaluation) => {
        return (
          <React.Fragment>
            {moment(createdAt).format("DD/MM/YYYY")}
            <div
              className={tableStyles.actions}
              onClick={handleEditEvaluation(evaluation)}
            >
              <Button type="default">Editar</Button>
            </div>
          </React.Fragment>
        );
      },
    },
  ];

  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedRowKeys: ReactText[]) => {
      selectRowKeys(selectedRowKeys);
    },
  };

  const menu = (
    <Menu
      onClick={(e) => {
        const selectedType = e.key as EvaluationTypeEnum;
        const prefix = selectedType === "SURVEY" ? "una" : "un";
        setAction("create");
        setEvaluationId("");
        setInitialValues(emptyState);
        setSelectedEvaluationType(selectedType);
        setCurrentAction(
          `Creaste ${prefix} ${EvaluationTypeLabelEnum[
            selectedType
          ].toLowerCase()}`
        );
      }}
    >
      <Menu.Item key={EvaluationTypeEnum.Quiz}>
        {EvaluationTypeLabelEnum[EvaluationTypeEnum.Quiz]}
      </Menu.Item>
      <Menu.Item key={EvaluationTypeEnum.Test}>
        {EvaluationTypeLabelEnum[EvaluationTypeEnum.Test]}
      </Menu.Item>
      <Menu.Item key={EvaluationTypeEnum.Exam}>
        {EvaluationTypeLabelEnum[EvaluationTypeEnum.Exam]}
      </Menu.Item>
      <Menu.Item key={EvaluationTypeEnum.Survey}>
        {EvaluationTypeLabelEnum[EvaluationTypeEnum.Survey]}
      </Menu.Item>
    </Menu>
  );

  const handleEditEvaluation = (evaluation: IEvaluation) => () => {
    setEvaluationId(evaluation._id);
    const loadValues = {
      companyId: evaluation.companyId || '',
      evaluationTitle: evaluation.title,
      questions: evaluation.questions.map((question) => {
        const foundAnswer = question.possibleAnswers.findIndex(
          (answer) => answer._id === question.correctAnswer
        );
        return {
          title: question.title,
          answers: question.possibleAnswers.map((answer) => ({
            title: answer.label,
            feedback: answer.feedback ?? "",
          })),
          selected: foundAnswer ?? 0,
        };
      }),
    };
    setAction("edit");
    setInitialValues(loadValues);
    setSelectedEvaluationType(evaluation.type);
    const prefix = evaluation.type === "SURVEY" ? "una" : "un";
    setCurrentAction(
      `Editaste ${prefix} ${EvaluationTypeLabelEnum[
        evaluation.type
      ].toLowerCase()}`
    );
  };
  const handleClose = () => {
    setShowConfirmationModal(false);
    setSelectedEvaluationType(undefined);
  };
  const handleOpenConfirmationModal = () => setShowConfirmationModal(true);
  const handleCloseConfirmationModal = () => setShowConfirmationModal(false);
  const handleCreate = (currentEvaluation: IEvaluation) => {
    if(userRole !== UserRole.SuperAdmin){
      if(evaluationId && currentEvaluation.companyId !== companyId){ // don't allow editing courses that don't belong to the company
        return;
      }
      currentEvaluation.companyId = companyId; // force to company in case of creating
    }
    if(!currentEvaluation.companyId){
      currentEvaluation.companyId = undefined;
    }
    evaluationId !== ""
      ? dispatch(updateEvaluation({ ...currentEvaluation, _id: evaluationId }))
      : dispatch(createEvaluation(currentEvaluation));
    setAction("");
  };

  const enableStatus = () => {
    const data = {
      ids: selectedRowKeys.map((key) => key.toString()),
    };
    dispatch(enableEvaluationStatus(data));
  };

  const disableStatus = () => {
    const data = {
      ids: selectedRowKeys.map((key) => key.toString()),
    };
    dispatch(disableEvaluationsStatus(data));
  };

  useEffect(() => {
    if (selectedRowKeys.length > 0) {
      setShowActivationBtns(true);
    } else {
      setShowActivationBtns(false);
    }
  }, [selectedRowKeys]);

  useEffect(() => {
    dispatch(fetchEvaluations());
  }, [dispatch]);

  useEffect(() => {
    if (action === "" && selectedEvaluationType && !isLoading) {
      setSelectedEvaluationType(undefined);
    }
  }, [action, selectedEvaluationType, isLoading]);

  function onChange(_pagination: any, filters: any, _sorter: any, _extra: any) {
    if (filters.status) {
      setStatusFilter(filters.status);
    }
  }
  const prefix =
    evaluationId !== ""
      ? "Editar"
      : selectedEvaluationType !== EvaluationTypeEnum.Survey
      ? "Nuevo"
      : "Nueva";
  return (
    <MainLayout title="Evaluaciones" rightTopOptions={renderOptions(menu)}>
      {showConfirmationModal && (
        <ConfirmationModal
          headerIcon="Help"
          title="¿Seguro quieres salir sin guardar?"
          message={
            <p>Una vez cancelado no podrás recuperar los cambios realizados</p>
          }
          footer={[
            <Button type="text" onClick={handleCloseConfirmationModal}>
              Mantener
            </Button>,
            <Button type="link" onClick={handleClose}>
              Salir
            </Button>,
          ]}
          onCancel={handleCloseConfirmationModal}
          onConfirmation={handleClose}
        />
      )}
      <DialogPopUp
        name="¡Excelente!"
        action={currentAction}
        visible={isModalVisible}
        onCancel={() => {
          dispatch(hideModal());
        }}
      />
      <TableTools
        placeholder="Buscar en Teurona"
        onEnable={() => enableStatus()}
        onDisable={() => disableStatus()}
        onSearch={(value) => setFilterValue([value])}
        onChange={(event) => setFilterValue([event.target.value])}
        activationBtnsVisible={showActivationBtns}
      />
      <Table<IEvaluation>
        onChange={onChange}
        loading={areEvaluationsFetching}
        className={tableStyles.table}
        rowClassName={() => tableStyles.row}
        rowKey="_id"
        rowSelection={rowSelection}
        columns={columns}
        dataSource={evaluationList}
        pagination={{ position: ["bottomRight"], pageSize: PAGE_SIZE }}
      />
      {selectedEvaluationType && (
        <CogDrawer
          title={`${prefix} ${EvaluationTypeLabelEnum[
            selectedEvaluationType
          ].toLowerCase()}`}
          width={"90%"}
          visible={true}
          toggleVisible={handleOpenConfirmationModal}
          loading={isLoading}
        >
          <EvaluationForm
            isLoading={isLoading}
            id={evaluationId}
            type={selectedEvaluationType}
            onClose={handleOpenConfirmationModal}
            onCreate={handleCreate}
            initialValues={initialValues}
          />
        </CogDrawer>
      )}
    </MainLayout>
  );
};
