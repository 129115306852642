import React, { FunctionComponent } from 'react';
import { useSelector } from 'react-redux';
import ctx from 'classnames';
import { Card } from 'antd';

import {
    selectStudentCourseInfo
} from '../../../features/students/courseSlice';

import { CogIcon } from '../../common/CogIcon';
import styles from './courseInfo.module.scss';

interface StudentCourseInfoProps {}
const convertToList = (content: string | undefined) => {
    if(!content){
        return null;
    }
    const rows = content.split("\n");
    return <ul className={styles.list}>
        {rows.map((row, key) => <li className={styles.item} key={key}>{row}</li> )}
    </ul>
}
export const StudentCourseInfo: FunctionComponent<StudentCourseInfoProps> = () => {
    const info = useSelector(selectStudentCourseInfo);
    return <Card className={ctx(styles.card)}>
        <div className={styles.mainTitle}>Guía de estudios</div>
        <div className={styles.title}><CogIcon className={styles.icon} icon="Flag"/> Objetivos del curso</div>
        <div className={styles.content}>{convertToList(info.objectives)}</div>
        <div className={styles.divider}/>
        <div className={styles.title}><CogIcon className={styles.icon} icon="Check"/> Sistema de evaluación</div>
        <div className={styles.content}>{info.evaluationSystem}</div>
        <div className={styles.divider}/>
        <div className={styles.title}><CogIcon className={styles.icon} icon="Attention"/> Información adicional</div>
        <div className={styles.content}>{info.additionalInfo}</div>
    </Card>
}