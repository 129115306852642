import React, { FunctionComponent, ReactNode } from "react";
import { useSelector } from "react-redux";
import { Route, Switch } from "react-router-dom";
import { selectUserRole } from "../../features/auth/authSlice";
import { UserRole } from "../../features/users/usersSlice";
import { CampaignReports } from "./Campaigns";
import { CasesReports } from "./Case";
import { CompanyReports } from "./Company";
import { EducationalResourcesReport } from "./EducationalResources";
import { KnowledgeBaseReports } from "./KnowledgeBase";
import { CoursesReport } from "./Learning/Courses";
import { LessonsReport } from "./Learning/Lessons";
import { TopicsReport } from "./Learning/Topics";
import { UsersReports } from "./Users";

interface PerCategoryProps {
  companyId: string;
}

enum ReportToolTips {
  day = "Información desde el primer día",
  month = "La información contenida en esta sección corresponde a los últimos 30 días, a menos que se indique lo contrario",
}

export enum ReportTypes {
  Users = "users",
  Companies = "companies",
  EducationalContent = "educationalContent",
  Lessons = "lessons",
  Topics = "topics",
  Courses = "courses",
  KnowledgeBase = "knowledgebase",
  Campaigns = "campaign",
  Cases = "Casos Reportados",
}

export const ReportRoles: {
  [reportType: string]: UserRole[];
} = {
  [ReportTypes.Lessons]: [UserRole.SuperAdmin, UserRole.CompanyAdmin, UserRole.Supervisor],
  [ReportTypes.Topics]: [UserRole.SuperAdmin, UserRole.CompanyAdmin, UserRole.Supervisor],
  [ReportTypes.Courses]: [UserRole.SuperAdmin, UserRole.CompanyAdmin, UserRole.Supervisor],
  [ReportTypes.Users]: [UserRole.SuperAdmin, UserRole.CompanyAdmin, UserRole.Supervisor],
  [ReportTypes.Companies]: [UserRole.SuperAdmin],
  [ReportTypes.Campaigns]: [UserRole.SuperAdmin, UserRole.CompanyAdmin, UserRole.Supervisor],
  [ReportTypes.KnowledgeBase]: [UserRole.SuperAdmin, UserRole.CompanyAdmin, UserRole.Supervisor],
  [ReportTypes.EducationalContent]: [UserRole.SuperAdmin],
  [ReportTypes.Cases]: [UserRole.SuperAdmin, UserRole.CompanyAdmin],
};

interface IReportSectionTitles {
  [_id: string]: string;
}

export const BASE_REPORT_URL = "reports/";

export const sectionTooltips: Record<ReportTypes, ReportToolTips> = {
  [ReportTypes.Lessons]: ReportToolTips.day,
  [ReportTypes.Topics]: ReportToolTips.day,
  [ReportTypes.Courses]: ReportToolTips.day,
  [ReportTypes.Users]: ReportToolTips.month,
  [ReportTypes.Companies]: ReportToolTips.month,
  [ReportTypes.Campaigns]: ReportToolTips.day,
  [ReportTypes.KnowledgeBase]: ReportToolTips.month,
  [ReportTypes.EducationalContent]: ReportToolTips.day,
  [ReportTypes.Cases]: ReportToolTips.day,
};

export const menuTitles: Record<ReportTypes, string> = {
  [ReportTypes.Lessons]: "Lecciones",
  [ReportTypes.Topics]: "Temas",
  [ReportTypes.Courses]: "Cursos",
  [ReportTypes.Users]: "Usuarios",
  [ReportTypes.Companies]: "Empresas",
  [ReportTypes.Campaigns]: "Campañas",
  [ReportTypes.KnowledgeBase]: "Bases de conocimiento",
  [ReportTypes.EducationalContent]: "Material educativo",
  [ReportTypes.Cases]: "Casos Reportados",
};

export const sectionTitles: IReportSectionTitles = {
  [ReportTypes.Lessons]: "Lecciones",
  [ReportTypes.Topics]: "Temas",
  [ReportTypes.Courses]: "Cursos",
  [ReportTypes.Users]: "Usuarios",
  [ReportTypes.Companies]: "Empresas",
  [ReportTypes.Campaigns]: "Campañas",
  [ReportTypes.KnowledgeBase]: "Bases de conocimiento",
  [ReportTypes.EducationalContent]: "Material educativo",
  [ReportTypes.Cases]: "Casos",
};

export const ReportsRoutes: FunctionComponent<PerCategoryProps> = ({
  companyId,
}) => {
  const userRole = useSelector(selectUserRole);
  const indexes: { [_id: string]: ReactNode } = {
    [ReportTypes.Users]: <UsersReports companyId={companyId} />,
    [ReportTypes.Lessons]: <LessonsReport companyId={companyId} />,
    [ReportTypes.Topics]: <TopicsReport companyId={companyId} />,
    [ReportTypes.Courses]: <CoursesReport companyId={companyId} />,
    [ReportTypes.Companies]: <CompanyReports />,
    [ReportTypes.Campaigns]: <CampaignReports companyId={companyId} />,
    [ReportTypes.KnowledgeBase]: <KnowledgeBaseReports companyId={companyId} />,
    [ReportTypes.EducationalContent]: <EducationalResourcesReport />,
    [ReportTypes.Cases]: <CasesReports companyId={companyId} />,
  };
  return (
    <Switch>
      {Object.values(ReportTypes)
        .filter(
          (reportType) => userRole && ReportRoles[reportType].includes(userRole)
        )
        .map((reportType) => {
          return (
            <Route
              key={reportType}
              path={`/${BASE_REPORT_URL}${reportType}`}
              render={() => indexes[reportType]}
            />
          );
        })}
    </Switch>
  );
};
