import React, { FunctionComponent } from "react";
import ctx from "classnames";
import { Tooltip } from "antd";

import styles from "./notAvaiblable.module.scss";

interface NotAvailableProps {
  className?: string;
}

export const NotAvailable: FunctionComponent<NotAvailableProps> = ({
  className
}) => {
  return (
    <Tooltip
      overlayClassName={styles.tooltip}
      placement="bottom"
      title="El curso no cumple con los requerimientos para ser visto"
    >
      <div className={ctx(styles.notAvailable, className)}>No disponible</div>
    </Tooltip>
  );
};
