import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Dispatch } from "react";
import { Microservices } from "../../app/AllowedMicroservices";
import { RootState } from "../../app/store";
import apiActionCreator, { HttpMethods } from "../../services/apiActionCreator";
import { FetchStatus } from "../common/enums";

export interface ICertificate {
  showName: boolean;
  showDescription: boolean;
  showTotalHours: boolean;
  showTraineeFullName: boolean;
  showTraineeNationalId: boolean;
  showCompanyName: boolean;
  showNameAndLogo: boolean;
  showUniqueCode: boolean;
  showQrCode: boolean;
  _id: string;
  blueprintId: string;
  courseId: string;
  courseName: string;
  hash: string;
  templateId: string;
  name: string;
  username: string;
  traineeNationalId: string;
  companyLogo: string;
  companyName: string;
  description: string;
  totalHours: number;
  uniqueCode: string;
  updatedAt: string;
  createdAt: string;
  __v: number;
}

interface CertificateState {
  item: ICertificate | undefined;
  status: FetchStatus;
}

const initialState: CertificateState = {
  item: undefined,
  status: FetchStatus.Idle,
};

const cetificateSlice = createSlice({
  name: "cetificate",
  initialState,
  reducers: {
    fetching: (state) => {
      state.status = FetchStatus.Fetching;
    },
    fetchedCertificate: (state, action: PayloadAction<ICertificate>) => {
      state.item = {
        ...action.payload,
        description: action.payload.description.substring(0, 500),
      };
      state.status = FetchStatus.Fetched;
    },
    error: (state) => {
      state.status = FetchStatus.Error;
    },
  },
});

const { fetching, error, fetchedCertificate } = cetificateSlice.actions;

export const fetchCertificate =
  (hash: string) => (dispatch: Dispatch<object>) => {
    return dispatch(
      apiActionCreator({
        endpoint: `/certificate/getData/${hash}`,
        types: {
          requestType: fetching,
          successTypes: [
            {
              actionOrCreator: fetchedCertificate,
            },
          ],
          errorTypes: [
            {
              actionOrCreator: error,
            },
          ],
        },
        method: HttpMethods.GET,
        microservice: Microservices.EVALUATION_SERVICE,
      })
    );
  };

export const selectIsCertificateFetching = (state: RootState) =>
  state.certificate.status === FetchStatus.Fetching;
export const selectCertificate = () => (state: RootState) =>
  state.certificate.item;

export default cetificateSlice.reducer;
