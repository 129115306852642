import { ISelectValue } from "../Users/Form";

export const getUniqueNamesCities = (cities:any): ISelectValue[] => {
  const uniqueCities:any = {};
  cities.default.forEach((city: any) => {
    uniqueCities[city.name] ={
      value: city.name,
      label: city.name,
    };
  });
  return Object.values(uniqueCities);
}