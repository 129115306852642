import { Button, Table, Tooltip } from "antd";
import { ColumnsType } from "antd/es/table";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CampaignSurveyTaken } from "..";
import { ICampaign } from "../../../../features/campaign/campaignSlice";
import {
  selectCompanies,
  selectCompaniesAreFetching,
} from "../../../../features/company/companySlice";
import { EvaluationTypeEnum } from "../../../../features/evaluation/common/enums";
import { IEvaluation } from "../../../../features/evaluation/common/interfaces";
import { selectAreEvaluationFetching } from "../../../../features/evaluation/evaluationsSlice";
import {
  fetchGroups,
  selectGroupsDict,
} from "../../../../features/groups/groupSlice";
import { ReportCampaign } from "../../../../features/reports/Campaigns/stats";
import {
  fetchEvaluationsResultsWithResponses,
  selectEvaluationsResultsWithResponses,
} from "../../../../features/reports/learning/evaluations/evaluationsResultsWithResponses";
import {
  fetchStudents,
  selectStudents,
} from "../../../../features/users/usersSlice";
import { CogIcon } from "../../../common/CogIcon";
import { Status, StatusEnum } from "../../../common/Status";
import tableStyles from "../../../common/table.module.scss";
import { EmptyTable } from "../../common/EmptyTable";
import { DataType, exportToExcel, HeadersType } from "../../common/excelExport";
import { circleScore } from "../../Learning/common/ContentTable/ColumnsPerType";
import styles from "./index.module.scss";

export const fulfillmentColumnTitle = () => {
  return (
    <div className={styles.surveyColumn}>
      <span className={styles.columntitle}>Completación de encuesta</span>
      <Tooltip
        title={
          <>
            Verde = 100%
            <br />
            Amarillo Entre 50% y 100%
            <br />
            Rojo {"<"} 50%
          </>
        }
        className={styles.tooltip}
      >
        <span>
          <CogIcon size="1.4em" color="#81858b" icon={"Attention"} />
        </span>
      </Tooltip>
    </div>
  );
};

const EXCEL_HEADERS: HeadersType = [
  "Nombre",
  "Estado",
  "Campaña",
  "Empresa",
  "Usuario",
  "Grupo",
  "Fecha de completación",
];

interface Props {
  surveys: IEvaluation[];
  campaigns: ICampaign[];
  campaignStats: ReportCampaign[];
  campaignsWithSurveyPercentage: CampaignSurveyTaken[];
  companyId?: string;
}

export const CampaignsTable: React.FunctionComponent<Props> = ({
  companyId,
  surveys,
  campaigns,
  campaignStats,
  campaignsWithSurveyPercentage,
}) => {
  const [isDownloading, setIsDownloading] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const students = useSelector(selectStudents);
  const groups = useSelector(selectGroupsDict);
  const companyDict = useSelector(selectCompanies);
  const areEvaluationsFetching = useSelector(selectAreEvaluationFetching);
  const areCompaniesFetching = useSelector(selectCompaniesAreFetching);
  const evaluationsResultsWithResponses = useSelector(
    selectEvaluationsResultsWithResponses
  );
  const dispatch = useDispatch();

  useEffect(() => {
    if (companyId) {
      dispatch(fetchStudents());
      dispatch(fetchGroups());
    }
  }, [dispatch, companyId]);

  useEffect(() => {
    if (!evaluationsResultsWithResponses.evaluation || !isProcessing) {
      return;
    }
    const headers = [...EXCEL_HEADERS];
    const {
      questions = [],
      title,
      _id,
    } = evaluationsResultsWithResponses.evaluation;
    questions.forEach((question) => {
      headers.push(question.title);
    });
    const data: DataType = [];
    evaluationsResultsWithResponses.results.forEach((resultWithResponses) => {
      const { updatedAt, userId } = resultWithResponses;
      const user = students[userId];
      const group = groups[user.studentGroupId || ""];
      const currentSurvey = surveys.find((item) => item._id === _id);
      const currentCampaign = campaigns.find((item) => item.survey === _id);
      const row: string[] = [
        title,
        currentSurvey?.status === StatusEnum.Enable
          ? "Publicado"
          : "No publicado",
        currentCampaign?.title ?? "",
        companyDict[currentCampaign?.companyId ?? ""]?.name ?? "",
        user.name,
        group.name,
        moment(updatedAt).format("DD-MM-YYYY"),
      ];
      resultWithResponses.answers.forEach((answer) => {
        const question = questions.find(
          (question) => question._id === answer.questionId
        );
        if (!question) {
          row.push("--");
          return;
        }
        const possibleAnswer = question.possibleAnswers.find(
          (possibleAnswer) => possibleAnswer._id === answer.answerId
        );
        if (!possibleAnswer) {
          row.push("--");
          return;
        }
        row.push(possibleAnswer.label);
      });
      data.push(row);
    });
    exportToExcel(headers, data, "Encuesta_", `${title}`);
    setIsProcessing(false);
    setIsDownloading(false);
  }, [
    evaluationsResultsWithResponses,
    groups,
    students,
    campaigns,
    companyDict,
    surveys,
    isProcessing,
  ]);

  const handleDownloadSurveyResult = async (campaign: CampaignSurveyTaken) => {
    setIsDownloading(true);
    await dispatch(
      fetchEvaluationsResultsWithResponses({
        usersIds:
          campaignStats
            .find((item) => item.id === campaign.id)
            ?.users.map((user) => user.id) ?? [],
        parentId: campaign.id,
        evaluationId: campaign.surveyId,
        evaluationType: EvaluationTypeEnum.Survey,
      })
    );
    setIsProcessing(true);
  };

  const columns: ColumnsType<CampaignSurveyTaken> = [
    {
      title: "Título de Campaña",
      dataIndex: "name",
      sortDirections: ["descend", "ascend"],
      defaultSortOrder: "ascend",
      ellipsis: true,
      sorter: (a: CampaignSurveyTaken, b: CampaignSurveyTaken) =>
        a.name.localeCompare(b.name),
    },
    {
      title: "Empresa",
      dataIndex: "companyName",
      sortDirections: ["descend", "ascend"],
      defaultSortOrder: "ascend",
      ellipsis: true,
      sorter: (a: CampaignSurveyTaken, b: CampaignSurveyTaken) =>
        a.companyName.localeCompare(b.companyName),
    },
    {
      title: "Estado de campaña",
      dataIndex: "status",
      render: (status) => {
        return <Status status={status} />;
      },
      sortDirections: ["descend", "ascend"],
      sorter: (a: CampaignSurveyTaken, b: CampaignSurveyTaken) =>
        a.status === b.status ? 0 : a.status > b.status ? 1 : -1,
    },
    {
      title: "Nombre de encuesta",
      dataIndex: "survey",
      sortDirections: ["descend", "ascend"],
      defaultSortOrder: "ascend",
      ellipsis: true,
      sorter: (a: CampaignSurveyTaken, b: CampaignSurveyTaken) =>
        a.survey.localeCompare(b.survey),
    },
    {
      title: fulfillmentColumnTitle,
      dataIndex: "percentage",
      sortDirections: ["descend", "ascend"],
      defaultSortOrder: "ascend",
      ellipsis: true,
      sorter: (a: CampaignSurveyTaken, b: CampaignSurveyTaken) =>
        a.percentage - b.percentage,
      render: (value: number, campaign) => (
        <>
          <div>
            {circleScore(value)} {Math.round(value)}%
          </div>
          {value > 0 && (
            <div className={tableStyles.actions}>
              <Button
                loading={isDownloading}
                disabled={isDownloading}
                type="default"
                onClick={() => handleDownloadSurveyResult(campaign)}
              >
                Descargar
              </Button>
            </div>
          )}
        </>
      ),
    },
  ];

  return (
    <>
      <div className={styles.title}>Detalle de completación de encuestas</div>
      <Table<any>
        loading={areEvaluationsFetching || areCompaniesFetching}
        locale={
          !companyId
            ? {
                emptyText: <EmptyTable />,
              }
            : undefined
        }
        className={tableStyles.table}
        rowClassName={() => tableStyles.row}
        rowKey="id"
        columns={columns}
        dataSource={companyId ? campaignsWithSurveyPercentage : []}
        pagination={{ position: ["bottomRight"], pageSize: 10 }}
        scroll={{ y: 372 }}
      />
    </>
  );
};
