import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Dispatch } from "react";
import { Microservices } from "../../../../app/AllowedMicroservices";
import { RootState } from "../../../../app/store";
import apiActionCreator, {
  HttpMethods,
} from "../../../../services/apiActionCreator";
import { FetchStatus } from "../../../common/enums";
import { DataState, sortReportValue } from "../../common";

interface IResponseItem {
  userType: string;
  status: string;
  count: number | string;
}
const initialState: DataState = {
  data: [],
  status: FetchStatus.Idle,
};

export const dataSlice = createSlice({
  name: "topicsCompletenessByRole",
  initialState,
  reducers: {
    fetching: (state) => {
      state.status = FetchStatus.Fetching;
    },
    fetched: (state, action: PayloadAction<IResponseItem[]>) => {
      state.data = action.payload.map((item) => {
        return {
          key: {
            status: item.status,
            other: item.userType,
          },
          label: "",
          value: item.count,
        };
      });
      state.data.sort(sortReportValue);
      state.status = FetchStatus.Fetched;
    },
    error: (state) => {
      state.data = [];
      state.status = FetchStatus.Error;
    },
  },
});

export const { fetching, fetched, error } = dataSlice.actions;

export const fetchTopicsCompletenessByRole = () => (
  dispatch: Dispatch<object>
) => {
  let endpoint = `/topics/completenessByRole`;
  return dispatch(
    apiActionCreator({
      endpoint,
      types: {
        requestType: fetching,
        successTypes: [
          {
            actionOrCreator: fetched,
          },
        ],
        errorTypes: [
          {
            actionOrCreator: error,
          },
        ],
      },
      method: HttpMethods.GET,
      microservice: Microservices.LEARNING_SERVICE,
      authenticated: true,
    })
  );
};

export const selectTopicsCompletenessByCRoleData = (state: RootState) =>
  state.reports.learning.topicsCompletenessByRole.data;
export const selectTopicsCompletenessByRoleIsFetching = (state: RootState) =>
  state.reports.learning.topicsCompletenessByRole.status ===
  FetchStatus.Fetching;

export default dataSlice.reducer;
