import { Divider, Space } from "antd";
import queryString from "query-string";
import React, { FunctionComponent, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import notFoundImage from "../../../assets/modalIllustrations/notfound.png";
import { fetchCategories } from "../../../features/category/categorySlice";
import {
  fetchStudentMyCourses,
  selectAreStudentMyCoursesFetching,
  selectStudentMyCourse,
  selectStudentMyCoursesFilteredKeys,
  selectStudentMyCoursesFilteredKeysCount,
} from "../../../features/students/myCoursesSlice";
import { StudentMainLayout } from "../../common/Layouts/StudentMainLayout";
import LoadingOverlay from "../../common/LoadingOverlay";
import { OneColumnLayout } from "../common/OneColumnLayout";
import styles from "./coursesFiltered.module.scss";
import { MyCoursesBlock } from "./MyCoursesBlock";
import { MyCourseSearch } from "./MyCourseSearch";

const TAG_PARAM_NAME = "tag";
const CATEGORY_ID_PARAM_NAME = "category";
const TEXT_TO_SEARCH_PARAM_NAME = "textToSearch";

export const CoursesFiltered: FunctionComponent<{}> = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const isFetching = useSelector(selectAreStudentMyCoursesFetching);
  const queryParamsObj: any = queryString.parse(location.search);
  const tagsFromUrl = queryParamsObj[TAG_PARAM_NAME] || [];
  const tags = Array.isArray(tagsFromUrl) ? tagsFromUrl : [tagsFromUrl];
  const categoryId = queryParamsObj[CATEGORY_ID_PARAM_NAME] || "";
  const textToSearch = queryParamsObj[TEXT_TO_SEARCH_PARAM_NAME] || "";

  const coursesCount = useSelector(
    selectStudentMyCoursesFilteredKeysCount(
      { tags: [...tags], categoryId: categoryId },
      { text: textToSearch }
    )
  );

  useEffect(() => {
    dispatch(fetchStudentMyCourses());
    dispatch(fetchCategories());
  }, [dispatch]);
  let content;
  if (isFetching) {
    content = <LoadingOverlay fullscreen spinning />;
  } else if (coursesCount > 0) {
    content = (
      <div>
        <MyCoursesBlock
          title="Cursos encontrados"
          individualSelector={selectStudentMyCourse}
          selector={selectStudentMyCoursesFilteredKeys(
            { tags: [...tags], categoryId: categoryId },
            { text: textToSearch }
          )}
          emptyMessage="No hay ningún curso en esta sección"
          />
      </div>
    );
  } else {
    content = (
      <div className={styles.notResultContainer}>
        <img className={styles.image} src={notFoundImage} alt="success" />
        <p className={styles.notFoundText}>
          No hemos encontrado coincidencias con tu búsqueda
        </p>
      </div>
    );
  }

  return (
    <StudentMainLayout>
      <OneColumnLayout>
        <MyCourseSearch />
        <Space style={{ width: "100%" }} size={80} direction="vertical">
          <div>
            <Divider />
            {content}
          </div>
        </Space>
      </OneColumnLayout>
    </StudentMainLayout>
  );
};
