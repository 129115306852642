import { Button, Dropdown } from "antd";
import moment from "moment";
import React, { FunctionComponent, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import noNotificationImage from "../../assets/notification.png";
import {
  fetchStudentNotifications,
  INotification,
  selectStudentNotificationsIsFetching,
  selectStudentUreadNotifications,
} from "../../features/students/notifications";
import { CogIcon } from "../common/CogIcon";
import LoadingOverlay from "../common/LoadingOverlay";
import { NotificationsTypes } from "../Notifications/Manager";
import styles from "./notificationMenu.module.scss";

interface NotificationMenuProps {}
interface MenuProps {
  close: () => void;
}

export const getNotificationIcon = (notification: INotification) => {
  if (notification.type === NotificationsTypes.PUSH) {
    return "politicas";
  }
  if (notification.type === NotificationsTypes.SMS) {
    return "sms";
  }
  return "Mail";
};

const Menu: FunctionComponent<MenuProps> = ({ close }) => {
  const fetching = useSelector(selectStudentNotificationsIsFetching);
  const notifications = useSelector(selectStudentUreadNotifications);
  const history = useHistory();
  if (fetching) {
    return (
      <div className={styles.menuContainer}>
        <LoadingOverlay inline height={300} spinning />
      </div>
    );
  }
  const goToAllNotifications = (
    <div className={styles.goToAll}>
      <div className={styles.divider}></div>
      <div className={styles.buttonContainer}>
        <Button
          onClick={() => {
            history.push("/all/notifications");
            close();
          }}
          className={styles.button}
          type="link"
        >
          Ver todas
        </Button>
      </div>
    </div>
  );
  if (notifications.length === 0) {
    return (
      <div className={styles.menuContainer}>
        <div className={styles.imageContainer}>
          <img src={noNotificationImage} alt="no notifications" />
        </div>
        <div className={styles.noNotificationsText}>
          No hay notificaciones nuevas
        </div>
        {goToAllNotifications}
      </div>
    );
  }
  return (
    <div className={styles.menuContainer}>
      <div className={styles.menuTitle}>Notificiones</div>
      <div className={styles.subTitle}>Nuevas</div>
      <div className={styles.notifications}>
        {notifications.map((notification) => {
          return (
            <div key={notification._id} className={styles.notification}>
              <div className={styles.iconContainer}>
                <div className={styles.background}>
                  <CogIcon
                    className={styles.icon}
                    icon={getNotificationIcon(notification)}
                  />
                </div>
              </div>
              <div className={styles.content}>
                <div
                  className={styles.title}
                  title={notification.title || notification.body}
                >
                  {notification.title || notification.body}
                </div>
                <div className={styles.date}>
                  {moment(
                    notification.scheduledDateTime || notification.createdAt
                  ).format("DD/MM/Y")}
                </div>
              </div>
            </div>
          );
        })}
      </div>
      {goToAllNotifications}
    </div>
  );
};
export const NotificationMenu: FunctionComponent<NotificationMenuProps> =
  () => {
    const [open, setOpen] = useState<boolean>(false);
    const dispatch = useDispatch();
    const notifications = useSelector(selectStudentUreadNotifications);

    useEffect(() => {
      dispatch(fetchStudentNotifications());
    }, [dispatch]);

    const menu = (
      <Menu
        close={() => {
          setOpen(false);
        }}
      />
    );

    return (
      <Dropdown
        visible={open}
        onVisibleChange={(visible) => {
          setOpen(visible);
        }}
        overlay={menu}
        trigger={["click"]}
        placement="bottomCenter"
      >
        <div className={styles.container}>
          <CogIcon className={styles.icon} icon="Notifications" />
          {notifications.length === 0 ? null : (
            <div className={styles.counter}>{notifications.length}</div>
          )}
        </div>
      </Dropdown>
    );
  };
