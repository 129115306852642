import React, { FunctionComponent } from 'react';
import cls from 'classnames';
import footerStyles from './Footer.module.scss';

interface FooterProps {
  title: string,
  linkLabel: string,
  linkRef: string,
  className?: string,
}

export const Footer: FunctionComponent<FooterProps> = ({title, linkLabel, linkRef}) => 
  <div className={cls(footerStyles.GrayBackground)}>
    <div>{title}</div>
    <a
      className={cls()}
      href={linkRef}
      rel="noopener noreferrer"
    >
      {linkLabel}
    </a>
  </div>
