import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Dispatch } from "react";
import { Microservices } from "../../app/AllowedMicroservices";
import { RootState } from "../../app/store";
import apiActionCreator, { HttpMethods } from "../../services/apiActionCreator";
import { ICampaign } from "../campaign/campaignSlice";
import { FetchStatus, ModalStatus } from "../common/enums";

interface CampaignState {
  list: ICampaign[];
  status: FetchStatus;
  modalStatus: ModalStatus;
}

const initialState: CampaignState = {
  list: [],
  status: FetchStatus.Idle,
  modalStatus: ModalStatus.Hide,
};

interface CampaignFetch {
  docs: ICampaign[];
  limit: number;
  page: number;
  pages: number;
  total: number;
}

export const campaignSlice = createSlice({
  name: "student-campaign",
  initialState,
  reducers: {
    fetching: (state) => {
      state.status = FetchStatus.Fetching;
    },
    fetched: (state, action: PayloadAction<CampaignFetch>) => {
      state.list = action.payload.docs;
      state.status = FetchStatus.Fetched;
    },
    error: (state, _action: PayloadAction<CampaignFetch>) => {
      state.list = [];
      state.status = FetchStatus.Error;
    },
  },
});

const BASE_ENDPOINT = "/students";
const { fetching, fetched, error } = campaignSlice.actions;

export const fetchHomeCampaigns = () => (dispatch: Dispatch<object>) => {
  return dispatch(
    apiActionCreator({
      endpoint: `${BASE_ENDPOINT}/home?limit=1000000`,
      types: {
        requestType: fetching,
        successTypes: [
          {
            actionOrCreator: fetched,
          },
        ],
        errorTypes: [
          {
            actionOrCreator: error,
          },
        ],
      },
      method: HttpMethods.GET,
      microservice: Microservices.CAMPAIGN_SERVICE,
      authenticated: true,
    })
  );
};

export const fetchCampaignById = (id: string) => (
  dispatch: Dispatch<object>
) => {
  return dispatch(
    apiActionCreator({
      endpoint: BASE_ENDPOINT,
      types: {
        requestType: fetching,
        successTypes: [
          {
            actionOrCreator: fetched,
          },
        ],
        errorTypes: [
          {
            actionOrCreator: error,
          },
        ],
      },
      method: HttpMethods.POST,
      microservice: Microservices.CAMPAIGN_SERVICE,
      authenticated: true,
    })
  );
};

export const selectCampaigns = (state: RootState) =>
  state.studentCampaigns.list;
export const selectAreCampaignsFetching = (state: RootState) =>
  state.studentCampaigns.status === FetchStatus.Fetching;

export default campaignSlice.reducer;
