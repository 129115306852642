import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Dispatch } from "react";
import { Microservices } from "../../app/AllowedMicroservices";
import { RootState } from "../../app/store";
import apiActionCreator, { HttpMethods } from "../../services/apiActionCreator";
import { FetchStatus, ModalStatus } from "../common/enums";

interface CertificateHashListFetchItem {
  courseName: string;
  hash: string;
  _id: string;
}

interface CertificateHashState {
  list: CertificateHashListFetchItem[];
  status: FetchStatus;
  modalStatus: ModalStatus;
}

const initialState: CertificateHashState = {
  list: [],
  status: FetchStatus.Idle,
  modalStatus: ModalStatus.Hide,
};

export const certificateHashSlice = createSlice({
  name: "certificateHashSlice",
  initialState,
  reducers: {
    fetching: (state) => {
      state.status = FetchStatus.Fetching;
      state.list = [];
    },
    fetched: (state, action: PayloadAction<CertificateHashListFetchItem[]>) => {
      state.list = [...(Array.isArray(action.payload) ? action.payload : [action.payload])];
      state.status = FetchStatus.Fetched;
    },
    error: (state, _action: PayloadAction<string>) => {
      state.status = FetchStatus.Error;
    },
  },
});

const BASE_ENDPOINT = "/certificate";
const { fetching, fetched, error } = certificateHashSlice.actions;

export const fetchCertificatesList = () => (dispatch: Dispatch<object>) => {
  return dispatch(
    apiActionCreator({
      endpoint: `${BASE_ENDPOINT}/list`,
      types: {
        requestType: fetching,
        successTypes: [
          {
            actionOrCreator: fetched,
          },
        ],
        errorTypes: [
          {
            actionOrCreator: error,
          },
        ],
      },
      method: HttpMethods.GET,
      microservice: Microservices.EVALUATION_SERVICE,
      authenticated: true,
    })
  );
};

export const selectCertificateList = (state: RootState) =>
  state.certificateList.list;
export const selectIsCertificateListFetching = (state: RootState) =>
  state.certificateList.status === FetchStatus.Fetching;

export default certificateHashSlice.reducer;
