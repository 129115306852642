import React, { useState, FunctionComponent, useEffect } from 'react';
import { Form, Col, Row, Button, Input, Select } from 'antd';
import cls from 'classnames';
import phoneValidator from "phone";

import { Header } from '../Header';
import { ISignUpStepProps, layout } from './interfaces';
import { UploadImage } from '../../common/UploadImage';
import { Countries, ISelectValue, MIN_3_CHAR } from '../../Users/Form';
import { getUniqueNamesCities } from '../../common/cities.utils';

import styles from './index.module.scss';
import commonStyles from '../Login.module.scss';

interface Step2Props extends ISignUpStepProps {

};

export const Step4: FunctionComponent<Step2Props> = ({ next, userData }) => {
  const [picture, setPicture] = useState<string>('');
  const [country, setCountry] = useState<string>('');
  const [city, setCity] = useState<string>('');
  const [address, setAddress] = useState<string>('');
  const [phone_number, setPhone] = useState<string>('');
  const [loadingImage, setLoadingImage] = useState<boolean>(false);
  const [cities, setCities] = useState<ISelectValue[]>([]);
  useEffect(() => {
  }, []);
  const handleSubmit = () => {
    form.validateFields().then(()=>{
      next({
        address,
        city,
        country,
        phone_number,
        picture
      });
    });
  };
  const handleChange = (info: any) => {
    if (info.file.status === "uploading") {
      setLoadingImage(true);
      return;
    }
    if (info.file.status === "done") {
      setLoadingImage(false);
      setPicture(info.file.response.url);
    }
  };
  useEffect(() => {
    setCountry(userData.email || '');
    setCountry(userData.email || '');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const [form] = Form.useForm();
  return (
    <Form
      {...layout}
      form={form}
      name="basic"
      onFinish={() => {
        handleSubmit();
      }}
      onFinishFailed={() => {
        console.log('signup failed');
      }}
      
    >
      <Col
        span={24}
        className={cls(commonStyles.formInnerContainer, commonStyles.overload)}
      >
        <Row>
          <Col span={18} offset={3}>
            <Header title={'Solo un paso más'}>
              <div className={styles.subTitles}>
              No te preocupes, esta información la usaremos solo como datos de registro.
              </div>
            </Header>
            <Form.Item
              name="picture"
              initialValue={picture}
              wrapperCol={{ sm: 24 }}
            >
              <UploadImage
                text="Cargar foto"
                loading={loadingImage}
                imageUrl={picture}
                onHandleChange={handleChange}
              />
            </Form.Item>
            <Form.Item
              label="País"
              name="country"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              initialValue={country}
              rules={[
                { type: "string", required: true, message: "Selecciona un país" },
              ]}
            >
              <Select
                placeholder="Selecciona un país"
                onChange={async (value) => {
                  setCountry(value as string);
                  setCity('');
                  const countryCities:any = await import(`../../../assets/countries/cities/${value}.json`);
                  const cities:ISelectValue[] = getUniqueNamesCities(countryCities);
                  setCities(cities);
                }}
              >
                {Countries.map((item) => (
                  <Select.Option key={item.value} value={item.value}>
                    {item.label}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              label="Ciudad"
              name="city"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              initialValue={city}
              rules={[
                { type: "string", required: true, message: "Selecciona una ciudad" },
              ]}
            >
              <Select
                placeholder="Selecciona una ciudad"
                onChange={(value) => {
                  setCity(value as string);
                }}
              >
                {cities.map((item) => (
                  <Select.Option key={item.value} value={item.value}>
                    {item.label}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              label="Dirección"
              name="address"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              initialValue={address}
              rules={[
                { type: "string", required: true, message: "Requerido" },
                { type: "string", max: 200, message: "Máximo 200 caracteres" },
                { type: "string", min: 3, message: MIN_3_CHAR },
              ]}
            >
              <Input.TextArea
                onChange={(event) => {
                  setAddress(event.target.value);
                }}
              />
            </Form.Item>
            <Form.Item
              label="Número telefónico"
              name="phone_number"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              initialValue={phone_number}
              rules={[
                (obj) => ({
                  validator: async (rules, value) => {
                    const phone = phoneValidator(value);
                    if (phone.length === 0) {
                      throw new Error(
                        "El telefono debe ser válido (debe tener el codigo de país (ej: +593)"
                      );
                    }
                  },
                }),
              ]}
            >
              <Input
                placeholder="Número de celular"
                onChange={(event) => {
                  setPhone(event.target.value);
                }}
              />
            </Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              disabled={false}
              className={cls(commonStyles.signupBtn, styles.signupBtn2)}
            >
              Listo
            </Button>
          </Col>
        </Row>
      </Col>
    </Form>
  );
};
