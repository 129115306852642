import React, { FunctionComponent } from 'react';
import ctx from 'classnames';

import styles from './chip.module.scss';

interface CategoryChipProp {
    categoryName: string,
    className?: string
}

export const CategoryChip: FunctionComponent<CategoryChipProp> = ({ categoryName, className }) => {
    return <span className={ctx(styles.chip, className)}>{categoryName}</span>;
};