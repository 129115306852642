import React, { FunctionComponent } from "react";
import styles from './tileTitle.module.scss';

interface TitleTitleProps {
  text: string,
}
export const TileTile: FunctionComponent<TitleTitleProps> = ({text= ''}) => {
  return (
    <div className={styles.title}>{text}</div>
  );
}
