import { Button } from "antd";
import React, { ReactNode, useEffect, forwardRef, useImperativeHandle, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  fetchCertificate,
  ICertificate,
  selectCertificate,
  selectIsCertificateFetching,
} from "../../features/students/certificateSlice";
import LoadingOverlay from "../common/LoadingOverlay";
import styles from "./index.module.scss";
import Template1, { createPDF } from "./Template1";
import Template2, { createPDF as createPDF2 } from "./Template2";
import Template3, { createPDF as createPDF3 } from "./Template3";
import Template4, { createPDF as createPDF4 } from "./Template4";
import { analytics } from '../../services/analytics';
import { getCognitoUserData } from '../../features/auth/authSlice';

interface ICertificateWithBlueprint {
  blueprint: ReactNode;
  print(name: string, props: ICertificate): void;
}

interface RouteParams {
  hash: string;
}

const Certificate: React.FunctionComponent = () => {
  const { hash } = useParams<RouteParams>();
  const certificateFromStore = useSelector(selectCertificate());
  const isFetching = useSelector(selectIsCertificateFetching);
  const dispatch = useDispatch();
  const analyticsRef = useRef<AnalyticsDownloadRef>(null);

  useEffect(() => {
    if (hash) {
      dispatch(fetchCertificate(hash));
    }
  }, [dispatch, hash]);

  if (isFetching || !certificateFromStore) {
    return <LoadingOverlay spinning={true} />;
  }

  const TEMPLATES: Record<string, ICertificateWithBlueprint> = {
    template1: {
      blueprint: <Template1 {...certificateFromStore} />,
      print: (name, props) => createPDF(name, props),
    },
    template2: {
      blueprint: <Template2 {...certificateFromStore} />,
      print: (name, props) => createPDF2(name, props),
    },
    template3: {
      blueprint: <Template3 {...certificateFromStore} />,
      print: (name, props) => createPDF3(name, props),
    },
    template4: {
      blueprint: <Template4 {...certificateFromStore} />,
      print: (name, props) => createPDF4(name, props),
    },
  };

  return (
    <article className={styles.mainContainer}>
      <header className={styles.certificateHeader}>
        <Button
          onClick={() => {
            TEMPLATES[
              certificateFromStore.blueprintId
            ].print(certificateFromStore.hash, { ...certificateFromStore })
            analyticsRef?.current?.trigger();
          }}
        >
          Descargar Certificado
        </Button>
      </header>
      <div className={styles.certificateContainer}>
        {TEMPLATES[certificateFromStore.blueprintId].blueprint}
      </div>
      <AnalyticsDownloadCertificate ref={analyticsRef}
        courseName={certificateFromStore.courseName}
        programName={null}
        companyName={certificateFromStore.companyName}
        groupName={null}
      />

    </article>
  );
};

type AnalyticsDownloadRef = {
  trigger: () => void,
}
interface AnalyticsDownloadCertProps {
  courseName: string,
  programName: string | null,
  companyName: string,
  groupName: string | null, //TODO: ? group
}

const AnalyticsDownloadCertificate = forwardRef<AnalyticsDownloadRef, AnalyticsDownloadCertProps>(({ courseName, programName, companyName, groupName }, ref) => {
  useImperativeHandle(ref, () => ({
      trigger: () => {
          analytics.downloadCertificate({
              userName: cognitoUsername,
              courseTitle: courseName,
              programTitle: programName,
              companyName: companyName,
              groupName: groupName,
          })
      }
  }));
  const cognitoUserData = useSelector(getCognitoUserData);
  const cognitoUsername = cognitoUserData ? cognitoUserData['cognito:username'] : null;
  return null;
});

export default Certificate;
