import Tooltip from 'antd/es/tooltip';
import moment from 'moment';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PreCreatedCompanies, selectCompanies, selectCompaniesAreFetching, selectCompaniesIds } from '../../../../features/company/companySlice';
import { PIECHART_COLORS } from '../../../../features/reports/common';
import {
  fetchDataForChurn,
  selectChurnedDataIsFetching,
  selectCompaniesCountAtStart,
  selectCompaniesCountAtEnd
} from '../../../../features/reports/company/insAndOutsForChurned';
import { CogIcon } from '../../../common/CogIcon';
import LoadingOverlay from '../../../common/LoadingOverlay';

import { RoundedContainer } from '../../../common/RoundedContainer';
import { StatusEnum } from '../../../common/Status';
import { StatData, StatsRow } from '../../common/StatsRow';

import styles from './index.module.scss';

interface ChurnedAndLicensesProps { }
export const ChurnedAndLicenses: FunctionComponent<ChurnedAndLicensesProps> = () => {
  const dispatch = useDispatch();
  const churnedDataIsFetching = useSelector(selectChurnedDataIsFetching);
  const companiesCountAtStart = useSelector(selectCompaniesCountAtStart);
  const companiesCountAtEnd = useSelector(selectCompaniesCountAtEnd);
  const [churnedPercentage, setChurnedPercentage] = useState<number>(0);

  const [licensesUsagePercentage, setLicensesUsagePercentage] = useState<number>(-1);
  const isFetchingCompanies = useSelector(selectCompaniesAreFetching);
  const companyIdsList = useSelector(selectCompaniesIds);
  const companyDict = useSelector(selectCompanies);
  const companyList = companyIdsList.map((companyId) => ({
    ...companyDict[companyId],
  }));

  const dataIsFetching = churnedDataIsFetching || isFetchingCompanies;

  useEffect(() => {
    const today = moment();
    dispatch(fetchDataForChurn({ yyyyMmDdEndDate: today.format('YYYY-MM-DD'), utcOffset: today.utcOffset() }));
  }, [dispatch])
  useEffect(() => {
    if (dataIsFetching) {
      return;
    }
    const enabledCompanies = companyList.filter(company => 
      company.status === StatusEnum.Enable && !PreCreatedCompanies.includes(company._id || '')
    );
    const results = enabledCompanies.reduce((acc, current) => {
      acc.activeUsersTotalCount += current.activeUsersCount;
      acc.maxActiveStudentsTotalCount += current.maxActiveStudents;
      return acc;
    }, { maxActiveStudentsTotalCount: 0, activeUsersTotalCount: 0 });

    setLicensesUsagePercentage(100 * results.activeUsersTotalCount / results.maxActiveStudentsTotalCount);
  }, [companyList, dataIsFetching]);
  useEffect(() => {
    if (dataIsFetching) {
      return;
    }
    const churnedCompaniesCount = (companiesCountAtStart - companiesCountAtEnd) / (companiesCountAtStart ? companiesCountAtStart : 1) * 100;

    setChurnedPercentage(churnedCompaniesCount);
  }, [companiesCountAtEnd, companiesCountAtStart, dataIsFetching]
  );

  const churnTooltipText = 'Mide la cantidad de empresas deshabilitadas, en relación a las empresas habilitadads en el mismo período.';

  const statsData: StatData = [
    {
      percentValue: (Math.round(licensesUsagePercentage * 100) / 100).toFixed(2),
      description: <div className={styles.licenseUsage}>de uso de licencias</div>,
      canvasHeight: 100,
      type: '2',
      data: {
        labels: [
          'Usados',
          'Sin uso',
        ],
        datasets: [{
          label: 'Uso de licencias',
          data: [licensesUsagePercentage, 100 - licensesUsagePercentage],
          backgroundColor: [
            PIECHART_COLORS[1],
            PIECHART_COLORS[0],
          ],
          hoverOffset: 4
        }],
      },
    },
    {
      percentValue: Number.parseFloat(churnedPercentage.toPrecision(2)).toFixed(2),
      description: <div className={styles.churnedDescription}>
        <div className={styles.rate}>
          Churn rate&nbsp;
          <Tooltip placement='bottomRight' title={churnTooltipText}>
            <div><CogIcon size="1.4em" color="#81858b" icon={'Attention'} /></div>
          </Tooltip>
        </div>
        <div className={styles.description}>
          Tasa de cancelación de clientes
        </div>
      </div>,
      canvasHeight: 100,
      type: '2',
      data: {
        labels: [
          'Churned',
          'Total',
        ],
        datasets: [{
          label: 'Tasa de cancelación',
          data: [churnedPercentage, 100 - churnedPercentage],
          backgroundColor: [
            PIECHART_COLORS[2],
            PIECHART_COLORS[3],
          ],
          hoverOffset: 4
        }],
      },
    }
  ]

  return (
    <RoundedContainer className={styles.mainWrapper} >
      {
        dataIsFetching ?
          <LoadingOverlay spinning inline height={185} />
          :
          <StatsRow statsData={statsData} />
      }
    </RoundedContainer>
  );
}