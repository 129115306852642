import React, { FunctionComponent } from 'react';
import { useSelector } from 'react-redux';
import { selectBreadcrumbs } from '../../../features/breadcrumbs/breadcrumbsSlice';
import { Breadcrumb } from 'antd';

import styles from './index.module.scss';
import { Link } from 'react-router-dom';

interface CategoryFieldProps {
}

export const Breadcrumbs: FunctionComponent<CategoryFieldProps> = () => {
    const breadcrumbs = useSelector(selectBreadcrumbs);
    if(breadcrumbs.length === 0){
        return null;
    }
    return <div className={styles.container}>
        <Breadcrumb className={styles.breadcrumbs}>
        {breadcrumbs.map((crumb, key)=>{
            return <Breadcrumb.Item key={key}>
                {crumb.url ? 
                <Link to={crumb.url}>{crumb.label}</Link>:
                crumb.label
            }
          </Breadcrumb.Item>
        })}
        </Breadcrumb>
    </div>
};