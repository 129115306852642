import { Tooltip } from "antd";
import ctx from "classnames";
import React, {
  FunctionComponent,
  ReactText,
  useEffect,
  useState,
} from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { getCognitoUserData } from "../../features/auth/authSlice";
import { UserRole } from "../../features/users/usersSlice";
import { CogIcon } from "../common/CogIcon";
import { MainLayout } from "../common/Layouts/Main";
import LoadingOverlay from "../common/LoadingOverlay";
import { CompanyFilter } from "../Filters/Company";
import styles from "./index.module.scss";
import {
  BASE_REPORT_URL,
  ReportsRoutes,
  ReportTypes,
  sectionTitles,
  sectionTooltips,
} from "./MenuAndRoutes";

export const Reports: FunctionComponent = () => {
  const [filteredInfo, setFilteredInfo] = useState<ReactText[]>([]);
  const [reportType, setReportType] = useState<string>();
  const [shouldReset, setShouldReset] = useState(false);
  const cognitoData = useSelector(getCognitoUserData);
  const role = cognitoData ? cognitoData["custom:role"] : undefined;
  const location = useLocation();

  useEffect(() => {
    setShouldReset(true);
    setFilteredInfo([]);
    setReportType(
      Object.keys(sectionTitles).find((reportType) => {
        return (
          location.pathname.indexOf(`/${BASE_REPORT_URL}${reportType}`) === 0
        );
      })
    );
  }, [location.pathname]);

  useEffect(() => {
    if (shouldReset) {
      setShouldReset(false);
    }
  }, [shouldReset]);

  if (!reportType) {
    return <LoadingOverlay spinning={true} />;
  }

  return (
    <MainLayout
      title={sectionTitles[reportType]}
      rightTopOptions={
        <div className={styles.optionsContainer}>
          <Tooltip
            placement="topLeft"
            title={sectionTooltips[reportType as ReportTypes]}
            className={styles.tooltip}
          >
            <div>
              <CogIcon size="1.4em" color="#81858b" icon={"Attention"} />
            </div>
          </Tooltip>
          {role === UserRole.SuperAdmin &&
            (reportType !== ReportTypes.EducationalContent && reportType !== ReportTypes.Companies) && (
              <CompanyFilter
                onApply={setFilteredInfo}
                type="report"
                reset={shouldReset}
              />
            )}
        </div>
      }
      backgroundColor="#f7f8fa"
      titleContainerClass={styles.report}
    >
      <div className={ctx(styles.container)}>
        <ReportsRoutes companyId={filteredInfo[0]?.toString() ?? ""} />
      </div>
    </MainLayout>
  );
};
