import fileDownload from 'js-file-download';
import moment from 'moment';
import replace from 'lodash/replace';

export type HeadersType = string[];
export type DataType = (string|number)[][];

const Excel = require('exceljs/dist/es5/exceljs.browser.js');

export const exportToExcel = async (headers: HeadersType, data: DataType, sheetName: string, filename: string) => {
  const workbook = new Excel.Workbook();
  const sheet = workbook.addWorksheet(sheetName);
  const row = sheet.addRow(headers);
  headers.forEach((_, index) => {
    row.getCell(index + 1).font = {
      color: { argb: '00FFFFFF' }
    }
    row.getCell(index + 1).fill = {
      type: 'pattern',
      pattern:'solid',
      fgColor:{ argb:'88000000' }
    };
    sheet.getColumn(index + 1).width = 25;
  });
  data.forEach(row=>{
    sheet.addRow(row);
  });
  ;
  const file = await workbook.xlsx.writeBuffer();
  let fileName = `${filename}_${moment(new Date()).format(
    'MMM YYYY'
  )}.xlsx`;
  fileName = replace(fileName, / /g, '_');
  fileDownload(file, fileName);
}