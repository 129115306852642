import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../../../app/store";
import { ICompanyContent, IGroupContent } from '../../../../components/Reports/Learning/common/interfaces';

interface IReportsLearningCompanyContent {
  data: ICompanyContent,
  dataByGroups: IGroupContent,
  fetching: boolean,
}
const initialState:IReportsLearningCompanyContent = {
  data: {},
  dataByGroups: {},
  fetching: false,
};
interface ISetCompaniesAndGroupsContents {
  byCompany: ICompanyContent,
  byGroups: IGroupContent,
}

export const dataSlice = createSlice({
  name: "reportsLearningCompanyContent",
  initialState,
  reducers: {
    setFetching: (state) => {
      state.data = {};
      state.fetching = true;
    },
    setCompaniesContents: (state, action: PayloadAction<ISetCompaniesAndGroupsContents>) => {
      state.data = action.payload.byCompany;
      state.dataByGroups = action.payload.byGroups;
      state.fetching = false;
    },
  },
});

export const { setFetching: setCompanyContentFetching, setCompaniesContents } = dataSlice.actions;

export const selectCompaniesContents = (state: RootState) =>
  state.reports.learning.common.companiesContent.data;
  export const selectGroupsContents = (state: RootState) =>
  state.reports.learning.common.companiesContent.dataByGroups;
export const selectCompaniesContentsAreFetching = (state: RootState) =>
  state.reports.learning.common.companiesContent.fetching;
export default dataSlice.reducer;
