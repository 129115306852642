import { buildShortIds, IWithShortId } from './../common/shortId';
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Dispatch } from "react";
import { Microservices } from "../../app/AllowedMicroservices";
import { AppThunk, RootState } from "../../app/store";
import { StatusEnum } from "../../components/common/Status";
import apiActionCreator, { HttpMethods } from "../../services/apiActionCreator";
import { MODAL_DELAY_MS } from "../../utils/constants";
import { ICategory } from "../category/categorySlice";
import { FetchStatus, ModalStatus } from "../common/enums";
import { setMessageError, setMessageInfo } from "../message/messageSlice";

export interface TopicsLessonsSelectedDisabled {
  [_id: string]: boolean;
}

export interface ICourse extends IWithShortId {
  _id: string;
  title: string;
  description: string;
  category: string | ICategory;
  categoryName?: string;
  tags: string[];
  durationHour: number;
  imageurl: string;
  objectives: string;
  evaluationSystem: string;
  additionalInfo: string;
  topics: string[];
  topicsLessonsDisabled: TopicsLessonsSelectedDisabled;
  certificate?: string | null;
  status: StatusEnum;
  createdAt: Date;
  evaluation?: string;
  correctAnswersToPass?: number;
  timeToRetry?: number;
  survey?: string;
  companyId?: string;
  companyName?: string;
}

export interface ICourseDict {
  [_id: string]: ICourse;
}

interface CourseState {
  list: string[];
  dict: ICourseDict;
  status: FetchStatus;
  modalStatus: ModalStatus;
}

const initialState: CourseState = {
  list: [],
  dict: {},
  status: FetchStatus.Idle,
  modalStatus: ModalStatus.Hide,
};

interface CoursesFetch {
  docs: ICourse[];
  limit: number;
  page: number;
  pages: number;
  total: number;
}

interface CourseUpdateStatusData {
  ids: string[];
}

export const coursesSlice = createSlice({
  name: "courses",
  initialState,
  reducers: {
    creating: (state) => {
      state.status = FetchStatus.Creating;
    },
    fetching: (state) => {
      state.status = FetchStatus.Fetching;
    },
    fetchedCourses: (state, action: PayloadAction<CoursesFetch>) => {
      action.payload.docs = buildShortIds(action.payload.docs) as ICourse[];
      state.list = action.payload.docs.map((company) => company._id);
      state.dict = {};
      action.payload.docs.forEach((company) => {
        state.dict[company._id] = company;
      });
      state.status = FetchStatus.Fetched;
    },
    fetchedCourse: (state, action: PayloadAction<ICourse>) => {
      if (!state.list.includes(action.payload._id)) {
        state.list.push(action.payload._id);
      }
      state.dict[action.payload._id] = action.payload;
      state.status = FetchStatus.Fetched;
    },
    error: (state, _action: PayloadAction<CoursesFetch>) => {
      state.list = [];
      state.status = FetchStatus.Error;
    },
    showModal: (state) => {
      state.modalStatus = ModalStatus.Show;
    },
    hideModal: (state) => {
      state.modalStatus = ModalStatus.Hide;
    },
  },
});

const hideModalWithDelay = (): AppThunk => async (dispatch) => {
  return new Promise((resolve) => {
    setTimeout(() => {
      dispatch(hideModal());
      resolve();
    }, MODAL_DELAY_MS);
  });
};

const { showModal } = coursesSlice.actions;
export const {
  fetching,
  fetchedCourses,
  fetchedCourse,
  error,
  creating,
  hideModal,
} = coursesSlice.actions;
export const createCourse = (newcourse: ICourse) => (
  dispatch: Dispatch<object>
) => {
  return dispatch(
    apiActionCreator({
      endpoint: "/courses",
      types: {
        requestType: creating,
        successTypes: [
          {
            actionOrCreator: fetchCourses,
          },
          {
            actionOrCreator: showModal,
            selector: () => {
              dispatch(hideModalWithDelay());
            },
          },
        ],
        errorTypes: [
          {
            actionOrCreator: error,
          },
        ],
      },
      method: HttpMethods.POST,
      microservice: Microservices.LEARNING_SERVICE,
      authenticated: true,
      data: newcourse,
    })
  );
};

export const updateCourse = (course: ICourse) => (
  dispatch: Dispatch<object>
) => {
  return dispatch(
    apiActionCreator({
      endpoint: `/courses/${course._id}`,
      types: {
        requestType: creating,
        successTypes: [
          {
            actionOrCreator: fetchCourses,
          },
          {
            actionOrCreator: showModal,
            selector: () => {
              dispatch(hideModalWithDelay());
            },
          },
        ],
        errorTypes: [
          {
            actionOrCreator: error,
          },
        ],
      },
      method: HttpMethods.PUT,
      microservice: Microservices.LEARNING_SERVICE,
      authenticated: true,
      data: course,
    })
  );
};

export const fetchCourses = () => (dispatch: Dispatch<object>) => {
  return dispatch(
    apiActionCreator({
      endpoint: "/courses?limit=1000000",
      types: {
        requestType: fetching,
        successTypes: [
          {
            actionOrCreator: fetchedCourses,
          },
        ],
        errorTypes: [
          {
            actionOrCreator: error,
          },
        ],
      },
      method: HttpMethods.GET,
      microservice: Microservices.LEARNING_SERVICE,
      authenticated: true,
    })
  );
};
export const fetchCourse = (courseId: string) => (
  dispatch: Dispatch<object>
) => {
  return dispatch(
    apiActionCreator({
      endpoint: `/courses/${courseId}`,
      types: {
        requestType: fetching,
        successTypes: [
          {
            actionOrCreator: fetchedCourse,
          },
        ],
        errorTypes: [
          {
            actionOrCreator: error,
          },
        ],
      },
      method: HttpMethods.GET,
      microservice: Microservices.LEARNING_SERVICE,
      authenticated: true,
    })
  );
};

export const enableCoursesStatus = (courses: CourseUpdateStatusData) => (
  dispatch: Dispatch<object>
) => {
  return dispatch(
    apiActionCreator({
      endpoint: "/courses/enable",
      types: {
        requestType: creating,
        successTypes: [
          {
            actionOrCreator: fetchCourses,
          },
          {
            actionOrCreator: setMessageInfo({
              message: "Curso(s) activado(s)",
            }),
          },
        ],
        errorTypes: [
          {
            actionOrCreator: error,
            selector: (response: any) => {
              if (
                response.data &&
                response.data.statusCode === 409 &&
                response.data.data === "CanBeEnabled"
              ) {
                const message = "Algunos cursos no están completos";
                dispatch(setMessageError({ message }));
                return;
              }
              dispatch(setMessageError({ message: "Falló la activación" }));
            },
          },
          {
            actionOrCreator: fetchCourses,
          },
        ],
      },
      method: HttpMethods.PUT,
      microservice: Microservices.LEARNING_SERVICE,
      authenticated: true,
      data: courses,
    })
  );
};

export const disableCoursesStatus = (courses: CourseUpdateStatusData) => (
  dispatch: Dispatch<object>
) => {
  return dispatch(
    apiActionCreator({
      endpoint: "/courses/disable",
      types: {
        requestType: creating,
        successTypes: [
          {
            actionOrCreator: fetchCourses,
          },
          {
            actionOrCreator: setMessageInfo({
              message: "Curso(s) desactivado(s)",
            }),
          },
        ],
        errorTypes: [
          {
            actionOrCreator: error,
          },
          {
            actionOrCreator: fetchCourses,
          },
          {
            actionOrCreator: setMessageError({
              message: "Falló la desactivación",
            }),
          },
        ],
      },
      method: HttpMethods.PUT,
      microservice: Microservices.LEARNING_SERVICE,
      authenticated: true,
      data: courses,
    })
  );
};

export const selectCourses = (state: RootState) => state.courses.list;
export const selectCoursesDict = (state: RootState) => state.courses.dict;
export const selectCourse = (id: string | null) => (state: RootState) =>
  id ? state.courses.dict[id] : null;
export const selectAreCoursesFetching = (state: RootState) =>
  state.courses.status === FetchStatus.Fetching;
export const selectCoursesStatus = (state: RootState) => {
    const status: {
      [id:string]: StatusEnum
    } = {};
    Object.values(state.courses.dict).forEach((course) => {
      status[course._id || ''] = course.status || StatusEnum.Disable;
    });
    return status;
  };

export default coursesSlice.reducer;
