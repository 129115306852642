import React from 'react';
import {
  Card,
} from 'antd';
import cls from 'classnames';
import './searchOverrides.scss';
import styles from './knowledgeBaseSummary.module.scss';
import Paragraph from 'antd/lib/typography/Paragraph';

interface SummaryProps {
  title: string,
  description: string,
  category: string,
  lastUpdate: string,
}

export const KnowledgeBaseSummary: React.FC<SummaryProps> = ({
  title,
  description,
  category,
  lastUpdate,
}) => {
  return (
    <Card className={cls(styles.summaryCard, styles.border)}>
      <div className={styles.title}>
        {title}
      </div>
      <div className={styles.description}>
        <Paragraph ellipsis={{ rows: 3, expandable: false }}>
          {description}
        </Paragraph>
      </div>
      <div className={styles.meta}>
        <span>{category}</span>&nbsp;•&nbsp;<span>{lastUpdate}</span>
      </div>
    </Card>
  );
}
