import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Dispatch } from "react";
import { Microservices } from "../../../../app/AllowedMicroservices";
import { RootState } from "../../../../app/store";
import apiActionCreator, {
  HttpMethods,
} from "../../../../services/apiActionCreator";
import { FetchStatus } from "../../../common/enums";
import { DataState, IReportValue, sortReportValue } from "../../common";

const initialState: DataState = {
  data: [],
  status: FetchStatus.Idle,
};

export const dataSlice = createSlice({
  name: "topicsByCategory",
  initialState,
  reducers: {
    fetching: (state) => {
      state.status = FetchStatus.Fetching;
    },
    fetched: (state, action: PayloadAction<IReportValue[]>) => {
      state.data = action.payload;
      state.data.sort(sortReportValue);
      state.status = FetchStatus.Fetched;
    },
    error: (state) => {
      state.data = [];
      state.status = FetchStatus.Error;
    },
  },
});

export const { fetching, fetched, error } = dataSlice.actions;

export const fetchTopicsByCategory = (dispatch: Dispatch<object>) => {
  return dispatch(
    apiActionCreator({
      endpoint: "/topics/byCategory",
      types: {
        requestType: fetching,
        successTypes: [
          {
            actionOrCreator: fetched,
          },
        ],
        errorTypes: [
          {
            actionOrCreator: error,
          },
        ],
      },
      method: HttpMethods.GET,
      microservice: Microservices.LEARNING_SERVICE,
      authenticated: true,
    })
  );
};

export const selectTopicsByCategoryData = (state: RootState) =>
  state.reports.learning.topicsByCategory.data;
export const selectTopicsByCategoryIsFetching = (state: RootState) =>
  state.reports.learning.topicsByCategory.status === FetchStatus.Fetching;

export default dataSlice.reducer;
