import { Button, Card, Layout, Space } from "antd";
import ctx from "classnames";
import React,
{
  FunctionComponent,
  useEffect,
  useState,
  forwardRef,
  useImperativeHandle,
  useRef
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { FetchStatus } from "../../../../features/common/enums";
import { ParentTypeEnum } from "../../../../features/evaluation/common/enums";
import {
  IAnswer,
  IQuestion,
  QuestionsAnswers,
} from "../../../../features/evaluation/common/interfaces";
import {
  fetchEvaluation,
  registerEvaluationResult,
  selectIsEvaluationFetching,
  selectEvaluation,
  selectEvaluationFetchStatus,
} from "../../../../features/evaluation/currentEvaluationSlice";
import { setMessageError } from "../../../../features/message/messageSlice";
import {
  fetchStudentCourse,
  makeProgressStudentCourse,
  selectStudentCourse,
  selectStudentCourseIsFetching,
  TOPIC_TEST,
} from "../../../../features/students/courseSlice";
import {
  fetchExamResult,
  selectIsStudentExamResultFetching,
  selectStudentExamResult,
} from "../../../../features/students/examResultSlice";
import { CogIcon } from "../../../common/CogIcon";
import { CogDrawer } from "../../../common/Drawer";
import { StudentMainLayout } from "../../../common/Layouts/StudentMainLayout";
import LoadingOverlay from "../../../common/LoadingOverlay";
import { SIZES } from "../../../common/screen";
import { useDeviceSize } from "../../../utils/useDeviceSize";
import { TwoColumnLayout } from "../../common/TwoColumnLayout";
import { StudentLessonCourseInfo } from "../../Lesson/CourseInfo";
import { StudentLessonTopics } from "../../Lesson/Topics";
import { QuestionPage } from "../QuestionPage";
import { FirstPage } from "./FirstPage";
import styles from "./index.module.scss";
import { LastPage } from "./LastPage";
import { analytics } from "../../../../services/analytics";
import { getCognitoUserData } from '../../../../features/auth/authSlice';
import { selectUser } from '../../../../features/users/usersSlice';
import {
  fetchPrograms,
  selectPrograms,
  selectProgramsDict,
} from '../../../../features/program/programSlice';
import { selectCompany } from '../../../../features/company/companySlice';
import { selectGroup } from "../../../../features/groups/groupSlice";

interface ExamProps {
  questionToShow?: number;
}

export const Exam: FunctionComponent<ExamProps> = ({ questionToShow = -1 }) => {
  const [timerIsActive, setTimerIsActive] = useState<boolean>(false);
  const [answersSelected, setAnswerSelected] = useState<QuestionsAnswers>({});
  const [currentQuestion, setCurrentQuestion] = useState<number>(
    questionToShow
  );
  const { id: courseId }: { id: string } = useParams();
  const course = useSelector(selectStudentCourse);
  const evaluationId = course?.evaluation;
  const courseIsFetching = useSelector(selectStudentCourseIsFetching);
  const evaluation = useSelector(selectEvaluation(evaluationId || ''));
  const areEvaluationsFetching = useSelector(selectIsEvaluationFetching);
  const evaluationFetchStatus = useSelector(selectEvaluationFetchStatus(evaluationId || ''));
  const examResult = useSelector(selectStudentExamResult);
  const examResultIsFetching = useSelector(selectIsStudentExamResultFetching);
  const history = useHistory();
  const dispatch = useDispatch();
  const size = useDeviceSize();
  const [isDrawerVisible, setIsDrawerVisible] = useState<boolean>(false);
  const completeExamRef = useRef<AnalyticsCompleteExamRef>(null);
  useEffect(() => {
    dispatch(fetchStudentCourse(courseId));
  }, [courseId, dispatch]);
  useEffect(() => {
    if (evaluationId) {
      dispatch(fetchExamResult(evaluationId, courseId));
    }
  }, [courseId, dispatch, evaluationId]);
  useEffect(() => {
    if (examResult && examResult.passed && evaluation?.questions) {
      const answers: QuestionsAnswers = {};
      examResult.answers.forEach((answer) => {
        answers[answer.questionId] = {
          correct:
            evaluation.questions.find(
              (question: IQuestion) => question._id === answer.questionId
            )?.correctAnswer === answer.answerId,
          answerId: answer.answerId,
        };
      });
      setAnswerSelected(answers);
      setCurrentQuestion(evaluation?.questions.length);
    }
  }, [evaluation, examResult]);

  useEffect(() => {
    if(courseIsFetching || areEvaluationsFetching){
      return;
    }
    if (
      course &&
      !evaluation &&
      evaluationFetchStatus !== FetchStatus.Idle
    ) {
      dispatch(setMessageError({ message: "Este exámen no esta disponible" }));
      history.push(`/course/${courseId}`);
    }
  }, [
    evaluation,
    course,
    dispatch,
    history,
    courseId,
    areEvaluationsFetching,
    courseIsFetching,
    evaluationFetchStatus,
  ]);

  const examId = course?.evaluation;
  useEffect(() => {
    if (!examId || !courseId) {
      return;
    }
    dispatch(fetchEvaluation(examId, courseId, courseId));
  }, [courseId, dispatch, examId]);
  let content;
  const questionsCount = evaluation?.questions?.length || Infinity;
  const onNext = () => {
    setCurrentQuestion(currentQuestion + 1);
    if (currentQuestion + 1 === questionsCount) {
      const answers: IAnswer[] = [];
      let result = 0;
      Object.keys(answersSelected).forEach((answerSelected) => {
        answers.push({
          answerId: answersSelected[answerSelected].answerId,
          questionId: answerSelected,
        });
        if (answersSelected[answerSelected].correct) {
          ++result;
        }
      });
      const correctAnswersToPass = course?.correctAnswersToPass || Infinity;
      const passed = result >= correctAnswersToPass;
      completeExamRef?.current?.trigger(answers);
      if (passed) {
        dispatch(
          makeProgressStudentCourse({
            courseId: courseId,
            progress: 1,
            evaluationId: evaluation?._id,
          })
        );
      }
      dispatch(
        registerEvaluationResult({
          answers,
          evaluationId: examId || "",
          parentId: courseId,
          parentType: ParentTypeEnum.CourseExam,
          result,
          total: Object.keys(answersSelected).length,
          correctAnswersToPass: course?.correctAnswersToPass,
          passed,
          courseId,
        })
      );
    }
  };
  if (currentQuestion === -1) {
    content = <FirstPage evaluationId={examId || ''} onNext={onNext} />;
  } else if (questionsCount <= currentQuestion) {
    content = (
      <LastPage
        retakeExam={() => {
          dispatch(fetchEvaluation(examId || "", courseId, courseId));
          setAnswerSelected({});
          setCurrentQuestion(-1);
        }}
        answersSelected={answersSelected}
        courseId={courseId}
      />
    );
  } else {
    const question = evaluation?.questions
      ? evaluation?.questions[currentQuestion]
      : undefined;
    content = (
      <QuestionPage
        dontShowFeedback
        key={currentQuestion}
        question={question}
        currentQuestion={currentQuestion}
        questionsCount={evaluation?.questions?.length || 0}
        onNext={onNext}
        setAnswerSelected={(answerId: string) => {
          setAnswerSelected({
            ...answersSelected,
            [question?._id || ""]: {
              answerId,
              correct: question?.correctAnswer === answerId,
            },
          });
        }}
      />
    );
  }
  useEffect(() => {
    let timerId: any = null;
    const timeToRetake = evaluation?.timeToRetake as number;
    if (examId && timeToRetake > 0) {
      timerId = setTimeout(
        () => {
          dispatch(fetchEvaluation(examId, courseId, courseId));
        },
        timeToRetake > 1 ? 30000 : 10000
      );
      setTimerIsActive(true);
    }
    return function unmount() {
      if (timerId) {
        clearTimeout(timerId);
        setTimerIsActive(false);
      }
    };
  }, [evaluation, examId, dispatch, courseId]);

  const title = (
    <div className={ctx(styles.container)}>
      {size === SIZES.DESKTOP ? (
        <Card className={styles.titleContainer}>
          <div className={styles.topicTitle}>{course?.title}</div>
          <div className={styles.title}>Examen: {evaluation?.title}</div>
        </Card>
      ) : (
        <div>
          <div className={styles.topicTitle}>{course?.title}</div>
          <div className={styles.title}>Examen: {evaluation?.title}</div>
        </div>
      )}
    </div>
  );

  const lessonCourseInfo = <StudentLessonCourseInfo />;

  const lesonTopics = (
    <StudentLessonTopics
      currentTopicId={course?._id}
      currentLessonId={TOPIC_TEST}
      isExam
      isTest={false}
    />
  );

  const mobileDrawerHeader = (
    <div>
      <div>
        <Button
          type="default"
          onClick={() => {
            setIsDrawerVisible(false);
          }}
        >
          <CogIcon className={"el-style"} icon="Arrow-right-big" size="1em" />
          Cerrar
        </Button>
      </div>
    </div>
  );

  if (size === SIZES.DESKTOP) {
    return (
      <StudentMainLayout>
        <TwoColumnLayout
          firstColumnSpan={18}
          firstColumnContent={
            <React.Fragment>
              {title}
              {content}
            </React.Fragment>
          }
          secondColumnContent={
            <React.Fragment>
              {lessonCourseInfo}
              {lesonTopics}
            </React.Fragment>
          }
        />
        <AnalyticsSelectExam ref={completeExamRef} />
      </StudentMainLayout>
    );
  }
  if (size === SIZES.TABLET) {
    return (
      <StudentMainLayout>
        <Layout>
          <Space direction="vertical" size="middle" style={{ width: "100%" }}>
            <div>
              {title}
              {content}
            </div>
            <TwoColumnLayout
              firstColumnSpan={16}
              firstColumnContent={<div></div>}
              secondColumnContent={
                <React.Fragment>
                  {lessonCourseInfo}
                  {lesonTopics}
                </React.Fragment>
              }
            />
          </Space>
        </Layout>
        <AnalyticsSelectExam ref={completeExamRef} />
      </StudentMainLayout>
    );
  }

  if (
    !timerIsActive &&
    (examResultIsFetching || courseIsFetching || areEvaluationsFetching)
  ) {
    return <LoadingOverlay spinning={true} />;
  }

  return (
    <React.Fragment>
      <div className={styles.viewLessonsMobile}>
        <div></div>
        <Button
          type="default"
          onClick={() => {
            setIsDrawerVisible(true);
          }}
        >
          <CogIcon className={"el-style"} icon="Arrow-left-big" size="1em" />
          Ver lecciones
        </Button>
      </div>
      <StudentMainLayout>
        <Layout>
          {title}
          {content}
          <CogDrawer
            visible={isDrawerVisible}
            toggleVisible={() => { }}
            title={mobileDrawerHeader}
            footer={<div></div>}
            width={"100%"}
            headerStyle={{ borderBottom: 0, paddingLeft: 0, paddingRight: 0 }}
            closeIcon={null}
            noGutter
          >
            {lesonTopics}
          </CogDrawer>
        </Layout>
      </StudentMainLayout>
      <AnalyticsSelectExam ref={completeExamRef} />
    </React.Fragment>
  );
};


type AnalyticsCompleteExamRef = {
  trigger: (answers: IAnswer[]) => void,
}

interface AnalyticsCompleteExamProps { }

export const AnalyticsSelectExam =
  forwardRef<AnalyticsCompleteExamRef, AnalyticsCompleteExamProps>((_, ref) => {
    useImperativeHandle(ref, () => ({
      trigger: (answers) => {
        analytics.completeExam({
          exam: evaluation,
          course: course,
          program: courseProgram,
          company: company,
          group: group || null,
          answers: answers,
        });
      }
    }));
    const dispatch = useDispatch();
    const course = useSelector(selectStudentCourse);
    const evaluation = useSelector(selectEvaluation(course?.evaluation || ''));
    const allProgramsIds = useSelector(selectPrograms);
    const programsDict = useSelector(selectProgramsDict);
    const programId = allProgramsIds.filter(programId => {
      const program = programsDict[programId] || {};
      const { courses: programCourses =  [] } = program;
      const foundCourse = programCourses.find(courseId => courseId === course?._id);
      if (foundCourse !== undefined) {
        return true;
      }
      return false;
    })
    const courseProgram = programsDict[programId[0]] || null;
    const cognitoUserData = useSelector(getCognitoUserData);
    const cognitoUsername = cognitoUserData
      ? cognitoUserData["cognito:username"]
      : null;
    const userFromStore = useSelector(selectUser(cognitoUsername));
    const group = useSelector(selectGroup(userFromStore?.studentGroupId || '')) || null;
    const companyId = userFromStore?.company ?? "";
    const company = useSelector(
      selectCompany(companyId)
    );

    useEffect(() => {
      dispatch(fetchPrograms());
    }, [dispatch]);

    return null;
  });
