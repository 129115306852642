import { Modal } from "antd";
import React from "react";
import UpsertInfo from "../ConfirmationModal/UpsertInfo";

import styles from './index.module.scss';

interface Props {
  name: string;
  action: string;
  visible: boolean;
  extraText?: string;
  onCancel(): void;
}

export const DialogPopUp: React.FunctionComponent<Props> = ({
  name,
  action,
  visible,
  extraText,
  onCancel,
}) => (
  <>
    {visible && (
      <Modal
        visible={visible}
        closable={false}
        centered={true}
        footer={null}
        maskClosable={true}
        width={375}
        onCancel={onCancel}
      >
        <UpsertInfo
          msgLine1={name}
          msgLine2={action}
          alt={`${name} ${action}`}
        />
        {extraText ? 
          <div className={styles.extraText}>
            {extraText}
          </div> :
          null
        }
      </Modal>
    )}
  </>
);
