import { FetchStatus } from "../common/enums";

interface keyValue {
  [_id: string]: string;
}

export const CHART_COLORS: string[] = [
  '#9ED1F4', // blue
  '#FEB2C2', // red
  '#FEE5AF', // yellow
  '#A8DFDF', // green
  '#CCB5FD', // melrose
];


export const PIECHART_COLORS: string[] = [
  'rgb(255, 99, 132)', //red
  'rgb(54, 162, 235)', //blue
  'rgb(255, 205, 86)', //yellow
  'rgb(75, 192, 192)', //green
  'rgb(255, 159, 64)', //organge
  'rgb(153, 102, 255)', //purple
  'rgb(255,105,180)', //pink
  'rgb(255,215,0)', //gold
  'rgb(201, 203, 207)', //gray
];

export const COLORS: string[] = [
  "#D50000",
  "#880E4F",
  "#4A148C",
  "#B39DDB",
  "#6200EA",
  "#2962FF",
  "#00B0FF",
  "#006064",
  "#00796B",
  "#1B5E20",
  "#64DD17",
  "#F57F17",
  "#FF6F00",
  "#E65100",
  "#DD2C00",
  "#6D4C41",
  "#455A64",
];
export const BACKGROUND_COLORS: string[] = [
  "#EF9A9A",
  "#F8BBD0",
  "#CE93D8",
  "#9FA8DA",
  "#304FFE",
  "#90CAF9",
  "#81D4FA",
  "#80DEEA",
  "#80CBC4",
  "#A5D6A7",
  "#C5E1A5",
  "#FFF59D",
  "#FFE082",
  "#FFCC80",
  "#FF8A65",
  "#BCAAA4",
  "#B0BEC5",
];

export interface IReportDataset {
  label: any;
  data: number[];
  color?: string,
}
export interface IReportValue {
  key: string | keyValue;
  label: string;
  value: number | string;
  group?: string;
  index?: Date;
}
export const sortReportValue = (a: IReportValue, b: IReportValue) => {
  if (a.label > b.label) return 1;
  if (b.label > a.label) return -1;
  return 0;
};

export interface DataState {
  data: IReportValue[];
  status: FetchStatus;
}