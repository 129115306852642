import { Drawer, Spin } from 'antd';
import React from 'react';
import styles from './index.module.scss';
import cls from 'classnames';

interface TableProps {
    children: React.ReactNode;
    visible: boolean,
    title: React.ReactNode;
    width?: string | number;
    footer?: React.ReactNode;
    loading?: boolean;
    toggleVisible(): void;
    headerStyle?: React.CSSProperties,
    closeIcon?: React.ReactNode,
    noGutter?: boolean,
}


export const CogDrawer: React.FunctionComponent<TableProps> = ({
    children,
    visible,
    title,
    width = 420,
    footer,
    loading = false,
    toggleVisible,
    headerStyle,
    closeIcon,
    noGutter = false,
}) => {
    const handleClose = () => toggleVisible();
    return <Drawer
        title={title}
        width={width}
        visible={visible}
        onClose={handleClose}
        maskClosable={false}
        headerStyle={headerStyle}
        closeIcon={closeIcon}
    >
        <div className={cls(styles.container, { [styles.noGutter]: noGutter })}>
            <div className={cls(styles.content, {
                [styles.noFooter]: footer === false,
                [styles.noGutter]: noGutter,
            })}>
                <Spin spinning={loading}>
                    {children}
                </Spin>
            </div>
            {
                footer &&
                <div className={styles.footer}>
                    {footer}
                </div>
            }
        </div>
    </Drawer>
};
