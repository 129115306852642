import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import moment from "moment";
import { Dispatch } from "react";
import { Microservices } from "../../../app/AllowedMicroservices";
import { RootState } from "../../../app/store";
import apiActionCreator, {
  HttpMethods,
} from "../../../services/apiActionCreator";
import { FetchStatus } from "../../common/enums";
import { IReportDataset, IReportValue } from "../common";

interface Datasets {
  [key: string]: IReportDataset;
}
export interface IInternalVsExternal {
  table: IReportValue[];
  graph: {
    labels: string[];
    datasets: Datasets;
  };
}

interface IObjectKeyNumberValue {
  [key: string]: number;
}

interface ITicketsStatusesFetch extends IObjectKeyNumberValue {
  INITIAL: number;
  OPEN: number;
  IN_PROCESS: number;
  RESOLVED: number;
  CLOSED: number;
  CANCELED: number;
}

interface ITicketsFetch {
  internal: ITicketsStatusesFetch;
  external: ITicketsStatusesFetch;
}

interface IStatuses extends IObjectKeyNumberValue {
  initial: number;
  open: number;
  inProcess: number;
  resolved: number;
  closed: number;
  canceled: number;
}

interface UsersByCaseStatusState {
  internal: IStatuses;
  external: IStatuses;
  status: FetchStatus;
}

const emptyValues = {
  initial: 0,
  open: 0,
  inProcess: 0,
  resolved: 0,
  closed: 0,
  canceled: 0,
};

const initialState: UsersByCaseStatusState = {
  internal: { ...emptyValues },
  external: { ...emptyValues },
  status: FetchStatus.Idle,
};

export const dataSlice = createSlice({
  name: "usersByCase",
  initialState,
  reducers: {
    fetching: (state) => {
      state.status = FetchStatus.Fetching;
    },
    fetched: (state, action: PayloadAction<ITicketsFetch>) => {
      const internalFetchedData = action.payload.internal;
      const externalFetchedData = action.payload.external;
      const getInnerDataObject = (fetchedData: ITicketsStatusesFetch) => ({
        initial: fetchedData.INITIAL || 0,
        open: fetchedData.OPEN || 0,
        inProcess: fetchedData.IN_PROCESS || 0,
        resolved: fetchedData.RESOLVED || 0,
        closed: fetchedData.CLOSED || 0,
        canceled: fetchedData.CANCELED || 0,
      });

      state.internal = getInnerDataObject(internalFetchedData);
      state.external = getInnerDataObject(externalFetchedData);
      state.status = FetchStatus.Fetched;
    },
    error: (state) => {
      state.status = FetchStatus.Error;
    },
  },
});

export const { fetching, fetched, error } = dataSlice.actions;
export interface FetchCasesParams {
  from: moment.Moment;
  to: moment.Moment;
}

export const fetchByRange = ({ from, to }: FetchCasesParams) => (
  dispatch: Dispatch<object>
) => {
  const fromStrDate = moment(from).format("YYYY-MM-DD");
  const toStrDate = moment(to).format("YYYY-MM-DD");
  return dispatch(
    apiActionCreator({
      endpoint: `/reports/ticketsByUserType?from=${fromStrDate}&to=${toStrDate}`,
      types: {
        requestType: fetching,
        successTypes: [
          {
            actionOrCreator: fetched,
          },
        ],
        errorTypes: [
          {
            actionOrCreator: error,
          },
        ],
      },
      method: HttpMethods.GET,
      microservice: Microservices.X_ONE,
      authenticated: false,
    })
  );
};

export const selectCaseIsFetching = (state: RootState) =>
  state.reports.case.usersByCase.status === FetchStatus.Fetching;

export const selectUsersByCaseData = (state: RootState) => {
  interface IObjectKeyStringValue {
    [key: string]: string;
  }
  interface TicketStatusLabel extends IObjectKeyStringValue {
    initial: string;
    open: string;
    inProcess: string;
    resolved: string;
    closed: string;
    canceled: string;
  }
  const labelsDict: TicketStatusLabel = {
    initial: "Inicial",
    open: "Abiertos",
    inProcess: "Pendientes",
    resolved: "Resueltos",
    closed: "Cerrados",
    canceled: "Cancelados",
  };

  const usersByCaseStateData = state.reports.case.usersByCase;
  const internalData = usersByCaseStateData.internal;
  const externalData = usersByCaseStateData.external;

  const externalCounts: number[] = [];
  const internalCounts: number[] = [];
  const labels: string[] = [];
  const reportValues: IReportValue[] = [];

  Object.keys(internalData).forEach((ticketStatus) => {
    const qtyInternal = internalData[ticketStatus];
    const qtyExterrnal = externalData[ticketStatus];
    const label = labelsDict[ticketStatus];
    externalCounts.push(qtyExterrnal);
    internalCounts.push(qtyInternal);
    labels.push(label);

    const reportValueInternal: IReportValue = {
      key: "internal" + ticketStatus,
      label: "internos",
      value: qtyInternal,
      group: labelsDict[ticketStatus],
    };
    const reportValueExternal: IReportValue = {
      key: "external" + ticketStatus,
      label: "externos",
      value: qtyExterrnal,
      group: labelsDict[ticketStatus],
    };

    reportValues.push(reportValueInternal);
    reportValues.push(reportValueExternal);
  });

  const returnValues: IInternalVsExternal = {
    table: reportValues,
    graph: {
      labels: labels,
      datasets: {
        external: {
          label: "Externos",
          data: externalCounts,
        },
        internal: {
          label: "Internos",
          data: internalCounts,
        },
      },
    },
  };

  return returnValues;
};

export default dataSlice.reducer;
