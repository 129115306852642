import React, { useState, FunctionComponent } from 'react';
import cls from 'classnames';
import { useDispatch } from 'react-redux';

import { ContainerWithCarousel } from '../ContainerWithCarousel';
import { analytics } from '../../../services/analytics';
import { Step1 } from './Step1';
import { Step2 } from './Step2';
import { Step3 } from './Step3';
import { Step4 } from './Step4';
import { signUpUser } from '../../../features/signUp/signUpSlice';
import { ISignUpUser } from './interfaces';

import commonStyles from '../Login.module.scss';
import styles from './index.module.scss';
import { DialogPopUp } from '../../common/DialogPopUp';
import LoadingOverlay from '../../common/LoadingOverlay';
import { history } from '../../../App';

const STEPS = [
  Step1,
  Step2,
  Step3,
  Step4,
];

export const SignUp: FunctionComponent = () => {
  const [sending, setSending] = useState<boolean>(false);
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [stepIndex, setStepIndex] = useState<number>(0);
  const [userData, setUserData] = useState<ISignUpUser>({});
  const dispatch = useDispatch();

  const next = (user: ISignUpUser) => {
    const newUser = { ...userData, ...user };
    setUserData(newUser);
    if(stepIndex < STEPS.length - 1){
      setStepIndex(stepIndex + 1);
      return;
    }
    setSending(true);
    newUser.username = newUser.email;
    dispatch(signUpUser(newUser, ()=>{
      setSending(false);
      setModalVisible(true);
    }, ()=> { setSending(false) }));
    analytics.signUp();
  }
  const Step = STEPS[stepIndex];
  console.log(sending);
  return (
    <React.Fragment>
      {sending ? <LoadingOverlay
        spinning={sending}
        fullscreen
      />:null}
      <DialogPopUp
        name="¡Excelente!"
        action={"Creaste tu cuenta"}
        visible={modalVisible}
        onCancel={() => {
          setModalVisible(false);
          history.replace('/login');
        }}
        extraText="Te llegará un correo con la información para ingresar"
      />
      <div className={cls(commonStyles.roundedBorder)}>
      </div>
      {stepIndex === 0 ? 
        <ContainerWithCarousel>
          {<Step next={next} userData={userData} />}
        </ContainerWithCarousel>:
        <div className={styles.otherStepsContainer}>
          <div className={styles.headerBackground}></div>
          <div className={styles.innerContainer}>
            <Step next={next} userData={userData} />
          </div>
        </div>
      }
    </React.Fragment>

  );
}
