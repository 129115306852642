import React, { FunctionComponent } from 'react';
import ctx from 'classnames';
import { useSelector } from 'react-redux';

import {
    selectStudentCourseTopic,
    selectStudentCourseTopicProgress
} from '../../../features/students/courseSlice';
import { Collapse } from 'antd';
import { StudentLessonTopicLesson } from './TopicLesson';
import { StudentLessonTopicTest } from './TopicTest';
import { ITopic } from '../../../features/topic/topicSlice';

import styles from './topic.module.scss';
import { CompletenessIndicator } from './CompletenessIndicator';

interface StudentLessonTopicProp {
    className?: string,
    topicId: string,
    currentTopicId?: string,
    currentLessonId?: string
    isTest: boolean,
}
const { Panel } = Collapse;

interface TopicHeaderProps {
    topic: ITopic,
    currentTopicId?: string,
}
const TopicHeader: FunctionComponent<TopicHeaderProps> = ({ topic, currentTopicId }) => {
    const progress = useSelector(selectStudentCourseTopicProgress(topic._id));
    return <div className={styles.topicHeader}>
        <CompletenessIndicator progress={progress} />
        <div className={ctx(styles.title, {[styles.selected]: currentTopicId === topic._id})}>{topic.title}</div>
    </div>;
}

export const StudentLessonTopic: FunctionComponent<StudentLessonTopicProp> = ({ className, topicId, currentTopicId, currentLessonId, isTest }) => {
    const topic = useSelector(selectStudentCourseTopic(topicId));
    return <div className={ctx(styles.container, className)}>
        <Collapse className={styles.accordion} bordered={false} defaultActiveKey={['1']} expandIconPosition="right">
            <Panel header={<TopicHeader currentTopicId={currentTopicId} topic={topic} />} key="1">
                {topic.lessons.map(lesson => {
                    return <StudentLessonTopicLesson currentLessonId={currentLessonId} lessonId={lesson} key={lesson} />
                })}
                {topic.evaluation ? 
                    <StudentLessonTopicTest topicId={topicId} evaluationId={topic.evaluation} key={topic._id} selected={isTest} />
                :null}
            </Panel>
        </Collapse>
    </div>;
};