import {
  Alert,
  Button,
  Card,
  Divider,
  Dropdown,
  Form,
  Input,
  Menu,
  Select,
} from "antd";
import { SizeType } from "antd/lib/config-provider/SizeContext";
import cls from "classnames";
import React from "react";
import { useSelector } from "react-redux";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import { selectCompanyId, selectUserRole } from '../../features/auth/authSlice';
import { selectCompanies } from '../../features/company/companySlice';
import {
  ILesson,
  selectLessons,
  selectLessonsDict,
} from "../../features/lesson/lessonSlice";
import { ITopic } from "../../features/topic/topicSlice";
import { UserRole } from '../../features/users/usersSlice';
import { CategoryField } from "../common/CategoryField";
import { CogIcon } from "../common/CogIcon";
import formStyles from "../common/form.module.scss";
import { StatusEnum } from "../common/Status";
import { MIN_3_CHAR } from "../Users/Form";
import styles from "./form.module.scss";
import { StepProps } from "./interfaces";

const MAX_TAGS = 10;
const MAX_TAG_CHAR_COUNT = 20;

const renderLessonCard = (
  lesson: ILesson,
  setNewTopic: Function,
  newTopic: ITopic | undefined
) => (
  <Card
    key={lesson._id}
    className={cls(styles.cardContainer, styles.extendedStyle)}
    bodyStyle={{ padding: "16px" }}
  >
    <div className={styles.topIconContainer}>
      <CogIcon icon="drag"></CogIcon>
    </div>
    <div className={styles.title}>{lesson.title}</div>
    <div className={styles.meta}>
      <div>{lesson._id}</div>
    </div>
    <div className={styles.description}>{lesson.description}</div>
    <Divider />
    <div className={styles.btnContainer}>
      <Button
        className={styles.removeButton}
        type="default"
        onClick={() => {
          const currentTopicLessonsIds = newTopic
            ? (newTopic?.lessons as string[])
            : [];
          const currentLessonsIds = currentTopicLessonsIds.filter(
            (lessonId) => lessonId !== lesson._id
          );
          setNewTopic({ ...newTopic, lessons: [...currentLessonsIds] });
        }}
      >
        <CogIcon className={styles.square} icon="Delete"></CogIcon>
      </Button>
    </div>
  </Card>
);

const SortableItem = SortableElement(({ value }: any) => (
  <li style={{ zIndex: 99999999, listStyle: "none" }}>{value}</li>
));

const SortableList = SortableContainer(({ items, keys }: any) => {
  return (
    <ul className={styles.sortablelist}>
      {items.map((value: any, index: number) => (
        <SortableItem key={`item-${keys[index]}`} index={index} value={value} />
      ))}
    </ul>
  );
});

const renderSelectedLessons = (
  lessons: ILesson[] = [],
  setNewTopic: Function,
  newTopic: ITopic | undefined
) => {
  const onSortEnd = ({ oldIndex, newIndex }: any) => {
    const newLessons = lessons.map((lesson) => lesson);
    const lesson = lessons[oldIndex];
    newLessons.splice(oldIndex, 1);
    newLessons.splice(newIndex, 0, lesson);
    setNewTopic({
      ...newTopic,
      lessons: newLessons.map((lesson) => lesson._id),
    });
  };
  return (
    <div className={styles.cardsContainer}>
      <SortableList
        lockAxis="y"
        keys={lessons.map((lesson) => lesson._id)}
        items={lessons.map((lesson) =>
          renderLessonCard(lesson, setNewTopic, newTopic)
        )}
        onSortEnd={onSortEnd}
      />
    </div>
  );
};

export const FirstStep: React.FC<StepProps> = ({
  newTopic,
  setNewTopic,
  categories,
  categoriesIds,
}) => {
  const lessonDict = useSelector(selectLessonsDict);
  const lessonList = useSelector(selectLessons);
  const userRole = useSelector(selectUserRole);
  const companyId = useSelector(selectCompanyId);
  const companies = useSelector(selectCompanies);
  const currentLessonsIds =
    newTopic && newTopic.lessons ? [...(newTopic?.lessons as any)] : [];
  const currentLessons = currentLessonsIds.map(
    (lessonId) => lessonDict[lessonId]
  );
  const lessonListWOutCurrentWOutDisabled = lessonList.filter((lesson) => {
    return (
      !currentLessonsIds.includes(lesson._id) &&
      lesson.status === StatusEnum.Enable
    );
  });
  const handleTagsChange = (value: string[]) => {
    if (value.length <= MAX_TAGS) {
      setNewTopic({ ...newTopic, tags: [...value] });
    }
  };
  const handleCompanyChange = (value: string) => {
    setNewTopic({ ...newTopic, companyId: value });
  };

  const lessonByTitleComparator = (a: ILesson, b: ILesson) => {
    const aName = a.title.toLowerCase();
    const bName = b.title.toLowerCase();

    if (aName < bName) {
      return -1;
    }
    if (aName > bName) {
      return 1;
    }
    return 0;
  };
  const menuItems = lessonListWOutCurrentWOutDisabled
    .filter(lesson => {
      const isSuperAdmin = userRole === UserRole.SuperAdmin;
      const topicCompanyId = isSuperAdmin ? (!newTopic?.companyId ? undefined : newTopic?.companyId) : companyId;
      const lessonFromCompany = lesson.companyId === topicCompanyId;
      return isSuperAdmin || lessonFromCompany;
    })

    .sort(lessonByTitleComparator)
    .map((lesson, index) => {
      return (
        <Menu.Item key={index}>
          <p
            onClick={() => {
              setNewTopic({
                ...newTopic,
                lessons: [...currentLessonsIds, lesson._id],
              });
            }}
          >
            {lesson.title}
          </p>
        </Menu.Item>
      );
    });
  const hasLessonsAvailable = menuItems.length > 0 ;
  const menu = (
    <Menu>
      {menuItems}
    </Menu>
  );

  return (
    <React.Fragment>
      <Form.Item
        initialValue={newTopic?.title}
        name="title"
        label="Título del tema"
        rules={[
          { required: true, message: "Requerido" },
          { type: "string", min: 3, message: MIN_3_CHAR },
          { type: "string", max: 200, message: "Máximo 200 caracteres" },
        ]}
      >
        <Input
          onChange={(event) => {
            setNewTopic({ ...newTopic, title: event.target.value });
          }}
          type="text"
          placeholder="Título del tema"
        />
      </Form.Item>
      <CategoryField
        markRequired
        label="Categoría del tema"
        categories={categories}
        categoriesIds={categoriesIds}
        object={newTopic}
        setObject={setNewTopic}
      />
      <Form.Item
        initialValue={newTopic?.description}
        name="description"
        label="Descripción"
        rules={[
          { required: true, message: "Requerido" },
          { type: "string", max: 2000, message: "Máximo 2000 caracteres" },
          { type: "string", min: 3, message: MIN_3_CHAR },
        ]}
      >
        <Input.TextArea
          onChange={(event) => {
            setNewTopic({ ...newTopic, description: event.target.value });
          }}
          placeholder="Dirección"
        />
      </Form.Item>

      <Form.Item
        initialValue={newTopic?.tags}
        name="tags"
        label="Tags"
        rules={[
          { required: true },
          {
            validator: (rule, value: any) => {
              return new Promise((resolve, reject) => {
                if (value) {
                  const exceedsMaxCharCount = value.find(
                    (element: string) => element.length > MAX_TAG_CHAR_COUNT
                  );
                  if (value.length > MAX_TAGS) {
                    value.pop();
                    reject(`Máximo ${MAX_TAGS} tags`);
                    return;
                  } else if (exceedsMaxCharCount) {
                    value.pop();
                    reject(`Máximo ${MAX_TAG_CHAR_COUNT} caracteres por tag`);
                    return;
                  }
                  resolve();
                } else {
                  resolve();
                }
              });
            },
          },
        ]}
      >
        <Select
          mode="tags"
          size={"default" as SizeType}
          placeholder="Please select"
          onChange={handleTagsChange}
          style={{ width: "100%" }}
          maxTagCount={MAX_TAGS}
          maxTagTextLength={20}
          tokenSeparators={[" ", ","]}
        ></Select>
      </Form.Item>

      {userRole === UserRole.SuperAdmin ? <Form.Item
          label="Compañia"
          name="company"
          initialValue={newTopic?.companyId || ''}
        >
          <Select
            placeholder=""
            onChange={handleCompanyChange}
            style={{ width: "100%" }}
            disabled={newTopic?.lessons && newTopic?.lessons.length > 0}
          >
            <Select.Option key={'all_companies'} value={''}>
                Todas
              </Select.Option>
            {Object.values(companies).map(company => {
              return <Select.Option key={company._id} value={company._id}>
                {company.name}
              </Select.Option>
            })}
          </Select>
        </Form.Item>: null}

      <Divider />
      <div className={formStyles.sectionTitle}>Lecciones</div>
      {renderSelectedLessons(currentLessons, setNewTopic, newTopic)}
      {hasLessonsAvailable ? <Dropdown
        getPopupContainer={() =>
          document.getElementById("lc-div") as HTMLElement
        }
        overlay={menu}
        trigger={["click"]}
        disabled={menuItems.length === 0}
      >
        <p
          className="ant-dropdown-link"
          onClick={(e) => {
            e.preventDefault();
          }}
        >
          <Button disabled={menuItems.length === 0} className={styles.addLessonButton} type="default">
            <CogIcon className={styles.square} color="white" icon="Plus" />
            Añadir lección
          </Button>
        </p>
      </Dropdown> : <Alert message="Termina de configurar el tema y luego crea lecciones en la sección correspondiente" type="info"/>}
      <div id={"lc-div"}></div>
    </React.Fragment>
  );
};
