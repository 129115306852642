import React from "react";
import teuronaLogo from "../../../assets/logo-teurona.png";
import styles from "./pageCountHeader.module.scss";

interface PageHeaderProps {
  text: string;
  currentPage: number;
  totalPages: number;
}

export const PageCountHeader: React.FunctionComponent<PageHeaderProps> = ({
  text,
  currentPage,
  totalPages,
}) => {
  return (
    <React.Fragment>
      <div className={styles.mainWrapper}>
        <div className={styles.content}>
          <div className={styles.logo}>
            <img src={teuronaLogo} alt={"logo"} />
          </div>
          <div className={styles.count}>
            {text} {currentPage}/{totalPages}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
