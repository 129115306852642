import { Carousel, Col, Row } from "antd";
import { CarouselRef } from 'antd/lib/carousel';
import cls from "classnames";
import React, { FunctionComponent, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { fetchCategories } from "../../../features/category/categorySlice";
import {
  fetchStudentHomeCourses,
  selectAreStudentHomeCoursesFetching,
  selectStudentHomeCoursesIndexes,
} from "../../../features/students/homeCoursesSlice";
import { fetchStudentMyCourses } from "../../../features/students/myCoursesSlice";
import { SIZES } from "../../common/screen";
import { useDeviceSize } from "../../utils/useDeviceSize";
import { CarouselControls } from "../common/CarouselControls";
import { CourseBlock } from "./CourseBlock";
import styles from "./coursesBlock.module.scss";

interface StudentRouterProps {}

export const CoursesBlock: FunctionComponent<StudentRouterProps> = () => {
  const dispatch = useDispatch();
  const size = useDeviceSize();
  const coursesKeys = useSelector(selectStudentHomeCoursesIndexes);
  const areCoursesFetching = useSelector(selectAreStudentHomeCoursesFetching);
  const refCarousel = useRef<CarouselRef>(null);
  useEffect(() => {
    dispatch(fetchStudentHomeCourses());
    dispatch(fetchCategories());
    dispatch(fetchStudentMyCourses());
  }, [dispatch]);

  const handleNext = () => {
    if (refCarousel && refCarousel.current) {
      refCarousel.current.next();
    }
  };

  const handlePrev = () => {
    if (refCarousel && refCarousel.current) {
      refCarousel.current.prev();
    }
  };
  const coursesChildren = coursesKeys.map((courseKey) => {
    if (size === SIZES.PHONE) {
      return <CourseBlock key={courseKey} courseKey={courseKey} />;
    }

    return (
      <Col xs={24} sm={12} md={12} lg={8} key={`col-${courseKey}`}>
        <CourseBlock key={courseKey} courseKey={courseKey} />
      </Col>
    );
  });
  if(coursesKeys.length === 0){
    return <div className={styles.container}>
    <div className={styles.titleContainer}>
      <div className={styles.title}>Próximos cursos</div>
      <div className={styles.linkContainer}>
          <Link className={styles.link} to="/myCourses">
            Ver todos
          </Link>
        </div>
      </div>
      <div className={cls(styles.emptyMessage, {
        [styles.mobile]: size === SIZES.PHONE
      })}>
        Felicitaciones! Ya terminaste todos los cursos disponibles
      </div>
      </div>;
  }
  return (
    <div className={styles.container}>
      <div className={styles.titleContainer}>
        <div className={styles.title}>Próximos cursos</div>
        <div className={styles.linkContainer}>
          <Link className={styles.link} to="/myCourses">
            Ver todos
          </Link>
        </div>
      </div>
      <div
        className={cls(
          styles.innerContainer,
          "courses-block-carousel-container"
        )}
      >
        {size === SIZES.PHONE && (
          <>
            <Carousel
              ref={refCarousel}
              accessibility={true}
              effect="fade"
              dots={false}
            >
              {coursesChildren}
            </Carousel>
            {!areCoursesFetching ? (
              <CarouselControls
                handleNext={handleNext}
                handlePrev={handlePrev}
              />
            ) : null}
          </>
        )}
        {(size === SIZES.TABLET || size === SIZES.DESKTOP) && (
          <Row gutter={[16, 16]} justify="space-between">
            {coursesChildren}
          </Row>
        )}
      </div>
    </div>
  );
};
