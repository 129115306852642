import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Dispatch } from "react";
import { Microservices } from "../../../app/AllowedMicroservices";
import { RootState } from "../../../app/store";
import apiActionCreator, {
  HttpMethods,
} from "../../../services/apiActionCreator";
import { FetchStatus } from "../../common/enums";

interface CognizantAuth {
  access_token?: string;
}

interface AuthState {
  data: CognizantAuth;
  status: FetchStatus;
}

const initialState: AuthState = {
  data: {},
  status: FetchStatus.Idle,
};

export const dataSlice = createSlice({
  name: "getToken",
  initialState,
  reducers: {
    fetching: (state) => {
      state.status = FetchStatus.Fetching;
    },
    fetched: (state, action: PayloadAction<CognizantAuth>) => {
      state.data = action.payload;
      state.status = FetchStatus.Fetched;
    },
    error: (state) => {
      state.data = {};
      state.status = FetchStatus.Error;
    },
  },
});

export const { fetching, fetched, error } = dataSlice.actions;

export const getAuthToken = () => (dispatch: Dispatch<object>) => {
  return dispatch(
    apiActionCreator({
      endpoint: "oauth/token",
      types: {
        requestType: fetching,
        successTypes: [
          {
            actionOrCreator: fetched,
          },
        ],
        errorTypes: [
          {
            actionOrCreator: error,
          },
        ],
      },
      method: HttpMethods.POST,
      microservice: Microservices.X_ONE,
      authenticated: false,
      contentType: "application/x-www-form-urlencoded",
      data: "client_id=651085286530.apps.x-one&client_secret=6nVlpCBPKR0o1JZPIDH2lB9DOAD1hSDD&grant_type=client_credentials",
    })
  );
};

export const selectAuthToken = (state: RootState) =>
  state.reports.case.xOneToken.data;
export const selectAuthTokenIsFetching = (state: RootState) =>
  state.reports.case.xOneToken.status === FetchStatus.Fetching;

export default dataSlice.reducer;
