import { Button, Carousel, Col, Row, Spin, Typography } from "antd";
import { CarouselRef } from 'antd/lib/carousel';
import cls from "classnames";
import React, { FunctionComponent, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import campaignPlaceholder from "../../../assets/campaign_placeholder.svg";
import { ICampaign } from "../../../features/campaign/campaignSlice";
import { ParentTypeEnum } from "../../../features/evaluation/common/enums";
import {
  hideModal,
  selectDisplayModal,
} from "../../../features/evaluation/currentSurveySlice";
import {
  fetchHomeCampaigns,
  selectAreCampaignsFetching,
  selectCampaigns,
} from "../../../features/students/campaignSlice";
import { CogIcon } from "../../common/CogIcon";
import { DialogPopUp } from "../../common/DialogPopUp";
import { SIZES } from "../../common/screen";
import { useDeviceSize } from "../../utils/useDeviceSize";
import { Survey } from "../Evaluation/Survey";
import styles from "./campaignsBlock.module.scss";
import { IAnswer } from "../../../features/evaluation/common/interfaces";
import moment from "moment";
import { analytics } from '../../../services/analytics';

interface CampaignProps {
  campaign: ICampaign;
  setSurveyVisible: React.Dispatch<React.SetStateAction<boolean>>;
  handlePrev(): void;
  handleNext(): void;
  liveCampaignsCount: number;
  currentCampaignIndex: number;
  setStartTime: React.Dispatch<React.SetStateAction<moment.Moment>>,
}

const CampaignCard: React.FC<CampaignProps> = ({
  campaign,
  setSurveyVisible,
  handlePrev,
  handleNext,
  liveCampaignsCount,
  currentCampaignIndex,
  setStartTime = () => { },
}) => {
  const size = useDeviceSize();
  const image = campaign?.image
    ? `url(${campaign?.image})`
    : `url(${campaignPlaceholder})`;

  return (
    <React.Fragment>
      <div
        className={cls(styles.inners, {
          [styles.tablet]: size === SIZES.TABLET,
          [styles.desktop]: size === SIZES.DESKTOP,
        })}
      >
        <Row style={{ width: "100%" }}>
          <Col
            span={cls({
              24: size === SIZES.PHONE,
              10: size === SIZES.TABLET || size === SIZES.DESKTOP,
            })}
            order={cls({
              1: size === SIZES.PHONE,
              2: size === SIZES.TABLET || size === SIZES.DESKTOP,
            })}
            style={{ height: "100%" }}
          >
            <div
              className={cls(styles.imageWrapper, {
                [styles.tablet]: size === SIZES.TABLET,
                [styles.desktop]: size === SIZES.DESKTOP,
              })}
            >
              <div
                className={styles.aspectRatioImage}
                style={{
                  backgroundImage: image,
                }}
              >
                <div></div>
              </div>
            </div>
          </Col>
          <Col
            span={cls({
              24: size === SIZES.PHONE,
              14: size === SIZES.TABLET || size === SIZES.DESKTOP,
            })}
            order={1}
          >
            <div
              className={cls(styles.content, {
                [styles.tablet]: size === SIZES.TABLET,
                [styles.desktop]: size === SIZES.DESKTOP,
              })}
            >
              <div
                className={cls(styles.title, {
                  [styles.tablet]: size === SIZES.TABLET,
                  [styles.desktop]: size === SIZES.DESKTOP,
                })}
              >
                {campaign?.title}
              </div>
              <div
                className={cls(styles.description, {
                  [styles.tablet]: size === SIZES.TABLET,
                  [styles.desktop]: size === SIZES.DESKTOP,
                })}
              >
                <Typography.Paragraph ellipsis={{ rows: 4 }}>
                  {campaign?.description}
                </Typography.Paragraph>
              </div>
              {!campaign?.answered && campaign.survey ? (
                <Button
                  className={cls(styles.mainButton, {
                    [styles.tablet]: size === SIZES.TABLET,
                    [styles.desktop]: size === SIZES.DESKTOP,
                  })}
                  onClick={() => {
                    setSurveyVisible(true);
                    setStartTime(moment());
                    analytics.selectPromoBanner({
                      banner: campaign,
                    });
                  }}
                  type="primary"
                >
                  Responder encuesta
                </Button>
              ) : null}
            </div>
          </Col>
        </Row>
      </div>
      <div
        className={cls(styles.ctaWrapper, {
          [styles.tablet]: size === SIZES.TABLET,
          [styles.desktop]: size === SIZES.DESKTOP,
        })}
      >
        <Row
          className={cls(styles.ctaRow, {
            [styles.desktop]: size === SIZES.DESKTOP,
          })}
        >
          <Col
            span={cls({
              "20": size === SIZES.PHONE,
              "5": size === SIZES.TABLET,
            })}
            offset={cls({
              "2": size === SIZES.PHONE,
              "19": size === SIZES.TABLET,
            })}
          >
            <div
              className={cls(styles.arrows, {
                [styles.tablet]: size === SIZES.TABLET,
                [styles.desktop]: size === SIZES.DESKTOP,
              })}
            >
              <Button
                className={cls(styles.prevNext, "previous")}
                onClick={handlePrev}
              >
                <CogIcon
                  className={styles.icon}
                  color="#81858B"
                  icon={cls({
                    "Arrow-left1": size === SIZES.PHONE,
                    "Arrow-left-big":
                      size === SIZES.TABLET || size === SIZES.DESKTOP,
                  })}
                />
              </Button>
              <span
                className={cls(styles.values, {
                  [styles.tablet]: size === SIZES.TABLET,
                })}
              >
                {currentCampaignIndex + 1}/{liveCampaignsCount}
              </span>
              <Button
                className={cls(styles.prevNext, "next")}
                onClick={handleNext}
              >
                <CogIcon
                  className={styles.icon}
                  color="#81858B"
                  icon={cls({
                    "Arrow-right1": size === SIZES.PHONE,
                    "Arrow-right-big":
                      size === SIZES.TABLET || size === SIZES.DESKTOP,
                  })}
                />
              </Button>
            </div>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
};
interface ISurveyVisiblePerCampaign {
  [id: string]: boolean
}
export const CampaignsBlock: FunctionComponent = () => {
  const [surveyVisible, setSurveyVisible] = useState<ISurveyVisiblePerCampaign>({});
  const allCampaigns = useSelector(selectCampaigns);
  const isModalVisible = useSelector(selectDisplayModal);
  const refCarousel = useRef<CarouselRef>(null);
  const dispatch = useDispatch();
  const areCampaignsFetching = useSelector(selectAreCampaignsFetching);
  const [surveyAnswers, setSurveyAnswers] = useState<IAnswer[]>([]);
  const [startTime, setStartTime] = useState<moment.Moment>(moment());
  const seenCampaignIds = useMemo(() => new Set(), []);

  useEffect(() => {
    dispatch(fetchHomeCampaigns());
  }, [dispatch]);

  useEffect(() => {
    if (isModalVisible === true) {
      dispatch(fetchHomeCampaigns());
    }
  }, [isModalVisible, dispatch]);

  useEffect(() => {
    if (allCampaigns && allCampaigns.length > 0) {
      const campaign = allCampaigns[0];
      analytics.viewPromoBanner({ banner: campaign });
      seenCampaignIds.add(campaign._id);
    }
  }, [allCampaigns, seenCampaignIds]);

  if (allCampaigns[0] === null || allCampaigns[0] === undefined) {
    return null;
  }

  const handlePrev = () => {
    if (refCarousel && refCarousel.current) {
      refCarousel.current.prev();
    }
  };

  const handleNext = () => {
    if (refCarousel && refCarousel.current) {
      refCarousel.current.next();
    }
  };

  const onSurveyCompleted = () => {
    setSurveyVisible({});
  };

  const cancelSurvey = () => {
    setSurveyVisible({});
  };

  const handleCompletePromoAnalyticsEvent = (surveyAnswers: IAnswer[], startTime: moment.Moment) => {
    setTimeout(() => {
      const timeDiffSecs = moment().diff(startTime, 'seconds');
      analytics.completePromoBanner({
        totalTimeSecs: timeDiffSecs,
        answers: surveyAnswers,
      });
    }, 200);
  }

  const handleViewPromoBannerAnalytics = (from: number, to: number) => {
    if (!seenCampaignIds.has(to)) {
      const campaign = allCampaigns[to];
      analytics.viewPromoBanner({ banner: campaign });
      seenCampaignIds.add(campaign._id);
    }
  }

  const campaignsCarouselChildren = allCampaigns.map((campaign, index) => {
    return (
      <React.Fragment key={campaign._id}>
        <CampaignCard
          campaign={campaign}
          setSurveyVisible={() => {
            setSurveyVisible({...surveyVisible, [campaign._id]: true})
          }}
          handleNext={handleNext}
          handlePrev={handlePrev}
          setStartTime={setStartTime}
          liveCampaignsCount={allCampaigns.length}
          currentCampaignIndex={index}
        />
        {surveyVisible[campaign._id] && campaign.survey && (
          <Survey
            courseId=''
            parentId={campaign._id}
            surveyId={campaign.survey}
            setSurveyAnswers={setSurveyAnswers}
            onSurveyComplete={() => {
              handleCompletePromoAnalyticsEvent(surveyAnswers, startTime);
              onSurveyCompleted();
            }}
            parentType={ParentTypeEnum.Campaign}
            onCancelSurvey={cancelSurvey}
          />
        )}
      </React.Fragment>
    );
  });

  return (
    <React.Fragment>
      <DialogPopUp
        name="Tu respuesta se ha"
        action="enviado correctamente"
        visible={isModalVisible}
        onCancel={() => {
          dispatch(hideModal());
        }}
      />
      <div className={cls(styles.container, styles.fix, styles.fix)}>
        <div className={styles.titleWrapper}>
          <span className={styles.text}>Campañas</span>
        </div>
        <Spin spinning={areCampaignsFetching}>
          <Carousel
            autoplaySpeed={5000}
            autoplay
            ref={refCarousel}
            accessibility={true}
            effect="fade"
            dots={false}
            slidesToScroll={1}
            beforeChange={handleViewPromoBannerAnalytics}
          >
            {campaignsCarouselChildren}
          </Carousel>
        </Spin>
      </div>
    </React.Fragment>
  );
};
