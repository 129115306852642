import React, { FunctionComponent, useEffect } from 'react';
import ctx from 'classnames';
import { useDispatch, useSelector } from 'react-redux';

import {
    fetchTeachers,
    selectTeacher
} from '../../../features/teacher/teacherSlice';
import { convertToP } from '../../utils/convertions';

import styles from './teacher.module.scss';

interface LessonTeacherProp {
    teacherId: string,
    className?: string
}

export const LessonTeacher: FunctionComponent<LessonTeacherProp> = ({ teacherId, className }) => {
    const dispatch = useDispatch();
    const teacher = useSelector(selectTeacher(teacherId));
    useEffect(() => {
        dispatch(fetchTeachers())
    },[dispatch]);
    if (!teacher) {
        return null;
    }
    return <div className={ctx(styles.container, className)}>
        <div className={styles.pictureAndName}>
            {teacher.picture?<img className={styles.picture} src={teacher.picture} alt="teacher"/>:null}
            <div className={styles.name}>{teacher.firstName} {teacher.middleName} {teacher.lastName} {teacher.secondLastName}</div>
        </div>
        <div className={styles.description}>{convertToP(teacher.description, styles.content, styles.p)}</div>
    </div>;
};