import React, { FunctionComponent, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RangeValue } from "rc-picker/es/interface";
import { RoundedContainer } from "../../../../common/RoundedContainer";
import moment from "moment";
import { Line } from 'react-chartjs-2'
import { PIECHART_COLORS } from "../../../../../features/reports/common";
import { DatePicker, Space } from 'antd';
import { TileTile } from "../../common/TileTitle";
import LoadingOverlay from "../../../../common/LoadingOverlay";
import {
  selectInsAndOutsPerCompanyPerDayData,
  selectDataIsFetching,
  fetchInsAndOutsPerCompanyPerDayData
} from "../../../../../features/reports/company/insAndOutsPerDayAggregated";
import { getDaysByRange, getEnableDisableCountsPerDay } from "../utils";

const { RangePicker } = DatePicker;

export const InsAndOutsPerDayAggregated: FunctionComponent = () => {
  const dispatch = useDispatch();
  const insAndOutsPerCompanyByDate = useSelector(selectInsAndOutsPerCompanyPerDayData);
  const dataIsFetching = useSelector(selectDataIsFetching);
  const [countsPerDay, setCountsPerDay] = useState<CountsPerDay>({});
  const [dates, setDates] = useState<moment.Moment[]>([]);
  const [endDateForPerDayChart, setEndDateForPerDayChart] = useState<moment.Moment>(moment());
  const [initialDateForPerDdayChart, setInitialDateForPerDdayChart] =
    useState<moment.Moment>(endDateForPerDayChart.clone().subtract(30, 'days'));
  const prevDateRef = useRef<moment.Moment>();

  useEffect(() => {
    prevDateRef.current = endDateForPerDayChart;
  });
  const currentEndDate = prevDateRef.current;
  const didEndDateForPerDayChartChanged = endDateForPerDayChart !== currentEndDate;


  useEffect(() => {
    if (
      (
        dataIsFetching ||
        Object.keys(insAndOutsPerCompanyByDate).length === 0
      ) && !didEndDateForPerDayChartChanged
    ) {
      return;
    }

    const sortedDates = getDaysByRange({
      startDate: endDateForPerDayChart.clone().subtract(31, 'days'),
      endDate: endDateForPerDayChart
    });

    const counts: CountsPerDay = getEnableDisableCountsPerDay({
      dates: sortedDates,
      insAndOutsPerCompany: insAndOutsPerCompanyByDate
    });
    if (sortedDates.length > 0) {
      setCountsPerDay(counts);
      setDates(sortedDates);
    }
  }, [dataIsFetching, insAndOutsPerCompanyByDate, endDateForPerDayChart, didEndDateForPerDayChartChanged]);

  const onChangeDateForInsAndOutsPerDay = (dates: RangeValue<moment.Moment> = [moment(), moment()]) => {
    const endDate = (dates || [moment(), moment()])[1];
    if (endDate) {
      setInitialDateForPerDdayChart(endDate.clone().subtract(30, 'days'));
      setEndDateForPerDayChart(endDate);
    }
  }

  useEffect(() => {
    const endDateParam = moment(endDateForPerDayChart?.format('YYYY-MM-DD'));
    const endDateParamString = endDateParam.format('YYYY-MM-DD');
    dispatch(
      fetchInsAndOutsPerCompanyPerDayData({ yyyyMmDdEndDate: endDateParamString, utcOffset: endDateParam.utcOffset() })
    );
  }, [dispatch, endDateForPerDayChart]);

  const { chartConfig } = getInOutsLineConfigs(countsPerDay, dates);

  return (
    <RoundedContainer>
      {
        dataIsFetching ? <LoadingOverlay spinning inline height={235} /> :
          (
            <>
              <TileTile text='Entrada y salida de empresas (acumulado)' />
              <Space direction="vertical" size={12}>
                <RangePicker
                  value={[initialDateForPerDdayChart, endDateForPerDayChart]}
                  defaultValue={[initialDateForPerDdayChart, endDateForPerDayChart]}
                  disabled={[true, false]}
                  onChange={values => onChangeDateForInsAndOutsPerDay(values)}
                />
                <div></div>
              </Space>
              <Line {...chartConfig} />
            </>
          )
      }
    </RoundedContainer>
  );
}

interface CountsData {
  enabled: number,
  disabled: number,
  dayLabel: string,
  dateLabel: string,
}

interface CountsPerDay {
  [YYYYMMDD: string]: CountsData,
}

const getInOutsLineConfigs = (countsData: CountsPerDay, sortedDates: moment.Moment[]) => {
  const {
    labels,
    prettyFormatDateLabels,
    enabledCountsPerDayCummulative,
    disabledCountsPerDayCummulative,
  } = generateCountsByDayLabelsAndData(countsData, sortedDates);
  const chartConfig = getChartFullConfig({
    line1: {
      label: 'Empresas deshabilitadas',
      data: disabledCountsPerDayCummulative,
      color: PIECHART_COLORS[2]
    },
    line2: {
      label: 'Empresas nuevas',
      data: enabledCountsPerDayCummulative,
      color: PIECHART_COLORS[3],
    },
    labels: labels,
    tooltipLabels: prettyFormatDateLabels,
  });

  chartConfig.options.scales.xAxes[0].gridLines.display = false;

  return {
    chartConfig,
  }
}

interface LineData {
  label: string,
  data: number[],
  color: string,
}
interface IGetChartConfig {
  line1: LineData,
  line2: LineData,
  labels: string[],
  tooltipLabels: string[]
}

const getChartFullConfig = ({ line1, line2, labels, tooltipLabels }: IGetChartConfig) => {
  const data = {
    labels: labels,
    datasets: [
      {
        label: line1.label,
        data: line1.data,
        fill: false,
        borderColor: line1.color,
        tension: 0.1,
        borderWidth: 1,
        pointBackgroundColor: line1.color,
        spanGaps: 2,
      },
      {
        label: line2.label,
        data: line2.data,
        fill: false,
        borderColor: line2.color,
        tension: 0.1,
        borderWidth: 1,
        pointBackgroundColor: line2.color,
        spanGaps: 2,
      }
    ]
  };
  return {
    type: 'line',
    data: data,
    options: {
      scales: {
        xAxes: [{
          ticks: {
            autoSkip: false,
            maxRotation: 0,
            minRotation: 0,
          },
          gridLines: {
            display: true
          }
        }],
        yAxes: [{
          ticks: {
            precision: 0,
            stepSize: 1,
          }
        }],
      },
      legend: {
        position: 'bottom',
        align: 'center',
        reverse: true,
      },
      tooltips: {
        callbacks: {
          title: function () { return null },
          label: function (tooltipItem: any, data: any) {
            const { index } = tooltipItem;
            return tooltipLabels[index]
          },
          afterLabel: function (tooltipItem: any, data: any) {
            const { datasetIndex, yLabel } = tooltipItem;
            const { label } = data.datasets[datasetIndex];
            return `${label}: ${yLabel}`;
          },
        }
      }
    }
  };
}

const generateCountsByDayLabelsAndData = (countData: CountsPerDay, sortedDates: moment.Moment[]) => {
  const labels: string[] = [];
  const prettyFormatDateLabels: string[] = [];
  const enabledCountsPerDayCummulative: number[] = [];
  const disabledCountsPerDayCummulative: number[] = [];
  let enabledCountsTotal = 0;
  let disabledCountsTotal = 0;

  sortedDates.forEach(date => {
    const yyMmDdkey = date.format('YYYY-MM-DD').toString();
    const data = countData[yyMmDdkey];
    labels.push(data.dayLabel);
    prettyFormatDateLabels.push(data.dateLabel);
    enabledCountsTotal += data.enabled;
    disabledCountsTotal += data.disabled;
    enabledCountsPerDayCummulative.push(enabledCountsTotal);
    disabledCountsPerDayCummulative.push(disabledCountsTotal);
  });

  return {
    labels,
    prettyFormatDateLabels,
    enabledCountsPerDayCummulative,
    disabledCountsPerDayCummulative
  }
}
