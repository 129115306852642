import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Dispatch } from "react";
import { Microservices } from "../../../../app/AllowedMicroservices";
import { RootState } from "../../../../app/store";
import apiActionCreator, {
  HttpMethods,
} from "../../../../services/apiActionCreator";
import { FetchStatus } from "../../../common/enums";
import { IFulfillment } from '../common/interfaces';


interface IFulfillmentState {
  topicFulfillment: IFulfillment[],
  status: FetchStatus,
}
const initialState: IFulfillmentState = {
  topicFulfillment: [],
  status: FetchStatus.Idle,
};

export const dataSlice = createSlice({
  name: "reportLearningTopicsFulfillment",
  initialState,
  reducers: {
    fetching: (state) => {
      state.status = FetchStatus.Fetching;
    },
    fetched: (state, action: PayloadAction<IFulfillment[]>) => {
      state.topicFulfillment = action.payload;
      state.status = FetchStatus.Fetched;
    },
    error: (state) => {
      state.status = FetchStatus.Error;
    },
  },
});

export const { fetching, fetched, error } = dataSlice.actions;

export const fetchTopicsCompletion = (groupId?: string) => (dispatch: Dispatch<object>) => {
  let url = '/topics/completionPerGroup';
  if(groupId){
    url = `${url}/${groupId}`;
  }
  return dispatch(
    apiActionCreator({
      endpoint: url,
      types: {
        requestType: fetching,
        successTypes: [
          {
            actionOrCreator: fetched,
          },
        ],
        errorTypes: [
          {
            actionOrCreator: error,
          },
        ],
      },
      method: HttpMethods.GET,
      microservice: Microservices.LEARNING_SERVICE,
      authenticated: true,
    })
  );
};

export const selectTopicsFulfillments = (state: RootState) => state.reports.learning.topics.topicsFulfillments.topicFulfillment;
export const selectTopicsFulfillmentsFetching = (state: RootState) => state.reports.learning.topics.topicsFulfillments.status === FetchStatus.Fetching;

export default dataSlice.reducer;
