import React, { FunctionComponent, useEffect, Fragment } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { message as antdMessage } from 'antd';

import { clearMessage, MessageTypeEnum, selectMessage } from '../../../features/message/messageSlice';

export const Message: FunctionComponent = () => {
    const message = useSelector(selectMessage);
    const dispatch = useDispatch()
    const onClose = () => {
        dispatch(clearMessage());
    };
    useEffect(() => {
        if(!message.message){
            return;
        }
        switch (message.type) {
            case MessageTypeEnum.INFO:
                antdMessage.info({
                    content: message.message,
                    onClose: onClose,
                });
                break;
            case MessageTypeEnum.ERROR:
                antdMessage.error({
                    content: message.message,
                    onClose: onClose,
                });
                break;
            case MessageTypeEnum.WARNING:
                antdMessage.warning({
                    content: message.message,
                    onClose: onClose,
                });
                break;
        }
    });

    return <Fragment></Fragment>;
}