import cls from "classnames";
import React, { Fragment } from "react";
import defaultImg from "../../../assets/profile/profile-placeholder.png";
import { ProfileBase } from "./ProfileBaseLyt";
import styles from "./profileLytBase.module.scss";

import {
  Col,
  Row,
} from 'antd';

interface ProfileLayoutProps {
  children?: React.ReactNode;
  classname?: string;
  rightTopOptions?: React.ReactNode;
  image?: any;
  leftBottomOptions?: React.ReactNode;
  rightBottomOptions?: React.ReactNode;
}

const renderRightTopOptions = (rightTopOptions?: React.ReactNode) => {
  if (!rightTopOptions) {
    return null;
  }
  return <Fragment>{rightTopOptions}</Fragment>;
};

const renderImage = (imageOption?: string) => {
  if (!imageOption) {
    return null;
  }
  return (
    <Fragment>
      <img src={imageOption} alt='imagen del perfil' className={cls(styles.image)} />
    </Fragment>
  );
};

const renderLeftBottomOptions = (leftBottomOptions?: React.ReactNode) => {
  if (!leftBottomOptions) {
    return null;
  }
  return <Fragment>{leftBottomOptions}</Fragment>;
};

const renderRightBottomOptions = (rightBottomOptions?: React.ReactNode) => {
  if (!rightBottomOptions) {
    return null;
  }
  return <Fragment>{rightBottomOptions}</Fragment>;
};

export const ProfileLayout: React.FunctionComponent<ProfileLayoutProps> = ({
  children,
  rightTopOptions,
  image = defaultImg,
  leftBottomOptions,
  rightBottomOptions,
}) => {
  return (
    <ProfileBase panelHeight={styles.height90}>
      <Row>
        <Col
          xs={{ span: 22, offset: 1 }}
          md={{ span: 22, offset: 1 }}
          lg={24}
          className={cls(styles.heading)}
        >
          <Row style={{ alignItems: 'flex-end' }}>
            <Col md={8} lg={6} xs={24}>
              {renderImage(image)}
            </Col>
            <Col md={16} lg={12} xs={24}>
              {renderRightTopOptions(rightTopOptions)}
            </Col>
          </Row>
        </Col>
      </Row>
      <Row gutter={[24, 16]} style={{ marginTop: '3rem' }}>
        <Col
          xs={{ span: 22, offset: 1 }}
          md={{ span: 22, offset: 1 }}
          lg={24}
        >
          <Row gutter={[24, 16]}>
            <Col md={8} lg={6} xs={24}>
              {renderLeftBottomOptions(leftBottomOptions)}
            </Col>
            <Col md={16} lg={12} xs={24}>
              {renderRightBottomOptions(rightBottomOptions)}
            </Col>
          </Row>
        </Col>
      </Row>
      {children && children}
    </ProfileBase>
  );
};
