import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import moment from "moment";
import { Dispatch } from "react";
import { Microservices } from "../../../app/AllowedMicroservices";
import { RootState } from "../../../app/store";
import apiActionCreator, {
  HttpMethods,
} from "../../../services/apiActionCreator";
import { FetchStatus } from "../../common/enums";
import { IReportDataset, IReportValue } from "../common";
import { FetchCasesParams } from "./usersByCase";

interface IObjectKeyNumberValue {
  [key: string]: number;
}

interface IResponseTimes extends IObjectKeyNumberValue {
  min: number;
  max: number;
  average: number;
}

interface IResponseTimesPayloadFetch {
  internal: IResponseTimes;
  external: IResponseTimes;
}

interface UsersByCaseStatusState {
  internal: IResponseTimes;
  external: IResponseTimes;
  status: FetchStatus;
}

const emptyValues = {
  min: 0,
  max: 0,
  average: 0,
};

const initialState: UsersByCaseStatusState = {
  internal: { ...emptyValues },
  external: { ...emptyValues },
  status: FetchStatus.Idle,
};

export const usersByResponseTimeSlice = createSlice({
  name: "usersByCaseResonseTime",
  initialState,
  reducers: {
    fetching: (state) => {
      state.status = FetchStatus.Fetching;
    },
    fetched: (state, action: PayloadAction<IResponseTimesPayloadFetch>) => {
      state.internal = action.payload.internal;
      state.external = action.payload.external;
      state.status = FetchStatus.Fetched;
    },
    error: (state) => {
      state.status = FetchStatus.Error;
    },
  },
});

export const { fetching, fetched, error } = usersByResponseTimeSlice.actions;

export const fetchByRange = ({ from, to }: FetchCasesParams) => (
  dispatch: Dispatch<object>
) => {
  const fromStrDate = moment(from).format("YYYY-MM-DD");
  const toStrDate = moment(to).format("YYYY-MM-DD");
  return dispatch(
    apiActionCreator({
      endpoint: `/reports/resolutionTimeByUserType?from=${fromStrDate}&to=${toStrDate}`,
      types: {
        requestType: fetching,
        successTypes: [
          {
            actionOrCreator: fetched,
          },
        ],
        errorTypes: [
          {
            actionOrCreator: error,
          },
        ],
      },
      method: HttpMethods.GET,
      microservice: Microservices.X_ONE,
      authenticated: false,
    })
  );
};

export const selectCaseIsFetching = (state: RootState) =>
  state.reports.case.userByCaseResponseTime.status === FetchStatus.Fetching;

export const selectUsersByCaseResponseTimeData = (state: RootState) => {
  interface IObjectKeyStringValue {
    [key: string]: string;
  }

  interface Datasets {
    [key: string]: IReportDataset;
  }
  interface ReturnValues {
    table: IReportValue[];
    graph: {
      labels: string[];
      datasets: Datasets;
    };
  }

  interface TicketStatusLabel extends IObjectKeyStringValue {
    min: string;
    max: string;
    average: string;
  }
  const labelsDict: TicketStatusLabel = {
    min: "Min",
    max: "Max",
    average: "Promedio",
  };

  const usersByCaseResponseTimes = state.reports.case.userByCaseResponseTime;
  const internalData = usersByCaseResponseTimes.internal;
  const externalData = usersByCaseResponseTimes.external;

  const externalCounts: number[] = [];
  const internalCounts: number[] = [];
  const labels: string[] = [];
  const reportValues: IReportValue[] = [];

  Object.keys(internalData).forEach((timeKey) => {
    const qtyInternal = internalData[timeKey];
    const qtyExterrnal = externalData[timeKey];
    const label = labelsDict[timeKey];
    externalCounts.push(qtyExterrnal);
    internalCounts.push(qtyInternal);
    labels.push(label);

    const reportValueInternal: IReportValue = {
      key: "internal" + timeKey,
      label: "internos",
      value: qtyInternal,
      group: labelsDict[timeKey],
    };
    const reportValueExternal: IReportValue = {
      key: "external" + timeKey,
      label: "externos",
      value: qtyExterrnal,
      group: labelsDict[timeKey],
    };

    reportValues.push(reportValueInternal);
    reportValues.push(reportValueExternal);
  });

  const returnValues: ReturnValues = {
    table: reportValues,
    graph: {
      labels: labels,
      datasets: {
        external: {
          label: "Externos",
          data: externalCounts,
        },
        internal: {
          label: "Internos",
          data: internalCounts,
        },
      },
    },
  };

  return returnValues;
};

export default usersByResponseTimeSlice.reducer;
