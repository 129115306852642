import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import moment from "moment";
import { Dispatch } from "react";
import { Microservices } from "../../../app/AllowedMicroservices";
import { RootState } from "../../../app/store";
import apiActionCreator, {
  HttpMethods,
} from "../../../services/apiActionCreator";
import { FetchStatus } from "../../common/enums";
import { IReportValue } from "../common";
import { FetchCasesParams } from "./ticketsByCategory";

interface TicketsTopFiveByCompany {
  data: IReportValue[];
  status: FetchStatus;
}

interface ITicketsTopFiveByCompany {
  count: string;
  id: string;
  logoUrl: string;
  name: string;
}

const initialState: TicketsTopFiveByCompany = {
  data: [],
  status: FetchStatus.Idle,
};

export const ticketsTopFiveSlice = createSlice({
  name: "ticketsTopFive",
  initialState,
  reducers: {
    fetching: (state) => {
      state.status = FetchStatus.Fetching;
    },
    fetched: (state, action: PayloadAction<ITicketsTopFiveByCompany[]>) => {
      state.data = action.payload.map((item) => ({
        key: item.id,
        label: item.name,
        value: Number(item.count),
      }));
      state.status = FetchStatus.Fetched;
    },
    error: (state) => {
      state.status = FetchStatus.Error;
    },
  },
});

const { fetching, fetched, error } = ticketsTopFiveSlice.actions;

export const fetchTicketsTopFiveByCompany =
  ({ from, to, customToken }: FetchCasesParams) =>
  (dispatch: Dispatch<object>) => {
    const fromStrDate = moment(from).format("YYYY-MM-DD");
    const toStrDate = moment(to).format("YYYY-MM-DD");
    return dispatch(
      apiActionCreator({
        endpoint: `cognizant/ticketsByCompany?from=${fromStrDate}&to=${toStrDate}`,
        types: {
          requestType: fetching,
          successTypes: [
            {
              actionOrCreator: fetched,
            },
          ],
          errorTypes: [
            {
              actionOrCreator: error,
            },
          ],
        },
        method: HttpMethods.GET,
        microservice: Microservices.X_ONE,
        authenticated: true,
        customToken,
      })
    );
  };

export const selectTopFiveIsFetching = (state: RootState) =>
  state.reports.case.ticketsTopFive.status === FetchStatus.Fetching;

export const selectCasesByCompanyTopFiveData = (state: RootState) =>
  state.reports.case.ticketsTopFive.data;

export default ticketsTopFiveSlice.reducer;
