import React, { FunctionComponent, useState, useEffect } from "react"
import { IContent } from '../../../../features/knowledgeBase/contentSlice';
import { CHART_COLORS, IReportDataset } from "../../../../features/reports/common";
import { RoundedContainer } from "../../../common/RoundedContainer";
import { BarChartGrouped } from "../../common/BarChartGrouped";
import { TileTile } from "../../Company/common/TileTitle";
import { useKBDataIsFetching } from "../common/useDataIsFetching";

interface ByCategoryData {
  categoryId: string,
  categoryName: string,
  values: IContent[],
}

interface ContentsByCategory {
  [categoryId: string]: ByCategoryData;
}

interface TopProps {
  topWhat: number,
  list: IContent[],
}
export const TopCategoriesWithMostContent: FunctionComponent<TopProps> = ({ topWhat, list }) => {
  const dataIsFetching = useKBDataIsFetching();
  const [topCategories, setTopCategories] = useState<ByCategoryData[]>([]);

  useEffect(() => {
    if (dataIsFetching) {
      return;
    }
    const contentsByCategoryId = list.reduce((contentsByCategoryId, current) => {
      const categoryId = current.category as string;
      if (!contentsByCategoryId[categoryId]) {
        contentsByCategoryId[categoryId] = {
          categoryId,
          categoryName: current.categoryName,
          values: [],
        } as ByCategoryData;
      }
      contentsByCategoryId[categoryId].values.push(current);
      return contentsByCategoryId;
    }, {} as ContentsByCategory);

    const byCategoryData = Object.values(contentsByCategoryId);
    const byCategoryDataSorted = byCategoryData.sort((a, b) => (b.values.length - a.values.length));
    setTopCategories(byCategoryDataSorted.slice(0, topWhat));
  }, [dataIsFetching, list, topWhat]);
  const chartDatasets = buildChartData(topCategories, list.length);

  return (
    <RoundedContainer>
      <TileTile text={`Top ${topWhat} de Categorías con más bases de conocimiento`} />
      <BarChartGrouped
        datasets={chartDatasets}
        labels={['']}
        labelColumnTitle=""
        fetching={dataIsFetching}
        yTickCallBack={(value: number) => (value + '%')}
        labelCallback={(tooltipItem: any, data: any) => {
          const { value = 0 } = tooltipItem;
          return (parseFloat(value)).toFixed(2) + '%';
        }}
      />
    </RoundedContainer>
  )
}

const colorsForTop = [CHART_COLORS[1], CHART_COLORS[0], CHART_COLORS[2], CHART_COLORS[3], CHART_COLORS[4]]

const buildChartData = (topCategoriesList: ByCategoryData[], total: number) => {
  const results: IReportDataset[] = [];
  topCategoriesList.forEach((item, index) => {
    const value = (100 * (item.values.length || 0)) / total;
    results.push({
      label: item.categoryName,
      data: [value],
      color: colorsForTop[index],
    });
  });
  return results;
}
