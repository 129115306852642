enum PasswordRule {
  length = 'Invalid length',
  lowercase = 'Must contain at least 1 lowercase char',
  uppercase = 'Must contain at least 1 uppercase char',
  numeric = 'Must contain at least 1 number char',
  special = 'Must contain at least 1 special char',
}

export enum RequirementIndicatorEnum {
  FULFILLED = 'fulfilled',
  UNFULFILLED = 'unfulfilled',
  UNSET = 'unset',
}

interface PasswordValidation {
  valid: boolean,
  errors: PasswordRule[],
}
interface RequirementFlags {
    minCatacterRequirement: RequirementIndicatorEnum,
    numberRequirement: RequirementIndicatorEnum,
    letterRequirement: RequirementIndicatorEnum,
    specialCaracterRequirement: RequirementIndicatorEnum
    allFulfiled: RequirementIndicatorEnum
}
const MIN_LENGTH = 8;
const checkForLowerCase = new RegExp('(?=.*[a-z])');
const checkForUpperCase = new RegExp('(?=.*[A-Z])');
const checkForNumericChar = new RegExp('(?=.*[0-9])');
const checkForSpecialChar = new RegExp('(?=.*[!@#$%^&*])');

export const cognizantPasswordContraints = (password: string): PasswordValidation => {
  const errors:PasswordRule[] = [];

  const {
    minCatacterRequirement,
    numberRequirement,
    letterRequirement,
    specialCaracterRequirement
  } = getRequirementFlags(password);

  if(minCatacterRequirement === RequirementIndicatorEnum.UNFULFILLED) {
    errors.push(PasswordRule.length);
  }
  if(!checkForLowerCase.test(password) && letterRequirement === RequirementIndicatorEnum.UNFULFILLED) {
    errors.push(PasswordRule.lowercase);
  }
  if(!checkForUpperCase.test(password) && letterRequirement === RequirementIndicatorEnum.UNFULFILLED) {
    errors.push(PasswordRule.uppercase);
  }
  if(numberRequirement === RequirementIndicatorEnum.UNFULFILLED) {
    errors.push(PasswordRule.numeric);
  }
  if(specialCaracterRequirement === RequirementIndicatorEnum.UNFULFILLED) {
    errors.push(PasswordRule.special);
  }

  return {
    valid: errors.length > 0 ? false : true,
    errors,
  }
}
export  const getRequirementFlags = (password: string): RequirementFlags =>{
  
  let minCatacterRequirement = RequirementIndicatorEnum.UNSET;
  let numberRequirement = RequirementIndicatorEnum.UNSET;
  let letterRequirement = RequirementIndicatorEnum.UNSET;
  let specialCaracterRequirement = RequirementIndicatorEnum.UNSET;
  let allFulfiled = RequirementIndicatorEnum.UNSET;
  if(password === ''){
    return {
      minCatacterRequirement,
      numberRequirement,
      letterRequirement,
      specialCaracterRequirement,
      allFulfiled
    };
  }
  allFulfiled = RequirementIndicatorEnum.FULFILLED;

  if(password.length < MIN_LENGTH) {
    minCatacterRequirement = RequirementIndicatorEnum.UNFULFILLED
    allFulfiled = RequirementIndicatorEnum.UNFULFILLED
  }else{
    minCatacterRequirement = RequirementIndicatorEnum.FULFILLED
  }
  if(!checkForNumericChar.test(password)) {
    numberRequirement = RequirementIndicatorEnum.UNFULFILLED
    allFulfiled = RequirementIndicatorEnum.UNFULFILLED
  }else{
    numberRequirement = RequirementIndicatorEnum.FULFILLED
  }

  if(!checkForLowerCase.test(password) || !checkForUpperCase.test(password)) {
    letterRequirement = RequirementIndicatorEnum.UNFULFILLED
    allFulfiled = RequirementIndicatorEnum.UNFULFILLED
  }else{
    letterRequirement = RequirementIndicatorEnum.FULFILLED
  }

  if(!checkForSpecialChar.test(password))  {
    specialCaracterRequirement = RequirementIndicatorEnum.UNFULFILLED
    allFulfiled = RequirementIndicatorEnum.UNFULFILLED
  }else{
    specialCaracterRequirement = RequirementIndicatorEnum.FULFILLED
  }
  return {
    minCatacterRequirement,
    numberRequirement,
    letterRequirement,
    specialCaracterRequirement,
    allFulfiled
  };
}
