import React, { FunctionComponent } from 'react';
import { Layout } from 'antd';
import {
  Router,
  Switch,
  Route,
} from "react-router-dom";
import * as H from 'history';

import { Header } from '../../components/Header';
import { FinishRegistration } from '../../components/Authentication/FinishRegistration';
import { PasswordChange } from '../../components/Authentication/PasswordChange';
import { Login } from '../../components/Authentication/Login';
import { Message } from '../../components/common/Message';
import { Welcome } from '../../components/Authentication/Welcome';
import { Group } from '../../components/Group';
import { CompanyProfile } from '../../components/Company/CompanyProfile';
import { StudentProfile } from '../../components/Profile/StudentProfile';
import { Students } from '../../components/Users/Students';
import { KnowledgeBase } from '../../components/KnowledgeBase';
import { Campaigns } from '../../components/Campaign';
import { Reports } from '../../components/Reports';
import { LoggedUserProfile } from '../../components/LoggedUser/LoggedUserProfile';
import { AdminUsers } from '../../components/Users/AdminUsers';
import ScrollToTop from '../../components/common/ScrollToTop';
import { AllNotifications } from '../../components/Students/Notifications';
import { Notifications } from '../../components/Notifications';
import { Profile } from "../../components/Profile";
import { PrintableProfile } from "../../components/Printable/Profile";
import { CompanyPrintableProfile } from "../../components/Printable/CompanyProfile";
import { AnalyticsCompanyDimSetter } from '../../components/common/AnalyticsCompanyDimSetter';
import { Courses } from "../../components/Courses";
import { Topic } from "../../components/Topic";
import { Lessons } from "../../components/Lesson";
import { Evaluations } from "../../components/Evaluation";
import { Program } from "../../components/Program";

const { Content } = Layout;

interface AdminRouterProps {
  history: H.History<any>;
}

const CompanyAdminRouter: FunctionComponent<AdminRouterProps> = ({ history }) => {
  return <Router history={history}>
    <AnalyticsCompanyDimSetter />
    <ScrollToTop />
    <Layout>
      <Header isLoggedIn />
      <Content className="site-layout" style={{ position: "relative" }}>
        <Switch>
          <Route path="/my-profile">
            <LoggedUserProfile />
          </Route>
          <Route path="/new-password">
            <FinishRegistration />
          </Route>
          <Route path="/change-password">
            <PasswordChange />
          </Route>
          <Route path="/welcome">
            <Welcome />
          </Route>
          <Route path="/groups">
            <Group />
          </Route>
          <Route path="/companies/:id/print">
            <CompanyPrintableProfile />
          </Route>
          <Route path="/companies/:id">
            <CompanyProfile />
          </Route>
          <Route path="/students/:id/print">
            <PrintableProfile />
          </Route>
          <Route path="/students/:id">
            <StudentProfile />
          </Route>
          <Route path="/students">
            <Students />
          </Route>
          <Route path="/admin-users/:id/print">
            <PrintableProfile />
          </Route>
          <Route path="/admin-users/:id">
            <Profile />
          </Route>
          <Route path="/admin-users">
            <AdminUsers />
          </Route>
          <Route path="/campaigns">
            <Campaigns />
          </Route>
          <Route path="/lessons">
            <Lessons />
          </Route>
          <Route path="/evaluations">
            <Evaluations />
          </Route>
          <Route path="/courses">
            <Courses />
          </Route>
          <Route path="/topics">
            <Topic />
          </Route>
          <Route path="/programs">
            <Program />
          </Route>
          <Route path="/knowledgeBase">
            <KnowledgeBase />
          </Route>
          <Route path="/reports">
            <Reports />
          </Route>
          <Route path="/notifications">
            <Notifications />
          </Route>
          <Route path="/all/notifications">
            <AllNotifications />
          </Route>
          <Route path="/">
            <Login />
          </Route>
        </Switch>
      </Content>
      <Message />
    </Layout>
  </Router>
};

export default CompanyAdminRouter;