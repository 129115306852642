import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Dispatch } from "react";
import { FetchStatus } from "../../common/enums";
import apiActionCreator, {
  HttpMethods,
} from "../../../services/apiActionCreator";
import { Microservices } from "../../../app/AllowedMicroservices";
import { RootState } from "../../../app/store";
import { IRecord, InsAndOuts, IRecordsAtTimeWindow, EnableDisableProps } from "./insAndOutsCommon";
import { processForInOutsPerDay } from "./insAndOutsPerDay";

interface EntriesAndExitsState {
  fetchStatus: FetchStatus,
  insAndOutsPerDay: InsAndOuts,
}

const initialState: EntriesAndExitsState = {
  fetchStatus: FetchStatus.Idle,
  insAndOutsPerDay: {},
};

interface EnabledAnDisabledFetch {
  atStartDate: IRecord[];
  betweenStartAndEndDates: IRecordsAtTimeWindow;
}

const enablingDisablingCompanySlice = createSlice({
  name: "insAndOutsPerDayAggregated",
  initialState,
  reducers: {
    fetching: (state) => {
      state.fetchStatus = FetchStatus.Fetching;
    },
    fetched: (state, action: PayloadAction<EnabledAnDisabledFetch>) => {
      const inOutsPerDayResults = processForInOutsPerDay(
        action.payload.betweenStartAndEndDates
      );
      state.insAndOutsPerDay = inOutsPerDayResults;
      state.fetchStatus = FetchStatus.Fetched;
    },
    fetchingFailed: (state) => {
      state.fetchStatus = FetchStatus.Error;
    },
  },
});

export const { fetching, fetched, fetchingFailed } =
  enablingDisablingCompanySlice.actions;

export const fetchInsAndOutsPerCompanyPerDayData =
  ({ yyyyMmDdEndDate, utcOffset }: EnableDisableProps) =>
  (dispatch: Dispatch<object>) => {
    return dispatch(
      apiActionCreator({
        endpoint: `/companies/insAndOuts?endDate=${yyyyMmDdEndDate}&utcOffset=${utcOffset}`,
        types: {
          requestType: fetching,
          successTypes: [
            {
              actionOrCreator: fetched,
            },
          ],
          errorTypes: [
            {
              actionOrCreator: fetchingFailed,
            },
          ],
        },
        method: HttpMethods.GET,
        microservice: Microservices.COMPANY_SERVICE,
        authenticated: true,
      })
    );
  };

export const selectDataIsFetching = (state: RootState) =>
  state.reports.company.insAndOutsNew.perDayAggregated.fetchStatus === FetchStatus.Fetching;
export const selectInsAndOutsPerCompanyPerDayData = (state: RootState) =>
  state.reports.company.insAndOutsNew.perDayAggregated.insAndOutsPerDay;

export default enablingDisablingCompanySlice.reducer;
