import React, { FunctionComponent, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import ctx from 'classnames';
import { Card, Progress } from 'antd';

import {
    selectStudentCourse,
    selectStudentCourseAdvancePercentage,
} from '../../../features/students/courseSlice';
import {
    fetchCategories
} from '../../../features/category/categorySlice';

import styles from './courseInfo.module.scss';

interface StudentLessonCourseInfoProps {
    className?: string,
}

export const StudentLessonCourseInfo: FunctionComponent<StudentLessonCourseInfoProps> = ({ className = '' }) => {
    const course = useSelector(selectStudentCourse);
    const advancePercentage = useSelector(selectStudentCourseAdvancePercentage) || 0;
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(fetchCategories())
    }, [dispatch])

    if (!course) {
        return null;
    }
    return <Card className={ctx(styles[className], styles.container)}>
        <div className={styles.title}>{course.title}</div>
        <div className={styles.progress}>
            <div className={styles.percentage}>{(advancePercentage * 100).toFixed(0)}%</div>
            <Progress percent={advancePercentage * 100} showInfo={false} />
        </div>
    </Card>
}