import React, { FunctionComponent } from "react";
import ctx from "classnames";
import { Input } from "antd";

import { ViewProps } from "./common";

import styles from "./push.module.scss";

const { TextArea } = Input;

export const SMS: FunctionComponent<ViewProps> = ({ message, setMessage, maxMessageCount }) => {
  return (
    <div className={ctx(styles.container, styles.sms)}>
      <div className={styles.label}>Mensaje</div>
      <TextArea
        value={message}
        onChange={event => {
          setMessage(event.target.value);
        }}
        className={styles.textarea}
        rows={4}
      />
      <div className={styles.counter}>{message.length} / {maxMessageCount}</div>
    </div>
  );
};
