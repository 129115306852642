import { FunctionComponent, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCompanies, selectCompanies, selectCompaniesAreFetching } from '../../../../features/company/companySlice';
import { fetchCourses, selectAreCoursesFetching, selectCoursesDict } from '../../../../features/courses/coursesSlice';
import { fetchEvaluations, selectAreEvaluationFetching } from '../../../../features/evaluation/evaluationsSlice';
import { fetchGroups, selectAreGroupsFetching, selectGroups } from '../../../../features/groups/groupSlice';
import { fetchLessons, selectAreLessonsFetching } from '../../../../features/lesson/lessonSlice';
import { fetchPrograms, selectAreProgramsFetching, selectProgramsDict } from '../../../../features/program/programSlice';
import { setCompaniesContents, setCompanyContentFetching } from '../../../../features/reports/learning/common/companiesContent';
import { fetchTopics, selectAreTopicsFetching, selectTopics } from '../../../../features/topic/topicSlice';
import { fetchStudents, selectAreUsersFetching, selectStudents } from '../../../../features/users/usersSlice';
import { ICompanyContent, IGroupContent } from './interfaces';

export const CommonFetcher: FunctionComponent = () => {
  //evaluations
  const isEvaluationsFetching = useSelector(selectAreEvaluationFetching);
  //students
  const students = useSelector(selectStudents);
  const isStudentsFetching = useSelector(selectAreUsersFetching);
  // Programs
  const programsStatusFetching = useSelector(selectAreProgramsFetching);
  const programs = useSelector(selectProgramsDict);
  // Lessons
  const lessonsStatusFetching = useSelector(selectAreLessonsFetching);
  // Courses
  const coursesStatusFetching = useSelector(selectAreCoursesFetching);
  const courses = useSelector(selectCoursesDict);
  // Topics
  const topicsStatusFetching = useSelector(selectAreTopicsFetching);
  const topics = useSelector(selectTopics);
  // Companies
  const isCompaniesFetching = useSelector(selectCompaniesAreFetching);
  const companies = useSelector(selectCompanies);
  // Groups 
  const isGroupsFetching = useSelector(selectAreGroupsFetching);
  const groups = useSelector(selectGroups);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setCompanyContentFetching())
    dispatch(fetchPrograms());
    dispatch(fetchGroups());
    dispatch(fetchCompanies());
    dispatch(fetchStudents());
    dispatch(fetchLessons());
    dispatch(fetchCourses());
    dispatch(fetchTopics());
    dispatch(fetchEvaluations());
  }, [dispatch]);

  const contentsAreFetching = isStudentsFetching || 
    isCompaniesFetching ||
    programsStatusFetching ||
    lessonsStatusFetching ||
    coursesStatusFetching ||
    topicsStatusFetching ||
    isEvaluationsFetching ||
    isGroupsFetching;
    ;

  useEffect(() => {
    if (contentsAreFetching) {
      return;
    }
    const companiesContent: ICompanyContent = {};
    const groupsContents: IGroupContent = {};
    groups.forEach(group=>{
      const groupId = group._id;
      const companyId = group.company;
      const studentsCount = Object.values(students).filter(student => student.studentGroupId === groupId && student.enabled).length;
      group.courses.forEach(courseId => {
        const course = courses[courseId];
        if(!course || !course.topics){
          return;
        }
        const topicsIds: string[] = course?.topics;
        const lessonsIds: string[] = [];
        course.topics.forEach((topicId) => {
          const topic = topics[topicId];
          if(topic){
            lessonsIds.push(...topic.lessons);
          }
        });
        if (!groupsContents[groupId]) {
          groupsContents[groupId] = {
            courses: [],
            topics: [],
            lessons: [],
            students: studentsCount,
          };
        }
        groupsContents[groupId].lessons.push(...lessonsIds);
        groupsContents[groupId].courses.push(courseId);
        groupsContents[groupId].topics.push(...topicsIds);
        if (!companiesContent[companyId]) {
          companiesContent[companyId] = {
            courses: [],
            topics: [],
            lessons: [],
            students: studentsCount,
          };
        }
        companiesContent[companyId].lessons.push(...lessonsIds);
        companiesContent[companyId].courses.push(courseId);
        companiesContent[companyId].topics.push(...topicsIds);
      });
    });
    
    dispatch(setCompaniesContents({
      byCompany: companiesContent,
      byGroups: groupsContents,
    }));
    return;
  }, [companies, contentsAreFetching, courses, dispatch, programs, students, topics, groups]);

  return null;
};