export enum EvaluationTypeEnum {
  Quiz = "QUIZ",
  Test = "TEST",
  Exam = "EXAM",
  Survey = "SURVEY",
}

export enum EvaluationTypeLabelEnum2 {
  QUIZ = "el quiz",
  TEST = "el test",
  EXAM = "el examen",
  SURVEY = "la encuesta",
}

export enum EvaluationTypeLabelEnum {
  QUIZ = "Quiz",
  TEST = "Test",
  EXAM = "Examen",
  SURVEY = "Encuesta",
}

export enum RegisterEvaluationStatus {
  Registering = "Registering",
  Registered = "Registered",
  Error = "Error",
  Idle = "Idle",
}

export enum ParentTypeEnum {
  Lesson = "LESSON",
  Topic = "TOPIC",
  CourseExam = "COURSE_EXAM",
  CourseSurvey = "COURSE_SURVEY",
  Campaign = "CAMPAIGN",
}
