import { Col, Row } from "antd";
import moment from "moment";
import React, { FunctionComponent, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCognitoUserData } from "../../../features/auth/authSlice";
import { fetchByRange as fetchCategoryByReportByRange } from "../../../features/reports/case/ticketsByCategory";
import { fetchTicketsTopFiveByCompany } from "../../../features/reports/case/ticketsByCompanyTopFive";
import { fetchByRange } from "../../../features/reports/case/ticketsByReportType";
import { fetchAllCases } from "../../../features/reports/case/ticketsByStatus";
import {
  getAuthToken,
  selectAuthToken,
} from "../../../features/reports/case/xOneToken";
import { UserRole } from "../../../features/users/usersSlice";
import { RoundedContainer } from "../../common/RoundedContainer";
import { AnalyticsViewReport } from "../common/AnalyticsViewReport";
import { ReportTypes, sectionTitles } from "../MenuAndRoutes";
import { CasesByUserType } from "./CasesByUserType";
import { CasesDoughnut } from "./CasesDoughnut";
import { CasesStats } from "./CasesStats";
import { CasesTop5 } from "./CasesTop5";

interface Props {
  companyId?: string;
}

export const CasesReports: FunctionComponent<Props> = ({ companyId }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const cognitoData = useSelector(getCognitoUserData);
  const role = cognitoData ? cognitoData["custom:role"] : undefined;
  const xOneAuth = useSelector(selectAuthToken);
  const dispatch = useDispatch();
  const userCompanyId: string | undefined = cognitoData
    ? cognitoData["custom:company"]
    : undefined;
  const currentCompanyId =
    role === UserRole.SuperAdmin ? companyId : userCompanyId;

  useEffect(() => {
    const getToken = async () => {
      await dispatch(getAuthToken());
      setIsAuthenticated(true);
    };
    getToken();
  }, [dispatch]);

  useEffect(() => {
    if (xOneAuth.access_token && isAuthenticated) {
      setIsAuthenticated(true);
      const from = moment("2021-09-01"); // this is hardcoded due to client request
      const to = moment();
      const customToken = `Bearer ${xOneAuth.access_token}`;
      dispatch(
        fetchByRange({ from, to, customToken, companyId: currentCompanyId })
      );
      dispatch(
        fetchAllCases({ from, to, customToken, companyId: currentCompanyId })
      );
      dispatch(
        fetchCategoryByReportByRange({
          from,
          to,
          customToken,
          companyId: currentCompanyId,
        })
      );
      dispatch(fetchTicketsTopFiveByCompany({ from, to, customToken }));
    }
  }, [dispatch, isAuthenticated, xOneAuth.access_token, currentCompanyId]);

  return (
    <>
      <AnalyticsViewReport reportName={sectionTitles[ReportTypes.Cases]} />
      <Row gutter={[24, 24]} style={{ marginBottom: 46 }}>
        <Col span={12}>
          <RoundedContainer>
            <CasesStats />
          </RoundedContainer>
        </Col>
        <Col span={12}>
          <RoundedContainer>
            <CasesDoughnut />
          </RoundedContainer>
        </Col>
        <Col span={role === UserRole.SuperAdmin ? 12 : 24}>
          <RoundedContainer>
            <CasesByUserType />
          </RoundedContainer>
        </Col>
        {role === UserRole.SuperAdmin && (
          <Col span={12}>
            <RoundedContainer>
              <CasesTop5 />
            </RoundedContainer>
          </Col>
        )}
      </Row>
    </>
  );
};
