import Vimeo from "@u-wave/react-vimeo";
import { Player, VimeoCuePointData } from "@vimeo/player";
import cls from 'classnames';
import React, { FunctionComponent, useEffect, useState } from "react";
import { QuestionsAnswers } from "../../../features/evaluation/common/interfaces";
import { IMedia, IQuizCuePoint } from "../../../features/lesson/lessonSlice";
import { SIZES } from '../../common/screen';
import { useDeviceSize } from '../../utils/useDeviceSize';
import { Quiz } from "../Evaluation/Quiz";
import styles from "./video.module.scss";

interface VideoLessonProps {
  courseId: string;
  lessonId: string;
  media: IMedia;
  quizCuePoints: IQuizCuePoint[];
  evaluationId?: string;
  onEndVideo(): void;
}
let playerInstance: Player | null = null;

interface IVisitedMarks {
  [_i: string]: boolean;
}

declare global {
  interface Document {
    mozCancelFullScreen?: () => Promise<void>;
    msExitFullscreen?: () => Promise<void>;
    webkitExitFullscreen?: () => Promise<void>;
    onwebkitfullscreenchange?: () => void;
    mozFullScreenElement?: Element;
    msFullscreenElement?: Element;
    webkitFullscreenElement?: Element;
    onmozfullscreenchange?: () => void;
    onmsfullscreenchange?: () => void;
  }

  interface HTMLElement {
    msRequestFullscreen?: () => Promise<void>;
    mozRequestFullscreen?: () => Promise<void>;
    webkitRequestFullscreen?: () => Promise<void>;
    onwebkitfullscreenchange?: () => void;
    onmozfullscreenchange?: () => void;
    onmsfullscreenchange?: () => void;
  }
}

export const VideoLesson: FunctionComponent<VideoLessonProps> = ({
  courseId,
  media,
  quizCuePoints = [],
  lessonId,
  evaluationId,
  onEndVideo,
}) => {
  const size = useDeviceSize();
  const [answersSelected, setAnswerSelected] = useState<QuestionsAnswers>({});
  const [showQuiz, setShowQuiz] = useState<boolean>(false);
  //   const [progress, setProgress] = useState<number>(0);
  //   const [marks, setMarks] = useState<IMarks>({});
  const [visitedMarks, setVisitedMarks] = useState<IVisitedMarks>({});
  //   const [isPlaying, setIsPlaying] = useState<boolean>(false);
  // const [isFullscreen, setIsFullscreen] = useState<boolean>(false);
  const [playerIsSet, setPlayerIsSet] = useState<boolean>(false);
  const { url } = media;
  const video: string = url.split("/").pop() || "";
  //   const { duration } = videoInfo;
  useEffect(() => {
    if (playerInstance && playerIsSet) {
      //   const newMarks: IMarks = {};
      quizCuePoints.forEach((quizCuePoint, index) => {
        playerInstance?.addCuePoint(quizCuePoint.time, {
          questionId: quizCuePoint.questionId,
          time: quizCuePoint.time,
        });
        // const left = Math.floor((quizCuePoint.time / duration) * MAX);
        // newMarks[left] = `${index + 1}`;
      });
      //   setMarks(newMarks);
      playerInstance.on("fullscreenchange", (data) => {
        //   setIsFullscreen(data.fullscreen);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [playerIsSet]);
  // const sliderValue = progress;

  const isFullscreenEnabled = () => {
    return (
      document.fullscreenElement ||
      document.mozFullScreenElement ||
      document.webkitFullscreenElement ||
      document.msFullscreenElement
    );
  };
  const toggleFullscreen = function() {
    var fullscreenChange: any = null;
    // other vars …

    // Check for fullscreen support
    if (isFullscreenEnabled()) {
      // If there's currently an element fullscreen, exit fullscreen
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      }

      // Do stuff when the video exits fullscreen mode
      // …
    } else {
      const videoWrapper: any = document.getElementById("videoWrapper");
      // Otherwise, enter fullscreen
      // `videoWrapper` is just a `div` element wrapping the video
      if (videoWrapper?.requestFullscreen) {
        videoWrapper.requestFullscreen();
      } else if (videoWrapper?.mozRequestFullScreen) {
        videoWrapper?.mozRequestFullScreen();
      } else if (videoWrapper?.webkitRequestFullscreen) {
        videoWrapper.webkitRequestFullscreen();
      } else if (videoWrapper?.msRequestFullscreen) {
        videoWrapper.msRequestFullscreen();
      }

      // Do stuff when the video enters fullscreen mode
      // …
    }

    fullscreenChange = function() {
      // Do something on fullscreen change event
      // …
    };

    document.onfullscreenchange = function() {
      if (!document.fullscreenElement) {
        fullscreenChange();
      }
    };
    document.onwebkitfullscreenchange = function() {
      if (!document.webkitFullscreenElement) {
        fullscreenChange();
      }
    };
    document.onmozfullscreenchange = function() {
      if (!document.mozFullScreenElement) {
        fullscreenChange();
      }
    };
    document.onmsfullscreenchange = function() {
      if (!document.msFullscreenElement) {
        fullscreenChange();
      }
    };
  };

  return (
    <div id="videoWrapper" className={styles.container}>
      {evaluationId && showQuiz ? (
        <Quiz
          courseId={courseId}
          lessonId={lessonId}
          className={styles.quizContainer}
          quizId={evaluationId || ""}
          onEvaluationComplete={() => {}}
          onNextQuestion={() => {
            setShowQuiz(false);
            playerInstance?.play();
          }}
          answers={answersSelected}
          questionToShow={Object.keys(answersSelected).length}
          onChangeAnswer={(answers) => {
            setAnswerSelected(answers);
          }}
          showOnlyOneQuestion
        />
      ) : null}
      <Vimeo
        className={styles.vimeo}
        controls
        video={video}
        width={"100vw"}
        responsive
        height={444}
        onPlay={() => {
          //   setIsPlaying(true);
        }}
        onPause={() => {
          //   setIsPlaying(false);
        }}
        onEnd={() => {
          //   setIsPlaying(false);
          //   setProgress(MAX);
          let isEvaluationComplete = !evaluationId;
          quizCuePoints.forEach(
            (cuePoint) =>
              (isEvaluationComplete = visitedMarks[cuePoint.questionId])
          );

          if (isEvaluationComplete) {
            onEndVideo();
          }
        }}
        onReady={(player) => {
          playerInstance = player;
          setPlayerIsSet(true);
        }}
        onCuePoint={(point) => {
          if (playerInstance) {
            const { data }: VimeoCuePointData = point;
            if (!visitedMarks[data.questionId]) {
              playerInstance.pause();
              if (isFullscreenEnabled()) {
                toggleFullscreen();
              }
              const newVisitedMarks: IVisitedMarks = { ...visitedMarks };
              newVisitedMarks[data.questionId] = true;
              setVisitedMarks(newVisitedMarks);
              setShowQuiz(true);
            }
          }
        }}
        onTimeUpdate={(event) => {
          //   setProgress(Math.floor((event.seconds / duration) * MAX));
        }}
      />
      <div className={cls(styles.seekBlocker,{[styles.mobile]:size === SIZES.PHONE} )} />
      {/* <Slider
      className={ctx(styles.slider, {
          [styles.fullscreen]: isFullscreen,
      })}
      tooltipVisible={false}
      min={0}
      max={MAX}
      value={sliderValue}
      marks={marks}
      defaultValue={37}
      onChange={(value: number)=>{
          if(playerInstance){
              playerInstance.setCurrentTime(value/MAX * duration);
          }
      }}
    /> */}
    </div>
  );
};
