import { Tooltip } from "antd";
import React from "react";
import award from "../../../assets/award.png";
import bullseye from "../../../assets/bullseye.png";
import { CogIcon } from "../../common/CogIcon";
import styles from "./TopFiveAward.module.scss";

interface Props {
  isInTop5: boolean;
  tooltipText: string;
  topAmount?: number;
}

export const TopFiveAward: React.FunctionComponent<Props> = ({
  isInTop5,
  tooltipText,
  topAmount = 10,
}) => {
  const title = isInTop5
    ? "Tu empresa está"
    : "Aumenta tu actividad en la plataforma y";
  const subtitle = isInTop5 ? `En el TOP ${topAmount}` : `Sé parte del TOP ${topAmount}`;
  const text = isInTop5
    ? tooltipText
    : `${tooltipText}, motiva a tu equipo para alcanzar este top`;
  return (
    <div className={styles.container}>
      <div className={styles.contentWrapper}>
        <div className={styles.imageWrapper}>
          <img src={isInTop5 ? award : bullseye} alt={subtitle} />
        </div>
        <p>{title}</p>
        <div className={styles.subtitle}>
          <h3>{subtitle}</h3>
          <Tooltip placement="right" title={text} className={styles.tooltip}>
            <div>
              <CogIcon size="1.4em" color="#81858b" icon={"Attention"} />
            </div>
          </Tooltip>
        </div>
      </div>
    </div>
  );
};
