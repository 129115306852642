import React, { FunctionComponent, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectCompanies, selectCompaniesAreFetching } from "../../../../features/company/companySlice";
import { selectUserStats } from "../../../../features/reports/company/userStats";
import { selectUsersSessionDurationIsFetching } from "../../../../features/reports/users/sessionDuration";
import { selectStudentsPerCompany } from "../../../../features/users/usersSlice";
import LoadingOverlay from "../../../common/LoadingOverlay";
import { RoundedContainer } from "../../../common/RoundedContainer";
import { StatusEnum } from "../../../common/Status";
import { BarChartSimple } from "../../common/BarChartSimple";
import { TileTile } from "../common/TileTitle";
import { getCoutriesDictionary } from "../common/utils";

interface UserUsagesByCountry {
  countryName: string,
  averages: number[],
}

interface UserUsagesAveragesByCountry {
  countryName: string,
  activeCompaniesPercentage: number,
}
interface TopCountriesWithActiveCompaniesProps {
  topWhat: number,
}

export const TopCountriesWithActiveCompanies: FunctionComponent<TopCountriesWithActiveCompaniesProps> = ({ topWhat }) => {
  const isFetchingCompanies = useSelector(selectCompaniesAreFetching);
  const isUserSessionDurationFetching = useSelector(selectUsersSessionDurationIsFetching);
  const allCompanies = useSelector(selectCompanies);
  const [countriesWithMoreActivity, setCountriesWithMoreActivity] = useState<UserUsagesAveragesByCountry[]>([]);
  const userStats = useSelector(selectUserStats);
  const studentsPerCompany = useSelector(selectStudentsPerCompany);
  const { companiesWithUserUsage = [] } = userStats;

  const dataIsFetching = isFetchingCompanies || isUserSessionDurationFetching;
  useEffect(() => {
    if (dataIsFetching) {
      return;
    }

    const companiesWithAverage: Record<string, number> = companiesWithUserUsage.reduce((acc, current) => {
      acc[current.companyId] = current.avgActiveUserPercentage;
      return acc;
    }, {} as Record<string, number>);

    const countriesDict = getCoutriesDictionary();

    const allCompaniesArr = Object.values(allCompanies);
    const enabledCompanies = allCompaniesArr.filter(company => company.status === StatusEnum.Enable);

    const userUsageByCountry: Record<string, UserUsagesByCountry> = {};
    for(const company of enabledCompanies) {
      const { country: countryCode, _id: companyId } = company;
      if (!userUsageByCountry[countryCode]) {
        userUsageByCountry[countryCode] = {
          countryName: countriesDict[countryCode].countryName,
          averages: [],
        };
      }

      if (companiesWithAverage[companyId] >= 0) {
        userUsageByCountry[countryCode].averages.push(companiesWithAverage[companyId]);
      } else {
        userUsageByCountry[countryCode].averages.push(0);
      }
    }

    const userUsageByCountryArr = Object.values(userUsageByCountry);

    const countriesWithAverageUsage: UserUsagesAveragesByCountry[] = userUsageByCountryArr.map(countryData => {
      const { averages = [], countryName = '' } = countryData;
      const activeCompaniesAverages = averages.filter(value => value > 0);
      return {
        countryName,
        activeCompaniesPercentage: (100 * activeCompaniesAverages.length) / averages.length,
      }
    });
    setCountriesWithMoreActivity(countriesWithAverageUsage);

  }, [dataIsFetching, allCompanies, studentsPerCompany, companiesWithUserUsage])

  const topCountriesData = fillWithEmptyDataIfNeeded(countriesWithMoreActivity, topWhat);
  const dataForChart = prepareDataForChart(topCountriesData);

  const height = 300;

  return (
    <RoundedContainer>
      <TileTile text={`Top ${topWhat} paises con más empresas activas.`} />
      {dataIsFetching ?
        (<LoadingOverlay inline height={height} spinning />) :
        (
          <BarChartSimple
            height={height}
            fetching={dataIsFetching}
            labelColumnTitle=""
            data={dataForChart}
            xMaxValue={100}
            xTickCallBack={(value: number) => (value + '%')}
            labelCallback={(tooltipItem: any, data: any) => {
              const dataset = data.datasets[tooltipItem.datasetIndex];
              return `${(dataset.data[tooltipItem.index].x).toFixed(2)}%`;
            }}
          />
        )
      }
    </RoundedContainer>
  )
}

const fillWithEmptyDataIfNeeded = (data: UserUsagesAveragesByCountry[], topWhat: number): UserUsagesAveragesByCountry[] => {
  const results = [...data];
  for (let i = data.length; i < topWhat; i++) {
    results.push({
      countryName: 'No hay datos',
      activeCompaniesPercentage: 0,
    })
  }
  return results.slice(0, topWhat)
}

interface DataForChart {
  key: string,
  label: string,
  value: number,
}

const prepareDataForChart = (countriesData: UserUsagesAveragesByCountry[]) => {
  const dataForChart: DataForChart[] = [];
  countriesData.forEach(item => {
    dataForChart.push({
      key: item.countryName,
      label: item.countryName,
      value: Math.round(item.activeCompaniesPercentage * 10) / 10,
    })
  });

  return dataForChart;
}
