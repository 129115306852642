import React, { FunctionComponent, useEffect } from 'react';
import { Layout } from 'antd';
import {
    Router,
    Switch,
    Route,
    Redirect,
} from "react-router-dom";
import * as H from 'history';

import { Header } from '../../components/Header';
import { Message } from '../../components/common/Message';
import { StudentHome } from '../../components/Students/Home';

import { LoggedUserProfile } from '../../components/LoggedUser/LoggedUserProfile';
import { StudentMyCourses } from '../../components/Students/Courses';
import { StudentCourse } from '../../components/Students/Course';
import { StudentLesson } from '../../components/Students/Lesson';
import { Test } from '../../components/Students/Evaluation/Test';
import { Exam } from '../../components/Students/Evaluation/Exam';
import { KnowledgeBase } from '../../components/Students/KnowledgeBase';
import { KnowledgeBaseSearch } from '../../components/Students/KnowledgeBase/KnowledgeBaseSearch';
import { KnowledgeBaseDetails } from '../../components/Students/KnowledgeBase/KnowledgeBaseDetails';
import { CoursesInProgress } from '../../components/Students/Courses/CoursesInProgress';
import { CoursesPending } from '../../components/Students/Courses/CoursesPending';
import { CoursesCompleted } from '../../components/Students/Courses/CoursesCompleted';
import { CoursesFiltered } from '../../components/Students/Courses/CoursesFiltered';
import ScrollToTop from '../../components/common/ScrollToTop';
import { Congratulation } from '../../components/Students/Course/Congratulations';
import { AllNotifications } from '../../components/Students/Notifications';
import { Support } from '../../components/Support';
import StudentFooter from '../../components/StudentFooter';
import AnalyticsPageTracker from '../../components/common/AnalyticsPageTracker';
import { AnalyticsCompanyDimSetter } from '../../components/common/AnalyticsCompanyDimSetter';

const { Content } = Layout;

interface StudentRouterProps {
    history: H.History<any>;
}

export const StudentRouter: FunctionComponent<StudentRouterProps> = ({ history }) => {
    useEffect(() => {
    }, []);
    return <Router history={history}>
        <AnalyticsCompanyDimSetter />
        <ScrollToTop />
        <AnalyticsPageTracker />
        <Layout>
            <Header isLoggedIn />
            <Content className='site-layout students'>
                <Switch>
                    <Route path="/my-profile">
                        <LoggedUserProfile />
                    </Route>
                    <Route path="/myCourses/in-progress">
                        <CoursesInProgress />
                    </Route>
                    <Route path="/myCourses/pending">
                        <CoursesPending />
                    </Route>
                    <Route path="/myCourses/completed">
                        <CoursesCompleted />
                    </Route>
                    <Route path="/myCourses/filter">
                        <CoursesFiltered />
                    </Route>
                    <Route path="/myCourses">
                        <StudentMyCourses />
                    </Route>
                    <Route path="/course/:id/exam">
                        <Exam />
                    </Route>
                    <Route path="/course/:id/test/:topicId">
                        <Test />
                    </Route>
                    <Route path="/course/:id/congratulations">
                        <Congratulation />
                    </Route>
                    <Route path="/course/:courseId/:lessonId">
                        <StudentLesson />
                    </Route>
                    <Route path="/course/:id">
                        <StudentCourse />
                    </Route>
                    <Route path="/knowledgeBases/search">
                        <KnowledgeBaseSearch />
                    </Route>
                    <Route exact path="/knowledgeBases">
                        <KnowledgeBase />
                    </Route>
                    <Route path="/knowledgeBases/:contentId">
                        <KnowledgeBaseDetails />
                    </Route>
                    <Route path="/all/notifications">
                        <AllNotifications />
                    </Route>
                    <Route path="/support">
                        <Support />
                    </Route>
                    <Route path="/home">
                        <StudentHome />
                    </Route>
                    <Redirect to='/home' />
                </Switch>
            </Content>
            <StudentFooter />
            <Message />
        </Layout>
    </Router>
};



