import React, { FunctionComponent, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { RootState } from '../../../../../app/store';
import { LearningLabels, LearningType } from '../../../../../features/reports/learning/common/interfaces';
import LoadingOverlay from '../../../../common/LoadingOverlay';
import { RoundedContainer } from '../../../../common/RoundedContainer';
import { TopFiveAward } from '../../../common/TopFiveAward';

interface FulfillmentTop10ChartProps {
  fetchIsInTop10: () => void;
  selectIsInTop10: (state: RootState) => boolean;
  selectFetchingInTop10: (state: RootState) => boolean;
  fetchLearningContentCompletionPerCompany: (companyId?: string | undefined) => void,
  learningType: LearningType
}

export const FulfillmentInTop10: FunctionComponent<FulfillmentTop10ChartProps> = ({
  fetchIsInTop10,
  selectIsInTop10,
  selectFetchingInTop10,
  fetchLearningContentCompletionPerCompany,
  learningType
}) => {
  const isFetching = useSelector(selectFetchingInTop10);
  const isInTop10 = useSelector(selectIsInTop10);

  const dispatch = useDispatch();
  useEffect(()=>{
    dispatch(fetchIsInTop10());
    dispatch(fetchLearningContentCompletionPerCompany());
  }, [dispatch, fetchIsInTop10, fetchLearningContentCompletionPerCompany]);

  
  const height = learningType === LearningType.lessons ? 280 : 400;
  const tooltipText = `El top 10 se calcula en base a las empresas con mas cumplimientos en ${LearningLabels[learningType]}`;
  return <RoundedContainer>
    {isFetching ? (
      <LoadingOverlay inline height={height} spinning />
    ) : (
      <TopFiveAward
        isInTop5={isInTop10}
        tooltipText={tooltipText}
        topAmount={10}
      />
    )}
  </RoundedContainer>
}