import { Button, Card, Divider, Form, Input, Popover, Radio, Select } from "antd";
import { FormInstance } from "antd/lib/form";
import { FormListFieldData } from "antd/lib/form/FormList";
import { RadioChangeEvent } from "antd/lib/radio";
import React, { useEffect, useState } from "react";
import { useSelector } from 'react-redux';
import { v4 as uuid_v4 } from "uuid";
import { selectUserRole } from '../../features/auth/authSlice';
import { selectCompanies } from '../../features/company/companySlice';
import {
  EvaluationTypeEnum,
  EvaluationTypeLabelEnum,
} from "../../features/evaluation/common/enums";
import {
  IEvaluation,
  IPossibleAnswer,
  IQuestion,
} from "../../features/evaluation/common/interfaces";
import { UserRole } from '../../features/users/usersSlice';
import { CogIcon } from "../common/CogIcon";
import { StatusEnum } from "../common/Status";
import { MIN_3_CHAR } from "../Users/Form";
import styles from "./form.module.scss";

const ANSWER_MAX_LIMIT = 5;
const ANSWER_MIN_LIMIT = 2;
const QUESTION_MIN_LIMIT = 1;

enum QuestionsLimit {
  QUIZ = 3,
  TEST = 10,
  EXAM = 60,
  SURVEY = 30,
}

interface Answer {
  title: string;
  feedback: string;
}

interface Question {
  title: string;
  answers: Answer[];
  selected: number;
}

export interface Values {
  evaluationTitle: string;
  questions: Question[];
  companyId?: string;
}

interface QuestionCardProps {
  questionsNumber: number;
  form: FormInstance<Values>;
  type: EvaluationTypeEnum;
  currentIndex: number;
  question: FormListFieldData;
  onDuplicateQuestion(question: Question, index: number): void;
  onDeleteQuestion(index: number): void;
}

const QuestionCard: React.FunctionComponent<QuestionCardProps> = ({
  form,
  currentIndex,
  question,
  type,
  questionsNumber,
  onDuplicateQuestion,
  onDeleteQuestion,
}) => {
  const [isVisible, setIsVisible] = useState(false);
  const [selectedAnswer, setSelectedAnswer] = useState(0);
  const [answersNumber, setAnswersNumber] = useState(ANSWER_MIN_LIMIT);
  useEffect(() => {
    const currentQuestions = form.getFieldsValue().questions as Question[];
    if (currentQuestions) {
      setSelectedAnswer(currentQuestions[question.name].selected);
      setAnswersNumber(currentQuestions[question.name].answers.length);
    }
  }, [form, question.name]);
  const handleDelete = () => {
    setIsVisible(false);
    onDeleteQuestion(currentIndex);
  };
  const handleDuplicate = () => {
    const selectedQuestion = form.getFieldsValue().questions[
      question.name
    ] as Question;
    if (selectedQuestion) {
      onDuplicateQuestion(selectedQuestion, currentIndex + 1);
    }
    setIsVisible(false);
  };
  const handleVisibleChange = (value: boolean) => setIsVisible(value);
  const handleSelect = (e: RadioChangeEvent) => {
    const currentQuestions = form.getFieldsValue().questions as Question[];
    currentQuestions[question.name].selected = e.target.value;
    setSelectedAnswer(e.target.value);
    form.setFieldsValue({
      questions: currentQuestions,
    });
  };

  const content = () => (
    <div className={styles.questionPopOver}>
      <Button
        type="link"
        className={styles.duplicateButton}
        onClick={handleDuplicate}
        disabled={questionsNumber >= QuestionsLimit[type]}
      >
        Duplicar
      </Button>
      <Button
        type="link"
        onClick={handleDelete}
        className={styles.deleteButton}
        disabled={questionsNumber <= QUESTION_MIN_LIMIT}
      >
        Eliminar
      </Button>
    </div>
  );

  return (
    <Card className={styles.card}>
      <div className={styles.headerActions}>
        <div className={styles.questionSelector}>
          <CogIcon size="15px" icon="drag" />
          {` ${currentIndex + 1}`}
        </div>
        <Popover
          placement="rightTop"
          content={content}
          visible={isVisible}
          trigger="click"
          onVisibleChange={handleVisibleChange}
        >
          <Button type="link" className={styles.menuButton}>
            <CogIcon size="20px" color="#81858B" icon="More-vert" />
          </Button>
        </Popover>
      </div>
      <Form.Item
        label="Pregunta"
        {...question}
        name={[question.name, "title"]}
        rules={[
          { required: true, message: "Requerido" },
          { type: "string", min: 3, message: MIN_3_CHAR },
          { type: "string", max: 500, message: "Máximo 500 caracteres" },
        ]}
      >
        <Input type="text" placeholder="Escribre la pregunta" />
      </Form.Item>
      <Divider />
      <Radio.Group onChange={handleSelect} value={selectedAnswer}>
        <Form.List name={[question.name, "answers"]}>
          {(answers, { add, remove }) => {
            const handleAddAnswer = (index: number) => () => {
              if (answersNumber < ANSWER_MAX_LIMIT) {
                add(undefined, index + 1);
                setAnswersNumber(answersNumber + 1);
                if (index + 1 <= selectedAnswer) {
                  setSelectedAnswer(selectedAnswer + 1);
                }
              }
            };
            const handleDeleteAnswer = (index: number) => () => {
              if (answersNumber > ANSWER_MIN_LIMIT) {
                remove(index);
                setAnswersNumber(answersNumber - 1);
                if (index === selectedAnswer) {
                  setSelectedAnswer(index === 0 ? index : index - 1);
                }
                if (selectedAnswer >= answersNumber - 1) {
                  setSelectedAnswer(selectedAnswer - 1);
                }
              }
            };
            return (
              <div>
                {answers.map((answer, index) => {
                  return (
                    <div
                      key={`answer-${index}`}
                      className={styles.answerContainer}
                    >
                      {type !== "SURVEY" && <Radio value={index} />}
                      <div className={styles.fieldContainer}>
                        <Form.Item
                          name={[answer.name, "title"]}
                          label="Respuesta"
                          rules={[
                            { required: true, message: "Requerido" },
                            {
                              type: "string",
                              min: 1,
                              message: "Mínimo 1 caracter",
                            },
                            {
                              type: "string",
                              max: 50,
                              message: "Máximo 50 caracteres",
                            },
                          ]}
                        >
                          <Input
                            type="text"
                            placeholder="Escribre la respuesta"
                          />
                        </Form.Item>
                        {type !== "SURVEY" && type !== "EXAM" && (
                          <Form.Item
                            name={[answer.name, "feedback"]}
                            label="Retroalimentación"
                            rules={[
                              { type: "string", min: 3, message: MIN_3_CHAR },
                              {
                                type: "string",
                                max: 500,
                                message: "No puede tener más de 500 caracteres",
                              },
                            ]}
                          >
                            <Input
                              type="text"
                              placeholder="Escribre la retroalimentación"
                            />
                          </Form.Item>
                        )}
                      </div>
                      <div className={styles.actions}>
                        <Button
                          type="default"
                          onClick={handleDeleteAnswer(index)}
                          className={styles.answerButton}
                          icon={
                            <CogIcon size="15px" color="#4376F9" icon="Minus" />
                          }
                          disabled={answersNumber === ANSWER_MIN_LIMIT}
                        />
                        <Button
                          type="default"
                          onClick={handleAddAnswer(index)}
                          className={styles.answerButton}
                          icon={
                            <CogIcon size="15px" color="#4376F9" icon="Plus" />
                          }
                          disabled={answersNumber === ANSWER_MAX_LIMIT}
                        />
                      </div>
                    </div>
                  );
                })}
              </div>
            );
          }}
        </Form.List>
      </Radio.Group>
    </Card>
  );
};

interface FormProps {
  id: string;
  type: EvaluationTypeEnum;
  initialValues: Values;
  isLoading: boolean;
  onClose(): void;
  onCreate(evaluation: IEvaluation): void;
}

const EvaluationForm: React.FunctionComponent<FormProps> = ({
  id,
  initialValues,
  type,
  isLoading,
  onClose,
  onCreate,
}) => {
  const userRole = useSelector(selectUserRole);
  const companies = useSelector(selectCompanies);
  const [questionsNumber, setQuestionsNumber] = useState(0);
  const [form] = Form.useForm<Values>();
  const evaluationLabel = `Nombre del ${EvaluationTypeLabelEnum[
    type
  ].toLowerCase()}`;

  useEffect(() => {
    const currentQuestions = form.getFieldsValue().questions as Question[];
    if (currentQuestions) {
      setQuestionsNumber(currentQuestions.length);
    }
  }, [form]);

  const handleFinish = (values: Values) => {
    const currentQuestions = values.questions.map((question) => {
      let correctAnswer = "";
      const possibleAnswers = question.answers.map((answer, key) => {
        const generatedId = uuid_v4();
        let possibleAnswer: IPossibleAnswer = {
          _id: generatedId,
          label: answer.title,
        };
        if (type !== "SURVEY") {
          if (key === question.selected) {
            correctAnswer = generatedId;
          }
          possibleAnswer.feedback = answer.feedback;
        }
        return possibleAnswer;
      });
      let currentQuestion: IQuestion = {
        title: question.title,
        possibleAnswers,
      };
      if (type !== "SURVEY") {
        currentQuestion.correctAnswer = correctAnswer;
      }
      return currentQuestion;
    });
    onCreate({
      _id: "",
      typeName: type,
      type,
      title: values.evaluationTitle,
      companyId: values.companyId,
      questions: currentQuestions,
      status: StatusEnum.Enable,
    });
  };

  const newQuestion = {
    title: "",
    answers: [
      { title: "", feedback: "" },
      { title: "", feedback: "" },
    ],
    selected: 0,
  };
  return (
    <Form
      form={form}
      initialValues={initialValues}
      layout="vertical"
      onFinish={handleFinish}
    >
      <header>
        <div className={styles.actionContainer}>
          <Button type="default" onClick={onClose}>
            Salir
          </Button>
          <div className={styles.sideButtons}>
            <Button type="primary" htmlType="submit" disabled={isLoading}>
              {`${id !== "" ? "Editar" : "Crear"} ${EvaluationTypeLabelEnum[
                type
              ].toLowerCase()} `}
            </Button>
          </div>
        </div>
      </header>
      <div className={styles.formContainer}>
        <Card className={styles.card}>
          {userRole === UserRole.SuperAdmin ? 
            <Form.Item
              label="Compañia"
              name="companyId"
            >
              <Select
                placeholder=""
                style={{ width: "100%" }}
              >
                <Select.Option key={'all_companies'} value={''}>
                    Todas
                  </Select.Option>
                {Object.values(companies).map(company => {
                  return <Select.Option key={company._id} value={company._id}>
                    {company.name}
                  </Select.Option>
                })}
              </Select>
            </Form.Item>
          : <Form.Item 
              style={{display: 'none'}}
              name="companyId"
            ><Input type="hidden" /></Form.Item>}
          <Form.Item
            name="evaluationTitle"
            label={evaluationLabel}
            rules={[
              { required: true, message: "Requerido" },
              { type: "string", min: 3, message: MIN_3_CHAR },
              { type: "string", max: 50, message: "Máximo 50 caracteres" },
            ]}
          >
            <Input type="text" placeholder={evaluationLabel} />
          </Form.Item>
        </Card>
        <Form.List name="questions">
          {(questions, { add, remove }) => {
            const handleAdd = () => {
              if (questionsNumber < QuestionsLimit[type]) {
                add(newQuestion);
                setQuestionsNumber(questionsNumber + 1);
              }
            };
            const handleDelete = (index: number) => {
              if (questionsNumber > QUESTION_MIN_LIMIT) {
                remove(index);
                setQuestionsNumber(questionsNumber - 1);
              }
            };
            const handleDuplicate = (
              selectedQuestion: Question,
              index: number
            ) => {
              if (questionsNumber < QuestionsLimit[type]) {
                add(selectedQuestion, index);
                setQuestionsNumber(questionsNumber + 1);
              }
            };
            return (
              <div className={styles.questionContainer}>
                {questions.map((question, key) => {
                  return (
                    <QuestionCard
                      key={`question-${key}`}
                      type={type}
                      form={form}
                      question={question}
                      questionsNumber={questionsNumber}
                      currentIndex={key}
                      onDeleteQuestion={handleDelete}
                      onDuplicateQuestion={handleDuplicate}
                    />
                  );
                })}
                <Button
                  onClick={handleAdd}
                  className={styles.addQuestionButton}
                  type="default"
                  disabled={
                    questionsNumber >= QuestionsLimit[type] || isLoading
                  }
                >
                  <CogIcon
                    className={styles.square}
                    color="white"
                    icon="Plus"
                  />
                  Añadir Pregunta
                </Button>
              </div>
            );
          }}
        </Form.List>
      </div>
    </Form>
  );
};

export default EvaluationForm;
