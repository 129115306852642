import { Button, Card } from "antd";
import React, {
  FunctionComponent,
  ReactNode,
  useState,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
} from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { ICourse } from "../../../../features/courses/coursesSlice";
import { ParentTypeEnum } from "../../../../features/evaluation/common/enums";
import { QuestionsAnswers } from "../../../../features/evaluation/common/interfaces";
import { selectEvaluation } from "../../../../features/evaluation/currentEvaluationSlice";
import { selectStudentCourse } from "../../../../features/students/courseSlice";
import { CogIcon } from "../../../common/CogIcon";
import { Survey } from "../Survey";
import styles from "./lastPage.module.scss";
import { analytics } from "../../../../services/analytics";
import {
  selectPrograms,
  selectProgramsDict,
} from '../../../../features/program/programSlice';
import { getCognitoUserData } from '../../../../features/auth/authSlice';
import { selectUser } from '../../../../features/users/usersSlice';
import { selectGroup } from "../../../../features/groups/groupSlice";
import { selectCompany } from '../../../../features/company/companySlice';
import {
  fetchSurvey,
} from "../../../../features/evaluation/currentSurveySlice";
import { selectEvaluationById } from '../../../../features/evaluation/evaluationsSlice';

interface LastPageProps {
  courseId: string;
  answersSelected: QuestionsAnswers;
  retakeExam: Function;
}

interface GetActionsParams {
  examWasPassed: boolean;
  retakeExam: Function;
  course: ICourse | null;
  courseId: string;
}

export const LastPage: FunctionComponent<LastPageProps> = ({
  courseId,
  answersSelected = {},
  retakeExam,
}) => {
  const [showSurvey, setShowSurvey] = useState<boolean>(false);
  const correctAnswersCount = Object.values(answersSelected).reduce(
    (total: number, answer) => total + (answer?.correct ? 1 : 0),
    0
  );
  const course = useSelector(selectStudentCourse);
  const evaluation = useSelector(selectEvaluation(course?.evaluation || ''));
  const questionsCount = evaluation?.questions?.length || 1;
  const successRate = Math.round((correctAnswersCount / questionsCount) * 100);
  const correctAnswersToPass = course?.correctAnswersToPass || 0;
  const examWasPassed = correctAnswersCount >= correctAnswersToPass;
  const title = examWasPassed ? "¡Excelente, aprobaste!" : "Fallaste esta vez";
  const icon = examWasPassed ? "test-1" : "Cross-circle";
  const history = useHistory();
  const goToMyCourses = () => {
    history.push(`/course/${course?._id}/congratulations`);
  };
  const selectSurveyRef = useRef<AnalyticsSelectSurveyRef>(null);
  const getActions = ({
    examWasPassed,
    retakeExam,
    course,
  }: GetActionsParams): ReactNode[] => {
    const hasSurvey = course?.survey !== undefined;
    const buttonLabel = hasSurvey ? "Tomar encuesta" : "Completar curso";
    if (!examWasPassed) {
      return [
        <div className={styles.buttonContainer}>
          <Button
            onClick={() => {
              if (hasSurvey) {
                selectSurveyRef?.current?.trigger();
                setShowSurvey(true);
                return;
              }
              goToMyCourses();
            }}
            type="link"
          >
            {buttonLabel}
          </Button>
        </div>,
        <div className={styles.buttonContainer}>
          <Button
            onClick={() => {
              retakeExam();
            }}
            type="primary"
          >
            Intentar nuevamente
          </Button>
        </div>,
      ];
    }
    return [
      <div className={styles.buttonContainer}>
        <Button
          onClick={() => {
            if (hasSurvey) {
              setShowSurvey(true);
              return;
            }
            goToMyCourses();
          }}
          type="primary"
        >
          {buttonLabel}
        </Button>
      </div>,
    ];
  };

  return (
    <Card
      className={styles.container}
      actions={getActions({
        examWasPassed,
        retakeExam,
        course,
        courseId,
      })}
    >
      {showSurvey ? (
        <Survey
          courseId={courseId}
          parentId={courseId}
          surveyId={course?.survey || ""}
          onSurveyComplete={() => {
            goToMyCourses();
          }}
          parentType={ParentTypeEnum.CourseSurvey}
          onCancelSurvey={() => setShowSurvey(false)}
        />
      ) : null}
      <CogIcon className={styles.icon} icon={icon} />
      <div className={styles.info}>{title}</div>
      <div className={styles.results}>
        <div className={styles.result}>
          Tu calificación fue de: {correctAnswersCount} / {questionsCount}
        </div>
        <div className={styles.result}>
          Tuviste un acierto del: {successRate}%
        </div>
        <div className={styles.result}>
          Respuestas correctas: {correctAnswersCount}
        </div>
        <div className={styles.result}>
          Respuestas incorrectas: {questionsCount - correctAnswersCount}
        </div>
        {!examWasPassed ? (
          <div className={styles.result}>
            Puedes volver a intentarlo en {course?.timeToRetry} min
          </div>
        ) : null}
      </div>
      <AnalyticsSelectSurvey ref={selectSurveyRef} parentId={courseId} surveyId={course?.survey || ""} />
    </Card>
  );
};

type AnalyticsSelectSurveyRef = {
  trigger: () => void,
}

interface AnalyticsSelectSurveyProps {
  parentId: string,
  surveyId: string,
}

export const AnalyticsSelectSurvey =
  forwardRef<AnalyticsSelectSurveyRef, AnalyticsSelectSurveyProps>(({ parentId, surveyId }, ref) => {
    useImperativeHandle(ref, () => ({
      trigger: () => {
        analytics.selectSurvey({
          survey: evaluation,
          course: course,
          program: courseProgram,
          company: company,
          group: group || null,
        });
      }
    }));
    const dispatch = useDispatch();
    const evaluation = useSelector(selectEvaluationById(surveyId));
    const course = useSelector(selectStudentCourse);
    const allProgramsIds = useSelector(selectPrograms);
    const programsDict = useSelector(selectProgramsDict);
    const programId = allProgramsIds.filter(programId => {
      const program = programsDict[programId] || {};
      const { courses: programCourses =  [] } = program;
      const foundCourse = programCourses.find(courseId => courseId === course?._id);
      if (foundCourse !== undefined) {
        return true;
      }
      return false;
    })
    const courseProgram = programsDict[programId[0]] || null;
    const cognitoUserData = useSelector(getCognitoUserData);
    const cognitoUsername = cognitoUserData
      ? cognitoUserData["cognito:username"]
      : null;
    const userFromStore = useSelector(selectUser(cognitoUsername));
    const group = useSelector(selectGroup(userFromStore?.studentGroupId || '')) || null;
    const companyId = userFromStore?.company ?? "";
    const company = useSelector(
      selectCompany(companyId)
    );

    useEffect(() => {
      if (parentId && surveyId) {
        dispatch(fetchSurvey(surveyId, parentId));
      }
    }, [dispatch, parentId, surveyId]);

    return null;
  });
