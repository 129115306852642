import { Microservices } from './../../app/AllowedMicroservices';
import { HttpMethods } from './../../services/apiActionCreator';
import { Dispatch } from 'react';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import apiActionCreator from '../../services/apiActionCreator';

export enum VideoStatus {
  none = 'none',
  checking = 'checking',
  available = 'available',
  transcoding = 'transcoding',
  transcodeStarting = 'transcode_starting',
  error = 'error',
}
interface VideoStatusState {
  status: VideoStatus,
  info?: ICheckVideoStatusResponse,
}

const initialState: VideoStatusState = {
  status: VideoStatus.none,
};

export interface ICheckVideoStatusResponse {
  duration: number,
  height: number
  status: VideoStatus,
  width: number
};

export const videoStausSlice = createSlice({
  name: 'videoStatus',
  initialState,
  reducers: {
    checking: (state) => {
      state.status = VideoStatus.checking;
    },
    checked: (state, action: PayloadAction<ICheckVideoStatusResponse>) => {
      state.status = action.payload.status;
      state.info = action.payload;
    },
    error: (state, action: PayloadAction<ICheckVideoStatusResponse>) => {
      state.status = VideoStatus.error;
    },
    setToNone: (state, action: PayloadAction<ICheckVideoStatusResponse>) => {
      state.status = VideoStatus.none;
    },
  },
});

export const { checking, checked, setToNone, error } = videoStausSlice.actions;

export const checkVideoStatus = (videoUrl: string) => (dispatch: Dispatch<object>) => {
  return dispatch(
      apiActionCreator({
          endpoint: `/files/infoFromVimeoVideo${videoUrl}`,
          types: {
              requestType: checking,
              successTypes: [
                  {
                      actionOrCreator: checked,
                  },
              ],
              errorTypes: [
                  {
                      actionOrCreator: error,
                  }
              ],
          },
          method: HttpMethods.GET,
          microservice: Microservices.MEDIA_SERVICE,
          authenticated: true,
      })
  );
};

export const selectVideoStatus = (state: RootState) => state.videoStatus.status;
export const selectVideoStatusInfo = (state: RootState) => state.videoStatus.info;

export default videoStausSlice.reducer;
 