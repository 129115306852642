import React from 'react';
import { ColumnsType } from 'antd/es/table';
import cls from 'classnames';

import { StatusEnum } from '../../../../common/Status/index';
import { Status } from "../../../../common/Status";

import { EvaluationLabels, LearningLabels, LearningType } from '../../../../../features/reports/learning/common/interfaces';

import styles from './index.module.scss';
import { DownloadEvaluationDetails } from './DownloadEvaluationDetails';
import { ITableRecord } from './interfaces';
import { Tooltip } from 'antd';
import { CogIcon } from '../../../../common/CogIcon';

export const getColumnByType = (learningType: LearningType) => {
  switch(learningType){
    case(LearningType.lessons):
      return LessonsColumns;
    case(LearningType.topics):
      return TopicsColumns;
    case(LearningType.courses):
      return CoursesColumns;
  }
};
const circleFulfillment = (value: number) => {
  return <div className={cls(styles.circle, {
    [styles.green]: value >= 100,
    [styles.orange]: value < 100 && value >= 50,
    [styles.red]: value <50,
  })}></div>
}
export const circleScore = (value: number) => {
  return <div className={cls(styles.circle, {
    [styles.green]: value >= 80,
    [styles.orange]: value < 80 && value >= 50,
    [styles.red]: value <50,
  })}></div>
}

const renderStatus = (status: StatusEnum) => {
  return <Status status={status} />;
}

const renderFulfillment = (value?: number) => {
  if(value === undefined){
    return null;
  }
  return <div>
    {circleFulfillment(value)} {Math.round(value)}%
  </div>;
};

const renderFulfillmentWithDownload = (value: number | undefined, record: ITableRecord) => {
  if(value === undefined){
    return null;
  }
  return <>
    <div>
      {circleFulfillment(value)} {Math.round(value)}%
    </div>
    <DownloadEvaluationDetails record={record}/>
  </>;
};

const renderScore = (value: number | undefined, record: ITableRecord) => {
  if(value === undefined){
    return null
  }
  return <>
    <div>
      {circleScore(value)} {Math.round(value)}%
    </div>
    <DownloadEvaluationDetails record={record}/>
  </>;
};

const renderScoreSurveyDownload = (value: number | undefined, record: ITableRecord) => {
  if(value === undefined){
    return null
  }
  return <>
    <div>
      {circleScore(value)} {Math.round(value)}%
    </div>
    <DownloadEvaluationDetails record={record} survey/>
  </>;
};
const averageScoreTitle = () => {
  return <>
    Calificación promedio <Tooltip
      title={<>
        Verde ≥ 80%<br/>
        Amarillo Entre 50% y 80%<br/>
        Rojo {'<'} 50%
      </>}
      className={styles.tooltip}
    >
      <span>
        <CogIcon size="1.4em" color="#81858b" icon={"Attention"} />
      </span>
    </Tooltip>
  </>;
}
const averageScoreSurveyTitle = () => {
  return <>
    Completación de encuesta <Tooltip
      title={<>
        Verde ≥ 80%<br/>
        Amarillo Entre 50% y 80%<br/>
        Rojo {'<'} 50%
      </>}
      className={styles.tooltip}
    >
      <span>
        <CogIcon size="1.4em" color="#81858b" icon={"Attention"} />
      </span>
    </Tooltip>
  </>;
}
const fulfillmentColumnTitle = () => {
  return <>
    Cumplimiento <Tooltip
      title={<>
        Verde = 100%<br/>
        Amarillo Entre 50% y 100%<br/>
        Rojo {'<'} 50%
      </>}
      className={styles.tooltip}
    >
      <span>
        <CogIcon size="1.4em" color="#81858b" icon={"Attention"} />
      </span>
    </Tooltip>
  </>;
}

const LessonsColumns: ColumnsType<ITableRecord> = [
  {
    title: "Empresa",
    dataIndex: "company",
    sortDirections: ["descend", "ascend"],
    defaultSortOrder: "ascend",
    ellipsis: true,
  },
  {
    title: 'Lección',
    dataIndex: "contentName",
    sortDirections: ["descend", "ascend"],
    defaultSortOrder: "ascend",
    ellipsis: true,
  },
  {
    title: `Estado de ${LearningLabels[LearningType.lessons]}`,
    dataIndex: "status",
    sortDirections: ["descend", "ascend"],
    defaultSortOrder: "ascend",
    ellipsis: true,
    render: renderStatus,
  },
  {
    title: `Nombre del ${EvaluationLabels[LearningType.lessons]}`,
    dataIndex: "evaluation",
    sortDirections: ["descend", "ascend"],
    defaultSortOrder: "ascend",
    ellipsis: true,
  },
  {
    title: fulfillmentColumnTitle,
    dataIndex: "fulfillment",
    sortDirections: ["descend", "ascend"],
    defaultSortOrder: "ascend",
    width: 150,
    ellipsis: true,
    render: renderFulfillmentWithDownload,
  },
];

const TopicsColumns: ColumnsType<ITableRecord> = [
  {
    title: "Empresa",
    dataIndex: "company",
    sortDirections: ["descend", "ascend"],
    defaultSortOrder: "ascend",
    ellipsis: true,
  },
  {
    title: 'Tema',
    dataIndex: "contentName",
    sortDirections: ["descend", "ascend"],
    defaultSortOrder: "ascend",
    ellipsis: true,
  },
  {
    title: `Estado de ${LearningLabels[LearningType.topics]}`,
    dataIndex: "status",
    sortDirections: ["descend", "ascend"],
    defaultSortOrder: "ascend",
    ellipsis: true,
    render: renderStatus,
  },
  {
    title: fulfillmentColumnTitle,
    dataIndex: "fulfillment",
    sortDirections: ["descend", "ascend"],
    defaultSortOrder: "ascend",
    width: 150,
    ellipsis: true,
    render: renderFulfillment
  },
  {
    title: `Nombre del ${EvaluationLabels[LearningType.topics]}`,
    dataIndex: "evaluation",
    sortDirections: ["descend", "ascend"],
    defaultSortOrder: "ascend",
    ellipsis: true,
  },
  {
    title: averageScoreTitle,
    dataIndex: "score",
    sortDirections: ["descend", "ascend"],
    defaultSortOrder: "ascend",
    ellipsis: true,
    width: 210,
    render: renderScore,
  },
];

const CoursesColumns: ColumnsType<ITableRecord> = [
  {
    title: "Empresa",
    dataIndex: "company",
    sortDirections: ["descend", "ascend"],
    defaultSortOrder: "ascend",
    ellipsis: true,
    width: 200,
    fixed: true,
  },
  {
    title: 'Curso',
    dataIndex: "contentName",
    sortDirections: ["descend", "ascend"],
    defaultSortOrder: "ascend",
    ellipsis: true,
    width: 200,
    fixed: true,
  },
  {
    title: `Estado de ${LearningLabels[LearningType.courses]}`,
    dataIndex: "status",
    sortDirections: ["descend", "ascend"],
    defaultSortOrder: "ascend",
    ellipsis: true,
    render: renderStatus,
    width: 160,
  },
  {
    title: fulfillmentColumnTitle,
    dataIndex: "fulfillment",
    sortDirections: ["descend", "ascend"],
    defaultSortOrder: "ascend",
    width: 150,
    ellipsis: true,
    render: renderFulfillment,
  },
  {
    title: `Nombre del ${EvaluationLabels[LearningType.courses]}`,
    dataIndex: "evaluation",
    sortDirections: ["descend", "ascend"],
    defaultSortOrder: "ascend",
    ellipsis: true,
    width: 230,
  },
  {
    title: averageScoreTitle,
    dataIndex: "score",
    sortDirections: ["descend", "ascend"],
    defaultSortOrder: "ascend",
    ellipsis: true,
    width: 210,
    render: renderScore,
  },
  {
    title: `Nombre de encuesta`,
    dataIndex: "survey",
    sortDirections: ["descend", "ascend"],
    defaultSortOrder: "ascend",
    ellipsis: true,
    width: 210,
  },
  {
    title: averageScoreSurveyTitle,
    dataIndex: "completed",
    sortDirections: ["descend", "ascend"],
    defaultSortOrder: "ascend",
    ellipsis: true,
    width: 210,
    render: renderScoreSurveyDownload,
  },
];