import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Dispatch } from "react";
import { Microservices } from "../../app/AllowedMicroservices";
import { AppThunk, RootState } from "../../app/store";
import apiActionCreator, { HttpMethods } from "../../services/apiActionCreator";
import { MODAL_DELAY_MS } from "../../utils/constants";
import { ConditionEnum, FetchStatus, ModalStatus } from "../common/enums";
import { setMessageError, setMessageInfo } from "../message/messageSlice";

export enum VisibilityRole {
  CompanyAdmin = "CompanyAdmin",
  Supervisor = "Supervisor",
  AllRoles = "AllRoles",
}

export const VisibilityRolesDict = {
  [VisibilityRole.CompanyAdmin]: "Administrador de empresas",
  [VisibilityRole.Supervisor]: "Supervisor",
  [VisibilityRole.AllRoles]: "Todos",
};

export interface ICampaign {
  _id: string;
  title: string;
  description: string;
  fromDate: string;
  toDate: string;
  companyId: string;
  published?: ConditionEnum;
  resultsVisibilityRole?: VisibilityRole;
  survey?: string;
  image?: string;
  createdAt?: Date | string;
  answered?: boolean;
}

interface CampaignUpdateStatusData {
  ids: string[];
}

interface CampaignState {
  list: ICampaign[],
  status: FetchStatus,
  modalStatus: ModalStatus,
  created?: ICampaign;
}

const initialState: CampaignState = {
  list: [],
  status: FetchStatus.Idle,
  modalStatus: ModalStatus.Hide,
};

interface CampaignFetch {
  docs: ICampaign[];
  limit: number;
  page: number;
  pages: number;
  total: number;
}

export const campaignSlice = createSlice({
  name: "campaign",
  initialState,
  reducers: {
    creating: (state) => {
      state.status = FetchStatus.Creating;
    },
    created: (state, action: PayloadAction<ICampaign>) => {
      state.created = action.payload;
    },
    fetching: (state) => {
      state.status = FetchStatus.Fetching;
    },
    fetched: (state, action: PayloadAction<CampaignFetch>) => {
      state.list = action.payload.docs;
      state.status = FetchStatus.Fetched;
    },
    showModal: (state) => {
      state.modalStatus = ModalStatus.Show;
    },
    hideModal: (state) => {
      state.modalStatus = ModalStatus.Hide;
    },
    error: (state, _action: PayloadAction<CampaignFetch>) => {
      state.list = [];
      state.status = FetchStatus.Error;
    },
  },
});

const BASE_ENDPOINT = "/campaign";
export const { hideModal } = campaignSlice.actions;
const { fetching, fetched, error, creating, showModal, created } = campaignSlice.actions;

export const fetchCampaigns = () => (dispatch: Dispatch<object>) => {
  return dispatch(
    apiActionCreator({
      endpoint: `${BASE_ENDPOINT}?limit=1000000`,
      types: {
        requestType: fetching,
        successTypes: [
          {
            actionOrCreator: fetched,
          },
        ],
        errorTypes: [
          {
            actionOrCreator: error,
          },
        ],
      },
      method: HttpMethods.GET,
      microservice: Microservices.CAMPAIGN_SERVICE,
      authenticated: true,
    })
  );
};

export const createCampaign = (newCampaign: ICampaign) => (
  dispatch: Dispatch<object>
) => {
  return dispatch(
    apiActionCreator({
      endpoint: BASE_ENDPOINT,
      types: {
        requestType: creating,
        successTypes: [
          {
            actionOrCreator: created,
          },
          {
            actionOrCreator: fetchCampaigns,
          },
          {
            actionOrCreator: showModal,
            selector: () => {
              dispatch(hideModalWithDelay());
            },
          },
        ],
        errorTypes: [
          {
            actionOrCreator: error,
            selector: (response: any) => {
              const message = `Error desconocido`;
              dispatch(setMessageError({ message }));
            },
          },
        ],
      },
      method: HttpMethods.POST,
      microservice: Microservices.CAMPAIGN_SERVICE,
      authenticated: true,
      data: newCampaign,
    })
  );
};

export const updateCampaign = (campaign: ICampaign) => (
  dispatch: Dispatch<object>
) => {
  return dispatch(
    apiActionCreator({
      endpoint: `${BASE_ENDPOINT}/${campaign._id}`,
      types: {
        requestType: creating,
        successTypes: [
          {
            actionOrCreator: fetchCampaigns,
          },
          {
            actionOrCreator: showModal,
            selector: () => {
              dispatch(hideModalWithDelay());
            },
          },
        ],
        errorTypes: [
          {
            actionOrCreator: error,
            selector: (_response: any) => {
              const message = `Error desconocido`;
              dispatch(setMessageError({ message }));
            },
          },
        ],
      },
      method: HttpMethods.PUT,
      microservice: Microservices.CAMPAIGN_SERVICE,
      authenticated: true,
      data: campaign,
    })
  );
};

export const publishCampaignStatus = (campaigns: CampaignUpdateStatusData) => (
  dispatch: Dispatch<object>
) => {
  return dispatch(
    apiActionCreator({
      endpoint: `${BASE_ENDPOINT}/publish`,
      types: {
        requestType: creating,
        successTypes: [
          {
            actionOrCreator: fetchCampaigns,
          },
          {
            actionOrCreator: setMessageInfo({
              message: "Campaña(s) activada(s)",
            }),
          },
        ],
        errorTypes: [
          {
            actionOrCreator: error,
          },
          {
            actionOrCreator: fetchCampaigns,
          },
          {
            actionOrCreator: setMessageError({
              message: "Falló la activación",
            }),
          },
        ],
      },
      method: HttpMethods.PUT,
      microservice: Microservices.CAMPAIGN_SERVICE,
      authenticated: true,
      data: campaigns,
    })
  );
};

export const unpublishCampaignStatus = (
  campaigns: CampaignUpdateStatusData
) => (dispatch: Dispatch<object>) => {
  return dispatch(
    apiActionCreator({
      endpoint: `${BASE_ENDPOINT}/unpublish`,
      types: {
        requestType: creating,
        successTypes: [
          {
            actionOrCreator: fetchCampaigns,
          },
          {
            actionOrCreator: setMessageInfo({
              message: "Campaña(s) desactivada(s)",
            }),
          },
        ],
        errorTypes: [
          {
            actionOrCreator: error,
          },
          {
            actionOrCreator: fetchCampaigns,
          },
          {
            actionOrCreator: setMessageError({
              message: "Falló la desactivación",
            }),
          },
        ],
      },
      method: HttpMethods.PUT,
      microservice: Microservices.CAMPAIGN_SERVICE,
      authenticated: true,
      data: campaigns,
    })
  );
};

const hideModalWithDelay = (): AppThunk => async (dispatch) => {
  return new Promise((resolve) => {
    setTimeout(() => {
      dispatch(hideModal());
      resolve();
    }, MODAL_DELAY_MS);
  });
};

export const selectCampaignStatus = (state: RootState) =>
  state.campaigns.status === FetchStatus.Creating;
export const selectCampaigns = (state: RootState) => state.campaigns.list;
export const selectCampaignsAreFetching = (state: RootState) =>
  state.campaigns.status === FetchStatus.Fetching;
export const selectDisplayModal = (state: RootState) =>
  state.campaigns.modalStatus === ModalStatus.Show;
export const selectCreatedCampaign = (state: RootState) => state.campaigns.created;

export default campaignSlice.reducer;
