import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectCompaniesAreFetching } from "../../../../features/company/companySlice";
import { selectAreContentCategoriesFetching } from "../../../../features/contentCategory/contentCategorySlice";
import { selectIsContentFetching } from "../../../../features/knowledgeBase/contentSlice";
import { selectKnowledgeBaseVisitsIsFetching } from "../../../../features/reports/knowledgeBase/viewKnowledgeBase";
import { selectAreUsersFetching } from "../../../../features/users/usersSlice";

export const useKBDataIsFetching = () => {
  const [dataIsFetching, setDataIsFetching] = useState<boolean>(false);
  const isContentsFetching = useSelector(selectIsContentFetching);
  const isFetchingCategories = useSelector(selectAreContentCategoriesFetching);
  const isFetchingCompanies = useSelector(selectCompaniesAreFetching);
  const isFetchingKnowledgeBaseVisits = useSelector(
    selectKnowledgeBaseVisitsIsFetching
  );
  const isFetchingStudents = useSelector(selectAreUsersFetching);

  useEffect(() => {
    setDataIsFetching(
      isContentsFetching ||
        isFetchingCategories ||
        isFetchingCompanies ||
        isFetchingKnowledgeBaseVisits ||
        isFetchingStudents
    );
  }, [
    isContentsFetching,
    isFetchingCategories,
    isFetchingCompanies,
    isFetchingKnowledgeBaseVisits,
    isFetchingStudents,
  ]);
  return dataIsFetching;
};
