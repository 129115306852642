import { Button, Card, Form, Input, Popconfirm, Spin } from "antd";
import { FormInstance } from "antd/lib/form";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setBreadcrumbs } from "../../features/breadcrumbs/breadcrumbsSlice";
import {
  fetchCourses,
  selectAreCoursesFetching,
  selectCourses,
  selectCoursesDict,
} from "../../features/courses/coursesSlice";
import {
  createProgram,
  ICourseRules,
  IProgram,
  selectAreProgramsFetching,
  selectProgram,
  updateProgram,
} from "../../features/program/programSlice";
import { fetchTopics, selectTopics } from "../../features/topic/topicSlice";
import { CogIcon } from "../common/CogIcon";
import LoadingOverlay from "../common/LoadingOverlay";
import { StatusEnum } from "../common/Status";
import { StepIndicator } from "../common/StepIndicator";
import { MIN_3_CHAR } from "../Users/Form";
import { CoursesSelected } from "./CoursesSelected";
import styles from "./form.module.scss";

export const PRIORITIES = [
  {
    value: 1,
    label: 1,
  },
  {
    value: 2,
    label: 2,
  },
  {
    value: 3,
    label: 3,
  },
];

export const DATE_FORMAT = "YYYY-MM-DD";

interface StepProps {
  stepIndicator: React.ReactNode;
  program?: IProgram;
  setProgram: Function;
  validateFields?: Function;
}

const FirstStep: React.FC<StepProps> = ({
  stepIndicator,
  program,
  setProgram,
}) => {
  return (
    <React.Fragment>
      <Card className={styles.card}>
        {stepIndicator}
        <div className={styles.title}>Información básica</div>
        <div className={styles.subTitle}>
          Ingresa la información del programa
        </div>
      </Card>
      <Card className={styles.card}>
        <Form.Item
          name="title"
          label="Nombre del programa"
          rules={[
            { type: "string", required: true, message: "Requerido" },
            {
              type: "string",
              max: 50,
              message: "No puede tener más de 50 caracteres",
            },
            { type: "string", min: 3, message: MIN_3_CHAR },
          ]}
        >
          <Input
            onChange={(event) => {
              setProgram({ ...program, title: event.target.value });
            }}
          />
        </Form.Item>
      </Card>
    </React.Fragment>
  );
};

const SecondStep: React.FC<StepProps> = ({
  stepIndicator,
  program,
  setProgram,
  validateFields = () => {},
}) => {
  const dispatch = useDispatch();
  const topics = useSelector(selectTopics);
  const courseIdsList = useSelector(selectCourses);
  const coursesDict = useSelector(selectCoursesDict);
  const coursesEnabledList = courseIdsList
    .map((courseId) => coursesDict[courseId])
    .filter(
      (course) =>
        course.status !== StatusEnum.Disable &&
        course.topics?.find((item) => topics[item]?.lessons.length > 0)
    );
  const areCoursesFetching = useSelector(selectAreCoursesFetching);

  const selectedCourses = program && program.courses ? program.courses : [];

  useEffect(() => {}, [program]);

  useEffect(() => {
    dispatch(fetchTopics());
    dispatch(fetchCourses());
  }, [dispatch]);

  return (
    <Spin spinning={areCoursesFetching}>
      <React.Fragment>
        <Card className={styles.card}>
          {stepIndicator}
          <div className={styles.title}>Asignar Cursos</div>
          <div className={styles.subTitle}>
            Selecciona los cursos para el programa
          </div>
        </Card>
        <CoursesSelected
          selectedCourses={selectedCourses}
          setProgram={setProgram}
          program={program}
          coursesList={coursesEnabledList}
          validateFields={validateFields}
        />
        <Card className={styles.card}></Card>
      </React.Fragment>
    </Spin>
  );
};

interface ProgramFromProps {
  form: FormInstance;
  programBeingEdited: string | null;
  onDone: Function;
}

interface ICourseRulesWithDateRange extends ICourseRules {
  dateRange: any;
  endDate?: Date;
}
interface IProgramExtended extends IProgram {
  courseRules2: ICourseRulesWithDateRange[];
}

export const ProgramForm: React.FC<ProgramFromProps> = ({
  form,
  programBeingEdited,
  onDone,
}) => {
  const dispatch = useDispatch();
  const [step, setStep] = useState<number>(0); //I will rename to step index
  const [program, setProgram] = useState<IProgram | IProgramExtended>();
  const areProgramsFetching = useSelector(selectAreProgramsFetching);
  const programId = program?._id;
  const buttonLabel = programId ? "Actualizar programa" : "Crear programa";
  const programToEdit = useSelector(selectProgram(programBeingEdited));

  useEffect(() => {
    dispatch(
      setBreadcrumbs([
        {
          label: "Programas",
        },
        {
          label: programId ? "Editando programa" : "Nuevo programa",
        },
      ])
    );
  }, [dispatch, programId]);

  useEffect(() => {
    if (programToEdit) {
      const currentRules = [...programToEdit.coursesRules];
      const newRules = currentRules.map((rule) => ({
        ...rule,
        startDate: moment(rule.startDate, DATE_FORMAT),
        dateRange: [
          moment(rule.startDate, DATE_FORMAT),
          rule.endDate ? moment(rule.endDate, DATE_FORMAT) : null,
        ],
      }));
      setProgram({ ...programToEdit, courseRules2: [...newRules] });
    }
  }, [programToEdit]);

  useEffect(() => {
    dispatch(fetchCourses());
  }, [dispatch]);

  if (areProgramsFetching) {
    return <LoadingOverlay spinning={true} />;
  }

  const steps = [FirstStep, SecondStep];
  const STEPS_COUNT = steps.length;

  const goNext = () => {
    if (step + 1 >= STEPS_COUNT) {
      return;
    }
    form
      .validateFields()
      .then(() => {
        setStep(step + 1);
      })
      .catch((error) => {
        console.log("ProgramForm: React.FC<ProgramFromProps> -> error", error);
      });
  };

  const goPrev = () => {
    if (step === 0) {
      return;
    }
    form
      .validateFields()
      .then(() => {
        setStep(step - 1);
      })
      .catch((error) => {
        console.log("ProgramForm: React.FC<ProgramFromProps> -> error", error);
      });
  };

  const validateFields = () => {
    return form.validateFields();
  };

  const StepToShow = steps[step];
  const stepIndicator = <StepIndicator step={step} stepsCount={STEPS_COUNT} />;
  const onSave = () => {
    if (!program) {
      return;
    }
    form
      .validateFields()
      .then(() => {
        if (program._id) {
          dispatch(updateProgram(program));
          setTimeout(() => {
            form.resetFields();
            form.setFieldsValue({});
          }, 100);
          onDone();
          return;
        }
        dispatch(createProgram(program));
        setTimeout(() => {
          form.resetFields();
          form.setFieldsValue({});
        }, 100);
        onDone();
      })
      .catch((error) => {
        console.log("ProgramForm: React.FC<ProgramFromProps> -> error", error);
      });
  };

  let backButton = null;
  if (step === 0) {
    backButton = (
      <Popconfirm
        placement="top"
        title="¿Desea salir del formulario?"
        onConfirm={() => {
          onDone();
        }}
        okText="Si"
        cancelText={`No, voy a seguir ${
          programId ? "editando" : "creando"
        } el programa`}
        cancelButtonProps={{
          type: "default",
        }}
      >
        <Button className={styles.buttonNextPrev} type="default">
          <CogIcon
            color="#4376F9"
            className={styles.icon}
            icon="Arrow-left-big"
          />
        </Button>
      </Popconfirm>
    );
  } else {
    backButton = (
      <Button onClick={goPrev} className={styles.buttonNextPrev} type="default">
        <CogIcon
          color="#4376F9"
          className={styles.icon}
          icon="Arrow-left-big"
        />
      </Button>
    );
  }

  return (
    <div className={styles.container}>
      <div className={styles.titleContainer}>
        <div className={styles.title}>
          {programBeingEdited ? `Editando ${program?.title}` : "Nuevo Programa"}
        </div>
        <div className={styles.actions}>
          {backButton}
          <Button
            disabled={step === STEPS_COUNT - 1}
            onClick={goNext}
            className={styles.buttonNextPrev}
            type="default"
          >
            <CogIcon
              color="#4376F9"
              className={styles.icon}
              icon="Arrow-right-big"
            />
          </Button>
          <Button
            disabled={step !== STEPS_COUNT - 1}
            onClick={onSave}
            className={styles.button}
            type="primary"
          >
            <span className={styles.label}>{buttonLabel}</span>
          </Button>
        </div>
      </div>
      <Form form={form} layout="vertical" initialValues={{ ...program }}>
        <StepToShow
          setProgram={setProgram}
          program={program}
          stepIndicator={stepIndicator}
          validateFields={validateFields}
        />
      </Form>
    </div>
  );
};
