import React, { FunctionComponent } from 'react';
import cls from 'classnames';

import styles from './index.module.scss';

interface OutputProps {
    label: string,
    text: string,
    children?: React.ReactNode,
}

export const Output: FunctionComponent<OutputProps> = ({ label, text, children }) => {
    return (
        <div className={cls(styles.container)}>
            <label className={cls(styles.label)}>{label}</label>
            <div className={cls(styles.text)}>{text}</div>
            { children ? children : null}
        </div>
    );
}