import React, { FunctionComponent } from "react";
import ctx from "classnames";
import { Input } from "antd";

import styles from "./email.module.scss";
import { ViewProps } from "./common";

const { TextArea } = Input;

export const Email: FunctionComponent<ViewProps> = ({
  message,
  setMessage,
  title,
  setTitle,
  maxMessageCount
}) => {
  return (
    <div className={ctx(styles.container)}>
      <div className={styles.label}>Título</div>
      <Input
        value={title}
        onChange={event => {
          setTitle(event.target.value);
        }}
        className={styles.input}
      />
      <div className={styles.label}>Mensaje</div>
      <TextArea
        value={message}
        onChange={event => {
          setMessage(event.target.value);
        }}
        className={styles.textarea}
        rows={4}
      />
      <div className={styles.counter}>{message.length} / {maxMessageCount}</div>
    </div>
  );
};
