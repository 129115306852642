import { Button, Divider, Spin, Upload, message, Alert } from "antd";
import { RcFile } from 'antd/lib/upload';
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Microservices } from "../../app/AllowedMicroservices";
import { BASE_URL } from "../../app/apiMiddleware";
import { cognitoUser } from "../../features/auth/authSlice";
import { EvaluationTypeEnum } from "../../features/evaluation/common/enums";
import { IEvaluation } from "../../features/evaluation/common/interfaces";
import {
  selectEvaluationsDict,
  selectQuizzes,
} from "../../features/evaluation/evaluationsSlice";
import {
  IMedia,
  IVideoInfo,
  MediaTypes,
} from "../../features/lesson/lessonSlice";
import { selectVideoStatus, VideoStatus } from '../../features/lesson/videoStatus';
import { CogIcon } from "../common/CogIcon";
import EvaluationField from "../common/EvaluationField";
import { StepProps } from "./commonInterfaces";
import styles from "./form.module.scss";
import { LinkQuizToVideo } from "./LinkQuizToVideo";
import { MediaPreview } from "./MediaPreview";

const MEDIA_OPTIONS = {
  IMAGE: {
    loadTitle: "Cargar imagenes",
    maxSizeMB: 5,
    messageTypeError: "formatos permitidos PNP, JPG, GIF",
    description:
      "Tamaño máximo permitido 5MB, formatos permitidos PNP, JPG, GIF",
  },
  VIDEO: {
    loadTitle: "Cargar video",
    maxSizeMB: 1000,
    messageTypeError: "formatos permitidos MP4",
    description:
      "Tamaño máximo permitido 1000MB, formatos permitidos MP4",
  },
  PDF: {
    loadTitle: "Cargar PDF",
    messageTypeError: "formatos permitidos PDF",
    maxSizeMB: 5,
    description: "Tamaño máximo permitido 5MB, formato permitido PDF",
  },
};
const beforeUpload = (acceptedTypes:string, messageTypeError:string, maxSize: number, file: RcFile, FileList: RcFile[]) => {
  const validTypes = acceptedTypes.split(',');
  console.log("🚀 ~ file: StepOne.tsx ~ line 50 ~ beforeUpload ~ types", validTypes, file.type)
  const isvalidType = validTypes.includes(file.type);
  if (!isvalidType) {
    message.error(messageTypeError);
  }
  const isSizeValid = file.size / 1024 / 1024 <=  maxSize;
  if (!isSizeValid) {
    message.error(`El archivo debe ser menor a ${maxSize}MB`);
  }
  return isvalidType && isSizeValid;
}

export const StepOne: React.FunctionComponent<StepProps> = ({
  newLesson,
  fileType,
  acceptedTypes,
  setNewLesson,
  onBack,
  setFileType,
}) => {
  const [error, setError] = useState("");
  const [openLinkVideo, setOpenLinkVideo] = useState<boolean>(false);
  const evaluationsDict = useSelector(selectEvaluationsDict);
  const videoStatus = useSelector(selectVideoStatus);
  const [isLoading, setIsLoading] = useState(false);
  const [isVideoLocked, setIsVideoLocked] = useState(true);
  const handleDelete = (url: string) =>
    {
      setNewLesson({
      ...newLesson,
      media: newLesson.media?.filter((media) => media.url !== url),
      quizCuePoints: [],
    });
  }
  const handleQuizUpdate = (evaluation: IEvaluation) =>
    setNewLesson({ ...newLesson, 
      evaluation: evaluation._id,
      quizCuePoints: [], 
    });
  const handleQuizDelete = () =>
    setNewLesson({ ...newLesson, 
      evaluation: undefined,
      quizCuePoints: [], 
    });

  const handleChangeFile = (info: any) => {
    const { status } = info.file;
    if (status === "uploading") {
      setIsLoading(true);
      setError("");
      return;
    }
    if (status === "done") {
      let updatedMedia: IMedia[] = [];
      if (newLesson.media) {
        updatedMedia = [...newLesson.media];
      }
      const url =
        fileType === MediaTypes.VIDEO
          ? info.file.response.videoUri
          : info.file.response.url;
      updatedMedia.push({
        type: fileType,
        url,
        filename: info.file.name,
        size: info.file.size,
      });
      setNewLesson({ ...newLesson, media: updatedMedia });
      setIsLoading(false);
    } else if (status === "error") {
      setError("Error al subir archivo");
    }
  };

  let action: string = `${
    BASE_URL[Microservices.LEARNING_SERVICE]
  }/media/uploadToS3`;
  if (fileType === MediaTypes.VIDEO) {
    action = `${BASE_URL[Microservices.MEDIA_SERVICE]}/files/uploadToVimeo`;
  }

  return (
    <>
      <button type="button" className={styles.backButton} onClick={onBack}>
        <CogIcon size="15px" color="#686F84" icon="Arrow-left" />
        <span>Atrás</span>
      </button>
      <div className={styles.formSubtitle}>Archivos de la lección</div>
      <p className={styles.formParagraph}>
        Selecciona el tipo de archivo de la lección
      </p>
      <div className={styles.mediaContainer}>
        <button
          className={`${styles.mediaButton} ${
            fileType === MediaTypes.IMAGE ? styles.selected : ""
          }`}
          onClick={() => setFileType(MediaTypes.IMAGE)}
          type="button"
        >
          <CogIcon size="39px" icon="Image" />
          <span>Imagenes</span>
        </button>
        <button
          className={`${styles.mediaButton} ${
            fileType === MediaTypes.VIDEO ? styles.selected : ""
          }`}
          onClick={() => setFileType(MediaTypes.VIDEO)}
          type="button"
        >
          <CogIcon size="39px" icon="video" />
          <span>Video</span>
        </button>
        <button
          className={`${styles.mediaButton} ${
            fileType === MediaTypes.PDF ? styles.selected : ""
          }`}
          onClick={() => setFileType(MediaTypes.PDF)}
          type="button"
        >
          <CogIcon size="39px" icon="pdf" />
          <span>PDF</span>
        </button>
      </div>
      <div className={styles.listMediaContainer}>
        {newLesson.media
          ?.filter((item) => item.type === fileType)
          .map((media) => (
            <MediaPreview
              key={media.url}
              media={media}
              onDelete={handleDelete}
              onUnlockVideo={(videoInfo: IVideoInfo) => {
                setIsVideoLocked(false);
                const { media: mediaList = [] } = newLesson;
                let media: IMedia = mediaList[0];
                if (!media) {
                  media = {
                    filename: "",
                    size: 0,
                    type: MediaTypes.VIDEO,
                    url: "",
                  };
                } else {
                  media = { ...media };
                }
                media.videoInfo = videoInfo;
                setNewLesson({ ...newLesson, media: [media] });
              }}
              isVideoLocked={isVideoLocked}
            />
          ))}
        {isLoading && <Spin />}
        {error !== "" && <p>{error}</p>}
      </div>
      {fileType === MediaTypes.VIDEO && newLesson.media?.length === 1 ? null : (
        <>
          <Upload
            accept={acceptedTypes}
            headers={{
              Authorization:
                cognitoUser
                  ?.getSignInUserSession()
                  ?.getAccessToken()
                  .getJwtToken() || "",
            }}
            action={action}
            showUploadList={false}
            onChange={handleChangeFile}
            className={styles.uploadContainer}
            beforeUpload={(file:RcFile, FileList: RcFile[]): boolean => {
              return beforeUpload(acceptedTypes, MEDIA_OPTIONS[fileType].messageTypeError, MEDIA_OPTIONS[fileType].maxSizeMB, file, FileList);
            }}
          >
            <Button className={styles.addMediaButton} type="default">
              <CogIcon
                className={styles.square}
                color="white"
                icon="Arrow-up"
              />
              {MEDIA_OPTIONS[fileType].loadTitle}
            </Button>
          </Upload>
          <p className={styles.formDescription}>
            {MEDIA_OPTIONS[fileType].description}
          </p>
        </>
      )}
      <Divider />
      <EvaluationField
        disabled={fileType === MediaTypes.VIDEO && videoStatus !== VideoStatus.available}
        evaluationId={newLesson.evaluation}
        type={EvaluationTypeEnum.Quiz}
        onUpdate={handleQuizUpdate}
        evaluationSelector={selectQuizzes}
        companyId={newLesson.companyId || ''}
        parentLabel="la lección"
      >
        { fileType === MediaTypes.VIDEO && videoStatus !== VideoStatus.available ?
          <Alert
            message="Para poder cargar un Quiz es necesario esperar a que el video esté procesado"
            type="warning"
          />: null
        }
        {newLesson.evaluation && evaluationsDict[newLesson.evaluation] && (
          <div className={styles.sectionRow}>
            <div className={styles.sectionInfo}>
              <CogIcon color="#81858B" icon="test" />
              <span>{evaluationsDict[newLesson.evaluation].title}</span>
              <Button type="link" onClick={handleQuizDelete}>
                <CogIcon color="#81858B" icon="Cross" />
              </Button>
            </div>
            {fileType === MediaTypes.VIDEO ? (
              <Button
                disabled={
                  !newLesson.media ||
                  !newLesson.media[0] ||
                  !newLesson.media[0].videoInfo?.duration
                }
                onClick={() => {
                  setOpenLinkVideo(true);
                }}
                type="default"
              >
                Vincular
              </Button>
            ) : null}
          </div>
        )}
      </EvaluationField>
      {fileType === MediaTypes.VIDEO &&
      openLinkVideo &&
      newLesson?.media &&
      newLesson ? (
        <LinkQuizToVideo
          onClose={() => {
            setOpenLinkVideo(false);
          }}
          open={openLinkVideo}
          setNewLesson={setNewLesson}
          lesson={newLesson}
          videoId={newLesson?.media[0]?.url}
          quizId={newLesson.evaluation}
        />
      ) : null}
    </>
  );
};
