import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Dispatch } from "react";
import { Microservices } from "../../app/AllowedMicroservices";
import { AppThunk, RootState } from "../../app/store";
import apiActionCreator, { HttpMethods } from "../../services/apiActionCreator";
import { MODAL_DELAY_MS } from "../../utils/constants";
import { FetchStatus, ModalStatus } from "../common/enums";
import { RegisterEvaluationStatus } from "./common/enums";
import {
  IEvaluation,
  RegisterEvaluationResultParams,
} from "./common/interfaces";

interface CurrentSurveyState {
  evaluation: IEvaluation | null;
  status: FetchStatus;
  regiterStatus: RegisterEvaluationStatus;
  modalStatus: ModalStatus;
}

const initialState: CurrentSurveyState = {
  evaluation: null,
  status: FetchStatus.Idle,
  regiterStatus: RegisterEvaluationStatus.Idle,
  modalStatus: ModalStatus.Hide,
};
const evaluationSlice = createSlice({
  name: "currentSurvey",
  initialState,
  reducers: {
    fetching: (state) => {
      state.status = FetchStatus.Fetching;
    },
    fetchedEvaluation: (state, action: PayloadAction<IEvaluation>) => {
      state.evaluation = action.payload;
      state.status = FetchStatus.Fetched;
    },
    error: (state, _action: PayloadAction) => {
      state.evaluation = null;
      state.status = FetchStatus.Error;
    },
    registering: (state) => {
      state.regiterStatus = RegisterEvaluationStatus.Registering;
    },
    registeredEvaluationResult: (state) => {
      state.regiterStatus = RegisterEvaluationStatus.Registered;
    },
    errorEvaluationResult: (state, _action: PayloadAction) => {
      state.evaluation = null;
      state.regiterStatus = RegisterEvaluationStatus.Error;
    },
    showModal: (state) => {
      state.modalStatus = ModalStatus.Show;
    },
    hideModal: (state) => {
      state.modalStatus = ModalStatus.Hide;
    },
  },
});

const BASE_ENDPOINT = "/evaluations";
export const {
  fetching,
  fetchedEvaluation,
  error,
  registering,
  registeredEvaluationResult,
  errorEvaluationResult,
  hideModal,
} = evaluationSlice.actions;

export const fetchSurvey = (id: string, parentId: string) => (
  dispatch: Dispatch<object>
) => {
  return dispatch(
    apiActionCreator({
      endpoint: `${BASE_ENDPOINT}/${id}/${parentId}`,
      types: {
        requestType: fetching,
        successTypes: [
          {
            actionOrCreator: fetchedEvaluation,
          },
        ],
        errorTypes: [
          {
            actionOrCreator: error,
          },
        ],
      },
      method: HttpMethods.GET,
      microservice: Microservices.EVALUATION_SERVICE,
      authenticated: true,
    })
  );
};

const { showModal } = evaluationSlice.actions;

const hideModalWithDelay = (): AppThunk => async (dispatch) => {
  return new Promise((resolve) => {
    setTimeout(() => {
      dispatch(hideModal());
      resolve();
    }, MODAL_DELAY_MS);
  });
};

export const registerSurveyResult = (
  params: RegisterEvaluationResultParams
) => (dispatch: Dispatch<object>) => {
  return dispatch(
    apiActionCreator({
      endpoint: `/evaluation/results/register`,
      types: {
        requestType: registering,
        successTypes: [
          {
            actionOrCreator: registeredEvaluationResult,
          },
          {
            actionOrCreator: showModal,
            selector: () => {
              dispatch(hideModalWithDelay());
            },
          },
        ],
        errorTypes: [
          {
            actionOrCreator: errorEvaluationResult,
          },
        ],
      },
      method: HttpMethods.POST,
      data: params,
      microservice: Microservices.EVALUATION_SERVICE,
      authenticated: true,
    })
  );
};

export const selectSurvey = (state: RootState) =>
  state.currentSurvey.evaluation;
export const selectAreSurveysFetching = (state: RootState) =>
  state.currentSurvey.status === FetchStatus.Fetching;
export const selectDisplayModal = (state: RootState) =>
  state.currentSurvey.modalStatus === ModalStatus.Show;

export default evaluationSlice.reducer;
