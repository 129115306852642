import * as config from '../app/config';
import { cognitoUser, logout } from '../features/auth/authSlice';
import { Microservices } from './AllowedMicroservices';

const DOMAIN = config.API_DOMAIN;
const PROTOCOL = config.API_PROTOCOL;

const COMPANY_PORT = config.COMPANY_PORT;
const LEARNING_PORT = config.LEARNING_PORT;
const EVALUATION_PORT = config.EVALUATION_PORT;
const KNOWLEDGEBASE_PORT = config.KNOWLEDGEBASE_PORT;
const CAMPAIGN_PORT = config.CAMPAIGN_PORT;
const MEDIA_PORT = config.MEDIA_PORT;
const ANALYTICS_PORT = config.ANALYTICS_PORT;


export const BASE_URL = {
  [Microservices.COMPANY_SERVICE]: `${PROTOCOL}${DOMAIN}:${COMPANY_PORT}/v1`,
  [Microservices.FILES_SERVICE]: `${PROTOCOL}${DOMAIN}:${COMPANY_PORT}/v1`,
  [Microservices.USER_SERVICE]: `${PROTOCOL}${DOMAIN}:${COMPANY_PORT}/v1`,
  [Microservices.LEARNING_SERVICE]: `${PROTOCOL}${DOMAIN}:${LEARNING_PORT}/v1`,
  [Microservices.TOPIC_SERVICE]: `${PROTOCOL}${DOMAIN}:${LEARNING_PORT}/v1`,
  [Microservices.EVALUATION_SERVICE]: `${PROTOCOL}${DOMAIN}:${EVALUATION_PORT}/v1`,
  [Microservices.KNOWLEDGE_BASE_SERVICE]: `${PROTOCOL}${DOMAIN}:${KNOWLEDGEBASE_PORT}/v1`,
  [Microservices.CAMPAIGN_SERVICE]: `${PROTOCOL}${DOMAIN}:${CAMPAIGN_PORT}/v1`,
  [Microservices.MEDIA_SERVICE]: `${PROTOCOL}${DOMAIN}:${MEDIA_PORT}/v1`,
  [Microservices.X_ONE]: 'https://api.cognizant.x-one.cloud/api/',
  [Microservices.ANALYTICS_SERVICE]: `${PROTOCOL}${DOMAIN}:${ANALYTICS_PORT}/v1`,
};

const addTokenIfIsAuthenticated = ({ authenticated, token, config, endpoint }) => {
  if (!authenticated) {
    return config;
  }
  if (token) {
    config.headers['Authorization'] = token;
    return config;
  }
  throw new Error(`NO_TOKEN_SAVED, ${endpoint}`);
};
const addBody = (data, configWithHeaders) => {
  if (!data) {
    return configWithHeaders;
  }
  if (configWithHeaders.headers['content-type'] === 'multipart/form-data') {
    delete configWithHeaders.headers['content-type'];
    delete configWithHeaders.headers['Accept'];
    return {
      ...configWithHeaders,
      body: data,
    };
  }
  if (configWithHeaders.headers['content-type'] === 'application/x-www-form-urlencoded') {
    return {
      ...configWithHeaders,
      body: data
    };
  }
  return {
    ...configWithHeaders,
    body: JSON.stringify(data),
  };
};

const updateToken = (next) => {
  return new Promise((resolve, reject) => {
    cognitoUser.getSession(async (err, session)=>{
      if (session) {
        const refreshToken = session.getRefreshToken();;
        cognitoUser.refreshSession(refreshToken, async (err, session)=>{
          if(session){
            return resolve(session.accessToken.jwtToken)
          }
          await next(logout());
          reject();
        });
        return;
      }
      await next(logout());
      reject();
    });
  })
};
const generateMessage = (url, config, textMessage, status, data) => {
  const { method, headers } = config;
  let message = {
    title: 'API_ERROR',
    url,
    method,
    ...headers,
  };
  if (textMessage) {
    message.textMessage = textMessage;
  }
  if (status) {
    message.status = status;
  }
  if (data) {
    message.submittedData = JSON.stringify(data);
  }
  return message;
};

async function callApi({
  endpoint,
  authenticated,
  microservice,
  method,
  data,
  token,
  next,
  contentType = 'application/json',
  accept = 'application/json',
}) {
  const headers = {
    'content-type': contentType,
    Accept: accept,
  };
  const configWithHeaders = addTokenIfIsAuthenticated({
    authenticated,
    token,
    config: { method, headers },
    endpoint,
  });
  const config = addBody(data, configWithHeaders);
  let response = null;
  const url = BASE_URL[microservice] + endpoint;
  try {
    response = await fetch(url, config);
  } catch (e) {
    const errorMessage = generateMessage(
      url,
      config,
      e.message,
      response ? response.status : 'NET_ERROR',
      data,
    );
    return Promise.reject({ data: e.message, errorMessage });
  }
  if (response.status === 401) {// TODO: change to cognito
    const newToken =
    (await updateToken(next)) || {};
    if (newToken) {
      config.headers['Authorization'] = newToken;
      try {
        response = await fetch(BASE_URL[microservice] + endpoint, config);
      } catch (e) {
        const errorMessage = generateMessage(
          url,
          config,
          e.message,
          response.status,
          data,
        );
        return Promise.reject({
          data: e.message,
          status: response.status,
          errorMessage,
        });
      }
    }
  }

  if (!response.ok) {
    const json = await response.json();
    const errorMessage = generateMessage(
      url,
      config,
      JSON.stringify(json),
      response.status,
      data,
    );
    return Promise.reject({
      status: response.status,
      data: json,
      errorMessage,
    });
  }
  let json = null;
  if (response.status === 204) {
    return '';
  }
  try {
    if (accept !== 'application/json' && response.blob) {
      return await response.blob();
    }
    json = await response.json();
  } catch (e) {
    const errorMessage = generateMessage(
      url,
      config,
      e.message,
      undefined,
      response,
    );
    return Promise.reject({ data: e.message, errorMessage });
  }
  return json;
}

const getToken = (authenticated) => {
  if (!authenticated || !cognitoUser) {
    return null;
  }
  if(!cognitoUser.getSignInUserSession()){
    return null;
  }
  return cognitoUser.getSignInUserSession().getAccessToken().getJwtToken();
};

const getRefreshToken = store => {
  const { security } = store.getState();
  if (!security) {
    return null;
  }
  return security.refreshToken;
};

export const CALL_API = 'CALL_API';

const executeActionsResponse = (next, response, typeObjs) => {
  const actionTypesObjects = [].concat(typeObjs);
  actionTypesObjects.forEach(actionTypeObj => {
    const actionIntend = actionTypeObj.actionOrCreator;
    const selector = actionTypeObj.selector;
    if (typeof actionIntend === 'function') {
      const actionCreator = actionIntend;
      if(typeof selector === 'function') {
        return next(actionCreator(selector(response)));  
      } else {
        return next(actionCreator(response));  
      }
    } else if (typeof actionIntend === 'object') {
      const action = actionIntend;
      return next(action);
    } 
    
    return next({
      type: 'unknown-action',
    });
  });
};

const executeActionsError = (next, error, typesObjs) => {
  const actionTypesObjects = [].concat(typesObjs);
  actionTypesObjects.forEach(actionTypeObj => {
    const actionIntend = actionTypeObj.actionOrCreator;
    if (typeof actionIntend === 'function') {
      const selector = actionTypeObj.selector;
      const actionCreator = actionIntend;
      if(typeof selector === 'function') {
        return next(actionCreator(selector(error)));
      } else {
        return next(actionCreator());
      }
    } else if (typeof actionIntend === 'object') {
      const action = actionIntend;
      return next(action);
    }

    return next({
      type: 'unknown-action',
    });
  });
};

const ApiMiddleware = store => next => action => {
  const callAPI = action[CALL_API];

  // So the middleware doesn't get applied to every single action
  if (typeof callAPI === 'undefined') {
    return next(action);
  }
  const {
    endpoint,
    types,
    authenticated,
    method,
    data,
    microservice,
    contentType,
    accept,
    addOriginalAuthorization,
    customToken,
  } = callAPI;

  const {requestType, successTypes, errorTypes} = types;

  if (typeof requestType === 'object') {
    next(requestType);
  } else if(typeof requestType === 'function') {
    next(requestType());
  } else {
    next({
      type: requestType,
    });
  }
  
  const token = customToken ?? getToken(authenticated);
  const refreshToken = getRefreshToken(store);

  // Passing the authenticated boolean back in our data will let us distinguish between normal and secret quotes
  return callApi({
    endpoint,
    authenticated,
    microservice,
    method,
    data,
    token,
    refreshToken,
    next,
    contentType,
    accept,
    addOriginalAuthorization,
  })
    .then(response => {
      return executeActionsResponse(next, response, successTypes);
    })
    .catch(error => {
      return executeActionsError(next, error, errorTypes, store);
    });
};

export default ApiMiddleware;