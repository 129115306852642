import React from "react";
import { useSelector } from "react-redux";
import { selectCasesByReportTypeData } from "../../../../features/reports/case/ticketsByReportType";
import {
  selectCaseAssignedData,
  selectCaseResolvedData,
} from "../../../../features/reports/case/ticketsByStatus";
import { IReportValue } from "../../../../features/reports/common";
import { IconNames } from "../../common/SimpleStat";
import { StatItemType, StatsRow } from "../../common/StatsRow";

export const getTotal = (cases: IReportValue[]) => cases.reduce(
  (accum, item) => (accum += item.value as number),
  0
);

export const CasesStats: React.FunctionComponent = () => {
  const data = useSelector(selectCasesByReportTypeData);
  const resolved = useSelector(selectCaseResolvedData);
  const assigned = useSelector(selectCaseAssignedData);

  const stats: StatItemType[] = [
    {
      description: "Casos registrados",
      iconName: IconNames.Cases,
      mainText: getTotal(data).toString(),
    },
    {
      description: "Casos asignados",
      iconName: IconNames.CasesAssigned,
      mainText: getTotal(assigned).toString(),
    },
    {
      description: "Casos resueltos",
      iconName: IconNames.CasesActive,
      mainText: getTotal(resolved).toString(),
    },
  ];
  return <StatsRow statsData={stats} />;
};
