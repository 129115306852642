import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Dispatch } from "react";
import { Microservices } from "../../../../app/AllowedMicroservices";
import { RootState } from "../../../../app/store";
import apiActionCreator, {
  HttpMethods,
} from "../../../../services/apiActionCreator";
import { FetchStatus } from "../../../common/enums";
import { EvaluationTypeEnum } from '../../../evaluation/common/enums';

import { IEvaluationsCounters } from '../common/interfaces';

interface ITestResultsCountersState {
  data: IEvaluationsCounters[],
  status: FetchStatus,
}
const initialState: ITestResultsCountersState = {
  data: [],
  status: FetchStatus.Idle,
};

export const dataSlice = createSlice({
  name: "reportLearningTopicsTestResultsCounters",
  initialState,
  reducers: {
    fetching: (state) => {
      state.status = FetchStatus.Fetching;
    },
    fetched: (state, action: PayloadAction<IEvaluationsCounters[]>) => {
      state.data = action.payload;
      state.status = FetchStatus.Fetched;
    },
    error: (state) => {
      state.status = FetchStatus.Error;
    },
  },
});

export const { fetching, fetched, error } = dataSlice.actions;

interface IFetchTestCounters{
  companyId?: string,
  usersIds?: string[],
  parentId?: string
}
export const fetchTestCounters = ({ companyId, usersIds, parentId }:IFetchTestCounters) => (dispatch: Dispatch<object>) => {
  let url = '/evaluation/results/evaluationsCounters';
  const data:{
    evaluationType: EvaluationTypeEnum;
    companyId?: string,
    usersIds?: string[],
    parentId?: string,
  } = {
    companyId,
    usersIds,
    parentId,
    evaluationType: EvaluationTypeEnum.Test
  };
  return dispatch(
    apiActionCreator({
      endpoint: url,
      types: {
        requestType: fetching,
        successTypes: [
          {
            actionOrCreator: fetched,
          },
        ],
        errorTypes: [
          {
            actionOrCreator: error,
          },
        ],
      },
      method: HttpMethods.POST,
      microservice: Microservices.EVALUATION_SERVICE,
      authenticated: true,
      data,
    })
  );
};

export const selectTestResultsCounters = (state: RootState) => state.reports.learning.topics.testResultsCounters.data;
export const selectTestResultsCountersIsFetching = (state: RootState) => state.reports.learning.topics.testResultsCounters.status === FetchStatus.Fetching;

export default dataSlice.reducer;
