import React, { FunctionComponent } from "react";
import { CogIcon } from "../../common/CogIcon";
import { Tooltip } from "antd";
import cls from 'classnames';
import styles from "./simpleStat.module.scss";

export enum IconNames {
  User = 'user-default',
  UserEnabled = 'user-enabled',
  UserActive = 'user-active',
  Company = 'company',
  CompanyEnabled = 'company-enabled',
  CompanyActive = 'company-active',
  Base = 'base',
  BaseEnabled = 'base-enabled',
  BaseActive = 'base-active',
  Campaign = 'campaign',
  CampaignActive = 'campaign-active',
  Cases = 'cases',
  CasesAssigned = 'cases-assigned',
  CasesActive = 'cases-active',
  Availability = 'availability',
  AvailableTopics = 'bullet-boxes',
  AvailableCourses = 'doc-badge',
  RegisteredResources = 'entering',
  EnabledResources = 'check-green',
  Frequency = 'History',
  Check = 'Check',
}

export enum DisplayDirection {
  row = 'row',
  col = 'col'
}

interface StatItemProps {
  mainText: string,
  icon: IconNames,
  tooltipText?: string | undefined,
  description: string,
  direction?: DisplayDirection,
  iconColor?: string,
}

export const SimpleStat: FunctionComponent<StatItemProps> = ({
  mainText,
  icon,
  tooltipText,
  description,
  direction = DisplayDirection.col,
  iconColor
}) => {

  const color = icon === IconNames.Frequency ? '#3193DA' : iconColor;

  const renderTooltip = (tooltipText: string | undefined) => {
    if (!!tooltipText) {
      return (
        <div className={styles.tooltipTarget}>
          <Tooltip placement="topLeft" title={tooltipText}>
            <div><CogIcon size="1.4em" color="#81858b" icon={'Attention'} /></div>
          </Tooltip>
        </div>
      );
    }
    return null
  }

  const iconSize = direction === DisplayDirection.row ? '2.4em' : '2em';

  return (
    <div className={cls(styles.mainWrapper, {
      [styles.row]: direction === DisplayDirection.row,
    })}>
      <div className={styles.mainIcon}><CogIcon size={iconSize} icon={icon} color={color} /></div>
      <div className={styles.contents}>
        <div className={styles.numberWrapper}>
          <div className={styles.number}>{mainText}</div>
          {renderTooltip(tooltipText)}
        </div>
        <div className={styles.description}>{description}</div>

      </div>
    </div >
  );
};
