import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Dispatch } from "react";
import { Microservices } from "../../../app/AllowedMicroservices";
import { RootState } from "../../../app/store";
import apiActionCreator, {
  HttpMethods,
} from "../../../services/apiActionCreator";
import { FetchStatus } from "../../common/enums";
import { DataState, IReportValue, sortReportValue } from "../common";

const initialState: DataState = {
  data: [],
  status: FetchStatus.Idle,
};

export const dataSlice = createSlice({
  name: "companyEnabledVsDisabled",
  initialState,
  reducers: {
    fetching: (state) => {
      state.status = FetchStatus.Fetching;
    },
    fetched: (state, action: PayloadAction<IReportValue[]>) => {
      state.data = action.payload;
      state.data.sort(sortReportValue);
      state.status = FetchStatus.Fetched;
    },
    error: (state) => {
      state.data = [];
      state.status = FetchStatus.Error;
    },
  },
});

export const { fetching, fetched, error } = dataSlice.actions;

export const fetchCompanyEnabledVsDisabled = (grouper?: string) => (
  dispatch: Dispatch<object>
) => {
  let endpoint = "/companies/enabledVsDisabled";
  if (grouper) {
    endpoint += `/${grouper}`;
  }
  return dispatch(
    apiActionCreator({
      endpoint,
      types: {
        requestType: fetching,
        successTypes: [
          {
            actionOrCreator: fetched,
          },
        ],
        errorTypes: [
          {
            actionOrCreator: error,
          },
        ],
      },
      method: HttpMethods.GET,
      microservice: Microservices.COMPANY_SERVICE,
      authenticated: true,
    })
  );
};

export const selectCompanyEnabledVsDisabledData = (state: RootState) =>
  state.reports.company.companyEnabledVsDisabled.data;
export const selectCompanyEnabledVsDisabledIsFetching = (state: RootState) =>
  state.reports.company.companyEnabledVsDisabled.status ===
  FetchStatus.Fetching;

export default dataSlice.reducer;
