import React from 'react';
import styles from './carouselControls.module.scss';
import { CogIcon } from '../../common/CogIcon';

interface Props {
  handleNext: () => void,
  handlePrev: () => void,
}
export const CarouselControls: React.FC<Props> = ({ handleNext, handlePrev }) => {
  return (
    <div className={styles.carouselControls}>
      <div className={styles.arrow} onClick={() => { handleNext() }}>
        <CogIcon size='2em' className={styles.square} icon="Arrow-left" />
      </div>
      <div className={styles.arrow} onClick={() => { handlePrev() }}>
        <CogIcon size='2em' className={styles.square} icon="Arrow-right" />
      </div>
    </div>
  );
}
