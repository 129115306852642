import cls from "classnames";
import React, { FunctionComponent, ReactNode } from "react";
import { Doughnut } from "react-chartjs-2";
import styles from "./doughnut.module.scss";


interface DatasetType {
  label: string,
  data: number[],
  backgroundColor: string[],
  hoverOffset?: number,
}

export interface IDataStructure {
  labels: string[],
  datasets: DatasetType[]
}

interface ReturnValue {
  label: string,
  color: string,
}

const extractLabelsDataFromDougnutData = (doughnutData: IDataStructure): ReturnValue[] => {
  return doughnutData.labels.map((label, index) => ({
    label: label,
    color: doughnutData.datasets[0].backgroundColor[index]
  }));
}

interface DoughnutSimpleProps {
  canvasHeight?: number,
  canvasWidth?: number,
  type?: '1' | '2' | '3'
  percentValue: number | string,
  description: ReactNode,
  data: IDataStructure,
}

export interface DoughnutSimpleType extends DoughnutSimpleProps { }

export const DoughnutSimple: FunctionComponent<DoughnutSimpleProps> = ({
  canvasHeight = 100,
  type = '1',
  percentValue,
  description,
  canvasWidth = canvasHeight,
  data,
}) => {
  const options = getDefaultOptions();

  const labelsNode: ReactNode = (() => {
    const labelsData = extractLabelsDataFromDougnutData(data);
    return <div className={cls(styles.labelsContent, { [styles.typeTwo]: type === '2' })}>
      {
        labelsData.map((labelData, index) => {
          return (
            <div className={styles.itemContainer} key={index}>
              <div className={styles.box} style={{ backgroundColor: `${labelData.color}` }}></div>
              <div className={styles.label}>{labelData.label}</div>
            </div>
          );
        })
      }
    </div>;
  })();

  const infoNode: ReactNode = (() => {
    return (
    <div style={{ 
      minWidth: `${canvasHeight}px`, 
      maxWidth: `${type !== '1' ? 1.5 * canvasHeight + "px" : "100%"}`
    }}
      className={cls(styles.contentsContainer)}>
      <div className={styles.stats}>
        <div className={styles.number}>{percentValue}%</div>
        <div className={styles.description}>{description}</div>
      </div>
      {
        type === '1' ? labelsNode : null
      }
    </div>);
  })();

  if (type === '3') {
    options.legend.display = true;
  }
  const newCanvasWidth = type !== '3' ? canvasWidth : undefined

  const style = (() => {
    if (type !== '3') {
      return {
        width: `${canvasHeight}px`
      }
    }
    return {};
  })();

  return (
    <div className={cls(styles.mainWrapper, { [styles.typeTwo]: type === '2' })}>
      <div className={styles.container}>
        <div className={styles.doughnutGraph} style={{ ...style }}>
          <Doughnut
            width={newCanvasWidth}
            height={canvasHeight}
            type={"doughnut"}
            data={data}
            options={options}
          />
        </div>
        {type !== '3' ? infoNode : null}
      </div>
      {type === '2' ? labelsNode : null}
    </div>
  );
};

const getDefaultOptions = () => {
  return {
    legend: {
      display: false,
      position: 'right',
      align: 'center',
    },
    maintainAspectRatio: true,
    tooltips: {
      callbacks: {
        label: function(tooltipItem:any, data:any) {
            const dataset = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index] as number;
            return dataset.toFixed(2);
        }
      }
    }
  };
}
