import { Microservices } from "../../app/AllowedMicroservices";
import { RootState } from "../../app/store";
import apiActionCreator, { HttpMethods } from "../../services/apiActionCreator";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Dispatch } from "react";

import { FetchStatus } from "../common/enums";

export interface ICompanyType {
  _id: string;
  name: string;
}
export interface ICompanyTypeDict {
  [_id: string]: ICompanyType;
}

interface CompanyTypeState {
  list: string[];
  dict: ICompanyTypeDict;
  status: FetchStatus;
}

const initialState: CompanyTypeState = {
  list: [],
  dict: {},
  status: FetchStatus.Idle,
};

interface CompanyTypeFetch {
  docs: ICompanyType[];
  limit: number;
  page: number;
  pages: number;
  total: number;
}

export const companyTypeSlice = createSlice({
  name: "companyTypes",
  initialState,
  reducers: {
    fetching: (state) => {
      state.status = FetchStatus.Fetching;
    },
    fetched: (state, action: PayloadAction<CompanyTypeFetch>) => {
      state.list = action.payload.docs.map((company) => company._id);
      state.dict = {};
      action.payload.docs.forEach((company) => {
        state.dict[company._id] = company;
      });
      state.status = FetchStatus.Fetched;
    },
    error: (state, _action: PayloadAction<CompanyTypeFetch>) => {
      state.list = [];
      state.status = FetchStatus.Error;
    },
  },
});

export const { fetching, fetched, error } = companyTypeSlice.actions;

export const fetchCompanyTypes = () => (dispatch: Dispatch<object>) => {
  return dispatch(
    apiActionCreator({
      endpoint: "/companyTypes?limit=1000000",
      types: {
        requestType: fetching,
        successTypes: [
          {
            actionOrCreator: fetched,
          },
        ],
        errorTypes: [
          {
            actionOrCreator: error,
          },
        ],
      },
      method: HttpMethods.GET,
      microservice: Microservices.COMPANY_SERVICE,
      authenticated: true,
    })
  );
};

export const selectCompanyTypes = (state: RootState) => state.companyTypes.list;
export const selectCompanyTypesFetchStatus = (state: RootState) =>
  state.companyTypes.status;
export const selectCompanyTypesDict = (state: RootState) =>
  state.companyTypes.dict;
export const selectCompanyType = (id: string) => (state: RootState) =>
  state.companyTypes.dict[id];
export const selectCompanyTypesAreFetching = (state:RootState) =>
  state.companyTypes.status === FetchStatus.Fetching;

export default companyTypeSlice.reducer;
