import { Modal } from "antd";
import ctx from "classnames";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectQuiz } from "../../features/evaluation/evaluationsSlice";
import { ILesson } from "../../features/lesson/lessonSlice";
import { CogIcon } from "../common/CogIcon";
import styles from "./linkQuizToVideo.module.scss";
import { VideoPreviewForLink } from "./VideoPreviewForLink";

interface LinkQuizToVideoProps {
  open: boolean;
  lesson: ILesson;
  quizId?: string;
  videoId?: string;
  setNewLesson: React.Dispatch<React.SetStateAction<ILesson>>;
  onClose(): void;
}

export const LinkQuizToVideo: React.FunctionComponent<LinkQuizToVideoProps> = ({
  lesson,
  open,
  quizId,
  videoId,
  setNewLesson,
  onClose,
}) => {
  const [changedLesson, setChangedLesson] = useState<ILesson>();
  const [progress, setProgress] = useState<number>(0);
  const [isPaused, setIsPaused] = useState<boolean>(true);
  const quiz = useSelector(selectQuiz(quizId || ""));
  const { media = [] } = lesson;
  const [video] = media;

  useEffect(() => {
    if (lesson) {
      setChangedLesson(lesson);
    }
  }, [lesson]);

  if (!quizId || !quiz || !videoId || !video || !video.videoInfo || !lesson) {
    return null;
  }

  const { duration } = video.videoInfo;

  const removeLink = (questionId: string) => () => {
    if (!changedLesson) {
      return;
    }
    const newLesson = { ...changedLesson };
    newLesson.quizCuePoints = !newLesson.quizCuePoints
      ? []
      : [...newLesson.quizCuePoints];
    const index = newLesson.quizCuePoints.findIndex(
      (quizCuePoint) => questionId === quizCuePoint.questionId
    );
    if (index === -1) {
      return;
    }
    newLesson.quizCuePoints.splice(index, 1);
    setChangedLesson(newLesson as ILesson);
  };

  const linkToTime = (questionId: string) => () => {
    if (!isPaused || !changedLesson) {
      return;
    }
    const newLesson = { ...changedLesson };
    newLesson.quizCuePoints = !newLesson.quizCuePoints
      ? []
      : [...newLesson.quizCuePoints];
    const index = newLesson.quizCuePoints.findIndex(
      (quizCuePoint) => questionId === quizCuePoint.questionId
    );
    if (index === -1) {
      newLesson.quizCuePoints.push({
        questionId,
        time: progress * duration,
      });
    } else {
      newLesson.quizCuePoints[index] = {
        questionId,
        time: progress * duration,
      };
    }
    setChangedLesson(newLesson as ILesson);
  };
  
  return (
    <Modal
      visible={open}
      title={quiz.title}
      className={styles.modal}
      onCancel={() => {
        onClose();
      }}
      onOk={() => {
        if (changedLesson) {
          setNewLesson(changedLesson);
        }
        onClose();
      }}
      okText="Guardar vinculo"
      cancelText="Cancelar"
      okButtonProps={{
        disabled:
          !changedLesson ||
          !changedLesson.quizCuePoints ||
          changedLesson.quizCuePoints.length < quiz.questions.length,
      }}
    >
      <VideoPreviewForLink
        setProgress={setProgress}
        progress={progress}
        onChangeStatus={(isPaused) => {
          setIsPaused(isPaused);
        }}
        duration={duration}
        quizCuePoints={changedLesson?.quizCuePoints || []}
        videoUrl={videoId}
      />
      <div className={styles.innerContainer}>
        <div className={styles.divider} />
        <div className={styles.questions}>
          {quiz.questions.map((question) => {
            const quizCuePoint = changedLesson?.quizCuePoints?.find(
              (quizCuePoint) => quizCuePoint.questionId === question._id
            );
            const hours = quizCuePoint?.time
              ? Math.floor(quizCuePoint?.time / 60)
              : 0;
            const seconds = quizCuePoint?.time
              ? Math.floor(quizCuePoint?.time % 60)
              : 0;
            return (
              <div className={styles.question} key={question._id}>
                <div className={styles.title}>
                  <div className={styles.innerTitle}>
                    <CogIcon className={styles.icon} icon="test" />
                    {question.title}
                  </div>
                </div>
                {quizCuePoint?.time !== undefined ? (
                  <div className={styles.time}>
                    <CogIcon className={styles.icon} icon="clock" />
                    {("0" + hours).slice(-2)}:{("0" + seconds).slice(-2)}
                    <span onClick={removeLink(question._id || "")}>
                      <CogIcon
                        className={ctx(styles.icon, styles.delete)}
                        icon="Cross"
                      />
                    </span>
                  </div>
                ) : (
                  <span
                    className={ctx(styles.link, {
                      [styles.disabled]: !isPaused,
                    })}
                    onClick={linkToTime(question._id || "")}
                  >
                    <CogIcon
                      color={isPaused ? "#4376F9" : "#686F84"}
                      className={styles.icon}
                      icon="Bookmark-border"
                    />
                    Vincular
                  </span>
                )}
              </div>
            );
          })}
        </div>
      </div>
    </Modal>
  );
};
