import React, { FunctionComponent, useState } from 'react';
import { Drawer, Button } from 'antd';

import styles from './calendar.module.scss';
import { useSelector } from 'react-redux';
import {
    selectStudentMyCourses
} from '../../../features/students/myCoursesSlice';
import { CogIcon } from '../../common/CogIcon';
import { Calendar as MyCalendar } from './Calendar/index'; 

interface CalendarProps {
}

export const Calendar: FunctionComponent<CalendarProps> = () => {
    const [visible, setVisible] = useState<boolean>(false);
    const courses = useSelector(selectStudentMyCourses);
    const onClose = () => {
        setVisible(false);
    }
    return <React.Fragment>
        <Drawer
            className={styles.drawer}
            placement={'left'}
            closable={false}
            onClose={onClose}
            visible={visible}
        >
            <MyCalendar courses={courses} weekStartOnSunday={false} date={new Date()} onSelectDate={(date) => {
             }} />
        </Drawer>
        <div className={styles.container}>
            <Button className={styles.calendarButton} onClick={() => {
                setVisible(true);
            }}><CogIcon color="#686F84" icon="Calendar"/>&nbsp;&nbsp;Cronograma</Button>
        </div>
    </React.Fragment>

};