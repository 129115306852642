import { CognitoUserPool } from 'amazon-cognito-identity-js';
import { COGNITO_USER_POOL_ID, COGNITO_APP_CLIENT_ID } from '../app/config';

type UserPoolCredentials = {
	UserPoolId: string,
	ClientId: string
};

const UserPoolData: UserPoolCredentials = {
	UserPoolId: COGNITO_USER_POOL_ID || '',
	ClientId: COGNITO_APP_CLIENT_ID || ''
};

const CognizantUserPool = new CognitoUserPool(UserPoolData);

export default CognizantUserPool;
