import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Dispatch } from "react";
import { AppThunk, RootState } from "../../app/store";
import apiActionCreator from "../../services/apiActionCreator";
import { MODAL_DELAY_MS } from "../../utils/constants";
import { ModalStatus } from "../common/enums";
import { Microservices } from "./../../app/AllowedMicroservices";
import { NotificationsTypes } from "./../../components/Notifications/Manager";
import { HttpMethods } from "./../../services/apiActionCreator";

export enum SendType {
  NOW = "NOW",
  SCHEDULE = "SCHEDULE",
}
export enum NotificationsStatusEnum {
  IDLE = "IDLE",
  SENDING_NOW = "SENDING_NOW",
  SENDING_SCHEDULE = "SENDING_SCHEDULE",
}
interface MesageState {
  status: NotificationsStatusEnum;
  modalStatus: ModalStatus;
}

const initialState: MesageState = {
  status: NotificationsStatusEnum.IDLE,
  modalStatus: ModalStatus.Hide,
};

export interface ISetMessage {
  message: string;
}

export const notificationsSlice = createSlice({
  name: "notifications",
  initialState,
  reducers: {
    sending: (state, action: PayloadAction<SendType>) => {
      state.status =
        action.payload === SendType.NOW
          ? NotificationsStatusEnum.SENDING_NOW
          : NotificationsStatusEnum.SENDING_SCHEDULE;
    },
    sent: (state) => {
      state.status = NotificationsStatusEnum.IDLE;
    },
    showModal: (state) => {
      state.modalStatus = ModalStatus.Show;
    },
    hideModal: (state) => {
      state.modalStatus = ModalStatus.Hide;
    },
    error: (state) => {
      state.status = NotificationsStatusEnum.IDLE;
    },
  },
});

export const { sending, sent, error, hideModal } = notificationsSlice.actions;
const { showModal } = notificationsSlice.actions;

export interface IToData {
  to?: string; //phone or email
  username: string;
  companyId: string;
}

interface ISendNotifications {
  type: NotificationsTypes;
  title?: string;
  message: string;
  to: IToData[];
}

export const sendNotifications =
  ({ type, title, message, to }: ISendNotifications) =>
  (dispatch: Dispatch<object>) => {
    const data = {
      subject: title,
      body: message,
      to,
    };
    let endpoint = "/notifications";
    switch (type) {
      case NotificationsTypes.SMS:
        endpoint += "/sendSMS";
        break;
      case NotificationsTypes.EMAIL:
        endpoint += "/sendEmails";
        break;
      case NotificationsTypes.PUSH:
        endpoint += "/sendPush";
        break;
    }
    return dispatch(
      apiActionCreator({
        endpoint,
        types: {
          requestType: {
            type: sending.name,
            payload: SendType.NOW,
          },
          successTypes: [
            {
              actionOrCreator: sent,
            },
            {
              actionOrCreator: showModal,
              selector: () => {
                dispatch(hideModalWithDelay());
              },
            },
          ],
          errorTypes: [
            {
              actionOrCreator: error,
            },
          ],
        },
        method: HttpMethods.POST,
        microservice: Microservices.COMPANY_SERVICE,
        authenticated: true,
        data,
      })
    );
  };

interface ISendNotificationsSchedule {
  date: string;
  type: NotificationsTypes;
  title?: string;
  message: string;
  to: IToData[];
}

export const sendNotificationsSchedule =
  ({ date, type, title, message, to }: ISendNotificationsSchedule) =>
  (dispatch: Dispatch<object>) => {
    const data = {
      subject: title,
      body: message,
      to,
      type,
      date,
    };
    const endpoint = "/notifications/schedule";
    return dispatch(
      apiActionCreator({
        endpoint,
        types: {
          requestType: {
            type: sending.name,
            payload: SendType.SCHEDULE,
          },
          successTypes: [
            {
              actionOrCreator: sent,
            },
            {
              actionOrCreator: showModal,
              selector: () => {
                dispatch(hideModalWithDelay());
              },
            },
          ],
          errorTypes: [
            {
              actionOrCreator: error,
            },
          ],
        },
        method: HttpMethods.POST,
        microservice: Microservices.COMPANY_SERVICE,
        authenticated: true,
        data,
      })
    );
  };

const hideModalWithDelay = (): AppThunk => async (dispatch) => {
  return new Promise((resolve) => {
    setTimeout(() => {
      dispatch(hideModal());
      resolve();
    }, MODAL_DELAY_MS);
  });
};

export const selectNotificationsStatus = (state: RootState) =>
  state.notifications.status;
export const selectDisplayModal = (state: RootState) =>
  state.notifications.modalStatus === ModalStatus.Show;

export default notificationsSlice.reducer;
