import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Dispatch } from "react";
import { FetchStatus } from "../../common/enums";
import apiActionCreator, {
  HttpMethods,
} from "../../../services/apiActionCreator";
import { Microservices } from "../../../app/AllowedMicroservices";
import { RootState } from "../../../app/store";
import moment from "moment";
import { IRecord, InsAndOuts, IRecordsAtTimeWindow, EnableDisableProps } from "./insAndOutsCommon";

interface EntriesAndExitsState {
  fetchStatus: FetchStatus,
  insAndOutsPerDay: InsAndOuts,
}

const initialState: EntriesAndExitsState = {
  fetchStatus: FetchStatus.Idle,
  insAndOutsPerDay: {},
};

interface EnabledAnDisabledFetch {
  atStartDate: IRecord[];
  betweenStartAndEndDates: IRecordsAtTimeWindow;
}

const enablingDisablingCompanySlice = createSlice({
  name: "insAndOutsPerDay",
  initialState,
  reducers: {
    fetching: (state) => {
      state.fetchStatus = FetchStatus.Fetching;
    },
    fetched: (state, action: PayloadAction<EnabledAnDisabledFetch>) => {
      const inOutsPerDayResults = processForInOutsPerDay(
        action.payload.betweenStartAndEndDates
      );
      state.insAndOutsPerDay = inOutsPerDayResults;
      state.fetchStatus = FetchStatus.Fetched;
    },
    fetchingFailed: (state) => {
      state.fetchStatus = FetchStatus.Error;
    },
  },
});

interface IGroupByDate {
  [date: string]: IRecord[];
}

export const processForInOutsPerDay = (data: IRecordsAtTimeWindow) => {
  const trimGroups = (groupsByDate: { [date: string]: IRecord[] }) => {
    const result: { [date: string]: IRecord } = {};
    for (const date in groupsByDate) {
      const groupLength = groupsByDate[date].length;
      if (groupLength === 0) {
        console.error("Group must not be empty.");
      }
      if (groupLength > 1) {
        if (groupLength % 2 === 0) {
        } else {
          result[date] = { ...groupsByDate[date][groupLength - 1] };
        }
      } else {
        result[date] = { ...groupsByDate[date][0] };
      }
    }
    return result;
  };
  const processed = Object.values(data).reduce((acc, current) => {
    const records = current.records;
    const groupsByDate = records
      .sort((a, b) => +new Date(b.createdAt) - +new Date(a.createdAt))
      .reduce((acc, record) => {
        const createdAtString = record.createdAt;
        const createdAt = moment(createdAtString);
        const date = createdAt.format('YYYY-MM-DDTHH:mm:ss').split("T")[0];
        if (!acc[date]) {
          acc[date] = [];
        }
        acc[date].push(record);
        return acc;
      }, {} as IGroupByDate);

    const latestRecordByDate = trimGroups(groupsByDate);
    acc[current.companyId] = {
      companyId: current.companyId,
      byDate: latestRecordByDate,
    };
    return acc;
  }, {} as InsAndOuts);
  return processed;
};

export const { fetching, fetched, fetchingFailed } =
  enablingDisablingCompanySlice.actions;

export const fetchInsAndOutsPerCompanyPerDayData =
  ({ yyyyMmDdEndDate, utcOffset }: EnableDisableProps) =>
  (dispatch: Dispatch<object>) => {
    return dispatch(
      apiActionCreator({
        endpoint: `/companies/insAndOuts?endDate=${yyyyMmDdEndDate}&utcOffset=${utcOffset}`,
        types: {
          requestType: fetching,
          successTypes: [
            {
              actionOrCreator: fetched,
            },
          ],
          errorTypes: [
            {
              actionOrCreator: fetchingFailed,
            },
          ],
        },
        method: HttpMethods.GET,
        microservice: Microservices.COMPANY_SERVICE,
        authenticated: true,
      })
    );
  };

export const selectDataIsFetching = (state: RootState) =>
  state.reports.company.insAndOutsNew.perDay.fetchStatus === FetchStatus.Fetching;
export const selectInsAndOutsPerCompanyPerDayData = (state: RootState) =>
  state.reports.company.insAndOutsNew.perDay.insAndOutsPerDay;

export default enablingDisablingCompanySlice.reducer;
