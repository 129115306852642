import React from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import { Upload, message } from 'antd';
import { RcFile } from 'antd/es/upload/interface';

import { CogIcon } from '../../common/CogIcon';
import { cognitoUser } from '../../../features/auth/authSlice';
import { Microservices } from '../../../app/AllowedMicroservices';
import { BASE_URL } from '../../../app/apiMiddleware';

import styles from './index.module.scss';

import folderIcon from '../../../assets/folder.svg';


interface UploadImageProps {
    onHandleChange: any,
    loading?: boolean,
    uploadUrl?: string,
    fileTypesAllowed?: string[],
    fileNotAllowedMessage?: string,
    maxMB?: number,
    imageUrlToShow?: string
}

const { Dragger } = Upload;



export const UploadFile: React.FunctionComponent<UploadImageProps> = ({
    onHandleChange,
    loading = false,
    uploadUrl = `${BASE_URL[Microservices.COMPANY_SERVICE]}/user/import`,
    fileTypesAllowed = ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet','.xlsx'],
    fileNotAllowedMessage = '¡Solo se puede subir archivos xlsx!',
    maxMB = 10,
    imageUrlToShow,
}) => {
    function beforeUpload(file: RcFile) {
        const isFileAllowed = fileTypesAllowed.includes(file.type);
        if (!isFileAllowed) {
            message.error(fileNotAllowedMessage);
        }
        const isLt10M = file.size / 1024 / 1024 < maxMB;
        if (!isLt10M) {
            message.error(`¡El archivo no debe pasar los ${maxMB}MB!`);
        }
        return isFileAllowed && isLt10M;
    }

    const renderContent = (loading: boolean) => {
        if (loading) {
            return <LoadingOutlined spin
                style={{ fontSize: 44, color: "#4376F9" }}
            />;
        }
        return <React.Fragment>
            {imageUrlToShow ? 
                <div className={styles.imageUploaded}><img src={imageUrlToShow} alt="uploaded file"/> </div>:
                <div className={styles.uploadIcon}>
                    <div className={styles.background} />
                    <div className={styles.bluePart}>
                        <CogIcon className={styles.icon} size="10px" color="white" icon="arrow-up-bold" />
                    </div>
                    <img className={styles.folder} src={folderIcon} alt="foder icon" />
                </div>
            }
            <div className={styles.text}>Arrastra y suelta un archivo aqui o haz clic para subir un archivo</div>
        </React.Fragment>;
    }

    return <Dragger
        headers={{
            Authorization: cognitoUser?.getSignInUserSession()?.getAccessToken().getJwtToken() || '',
        }}
        accept={fileTypesAllowed.join(',')}
        method="POST"
        onChange={onHandleChange}
        name='file'
        beforeUpload={beforeUpload}
        action={uploadUrl}
        className={styles.uploadFile}
        showUploadList={false}
    >
        {renderContent(loading)}

    </Dragger>;
};