import { Spin } from "antd";
import ctx from "classnames";
import React, { FunctionComponent } from "react";
import { Bar, HorizontalBar } from "react-chartjs-2";
import { IReportDataset } from "../../../features/reports/common";
import styles from "./barChartGrouped.module.scss";
import { buildTooltipsCallbacks } from "./utils";
interface BarChartSimpleProps {
  datasets: IReportDataset[];
  labels: string[];
  labelColumnTitle: string;
  fetching: boolean;
  height?: number;
  axisMaxValue?: number;
  displayHorizontal?: boolean;
  yTickCallBack?(value: any, index: number, values: any): string;
  titleCallback?(tooltipItem: any, data: any): string | string[] | null;
  labelCallback?(tooltipItem: any, data: any): string | string[] | null;
  hideLegend?: boolean,
}

export const BarChartGrouped: FunctionComponent<BarChartSimpleProps> = ({
  datasets,
  labels,
  fetching,
  height,
  axisMaxValue,
  displayHorizontal,
  yTickCallBack,
  titleCallback,
  labelCallback,
  hideLegend = false,
}) => {
  let stepSize = 1;
  const chartData = {
    labels: [...labels],
    datasets: datasets.map((dataset) => {
      dataset.data.forEach((point) => {
        if (stepSize < point) {
          stepSize = point;
        }
      });
      return {
        label: dataset.label,
        borderColor: dataset.color,
        backgroundColor: dataset.color,
        data: [...dataset.data],
      };
    }),
  };
  stepSize = Math.trunc(stepSize / 6);

  let yCallbackObj = {};
  if (yTickCallBack) {
    yCallbackObj = {
      callback: yTickCallBack,
    };
  }
  const tooltips = buildTooltipsCallbacks({ titleCallback, labelCallback });

  let chartProps = {
    data: chartData,
    height: height || 400,
    options: {
      legend: hideLegend ?  false : {
        position: "bottom",
      },
      maintainAspectRatio: false,
      scales: {
        xAxes: [
          {
            ticks: {
              max: axisMaxValue,
            },
          },
        ],
        yAxes: [
          {
            ticks: {
              min: 0,
              max: axisMaxValue,
              stepSize,
              ...yCallbackObj,
            },
          },
        ],
      },
      ...tooltips,
    },
  };

  chartProps = displayHorizontal
    ? {
        ...chartProps,
        options: {
          ...chartProps.options,
          scales: {
            xAxes: [
              {
                ticks: {
                  max: axisMaxValue,
                },
              },
            ],
            yAxes: [
              {
                ticks: {
                  min: 0,
                  max: undefined,
                  stepSize,
                  ...yCallbackObj,
                },
              },
            ],
          },
        },
      }
    : chartProps;

  return (
    <div className={ctx(styles.container)}>
      <Spin spinning={fetching}>
        {displayHorizontal ? (
          <HorizontalBar {...chartProps} />
        ) : (
          <Bar {...chartProps} />
        )}
      </Spin>
    </div>
  );
};
