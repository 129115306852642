import React from 'react';
import { Select, Form } from 'antd';
import { useSelector } from 'react-redux';

import { selectGroups } from '../../../features/groups/groupSlice';
import { IUser } from '../../../features/users/usersSlice';
import {
    ICompany,
} from '../../../features/company/companySlice';

interface CompanyFieldProps {
    newObject?: IUser,
    value?: string | ICompany,
    setNewObject: Function,
    companyId?: string,
}

export const GroupField: React.FunctionComponent<CompanyFieldProps> = (props) => {
    const { value, setNewObject, newObject, companyId } = props;
    const list = useSelector(selectGroups);
    return <Form.Item
        initialValue={newObject?.groupId}
        name="groupId"
        wrapperCol={{ sm: 24 }}
        label="Grupo de supervisión"
        rules={[{ required: true, message: 'Requerido' }]}
    >
        <Select
            placeholder="Asigna un grupo de supervisión"
            value={value?.toString()}
            style={{ width: '100%' }}
            onChange={(value) => {
                setNewObject({ ...newObject, groupId: value })
            }}>
            {list.filter(group => {
                return group.company === companyId
            }).map(group => {
                return <Select.Option key={group._id} value={group._id}>{group.name}</Select.Option>
            })}
        </Select>
    </Form.Item>
};