import React, { FunctionComponent } from 'react';

import moment from 'moment';
import cls from 'classnames';
import { Output } from '../common/Output';
import { IUser, GenderDict } from '../../features/users/usersSlice';
import { ICompany } from '../../features/company/companySlice';
import { idLabelsByType, IdTypes } from '../Users/common/identification';
import {
  Card,
  Row,
  Col,
  Divider,
} from "antd";

import styles from './shared.module.scss';
import { getCountryName } from '../Users/utils';

export enum Headlines {
  Admin = 'ADMIN',
  Student = 'STUDENT',
  Self = 'SELF',
}

interface ProfileDetails {
  user: IUser
  company: ICompany
  hideCreationDate?: boolean,
  headlinesType: Headlines,
}

export const ProfileDetailsColumn: FunctionComponent<ProfileDetails> = ({ user, company, hideCreationDate, headlinesType }) => {
  let firstBoxTitle = 'Perfil';
  let secondBoxTitle = 'Datos de la empresa';
  if (headlinesType === Headlines.Self) {
    firstBoxTitle = 'Mi perfil';
    secondBoxTitle = 'Datos laborales';
  }

  return (
    <React.Fragment>
      <Row gutter={[16, 40]}>
        <Col span={24}>
          <Card className={cls(styles.card, styles.overload)}>
            <h2 className={cls(styles.title)}>{firstBoxTitle}</h2>
            <Output label={'Nombres'} text={(user.given_name ? user.given_name : '') + ' ' + (user.middle_name ? user.middle_name : '')} />
            <Output label={'Apellidos'} text={(user.family_name ? user.family_name : '') + ' ' + (user.secondLastname ? user.secondLastname : '')} />
            <Output label={'Correo electrónico'} text={user.email} />
            <Divider />
            <Output label={'Tipo de identificación'} text={idLabelsByType[user.nationalIdType as IdTypes]} />
            <Output label={'Número de identificación'} text={user.nationalId} />
            <Output label={'Número telefónico'} text={user.phone_number} />
            <Output label={'Género'} text={GenderDict[user.gender]} ></Output>
            <Output label={'Fecha de nacimiento'} text={moment.utc(user.birthdate).format("DD-MM-YYYY")} />
            <Divider />
            <Output label={'País'} text={getCountryName(user.country) || '---'} />
            <Output label={'Ciudad'} text={user.city} />
            <Output label={'Dirección'} text={user.address} />
            {!hideCreationDate &&
              <Output label={'Fecha de creación'} text={moment.utc(user.createdAt).format("DD-MM-YYYY")} />
            }
          </Card>
        </Col>
        <Col span={24}>
          <Card className={cls(styles.card, styles.overload)}>
            <h2 className={cls(styles.title)}>{secondBoxTitle}</h2>
            <Output label={'Cargo'} text={user.position || ' '} />
            <Output label={'Empresa'} text={company.name} />
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
};