import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import moment from "moment";
import { Dispatch } from "react";
import { Microservices } from "../../../app/AllowedMicroservices";
import { RootState } from "../../../app/store";
import apiActionCreator, {
  HttpMethods,
} from "../../../services/apiActionCreator";
import { ConditionEnum, FetchStatus } from "../../common/enums";

interface ICampaignHistory {
  _id: {
    companyId: string;
    campaignId: string;
    status: ConditionEnum;
    date: string;
  }
  count: number;
}
interface IInitialStatus {
  _id: string;
  companyId: string;
  status: ConditionEnum;
}
interface ItoDate {
  _id: string;
  companyId: string;
  toDate: string;
}
interface CampaignHistoryFetch {
  history: ICampaignHistory[],
  initialStatus: IInitialStatus[],
  toDates: ItoDate[],
  fromDate: string,
  toDate: string,
  companyId?: string
}
interface point {
  x: string,
  y: number,
}
interface InitialStateData {
  history: ICampaignHistory[],
  initialStatus: IInitialStatus[],
  toDates: ItoDate[],
  series: { enabled: point[], disabled: point[] }
  status: FetchStatus;
}

const initialState: InitialStateData = {
  history: [],
  initialStatus: [],
  toDates: [],
  series: { enabled: [], disabled: [], },
  status: FetchStatus.Idle,
};
interface IAcc {
  enabled: number;
  disabled: number;
  created: number;
}
export const dataSlice = createSlice({
  name: "campaignByDate",
  initialState,
  reducers: {
    fetching: (state) => {
      state.status = FetchStatus.Fetching;
    },
    fetched: (state, action: PayloadAction<CampaignHistoryFetch>) => {
      const { 
        history, 
        initialStatus, 
        toDates, 
        fromDate: stringFromDate, 
        toDate: stringToDate,
        companyId
      } = action.payload;
      const fromDate = moment(stringFromDate);
      const toDate = moment(stringToDate);
      state.history = history;
      state.initialStatus = initialStatus;
      state.toDates = toDates;
      state.series = { enabled: [], disabled: [] };
      const historyDictChange: {[id:string]: IAcc} = {};
      history.forEach(item=>{
        if(companyId && item._id.companyId !== companyId){
          return;
        }
        const date = moment(item._id.date).format('YYYY-MM-DD');
        if(!historyDictChange[date]){
          historyDictChange[date] = {
            disabled: 0,
            enabled: 0,
            created: 0,
          };
        }
        if(item._id.status === ConditionEnum.Published){
          historyDictChange[date].enabled += item.count;
        }else if(item._id.status === ConditionEnum.Unpublished){
          historyDictChange[date].disabled += item.count;
        }else {
          historyDictChange[date].created += item.count;
        }
      });
      toDates.forEach(item => {
        if(companyId && item.companyId !== companyId){
          return;
        }
        const date = moment(item.toDate).format('YYYY-MM-DD');
        if(!historyDictChange[date]){
          historyDictChange[date] = {
            disabled: 0,
            enabled: 0,
            created: 0,
          };
        }
        historyDictChange[date].disabled += 1;
      })
      let { enabled, disabled, created } = initialStatus.reduce((acc: IAcc, value: IInitialStatus) => {
        if(companyId && value.companyId !== companyId){
          return acc;
        }
        if(value.status === ConditionEnum.Published){
          acc.enabled++;
          return acc;
        }
        acc.disabled++;
        return acc;

      }, { enabled: 0, disabled: 0, created: 0, });
      console.log('🚀 ~ file: byDateRange.ts ~ line 131 ~ created', created);
      const pointsEnabled:point[] = [];
      const pointsDisabled:point[] = [];
      for (const m = moment(fromDate); m.diff(toDate, 'days') <= 0; m.add(1, 'days')) {
        const x = m.format('YYYY-MM-DD');
        if(historyDictChange[x]){
          const change = historyDictChange[x].enabled - historyDictChange[x].disabled;
          enabled += change;
          disabled -= change;
          disabled += historyDictChange[x].created;
        }
        pointsEnabled.push({
          x,
          y: enabled,
        });
        pointsDisabled.push({
          x,
          y: disabled,
        });
      }
      state.series = {
        disabled: pointsDisabled,
        enabled: pointsEnabled,
      };
      state.status = FetchStatus.Fetched;
    },
    error: (state) => {
      state.history = [];
      state.initialStatus = [];
      state.toDates = [];
      state.series = { enabled: [], disabled: [] };
      state.status = FetchStatus.Error;
    },
  },
});

export const { fetching, fetched, error } = dataSlice.actions;

export const fetchCampaignHistory =
  (from: moment.Moment, to: moment.Moment, companyId?:string) => (dispatch: Dispatch<object>) => {
    const fromStrDate = moment(from).format("YYYY-MM-DD");
    const toStrDate = moment(to).format("YYYY-MM-DD");
    return dispatch(
      apiActionCreator({
        endpoint: "/campaign/history",
        types: {
          requestType: fetching,
          successTypes: [
            {
              actionOrCreator: fetched,
              selector: (response) => {
                return {
                  ...response,
                  fromDate: fromStrDate,
                  toDate: toStrDate,
                  companyId,
                };
              }
            },
          ],
          errorTypes: [
            {
              actionOrCreator: error,
            },
          ],
        },
        method: HttpMethods.POST,
        microservice: Microservices.CAMPAIGN_SERVICE,
        authenticated: true,
        data: {
          from: fromStrDate,
          to: toStrDate,
        },
      })
    );
  };

export const selectCampaignHitoryFetching = (state: RootState) =>
  state.reports.campaign.history.status === FetchStatus.Fetching;
export const selectCampaignHistory = (state: RootState) => state.reports.campaign.history.series;

export default dataSlice.reducer;
