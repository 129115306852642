import { Button, Card } from "antd";
import React, { FunctionComponent } from "react";
import { useSelector } from "react-redux";
import {
  selectIsEvaluationFetching,
  selectEvaluation,
} from "../../../../features/evaluation/currentEvaluationSlice";
import { selectStudentCourse } from "../../../../features/students/courseSlice";
import { CogIcon } from "../../../common/CogIcon";
import styles from "./firstPage.module.scss";

interface FirstPageProps {
  evaluationId: string;
  className?: string;
  onNext: Function;
}

export const FirstPage: FunctionComponent<FirstPageProps> = ({ onNext, evaluationId }) => {
  const course = useSelector(selectStudentCourse);
  const isFetchingEvaluation = useSelector(selectIsEvaluationFetching(evaluationId));
  const evaluation = useSelector(selectEvaluation(evaluationId));
  const questionsCount = evaluation?.questions?.length || 1;
  const correctAnswersToPass = course?.correctAnswersToPass || 0;
  const percentageToPass = Math.round(
    (correctAnswersToPass / questionsCount) * 100
  );
  const buttonLabel = "Comenzar examen";
  const disabledButton = evaluation?.timeToRetake !== 0 || isFetchingEvaluation;

  let cannotRetake = null;
  if (
    evaluation &&
    (evaluation?.timeToRetake === false ||
      evaluation?.timeToRetake === undefined)
  ) {
    cannotRetake = <li>Este examen no puede ser tomado de nuevo.</li>;
  } else if (evaluation && (evaluation?.timeToRetake || 0) > 0) {
    const timeToRetake = evaluation.timeToRetake as number;
    cannotRetake = (
      <li>
        El examen se puede volver a tomar en{" "}
        {timeToRetake < 1 ? "menos de " : ""}
        {Math.ceil(timeToRetake)} min
      </li>
    );
  }
  return (
    <Card
      className={styles.container}
      actions={[
        <div className={styles.buttonContainer}>
          <Button
            disabled={disabledButton}
            onClick={() => onNext()}
            type="primary"
          >
            {buttonLabel}
          </Button>
        </div>,
      ]}
    >
      <CogIcon className={styles.icon} icon="test-1" />
      <div className={styles.info}>
        Para tomar este examen toma en cuenta...
      </div>
      <ul className={styles.infoList}>
        <li>Todas las respuestas son obligatorias</li>
        <li>
          El porcentaje minimo para aprobar es del{" "}
          {percentageToPass > 100 || percentageToPass === 0
            ? "--"
            : percentageToPass}
          %
        </li>
        <li>
          Sólo puedes repetir el examen una vez transcurrido el tiempo
          establecido de {course?.timeToRetry} min
        </li>
        {cannotRetake}
      </ul>
    </Card>
  );
};
