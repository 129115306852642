export interface IWithShortId {
    _id?: string
    shortId?: string
}

const getShortId = (id: string, size: number): string => {
    return id.slice(-size);
  };
const verifyIfRepeated = (objs: (IWithShortId)[], maxStringSize:number): boolean => {
let found:{[_id: string]: boolean} = {};
for(const obj of objs){
    const shortId = getShortId(obj._id || '', maxStringSize);
    if(found[shortId]){
    return false;
    }
    found[shortId] = true;
}
return true;
};
export const buildShortIds = (objs: (IWithShortId)[]):(IWithShortId)[] => {
    let maxStringSize = 8;
    while(!verifyIfRepeated(objs, maxStringSize)){
        ++maxStringSize;
    }
    objs.forEach(lesson=>{
        lesson.shortId = getShortId(lesson._id ?? '', maxStringSize);
    })
    return objs;
};