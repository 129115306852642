import { StatusEnum } from "../common/Status";

interface IWidthCreatedAt {
  status: StatusEnum | null;
}
export function getStatusFilterAndSortProps<T extends IWidthCreatedAt>(
  statusFilter: StatusEnum | undefined
) {
  return {
    filters: [
      { text: "Habilitado", value: StatusEnum.Enable },
      { text: "Deshabilitado", value: StatusEnum.Disable },
    ],
    filteredValue: statusFilter?.toString() ? [statusFilter.toString()] : null,
    filterMultiple: false,
    sorter: (a: T, b: T) =>
      a.status === b.status ? 0 : a.status === StatusEnum.Enable ? 1 : -1,
    onFilter: (value: string | number | boolean, record: T) =>
      record.status === value,
  };
}
