import { Editor } from "@tinymce/tinymce-react";
import { Button, Card, Form, Input, Popconfirm, Select } from "antd";
import { SizeType } from "antd/lib/config-provider/SizeContext";
import { FormInstance } from "antd/lib/form";
import React, { FunctionComponent, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Microservices } from "../../app/AllowedMicroservices";
import { BASE_URL } from "../../app/apiMiddleware";
import { cognitoUser } from "../../features/auth/authSlice";
import { setBreadcrumbs } from "../../features/breadcrumbs/breadcrumbsSlice";
import {
  ICategoriesDict,
  ICategory,
} from "../../features/category/categorySlice";
import {
  fetchContentCategories,
  selectContentCategories,
  selectContentCategoriesDict,
} from "../../features/contentCategory/contentCategorySlice";
import {
  createContent,
  IContent,
  selectContent,
  selectIsContentFetching,
  updateContent,
} from "../../features/knowledgeBase/contentSlice";
import { CategoryField } from "../common/CategoryField";
import { CogIcon } from "../common/CogIcon";
import { OTHER_CATEGORY_OPTION } from "../common/CompanyTypeField";
import LoadingOverlay from "../common/LoadingOverlay";
import { StepIndicator } from "../common/StepIndicator";
import { MIN_3_CHAR } from "../Users/Form";
import { UploadFile } from "../Users/UploadFile";
import { CompanyField } from "./CompanyField";
import styles from "./form.module.scss";

interface StepProps {
  stepIndicator: React.ReactNode;
  setContent: Function;
  form: FormInstance;
  categories: ICategoriesDict;
  categoriesIds: string[];
  validateForm?: Function;
  content?: IContent;
}
const STEPS_COUNT = 3;
const MAX_TAGS = 10;
const MAX_TAG_CHAR_COUNT = 20;
const ONEMB = 1024 * 1024;
const ONEkB = 1024;

const fileUploadUrl = `${
  BASE_URL[Microservices.FILES_SERVICE]
}/files/uploadToS3`;

const FirstStep: FunctionComponent<StepProps> = ({
  stepIndicator,
  content,
  setContent,
  categories,
  categoriesIds,
}) => {
  const handleChange = (value: string[]) => {
    if (value.length <= MAX_TAGS) {
      setContent({ ...content, tags: [...value] });
    }
  };
  const tags: string[] = content?.tags ? [...content?.tags] : [];
  return (
    <React.Fragment>
      <Card className={styles.card}>
        {stepIndicator}
        <div className={styles.title}>Información básica</div>
        <div className={styles.subTitle}>Ingresa la información del curso</div>
      </Card>
      <Card className={styles.card}>
        <Form.Item
          name="title"
          label="Título"
          initialValue={content?.title || ""}
          rules={[
            { type: "string", required: true, message: "Requerido" },
            { type: "string", min: 3, message: MIN_3_CHAR },
            {
              type: "string",
              max: 200,
              message: "No puede tener más de 200 caracteres",
            },
          ]}
        >
          <Input
            onChange={(event) => {
              setContent({ ...content, title: event.target.value });
            }}
          />
        </Form.Item>
        <Form.Item
          label="Descripción"
          name="description"
          initialValue={content?.description || ""}
          rules={[
            { type: "string", required: true, message: "Requerido" },
            { type: "string", min: 3, message: MIN_3_CHAR },
            {
              type: "string",
              max: 2000,
              message: "No puede tener más de 2000 caracteres",
            },
          ]}
        >
          <Input.TextArea
            onChange={(event) => {
              setContent({ ...content, description: event.target.value });
            }}
          />
        </Form.Item>
        <CategoryField
          markRequired
          label="Categoría"
          object={content}
          setObject={setContent}
          categories={categories}
          categoriesIds={categoriesIds}
        />
        <Form.Item
          label="Tags"
          name="tags"
          initialValue={content?.tags || []}
          rules={[
            { required: true, message: "Requerido" },
            {
              validator: (rule, value: any) => {
                if (value) {
                  const excedsMaxCharCount = value.find(
                    (element: string) => element.length > MAX_TAG_CHAR_COUNT
                  );
                  return new Promise((resolve, reject) => {
                    if (value.length > MAX_TAGS) {
                      value.pop();
                      reject(`Máximo ${MAX_TAGS} tags`);
                      return;
                    } else if (excedsMaxCharCount) {
                      value.pop();
                      reject(`Máximo ${MAX_TAG_CHAR_COUNT} caracteres por tag`);
                      return;
                    }
                    resolve();
                  });
                }
              },
            },
          ]}
        >
          <Select
            mode="tags"
            size={"default" as SizeType}
            placeholder="Please select"
            defaultValue={[...tags]}
            onChange={handleChange}
            style={{ width: "100%" }}
            maxTagCount={MAX_TAGS}
            maxTagTextLength={20}
            tokenSeparators={[" ", ","]}
          ></Select>
        </Form.Item>
        <CompanyField
          newObject={content}
          value={content?.companyId}
          setNewObject={setContent}
        />
      </Card>
    </React.Fragment>
  );
};

function image_upload_handler(
  blobInfo: any,
  success: any,
  failure: any,
  progress: any
) {
  let xhr: any;
  let formData;

  xhr = new XMLHttpRequest();
  xhr.withCredentials = false;
  xhr.open("POST", fileUploadUrl);
  xhr.setRequestHeader(
    "Authorization",
    cognitoUser?.getSignInUserSession()?.getAccessToken().getJwtToken() || ""
  );

  xhr.upload.onprogress = function (e: any) {
    progress((e.loaded / e.total) * 100);
  };

  xhr.onload = function () {
    let json: any;

    if (xhr.status === 403) {
      failure("HTTP Error: " + xhr.status, { remove: true });
      return;
    }

    if (xhr.status < 200 || xhr.status >= 300) {
      failure("HTTP Error: " + xhr.status);
      return;
    }

    json = JSON.parse(xhr.responseText);

    if (!json || typeof json.url != "string") {
      failure("Invalid JSON: " + xhr.responseText);
      return;
    }

    success(json.url);
  };

  xhr.onerror = function () {
    failure(
      "Image upload failed due to a XHR Transport error. Code: " + xhr.status
    );
  };

  formData = new FormData();
  formData.append("file", blobInfo.blob(), blobInfo.filename());

  xhr.send(formData);
}

const SecondStep: FunctionComponent<StepProps> = ({
  stepIndicator,
  content,
  setContent,
}) => {
  const [editorContent, setEditorContent] = useState<string>(
    content?.content || ""
  );
  useEffect(() => {
    setEditorContent(content?.content || "");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleEditorChange = (text: string) => {
    setContent({ ...content, content: text });
  };
  return (
    <React.Fragment>
      <Card className={styles.card}>
        {stepIndicator}
        <div className={styles.title}>Contenido</div>
        <div className={styles.suTitle}>Añade contenido</div>
      </Card>
      <Card className={styles.card}>
        <Editor
          initialValue={editorContent || ""}
          apiKey="xjpsp9wqaquoi2bnz4agx7czznxao66z4ear4fwe6yidfboi"
          init={{
            height: 500,
            menubar: false,
            image_advtab: true,
            images_upload_handler: image_upload_handler,
            language: "es",
            language_url: "/langs/es.js",
            plugins: [
              "advlist autolink lists link image charmap print preview anchor",
              "searchreplace visualblocks code fullscreen",
              "insertdatetime media table paste code help wordcount",
            ],
            toolbar: `undo redo | link image | formatselect | bold italic |
					alignleft aligncenter alignright alignjustify |
					bullist numlist outdent indent | removeformat`,
          }}
          onEditorChange={handleEditorChange}
        />
      </Card>
    </React.Fragment>
  );
};

const ThirdStep: FunctionComponent<StepProps> = ({
  stepIndicator,
  content,
  setContent,
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const pdfs = [...(content?.pdfs || [])];
  const handleChange = (info: any) => {
    const { status } = info.file;
    if (status === "uploading") {
      setLoading(true);
      return;
    }
    if (status === "done") {
      setLoading(false);
      pdfs.push({
        filename: info.file.name,
        size: info.file.size,
        url: info.file.response.url,
      });
      setContent({ ...content, pdfs });
    } else if (status === "error") {
      setLoading(false);
    }
  };
  const onRemovePdf = (index: number) => {
    const pdfs = [...(content?.pdfs || [])];
    pdfs.splice(index, 1);
    setContent({ ...content, pdfs });
  };
  return (
    <React.Fragment>
      <Card className={styles.card}>
        {stepIndicator}
        <div className={styles.title}>Archivos adjuntos</div>
        <div className={styles.suTitle}>
          Añade archivos adjuntos al contenido
        </div>
      </Card>
      <Card className={styles.card}>
        <UploadFile
          onHandleChange={handleChange}
          loading={loading}
          uploadUrl={fileUploadUrl}
          fileTypesAllowed={["application/pdf"]}
          fileNotAllowedMessage="¡Solo se puede subir archivos pdf!"
          maxMB={5}
        />
        {pdfs.length === 0 && (
          <p className={styles.formDescription}>
            Tamaño máximo permitido 5MB, formato permitido PDF
          </p>
        )}
        {pdfs.map((pdf, index) => {
          const size =
            Math.round(
              (pdf.size > ONEMB ? pdf.size / ONEMB : pdf.size / ONEkB) * 10
            ) / 10;
          let unit = pdf.size > ONEMB ? "MB" : "kB";
          return (
            <div className={styles.pdfFile} key={index}>
              <CogIcon className={styles.attach} icon="Attach" />
              <a target="_black" href={pdf.url} className={styles.name}>
                {pdf.filename}
              </a>
              <span className={styles.size}>
                {size} {unit}
              </span>
              <span
                className={styles.removePdf}
                onClick={() => onRemovePdf(index)}
              >
                <CogIcon className={styles.remove} icon="Cross" />
              </span>
            </div>
          );
        })}
      </Card>
    </React.Fragment>
  );
};

interface ContentFormPros {
  form: FormInstance;
  editing: string | null;
  onDone: Function;
}
export const ContentForm: FunctionComponent<ContentFormPros> = ({
  form,
  editing,
  onDone,
}) => {
  const isFetching = useSelector(selectIsContentFetching);
  const contentToEdit = useSelector(selectContent(editing));
  const [step, setStep] = useState<number>(0);
  const [content, setContent] = useState<IContent>();
  const categoriesIds = useSelector(selectContentCategories);
  const categories = useSelector(selectContentCategoriesDict);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      setBreadcrumbs([
        {
          label: "Contenido",
        },
        {
          label: editing ? "Editando contenido" : "Nuevo contenido",
        },
      ])
    );
    dispatch(fetchContentCategories());
  }, [dispatch, editing]);

  useEffect(() => {
    if (contentToEdit) {
      setContent(contentToEdit);
    }
  }, [contentToEdit]);
  if (isFetching) {
    return <LoadingOverlay spinning={true} />;
  }

  const steps = [FirstStep, SecondStep, ThirdStep];
  const goNext = () => {
    if (step + 1 >= STEPS_COUNT) {
      return;
    }
    form
      .validateFields()
      .then(() => {
        setStep(step + 1);
      })
      .catch((_error) => {});
  };
  const goPrev = () => {
    if (step === 0) {
      return;
    }
    form
      .validateFields()
      .then(() => {
        setStep(step - 1);
      })
      .catch((_error) => {});
  };
  const StepToShow = steps[step];
  const stepIndicator = <StepIndicator step={step} stepsCount={STEPS_COUNT} />;
  const onSave = () => {
    if (!content) {
      return;
    }
    if (!content.content) {
      content.content = "<p></p>";
    }
    if (content?.category === OTHER_CATEGORY_OPTION) {
      content.category = {
        name: content.categoryName || "",
      } as ICategory;
    }
    delete content.categoryName;
    if (content._id) {
      dispatch(updateContent(content));
    } else {
      dispatch(createContent(content));
    }
    onDone();
  };
  const buttonLabel = content?._id ? "Actualizar contenido" : "Crear contenido";
  let backButton;
  if (step === 0) {
    backButton = (
      <Popconfirm
        placement="top"
        title="¿Desea salir del formulario?"
        onConfirm={() => {
          onDone();
        }}
        okText="Si"
        cancelText={`No, voy a seguir ${
          content?._id ? "editando" : "creando"
        } el contenido`}
        cancelButtonProps={{
          type: "default",
        }}
      >
        <Button className={styles.buttonNextPrev} type="default">
          <CogIcon
            color="#4376F9"
            className={styles.icon}
            icon="Arrow-left-big"
          />
        </Button>
      </Popconfirm>
    );
  } else {
    backButton = (
      <Button onClick={goPrev} className={styles.buttonNextPrev} type="default">
        <CogIcon
          color="#4376F9"
          className={styles.icon}
          icon="Arrow-left-big"
        />
      </Button>
    );
  }
  return (
    <div className={styles.container}>
      <div className={styles.titleContainer}>
        <div className={styles.title}>
          {editing ? `Editando ${content?.title}` : "Nuevo contenido"}
        </div>
        <div className={styles.actions}>
          {backButton}
          <Button
            disabled={step === STEPS_COUNT - 1}
            onClick={goNext}
            className={styles.buttonNextPrev}
            type="default"
          >
            <CogIcon
              color="#4376F9"
              className={styles.icon}
              icon="Arrow-right-big"
            />
          </Button>
          <Button
            disabled={step !== STEPS_COUNT - 1}
            onClick={onSave}
            className={styles.button}
            type="primary"
          >
            {buttonLabel}
          </Button>
        </div>
      </div>
      <Form form={form} layout="vertical">
        <StepToShow
          categories={categories}
          categoriesIds={categoriesIds}
          setContent={setContent}
          content={content}
          stepIndicator={stepIndicator}
          validateForm={form.validateFields}
          form={form}
        />
      </Form>
    </div>
  );
};
