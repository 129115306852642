import { Button, Form, Table } from "antd";
import { ColumnsType } from "antd/es/table";
import { FormInstance } from "antd/lib/form";
import moment from "moment";
import { RangeValue } from "rc-picker/es/interface";
import React, { ReactText, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectCompanyId, selectUserRole } from '../../features/auth/authSlice';
import {
  fetchCategories,
  ICategory,
  selectCategories,
  selectCategoriesDict,
} from "../../features/category/categorySlice";
import { selectCompanies } from "../../features/company/companySlice";
import { selectEvaluationsDict } from '../../features/evaluation/evaluationsSlice';
import {
  createLesson,
  disableLessonsStatus,
  enableLessonsStatus,
  fetchLessons,
  hideModal,
  ILesson,
  MediaTypes,
  selectAreLessonsFetching,
  selectDisplayModal,
  selectIsCreatingLesson,
  selectLessons,
  updateLesson,
} from "../../features/lesson/lessonSlice";
import {
  fetchTeachers,
  selectTeachersDict,
} from "../../features/teacher/teacherSlice";
import { UserRole } from '../../features/users/usersSlice';
import { PAGE_SIZE } from "../../utils/constants";
import { OTHER_CATEGORY_OPTION } from "../common/CategoryField";
import { getDateFilterAndSortProps } from "../common/DateFilter";
import { DialogPopUp } from "../common/DialogPopUp";
import { CogDrawer } from "../common/Drawer";
import { MainLayout } from "../common/Layouts/Main";
import { Status, StatusEnum } from "../common/Status";
import tableStyles from "../common/table.module.scss";
import { TableTools } from "../common/TableTools";
import { useDebounce } from "../utils/useDebounce";
import { LessonForm } from "./Form";

const EMPTY_LESSON = {
  title: "",
  category: "",
  description: "",
  tags: [],
  media: [],
};

const INITIAL_VALUES = {
  title: "",
  category: undefined,
  description: "",
  tags: [],
  teacher: undefined,
  picture: "",
  firstName: "",
  middleName: "",
  lastName: "",
  secondLastName: "",
  teacherDescription: "",
};

function renderOptions(
  setNewLesson: React.Dispatch<React.SetStateAction<ILesson>>,
  setIsFormVisible: React.Dispatch<React.SetStateAction<boolean>>,
  form: FormInstance
) {
  return (
    <Button
      onClick={() => {
        form.setFieldsValue(INITIAL_VALUES);
        setNewLesson(EMPTY_LESSON);
        setIsFormVisible(true);
      }}
      type="primary"
    >
      Nueva lección
    </Button>
  );
}

export const Lessons: React.FunctionComponent = () => {
  const [fileType, setFileType] = useState<MediaTypes>(MediaTypes.IMAGE);
  const [dateFilterValues, setDateFilterValues] = useState<
    RangeValue<moment.Moment>
  >();
  const [filterValue, setFilterValue] = useState<ReactText[]>([]);
  const [selectedRowKeys, selectRowKeys] = useState<ReactText[]>([]);
  const [showActivationBtns, setShowActivationBtns] = useState<boolean>(false);
  const [isFormVisible, setIsFormVisible] = useState<boolean>(false);
  const [newLesson, setNewLesson] = useState<ILesson>(EMPTY_LESSON);
  const [step, setStep] = useState(0);
  const [currentAction, setCurrentAction] = useState<string>("");
  const debounceFilterValue = useDebounce(filterValue, 500);
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const categoriesDict = useSelector(selectCategoriesDict);
  const categoriesIds = useSelector(selectCategories);
  const teachersDict = useSelector(selectTeachersDict);
  const areLessonsFetching = useSelector(selectAreLessonsFetching);
  const isCreatingLesson = useSelector(selectIsCreatingLesson);
  const evaluationsDict = useSelector(selectEvaluationsDict);
  const userRole = useSelector(selectUserRole);
  const companyId = useSelector(selectCompanyId);
  const companiesList = useSelector(selectCompanies);
  const list = [...useSelector(selectLessons)].filter(lesson => {
    if(userRole === UserRole.SuperAdmin){
      return true;
    }
    return companyId === lesson.companyId;
  }).map((lesson) => ({
    ...lesson,
    categoryName: categoriesDict[lesson.category as string]?.name,
    companyName: lesson.companyId ? companiesList[lesson.companyId || '']?.name : 'Todas',
    teacherName: `${teachersDict[lesson.teacher as string]?.firstName ??
      ""} ${teachersDict[lesson.teacher as string]?.lastName ?? ""}`,
  }));
  const isModalVisible = useSelector(selectDisplayModal);

  const columns: ColumnsType<ILesson> = [
    {
      title: "Nombre",
      dataIndex: "title",
      sortDirections: ["descend", "ascend"],
      defaultSortOrder: "ascend",
      filteredValue: debounceFilterValue,
      ellipsis: true,
      onFilter: (value, record) => {
        const currentValue = value.toString().toLowerCase();
        const teacherName = record.teacherName ?? "";
        const categoryName = record.categoryName ?? "";
        const id = record._id ?? "";
        return (
          record.title.toLocaleLowerCase().includes(currentValue) ||
          teacherName.toLocaleLowerCase().includes(currentValue) ||
          categoryName.toLocaleLowerCase().includes(currentValue) ||
          id.toLocaleLowerCase().includes(currentValue)
        );
      },
      sorter: (a: ILesson, b: ILesson) => a.title.localeCompare(b.title),
    },
    {
      title: "ID",
      dataIndex: "shortId",
      sortDirections: ["descend", "ascend"],
      defaultSortOrder: "ascend",
      ellipsis: true,
      sorter: (a: ILesson, b: ILesson) =>
        a._id ? a._id.localeCompare(b._id ?? "") : 0,
    },
    {
      title: "Profesor",
      dataIndex: "teacherName",
      sortDirections: ["descend", "ascend"],
      ellipsis: true,
      defaultSortOrder: "ascend",
      sorter: (a: ILesson, b: ILesson) =>
        a.teacherName?.localeCompare(b?.teacherName ?? "") ?? 0,
    },
    {
      title: "Categoría",
      dataIndex: "categoryName",
      sortDirections: ["descend", "ascend"],
      defaultSortOrder: "ascend",
      ellipsis: true,
      sorter: (a: ILesson, b: ILesson) =>
        a.categoryName?.localeCompare(b?.categoryName ?? "") ?? 0,
    },
    {
      title: "Estado",
      dataIndex: "status",
      sortDirections: ["descend", "ascend"],
      render: (status) => <Status status={status} />,
      sorter: (a: ILesson, b: ILesson) =>
        a.status === b.status ? 0 : a.status === StatusEnum.Enable ? 1 : -1,
    },
    {
      title: "Empresa",
      dataIndex: "companyName",
      sortDirections: ["descend", "ascend"],
      ellipsis: true,
      onFilter: (value, record: ILesson) => record.companyId === (value as string),
      sorter: (a: ILesson, b: ILesson) =>
        (a.companyName || "").localeCompare(b.companyName || ""),
    },
    {
      title: "Fecha",
      dataIndex: "createdAt",
      sortDirections: ["descend", "ascend"],
      ...getDateFilterAndSortProps<ILesson>(
        dateFilterValues,
        setDateFilterValues
      ),
      render: (createdAt, lesson) => {
        return (
          <React.Fragment>
            {moment(createdAt).format("DD/MM/YYYY")}
            <div className={tableStyles.actions}>
              <Button
                type="default"
                onClick={() => {
                  if (lesson.media && lesson.media.length > 0) {
                    setFileType(lesson.media[0].type);
                  }
                  setStep(0);
                  if (typeof lesson.teacher === "string") {
                    lesson.teacher = teachersDict[lesson.teacher];
                  }
                  setNewLesson(lesson);
                  const fields = {
                    title: lesson.title,
                    category:
                      typeof lesson.category === "object"
                        ? lesson.category._id
                        : lesson.category,
                    description: lesson.description,
                    tags: lesson.tags,
                    teacher: lesson.teacher?._id,
                    picture: lesson.teacher?.picture ?? "",
                    firstName: lesson.teacher?.firstName ?? "",
                    middleName: lesson.teacher?.middleName ?? "",
                    lastName: lesson.teacher?.lastName ?? "",
                    secondLastName: lesson.teacher?.secondLastName ?? "",
                    teacherDescription: lesson.teacher?.description ?? "",
                    companyId: lesson.companyId,
                  };
                  form.setFieldsValue(fields);
                  setIsFormVisible(true);
                }}
              >
                Editar
              </Button>
            </div>
          </React.Fragment>
        );
      },
    },
  ];

  useEffect(() => {
    dispatch(fetchLessons());
    dispatch(fetchCategories());
    dispatch(fetchTeachers());
  }, [dispatch]);

  useEffect(() => {
    if (selectedRowKeys.length > 0) {
      setShowActivationBtns(true);
    } else {
      setShowActivationBtns(false);
    }
  }, [selectedRowKeys]);

  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedRowKeys: ReactText[]) => {
      selectRowKeys(selectedRowKeys);
    },
  };

  const enableStatus = () => {
    const data = {
      ids: selectedRowKeys.map((key) => key.toString()),
    };
    dispatch(enableLessonsStatus(data));
  };

  const disableStatus = () => {
    const data = {
      ids: selectedRowKeys.map((key) => key.toString()),
    };
    dispatch(disableLessonsStatus(data));
  };

  const handleCancel = () => {
    setIsFormVisible(false);
    form.setFieldsValue(INITIAL_VALUES);
    setStep(0);
  };

  const handleContinue = () => {
    form
      .validateFields()
      .then(() => {
        setStep(step + 1);
      })
      .catch((_error) => {});
  };

  const handleAction = () => {
    form
      .validateFields()
      .then(() => {
        if (!newLesson || !newLesson.category) {
          return;
        }
        if (newLesson.category === OTHER_CATEGORY_OPTION) {
          newLesson.category = {
            name: newLesson.categoryName || "",
          } as ICategory;
        }
        delete newLesson.categoryName;
        if(userRole !== UserRole.SuperAdmin){
          if(newLesson._id && newLesson.companyId !== companyId){ // don't allow editing lesson that don't belong to the company
            return;
          }
          newLesson.companyId = companyId; // force to company in case of creating
        }
        if(!newLesson.companyId){
          newLesson.companyId = undefined;
        }
        if (newLesson.companyId || !newLesson.teacher || newLesson.teacher === "") {
          delete newLesson.teacher;
        }
        delete newLesson.teacherName;
        if (!newLesson._id) {
          setCurrentAction("creada");
          dispatch(
            createLesson({
              ...newLesson,
              media: newLesson.media?.filter((item) => item.type === fileType),
            })
          );
        } else {
          setCurrentAction("actualizada");
          dispatch(
            updateLesson({
              ...newLesson,
              media: newLesson.media?.filter((item) => item.type === fileType),
            })
          );
        }
        setStep(0);
        setIsFormVisible(false);
        form.setFieldsValue(INITIAL_VALUES);
      })
      .catch((_error) => {});
  };
  let saveButtonIsDisable = !newLesson || !newLesson.evaluation ? 
    false :
    (fileType === MediaTypes.VIDEO && newLesson.quizCuePoints?.length !== evaluationsDict[newLesson.evaluation]?.questions.length);
  return (
    <MainLayout
      title="Lecciones"
      rightTopOptions={renderOptions(setNewLesson, setIsFormVisible, form)}
    >
      <DialogPopUp
        name="Lección"
        action={currentAction}
        visible={isModalVisible}
        onCancel={() => {
          dispatch(hideModal());
        }}
      />
      <TableTools
        placeholder="Buscar en Teurona"
        onSearch={(value) => setFilterValue([value])}
        onChange={(event) => setFilterValue([event.target.value])}
        onEnable={() => enableStatus()}
        onDisable={() => disableStatus()}
        activationBtnsVisible={showActivationBtns}
      />
      <Table<ILesson>
        loading={areLessonsFetching || isCreatingLesson}
        className={tableStyles.table}
        rowClassName={() => tableStyles.row}
        rowKey="_id"
        rowSelection={rowSelection}
        columns={columns}
        dataSource={list}
        pagination={{ position: ["bottomRight"], pageSize: PAGE_SIZE }}
      />
      {isFormVisible && (
        <CogDrawer
          visible={true}
          toggleVisible={handleCancel}
          title={newLesson?._id ? "Editar lección" : "Nueva lección"}
          footer={
            <div>
              <Button onClick={handleCancel} type="default">
                Cancelar
              </Button>
              &nbsp;&nbsp;
              {step === 0 && (
                <Button type="primary" onClick={handleContinue}>
                  Continuar
                </Button>
              )}
              {step === 1 && (
                <Button disabled={saveButtonIsDisable} type="primary" onClick={handleAction}>
                  {newLesson?._id ? "Editar lección" : "Crear lección"}
                </Button>
              )}
            </div>
          }
        >
          <LessonForm
            form={form}
            fileType={fileType}
            newLesson={newLesson}
            step={step}
            categories={categoriesDict}
            categoriesIds={categoriesIds}
            setFileType={setFileType}
            setNewLesson={setNewLesson}
            onBack={() => setStep(0)}
          />
        </CogDrawer>
      )}
    </MainLayout>
  );
};
