import { Form } from "antd";
import { FormInstance } from "antd/lib/form";
import React from "react";
import { ICategoriesDict } from "../../features/category/categorySlice";
import { ITopic } from "../../features/topic/topicSlice";
import formStyles from "../common/form.module.scss";
import { FirstStep } from "./FirstStep";
import { SecondStep } from "./SecondStep";

interface TopicFormProps {
  categories: ICategoriesDict;
  categoriesIds: string[];
  form: FormInstance;
  newTopic?: ITopic;
  setNewTopic: Function;
  step: number;
  onBack: Function;
}

const Steps = [FirstStep, SecondStep];
export const TopicForm: React.FunctionComponent<TopicFormProps> = ({
  categories,
  categoriesIds,
  form,
  newTopic,
  setNewTopic,
  step,
  onBack,
}) => {
  const validateForm = form.validateFields;
  const Step = Steps[step];
  return (
    <Form
      form={form}
      className={formStyles.form}
      layout="vertical"
      name="control-hooks"
    >
      <Step
        newTopic={newTopic}
        setNewTopic={setNewTopic}
        categories={categories}
        categoriesIds={categoriesIds}
        onBack={onBack}
        validateForm={validateForm}
      />
    </Form>
  );
};
