import cls from "classnames";
import jsPDF from "jspdf";
import moment from "moment";
import React from "react";
import logo from "../../../assets/logo-teurona.svg";
import {
  FONT_INTER_BOLD,
  FONT_INTER_NORMAL,
  FONT_INTER_REGULAR,
} from "../fontFiles";
import {
  centerText,
  drawImage,
  RATIO_FACTOR,
  splitText,
  TemplateProps,
} from "../Template1";
import styles from "./index.module.scss";
const QRCode = require("qrcode.react");

export const createPDF = (name: string, props: TemplateProps) => {
  const doc = new jsPDF({
    orientation: "l",
    unit: "px",
    format: "a4",
  });
  let canvas: HTMLCanvasElement | undefined = props.showQrCode
    ? (document.getElementById("certicate-qr") as HTMLCanvasElement)
    : undefined;

  doc.addFileToVFS("Inter.ttf", FONT_INTER_NORMAL);
  doc.addFileToVFS("InterRegular.ttf", FONT_INTER_REGULAR);
  doc.addFileToVFS("InterBold.ttf", FONT_INTER_BOLD);

  // added fonts
  doc.addFont("Inter.ttf", "Inter", "normal");
  doc.addFont("InterRegular.ttf", "Inter", "regular");
  doc.addFont("InterBold.ttf", "Inter", "bold");

  // draw header
  doc.setFont("Inter", "normal");
  doc.setTextColor("#81858B");
  if (props.showNameAndLogo) {
    drawImage("businessLogo", doc, 0, 36 * RATIO_FACTOR, true);
  }

  // draw content
  if (props.showName) {
    doc.setFontSize(14);
    centerText(doc, "Certificado del curso", 119 * RATIO_FACTOR);
    doc.setFont("Inter", "bold");
    doc.setFontSize(28);
    doc.setTextColor("#4376F9");
    splitText(
      doc,
      props.courseName,
      722 * RATIO_FACTOR,
      26,
      0,
      156 * RATIO_FACTOR,
      true
    );
  }
  if (props.showTotalHours) {
    doc.setFont("Inter", "regular");
    doc.setFontSize(16);
    doc.setTextColor("#686F84");
    centerText(doc, `${props.totalHours} horas de estudio`, 217 * RATIO_FACTOR);
  }
  doc.setFont("Inter", "normal");
  doc.setFontSize(14);
  doc.setTextColor("#81858B");
  if (props.showDescription) {
    splitText(
      doc,
      props.description,
      752 * RATIO_FACTOR,
      16,
      0,
      242 * RATIO_FACTOR,
      true
    );
  }
  if (props.showTraineeFullName || props.showTraineeNationalId) {
    centerText(doc, "Otorgado a ", 340 * RATIO_FACTOR);
  }
  doc.setTextColor("#686F84");
  if (props.showTraineeFullName) {
    doc.setFont("Inter", "bold");
    doc.setFontSize(22);
    centerText(doc, props.name, 375 * RATIO_FACTOR);
  }
  if (props.showTraineeNationalId) {
    doc.setFont("Inter", "regular");
    doc.setFontSize(16);
    centerText(doc, "ID. " + props.traineeNationalId, 405 * RATIO_FACTOR);
  }
  doc.setFont("Inter", "normal");
  if (props.showQrCode && canvas) {
    doc.setFontSize(20);
    doc.addImage(
      canvas,
      "PNG",
      390 * RATIO_FACTOR,
      457 * RATIO_FACTOR,
      62 * RATIO_FACTOR,
      62 * RATIO_FACTOR
    );
  }
  if (props.showUniqueCode) {
    doc.setFontSize(12);
    doc.setTextColor("#81858B");
    centerText(doc, props.uniqueCode, 544 * RATIO_FACTOR);
  }

  // draw footer
  doc.setFontSize(12);
  doc.setTextColor("#81858B");
  if (props.showCompanyName) {
    doc.text(props.companyName, 40 * RATIO_FACTOR, 547 * RATIO_FACTOR);
  }
  doc.text(
    moment(props.createdAt).format("DD/MM/YYYY"),
    716 * RATIO_FACTOR,
    547 * RATIO_FACTOR
  );

  // Save document
  doc.save(`${name}.pdf`);
};

const Template2: React.FunctionComponent<TemplateProps> = ({
  showName,
  showDescription,
  showTotalHours,
  showTraineeFullName,
  showTraineeNationalId,
  showCompanyName,
  showNameAndLogo,
  showUniqueCode,
  showQrCode,
  name,
  courseName,
  description,
  totalHours,
  traineeNationalId,
  companyName,
  uniqueCode,
  createdAt,
  baseFontSizePx,
}) => (
  <article
    className={cls(styles.mainWrapper)}
    style={{ fontSize: `${baseFontSizePx}px` }}
  >
    <div className={styles.wrapper}>
      <section className={styles.container}>
        <header>
          <div className={styles.logo}>
            {showNameAndLogo && (
              <img
                src={logo}
                alt="businessLogo"
                id="businessLogo"
                crossOrigin="anonymous"
              />
            )}
          </div>
        </header>
        <div className={styles.content}>
          <div className={styles.courseTitle}>
            {showName && <h5>Certificado del curso</h5>}
          </div>
          <div className={styles.courseName}>
            {showName && <h1>{courseName}</h1>}
          </div>
          <div className={styles.courseHours}>
            {showTotalHours && <h4>{`${totalHours} horas de estudio`}</h4>}
          </div>
          <div className={styles.description}>
            {showDescription && <p>{description}</p>}
          </div>
          <div className={styles.traineeTitle}>
            {(showTraineeFullName || showTraineeNationalId) && (
              <span>Otorgado a</span>
            )}
          </div>
          <div className={styles.traineeName}>
            {showTraineeFullName && <h2>{name}</h2>}
          </div>
          <div className={styles.traineeId}>
            {showTraineeNationalId && <h3>ID. {traineeNationalId}</h3>}
          </div>
          <div className={styles.qrWrapper}>
            {showQrCode && (
              <QRCode
                value={window.location.href}
                id="certicate-qr"
                renderAs="canvas"
              />
            )}
          </div>
          {showUniqueCode && <p>{uniqueCode}</p>}
        </div>
        <footer>
          <div className={styles.companyName}>
            {showCompanyName && <span>{companyName}</span>}
          </div>
          <span>{moment(createdAt).format("DD/MM/YYYY")}</span>
        </footer>
      </section>
    </div>
  </article>
);

export default Template2;
