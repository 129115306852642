import { Button, Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import moment from "moment";
import { RangeValue } from "rc-picker/es/interface";
import React, {
  FunctionComponent,
  ReactText,
  useEffect,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectCompanyId, selectUserRole } from '../../features/auth/authSlice';
import { ICategoriesDict } from "../../features/category/categorySlice";
import { selectCompanies } from "../../features/company/companySlice";
import {
  disableCoursesStatus,
  enableCoursesStatus,
  fetchCourses,
  ICourse,
  selectAreCoursesFetching,
  selectCourses,
  selectCoursesDict,
} from "../../features/courses/coursesSlice";
import { UserRole } from '../../features/users/usersSlice';
import { PAGE_SIZE } from "../../utils/constants";
import { getDateFilterAndSortProps } from "../common/DateFilter";
import { Status } from "../common/Status";
import tableStyles from "../common/table.module.scss";
import { TableTools } from "../common/TableTools";
import { useDebounce } from "../utils/useDebounce";

interface CoursesListProps {
  editCourse: Function;
  categories: ICategoriesDict;
}

export const CoursesList: FunctionComponent<CoursesListProps> = ({
  editCourse,
  categories,
}) => {
  const [selectedRowKeys, selectRowKeys] = useState<ReactText[]>([]);
  const [dateFilterValues, setDateFilterValues] = useState<
    RangeValue<moment.Moment>
  >();
  const [filterValue, setFilterValue] = useState<ReactText[]>([]);
  const [showActivationBtns, setShowActivationBtns] = useState(false);
  const areCoursesFetching = useSelector(selectAreCoursesFetching);
  const coursesIdsList = useSelector(selectCourses);
  const coursesDict = useSelector(selectCoursesDict);
  const userRole = useSelector(selectUserRole);
  const companyId = useSelector(selectCompanyId);
  const companiesList = useSelector(selectCompanies);
  const coursesList = coursesIdsList.filter(courseId => {
    if(userRole === UserRole.SuperAdmin){
      return true;
    }
    return companyId === coursesDict[courseId].companyId;
  }).map((courseId) => ({
    ...coursesDict[courseId],
    categoryName: categories[coursesDict[courseId].category as string]?.name,
    companyName: coursesDict[courseId].companyId ? companiesList[coursesDict[courseId].companyId || '']?.name : 'Todas',
  }));
  const dispatch = useDispatch();
  const debounceFilterValue = useDebounce(filterValue, 500);
  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedRowKeys: ReactText[]) => {
      selectRowKeys(selectedRowKeys);
    },
  };
  useEffect(() => {
    dispatch(fetchCourses());
  }, [dispatch]);

  useEffect(() => {
    if (selectedRowKeys.length > 0) {
      setShowActivationBtns(true);
    } else {
      setShowActivationBtns(false);
    }
  }, [selectedRowKeys]);

  const enableStatus = () => {
    const data = {
      ids: selectedRowKeys.map((key) => key.toString()),
    };
    dispatch(enableCoursesStatus(data));
  };

  const disableStatus = () => {
    const data = {
      ids: selectedRowKeys.map((key) => key.toString()),
    };
    dispatch(disableCoursesStatus(data));
  };

  const columns: ColumnsType<ICourse> = [
    {
      title: "Nombre",
      dataIndex: "title",
      sortDirections: ["descend", "ascend"],
      defaultSortOrder: "ascend",
      ellipsis: true,
      sorter: (a: ICourse, b: ICourse) => a.title?.localeCompare(b.title),
      filteredValue: debounceFilterValue,
      onFilter: (value, record) => {
        return (
          record.title.toLowerCase().includes(value.toString().toLowerCase()) ||
          record.categoryName
            ?.toLowerCase()
            .includes(value.toString().toLowerCase()) ||
          record._id?.toLowerCase().includes(value.toString().toLowerCase())
        );
      },
    },
    {
      title: "ID",
      dataIndex: "shortId",
      sortDirections: ["descend", "ascend"],
      ellipsis: true,
      sorter: (a: ICourse, b: ICourse) => a._id.localeCompare(b._id),
    },
    {
      title: "Categoria",
      dataIndex: "categoryName",
      sortDirections: ["descend", "ascend"],
      ellipsis: true,
      sorter: (a: ICourse, b: ICourse) =>
        (a.categoryName || "").localeCompare(b.categoryName || ""),
    },
    {
      title: "Estado",
      dataIndex: "status",
      render: (status) => {
        return <Status status={status} />;
      },
      sortDirections: ["descend", "ascend"],
      sorter: (a: ICourse, b: ICourse) =>
        a.status === b.status ? 0 : a.status > b.status ? 1 : -1,
    },
    {
      title: "Empresa",
      dataIndex: "companyName",
      sortDirections: ["descend", "ascend"],
      ellipsis: true,
      onFilter: (value, record: ICourse) => record.companyId === (value as string),
      sorter: (a: ICourse, b: ICourse) =>
        (a.companyName || "").localeCompare(b.companyName || ""),
    },
    {
      title: "Fecha",
      dataIndex: "createdAt",
      ...getDateFilterAndSortProps<ICourse>(
        dateFilterValues,
        setDateFilterValues
      ),
      render: (createdAd, course) => {
        return (
          <React.Fragment>
            {moment(createdAd).format("DD-MM-YYYY")}
            <div className={tableStyles.actions}>
              <Button
                type="default"
                onClick={() => {
                  editCourse(course._id);
                }}
              >
                Editar
              </Button>
            </div>
          </React.Fragment>
        );
      },
      sortDirections: ["descend", "ascend"],
    },
  ];
  return (
    <React.Fragment>
      <TableTools
        placeholder="Buscar en Teurona"
        onSearch={(value) => setFilterValue([value])}
        onChange={(event) => setFilterValue([event.target.value])}
        onEnable={() => enableStatus()}
        onDisable={() => disableStatus()}
        activationBtnsVisible={showActivationBtns}
      />
      <Table<ICourse>
        loading={areCoursesFetching}
        className={tableStyles.table}
        rowClassName={() => tableStyles.row}
        rowKey="_id"
        rowSelection={rowSelection}
        columns={columns}
        dataSource={coursesList}
        pagination={{ position: ["bottomRight"], pageSize: PAGE_SIZE }}
      />
    </React.Fragment>
  );
};
