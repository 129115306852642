import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Dispatch } from "react";
import { Microservices } from "../../app/AllowedMicroservices";
import { RootState } from "../../app/store";
import apiActionCreator, { HttpMethods } from "../../services/apiActionCreator";
import { FetchStatus } from "../common/enums";
import { ParentTypeEnum } from "../evaluation/common/enums";
import { IAnswer, IHistory } from "../evaluation/common/interfaces";

interface IEvaluationResult {
  userId: string;
  evaluationId: string;
  updatedAt: Date;
  result: number;
  total: number;
  parentType: ParentTypeEnum;
  history: IHistory[];
  answers: IAnswer[];
  correctAnswersToPass?: number;
  passed?: boolean;
}

interface evaluationResultsDict {
  [_id: string]: IEvaluationResult | null | undefined;
}

interface statusDict {
  [_id: string]: FetchStatus;
}

interface evaluationsResultsState {
  result: evaluationResultsDict;
  status: statusDict;
}
interface fetchedPayload {
  results: IEvaluationResult[];
  evaluationId: string;
}

const initialState: evaluationsResultsState = {
  result: {},
  status: {},
};

export const evaluationsResultsSlice = createSlice({
  name: "evaluationsResults",
  initialState,
  reducers: {
    fetchingOne: (state, { payload }: PayloadAction<string>) => {
      state.status[payload] = FetchStatus.Fetching;
    },
    fetching: () => {},
    fetchedCourse: (
      state,
      { payload: { results = [], evaluationId } }: PayloadAction<fetchedPayload>
    ) => {
      if (results.length) {
        state.result[evaluationId] = results[0];
      }
      state.status[evaluationId] = FetchStatus.Fetched;
    },
    error: (state, { payload }: PayloadAction<string>) => {
      state.status[payload] = FetchStatus.Error;
    },
  },
});

export const {
  fetching,
  fetchingOne,
  fetchedCourse: fetchedCourses,
  fetchedCourse,
  error,
} = evaluationsResultsSlice.actions;
export const fetchingOneEvaluationResult = (evaluationId: string) => (
  dispatch: Dispatch<object>
) => {
  dispatch(fetchingOne(evaluationId));
};
export const fetchEvaluationResults = (
  evaluationId: string,
  parentId: string,
  courseId: string,
  parentType: ParentTypeEnum
) => (dispatch: Dispatch<object>) => {
  return dispatch(
    apiActionCreator({
      endpoint: `/evaluation/results`,
      types: {
        requestType: fetching,
        successTypes: [
          {
            actionOrCreator: fetchedCourse,
            selector: (response) => {
              return {
                results: response,
                evaluationId,
              };
            },
          },
        ],
        errorTypes: [
          {
            actionOrCreator: error,
            selector: (response) => {
              return {
                results: response,
                evaluationId,
              };
            },
          },
        ],
      },
      method: HttpMethods.POST,
      microservice: Microservices.EVALUATION_SERVICE,
      authenticated: true,
      data: {
        evaluationId,
        parentId,
        courseId,
        parentType,
      },
    })
  );
};

export const selectStudentEvaluationResultIsFetching = (
  evaluationId: string
) => (state: RootState) =>
  (state.evaluationsResults.status[evaluationId] ?? FetchStatus.Fetching) ===
  FetchStatus.Fetching;
export const selectStudentEvaluationResult = (evaluationId: string) => (
  state: RootState
) => state.evaluationsResults.result[evaluationId];

export default evaluationsResultsSlice.reducer;
