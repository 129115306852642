import { StatusEnum } from "../../../../common/Status";
import { InsAndOuts } from "../../../../../features/reports/company/insAndOutsCommon";

interface DatesBounds {
  startDate: moment.Moment;
  endDate: moment.Moment;
}

export const getDaysByRange = ({ startDate, endDate }: DatesBounds) => {
  const type = "days";
  const diff = endDate.diff(startDate, type);
  const range = [];
  const offset = 1;
  for (let i = offset; i < diff + offset; i++) {
    range.push(startDate.clone().add(i, "days"));
  }
  return range;
};

interface CountsData {
  enabled: number,
  disabled: number,
  dayLabel: string,
  dateLabel: string,
}

export interface CountsPerDay {
  [YYYYMMDD: string]: CountsData,
}

export const generateCountsPerDateStructure = (dates: moment.Moment[]): CountsPerDay => {
  return dates.reduce((countsPerDay, currentDateItem) => {
    countsPerDay[currentDateItem.format('YYYY-MM-DD').toString()] = {
      enabled: 0,
      disabled: 0,
      dayLabel: currentDateItem.format('D').toString(),
      dateLabel: currentDateItem.locale("es").format('D [de] MMM [de] YYYY'),
    }
    return countsPerDay;
  }, {} as CountsPerDay);
}

interface StatusCounts {
  dates: moment.Moment[],
  insAndOutsPerCompany: InsAndOuts,
}

export const getEnableDisableCountsPerDay = ({
  dates,
  insAndOutsPerCompany,
}: StatusCounts) => {
  const countsPerDate = generateCountsPerDateStructure(dates);
  for (const companyId in insAndOutsPerCompany) {
    const { byDate } = insAndOutsPerCompany[companyId];
    for (const yyyymmddDate in byDate) {
      const data = byDate[yyyymmddDate];
      if (countsPerDate[yyyymmddDate]) {
        if (data.status === StatusEnum.Enable) {
          countsPerDate[yyyymmddDate].enabled += 1;
        } else if (data.status === StatusEnum.Disable) {
          countsPerDate[yyyymmddDate].disabled += 1;
        }
      }
    }
  }
  return countsPerDate;
};
