import React, { FunctionComponent } from "react";
import { SimpleStat, IconNames, DisplayDirection } from "./SimpleStat";
import { DoughnutSimple, DoughnutSimpleType } from './Doughnut';
import { PIECHART_COLORS } from "../../../features/reports/common";

import styles from "./statsRow.module.scss";

export interface StatItemType {
  iconName: IconNames,
  mainText: string,
  tooltipText?: string,
  description: string,
  direction?: DisplayDirection,
  iconColor?: string,
}

interface StatRowItemProps {
  statsData: (StatItemType | DoughnutSimpleType)[],
}

export const StatsRow: FunctionComponent<StatRowItemProps> = ({
  statsData = [],
}) => {
  return (
    <div className={styles.mainWrapper}>
      {statsData.map((stats, index) => {
        let content = null;
        if (stats.hasOwnProperty('percentValue')) {
          const doughnutData = stats as DoughnutSimpleType;
          content = (
            <DoughnutSimple
              canvasHeight={doughnutData.canvasHeight}
              canvasWidth={doughnutData.canvasWidth}
              type={doughnutData.type}
              percentValue={doughnutData.percentValue}
              description={doughnutData.description}
              data={doughnutData.data}
            />
          );
        } else if (stats.hasOwnProperty('mainText')) {
          const simpleStatData = stats as StatItemType;
          content = (
            <SimpleStat
              iconColor={simpleStatData.iconColor}
              mainText={simpleStatData.mainText}
              icon={simpleStatData.iconName}
              description={simpleStatData.description}
              tooltipText={simpleStatData.tooltipText}
              direction={simpleStatData.direction}
            />
          );
        }

        return <React.Fragment key={index}>
          {content}
          {(index < statsData.length - 1) ? (
            <div className={styles.divider} key={index}>
              <hr className={styles.hrVertical} />
            </div>
          ) : null}
        </React.Fragment>
      })}
    </div >
  );
};
export type StatData = Array<StatItemType | DoughnutSimpleType>;

export const StatsRowExample: FunctionComponent = () => {
  const statsData: StatData = [
    {
      iconName: IconNames.CasesActive,
      mainText: '1200',
      description: 'Lorem ipsum',
    },
    {
      iconName: IconNames.Frequency,
      mainText: '12h',
      description: 'Lorem ipsum second',
      tooltipText: 'Tooltip text',
    },
    {
      iconName: IconNames.RegisteredResources,
      mainText: '400',
      description: 'This is a random text',
      direction: DisplayDirection.row
    },
    {
      percentValue: 12,
      description: <div>Here goes the description</div>,
      canvasHeight: 100,
      type: '2',
      data: {
        labels: [
          'Red',
          'Blue',
          'Yellow'
        ],
        datasets: [{
          label: 'My First Dataset',
          data: [300, 50, 100],
          backgroundColor: [
            PIECHART_COLORS[0],
            PIECHART_COLORS[1],
            PIECHART_COLORS[2]
          ],
          hoverOffset: 4
        }],
      },
    }
  ];
  return <StatsRow statsData={statsData} />
}
