import React, { FunctionComponent, forwardRef, useImperativeHandle, useRef } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import ctx from "classnames";

import {
  selectStudentCourse,
  selectStudentCourseTopic,
  selectStudentCourseTopicProgress,
  selectStudentCourseLastViewdItemByTopicId,
  TOPIC_TEST
} from "../../../features/students/courseSlice";

import styles from "./courseTopic.module.scss";
import { Card, Button, Progress } from "antd";
import { selectCategory } from "../../../features/category/categorySlice";
import { CategoryChip } from "../common/Chip";
import { SIZES } from "../../common/screen";
import { useDeviceSize } from "../../utils/useDeviceSize";

// analytics
import { selectPrograms, selectProgramsDict } from "../../../features/program/programSlice";
import { analytics } from "../../../services/analytics";
import {
  getCognitoUserData,
} from "../../../features/auth/authSlice";
import { selectUser } from '../../../features/users/usersSlice';
import { selectCompany } from '../../../features/company/companySlice';
import { selectGroup } from "../../../features/groups/groupSlice";

interface StudentCourseTopicsProps {
  id: string;
  lastTopicId?: string;
  isFirst: boolean;
}

export const StudentCourseTopic: FunctionComponent<StudentCourseTopicsProps> = ({
  id,
  isFirst,
  lastTopicId
}) => {
  const history = useHistory();
  const course = useSelector(selectStudentCourse);
  const topic = useSelector(selectStudentCourseTopic(id));
  const progress = useSelector(selectStudentCourseTopicProgress(id));
  const lastProgress = useSelector(
    selectStudentCourseTopicProgress(lastTopicId || "")
  );
  const category = useSelector(selectCategory(topic?.category as string));
  const toGo = useSelector(selectStudentCourseLastViewdItemByTopicId(id));
  const size = useDeviceSize();
  const analyticsRef = useRef<AnalyticsSelectTopicRef>(null);

  if (!topic) {
    return null;
  }
  const viewTopicHandler = () => {
    let redirectPath = `/course/${course?._id}/${toGo.id}`;
    if (toGo.where === TOPIC_TEST) {
      redirectPath = `/course/${course?._id}/test/${toGo.id}`;
    }
    if (progress === 1) {
      redirectPath = `/course/${course?._id}/${topic.lessons[0]}`;
    }
    // TODO: what happens if a topic will jump to a test
    analyticsRef?.current?.trigger();
    history.push(redirectPath);
  };

  const isViewTopicCTAdisabled = () => {
    return !isFirst && (lastProgress ?? 0) < 1;
  }

  return (
    <Card
      className={ctx(styles.container, {
        [styles.clickable]: size !== SIZES.DESKTOP
      })}
      onClick={() => {
        if (size !== SIZES.DESKTOP && !isViewTopicCTAdisabled()) {
          viewTopicHandler();
        }
      }}
    >
      <div className={styles.titleButtonContainer}>
        <div className={styles.title}>{topic.title}</div>
        {size === SIZES.DESKTOP ? (
          <div className={styles.gotToTopicContainer}>
            <Button
              disabled={isViewTopicCTAdisabled()}
              onClick={() => viewTopicHandler()}
              className={ctx("studentButton", styles.button)}
            >
              Ver tema
            </Button>
          </div>
        ) : null}
      </div>
      <div className={styles.categoryContainer}>
        <CategoryChip categoryName={category?.name} />
      </div>
      <div className={styles.description}>{topic.description}</div>
      <div className={styles.divider} />
      <div className={styles.progress}>
        <div className={styles.percentage}>{(progress * 100).toFixed(0)}%</div>
        <Progress percent={progress * 100} showInfo={false} />
      </div>
      <AnalyticsSelectTopic ref={analyticsRef} topicId={topic._id} />
    </Card>
  );
};

type AnalyticsSelectTopicRef = {
  trigger: () => void,
}

interface AnalyticsSelectTopicProps {
  topicId: string,
}

const AnalyticsSelectTopic =
  forwardRef<AnalyticsSelectTopicRef, AnalyticsSelectTopicProps>(({ topicId }, ref) => {
    useImperativeHandle(ref, () => ({
      trigger: () => {
        analytics.selectTopic({
          topic: topic,
          course: course,
          program: courseProgram,
          company: company,
          group: group,
        });
      }
    }));
    const course = useSelector(selectStudentCourse);
    const allProgramsIds = useSelector(selectPrograms);
    const programsDict = useSelector(selectProgramsDict);
    const programId = allProgramsIds.filter((programId) => {
      const program = programsDict[programId] || {};
      const { courses: programCourses =  [] } = program;
      const foundCourse = programCourses.find(
        (courseId) => courseId === course?._id
      );
      if (foundCourse !== undefined) {
        return true;
      }
      return false;
    });
    const courseProgram = programsDict[programId[0]] || null;
    const topic = useSelector(selectStudentCourseTopic(topicId));
    const cognitoUserData = useSelector(getCognitoUserData);
    const cognitoUsername = cognitoUserData
      ? cognitoUserData["cognito:username"]
      : null;
    const userFromStore = useSelector(selectUser(cognitoUsername));
    const companyId = userFromStore?.company ?? "";
    const company = useSelector(selectCompany(companyId));
    const group = useSelector(selectGroup(userFromStore?.studentGroupId || '')) || null;

    return null;
  });
