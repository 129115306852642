import React from "react";
import { CogIcon } from "../../common/CogIcon";
import styles from "./emptyTable.module.scss";

export const EmptyTable: React.FunctionComponent = () => (
  <div className={styles.emptyContainer}>
    <CogIcon size="5.5em" color="#81858b" icon={"filter-empty"} />
    <h2>Filtra una empresa para ver su información</h2>
    <p>
      Aplica un filtro para mostrar la información de la empresa correspondiente
    </p>
  </div>
);
