import React, { FunctionComponent, useEffect, forwardRef, useImperativeHandle, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import ctx from 'classnames';
import moment from 'moment';

import {
    selectStudentCourse,
    selectStudentCourseRules,
    selectStudentCourseAdvancePercentage,
    selectStudentCourseLastLessonLessonId,
    selectStudentCourseProgress,
    COURSE_EXAM,
    COURSE_SURVEY,
    TOPIC_TEST,
    selectStudentCourseLesson,
    selectStudentCourseTopicLesson,
} from '../../../features/students/courseSlice';

import styles from './lessonInfo.module.scss';
import {
    selectCategory,
    fetchCategories
} from '../../../features/category/categorySlice';
import { Button, Card, Progress } from 'antd';
import { CogIcon } from '../../common/CogIcon';
import {
    COURSES_PRIORITY_COLORS,
    COURSES_PRIORITY_LABELS,
    COURSES_PRIORITY_ICON
} from '../../../utils/constants';
import { useHistory } from 'react-router-dom';
import { CategoryChip } from '../common/Chip';
import { goToCertificate } from './Congratulations';
import { fetchHashByTemplateId, selectCertificateHash } from '../../../features/students/certificateHashSlice';
import {
    getCognitoUserData,
} from "../../../features/auth/authSlice";
import { analytics } from "../../../services/analytics";
import { selectUser } from '../../../features/users/usersSlice';
import { selectCompany } from '../../../features/company/companySlice';
import { IProgram } from "../../../features/program/programSlice";
import { selectGroup } from "../../../features/groups/groupSlice";

interface StudentLessonInfoProps { }

export const StudentLessonInfo: FunctionComponent<StudentLessonInfoProps> = () => {
    const course = useSelector(selectStudentCourse);
    const courseRules = useSelector(selectStudentCourseRules);
    const advancePercentage = useSelector(selectStudentCourseAdvancePercentage) || 0;
    const lessonIdToGo = useSelector(selectStudentCourseLastLessonLessonId);
    const progress = useSelector(selectStudentCourseProgress);
    const category = useSelector(selectCategory(course?.category as string));
    const hash = useSelector(selectCertificateHash(course?.certificate || ''));
    const analyticsRef = useRef<AnalyticsSelectLessonRef>(null);

    const dispatch = useDispatch();
    const history = useHistory();

    useEffect(() => {
        dispatch(fetchCategories())
    }, [dispatch]);

    useEffect(() => {
        if (course?.certificate && advancePercentage === 1) {
            dispatch(fetchHashByTemplateId(course.certificate))
        }
    }, [advancePercentage, course, dispatch])

    if (!course) {
        return null;
    }
    const { priority = 1, endDate } = courseRules || {};
    const daysLeft = moment(endDate).diff(moment(), 'days') + 1
    const buttonLabel = advancePercentage === 1 ? 'Curso completado' : (progress ? 'Continuar lección' : 'Comenzar curso');
    let urlToGo = `/course/${course._id}/${lessonIdToGo.id}`;
    let isLessonExamOrTestUrl = false;
    switch (lessonIdToGo.where) {
        case COURSE_EXAM:
        case COURSE_SURVEY:
            urlToGo = `/course/${course._id}/exam`;
            isLessonExamOrTestUrl = true;
            break;
        case TOPIC_TEST:
            urlToGo = `/course/${course._id}/test/${lessonIdToGo.id}`;
            isLessonExamOrTestUrl = true;
            break;
    }
    return <Card className={ctx(styles.container)}>
        <div className={styles.title}>{course.title}</div>
        <div className={styles.categoryContainer}>
            <CategoryChip categoryName={category?.name} />
        </div>
        <div className={styles.durationPriorityContainer}>
            <div className={styles.priority}>
                <CogIcon className={styles.icon} color={COURSES_PRIORITY_COLORS[priority]} icon={COURSES_PRIORITY_ICON[priority]} />
                    Prioridad {COURSES_PRIORITY_LABELS[priority]}
            </div>
            <div className={styles.duration}>
                <CogIcon className={styles.icon} color="#81858B" icon="clock" />
                {course.durationHour} Hora{course.durationHour === 1 ? '' : 's'}
            </div>
        </div>
        <div className={styles.timeLeft}>
            {daysLeft > 0 ? daysLeft : 0} día{daysLeft > 1 ? 's' : ''} para finalizar
            </div>
        <div className={styles.progress}>
            <div className={styles.percentage}>{(advancePercentage * 100).toFixed(0)}%</div>
            <Progress percent={advancePercentage * 100} showInfo={false} />
        </div>
        <div className={styles.gotToLessonContainer}>
            <Button className={styles.gotToLesson} type="primary" disabled={!lessonIdToGo || advancePercentage === 1} onClick={() => {
                if (!isLessonExamOrTestUrl) {
                    analyticsRef?.current?.trigger();
                }
                history.push(urlToGo);
            }}>{buttonLabel}</Button>
            {hash ? <Button className={styles.certificateButton}
                type="default" onClick={goToCertificate(hash)}
            >Ver certificado <CogIcon icon="" /></Button> : null}
        </div>
        <AnalyticsSelectLesson ref={analyticsRef} lessonId={lessonIdToGo.id} />
    </Card>
}

type AnalyticsSelectLessonRef = {
    trigger: () => void,
}
interface AnalyticsSelectLessonProps {
    lessonId: string,
}

const AnalyticsSelectLesson =
    forwardRef<AnalyticsSelectLessonRef, AnalyticsSelectLessonProps>(({ lessonId }, ref) => {
        useImperativeHandle(ref, () => ({
            trigger: () => {
                analytics.selectLesson({
                    lesson: lesson,
                    topic: topic,
                    course: course,
                    program: {} as IProgram, // TODO how to fetch program
                    company: company,
                    group: group,
                });
            }
        }));
        const lesson = useSelector(selectStudentCourseLesson(lessonId));
        const topic = useSelector(selectStudentCourseTopicLesson(lessonId));
        const course = useSelector(selectStudentCourse);
        const cognitoUserData = useSelector(getCognitoUserData);
        const cognitoUsername = cognitoUserData
            ? cognitoUserData["cognito:username"]
            : null;
        const userFromStore = useSelector(selectUser(cognitoUsername));
        const companyId = userFromStore?.company ?? "";
        const company = useSelector(selectCompany(companyId));
        const group = useSelector(selectGroup(userFromStore?.studentGroupId || '')) || null;

        return null;
    });
