import React from 'react';
import successImage from '../../../assets/modalIllustrations/success.png';
import successStyles from './upsert.module.scss';

interface Props {
  msgLine1: string,
  msgLine2: string,
  imgSrc?: string,
  alt?: string,
}

export const UpsertInfo: React.FC<Props> = ({
  msgLine1,
  msgLine2,
  imgSrc = successImage,
  alt = 'asset created successfully'
}) => {
  return (
    <div className={successStyles.container}>
      <img
        className={successStyles.img}
        src={imgSrc}
        alt={alt}
      />
      <div className={successStyles.success}>{msgLine1}<br />{msgLine2}</div>
    </div>
  )
}

export default UpsertInfo;
