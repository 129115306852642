import ctx from "classnames";
import moment from "moment";
import React, { FunctionComponent, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  markAsReadStudentNotifications,
  selectStudentNotification,
} from "../../../features/students/notifications";
import { CogIcon } from "../../common/CogIcon";
import { getNotificationIcon } from "../../LoggedUser/NotificationMenu";
import {
  NotificationsTypes,
  NotificationsTypesLabels,
} from "../../Notifications/Manager";
import styles from "./notificationPreview.module.scss";

const NOTIFICATION_LABEL = {
  [NotificationsTypes.SMS]: "Celular",
  [NotificationsTypes.EMAIL]: "Correo",
};

const NOTIFICATION_ICON = {
  [NotificationsTypes.SMS]: "Mobile",
  [NotificationsTypes.EMAIL]: "Mail",
};

interface NotificationPreviewProps {
  notificationId: string | null;
}

export const NotificationPreview: FunctionComponent<NotificationPreviewProps> =
  ({ notificationId }) => {
    const notification = useSelector(
      selectStudentNotification(notificationId || "")
    );
    const dispatch = useDispatch();
    useEffect(() => {
      if (notification && !notification.read) {
        dispatch(markAsReadStudentNotifications(notification._id));
      }
    }, [dispatch, notification]);
    if (!notificationId || !notification) {
      return null;
    }

    return (
      <div className={styles.container}>
        <div className={ctx(styles.notification)}>
          <div className={styles.iconContainer}>
            <div className={styles.background}>
              <CogIcon
                className={styles.icon}
                icon={getNotificationIcon(notification)}
              />
            </div>
          </div>
          <div className={styles.content}>
            <div
              className={styles.title}
              title={notification.title ?? notification.body}
            >
              {notification.type !== NotificationsTypes.SMS && (
                <h3>{notification.title}</h3>
              )}
              {notification.body}
            </div>
          </div>
        </div>
        <div className={styles.info}>
          {notification.type !== NotificationsTypes.PUSH && (
            <div className={styles.row}>
              <div className={styles.label}>
                <CogIcon
                  className={styles.icon}
                  icon={NOTIFICATION_ICON[notification.type]}
                />
                {NOTIFICATION_LABEL[notification.type]}
              </div>
              <div className={styles.value}>{notification.to}</div>
            </div>
          )}
          <div className={styles.row}>
            <div className={styles.label}>
              <CogIcon className={styles.icon} icon="Calendar" />
              Fecha de envío
            </div>
            <div className={styles.value}>
              {moment(
                notification.scheduledDateTime ?? notification.createdAt
              ).format("DD/MM/YY")}
            </div>
          </div>
          <div className={styles.row}>
            <div className={styles.label}>
              <CogIcon className={styles.icon} icon="Notifications" />
              Tipo de notificación
            </div>
            <div className={styles.value}>
              {NotificationsTypesLabels[notification.type]}
            </div>
          </div>
        </div>
      </div>
    );
  };
