import { Button, Card, Divider, Form, InputNumber } from "antd";
import cls from "classnames";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { EvaluationTypeEnum } from "../../features/evaluation/common/enums";
import { IEvaluation } from "../../features/evaluation/common/interfaces";
import {
  selectEvaluationsDict,
  selectTests,
} from "../../features/evaluation/evaluationsSlice";
import { CogIcon } from "../common/CogIcon";
import EvaluationField from "../common/EvaluationField";
import commonStyles from "../common/form.module.scss";
import styles from "./form.module.scss";
import { StepProps } from "./interfaces";

export const SecondStep: React.FC<StepProps> = ({
  newTopic = {},
  setNewTopic,
  onBack = () => {},
  validateForm = () =>
    new Promise((resolve, reject) => {
      resolve("Error");
    }),
}) => {
  const evaluationsDict = useSelector(selectEvaluationsDict);
  const [isCreatingOrUpdating, setIsCreatingOrUpdating] = useState<boolean>(
    true
  );
  const [disableAssignBtn, setDisableAssignBtn] = useState<boolean>(true);
  const handleTestUpdate = (evaluation: IEvaluation) =>
    setNewTopic({ ...newTopic, evaluation: evaluation._id });
  const handleTestDelete = () => {
    setIsCreatingOrUpdating(true);
    setNewTopic({ ...newTopic, evaluation: undefined });
  };
  const handleAcceptChange = () => {
    validateForm()
      .then(() => {
        setIsCreatingOrUpdating(false);
      })
      .catch(() => {
        console.error("Form error");
      });
  };

  useEffect(() => {
    if (newTopic._id !== undefined && newTopic.evaluation !== undefined) {
      setIsCreatingOrUpdating(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <React.Fragment>
      <button
        type="button"
        className={styles.backButton}
        onClick={() => onBack()}
      >
        <CogIcon size="15px" color="#686F84" icon="Arrow-left" />
        <span>Atrás</span>
      </button>
      {newTopic.evaluation && evaluationsDict[newTopic.evaluation] ? (
        <React.Fragment>
          <div className={styles.sectionTitle}>Test</div>
          <Card
            className={cls(
              commonStyles.evaluationCard,
              commonStyles.extendedStyle
            )}
            bodyStyle={{ padding: "16px" }}
          >
            <div className={commonStyles.title}>
              {evaluationsDict[newTopic.evaluation].title}
            </div>
            <span className={cls(commonStyles.pill, commonStyles.totalTop)}>
              <div className={commonStyles.pillContent}>
                Total de preguntas:{" "}
                {evaluationsDict[newTopic.evaluation].questions.length}
              </div>
            </span>
            <Divider />
            {isCreatingOrUpdating ? (
              <div>
                <Form.Item
                  initialValue={newTopic?.correctAnswersToPass}
                  name="correctAnswersToPass"
                  label="Cantidad de respuestas correctas para aprobar"
                  rules={[
                    { required: true, message: "Requerido" },
                    {
                      type: "number",
                      min: 1,
                      message: "No puede ser menor a 1",
                    },
                    {
                      type: "number",
                      max:
                        evaluationsDict[newTopic.evaluation].questions.length,
                      message: `No puede ser mayor a ${
                        evaluationsDict[newTopic.evaluation].questions.length
                      }`,
                    },
                    {
                      validator: (rule, value: any) => {
                        return new Promise((resolve, reject) => {
                          if (value) {
                            const evaluationId = newTopic.evaluation || "";
                            if (
                              parseInt(value) < 1 ||
                              parseInt(value) >
                                evaluationsDict[evaluationId].questions
                                  .length ||
                              isNaN(value)
                            ) {
                              setDisableAssignBtn(true);
                            } else {
                              setDisableAssignBtn(false);
                            }
                            resolve();
                          } else {
                            setDisableAssignBtn(true);
                            resolve();
                          }
                        });
                      },
                    },
                  ]}
                >
                  <InputNumber
                    style={{ width: "100%" }}
                    placeholder="Número de respuestas correctas"
                    onChange={(value) => {
                      setNewTopic({ ...newTopic, correctAnswersToPass: value });
                    }}
                  />
                </Form.Item>
                <Divider />
                <div className={commonStyles.collapsedContainer}>
                  <div className={commonStyles.btnContainer}>
                    <Button
                      disabled={false}
                      onClick={handleTestDelete}
                      type="default"
                    >
                      Descartar
                    </Button>
                    <Button
                      className={commonStyles.space}
                      disabled={disableAssignBtn}
                      onClick={handleAcceptChange}
                      type="primary"
                    >
                      Asignar test
                    </Button>
                  </div>
                </div>
              </div>
            ) : (
              <div className={commonStyles.collapsedContainer}>
                <div className={commonStyles.btnContainer}>
                  <Button
                    className={commonStyles.removeButton}
                    type="default"
                    onClick={() => {
                      setIsCreatingOrUpdating(true);
                    }}
                  >
                    <CogIcon
                      className={commonStyles.square}
                      icon="Edit"
                    ></CogIcon>
                  </Button>
                  <Button
                    className={cls(
                      commonStyles.removeButton,
                      commonStyles.space
                    )}
                    type="default"
                    onClick={handleTestDelete}
                  >
                    <CogIcon
                      className={commonStyles.square}
                      icon="Delete"
                    ></CogIcon>
                  </Button>
                </div>
                <div className={commonStyles.pill}>
                  <div className={commonStyles.pillContent}>
                    <CogIcon color="#81858B" icon="test" />
                    <span className={commonStyles.ansToPass}>
                      {newTopic?.correctAnswersToPass} Respuestas
                    </span>
                  </div>
                </div>
              </div>
            )}
          </Card>
        </React.Fragment>
      ) : (
        <EvaluationField
          evaluationId={newTopic.evaluation}
          type={EvaluationTypeEnum.Test}
          onUpdate={handleTestUpdate}
          evaluationSelector={selectTests}
          companyId={newTopic.companyId || ''}
          parentLabel="el tema"
        ></EvaluationField>
      )}
    </React.Fragment>
  );
};
