import { Countries } from "../../../Users/Form";

interface ICountryData {
  countryName: string,
  countryCode: string
}

export interface ICountriesDict {
  [countryCode: string]: ICountryData;
}

export const getCoutriesDictionary = (): ICountriesDict => {
  const countriesDict: ICountriesDict = {};
  Countries.forEach((country) => {
    countriesDict[country.value] = {
      countryName: country.label,
      countryCode: country.value,
    };
  });
  return countriesDict;
};
