import { Button, Form, Table } from "antd";
import { FormInstance } from "antd/lib/form";
import { ColumnsType } from "antd/lib/table";
import moment from "moment";
import React, { ReactText, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  createCompany,
  disableCompaniesStatus,
  enableCompaniesStatus,
  fetchCompanies,
  hideModal,
  ICompany,
  selectCompanies,
  selectCompaniesAreFetching,
  selectCompaniesIds,
  selectDisplayModal,
  updateCompany,
} from "../../features/company/companySlice";
import { fetchPrograms } from "../../features/program/programSlice";
import { PAGE_SIZE } from "../../utils/constants";
import { CogIcon } from "../common/CogIcon";
import { DialogPopUp } from "../common/DialogPopUp";
import { CogDrawer } from "../common/Drawer";
import { MainLayout } from "../common/Layouts/Main";
import { Status, StatusEnum } from "../common/Status";
import tableStyles from "../common/table.module.scss";
import { TableTools } from "../common/TableTools";
import { useDebounce } from "../utils/useDebounce";
import { MAX_EXTRA_STUDENTS_PERCENT } from "./CompanyProfile";
import { CompanyForm } from "./Form";
import styles from "./index.module.scss";

function renderOptions(
  toggleVisible: any,
  setNewCompany: any,
  form: FormInstance
) {
  return (
    <Button
      onClick={() => {
        toggleVisible();
        setNewCompany({});
        setTimeout(() => {
          form.resetFields();
          form.setFieldsValue({});
        }, 100);
      }}
      type="primary"
    >
      Nueva empresa
    </Button>
  );
}

export const Company: React.FunctionComponent = () => {
  const [visible, setVisible] = useState<boolean>(false);
  const [newCompany, setNewCompany] = useState<ICompany>();
  const [selectedRowKeys, selectRowKeys] = useState<ReactText[]>([]);
  const [filterValue, setFilterValue] = useState<ReactText[]>([]);
  const [currentAction, setCurrentAction] = useState<string>("");
  const [showActivationBtns, setShowActivationBtns] = useState<boolean>(false);
  const debounceFilterValue = useDebounce(filterValue, 500);
  const history = useHistory();
  const areCompaniesFetching = useSelector(selectCompaniesAreFetching);
  const companyIdsList = useSelector(selectCompaniesIds);
  const companyDict = useSelector(selectCompanies);
  const isModalVisible = useSelector(selectDisplayModal);
  const list = companyIdsList.map((companyId) => ({
    ...companyDict[companyId],
  }));
  const toggleVisible = () => setVisible(!visible);
  const dispatch = useDispatch();

  useEffect(() => {
    if (selectedRowKeys.length > 0) {
      setShowActivationBtns(true);
    } else {
      setShowActivationBtns(false);
    }
  }, [selectedRowKeys]);

  const columns: ColumnsType<ICompany> = [
    {
      title: "Empresa",
      dataIndex: "name",
      sortDirections: ["descend", "ascend"],
      defaultSortOrder: "ascend",
      filteredValue: debounceFilterValue,
      ellipsis: true,
      onFilter: (value, record) => {
        return (
          record.name.toLowerCase().includes(value.toString().toLowerCase()) ||
          record.ruc.toLowerCase().includes(value.toString().toLowerCase())
        );
      },
      render: (value, company: ICompany) => {
        const { activeUsersCount = 0, inactiveUsersCount = 0 } = company;
        const totalStudentsCount = activeUsersCount + inactiveUsersCount;
        const extraStudents =
          totalStudentsCount - (company.maxActiveStudents ?? 0);
        const showAlert =
          extraStudents > 0 &&
          (extraStudents / company.maxActiveStudents) * 100 >
            MAX_EXTRA_STUDENTS_PERCENT;
        return (
          <>
            <span className={styles.name}>
              <img className={styles.logo} src={company.logo} alt={value} />
              {value}
            </span>
            {showAlert ? (
              <CogIcon
                className={styles.alertIcon}
                icon="Attention"
                color="#EB5757"
                size="21"
              />
            ) : undefined}
          </>
        );
      },
      sorter: (a: ICompany, b: ICompany) => a.name.localeCompare(b.name),
    },
    {
      title: "Ruc",
      dataIndex: "ruc",
      sortDirections: ["descend", "ascend"],
      sorter: (a: ICompany, b: ICompany) => a.ruc.localeCompare(b.ruc),
    },
    {
      title: "Estado",
      dataIndex: "status",
      render: (status) => <Status status={status} />,
      sortDirections: ["descend", "ascend"],
      sorter: (a: ICompany, b: ICompany) =>
        a.status === b.status ? 0 : a.status === StatusEnum.Enable ? 1 : -1,
    },
    {
      title: "Inicio de contrato",
      dataIndex: "contractDate",
      sortDirections: ["descend", "ascend"],
      sorter: (a: ICompany, b: ICompany) =>
        new Date(a.contractDate).getTime() - new Date(b.contractDate).getTime(),
      render: (value, company) => {
        return (
          <React.Fragment>
            {moment(value).format("DD-MM-YYYY")}
            <div className={tableStyles.actions}>
              <Button
                type="default"
                onClick={() => {
                  history.push(`/companies/${company._id}`);
                }}
              >
                Ver
              </Button>
              <Button
                type="default"
                onClick={() => {
                  setNewCompany(company);
                  form.resetFields();
                  toggleVisible();
                }}
              >
                Editar
              </Button>
            </div>
          </React.Fragment>
        );
      },
    },
  ];

  useEffect(() => {
    dispatch(fetchCompanies());
    dispatch(fetchPrograms());
  }, [dispatch]);

  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedRowKeys: ReactText[]) => {
      selectRowKeys(selectedRowKeys);
    },
  };
  const enableStatus = () => {
    const data = {
      ids: selectedRowKeys.map((key) => key.toString()),
    };
    dispatch(enableCompaniesStatus(data));
  };

  const disableStatus = () => {
    const data = {
      ids: selectedRowKeys.map((key) => key.toString()),
    };
    dispatch(disableCompaniesStatus(data));
  };
  const [form] = Form.useForm();

  return (
    <MainLayout
      title="Empresas"
      rightTopOptions={renderOptions(toggleVisible, setNewCompany, form)}
    >
      <DialogPopUp
        name="Empresa"
        action={currentAction}
        visible={isModalVisible}
        onCancel={() => {
          dispatch(hideModal());
        }}
      />
      <TableTools
        placeholder="Buscar en Teurona"
        onSearch={(value) => setFilterValue([value])}
        onChange={(event) => setFilterValue([event.target.value])}
        onEnable={() => enableStatus()}
        onDisable={() => disableStatus()}
        activationBtnsVisible={showActivationBtns}
      />
      <Table<ICompany>
        loading={areCompaniesFetching}
        className={tableStyles.table}
        rowClassName={() => tableStyles.row}
        rowKey="_id"
        rowSelection={rowSelection}
        columns={columns}
        dataSource={list}
        pagination={{ position: ["bottomRight"], pageSize: PAGE_SIZE }}
      />
      <CogDrawer
        visible={visible}
        toggleVisible={toggleVisible}
        title={newCompany?._id ? "Editar empresa" : "Nueva empresa"}
        footer={
          <div>
            <Button onClick={toggleVisible} type="default">
              Cancelar
            </Button>
            &nbsp;&nbsp;
            <Button
              onClick={() => {
                form
                  .validateFields()
                  .then((_value) => {
                    if (!newCompany) {
                      return;
                    }
                    if (!newCompany._id) {
                      newCompany.status = StatusEnum.Enable;
                      setCurrentAction("creada");
                      if (!newCompany.contractDate) {
                        newCompany.contractDate = moment(new Date()).toDate();
                      }
                      dispatch(createCompany(newCompany));
                    } else {
                      setCurrentAction("actualizada");
                      dispatch(updateCompany(newCompany));
                    }
                    toggleVisible();
                  })
                  .catch((error) => {
                    console.log(
                      "Company:React.FunctionComponent -> error",
                      error
                    );
                  });
              }}
              type="primary"
            >
              {newCompany?._id ? "Actualizar empresa" : "Crear empresa"}
            </Button>
          </div>
        }
      >
        <div>
          {visible ? (
            <CompanyForm
              form={form}
              newCompany={newCompany}
              setNewCompany={setNewCompany}
            />
          ) : null}
        </div>
      </CogDrawer>
    </MainLayout>
  );
};
