import React, { FunctionComponent } from 'react';
import ctx from 'classnames';

import styles from './index.module.scss';

interface StepIndicatorProps {
    stepsCount: number,
    step: number,
}

export const StepIndicator: FunctionComponent<StepIndicatorProps> = ({
    stepsCount,
    step
}) => {
    const steps = [];
    for(let i = 0; i < stepsCount; ++i){
        steps.push(
        <div
            key={i} 
            className={ctx(styles.step, {
                [styles.passed]:i < step,
                [styles.current]:i === step,
            })}
        />);
    }
    return <div className={styles.container}>
        {steps}
    </div>
}