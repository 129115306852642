import { Button, Form } from "antd";
import React, { FunctionComponent, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { clearBreadcrumbs } from "../../features/breadcrumbs/breadcrumbsSlice";
import {
  fetchCategories,
  selectCategories,
  selectCategoriesDict,
} from "../../features/category/categorySlice";
import {
  fetchCourse,
  selectAreCoursesFetching,
} from "../../features/courses/coursesSlice";
import { MainLayout } from "../common/Layouts/Main";
import { CoursesForm } from "./Form";
import { CoursesList } from "./List";

function renderOptions(toggleVisible: any) {
  return (
    <Button
      onClick={() => {
        toggleVisible();
      }}
      type="primary"
    >
      Nuevo curso
    </Button>
  );
}

export const Courses: FunctionComponent = () => {
  const [isFormVisible, setFormVisible] = useState<boolean>(false);
  const [editingCourse, setEditingCourse] = useState<string | null>(null);
  const areCoursesFetching = useSelector(selectAreCoursesFetching);
  const categories = useSelector(selectCategoriesDict);
  const categoriesIds = useSelector(selectCategories);
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!areCoursesFetching) {
      form.setFieldsValue({});
      form.resetFields();
    }
  }, [form, areCoursesFetching]);

  useEffect(() => {
    dispatch(fetchCategories());
  }, [dispatch]);

  useEffect(() => {
    if (!isFormVisible) {
      dispatch(clearBreadcrumbs());
    }
  }, [dispatch, isFormVisible]);

  let content;
  if (isFormVisible) {
    content = (
      <CoursesForm
        categories={categories}
        categoriesIds={categoriesIds}
        editing={editingCourse}
        form={form}
        onDone={() => {
          setFormVisible(false);
          setEditingCourse(null);
        }}
      />
    );
  } else {
    const onEdit = (courseId: string) => {
      dispatch(fetchCourse(courseId));
      setEditingCourse(courseId);
      setFormVisible(true);
    };
    content = <CoursesList editCourse={onEdit} categories={categories} />;
  }
  const toggleVisible = () => setFormVisible(!isFormVisible);
  return (
    <MainLayout
      hideTitle={isFormVisible}
      hideRightTopOptions={isFormVisible}
      title="Cursos"
      rightTopOptions={renderOptions(toggleVisible)}
    >
      {content}
    </MainLayout>
  );
};
