import React, { FunctionComponent, useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  fetchStudentMyCourses,
  selectStudentMyStartedCourse,
  selectStudentMyStartedCoursesKeys,
} from "../../../features/students/myCoursesSlice";
import { StudentMainLayout } from "../../common/Layouts/StudentMainLayout";
import { OneColumnLayout } from "../common/OneColumnLayout";
import { MyCoursesBlock } from "./MyCoursesBlock";

export const CoursesInProgress: FunctionComponent<{}> = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchStudentMyCourses());
  });

  return (
    <StudentMainLayout>
      <OneColumnLayout>
        <div>
          <MyCoursesBlock
            title="En proceso"
            individualSelector={selectStudentMyStartedCourse}
            selector={selectStudentMyStartedCoursesKeys}
            emptyMessage="No tienes cursos en proceso"
          />
        </div>
      </OneColumnLayout>
    </StudentMainLayout>
  );
};
