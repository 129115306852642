import React, { FunctionComponent, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../app/store';
import { selectCompanies, selectCompaniesAreFetching } from '../../../../../features/company/companySlice';
import { selectCompaniesGroups } from '../../../../../features/groups/groupSlice';
import { PIECHART_COLORS } from '../../../../../features/reports/common';
import { selectCompaniesContents, selectCompaniesContentsAreFetching, selectGroupsContents } from '../../../../../features/reports/learning/common/companiesContent';
import { IFulfillmentPerCompany } from '../../../../../features/reports/learning/common/interfaces';
import { selectStudentsPerCompany } from '../../../../../features/users/usersSlice';
import LoadingOverlay from '../../../../common/LoadingOverlay';
import { RoundedContainer } from '../../../../common/RoundedContainer';
import { DisplayDirection, IconNames } from '../../../common/SimpleStat';
import { StatData, StatsRow } from '../../../common/StatsRow';

interface LessonsIndicatorsProps {
  companyId?: string,
  selectFulfillmentsPerCompany: (state: RootState) => IFulfillmentPerCompany;
  selectFulfillmentsPerCompanyFetching: (state: RootState) => boolean;
}

export const LessonsIndicators: FunctionComponent<LessonsIndicatorsProps> = (
  {
    selectFulfillmentsPerCompany,
    selectFulfillmentsPerCompanyFetching,
    companyId
  }
) => {
  const [data, setData] = useState<[number, number]>([0, 0]);
  const companiesContent = useSelector(selectCompaniesContents);
  const contentsAreFetching = useSelector(selectCompaniesContentsAreFetching);
  // Users
  const studentsPerCompany = useSelector(selectStudentsPerCompany);
  // Companies
  const isCompaniesFetching = useSelector(selectCompaniesAreFetching);
  const companies = useSelector(selectCompanies);
  // Fulfillments
  const isFulfillmentsFetching = useSelector(selectFulfillmentsPerCompanyFetching);
  const fulfillments = useSelector(selectFulfillmentsPerCompany);
  //Groups
  const companiesGroups = useSelector(selectCompaniesGroups);
  const groupsContents = useSelector(selectGroupsContents);

  const fetching = isCompaniesFetching || 
    isFulfillmentsFetching || 
    contentsAreFetching;

  useEffect(()=>{
    if(fetching || Object.keys(companiesContent).length === 0){
      return;
    }
    let completed = 0;
    Object.keys(fulfillments).forEach(fulfillmentsCompanyId => {
      if(companyId && companyId !== fulfillmentsCompanyId){
        return;
      }
      if(!companiesContent[fulfillmentsCompanyId]){
        return;
      }
      const fulfillment = fulfillments[fulfillmentsCompanyId];
      const company = companies[fulfillmentsCompanyId];
      if(!company){
        return;
      }
      completed += fulfillment.completed;
    });
    let total = 0;
    const addCompanyToTotal = (companyId:string) => {
      Array.from(new Set(companiesGroups[companyId])).forEach(groupId=>{
        if(!groupsContents[groupId]){
          return;
        }
        const contentCount = groupsContents[groupId].lessons.length;
        const studentsCount = groupsContents[groupId].students;
        total += studentsCount * contentCount ;
      });
    }
    Object.keys(companiesGroups).forEach(companyId => {
      addCompanyToTotal(companyId);
    });
    setData([completed, total - completed]);
  },[companies, companyId, fetching, fulfillments, companiesContent, studentsPerCompany, companiesGroups, groupsContents]);

  let count = 0;
  if(companyId){
    count = companiesContent[companyId]?(Array.from(new Set(companiesContent[companyId].lessons))).length : 0;
  }else{
    const lessonsIds:string[] = [];
    Object.values(companiesContent).forEach(companyContents => {
      lessonsIds.push(...companyContents.lessons);
    });
    count = (Array.from(new Set(lessonsIds))).length;
  }
  const [completed, rest] = data;
  let total = completed + rest;
  const percentage = Math.round(completed/(total ? total : 1) * 100);
  const statsData: StatData = [
    {
      iconName: IconNames.Availability,
      mainText: `${count}`,
      description: 'Lecciones disponibles',
      direction:DisplayDirection.row
    },
    {
      percentValue: percentage.toFixed(2),
      description: <div>de cumplimiento de lecciones</div>,
      canvasHeight: 100,
      type: '2',
      data: {
        labels: [
          'Cumplidas',
          'Incumplidas',
        ],
        datasets: [{
          label: '',
          data,
          backgroundColor: [
            PIECHART_COLORS[0],
            PIECHART_COLORS[1],
          ],
          hoverOffset: 4
        }],
      },
    }
  ];
  return <RoundedContainer>
    {contentsAreFetching ? 
      <LoadingOverlay inline height={300} spinning /> :
      <StatsRow statsData={statsData} />
    }
  </RoundedContainer>;
}