export const PAGE_SIZE:number = 10;
export const MODAL_DELAY_MS: number = 5000;
export const dateFormatList = ['DD/MM/YYYY', 'DD/MM/YY'];


export const COURSES_PRIORITY = {
       HIGH: 1,
       MEDIUM: 2,
       LOW: 3,
}

export const COURSES_PRIORITY_LABELS = {
    [COURSES_PRIORITY.HIGH]: 'alta',
    [COURSES_PRIORITY.MEDIUM]: 'media',
    [COURSES_PRIORITY.LOW]: 'baja',
}

export const COURSES_PRIORITY_ICON = {
    [COURSES_PRIORITY.HIGH]: 'priority-high',
    [COURSES_PRIORITY.MEDIUM]: 'priority-medium',
    [COURSES_PRIORITY.LOW]: 'priority-low',
}

export const COURSES_PRIORITY_COLORS = {
    [COURSES_PRIORITY.HIGH]: '#EB5757',
    [COURSES_PRIORITY.MEDIUM]: '#F2C94C',
    [COURSES_PRIORITY.LOW]: '#20a471',
}