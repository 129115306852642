import React, { useState, useEffect, useRef } from 'react';
import {
  Card,
  Button,
  DatePicker,
  Divider,
  Menu,
  Dropdown,
  Select,
  Form,
  Switch,
} from 'antd';
import moment from 'moment';
import cls from 'classnames';
import styles from './form.module.scss';
import { useSelector, useDispatch } from 'react-redux';
import { CogIcon } from '../common/CogIcon';
import {
  ICourse,
  selectCoursesDict,
} from "../../features/courses/coursesSlice";
import {
  selectCompany
} from "../../features/company/companySlice";

import { IProgram } from "../../features/program/programSlice";
import { setMessageError } from '../../features/message/messageSlice';
import { DATE_FORMAT } from './Form';
import { selectCompanyId, selectUserRole } from '../../features/auth/authSlice';
import { UserRole } from '../../features/users/usersSlice';

const { RangePicker } = DatePicker;

interface CoursesSelectedProps {
  selectedCourses: string[],
  setProgram: Function,
  program?: IProgram,
  coursesList: ICourse[],
  validateFields: Function,
}

interface CourseSelectedProps {
  courseId: string,
  courseIndex: number,
  remove: Function,
  setProgram: Function,
  program?: IProgram,
  fieldName: number,
  validateFields: Function,
}

interface ICourseRule {
  startDate: Date | null | moment.Moment,
  endDate?: Date | null,
  hasPredecessor: boolean,
  predecessor: string[],
  priority: number,
  endUndefined: boolean,
}

export const PRIORITIES = [
  {
    value: 1,
    label: 1,
    text: 'Prioridad alta',
  },
  {
    value: 2,
    label: 2,
    text: 'Prioridad media',
  },
  {
    value: 3,
    label: 3,
    text: 'Prioridad baja',
  },
];

interface CourseSelectedFormProps {
  courseId: string,
  courseIndex: number,
  setProgram: Function,
  program?: IProgram,
  fieldName: number,
  onRemove: Function,
  onAssign: Function,
}

interface DeleteHandlerProps {
  program?: IProgram
  fieldName: number,
  setProgram: Function,
  onRemove: Function,
  courseIndex: number,
  onDeleteRejected: Function
}

const onDeleteHandler = ({ program, fieldName, setProgram, onRemove, onDeleteRejected }: DeleteHandlerProps) => {
  let currentCourses = (program && program?.courses) ? program.courses : [];
  currentCourses = [...currentCourses];
  const courseIdToDelete = currentCourses[fieldName];
  let currentCoursesRules = (program && program?.coursesRules) ? program.coursesRules : [];
  currentCoursesRules = [...currentCoursesRules];
  const precededCoursesIds: string[] = [];
  currentCoursesRules.forEach((courseRule, index) => {
    let predecesorId = '';
    if (Array.isArray(courseRule.predecessor)) {
      predecesorId = courseRule.predecessor[0];
    } else {
      predecesorId = courseRule.predecessor;
    }
    if (predecesorId === courseIdToDelete) {
      precededCoursesIds.push(currentCourses[index])
    }
  });
  if (precededCoursesIds.length > 0) {
    onDeleteRejected('Está siendo usada como predecesor');
    return;
  }
  currentCoursesRules.splice(fieldName, 1);
  currentCourses.splice(fieldName, 1);
  setProgram({ ...program, courses: [...currentCourses], coursesRules: [...currentCoursesRules] } as IProgram);
  onRemove(fieldName);
}

const CourseSelectedForm: React.FC<CourseSelectedFormProps> = ({ courseId, courseIndex, setProgram, program, fieldName, onRemove, onAssign }) => {
  const currentRules = (program?.coursesRules) ? program?.coursesRules : [];
  const leftRules = currentRules.slice(0, courseIndex);
  const rightRules = currentRules.slice(courseIndex + 1, currentRules.length);
  const courseRule: ICourseRule | null = (program?.coursesRules) ? (program?.coursesRules)[courseIndex] : null;
  const currentSelectedCoursesIds = (program && program.courses) ? [...(program?.courses) as any] : [];
  const availablePredecesorIds = currentSelectedCoursesIds.filter(id => id !== courseId);
  const coursesDict = useSelector(selectCoursesDict);
  const predecesorCoursesList = availablePredecesorIds.map(courseId => coursesDict[courseId]);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!program?._id || !courseRule) { // perhaps only course rule needs to be tested
      const rule = Object.assign({}, currentRules[courseIndex], { predecessor: [] }, { hasPredecessor: false } as ICourseRule);
      setProgram({ ...program, coursesRules: [...leftRules, rule, ...rightRules] } as IProgram);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onDeleteRejected = (message: string) => {
    dispatch(setMessageError({ message }))
  }

  const currentStartDate = currentRules[courseIndex]?.startDate;
  const isCourseAlreadyStarted = currentStartDate ? moment(currentStartDate, DATE_FORMAT).isBefore(moment()) : false;
  const renderDatesSelector = () => {
    if (courseRule?.endUndefined) {
      return <Form.Item name={[courseIndex, 'startDate']} label="Fecha de inicio"
      rules={[
        { required: true, message: 'Requerido' },
      ]}>
      <DatePicker
        format={DATE_FORMAT}
        disabledDate={current => current && current < moment().startOf('day')}
        disabled={isCourseAlreadyStarted}
        onChange={(startDate) => {
          const startDateMoment = moment(startDate).format(DATE_FORMAT).toString();
          const rule = Object.assign({}, currentRules[courseIndex], { startDate: startDateMoment, endDate: null });
          setProgram({ ...program, coursesRules: [...leftRules, rule, ...rightRules] } as IProgram);
        }} />
      </Form.Item>;
    }
    return <Form.Item name={[courseIndex, 'dateRange']} label="Fecha de inicio y fin"
    rules={[
      { required: true, message: 'Requerido' },
      () => ({
        validator: async (_rules, value) => {
          const startDate = value[0] as moment.Moment;
          const endDate = value[1] as moment.Moment;
          if (startDate.isSame(endDate)) {
            throw new Error('Debe existir al menos un día de diferencia');
          }
        }
      })
    ]}>
    <RangePicker
      format={DATE_FORMAT}
      disabledDate={current => current && current < moment().startOf('day')}
      disabled={[isCourseAlreadyStarted, false]}
      onChange={(dates) => {
        if (dates) {
          const startDate = moment(dates![0]).format(DATE_FORMAT).toString();
          const endDate = moment(dates![1]).format(DATE_FORMAT).toString();

          const rule = Object.assign({}, currentRules[courseIndex], { startDate: startDate, endDate: endDate });
          setProgram({ ...program, coursesRules: [...leftRules, rule, ...rightRules] } as IProgram);
        }
      }} />
  </Form.Item>
  }
  return (
    <React.Fragment>
      <Form.Item name={[courseIndex, 'hasPredecessor']}>
        <div>
          <div>No tiene fecha de fin</div>
          <Switch checked={courseRule?.endUndefined ?? false} size='small' onChange={(value) => {
            let rule: ICourseRule;
            if (value === true) {
              rule = Object.assign({}, currentRules[courseIndex], { endUndefined: value, endDate: null } as ICourseRule);
            } else {
              rule = Object.assign({}, currentRules[courseIndex], { endUndefined: value, } as ICourseRule);
            }
            setProgram({ ...program, coursesRules: [...leftRules, rule, ...rightRules] } as IProgram);
          }} />
        </div>
      </Form.Item>
      {renderDatesSelector()}
      <Form.Item name={[courseIndex, 'priority']} label="Prioridad"
        rules={[{ required: true, message: 'Requerido' }]}
      >
        <Select placeholder={`Escoge un número entre el ${PRIORITIES[0].label} y el ${PRIORITIES[PRIORITIES.length - 1].label}`}
          onChange={value => {
            const rule = Object.assign({}, currentRules[courseIndex], { priority: value } as ICourseRule);
            setProgram({ ...program, coursesRules: [...leftRules, rule, ...rightRules] } as IProgram);
          }
          }>
          {PRIORITIES.map(item => (<Select.Option key={item.value} value={item.value}>{item.label}</Select.Option>))}
        </Select>
      </Form.Item>
      <Form.Item name={[courseIndex, 'hasPredecessor']}>
        <div>
          <div>Este curso tiene un predecesor</div>
          <Switch checked={courseRule?.hasPredecessor ?? false} size='small' disabled={isCourseAlreadyStarted} onChange={(value) => {
            let rule: ICourseRule;
            if (value === true) {
              rule = Object.assign({}, currentRules[courseIndex], { hasPredecessor: value } as ICourseRule);
            } else {
              rule = Object.assign({}, currentRules[courseIndex], { predecessor: [] }, { hasPredecessor: value } as ICourseRule);
            }
            setProgram({ ...program, coursesRules: [...leftRules, rule, ...rightRules] } as IProgram);
          }} />
        </div>
      </Form.Item>
      {
        !(courseRule?.hasPredecessor) ? null :
          <Form.Item name={[courseIndex, 'predecessor']} label="Curso predecesor"
            rules={[]}
          >
            <Select placeholder={'Escoge un curso'}
              onChange={value => {
                const rule = Object.assign({}, currentRules[courseIndex], { predecessor: value } as ICourseRule);
                setProgram({ ...program, coursesRules: [...leftRules, rule, ...rightRules] } as IProgram);
              }
              }>
              {predecesorCoursesList.map((course: ICourse) => (<Select.Option key={course._id} value={course._id}>{course.title}</Select.Option>))}
            </Select>
          </Form.Item>
      }
      {isCourseAlreadyStarted && <p><small>*Este curso ya ha empezado. No se puede modificar la fecha de inicio ni agregar un predecesor.</small></p>}
      <Divider />

      <Button
        type="default"
        className="dynamic-delete-button"
        onClick={() => {
          onDeleteHandler({ program, fieldName, setProgram, onRemove, courseIndex, onDeleteRejected });
        }}
      >
        Descartar
      </Button>
      <Button type="primary" onClick={() => {
        if (currentRules[courseIndex].hasPredecessor && (!currentRules[courseIndex].predecessor || currentRules[courseIndex].predecessor.length === 0)) {
          const rule = Object.assign({}, currentRules[courseIndex], { predecessor: [] }, { hasPredecessor: false } as ICourseRule);
          setProgram({ ...program, coursesRules: [...leftRules, rule, ...rightRules] } as IProgram);
        }
        onAssign();
      }}>Asignar curso</Button>

    </React.Fragment>
  );
}

interface CourseSelectedSummaryProps {
  setIsCreatingOrUpdating: Function,
  onRemove: Function,
  courseRule: ICourseRule,
  readonly: boolean,
}

const PriorityIconName = [
  'priority-low',
  'priority-high',
  'priority-medium',
  'priority-low',
]

const CourseSelectedSummary: React.FC<CourseSelectedSummaryProps> = ({ setIsCreatingOrUpdating, onRemove, readonly, courseRule = {} }) => {
  const castedCourseRule = courseRule as ICourseRule;
  const priority: number = (castedCourseRule?.priority) ? castedCourseRule?.priority : 0;
  return (
    <div className={styles.summary}>
      <div className={styles.btnContainer}>
        <Button
          className={styles.actionButton}
          type="default"
          disabled={readonly}
          onClick={() => {
            if(readonly){
              return;
            }
            setIsCreatingOrUpdating(true);
          }}
        >
          <CogIcon className={styles.square} icon="Edit"></CogIcon>
        </Button>
        <Button
          className={styles.actionButton}
          type="default"
          disabled={readonly}
          onClick={() => {
            if(readonly){
              return;
            }
            onRemove();
          }}
        >
          <CogIcon className={styles.square} icon="Delete"></CogIcon>
        </Button>
      </div>
      <div className={styles.details}>
        <div className={styles.range}>
          <CogIcon className={styles.icon} icon='Calendar'></CogIcon>
          <div>{moment(castedCourseRule?.startDate, DATE_FORMAT).format('DD/MM/YYYY')}</div>
          <span className={styles.separator}>-</span>
          <div>{castedCourseRule?.endUndefined ? 'No tiene fecha' : moment(castedCourseRule?.endDate, DATE_FORMAT).format('DD/MM/YYYY')}</div>
        </div>
        <div className={styles.priority}>
          <CogIcon
            icon={PriorityIconName[priority]}
            color={
              cls(
                { '#F2C94C': (castedCourseRule?.priority === 2) },
              )
            }
          />
          <div className={styles.label}>{PRIORITIES.find(item => item.value === castedCourseRule?.priority)?.text}</div>
        </div>
      </div>
    </div>
  )
}

const CourseSelected: React.FC<CourseSelectedProps> = ({ courseId, remove: onRemove, setProgram, program, courseIndex, fieldName, validateFields }) => {
  const courses = useSelector(selectCoursesDict);
  let course = useRef(courses[courseId]);
  const companyName = useSelector(selectCompany(course.current._id))?.name;
  const [isCreatingOrUpdating, setIsCreatingOrUpdating] = useState<boolean>(false);
  const courseRule = (program?.coursesRules) ? (program?.coursesRules)[courseIndex] : null;
  const dispatch = useDispatch();
  const userRole = useSelector(selectUserRole);
  const companyId = useSelector(selectCompanyId);
  const readonly = userRole !== UserRole.SuperAdmin && course.current.companyId !== companyId;

  useEffect(() => {
    if (courseRule === null || courseRule === undefined) {
      setIsCreatingOrUpdating(true);
    }
  }, [courseRule]);

  useEffect(() => {
    course.current = courses[courseId] as ICourse;
  }, [courseId, courses]);

  const onDeleteRejected = (message: string) => {
    dispatch(setMessageError({ message }))
  }

  if (!course) {
    return null;
  }
  const {current} = course;
  return (
    <Card key={course.current?._id} className={cls(styles.card)} bodyStyle={{ padding: '16px' }} >
      <div className={styles.topIconContainer}>
        <CogIcon icon="drag"></CogIcon>
      </div>
      {companyName?<div className={styles.topCompanyName}>{companyName}</div>:null}
      <div className={styles.title}>{current?.title}</div>
      <div className={styles.meta}>
        <div>{current?._id}</div>
      </div>
      <div className={styles.description}>
        {current?.description}
      </div>
      <Divider />
      {isCreatingOrUpdating && !readonly ?
        <CourseSelectedForm
          courseId={courseId}
          courseIndex={courseIndex}
          setProgram={setProgram}
          program={program}
          fieldName={fieldName}
          onRemove={onRemove}
          onAssign={() => {
            validateFields().then(() => {
              setIsCreatingOrUpdating(false);

            }).catch((error: any) => {
              console.error('Courses selected:  -> error', error);
            });
          }}
        /> :
        <CourseSelectedSummary
          setIsCreatingOrUpdating={setIsCreatingOrUpdating}
          onRemove={() => {
            onDeleteHandler({ program, fieldName, setProgram, onRemove, courseIndex, onDeleteRejected });
          }}
          readonly={readonly}
          courseRule={courseRule as ICourseRule}
        />
      }
    </Card>
  );
}

export const CoursesSelected: React.FC<CoursesSelectedProps> = ({ selectedCourses, setProgram, program, coursesList, validateFields }) => {
  const currentCoursesIds = (program && program.courses) ? [...(program?.courses) as any] : [];

  const courseByTitleComparator = (a: ICourse, b: ICourse) => {
    const aName = a.title.toLowerCase();
    const bName = b.title.toLowerCase();

    if (aName < bName) {
      return -1;
    }
    if (aName > bName) {
      return 1;
    }
    return 0;
  }

  return (
    <div>
      <Form.List name="courseRules2">
        {(fields, { add, remove }) => {
          const menu = (
            <Menu>
              {coursesList.filter((course: ICourse) => !selectedCourses.includes(course._id)).sort(courseByTitleComparator).map((course: ICourse, index: number) => {
                return (
                  <Menu.Item key={index}>
                    <p onClick={() => {
                      add();
                      setProgram({ ...program, courses: [...currentCoursesIds, course._id] });
                    }}>
                      {course.title}
                    </p>
                  </Menu.Item>
                );
              })}
            </Menu>
          );
          return (
            <div>
              {fields.map((field, index) => {
                return (
                  <div key={field.key}>
                    <CourseSelected
                      courseId={selectedCourses[index]}
                      fieldName={field.name}
                      courseIndex={index}
                      program={program}
                      remove={remove}
                      setProgram={setProgram}
                      validateFields={validateFields}
                    />
                  </div>
                );
              })}
              <Form.Item>
                <Dropdown
                  overlay={menu}
                  trigger={['click']}
                  getPopupContainer={() => document.getElementById('courses-lc-div') as HTMLElement}
                >
                  <p className="ant-dropdown-link"
                    onClick={
                      e => {
                        e.preventDefault();
                      }}>
                    <Button className={styles.addCoursesButton} type="default">
                      <CogIcon className={styles.square} color="white" icon="Plus" />Añadir curso
                    </Button>
                  </p>
                </Dropdown>
              </Form.Item>
            </div>
          );
        }}
      </Form.List>
      <div id='courses-lc-div' style={{ maxHeight: '100vh' }}></div>
    </div>
  );
}
