import { Space } from "antd";
import React, { FunctionComponent } from "react";
import { StudentMainLayout } from "../../common/Layouts/StudentMainLayout";
import ReportCaseButton from "../../ReportCaseButton";
import { CampaignsBlock } from "./CampaignsBlock";
import { CoursesBlock } from "./CoursesBlock";
import { CurrentCourseBlock } from "./CurrentCourseBlock";
import styles from "./index.module.scss";
import { RelatedArticlesBlock } from "./RelatedArticlesBlock";

export const StudentHome: FunctionComponent = () => (
  <>
    <ReportCaseButton />
    <div className={styles.grayBackground} />
    <StudentMainLayout>
      <Space size={100} direction="vertical">
        <CurrentCourseBlock />
        <CampaignsBlock />
        <CoursesBlock />
        <RelatedArticlesBlock />
      </Space>
    </StudentMainLayout>
  </>
);
