import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import moment from "moment";
import { Dispatch } from "react";
import { Microservices } from "../../../app/AllowedMicroservices";
import { RootState } from "../../../app/store";
import apiActionCreator, {
  HttpMethods,
} from "../../../services/apiActionCreator";
import { FetchStatus } from "../../common/enums";

interface ICategory {
  id: number;
  code: string;
  name: string;
}

interface Count {
  personal: number;
  anonimous: number;
}

interface ICategoryFetch extends ICategory {
  count: Count;
}

interface ICategoryState extends ICategory {
  personal: number;
  anonimous: number;
}

interface TicketsByCategoryState {
  data: ICategoryState[];
  status: FetchStatus;
}

const initialState: TicketsByCategoryState = {
  data: [],
  status: FetchStatus.Idle,
};

export const ticketsByCategorySlice = createSlice({
  name: "ticketsByCategory",
  initialState,
  reducers: {
    fetching: (state) => {
      state.status = FetchStatus.Fetching;
    },
    fetched: (state, action: PayloadAction<ICategoryFetch[]>) => {
      state.data = action.payload.map((item) => ({
        code: item.code,
        id: item.id,
        name: item.name,
        personal: item.count.personal,
        anonimous: item.count.anonimous,
      }));
      state.status = FetchStatus.Fetched;
    },
    error: (state) => {
      state.status = FetchStatus.Error;
    },
  },
});

export const { fetching, fetched, error } = ticketsByCategorySlice.actions;
export interface FetchCasesParams {
  from: moment.Moment;
  to: moment.Moment;
  customToken: string;
  companyId?: string; 
}

export const fetchByRange =
  ({ from, to, customToken, companyId }: FetchCasesParams) =>
  (dispatch: Dispatch<object>) => {
    const fromStrDate = moment(from).format("YYYY-MM-DD");
    const toStrDate = moment(to).format("YYYY-MM-DD");
    return dispatch(
      apiActionCreator({
        endpoint: "cognizant/category/ticketsByReportType",
        types: {
          requestType: fetching,
          successTypes: [
            {
              actionOrCreator: fetched,
            },
          ],
          errorTypes: [
            {
              actionOrCreator: error,
            },
          ],
        },
        method: HttpMethods.POST,
        microservice: Microservices.X_ONE,
        authenticated: true,
        customToken,
        data: {
          from: fromStrDate,
          to: toStrDate,
          company: companyId,
        },
      })
    );
  };

export const selectCaseIsFetching = (state: RootState) =>
  state.reports.case.ticketsByCategory.status === FetchStatus.Fetching;

export const selectCasesByCategoryData = (state: RootState) =>
  state.reports.case.ticketsByCategory.data;

export default ticketsByCategorySlice.reducer;
