import firebase from 'firebase/app';
import 'firebase/messaging';
// import 'firebase/analytics';
import { registerClient } from '../features/notifications/registerClientSlice';
import { FIREBASE_JSON, FIREBASE_VAPIDKEY } from '../app/config';
const firebaseConfig = FIREBASE_JSON;
console.log({ firebaseConfig });
firebase.initializeApp(firebaseConfig);
// firebase.analytics();
let firebaseIsSupported = firebase.messaging.isSupported();
let messaging:firebase.messaging.Messaging;
if (firebaseIsSupported) {
  messaging = firebase.messaging();
}
export const getFirebaseToken = (setTokenFound: any, dispatch: any) => {
  if(!firebaseIsSupported){
    return;
  }
  return messaging
    .getToken({
      vapidKey: FIREBASE_VAPIDKEY,
    })
    .then((currentToken) => {
      if (currentToken) {
          dispatch(registerClient({ firebaseClientId: currentToken }));
        //console.log('current token for client: ', currentToken);
        setTokenFound(true);
        // Track the token -> client mapping, by sending to backend server
        // show on the UI that permission is secured
      } else {
        console.log(
          'No registration token available. Request permission to generate one.'
        );
        setTokenFound(false);
        // shows on the UI that permission is required
      }
    })
    .catch((err) => {
      console.log('An error occurred while retrieving token. ', err);
      // catch error while creating client token
    });
};
export const onMessageListener = () => {
  if(!firebaseIsSupported){
    return new Promise(() => {});
  }
  return new Promise((resolve) => {
    messaging.onMessage((payload) => {
      resolve(payload);
    });
  });
}