export interface ViewProps {
  maxMessageCount: number,
  targets: string[];
  message: string;
  setMessage: (message: string) => void;
  title?: string;
  setTitle: (title: string) => void;
}

export enum TargetType {
  groups = 'groups',
  students = 'students'
}