import { Button } from "antd";
import cls from "classnames";
import moment from "moment";
import React from "react";
import campaignPlaceHolder from "../../../src/assets/campaign/campaign-placeholder.png";
import { ICampaign } from "../../features/campaign/campaignSlice";
import { ConditionEnum } from "../../features/common/enums";
import { CogIcon } from "../common/CogIcon";
import { Status, StatusEnum } from "../common/Status";
import tableStyles from "../common/table.module.scss";
import styles from "./campaignTile.module.scss";

interface Props {
  campaign: ICampaign;
  companyName: string;
  onEdit(campaign: ICampaign): void;
}

export const CampaignTile: React.FunctionComponent<Props> = ({
  campaign,
  companyName,
  onEdit,
}) => (
  <React.Fragment>
    <article className={styles.campaignContainer} tabIndex={0}>
      <div className={styles.imageWrapper}>
        <img
          src={campaign.image ?? campaignPlaceHolder}
          alt={campaign.title}
          tabIndex={1}
        />
      </div>
      <section className={styles.campaignTile}>
        <header>
          <h3 tabIndex={2}>{campaign.title}</h3>
          <div className={styles.extraWrapper}>
            {campaign.survey && (
              <div className={styles.survey} tabIndex={4}>
                <CogIcon
                  color="#81858B"
                  icon="test"
                  className={styles.square}
                />
                <span>Encuesta</span>
              </div>
            )}
            <Status
              status={
                campaign.published === ConditionEnum.Published
                  ? StatusEnum.Enable
                  : StatusEnum.Disable
              }
              small={true}
            />
          </div>
        </header>
        <p tabIndex={5}>{campaign.description}</p>
        <footer>
          <div tabIndex={6}>
            {companyName} - Creada en:{" "}
            {moment(campaign.createdAt).format("DD-MM-YY")}
          </div>
          <div tabIndex={7}>{`Comienza: ${moment(campaign.fromDate).format(
            "DD-MM-YY"
          )} - Termina: ${moment(campaign.toDate).format("DD-MM-YY")}`}</div>
        </footer>
      </section>
    </article>
    <div className={cls(tableStyles.actions, tableStyles.small)}>
      <Button type="default" onClick={() => onEdit(campaign)}>
        Editar
      </Button>
    </div>
  </React.Fragment>
);
