import React, { FunctionComponent, useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  fetchStudentMyCourses,
  selectStudentMyPendingCourse,
  selectStudentMyPendingCoursesKeys,
} from "../../../features/students/myCoursesSlice";
import { StudentMainLayout } from "../../common/Layouts/StudentMainLayout";
import { OneColumnLayout } from "../common/OneColumnLayout";
import { MyCoursesBlock } from "./MyCoursesBlock";

export const CoursesPending: FunctionComponent<{}> = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchStudentMyCourses());
  });

  return (
    <StudentMainLayout>
      <OneColumnLayout>
        <div>
          <MyCoursesBlock
            title="Pendiente"
            individualSelector={selectStudentMyPendingCourse}
            selector={selectStudentMyPendingCoursesKeys}
            emptyMessage="Felicitaciones, no tienes cursos pendientes"
            />
        </div>
      </OneColumnLayout>
    </StudentMainLayout>
  );
};
