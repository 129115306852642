import { Button, Card, message, Radio } from "antd";
import { RadioChangeEvent } from "antd/lib/radio";
import ctx from "classnames";
import React, { FunctionComponent, useState } from "react";
import { IQuestion } from "../../../features/evaluation/common/interfaces";
import styles from "./evaluations.module.scss";

interface QuestionPageProps {
  className?: string;
  question?: IQuestion;
  onNext: Function;
  dontShowFeedback?: boolean;
  currentQuestion: number;
  questionsCount: number;
  setAnswerSelected: Function;
}

export const QuestionPage: FunctionComponent<QuestionPageProps> = ({
  className,
  question,
  onNext,
  currentQuestion,
  questionsCount,
  dontShowFeedback = false,
  setAnswerSelected,
}) => {
  const [value, setValue] = useState<string | null>(null);
  const [showResults, setShowResults] = useState<boolean>(false);
  const onAnswerChange = (event: RadioChangeEvent) => {
    if (showResults) {
      return;
    }
    const answerId = event.target.value;
    setValue(answerId);
    setAnswerSelected(event.target.value);
  };
  const buttonLabel = "Continuar";
  const answerSelected = value;
  const buttonIsDisable = !answerSelected;
  const wasCorrectAnswer = question?.correctAnswer === value;
  const correcWrongClassName = wasCorrectAnswer ? styles.correct : styles.wrong;
  const answer = question?.possibleAnswers.find(
    (answer) => answer._id === value
  );
  const onInnerNext = () => {
    if (dontShowFeedback) {
      onNext();
      return;
    }
    setShowResults(true);
    const method = wasCorrectAnswer ? "success" : "error";
    const text = wasCorrectAnswer
      ? "Respuesta correcta"
      : "Respuesta incorrecta";
    message[method]({
      content: (
        <div className={styles.innerContainer}>
          <div className={styles.title}>{text}</div>
          {answer?.feedback ? (
            <div className={styles.feedback}>{answer?.feedback}</div>
          ) : null}
        </div>
      ),
      className: styles.feedbackToast,
      onClose: () => {
        onNext();
      },
    });
  };
  return (
    <div className={ctx(styles.container, styles.test, className)}>
      <Card
        className={styles.question}
        actions={[
          <div className={styles.buttonContainer}>
            <Button
              disabled={buttonIsDisable || showResults}
              onClick={onInnerNext}
              type="primary"
            >
              {buttonLabel}
            </Button>
          </div>,
        ]}
      >
        <div className={styles.counter}>
          {currentQuestion + 1}/{questionsCount}
        </div>
        <div className={styles.title}>{question?.title}</div>
        <div className={styles.subTitle}>Selecciona una respuesta</div>
        <Radio.Group
          className={styles.answers}
          onChange={onAnswerChange}
          value={value}
        >
          {question?.possibleAnswers.map((answer) => (
            <Radio
              className={ctx(styles.answer, {
                [correcWrongClassName]: showResults && value === answer._id,
              })}
              key={answer._id}
              value={answer._id}
            >
              {answer.label}
            </Radio>
          ))}
        </Radio.Group>
      </Card>
    </div>
  );
};
