import { Button, Card, Radio } from "antd";
import Modal from "antd/lib/modal/Modal";
import { RadioChangeEvent } from "antd/lib/radio";
import ctx from "classnames";
import React, { FunctionComponent, useEffect, useState, forwardRef, useImperativeHandle, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ParentTypeEnum } from "../../../features/evaluation/common/enums";
import {
  IAnswer,
  QuestionsAnswers,
} from "../../../features/evaluation/common/interfaces";
import {
  fetchSurvey,
  registerSurveyResult,
  selectAreSurveysFetching,
  selectSurvey,
} from "../../../features/evaluation/currentSurveySlice";
import styles from "./evaluations.module.scss";
import { analytics } from "../../../services/analytics";
import { selectEvaluationById } from '../../../features/evaluation/evaluationsSlice';
import { selectStudentCourse } from "../../../features/students/courseSlice";
import {
  selectPrograms,
  selectProgramsDict,
} from '../../../features/program/programSlice';
import { getCognitoUserData } from '../../../features/auth/authSlice';
import { selectUser } from '../../../features/users/usersSlice';
import { selectGroup } from "../../../features/groups/groupSlice";
import { selectCompany } from '../../../features/company/companySlice';

interface SurveyProps {
  courseId: string,
  className?: string,
  surveyId: string,
  parentId: string,
  questionToShow?: number,
  onSurveyComplete: Function,
  parentType: ParentTypeEnum,
  onCancelSurvey?: Function,
  setSurveyAnswers?: Function,
}

export const Survey: FunctionComponent<SurveyProps> = ({
  courseId,
  className,
  surveyId,
  parentId,
  questionToShow = 0,
  onSurveyComplete,
  parentType,
  onCancelSurvey = () => { },
  setSurveyAnswers = (answers: IAnswer[]) => { }
}) => {
  const [currentQuestion, setCurrentQuestion] = useState<number>(
    questionToShow
  );
  const [answersSelected, setAnswerSelected] = useState<QuestionsAnswers>({});
  const [feedback, setFeedback] = useState<string | boolean>(false);
  const quiz = useSelector(selectSurvey);
  const loading = useSelector(selectAreSurveysFetching);
  const completeSurveyRef = useRef<AnalyticsCompleteSurveyRef>(null);
  const dispatch = useDispatch();
  useEffect(() => {
    if (parentId && surveyId) {
      dispatch(fetchSurvey(surveyId, parentId));
    }
  }, [dispatch, parentId, surveyId]);
  const question = quiz?.questions[currentQuestion];
  const questionsCount = quiz?.questions.length || 0;
  const onAnswerChange = (event: RadioChangeEvent) => {
    const answerId = event.target.value;
    setAnswerSelected({
      ...answersSelected,
      [question?._id || ""]: {
        answerId,
        correct: question?.correctAnswer === answerId,
      },
    });
  };
  const buttonLabel = feedback
    ? "Continuar"
    : questionsCount - 1 === currentQuestion
      ? "Enviar respuestas"
      : "Siguiente";
  const answerSelected = answersSelected[question?._id || ""];
  const buttonIsDisable = !answerSelected;
  const onNext = () => {
    if (currentQuestion + 1 === questionsCount) {
      const answers: IAnswer[] = [];
      let result = 0;
      Object.keys(answersSelected).forEach((answerSelected) => {
        answers.push({
          answerId: answersSelected[answerSelected].answerId,
          questionId: answerSelected,
        });
        if (answersSelected[answerSelected].correct) {
          ++result;
        }
      });
      setSurveyAnswers(answers);
      completeSurveyRef?.current?.trigger(answers);
      dispatch(
        registerSurveyResult({
          answers,
          evaluationId: surveyId,
          parentId: parentId,
          parentType: parentType,
          result,
          total: Object.keys(answersSelected).length,
          courseId,
        })
      );
      onSurveyComplete();
      return;
    }
    setFeedback(false);
    setCurrentQuestion(currentQuestion + 1);
  };
  return (
    <Modal
      visible
      className={styles.surveyModal}
      centered
      destroyOnClose
      footer={[
        <div className={styles.buttonContainer}>
          <Button disabled={buttonIsDisable} onClick={onNext} type="link">
            {buttonLabel}
          </Button>
        </div>,
      ]}
      onCancel={() => onCancelSurvey()}
    >
      <div className={ctx(styles.container, styles.survey, className)}>
        <Card className={styles.question} loading={loading}>
          <div className={styles.counter}>
            {currentQuestion + 1}/{questionsCount}
          </div>
          <div className={styles.title}>{question?.title}</div>
          <div className={styles.subTitle}>Selecciona una respuesta</div>
          <Radio.Group
            className={styles.answers}
            onChange={onAnswerChange}
            value={answerSelected?.answerId}
          >
            {question?.possibleAnswers.map((answer) => (
              <Radio
                className={styles.answer}
                key={answer._id}
                value={answer._id}
              >
                {answer.label}
              </Radio>
            ))}
          </Radio.Group>
        </Card>
      </div>
      <AnalyticsSelectSurvey ref={completeSurveyRef} surveyId={surveyId} />
    </Modal>
  );
};

type AnalyticsCompleteSurveyRef = {
  trigger: (answers: IAnswer[]) => void,
}

interface AnalyticsCompleteSurveyProps {
  surveyId: string,
}

export const AnalyticsSelectSurvey =
  forwardRef<AnalyticsCompleteSurveyRef, AnalyticsCompleteSurveyProps>(({ surveyId }, ref) => {
    useImperativeHandle(ref, () => ({
      trigger: (answers) => {
        analytics.completeSurvey({
          survey: evaluation,
          course: course,
          program: courseProgram,
          company: company,
          group: group || null,
          answers: answers, // TODO translate answers to human readable instead of ids
        });
      }
    }));
    const evaluation = useSelector(selectEvaluationById(surveyId));
    const course = useSelector(selectStudentCourse);
    const allProgramsIds = useSelector(selectPrograms);
    const programsDict = useSelector(selectProgramsDict);
    const programId = allProgramsIds.filter(programId => {
      const program = programsDict[programId] || {};
      const { courses: programCourses =  [] } = program;
      const foundCourse = programCourses.find(courseId => courseId === course?._id);
      if (foundCourse !== undefined) {
        return true;
      }
      return false;
    })
    const courseProgram = programsDict[programId[0]] || null;
    const cognitoUserData = useSelector(getCognitoUserData);
    const cognitoUsername = cognitoUserData
      ? cognitoUserData["cognito:username"]
      : null;
    const userFromStore = useSelector(selectUser(cognitoUsername));
    const group = useSelector(selectGroup(userFromStore?.studentGroupId || '')) || null;
    const companyId = userFromStore?.company ?? "";
    const company = useSelector(
      selectCompany(companyId)
    );

    return null;
  });
