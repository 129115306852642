import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import cls from "classnames";
import { Header } from "./Header";
import { ContainerWithCarousel } from './ContainerWithCarousel';
import { PasswordInput } from "./PasswordInput";
import { cognizantPasswordContraints } from "../utils/passwordConstraint";
import styles from "./PasswordNew.module.scss";

import {
  Button,
  Form,
  Col,
  Row,
} from 'antd';

import {
  loggedIn,
  markEmailAsVerified,
  cognitoUsername,
  setFirstNewPassword,
  cognitoGivenName,
  cognitoUserEmail,
} from "../../features/auth/authSlice";

import { PasswordRequirements } from "./PasswordRequirements";
import { DialogPopUp } from "../common/DialogPopUp";

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

export function FinishRegistration() {
  const username = useSelector(cognitoUsername);
  const givenName = useSelector(cognitoGivenName);
  const userEmail = useSelector(cognitoUserEmail);
  const [password, setPassword] = useState<string>("");
  const [passwordConfirmation, setPaswrdConfirmation] = useState<string>("");
  const [passwordDontMatchError, setPasswordDontMatchError] = useState<string>(
    ""
  );
  const [isModalVisible, setIsModalVisble] = useState<boolean>(false);
  const [allIsFulfiled, setAllIsFulfiled] = useState<boolean>(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const isLoggedIn = useSelector(loggedIn);

  const handleSubmit = () => {
    if (password !== passwordConfirmation) {
      setPasswordDontMatchError("Contraseñas no son iguales");
      return;
    }
    const { valid, errors } = cognizantPasswordContraints(password);
    if (!valid) {
      errors.forEach((error) => console.log(error));
      return;
    }
    dispatch(markEmailAsVerified(username));
    dispatch(setFirstNewPassword(password));
  };

  useEffect(() => {
    if (isLoggedIn) {
      history.push("/welcome");
    }
    if (!username) {
      history.replace("/login");
    }
  });

  return (
    <React.Fragment>
      <ContainerWithCarousel>
        <DialogPopUp
          name="¡Contraseña"
          action="actualizada!"
          visible={isModalVisible}
          onCancel={() => {
            setIsModalVisble(false);
          }}
        />
        <Form
          {...layout}
          name="basic"
          initialValues={{ remember: true }}
          onFinish={(values: { password: string, confirmation: string }) => {
            handleSubmit();
          }}
          onFinishFailed={() => { console.error('on finish failed') }}
        >
          <Col span={24} className={cls(styles.innerContainer)}>
            <Row>
              <Col span={18} offset={3}>
                <Header
                  titleClassname={cls(styles.headerTitle)}
                  subTitleClassname={cls(styles.headerSubTitle)}
                  title={`¡Hola, ${givenName}!`}
                >
                  <div className={styles.welcomeMessage}>
                    <div>{userEmail}</div>
                    <div>Crea una contraseña para ingresar al portal</div>
                  </div>
                </Header>
                <Form.Item
                  label="Contraseña"
                  name="password"
                  rules={[{ required: true, message: 'Por favor ingrese la contraseña!' }]}
                  labelCol={{ span: 24 }}
                  wrapperCol={{ span: 24 }}
                >
                  <PasswordInput onChange={setPassword} />
                </Form.Item>
                <Form.Item
                  label="Repetir contraseña"
                  name="confirmation"
                  rules={[{ required: true, message: 'La confirmación es requerida' }]}
                  labelCol={{ span: 24 }}
                  wrapperCol={{ span: 24 }}
                >
                  <PasswordInput onChange={setPaswrdConfirmation} showMeter={false} />
                </Form.Item>
                <div className={cls(styles.errorMsg, 'error')}>{passwordDontMatchError}</div>
                <PasswordRequirements
                  password={password}
                  onAllFulfiledChange={(allIsFulfiled: boolean) => {
                    setAllIsFulfiled(allIsFulfiled);
                  }}
                />
                <Button
                  disabled={!allIsFulfiled || passwordConfirmation.length === 0}
                  className={cls(styles.resetBtn)}
                  type="default"
                  htmlType="submit"
                >
                  Establecer contraseña
                </Button>
              </Col>
            </Row>
          </Col>
        </Form>
      </ContainerWithCarousel>
    </React.Fragment>
  );
}
