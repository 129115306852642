import { IWithShortId } from './../common/shortId';
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Dispatch } from "react";
import { Microservices } from "../../app/AllowedMicroservices";
import { AppThunk, RootState } from "../../app/store";
import { StatusEnum } from "../../components/common/Status";
import apiActionCreator, { HttpMethods } from "../../services/apiActionCreator";
import { MODAL_DELAY_MS } from "../../utils/constants";
import { FetchStatus, ModalStatus } from "../common/enums";
import { buildShortIds } from '../common/shortId';
import { setMessageError, setMessageInfo } from "../message/messageSlice";

export interface IBlueprint extends IWithShortId {
  _id: string;
  blueprintName: string;
  blueprintId: string;
  showName: boolean;
  showDescription: boolean;
  showTotalHours: boolean;
  showTraineeFullName: boolean;
  showTraineeNationalId: boolean;
  showCompanyName: boolean;
  showNameAndLogo: boolean;
  showUniqueCode: boolean;
  showQrCode: boolean;
  createdAt?: Date;
  updateddAt?: Date;
  status: StatusEnum;
}

interface IBlueprintDict {
  [_id: string]: IBlueprint;
}

interface BlueprintState {
  list: string[];
  dict: IBlueprintDict;
  status: FetchStatus;
  modalStatus: ModalStatus;
}

const initialState: BlueprintState = {
  list: [],
  dict: {},
  status: FetchStatus.Idle,
  modalStatus: ModalStatus.Hide,
};

interface BlueprintFetch {
  docs: IBlueprint[];
  limit: number;
  page: number;
  pages: number;
  total: number;
}

export const blueprintSlice = createSlice({
  name: "blueprints",
  initialState,
  reducers: {
    creating: (state) => {
      state.status = FetchStatus.Creating;
    },
    updating: (state) => {
      state.status = FetchStatus.Creating;
    },
    fetching: (state) => {
      state.status = FetchStatus.Fetching;
    },
    fetchedBlueprints: (state, action: PayloadAction<BlueprintFetch>) => {
      action.payload.docs = buildShortIds(action.payload.docs) as IBlueprint[];
      state.list = action.payload.docs.map((blueprint) => blueprint._id);
      state.dict = {};
      action.payload.docs.forEach((blueprint) => {
        state.dict[blueprint._id] = blueprint;
      });
      state.status = FetchStatus.Fetched;
    },
    fetchedBluePrint: (state, action: PayloadAction<IBlueprint>) => {
      if (!state.list.includes(action.payload._id)) {
        state.list.push(action.payload._id);
      }
      state.dict[action.payload._id] = action.payload;
      state.status = FetchStatus.Fetched;
    },
    error: (state, action: PayloadAction<BlueprintFetch>) => {
      state.list = [];
      state.status = FetchStatus.Error;
    },
    showModal: (state) => {
      state.modalStatus = ModalStatus.Show;
    },
    hideModal: (state) => {
      state.modalStatus = ModalStatus.Hide;
    },
  },
});

export const {
  fetching,
  fetchedBlueprints,
  fetchedBluePrint,
  error,
  creating,
  updating,
  showModal,
  hideModal,
} = blueprintSlice.actions;

const BASE_ENDPOINT = "/blueprints";

export const createBlueprint = (newBlueprint: IBlueprint) => (
  dispatch: Dispatch<object>
) => {
  return dispatch(
    apiActionCreator({
      endpoint: BASE_ENDPOINT,
      types: {
        requestType: creating,
        successTypes: [
          {
            actionOrCreator: fetchedBluePrint,
          },
          {
            actionOrCreator: showModal,
            selector: () => {
              dispatch(hideModalWithDelay());
            },
          },
        ],
        errorTypes: [
          {
            actionOrCreator: error,
          },
        ],
      },
      method: HttpMethods.POST,
      microservice: Microservices.LEARNING_SERVICE,
      authenticated: true,
      data: newBlueprint,
    })
  );
};

export const updateBlueprint = (blueprint: IBlueprint) => (
  dispatch: Dispatch<object>
) => {
  return dispatch(
    apiActionCreator({
      endpoint: `${BASE_ENDPOINT}/${blueprint._id}`,
      types: {
        requestType: creating,
        successTypes: [
          {
            actionOrCreator: fetchBlueprints,
          },
          {
            actionOrCreator: showModal,
            selector: () => {
              dispatch(hideModalWithDelay());
            },
          },
        ],
        errorTypes: [
          {
            actionOrCreator: error,
            selector: (response: any) => {
              const message = `Error desconocido`;
              dispatch(setMessageError({ message }));
            },
          },
        ],
      },
      method: HttpMethods.PUT,
      microservice: Microservices.LEARNING_SERVICE,
      authenticated: true,
      data: blueprint,
    })
  );
};

const hideModalWithDelay = (): AppThunk => async (dispatch) => {
  return new Promise((resolve) => {
    setTimeout(() => {
      dispatch(hideModal());
      resolve();
    }, MODAL_DELAY_MS);
  });
};

export const fetchBlueprints = () => (dispatch: Dispatch<object>) => {
  return dispatch(
    apiActionCreator({
      endpoint: `${BASE_ENDPOINT}?limit=1000000`,
      types: {
        requestType: fetching,
        successTypes: [
          {
            actionOrCreator: fetchedBlueprints,
          },
        ],
        errorTypes: [
          {
            actionOrCreator: error,
          },
        ],
      },
      method: HttpMethods.GET,
      microservice: Microservices.LEARNING_SERVICE,
      authenticated: true,
    })
  );
};

interface BlueprintUpdateStatusData {
  ids: string[];
}

export const enableBlueprintsStatus = (
  blueprintIds: BlueprintUpdateStatusData
) => (dispatch: Dispatch<object>) => {
  return dispatch(
    apiActionCreator({
      endpoint: `${BASE_ENDPOINT}/enable`,
      types: {
        requestType: updating,
        successTypes: [
          {
            actionOrCreator: fetchBlueprints,
          },
          {
            actionOrCreator: setMessageInfo({
              message: "Certificado(s) activado(s)",
            }),
          },
        ],
        errorTypes: [
          {
            actionOrCreator: error,
            selector: (response: any) => {
              dispatch(setMessageError({ message: "Falló la activación" }));
            },
          },
          {
            actionOrCreator: fetchBlueprints,
          },
        ],
      },
      method: HttpMethods.PUT,
      microservice: Microservices.LEARNING_SERVICE,
      authenticated: true,
      data: blueprintIds,
    })
  );
};

export const disableBlueprintsStatus = (
  blueprintIds: BlueprintUpdateStatusData
) => (dispatch: Dispatch<object>) => {
  return dispatch(
    apiActionCreator({
      endpoint: `${BASE_ENDPOINT}/disable`,
      types: {
        requestType: updating,
        successTypes: [
          {
            actionOrCreator: fetchBlueprints,
          },
          {
            actionOrCreator: setMessageInfo({
              message: "Certificado(s) desactivado(s)",
            }),
          },
        ],
        errorTypes: [
          {
            actionOrCreator: fetchBlueprints,
          },
          {
            actionOrCreator: error,
            selector: (_response: any) => {
              setMessageError({ message: "Falló la desactivación" });
            },
          },
        ],
      },
      method: HttpMethods.PUT,
      microservice: Microservices.LEARNING_SERVICE,
      authenticated: true,
      data: blueprintIds,
    })
  );
};

export const selectBlueprintsIds = (state: RootState) => state.blueprints.list;
export const selectBlueprintsDict = (state: RootState) => state.blueprints.dict;
export const selectAreBlueprintsFetching = (state: RootState) =>
  state.blueprints.status === FetchStatus.Fetching;
export const selectDisplayModal = (state: RootState) =>
  state.blueprints.modalStatus === ModalStatus.Show;

export default blueprintSlice.reducer;
