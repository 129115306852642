import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Dispatch } from "react";
import { Microservices } from "../../../../app/AllowedMicroservices";
import { RootState } from "../../../../app/store";
import apiActionCreator, {
  HttpMethods,
} from "../../../../services/apiActionCreator";
import { FetchStatus } from "../../../common/enums";
import { IFulfillmentPerCompany } from '../common/interfaces';


interface IFulfillmentPerCompanyState {
  lessonFulfillmentPerCompany: IFulfillmentPerCompany,
  status: FetchStatus,
}
const initialState: IFulfillmentPerCompanyState = {
  lessonFulfillmentPerCompany: {},
  status: FetchStatus.Idle,
};

export const dataSlice = createSlice({
  name: "reportLearningLessonsFulfillmentPerCompany",
  initialState,
  reducers: {
    fetching: (state) => {
      state.status = FetchStatus.Fetching;
    },
    fetched: (state, action: PayloadAction<IFulfillmentPerCompany>) => {
      state.lessonFulfillmentPerCompany = action.payload;
      state.status = FetchStatus.Fetched;
    },
    error: (state) => {
      state.status = FetchStatus.Error;
    },
  },
});

export const { fetching, fetched, error } = dataSlice.actions;

export const fetchLessonsCompletionPerCompany = (companyId?: string) => (dispatch: Dispatch<object>) => {
  let url = '/lessons/completionPerCompany';
  if(companyId){
    url = `${url}/${companyId}`;
  }
  return dispatch(
    apiActionCreator({
      endpoint: url,
      types: {
        requestType: fetching,
        successTypes: [
          {
            actionOrCreator: fetched,
          },
        ],
        errorTypes: [
          {
            actionOrCreator: error,
          },
        ],
      },
      method: HttpMethods.GET,
      microservice: Microservices.LEARNING_SERVICE,
      authenticated: true,
    })
  );
};

export const selectLessonsFulfillmentsPerCompany = (state: RootState) => state.reports.learning.lessons.lessonsFulfillmentsPerCompany.lessonFulfillmentPerCompany;
export const selectLessonsFulfillmentsPerCompanyFetching = (state: RootState) => state.reports.learning.lessons.lessonsFulfillmentsPerCompany.status === FetchStatus.Fetching;

export default dataSlice.reducer;
