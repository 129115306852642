import React, { useState, FunctionComponent, useEffect } from 'react';
import { Form, Col, Row, Button, Input } from 'antd';
import cls from 'classnames';

import { Header } from '../Header';
import { Footer } from '../Footer';

import styles from './index.module.scss';
import commonStyles from '../Login.module.scss';
import { ISignUpStepProps, layout } from './interfaces';
import { BASE_URL } from '../../../app/apiMiddleware';
import { Microservices } from '../../../app/AllowedMicroservices';

const {
  loadCaptchaEnginge,
  LoadCanvasTemplate,
  validateCaptcha,
} = require('react-simple-captcha');

interface Step1Props extends ISignUpStepProps {

};

export const Step1: FunctionComponent<Step1Props> = ({ next, userData }) => {
  const [email, setEmail] = useState<string>('');
  const [captcha, setCaptcha] = useState<string>('');
  const [captchaError, setCaptchaError] = useState<string>('');
  const [emailError, setEmailError] = useState<string>('');
  useEffect(() => {
    loadCaptchaEnginge(6);
    setEmail(userData.email || '');
    setEmail(userData.email || '');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleSubmit = async () => {
    setEmailError('');
    if(!validateCaptcha(captcha)){
      setCaptchaError('Valor incorrecto');
      setCaptcha('');
      loadCaptchaEnginge(6); 
      return;
    }
    try{
      const response = await fetch(`${BASE_URL[Microservices.COMPANY_SERVICE]}/user/verifyEmail`, {
        method: 'POST', headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email }),
      });
      if (!response.ok) {
        loadCaptchaEnginge(6); 
        setEmailError('Error en el registro, intente más tarde');
        setCaptcha('');
        setCaptchaError('');
      return;
      }
    }catch(e){
      loadCaptchaEnginge(6); 
      setEmailError('Error en el registro, intente más tarde');
      setCaptcha('');
      setCaptchaError('');
      return;
    }
    console.log('🚀 ~ file: Step1.tsx ~ line 24 ~ email', email);
    setEmailError('');
    setCaptchaError('');
    setCaptcha('');
    next({ email });
  };

  return (
    <Form
      {...layout}
      name="basic"
      onFinish={(values: {
        password: string;
        confirmation: string;
        email: string;
      }) => {
        handleSubmit();
      }}
      onFinishFailed={() => {
        console.log('signup failed');
      }}
      
    >
      <Col
        span={24}
        className={cls(commonStyles.formInnerContainer, commonStyles.overload)}
      >
        <Row>
          <Col span={18} offset={3}>
            <Header title={'¡Bienvenido!'}>
              <div className={styles.subTitles}>
                Introduce tus credenciales para crear tu cuenta.
              </div>
            </Header>
            <Form.Item
              label="Email"
              name="email"
              rules={[{ required: true, message: 'email@compania.com' }]}
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              initialValue={email}
            >
              <Input
                onChange={(event) => {
                  setEmail(event.target.value);
                }}
              />
            </Form.Item>
            {emailError ? <div className={styles.captchaError}>{emailError}</div> : <div className={styles.captchaError}>&nbsp;</div>}
            <div className={styles.captchaContainer}>
              <div className={styles.captchaTitle}>
                Escribe abajo lo que ves en la imagen
              </div>
              <div className={styles.captchaImage}>
                <LoadCanvasTemplate reloadText="Recargar" />
              </div>
              <div className={styles.captchaInput}>
                <Input
                  value={captcha}
                  onChange={(event) => {
                    setCaptcha(event.target.value);
                  }}
                />
                {captchaError ? <div className={styles.captchaError}>{captchaError}</div> : <div className={styles.captchaError}>&nbsp;</div>}
              </div>
            </div>
            <Button
              type="primary"
              htmlType="submit"
              disabled={false}
              className={cls(commonStyles.signupBtn, styles.signupBtn)}
            >
              Registrarse
            </Button>
          </Col>
        </Row>
        <Footer
          className={cls()}
          title={'¿Ya tienes cuenta?'}
          linkLabel={'Ingresa Aqui'}
          linkRef={'/login'}
        />
      </Col>
    </Form>
  );
};
