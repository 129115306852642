import { Form, Input, Select } from "antd";
import { FormInstance } from "antd/lib/form";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchCompanies,
  selectCompany,
} from "../../features/company/companySlice";
import { selectCoursesDict } from "../../features/courses/coursesSlice";
import { IGroup } from "../../features/groups/groupSlice";
import { selectProgramsDict } from "../../features/program/programSlice";
import { CompanyField } from "../common/CompanyField";
import formStyles from "../common/form.module.scss";
import { MIN_3_CHAR } from "../Users/Form";

interface GroupFormProps {
  newGroup?: IGroup;
  setNewGroup: Function;
  form: FormInstance;
}

export const GroupForm: React.FunctionComponent<GroupFormProps> = (props) => {
  const { setNewGroup, newGroup, form } = props;
  const programs = useSelector(selectProgramsDict);
  const courses = useSelector(selectCoursesDict);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchCompanies());
  }, [dispatch]);
  const company = useSelector(selectCompany(newGroup?.company || null));
  let coursesAvailable: string[] = [];
  if (company) {
    const program = programs[company.studyProgram];
    if (program) {
      coursesAvailable = program.courses;
    }
  }
  const coursesSelectorIsDisabled = coursesAvailable.length === 0;
  return (
    <Form
      form={form}
      className={formStyles.form}
      layout="vertical"
      name="control-hooks"
    >
      <Form.Item
        initialValue={newGroup?.name}
        name="name"
        label="Nombre del grupo"
        rules={[
          { required: true, message: "Requerido" },
          { type: "string", min: 3, message: MIN_3_CHAR },
          { type: "string", max: 50, message: "Máximo 50 caracteres" },
        ]}
      >
        <Input
          onChange={(event) => {
            setNewGroup({ ...newGroup, name: event.target.value });
          }}
          type="text"
          placeholder="Nombre del grupo"
        />
      </Form.Item>
      <CompanyField
        newObject={newGroup}
        value={newGroup?.company}
        setNewObject={(newGroup: IGroup) => {
          setNewGroup({ ...newGroup, courses: [] });
          form.setFieldsValue({
            courses: [],
          });
        }}
      />
      <Form.Item
        initialValue={newGroup?.courses}
        name="courses"
        label="Cursos"
        rules={[{ required: true, message: "Requerido" }]}
      >
        <Select
          disabled={coursesSelectorIsDisabled}
          mode="multiple"
          placeholder={
            coursesSelectorIsDisabled
              ? "La empresa tiene cursos disponibles"
              : "Selecciona los cursos del programa de estudio"
          }
          style={{ width: "100%" }}
          value={newGroup?.courses}
          onChange={(value) => {
            setNewGroup({ ...newGroup, courses: value });
          }}
        >
          {coursesAvailable.map((id) => {
            return (
              <Select.Option key={id} value={id}>
                {courses[id].title}
              </Select.Option>
            );
          })}
        </Select>
      </Form.Item>
    </Form>
  );
};
