import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Dispatch } from "react";
import { Microservices } from "../../app/AllowedMicroservices";
import { RootState } from "../../app/store";
import apiActionCreator, { HttpMethods } from "../../services/apiActionCreator";
import { FetchStatus, ModalStatus } from "../common/enums";

interface CertificateHashState {
  hashes: { [_id: string]: string };
  status: FetchStatus;
  modalStatus: ModalStatus;
}

const initialState: CertificateHashState = {
  hashes: {},
  status: FetchStatus.Idle,
  modalStatus: ModalStatus.Hide,
};

interface CertificateHashFetch {
  hash: string;
  templateId: string;
}

export const certificateHashSlice = createSlice({
  name: "certificateHashSlice",
  initialState,
  reducers: {
    fetching: (state, action: PayloadAction<string>) => {
      state.status = FetchStatus.Fetching;
      state.hashes[action.payload] = "";
    },
    fetched: (state, action: PayloadAction<CertificateHashFetch>) => {
      state.hashes[action.payload.templateId] = action.payload.hash;
      state.status = FetchStatus.Fetched;
    },
    error: (state, action: PayloadAction<string>) => {
      state.hashes[action.payload] = "";
      state.status = FetchStatus.Error;
    },
  },
});

const BASE_ENDPOINT = "/certificate";
const { fetching, fetched, error } = certificateHashSlice.actions;

export const fetchHashByTemplateId = (certificateId: string) => (
  dispatch: Dispatch<object>
) => {
  return dispatch(
    apiActionCreator({
      endpoint: `${BASE_ENDPOINT}/hashByTemplateId/${certificateId}`,
      types: {
        requestType: {
          type: fetching.type,
          payload: certificateId,
        } as any,
        successTypes: [
          {
            actionOrCreator: fetched,
          },
        ],
        errorTypes: [
          {
            actionOrCreator: error,
            selector: () => ({
              payload: certificateId,
            }),
          },
        ],
      },
      method: HttpMethods.GET,
      microservice: Microservices.EVALUATION_SERVICE,
      authenticated: true,
    })
  );
};

export const selectCertificateHash = (id: string) => (state: RootState) =>
  state.certificateHash.hashes[id];
export const selectIsCertificateHashFetching = (state: RootState) =>
  state.certificateHash.status === FetchStatus.Fetching;

export default certificateHashSlice.reducer;
