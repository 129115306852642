import React, { FunctionComponent } from 'react';
import moment from 'moment';

import { CogIcon } from '../../../common/CogIcon';

import styles from './navigator.module.scss';

interface NavigatorProps {
  currentDate: moment.Moment,
  onChangeCurrentDate: (date:moment.Moment) => void,
}

export const Navigator: FunctionComponent<NavigatorProps> = ({currentDate, onChangeCurrentDate}) => {
  const onPrev = () => {
    let date = currentDate.clone().subtract(1, 'M');
    onChangeCurrentDate(date);
  };
  const onNext = () => {
    let date = currentDate.clone().add(1, 'M');
    onChangeCurrentDate(date);
  };
  const shortName = currentDate.format('MMM').replace(new RegExp("[.]+$"), "");
  const name = currentDate.format('MMMM');
  return <div className={styles.navigator}>
    <div className={styles.monthAndYear}><span className={styles.month} title={name} >{shortName}</span> {currentDate.format('YYYY')}</div>
    <div className={styles.controls}>
      <span className={styles.button} onClick={onPrev}><CogIcon className={styles.icon} icon="Arrow-left-big"/></span>
      <span className={styles.button} onClick={onNext}><CogIcon className={styles.icon} icon="Arrow-right-big"/></span>
  </div>
  </div>;
}