import {
  Alert,
  AutoComplete,
  Button,
  Card,
  Divider,
  Dropdown,
  Form,
  Input,
  InputNumber,
  Menu,
  Popconfirm,
  Select,
} from "antd";
import { SizeType } from "antd/lib/config-provider/SizeContext";
import { FormInstance } from "antd/lib/form";
import cls from "classnames";
import React, { FunctionComponent, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Microservices } from "../../app/AllowedMicroservices";
import { BASE_URL } from "../../app/apiMiddleware";
import { selectCompanyId, selectUserRole } from '../../features/auth/authSlice';
import {
  fetchBlueprints,
  IBlueprint,
  selectBlueprintsDict,
  selectBlueprintsIds,
} from "../../features/blueprint/blueprintSlice";
import { setBreadcrumbs } from "../../features/breadcrumbs/breadcrumbsSlice";
import {
  ICategoriesDict,
  ICategory,
} from "../../features/category/categorySlice";
import { selectCompanies } from '../../features/company/companySlice';
import {
  createCourse,
  ICourse,
  selectAreCoursesFetching,
  selectCourse,
  updateCourse,
} from "../../features/courses/coursesSlice";
import { EvaluationTypeEnum } from "../../features/evaluation/common/enums";
import { IEvaluation } from "../../features/evaluation/common/interfaces";
import {
  selectEvaluationsDict,
  selectExamns,
  selectSurveys,
} from "../../features/evaluation/evaluationsSlice";
import { fetchLessons } from "../../features/lesson/lessonSlice";
import {
  fetchTopics,
  ITopic,
  selectTopics,
  selectTopicsIds,
} from "../../features/topic/topicSlice";
import { UserRole } from '../../features/users/usersSlice';
import { DUMMY_DATA } from "../Blueprint/BlueprintForm";
import Template1, { TemplateProps } from "../Certificates/Template1";
import Template2 from "../Certificates/Template2";
import Template3 from "../Certificates/Template3";
import Template4 from "../Certificates/Template4";
import { CategoryField, OTHER_CATEGORY_OPTION } from "../common/CategoryField";
import { CogIcon } from "../common/CogIcon";
import ConfirmationModal from "../common/ConfirmationModal";
import EvaluationField from "../common/EvaluationField";
import commonStyles from "../common/form.module.scss";
import LoadingOverlay from "../common/LoadingOverlay";
import { StatusEnum } from "../common/Status";
import { StepIndicator } from "../common/StepIndicator";
import { MIN_3_CHAR } from "../Users/Form";
import { UploadFile } from "../Users/UploadFile";
import styles from "./form.module.scss";
import { TopicsSelected } from "./TopicsSelected";
interface StepProps {
  stepIndicator: React.ReactNode;
  setCourse: Function;
  form: FormInstance;
  categories: ICategoriesDict;
  categoriesIds: string[];
  course?: ICourse;
  validateForm?: Function;
}
const STEPS_COUNT = 4;
const MAX_TAGS = 10;
const MAX_TAG_CHAR_COUNT = 20;

const FirstStep: FunctionComponent<StepProps> = ({
  stepIndicator,
  course,
  setCourse,
  categories,
  categoriesIds,
}) => {
  const [error, setError] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const companies = useSelector(selectCompanies);
  const userRole = useSelector(selectUserRole);
  const handleTagsChange = (value: string[]) => {
    if (value.length <= MAX_TAGS) {
      setCourse({ ...course, tags: [...value] });
    }
  };
  const handleCompanyChange = (value: string) => {
    setCourse({ ...course, companyId: value });
  };

  const handleChangeFile = (info: any) => {
    const { status } = info.file;
    if (status === "uploading") {
      setLoading(true);
      setError("");
      return;
    }
    if (status === "done") {
      console.log("handleChangeFile -> info", info);
      setLoading(false);
      setCourse({ ...course, imageurl: info.file.response.url });
    } else if (status === "error") {
      setError("Error al subir imagen");
    }
  };
  return (
    <React.Fragment>
      <Card className={styles.card}>
        {stepIndicator}
        <div className={styles.title}>Información básica</div>
        <div className={styles.subTitle}>Ingresa la información del curso</div>
      </Card>
      <Card className={styles.card}>
        <UploadFile
          onHandleChange={handleChangeFile}
          loading={loading}
          uploadUrl={`${
            BASE_URL[Microservices.LEARNING_SERVICE]
          }/media/uploadToS3`}
          fileTypesAllowed={["image/jpeg", "image/png", "image/gif"]}
          fileNotAllowedMessage="¡Solo se puede subir imagenes jpg, png o gif!"
          imageUrlToShow={course?.imageurl}
        />
        {error}
        <Form.Item
          name="title"
          label="Nombre del curso"
          initialValue={course?.title || ""}
          rules={[
            { type: "string", required: true, message: "Requerido" },
            { type: "string", min: 3, message: MIN_3_CHAR },
            {
              type: "string",
              max: 200,
              message: "No puede tener más de 200 caracteres",
            },
          ]}
        >
          <Input
            onChange={(event) => {
              setCourse({ ...course, title: event.target.value });
            }}
          />
        </Form.Item>
        <CategoryField
          categories={categories}
          categoriesIds={categoriesIds}
          markRequired
          label="Categoría del curso"
          object={course}
          setObject={setCourse}
        />
        <Form.Item
          label="Descripción"
          name="description"
          initialValue={course?.description || ""}
          rules={[
            { type: "string", required: true, message: "Requerido" },
            { type: "string", min: 3, message: MIN_3_CHAR },
            {
              type: "string",
              max: 2000,
              message: "No puede tener más de 2000 caracteres",
            },
          ]}
        >
          <Input.TextArea
            onChange={(event) => {
              setCourse({ ...course, description: event.target.value });
            }}
          />
        </Form.Item>
        <Form.Item
          label="Horas de estudio"
          name="durationHour"
          initialValue={course?.durationHour || ""}
          rules={[
            { type: "number", required: true, message: "Requerido" },
            { type: "number", min: 1, message: "No puede ser menor a 1" },
            { type: "number", max: 2000, message: "No puede ser mayor a 2000" },
          ]}
        >
          <InputNumber
            onChange={(value) => {
              setCourse({ ...course, durationHour: value });
            }}
          />
        </Form.Item>
        <Form.Item
          label="Tags"
          name="tags"
          initialValue={course?.tags || []}
          rules={[
            { required: true, message: "Requerido" },
            {
              validator: (_rule, value: any) => {
                if (value) {
                  const excedsMaxCharCount = value.find(
                    (element: string) => element.length > MAX_TAG_CHAR_COUNT
                  );
                  return new Promise((resolve, reject) => {
                    if (value.length > MAX_TAGS) {
                      value.pop();
                      reject(`Máximo ${MAX_TAGS} tags`);
                      return;
                    } else if (excedsMaxCharCount) {
                      value.pop();
                      reject(`Máximo ${MAX_TAG_CHAR_COUNT} caracteres por tag`);
                      return;
                    }
                    resolve(0);
                  });
                }
              },
            },
          ]}
        >
          <Select
            mode="tags"
            size={"default" as SizeType}
            placeholder=""
            onChange={handleTagsChange}
            style={{ width: "100%" }}
            maxTagCount={MAX_TAGS}
            maxTagTextLength={20}
            tokenSeparators={[" ", ","]}
          ></Select>
        </Form.Item>
        {userRole === UserRole.SuperAdmin ? <Form.Item
          label="Compañia"
          name="company"
          initialValue={course?.companyId || ''}
        >
          <Select
            placeholder=""
            onChange={handleCompanyChange}
            style={{ width: "100%" }}
            disabled={course?.topics && course?.topics.length > 0}
          >
            <Select.Option key={'all_companies'} value={''}>
                Todas
              </Select.Option>
            {Object.values(companies).map(company => {
              return <Select.Option key={company._id} value={company._id}>
                {company.name}
              </Select.Option>
            })}
          </Select>
        </Form.Item>: null}
      </Card>
    </React.Fragment>
  );
};
const SecondStep: FunctionComponent<StepProps> = ({
  stepIndicator,
  course,
  setCourse,
}) => {
  return (
    <React.Fragment>
      <Card className={styles.card}>
        {stepIndicator}
        <div className={styles.title}>Guía de estudios</div>
        <div className={styles.suTitle}>
          Añade información adicional sobre el curso
        </div>
      </Card>
      <Card className={styles.card}>
        <Form.Item
          label="Objetivo del curso"
          name="objectives"
          initialValue={course?.objectives || ""}
          rules={[
            { type: "string", required: true, message: "Requerido" },
            { type: "string", min: 3, message: MIN_3_CHAR },
            {
              type: "string",
              max: 2000,
              message: "No puede tener más de 2000 caracteres",
            },
          ]}
        >
          <Input.TextArea
            onChange={(event) => {
              setCourse({ ...course, objectives: event.target.value });
            }}
          />
        </Form.Item>
        <Form.Item
          label="Sistema de evaluación"
          name="evaluationSystem"
          initialValue={course?.evaluationSystem || ""}
          rules={[
            { type: "string", required: true, message: "Requerido" },
            { type: "string", min: 3, message: MIN_3_CHAR },
            {
              type: "string",
              max: 2000,
              message: "No puede tener más de 2000 caracteres",
            },
          ]}
        >
          <Input.TextArea
            onChange={(event) => {
              setCourse({ ...course, evaluationSystem: event.target.value });
            }}
          />
        </Form.Item>
        <Form.Item
          label="Información adicional"
          name="additionalInfo"
          initialValue={course?.additionalInfo || ""}
          rules={[
            { type: "string", required: true, message: "Requerido" },
            { type: "string", min: 3, message: MIN_3_CHAR },
            {
              type: "string",
              max: 2000,
              message: "No puede tener más de 2000 caracteres",
            },
          ]}
        >
          <Input.TextArea
            onChange={(event) => {
              setCourse({ ...course, additionalInfo: event.target.value });
            }}
          />
        </Form.Item>
      </Card>
    </React.Fragment>
  );
};

const ThirdStep: FunctionComponent<StepProps> = ({
  stepIndicator,
  course,
  setCourse,
}) => {
  const dispatch = useDispatch();
  const topicsIds = useSelector(selectTopicsIds);
  const topics = useSelector(selectTopics);
  const userRole = useSelector(selectUserRole);
  const companyId = useSelector(selectCompanyId);
  useEffect(() => {
    dispatch(fetchTopics());
    dispatch(fetchLessons());
  }, [dispatch]);
  const topicByTitleComparator = (a: ITopic, b: ITopic) => {
    const aName = a.title.toLowerCase();
    const bName = b.title.toLowerCase();

    if (aName < bName) {
      return -1;
    }
    if (aName > bName) {
      return 1;
    }
    return 0;
  };
  const selectedTopics = course && course.topics ? course.topics : [];
  const menuItems = topicsIds
    .filter((topicId) => {
      const noIncludedInSelectecTopic = !selectedTopics.includes(topicId);
      const isSuperAdmin = userRole === UserRole.SuperAdmin;
      const courseCompanyId = isSuperAdmin ? (!course?.companyId ? undefined : course?.companyId) : companyId;
      const topicFromCompany = topics[topicId].companyId === courseCompanyId;
      return noIncludedInSelectecTopic && topicFromCompany;
    })
    .map((topicId) => topics[topicId])
    .sort(topicByTitleComparator)
    .map((topic) => {
      if (!topic || topic.status === StatusEnum.Disable) {
        return null;
      }
      return (
        <Menu.Item
          key={topic._id}
          onClick={() => {
            setCourse({ ...course, topics: [...selectedTopics, topic._id] });
          }}
        >
          {topic.title}
        </Menu.Item>
      );
    });
  const hasTopicsAvailable = menuItems.length > 0;
  const menu = (
    <Card>
      <Menu
        onClick={({ key }) => {
          if(menuItems.length === 0){
            return;
          }
          setCourse({ ...course, topics: [...selectedTopics, key] });
        }}
      >
        {menuItems}
      </Menu>
    </Card>
  );
  return (
    <React.Fragment>
      <Card className={styles.card}>
        {stepIndicator}
        <div className={styles.title}>Asigna temas</div>
        <div className={styles.subTitle}>
          Selecciona los temas para el curso
        </div>
      </Card>
      <TopicsSelected
        selectedTopics={selectedTopics}
        setCourse={setCourse}
        course={course}
      />
      {hasTopicsAvailable ? <Dropdown
        overlay={menu}
        overlayClassName={styles.topicMenu}
        disabled={menuItems.length === 0}
        trigger={["click"]}
      >
        <Button
          disabled={menuItems.length === 0}
          className={styles.addTopicButton}
          type="default"
        >
          <CogIcon className={styles.square} color="white" icon="Plus" />
          Añadir tema
        </Button>
      </Dropdown> : <Alert message="Termina de configurar el curso y luego crea temas en la sección correspondiente" type="info"/>}
    </React.Fragment>
  );
};

const FourthStep: FunctionComponent<StepProps> = ({
  stepIndicator,
  setCourse,
  course,
  validateForm = () =>
    new Promise((resolve, reject) => {
      resolve(true);
    }),
  form,
}) => {
  const evaluationsDict = useSelector(selectEvaluationsDict);
  const [isCreatingOrUpdating, setIsCreatingOrUpdating] =
    useState<boolean>(true);
  const [disableAssignBtn, setDisableAssignBtn] = useState<boolean>(true);
  const handleExamUpdate = (evaluation: IEvaluation) => {
    setIsCreatingOrUpdating(true);
    setCourse({ ...course, evaluation: evaluation._id });
  };
  const handleExamDelete = () =>
    setCourse({ ...course, evaluation: undefined });
  const handleAcceptChange = () => {
    validateForm()
      .then(() => {
        setIsCreatingOrUpdating(false);
      })
      .catch(() => {
        console.error("Courses FourthStep Form error");
      });
  };
  useEffect(() => {
    if (course?._id !== undefined && course.evaluation !== undefined) {
      setIsCreatingOrUpdating(false);
      setDisableAssignBtn(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchBlueprints());
  }, [dispatch]);
  const blueprintsIdsList = useSelector(selectBlueprintsIds);
  const blueprintDict = useSelector(selectBlueprintsDict);
  const blueprintsList = blueprintsIdsList
    .map((blueprintId: string) => ({
      ...blueprintDict[blueprintId],
    }))
    .filter((blueprint) => blueprint.status === StatusEnum.Enable);
  let courseTemplateId = course?.certificate ?? "";

  const handleCertificateUpdate = (blueprint: IBlueprint) => {
    setCourse({ ...course, certificate: blueprint._id });
  };

  const handleCertificateDelete = () =>
    setCourse({ ...course, certificate: undefined });

  const handleQuizUpdate = (evaluation: IEvaluation) =>
    setCourse({ ...course, survey: evaluation._id });
  const handleSurveyDelete = () => setCourse({ ...course, survey: undefined });
  const dict = useSelector(selectEvaluationsDict);

  const TEMPLATES: Record<string, React.FunctionComponent<TemplateProps>> = {
    template1: Template1,
    template2: Template2,
    template3: Template3,
    template4: Template4,
  };

  const SelectedTemplate =
    course?.certificate && blueprintDict
      ? TEMPLATES[blueprintDict[course.certificate]?.blueprintId]
      : undefined;

  const renderItem = (blueprint: IBlueprint) => {
    const CurrentTemplate = TEMPLATES[blueprint.blueprintId];
    return {
      value: blueprint.blueprintName,
      label: (
        <div
          className={styles.blueprintItemWrapper}
          onClick={() => handleCertificateUpdate(blueprint)}
        >
          <div className={styles.blueprintItem}>
            <CurrentTemplate
              baseFontSizePx={3}
              {...blueprint}
              {...DUMMY_DATA}
            />
          </div>
          <p>{blueprint.blueprintName}</p>
        </div>
      ),
    };
  };

  const unique: IBlueprint[] = Array.from(
    new Set(blueprintsList.map((item) => item.blueprintName))
  ).map((blueprintName) => {
    return blueprintsList.find(
      (item) => item.blueprintName === blueprintName
    ) as IBlueprint;
  });

  const options = unique
    .filter(blueprint => {
      return blueprint.status === StatusEnum.Enable;
    })
    .sort((blueprint) => {
      return blueprint._id === courseTemplateId ? 1 : -1;
    })
    .map((blueprint) => ({
      label: <></>,
      options: [renderItem(blueprint)],
    }));

  return (
    <React.Fragment>
      <Card className={styles.card}>
        {stepIndicator}
        <div className={styles.title}>Evaluación final</div>
        <div className={styles.subTitle}>
          Completa la sección final del curso
        </div>
      </Card>
      <React.Fragment>
        {course?.evaluation && evaluationsDict[course.evaluation] ? (
          <React.Fragment>
            <Card
              title="Examen"
              className={cls(
                commonStyles.evaluationCard,
                commonStyles.extendedStyle,
                styles.card
              )}
              bodyStyle={{ padding: "16px" }}
            >
              <div className={commonStyles.title}>
                {evaluationsDict[course.evaluation].title}
              </div>
              <span className={cls(commonStyles.pill, commonStyles.totalTop)}>
                <div className={commonStyles.pillContent}>
                  Total de preguntas:{" "}
                  {evaluationsDict[course.evaluation].questions.length}
                </div>
              </span>
              <Divider />
              {isCreatingOrUpdating ? (
                <div>
                  <Form.Item
                    initialValue={course?.correctAnswersToPass}
                    name="correctAnswersToPass"
                    label="Cantidad de respuestas correctas para aprobar"
                    rules={[
                      { required: true, message: "Requerido" },
                      {
                        type: "number",
                        min: 1,
                        message: "No puede ser menor a 1",
                      },
                      {
                        type: "number",
                        max: evaluationsDict[course.evaluation].questions
                          .length,
                        message: `No puede ser mayor a ${
                          evaluationsDict[course.evaluation].questions.length
                        }`,
                      },
                      {
                        validator: (rule, value: any) => {
                          return new Promise((resolve, reject) => {
                            if (value) {
                              const evaluationId = course.evaluation || "";
                              const timeToRetry =
                                form.getFieldValue("timeToRetry");
                              if (
                                parseInt(value) < 1 ||
                                parseInt(value) >
                                  evaluationsDict[evaluationId].questions
                                    .length ||
                                isNaN(value) ||
                                timeToRetry < 1 ||
                                timeToRetry === null ||
                                timeToRetry === undefined
                              ) {
                                setDisableAssignBtn(true);
                              } else {
                                setDisableAssignBtn(false);
                              }
                              resolve(0);
                            } else {
                              setDisableAssignBtn(true);
                              resolve(0);
                            }
                          });
                        },
                      },
                    ]}
                  >
                    <InputNumber
                      style={{ width: "100%" }}
                      placeholder="Número de respuestas correctas"
                      onChange={(value) => {
                        setCourse({ ...course, correctAnswersToPass: value });
                      }}
                    />
                  </Form.Item>
                  <Form.Item
                    initialValue={course?.timeToRetry}
                    name="timeToRetry"
                    label="Tiempo de reintento"
                    rules={[
                      { required: true, message: "Requerido" },
                      {
                        type: "number",
                        min: 1,
                        message: "No puede ser menor a 1",
                      },
                      {
                        validator: (rule, value: any) => {
                          return new Promise((resolve, reject) => {
                            const correctAnswersToPass = form.getFieldValue(
                              "correctAnswersToPass"
                            );
                            const evaluationId = course.evaluation || "";
                            if (value) {
                              if (
                                value < 1 ||
                                isNaN(value) ||
                                parseInt(correctAnswersToPass) < 1 ||
                                parseInt(correctAnswersToPass) >
                                  evaluationsDict[evaluationId].questions
                                    .length ||
                                isNaN(correctAnswersToPass)
                              ) {
                                setDisableAssignBtn(true);
                              } else {
                                setDisableAssignBtn(false);
                              }
                            } else {
                              setDisableAssignBtn(true);
                            }
                            resolve(0);
                          });
                        },
                      },
                    ]}
                  >
                    <InputNumber
                      style={{ width: "100%" }}
                      placeholder="Tiempo de reintento"
                      onChange={(value) => {
                        setCourse({ ...course, timeToRetry: value });
                      }}
                    />
                  </Form.Item>
                  <Divider />
                  <div className={commonStyles.collapsedContainer}>
                    <div className={commonStyles.btnContainer}>
                      <Button
                        disabled={false}
                        onClick={handleExamDelete}
                        type="default"
                      >
                        Descartar
                      </Button>
                      <Button
                        className={commonStyles.space}
                        disabled={disableAssignBtn}
                        onClick={handleAcceptChange}
                        type="primary"
                      >
                        Asignar examen
                      </Button>
                    </div>
                  </div>
                </div>
              ) : (
                <div className={commonStyles.collapsedContainer}>
                  <div className={commonStyles.btnContainer}>
                    <Button
                      className={commonStyles.removeButton}
                      type="default"
                      onClick={() => {
                        setIsCreatingOrUpdating(true);
                      }}
                    >
                      <CogIcon
                        className={commonStyles.square}
                        icon="Edit"
                      ></CogIcon>
                    </Button>
                    <Button
                      className={cls(
                        commonStyles.removeButton,
                        commonStyles.space
                      )}
                      type="default"
                      onClick={handleExamDelete}
                    >
                      <CogIcon
                        className={commonStyles.square}
                        icon="Delete"
                      ></CogIcon>
                    </Button>
                  </div>
                  <div className={commonStyles.pill}>
                    <div className={commonStyles.pillContent}>
                      <CogIcon color="#81858B" icon="test" />
                      <span className={commonStyles.ansToPass}>
                        {course?.correctAnswersToPass} Respuestas
                      </span>
                    </div>
                  </div>
                  <div className={commonStyles.pill}>
                    <div className={commonStyles.pillContent}>
                      <CogIcon color="#81858B" icon="test" />
                      <span className={commonStyles.ansToPass}>
                        {course?.timeToRetry} min para repetir
                      </span>
                    </div>
                  </div>
                </div>
              )}
            </Card>
          </React.Fragment>
        ) : (
          <Card className={styles.card}>
            <EvaluationField
              evaluationId={course?.evaluation}
              type={EvaluationTypeEnum.Exam}
              onUpdate={handleExamUpdate}
              evaluationSelector={selectExamns}
              companyId={course?.companyId || ''}
              parentLabel="el curso"
            ></EvaluationField>
          </Card>
        )}
      </React.Fragment>

      <Card className={styles.card}>
        <div className={styles.sectionTitle}>Certificado</div>
        {course?.certificate && SelectedTemplate ? (
          <div className={styles.bluePrintWrapper}>
            <SelectedTemplate
              baseFontSizePx={4}
              {...blueprintDict[course.certificate]}
              {...DUMMY_DATA}
            />
            <footer className={styles.blueprintFooter}>
              <p>{blueprintDict[course.certificate].blueprintName}</p>
              <Button
                className={commonStyles.removeButton}
                type="default"
                onClick={() => handleCertificateDelete()}
              >
                <CogIcon
                  className={commonStyles.square}
                  icon="Delete"
                ></CogIcon>
              </Button>
            </footer>
          </div>
        ) : (
          <AutoComplete
            style={{
              width: "100%",
            }}
            dropdownClassName={styles.blueprintMenu}
            options={options}
            filterOption={true}
          >
            <Input.Search size="large" placeholder="Añadir certificado" />
          </AutoComplete>
        )}
      </Card>

      <Card className={styles.card}>
        <EvaluationField
          evaluationId={course?.survey}
          hideSelectorAfterSelection
          type={EvaluationTypeEnum.Survey}
          onUpdate={handleQuizUpdate}
          evaluationSelector={selectSurveys}
          companyId={course?.companyId || ''}
          parentLabel="el curso"
        >
          {course?.survey && dict[course.survey] && (
            <div className={styles.sectionRow}>
              <div className={styles.sectionInfo}>
                <CogIcon color="#81858B" icon="test" />
                <span>{dict[course.survey].title}</span>
                <Button
                  type="link"
                  className={styles.removeButton}
                  onClick={handleSurveyDelete}
                >
                  <CogIcon color="#81858B" icon="Cross" size="16" />
                </Button>
              </div>
            </div>
          )}
        </EvaluationField>
      </Card>
    </React.Fragment>
  );
};

interface CoursesFormPros {
  form: FormInstance;
  editing: string | null;
  onDone: Function;
  categories: ICategoriesDict;
  categoriesIds: string[];
}

export const CoursesForm: FunctionComponent<CoursesFormPros> = ({
  form,
  editing,
  onDone,
  categories,
  categoriesIds,
}) => {
  const areCoursesFetching = useSelector(selectAreCoursesFetching);
  const courseToEdit = useSelector(selectCourse(editing));
  const topics = useSelector(selectTopics);
  const userRole = useSelector(selectUserRole);
  const companyId = useSelector(selectCompanyId);
  const [step, setStep] = useState<number>(0);
  const [course, setCourse] = useState<ICourse>();
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      setBreadcrumbs([
        {
          label: "Cursos",
        },
        {
          label: editing ? "Editando curso" : "Nuevo curso",
        },
      ])
    );
  }, [dispatch, editing]);
  useEffect(() => {
    if (courseToEdit) {
      setCourse(courseToEdit);
    }
  }, [courseToEdit]);
  if (areCoursesFetching) {
    return <LoadingOverlay spinning={true} />;
  }

  const steps = [FirstStep, SecondStep, ThirdStep, FourthStep];
  const goNext = () => {
    if (step + 1 >= STEPS_COUNT) {
      return;
    }
    form
      .validateFields()
      .then(() => {
        setStep(step + 1);
      })
      .catch((error) => {
        console.log("Company:React.FunctionComponent -> error", error);
      });
  };
  const goPrev = () => {
    if (step === 0) {
      return;
    }
    form
      .validateFields()
      .then(() => {
        setStep(step - 1);
      })
      .catch((error) => {
        console.log("Company:React.FunctionComponent -> error", error);
      });
  };
  const StepToShow = steps[step];
  const stepIndicator = <StepIndicator step={step} stepsCount={STEPS_COUNT} />;

  const handleCancel = () => setShowConfirmationModal(false);
  const onSubmit = () => {
    if (!course) {
      return;
    }
    if (
      !course.topics ||
      course.topics.length === 0 ||
      course.topics?.find((item) => topics[item].lessons.length === 0)
    ) {
      setShowConfirmationModal(true);
      return;
    }
    onSave();
  };

  const onSave = () => {
    if (!course) {
      return;
    }
    if (course?.category === OTHER_CATEGORY_OPTION) {
      course.category = {
        name: course.categoryName || "",
      } as ICategory;
    }
    delete course.categoryName;
    if(userRole !== UserRole.SuperAdmin){
      if(course._id && course.companyId !== companyId){ // don't allow editing courses that don't belong to the company
        return;
      }
      course.companyId = companyId; // force to company in case of creating
    }
    if(!course.companyId){
      course.companyId = undefined;
    }
    if (course._id) {
      dispatch(updateCourse(course));
    } else {
      dispatch(createCourse(course));
    }
    onDone();
  };
  const buttonLabel = course?._id ? "Actualizar curso" : "Crear curso";
  let backButton;
  if (step === 0) {
    backButton = (
      <Popconfirm
        placement="top"
        title="¿Desea salir del formulario?"
        onConfirm={() => {
          onDone();
        }}
        okText="Si"
        cancelText={`No, voy a seguir ${
          course?._id ? "editando" : "creando"
        } el curso`}
        cancelButtonProps={{
          type: "default",
        }}
      >
        <Button className={styles.buttonNextPrev} type="default">
          <CogIcon
            color="#4376F9"
            className={styles.icon}
            icon="Arrow-left-big"
          />
        </Button>
      </Popconfirm>
    );
  } else {
    backButton = (
      <Button onClick={goPrev} className={styles.buttonNextPrev} type="default">
        <CogIcon
          color="#4376F9"
          className={styles.icon}
          icon="Arrow-left-big"
        />
      </Button>
    );
  }
  return (
    <div className={styles.container}>
      {showConfirmationModal && (
        <ConfirmationModal
          headerIcon="Help"
          title="¿Estas seguro que deseas guardar o quieres seguir editando?"
          message={<p>Estas guardando un curso sin temas, o lecciones</p>}
          footer={[
            <Button type="link" onClick={onSave} key="save">
              Guardar
            </Button>,
            <Button type="text" onClick={handleCancel} key="cancel">
              Cancelar
            </Button>,
          ]}
          onCancel={handleCancel}
          onConfirmation={onSave}
        />
      )}
      <div className={styles.titleContainer}>
        <div className={styles.title}>
          {editing ? `Editando ${course?.title}` : "Nuevo Curso"}
        </div>
        <div className={styles.actions}>
          {backButton}
          <Button
            disabled={step === STEPS_COUNT - 1}
            onClick={goNext}
            className={styles.buttonNextPrev}
            type="default"
          >
            <CogIcon
              color="#4376F9"
              className={styles.icon}
              icon="Arrow-right-big"
            />
          </Button>
          <Button
            disabled={step !== STEPS_COUNT - 1}
            onClick={onSubmit}
            className={styles.button}
            type="primary"
          >
            {buttonLabel}
          </Button>
        </div>
      </div>
      <Form form={form} layout="vertical">
        <StepToShow
          categories={categories}
          categoriesIds={categoriesIds}
          setCourse={setCourse}
          course={course}
          stepIndicator={stepIndicator}
          validateForm={form.validateFields}
          form={form}
        />
      </Form>
    </div>
  );
};
