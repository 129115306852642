import { Alert, Button, Form, List, message, Table } from "antd";
import { ColumnsType } from "antd/es/table";
import { FormInstance } from "antd/lib/form";
import React, {
  FunctionComponent,
  ReactText,
  useEffect,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Microservices } from "../../app/AllowedMicroservices";
import { BASE_URL } from "../../app/apiMiddleware";
import {
  getCognitoUserData,
  resendConfirmationEmail,
} from "../../features/auth/authSlice";
import { FetchStatus } from "../../features/common/enums";
import {
  fetchCompanies,
  selectCompanies,
  selectCompaniesAreFetching,
} from "../../features/company/companySlice";
import {
  fetchGroups,
  selectGroupsDict,
} from "../../features/groups/groupSlice";
import {
  createStudent,
  disableUsersStatus,
  enableUsersStatus,
  fetchStudents,
  fetchUser,
  hideModal,
  hideModalWithDelay,
  IUser,
  selectDisplayModal,
  selectStudents,
  selectStudentsFetchStatus,
  selectStudentsIds,
  showModal,
  updateStudent,
  UserRole,
  UsersGroups,
  selectCreatedStudent,
} from "../../features/users/usersSlice";
import { PAGE_SIZE } from "../../utils/constants";
import { DialogPopUp } from "../common/DialogPopUp";
import { CogDrawer } from "../common/Drawer";
import { MainLayout } from "../common/Layouts/Main";
import { Status, StatusEnum } from "../common/Status";
import tableStyles from "../common/table.module.scss";
import { TableTools } from "../common/TableTools";
import { CompanyFilter } from "../Filters/Company";
import { extractDate } from "../utils/convertions";
import { useDebounce } from "../utils/useDebounce";
import { NamePlusThumbnail } from "./common/NamePlusThumbnail";
import { UserForm } from "./Form";
import styles from "./students.module.scss";
import { UploadFile } from "./UploadFile";
import { getGroupsForUser } from "./utils";
import { analytics } from '../../services/analytics';

interface RenderOptions {
  toggleVisibleCreateUpdate: Function;
  setIsCreating: Function;
  setNewStudent: Function;
  toggleVisibleImport: Function;
  form: FormInstance;
}

interface IRowErrors {
  [_id: string]: string[];
}

interface IError {
  list: string[];
  row: string;
}

type ErrorList = IError[];

interface IListItem {
  field: string;
  example: string;
}
const list: IListItem[] = [
  {
    field: "Correo electrónico",
    example: "xxxxxxx@xxxx.xx",
  },
  {
    field: "Numero de identificación",
    example: "xxxxxxxxxxxx",
  },
  {
    field: "Tipo de identificación",
    example: "CI o PASPORTE",
  },
  {
    field: "Primer nombre",
    example: "xxxxxxx",
  },
  {
    field: "Segundo nombre",
    example: "xxxxxxx",
  },
  {
    field: "Primer apellido",
    example: "xxxxxxx",
  },
  {
    field: "Segundo apellido",
    example: "xxxxxxx",
  },
  {
    field: "Fecha de nacimiento",
    example: "DD/MM/AAAA (tipo fecha)",
  },
  {
    field: "Genero",
    example: "MASCULINO o FEMENINO",
  },
  {
    field: "Numero telefónico",
    example: "+00000000000 (incluye el código de país)",
  },
  {
    field: "Dirección",
    example: "xxxxxxxxx",
  },
];

function renderOptions({
  toggleVisibleCreateUpdate,
  setIsCreating,
  setNewStudent,
  toggleVisibleImport,
  form,
}: RenderOptions) {
  return (
    <React.Fragment>
      <Button
        onClick={() => {
          toggleVisibleImport();
          setNewStudent({ group: "Students" });
        }}
        type="default"
      >
        Importar
      </Button>
      &nbsp;&nbsp;&nbsp;
      <Button
        onClick={() => {
          toggleVisibleCreateUpdate();
          setNewStudent({ group: "Students" });
          setIsCreating(true);
          setTimeout(() => {
            form.resetFields();
            form.setFieldsValue({});
          }, 100);
        }}
        type="primary"
      >
        Nuevo estudiante
      </Button>
    </React.Fragment>
  );
}
const renderLists = (errors: ErrorList, count: number) => {
  if (count === 0) {
    return (
      <React.Fragment>
        <div className={styles.infoText}>
          Debes preparar un archivo con las siguientes columnas, te recomendamos
          utilizar esta&nbsp;
          <a
            href={`${
              BASE_URL[Microservices.COMPANY_SERVICE]
            }/../assets/user-template-import.xlsx`}
          >
            plantilla
          </a>
        </div>
        <List<IListItem>
          className={styles.list}
          dataSource={list}
          renderItem={(item, index) => {
            return (
              <List.Item key={index} className={styles.item}>
                <div className={styles.field}>{item.field}</div>
                <div className={styles.example}>{item.field}</div>
              </List.Item>
            );
          }}
        />
      </React.Fragment>
    );
  }
  return (
    <List<IError>
      className={styles.errorslist}
      dataSource={errors}
      renderItem={(item, index) => {
        const errorList = item.list.map((error, index) => {
          return (
            <span className={styles.error} key={index}>
              {error}
            </span>
          );
        });
        return (
          <List.Item key={index} className={styles.item}>
            <div className={styles.text}>
              En la fila {item.row} se encontraron los siguientes errores:{" "}
            </div>
            <Alert
              className={styles.errorAlert}
              message={<React.Fragment>{errorList}</React.Fragment>}
              type="error"
            />
          </List.Item>
        );
      }}
    />
  );
};

export const Students: FunctionComponent = () => {
  const companiesDict = useSelector(selectCompanies);
  const studentsIdsList = useSelector(selectStudentsIds);
  const studentsDict = useSelector(selectStudents);
  const groupsDict = useSelector(selectGroupsDict);
  const studentsList = studentsIdsList.map((userId) => ({
    ...studentsDict[userId],
    companyName: companiesDict[studentsDict[userId].company ?? ""]?.name,
    studentGroupName:
      groupsDict[studentsDict[userId].studentGroupId ?? ""]?.name,
  }));
  const areCompaniesFetching = useSelector(selectCompaniesAreFetching);
  const areStudentsFetching =
    useSelector(selectStudentsFetchStatus) === FetchStatus.Fetching;
  const isStudentCreating =
    useSelector(selectStudentsFetchStatus) === FetchStatus.Creating;
  const studentFormId = "studensForm";
  const [filterValue, setFilterValue] = useState<ReactText[]>([]);
  const debounceFilterValue = useDebounce(filterValue, 500);
  const [loading, setLoading] = useState<boolean>(false);
  const history = useHistory();

  const [visibleCreateUpdate, setVisibleCreateUpdate] = useState<boolean>(
    false
  );
  const [visibleImport, setVisibleImport] = useState<boolean>(false);
  const [newStudent, setNewStudent] = useState<IUser>();
  const [isCreating, setIsCreating] = useState<boolean>(false);
  const [selectedRowKeys, selectRowKeys] = useState<ReactText[]>([]);
  const [filteredInfo, setFilteredInfo] = useState<ReactText[]>([]);
  const [errors, setErrors] = useState<ErrorList>([]);
  const [errorsCount, setErrorsCount] = useState<number>(0);
  const [objectName, setObjectName] = useState<string>("");
  const [currentAction, setCurrentAction] = useState<string>("");
  const [showActivationBtns, setShowActivationBtns] = useState<boolean>(false);
  const cognitoData = useSelector(getCognitoUserData);
  const isModalVisible = useSelector(selectDisplayModal);
  const role = cognitoData ? cognitoData["custom:role"] : undefined;
  const cognitoUsername = cognitoData ? cognitoData['cognito:username'] : null;
  const createdStudent = useSelector(selectCreatedStudent);
  const toggleVisibleCreateUpdate = () =>
    setVisibleCreateUpdate(!visibleCreateUpdate);

  useEffect(() => {
    if (createdStudent) {
      const companyId = createdStudent.User.Attributes.find((attr) => attr.Name === 'custom:company')?.Value;
      const companyName = companyId ? companiesDict[companyId].name : "";
      analytics.createStudent({
        userName: cognitoUsername,
        companyName,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createdStudent]);

  const toggleVisibleImport = () => {
    if (visibleImport) {
      setErrorsCount(0);
      setErrors([]);
    }
    setVisibleImport(!visibleImport);
  };
  const dispatch = useDispatch();

  useEffect(() => {
    if (selectedRowKeys.length > 0) {
      setShowActivationBtns(true);
    } else {
      setShowActivationBtns(false);
    }
  }, [selectedRowKeys]);

  const columns: ColumnsType<IUser> = [
    {
      title: "Nombre completo",
      dataIndex: "name",
      sortDirections: ["descend", "ascend"],
      defaultSortOrder: "ascend",
      ellipsis: true,
      filteredValue: debounceFilterValue,
      sorter: (a: IUser, b: IUser) => a.name.localeCompare(b.name),
      onFilter: (value, record) => {
        return (
          record.name.toLowerCase().includes(value.toString().toLowerCase()) ||
          record.email.toLowerCase().includes(value.toString().toLowerCase())
        );
      },
      render: (value, user) => {
        return <NamePlusThumbnail value={value} user={user} />;
      },
    },
    {
      title: "Correo electrónico",
      dataIndex: "email",
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Estado",
      dataIndex: "enabled",
      render: (status) => {
        return (
          <Status status={status ? StatusEnum.Enable : StatusEnum.Disable} />
        );
      },
      sortDirections: ["descend", "ascend"],
      sorter: (a: IUser, b: IUser) =>
        a.enabled === b.enabled ? 0 : a.enabled > b.enabled ? 1 : -1,
    },
    {
      title: "Grupo",
      dataIndex: "studentGroupName",
      ellipsis: true,
      sortDirections: ["descend", "ascend"],
      filteredValue: filteredInfo.length > 1 ? filteredInfo : [],
      onFilter: (value, record: IUser) =>
        record.studentGroupId === (value as string),
    },
    {
      title: "Empresa",
      dataIndex: "companyName",
      sortDirections: ["descend", "ascend"],
      filteredValue: filteredInfo,
      onFilter: (value, record: IUser) => record.company === (value as string),
      sorter: (a: IUser, b: IUser) =>
        (a.companyName || "").localeCompare(b.companyName || ""),
      render: (value, user) => {
        return (
          <React.Fragment>
            {value}
            <div className={tableStyles.actions}>
              {user.status === "FORCE_CHANGE_PASSWORD" ? (
                <Button
                  type="default"
                  onClick={() => {
                    dispatch(resendConfirmationEmail(user.username));
                  }}
                >
                  Reenviar invitación
                </Button>
              ) : null}
              <Button
                type="default"
                onClick={() => {
                  history.push(`/students/${user.username}`);
                }}
              >
                Ver
              </Button>
              <Button
                type="default"
                onClick={() => {
                  setIsCreating(false);
                  dispatch(fetchUser(user.username));
                  toggleVisibleCreateUpdate();
                  form.resetFields();
                  setNewStudent(user);
                }}
              >
                Editar
              </Button>
            </div>
          </React.Fragment>
        );
      },
    },
  ];

  useEffect(() => {
    dispatch(fetchStudents());
    dispatch(fetchCompanies());
    dispatch(fetchGroups());
  }, [dispatch]);

  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedRowKeys: ReactText[]) => {
      selectRowKeys(selectedRowKeys);
    },
  };

  const enableStatus = () => {
    const data = {
      usernames: selectedRowKeys.map((key) => key.toString()),
    };
    dispatch(enableUsersStatus(false, data));
  };

  const disableStatus = () => {
    const data = {
      usernames: selectedRowKeys.map((key) => key.toString()),
    };
    dispatch(disableUsersStatus(false, data));
  };
  const handleChange = (info: any) => {
    const { status } = info.file;
    if (status === "uploading") {
      setLoading(true);
      setErrors([]);
      setErrorsCount(0);
      setObjectName("Estudiante(s)");
      setCurrentAction("creado(s)");
      return;
    }
    if (status === "done") {
      setLoading(false);
      const {
        count,
        errors: errorsDict,
      }: {
        recordsCount: number;
        count: number;
        errors: IRowErrors;
      } = info.file.response;
      const errors: ErrorList = [];
      Object.keys(errorsDict).forEach((rowNumber) => {
        errors.push({
          list: errorsDict[rowNumber],
          row: rowNumber,
        });
      });
      setErrors(errors);
      setErrorsCount(count);
      if (errors.length === 0) {
        dispatch(showModal());
        dispatch(hideModalWithDelay());
        dispatch(fetchStudents());
        toggleVisibleImport();
      }
    } else if (status === "error") {
      setErrors([]);
      setErrorsCount(0);
      message.error(`${info.file.name} file upload failed.`);
    }
  };
  const [form] = Form.useForm();

  return (
    <MainLayout
      title="Estudiantes"
      rightTopOptions={renderOptions({
        toggleVisibleCreateUpdate,
        setNewStudent,
        setIsCreating,
        toggleVisibleImport,
        form,
      })}
    >
      <DialogPopUp
        name={objectName}
        action={currentAction}
        visible={isModalVisible}
        onCancel={() => {
          dispatch(hideModal());
        }}
      />
      <TableTools
        placeholder="Buscar en Teurona"
        onSearch={(value) => setFilterValue([value])}
        onChange={(event) => setFilterValue([event.target.value])}
        onEnable={() => enableStatus()}
        onDisable={() => disableStatus()}
        filter={
          role === UserRole.SuperAdmin ? (
            <CompanyFilter onApply={setFilteredInfo} showGroups={true} />
          ) : (
            undefined
          )
        }
        activationBtnsVisible={showActivationBtns}
      />
      <Table<IUser>
        loading={areCompaniesFetching || areStudentsFetching}
        className={tableStyles.table}
        rowClassName={() => tableStyles.row}
        rowKey="username"
        rowSelection={rowSelection}
        columns={columns}
        dataSource={studentsList}
        pagination={{ position: ["bottomRight"], pageSize: PAGE_SIZE }}
      />
      <CogDrawer
        loading={loading}
        visible={visibleImport}
        toggleVisible={toggleVisibleImport}
        title="Importar"
        footer={
          <div>
            <Button
              type="default"
              onClick={toggleVisibleImport}
              disabled={loading}
            >
              Cancelar
            </Button>
            &nbsp;&nbsp;
          </div>
        }
      >
        <UploadFile onHandleChange={handleChange} loading={loading} />
        {renderLists(errors, errorsCount)}
      </CogDrawer>
      <CogDrawer
        loading={areStudentsFetching || isStudentCreating}
        visible={visibleCreateUpdate}
        toggleVisible={toggleVisibleCreateUpdate}
        title={
          !(newStudent?.status === undefined)
            ? "Editar estudiante"
            : "Nuevo Estudiante"
        }
        footer={
          <div>
            <Button
              onClick={toggleVisibleCreateUpdate}
              disabled={isStudentCreating}
            >
              Cancelar
            </Button>
            &nbsp;&nbsp;
            <Button
              type="primary"
              htmlType="submit"
              form={studentFormId}
              disabled={isStudentCreating}
              onClick={() => {
                form
                  .validateFields()
                  .then(() => {
                    if (!newStudent) {
                      return;
                    }
                    const studentToSend = { ...newStudent };
                    delete studentToSend.companyName;
                    studentToSend.birthdate = extractDate(
                      studentToSend.birthdate
                    );
                    const isAdminToo = studentToSend.isAdminToo;
                    delete studentToSend.isAdminToo;
                    delete studentToSend.isStudentToo;
                    studentToSend.groups = getGroupsForUser(
                      studentToSend.groups,
                      UsersGroups.Students
                    );
                    if (isAdminToo) {
                      studentToSend.groups = getGroupsForUser(
                        studentToSend.groups,
                        UsersGroups.Administrators
                      );
                    } else {
                      studentToSend.groups = [UsersGroups.Students];
                    }
                    const status = studentToSend.status;
                    delete studentToSend.status;
                    setObjectName("Estudiante");
                    if (!status) {
                      setCurrentAction("creado");
                      dispatch(
                        createStudent(
                          { ...studentToSend },
                          toggleVisibleCreateUpdate
                        )
                      );
                    } else {
                      setCurrentAction("actualizado");
                      dispatch(
                        updateStudent(
                          { ...studentToSend },
                          toggleVisibleCreateUpdate
                        )
                      );
                    }
                  })
                  .catch((error) => {
                    console.log(
                      "Company:React.FunctionComponent -> error",
                      error
                    );
                  });
              }}
            >
              {!(newStudent?.status === undefined)
                ? "Actualizar"
                : "Crear estudiante"}
            </Button>
          </div>
        }
      >
        <div>
          {!visibleCreateUpdate ? null : (
            <UserForm
              form={form}
              formId={studentFormId}
              user={newStudent}
              setUser={setNewStudent}
              adminOptions={false}
              isCreating={isCreating}
              isStudent
            />
          )}
        </div>
      </CogDrawer>
    </MainLayout>
  );
};
