import { Checkbox, DatePicker, Form, Input, InputNumber, Select } from "antd";
import { FormInstance } from "antd/lib/form";
import moment from "moment";
import phoneValidator from "phone";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchCompanies, ICompany, selectCompanies } from "../../features/company/companySlice";
import {
  selectPrograms,
  selectProgramsDict,
} from "../../features/program/programSlice";
import { dateFormatList } from "../../utils/constants";
import { CompanyTypeField } from "../common/CompanyTypeField";
import formStyles from "../common/form.module.scss";
import { StatusEnum } from "../common/Status";
import { UploadImage } from "../common/UploadImage";
import { Countries, ISelectValue, MIN_3_CHAR } from "../Users/Form";
import { getUniqueNamesCities } from "../common/cities.utils";

interface CompanyFormProps {
  newCompany?: ICompany;
  setNewCompany: Function;
  form: FormInstance;
}

export const CompanyForm: React.FunctionComponent<CompanyFormProps> = (
  props
) => {
  const { setNewCompany, newCompany, form } = props;
  const programsIds = useSelector(selectPrograms);
  const programs = useSelector(selectProgramsDict);
  const companies = useSelector(selectCompanies);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchCompanies());
    form.setFieldsValue({
      logo: newCompany?.logo,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const [loading, setLoading] = useState<boolean>(false);
  const [cities, setCities] = useState<ISelectValue[]>([]);
  const [citiesContactInfo, setCitiesContactInfo] = useState<ISelectValue[]>([]);
  useEffect(() => {
    (async() => {
      if (newCompany?.country) {
        const countryCities:any = await import(`../../assets/countries/cities/${newCompany?.country}.json`);
        const cities:ISelectValue[] = getUniqueNamesCities(countryCities);
        setCities(cities);
      }
      if (newCompany?.contactInfo?.country) {
        const countryCities:any = await import(`../../assets/countries/cities/${newCompany?.contactInfo.country}.json`);
        const cities:ISelectValue[] = getUniqueNamesCities(countryCities);
        setCitiesContactInfo(cities);
      }
    })();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])
  const handleChange = (info: any) => {
    if (info.file.status === "uploading") {
      setLoading(true);
      return;
    }
    if (info.file.status === "done") {
      setLoading(false);
      setNewCompany({ ...newCompany, logo: info.file.response.url });
      form.setFieldsValue({
        logo: info.file.response.url,
      });
    }
  };
  const usedPrograms = Object.values(companies).filter(company => company.studyProgram && company.studyProgram !== newCompany?.studyProgram).map(company => company.studyProgram);
  return (
    <Form
      form={form}
      className={formStyles.form}
      layout="vertical"
      name="control-hooks"
    >
      <Form.Item
        name="logo"
        wrapperCol={{ sm: 24 }}
        rules={[{ type: "string", required: true, message: "Logo requerido" }]}
      >
        <UploadImage
          text="Cargar logo de la empresa"
          loading={loading}
          imageUrl={newCompany?.logo}
          onHandleChange={handleChange}
        />
      </Form.Item>

      <Form.Item
        initialValue={newCompany?.name}
        name="name"
        label="Nombre"
        rules={[
          { required: true, message: "Requerido" },
          { type: "string", max: 100, message: "Máximo 100 caracteres" },
          { type: "string", min: 3, message: MIN_3_CHAR },
        ]}
      >
        <Input
          onChange={(event) => {
            setNewCompany({ ...newCompany, name: event.target.value });
          }}
          type="text"
          placeholder="Nombre de la empresa"
        />
      </Form.Item>

      <CompanyTypeField
        markRequired
        label="Tipo de industria"
        object={newCompany}
        setObject={setNewCompany}
      />
      <Form.Item
        initialValue={newCompany?.ruc}
        name="ruc"
        label="RUC"
        rules={[
          { required: true, message: "Requerido" },
          {
            validator: (rule, value: string = "", callback) => {
              if (value.match(/\D/)) {
                callback("Sólo están permitidos dígitos");
                return;
              }
              callback();
            },
          },
          { type: "string", len: 13, message: "Debe tener 13 dígitos" },
        ]}
      >
        <Input
          onChange={(event) => {
            const { value } = event.target;
            setNewCompany({ ...newCompany, ruc: value.replace(/\D/g, "") });
          }}
          type="text"
          value={newCompany ? newCompany.ruc : ""}
          placeholder="ruc"
        />
      </Form.Item>
      <Form.Item
        initialValue={newCompany?.maxActiveStudents}
        name="maxActiveStudents"
        label="Número máximo de estudiantes activos"
        rules={[
          { type: "number", required: true, message: "Requerido" },
          { type: "number", max: 10000, message: "No puede ser mayor a 10000" },
        ]}
      >
        <InputNumber
          style={{ width: "100%" }}
          onChange={(value) => {
            setNewCompany({ ...newCompany, maxActiveStudents: value });
          }}
          type="number"
          placeholder="Número máximo de estudiantes activos"
        />
      </Form.Item>
      <Form.Item
        initialValue={newCompany?.address}
        name="address"
        label="Dirección"
        rules={[
          { required: true, message: "Requerido" },
          { type: "string", max: 200, message: "Máximo 200 caracteres" },
          { type: "string", min: 3, message: MIN_3_CHAR },
        ]}
      >
        <Input.TextArea
          onChange={(event) => {
            setNewCompany({ ...newCompany, address: event.target.value });
          }}
          placeholder="Dirección"
        />
      </Form.Item>
      <Form.Item
        initialValue={newCompany?.country}
        name="country"
        label="País"
        rules={[{ required: true, message: "Requerido" }]}
      >
        <Select
          showSearch
          filterOption={(input, option) => {
            return (option?.children?.toString().toLowerCase() ?? '').includes(`${input}`.toLowerCase());
          }}
          filterSort={(optionA, optionB) =>
            {
              return (optionA?.children?.toString() ?? '').toLowerCase().localeCompare((optionB?.children?.toString() ?? '').toLowerCase());
            }
          }
          placeholder="Selecciona un país"
          onChange={async (value) => {
            setNewCompany({ ...newCompany, country: value, city: null });
            form.setFieldsValue({
              city: null
            });
            const countryCities:any = await import(`../../assets/countries/cities/${value}.json`);
            const cities:ISelectValue[] = getUniqueNamesCities(countryCities);
            setCities(cities);
          }}
        >
          {Countries.map((item) => (
            <Select.Option key={item.value} value={item.value}>
              {item.label}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        initialValue={newCompany?.city}
        name="city"
        label="Ciudad"
        rules={[{ required: true, message: "Requerido" }]}
      >
        <Select
          placeholder="Selecciona una ciudad"
          onChange={(value) => {
            setNewCompany({ ...newCompany, city: value });
          }}
        >
          {cities.map((item) => (
            <Select.Option key={item.value} value={item.value}>
              {item.label}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item
        initialValue={newCompany?.supportEmail}
        name="supportEmail"
        label="Correo electrónico de soporte"
        rules={[
          { type: "email", message: "Debe ser un correo válido" },
          { type: "string", max: 50, message: "Máximo 50 caracteres" },
        ]}
      >
        <Input
          onChange={(event) => {
            setNewCompany({ ...newCompany, supportEmail: event.target.value });
          }}
          type="email"
          placeholder="Correo electrónico"
        />
      </Form.Item>

      <div className={formStyles.sectionTitle}>Datos del contacto</div>
      <Form.Item
        initialValue={newCompany?.contactInfo?.firstname}
        name="contactInfofirstname"
        label="Primer nombre"
        rules={[
          { required: true, message: "Requerido" },
          { type: "string", min: 3, message: MIN_3_CHAR },
          { type: "string", max: 15, message: "Máximo 15 caracteres" },
          {
            validator: (rule, value: string = "", callback) => {
              if (value.match(/\d/)) {
                callback("No se permiten números");
                return;
              }
              callback();
            },
          },
        ]}
      >
        <Input
          onChange={(event) => {
            setNewCompany({
              ...newCompany,
              contactInfo: {
                ...newCompany?.contactInfo,
                firstname: event.target.value,
              },
            });
          }}
          type="text"
          placeholder="Primer nombre"
        />
      </Form.Item>
      <Form.Item
        initialValue={newCompany?.contactInfo?.middlename}
        name="contactInfomiddlename"
        label="Segundo nombre"
        rules={[
          { type: "string", max: 15, message: "Máximo 15 caracteres" },
          { type: "string", min: 3, message: MIN_3_CHAR },
          {
            validator: (rule, value: string = "", callback) => {
              if (value.match(/\d/)) {
                callback("No se permiten números");
                return;
              }
              callback();
            },
          },
        ]}
      >
        <Input
          onChange={(event) => {
            setNewCompany({
              ...newCompany,
              contactInfo: {
                ...newCompany?.contactInfo,
                middlename: event.target.value,
              },
            });
          }}
          type="text"
          placeholder="Segundo nombre"
        />
      </Form.Item>
      <Form.Item
        initialValue={newCompany?.contactInfo?.lastname}
        name="contactInfolastname"
        label="Primer apellido"
        rules={[
          { required: true, message: "Requerido" },
          { type: "string", max: 15, message: "Máximo 15 caracteres" },
          { type: "string", min: 3, message: MIN_3_CHAR },
          {
            validator: (rule, value: string = "", callback) => {
              if (value.match(/\d/)) {
                callback("No se permiten números");
                return;
              }
              callback();
            },
          },
        ]}
      >
        <Input
          onChange={(event) => {
            setNewCompany({
              ...newCompany,
              contactInfo: {
                ...newCompany?.contactInfo,
                lastname: event.target.value,
              },
            });
          }}
          type="text"
          placeholder="Primer apellido"
        />
      </Form.Item>
      <Form.Item
        initialValue={newCompany?.contactInfo?.secondLastname}
        name="contactInfosecondLastname"
        label="Segundo apellido"
        rules={[
          { type: "string", max: 15, message: "Máximo 15 caracteres" },
          { type: "string", min: 3, message: MIN_3_CHAR },
          {
            validator: (rule, value: string = "", callback) => {
              if (value.match(/\d/)) {
                callback("No se permiten números");
                return;
              }
              callback();
            },
          },
        ]}
      >
        <Input
          onChange={(event) => {
            setNewCompany({
              ...newCompany,
              contactInfo: {
                ...newCompany?.contactInfo,
                secondLastname: event.target.value,
              },
            });
          }}
          type="text"
          placeholder="Segundo apellido"
        />
      </Form.Item>
      <Form.Item
        initialValue={newCompany?.contactInfo?.position}
        name="contactInfoposition"
        label="Cargo o posición"
        rules={[
          { required: true, message: "Requerido" },
          { type: "string", min: 3, message: MIN_3_CHAR },
          { type: "string", max: 50, message: "Máximo 50 caracteres" },
        ]}
      >
        <Input
          onChange={(event) => {
            setNewCompany({
              ...newCompany,
              contactInfo: {
                ...newCompany?.contactInfo,
                position: event.target.value,
              },
            });
          }}
          type="text"
          placeholder="Cargo o posición"
        />
      </Form.Item>
      <Form.Item
        initialValue={newCompany?.contactInfo?.cellphone}
        name="contactInfocellphone"
        label="Número celular"
        rules={[
          { required: true, message: "Requerido" },
          (obj) => ({
            validator: async (rules, value) => {
              const phone = phoneValidator(value);
              if (phone.length === 0) {
                throw new Error(
                  "El telefono debe ser válido (debe tener el codigo de país (ej: +593)"
                );
              }
            },
          }),
        ]}
      >
        <Input
          onChange={(event) => {
            setNewCompany({
              ...newCompany,
              contactInfo: {
                ...newCompany?.contactInfo,
                cellphone: event.target.value,
              },
            });
          }}
          type="text"
          placeholder="Número celular"
        />
      </Form.Item>
      <Form.Item
        initialValue={newCompany?.contactInfo?.email}
        name="contactInfoemail"
        label="Correo electrónico"
        rules={[
          { required: true, message: "Requerido" },
          { type: "email", message: "Debe ser un correo válido" },
          { type: "string", max: 50, message: "Máximo 50 caracteres" },
        ]}
      >
        <Input
          onChange={(event) => {
            setNewCompany({
              ...newCompany,
              contactInfo: {
                ...newCompany?.contactInfo,
                email: event.target.value,
              },
            });
          }}
          type="email"
          placeholder="Correo electrónico"
        />
      </Form.Item>

      <Form.Item
        initialValue={newCompany?.contactInfo?.address}
        name="contactInfoaddress"
        label="Dirección"
        rules={[
          { required: true, message: "Requerido" },
          { type: "string", min: 3, message: MIN_3_CHAR },
          { type: "string", max: 200, message: "Máximo 200 caracteres" },
        ]}
      >
        <Input.TextArea
          onChange={(event) => {
            setNewCompany({
              ...newCompany,
              contactInfo: {
                ...newCompany?.contactInfo,
                address: event.target.value,
              },
            });
          }}
          placeholder="Dirección"
        />
      </Form.Item>
      <Form.Item
        initialValue={newCompany?.contactInfo?.country}
        name="contactInfocountry"
        label="País"
        rules={[{ required: true, message: "Requerido" }]}
      >
        <Select
          showSearch
          filterOption={(input, option) => {
            return (option?.children?.toString().toLowerCase() ?? '').includes(`${input}`.toLowerCase());
          }}
          filterSort={(optionA, optionB) =>
            {
              return (optionA?.children?.toString() ?? '').toLowerCase().localeCompare((optionB?.children?.toString() ?? '').toLowerCase());
            }
          }
          placeholder="Selecciona un país"
          onChange={async (value) => {
            setNewCompany({
              ...newCompany,
              contactInfo: { ...newCompany?.contactInfo, country: value, city: null },
            });
            form.setFieldsValue({
              contactInfocity: null,
            });
            const countryCities:any = await import(`../../assets/countries/cities/${value}.json`);
            const cities:ISelectValue[] = getUniqueNamesCities(countryCities);
            setCitiesContactInfo(cities);
          }}
        >
          {Countries.map((item) => (
            <Select.Option key={item.value} value={item.value}>
              {item.label}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        initialValue={newCompany?.contactInfo?.city}
        name="contactInfocity"
        label="Ciudad"
        rules={[{ required: true, message: "Requerido" }]}
      >
        <Select
          placeholder="Selecciona una ciudad"
          onChange={(value) => {
            setNewCompany({
              ...newCompany,
              contactInfo: { ...newCompany?.contactInfo, city: value },
            });
          }}
        >
          {citiesContactInfo.map((item) => (
            <Select.Option key={item.value} value={item.value}>
              {item.label}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <div className={formStyles.sectionTitle}>Contrato</div>
      <Form.Item
        initialValue={newCompany?.studyProgram}
        name="studyProgram"
        label="Programa de estudio"
        rules={[{ required: true, message: "Requerido" }]}
      >
        <Select
          placeholder="Selecciona una plan de estudio"
          value={newCompany?.studyProgram}
          style={{ width: "100%" }}
          onChange={(value) => {
            setNewCompany({ ...newCompany, studyProgram: value });
          }}
        >
          {programsIds.map((id) => {
            if (programs[id] && programs[id].status === StatusEnum.Enable && !usedPrograms.includes(id)) {
              return (
                <Select.Option key={id} value={id}>
                  {programs[id].title}
                </Select.Option>
              );
            }
            return null;
          })}
        </Select>
      </Form.Item>
      <Form.Item
        initialValue={moment(newCompany?.contractDate)}
        name="contractDate"
        label="Inicio de contrato"
        rules={[{ required: true, message: "Requerido" }]}
      >
        <DatePicker
          disabledDate={(current) =>
            current && current < moment().startOf("day")
          }
          onChange={(value) => {
            setNewCompany({ ...newCompany, contractDate: value?.toDate() });
          }}
          format={dateFormatList}
        />
      </Form.Item>
      <Form.Item name="notifications" label="Notificaciones">
        <Checkbox
          checked={newCompany?.notificationTypesEnabled?.sms}
          onChange={(event) => {
            setNewCompany({
              ...newCompany,
              notificationTypesEnabled: {
                ...newCompany?.notificationTypesEnabled,
                sms: event.target.checked,
              },
            });
          }}
        >
          SMS
        </Checkbox>
        <Checkbox
          checked={newCompany?.notificationTypesEnabled?.email}
          onChange={(event) => {
            setNewCompany({
              ...newCompany,
              notificationTypesEnabled: {
                ...newCompany?.notificationTypesEnabled,
                email: event.target.checked,
              },
            });
          }}
        >
          E-mail
        </Checkbox>
        <Checkbox
          checked={newCompany?.notificationTypesEnabled?.web}
          onChange={(event) => {
            setNewCompany({
              ...newCompany,
              notificationTypesEnabled: {
                ...newCompany?.notificationTypesEnabled,
                web: event.target.checked,
              },
            });
          }}
        >
          Browser push
        </Checkbox>
      </Form.Item>
      <Form.Item name="xOneDisabled" label="">
        <Checkbox
          checked={newCompany?.xOneDisabled}
          onChange={(event) => {
            setNewCompany({
              ...newCompany,
              xOneDisabled: event.target.checked
            });
          }}
        >
          Deshabilitar Denunciar
        </Checkbox>
      </Form.Item>
    </Form>
  );
};
